import React from "react";
import { Icon28CancelOutline } from "@vkontakte/icons";
import { Icon28DoneOutline } from "@vkontakte/icons";

type CloseOrSavePopupFooterPropsType = {
    closePopup: () => void;
    savePopupResult: () => void;
    type: { popup_type: string; label: string };
};

const CloseOrSavePopupFooter = (props: CloseOrSavePopupFooterPropsType) => {
    const { closePopup, savePopupResult, type } = props;

    return (
        <div className="CloseOrSavePopupFooter">
            <div className="CloseOrSavePopupFooter__wrapper">
                <Icon28CancelOutline onClick={closePopup} />
                <span className="CloseOrSavePopupFooter__text">
                    {type.label}
                </span>
                <Icon28DoneOutline onClick={savePopupResult} />
            </div>
        </div>
    );
};

export default CloseOrSavePopupFooter;
