import React, { useState } from "react";
import { Cell, List, Footer } from "@vkontakte/vkui";
import Subscription from "../../../includes/Mappings/Subscription";
import { Icon24Add } from "@vkontakte/icons";

declare type SubscriptionSelectModal = {
    subscriptions: Subscription[];
    onSelect: Function;
};

const SubscriptionSelectModal = (props) => {
    const { subscriptions = [], onSelect } = props;

    const [items, setItems] = useState(subscriptions);

    const handleSelectSubscription = (s) => {
        const newItems = items.filter(
            (i) => i.data.subscription_id !== s.data.subscription_id
        );
        setItems(newItems);
        onSelect(s);
    };

    const renderItems = () => {
        if (items.length === 0) {
            return <Footer>Скрытые группы подписчиков отсутствуют</Footer>;
        }

        return items.map((s: Subscription) => {
            return (
                <Cell
                    after={
                        <Icon24Add
                            onClick={() => {
                                handleSelectSubscription(s);
                            }}
                        />
                    }
                    subtitle={s.data.text}
                    key={s.data.subscription_id}
                >
                    {s.data.name}
                </Cell>
            );
        });
    };

    return <List>{renderItems()}</List>;
};

export default SubscriptionSelectModal;
