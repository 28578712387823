import React, { useContext, useState } from "react";
import { Header, Spinner } from "@vkontakte/vkui";
import CloseOrSavePopupFooter from "./CloseOrSavePopupFooter";

import { Context } from "../../../context";
import container from "../../../container";
import { BlockData } from "../../../includes/Structures";
import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import ReactSlider from "react-slider";

type IndentsBlockPopupPropsType = {
    blockRef?: any;
    block: BlockData;
    platform: string;
    blockAlignment: BlockAlignment;
    blockPadding: number;
    setBlockPadding: (values: number) => void;
    setIndentsBlockPopupOpened: (indentsBlockPopupOpened: boolean) => void;
};

const SLIDER_STEP = 5;
const SLIDER_MIN_VALUE = 0;
const SLIDER_MAX_VALUE = 300;

const POPUP_FOOTER_TYPE = { popup_type: "indents-popup", label: "Отступы" };

const IndentsBlockPopup = (props: IndentsBlockPopupPropsType) => {
    const {
        blockRef,
        block,
        platform,
        blockAlignment,
        blockPadding,
        setBlockPadding,
        setIndentsBlockPopupOpened,
    } = props;

    const PagesService = container.get("PagesService");

    const { popout, snackbar, location, pages } = useContext(Context);

    const page_id = location.getPageId();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const saveBlockIndents = async (blockData: BlockData): Promise<void> => {
        const dataToBackend = {
            ...blockData,
            meta: {
                ...blockData.meta,
                padding: blockPadding,
                alignment: blockAlignment,
            },
        };

        setIsLoading(true);

        const response = await PagesService.updateBlock(dataToBackend);

        if (response.result === "success") {
            snackbar.showSuccess("Отступ изменен");

            pages.updateBlock(block.id, page_id, dataToBackend);

            setIsLoading(false);
        } else {
            popout.error(response.message);
            setIsLoading(false);
        }
    };

    const changeSliderValue = (values: number): void => {
        setBlockPadding(values);
    };

    const closeIndentsBlockPopup = (): void => {
        setIndentsBlockPopupOpened(false);
    };

    const savePopupResult = (): void => {
        saveBlockIndents(block);
    };

    const popup_classnames = ["IndentsPopup"];

    if (platform !== "desktop_web") {
        popup_classnames.push("IndentsPopup__mobile");
    }

    if (isLoading) {
        popup_classnames.push("loading");
    }

    return (
        <div className="IndentsPopup__box" ref={blockRef}>
            <div className={popup_classnames.join(" ")}>
                {isLoading ? <Spinner size="regular" /> : null}

                <div className="IndentsPopup__wrapper">
                    <Header>{blockPadding}px</Header>

                    <div className="IndentsPopup__slider">
                        <ReactSlider
                            step={SLIDER_STEP}
                            min={SLIDER_MIN_VALUE}
                            max={SLIDER_MAX_VALUE}
                            value={blockPadding}
                            className="ReactSlider"
                            trackClassName="ReactSlider__sliderTrack_line"
                            onChange={changeSliderValue}
                            minDistance={10}
                            renderThumb={(props) => (
                                <div
                                    {...props}
                                    className="ReactSlider__sliderThumb"
                                ></div>
                            )}
                        />
                    </div>
                </div>

                <CloseOrSavePopupFooter
                    type={POPUP_FOOTER_TYPE}
                    closePopup={closeIndentsBlockPopup}
                    savePopupResult={savePopupResult}
                />
            </div>
        </div>
    );
};

export default IndentsBlockPopup;
