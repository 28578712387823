import { setHash } from "./Helpers";

export const getViewCountText = (viewCount: number): any => {
    let text = "";
    let count = viewCount % 100;

    const words = ["просмотр", "просмотра", "просмотров"];

    if (count >= 5 && count <= 20) {
        text = words["2"];
    } else {
        count = count % 10;
        if (count == 1) {
            text = words["0"];
        } else if (count >= 2 && count <= 4) {
            text = words["1"];
        } else {
            text = words["2"];
        }
    }

    return `${viewCount} ${text}`;
};

export const pageHasUnpublishedState = (page): boolean => {
    let pagesBlocksToCompare = page.blocks.map((b) => {
        let block = { ...b };
        delete block.id;
        delete block.updated;
        return block;
    });

    let pagesEditBlocksToCompare = page.blocks_edit.map((b) => {
        let block = { ...b };
        delete block.id;
        delete block.updated;
        return block;
    });

    return (
        JSON.stringify(pagesBlocksToCompare) !==
        JSON.stringify(pagesEditBlocksToCompare)
    );
};

export const navigateToPage = (page_id: string): void => {
    setHash({
        list: "pages",
        view: "pages-edit",
        panel: "item",
        page_id: page_id,
    });
};

/**
 * Валидирует ссылки на изображения для вставки в промо-страницы по ссылке
 * Принимает ссылки на jpeg, jpg, png, svg изображения
 * @param url
 */
export function isValidLandingImageUrl(url: string): boolean {
    return /^(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg|PNG|JPG|JPEG|SVG|GIF))/.test(
        url
    );
}
