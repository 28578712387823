import React, { useState, useContext, useEffect } from "react";
import { AUDIENCE_ANY } from "../../includes/Constants";
import { Input } from "@vkontakte/vkui";
import { Context } from "../../context";
import { Icon16Add, Icon16Cancel } from "@vkontakte/icons";

const Badges = (props) => {
    const {
        desc,
        onChange,
        items,
        name,
        isPending,
        placeholder,
        tabIndex = -1,
    } = props;
    const { scheme } = useContext(Context);

    const [keywords, setKeywords] = useState(
        items && items !== AUDIENCE_ANY ? items : []
    );
    const [isFocused, setIsFocused] = useState(false);
    const [currentKeyword, setCurrentKeyword] = useState("");

    useEffect(() => {
        setKeywords(items && items !== AUDIENCE_ANY ? items : []);
    }, [items]);

    const deleteKeyword = (index) => {
        let newKeywords = [...keywords];
        newKeywords.splice(index, 1);
        setKeywords(newKeywords);

        onChange && onChange(newKeywords);
    };

    const handleEnter = (event) => {
        if (
            event.keyCode === 13 &&
            currentKeyword.trim() !== "" &&
            !isPending
        ) {
            addKeyword();
        }
    };

    const handleAddClick = () => {
        if (currentKeyword.trim() !== "" && !isPending) {
            addKeyword();
        }
    };

    const addKeyword = () => {
        let newKeywords = [...keywords];
        newKeywords.push(currentKeyword);
        setKeywords(newKeywords);
        setCurrentKeyword("");

        onChange && onChange(newKeywords);
    };

    const renderKeywords = () => {
        return keywords.map((keyword, i) => {
            return (
                <span className="multiple-select-badge" key={`${name}-${i}`}>
                    {keyword}
                    <i
                        onClick={() => {
                            deleteKeyword(i);
                        }}
                    >
                        <Icon16Cancel
                            className="c-ptr"
                            fill="var(--vkui--color_accent_gray)"
                        />
                    </i>
                </span>
            );
        });
    };

    return (
        <>
            {desc && <div className="FormLayout__row-top"> {desc} </div>}
            <div className="Badges__inner">
                <div className="Badges__input">
                    <Input
                        onChange={(e) => {
                            setCurrentKeyword(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            handleEnter(e);
                        }}
                        onFocus={(e) => {
                            setIsFocused(true);
                        }}
                        onBlur={(e) => {
                            setIsFocused(false);
                        }}
                        tabIndex={tabIndex}
                        value={currentKeyword}
                        placeholder={
                            placeholder ? placeholder : "Введите ключевое слово"
                        }
                    />
                    <button
                        style={{ opacity: isFocused ? 1 : 0.5 }}
                        onClick={handleAddClick}
                        className="Badges__add c-ptr"
                    >
                        <Icon16Add fill={scheme.isDark() ? "#444" : "#fff"} />
                    </button>
                </div>
                <div className="multiple-badges">
                    {keywords.length > 0 && renderKeywords()}
                </div>
            </div>
        </>
    );
};

export default Badges;
