import React, { useState } from "react";
import { Search, List, Cell, Caption, platform, Platform, Separator } from "@vkontakte/vkui";
import PageActionsController from "../../Controllers/PageActionsController";
import {
    getViewCountText,
    pageHasUnpublishedState,
} from "../../includes/Helpers/HelpersPages";
import EmptyPagesStub from "../Elements/Stubs/EmptyPagesStub";
import { setHash } from "../../includes/Helpers/Helpers";
import WithHoverTooltip from "../hoc/WithHoverTooltip";
import { Icon24Write } from "@vkontakte/icons";

interface PagesListProps {
    pagesProvider: any;
}

const PagesListDesktop = (props: PagesListProps) => {
    const { pagesProvider } = props;

    const [search, setSearch] = useState("");
    let pages = pagesProvider.getAll();

    const renderPages = () => {
        if (search.trim()) {
            pages = pages.filter((p) => {
                if (
                    search.trim() === "" ||
                    p.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                )
                    return true;
                return false;
            });
        }

        return pages.map((page) => {
            const hasUnpublishedState = pageHasUnpublishedState(page);

            return (
                <Cell
                    className="PagesListDesktop__cell"
                    key={page.id}
                    after={
                        <PageActionsController
                            mode={"icons"}
                            isEdit={false}
                            item={page}
                        />
                    }
                >
                    <div
                        onClick={() => {
                            setHash({
                                list: "pages",
                                view: "pages-edit",
                                panel: "item",
                                page_id: page.id,
                            });

                            pagesProvider.setAvailablePageStates(page.states);
                        }}
                    >
                        <div className="PagesList_desktop-name">
                            {page.name}
                        </div>

                        {hasUnpublishedState && (
                            <span
                                style={{
                                    marginLeft: 10,
                                    position: "relative",
                                    top: "-3px",
                                }}
                            >
                                <WithHoverTooltip
                                    tooltipContent={
                                        <span>
                                            Есть неопубликованные изменения
                                        </span>
                                    }
                                >
                                    <Icon24Write
                                        width={18}
                                        height={18}
                                        fill="var(--vkui--color_accent_gray)"
                                    />
                                </WithHoverTooltip>
                            </span>
                        )}
                        <div>
                            <Caption
                                level="1"
                                weight="3"
                                style={{
                                    color: "var(--vkui--color_text_secondary)",
                                }}
                            >
                                <span
                                    className="PagesList_desktop-status"
                                    style={{
                                        background:
                                            page.status === 3
                                                ? "#d9534f"
                                                : "#5cb85c",
                                    }}
                                />{" "}
                                {getViewCountText(page.statisticSummary.hits)}
                            </Caption>
                        </div>
                    </div>
                </Cell>
            );
        });
    };

    return (
        <>
            <Search
                onChange={(e) => {
                    setSearch(e.currentTarget.value);
                }}
                after=""
            />

            <Separator wide />

            {pages.length > 0 ? (
                <div className="PagesList PagesList_desktop">
                    <List>{renderPages()}</List>
                </div>
            ) : (
                <EmptyPagesStub />
            )}
        </>
    );
};

export default PagesListDesktop;
