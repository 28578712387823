import React, { useState } from "react";
import PagesTemplates from "../../includes/PagesTemplates";
import {
    Div,
    Search,
    Subhead,
    Cell,
    platform as VKUIPlatform,
    Platform
} from "@vkontakte/vkui";
import Card from "../Elements/Card";
import { v4 as uuidv4 } from "uuid";
import { clone } from "../../includes/Helpers/Helpers";

type PagesTemplatesProps = {
    /**
     * Коллбэк при выборе шаблона
     */
    onSelect?: Function;

    /**
     * Вид отображения
     */
    mode?: "cards" | "list";

    /**
     * Платформа отображения
     */
    platform: string;

    /**
     * Выводить вариант выбора "Пустая страница"
     */
    useDefault?: boolean;
};

export default (props: PagesTemplatesProps) => {
    let items = [...PagesTemplates.items];

    const {
        onSelect = () => {},
        mode = "cards",
        useDefault = false,
        platform = "desktop_web",
    } = props;

    const [search, setSearch] = useState("");

    const handleTemplateClick = (item) => {
        const new_item = clone(item);

        new_item.blocks_edit.forEach((block) => {
            if (block.hasOwnProperty("items") && Array.isArray(block.items)) {
                block.items.forEach((i) => {
                    i.uuid = uuidv4();
                });
            }
        });

        onSelect && onSelect(new_item);
    };

    const withSearch = (items) => {
        if (!search) return items;

        return items.filter((i) => {
            if (
                search.trim() === "" ||
                i.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                i.desc.toLowerCase().indexOf(search.toLowerCase()) >= 0
            )
                return true;
            return false;
        });
    };

    const renderCards = () => {
        const rows = [];

        // Выводим вариант выбора "Пустая страница", если необходимо
        if (useDefault) {
            rows.push(
                <div>
                    <Div
                        className={
                            platform === "desktop_web"
                                ? "PagesTemplates__desktop-grid"
                                : ""
                        }
                    >
                        {withSearch(items).map((item) => {
                            if (item.type !== "empty") return null;
                            return (
                                <div
                                    className="PagesTemplates__item"
                                    key={item.type}
                                >
                                    <Card
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            handleTemplateClick(item);
                                        }}
                                        title={item.name}
                                        text={item.desc}
                                        backgroundMinHeight={234}
                                        background={item.image}
                                    />
                                </div>
                            );
                        })}
                    </Div>
                </div>
            );

            /**
             * Добавляем подзаголовок "Популярные"
             */
            rows.push(
                <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Subhead
                        style={{
                            marginBottom: 10,
                            color: "var(--vkui--color_text_secondary)",
                        }}
                        weight="2"
                    >
                        Популярные
                    </Subhead>
                </Div>
            );
        }

        /**
         * Выводим основыне типы шаблонов
         */
        rows.push(
            <Div
                className={
                    platform === "desktop_web"
                        ? "PagesTemplates__desktop-grid"
                        : ""
                }
            >
                {withSearch(items).map((item) => {
                    if (item.type === "empty") return null;
                    return (
                        <div className="PagesTemplates__item" key={item.type}>
                            <Card
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    handleTemplateClick(item);
                                }}
                                title={item.name}
                                text={item.desc}
                                backgroundMinHeight={234}
                                background={item.image}
                            />
                            {useDefault && item.type === "empty" ? (
                                <Subhead
                                    style={{
                                        marginBottom: 10,
                                        color: "var(--vkui--color_text_secondary)",
                                    }}
                                    weight="2"
                                >
                                    Популярные
                                </Subhead>
                            ) : null}
                        </div>
                    );
                })}
            </Div>
        );

        return rows;
    };

    const renderList = () => {
        return (
            <div style={{ paddingTop: 8 }}>
                {withSearch(items).map((item) => {
                    if (!useDefault && item.type === "empty") return null;
                    return (
                        <div
                            className="Cell__main_nopadding PagesTemplates__cell-wrapper"
                            style={{ marginBottom: 12 }}
                            key={item.type}
                        >
                            <Cell
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleTemplateClick(item);
                                }}
                                subtitle={item.desc}
                                multiline={true}
                                hasHover={false}
                                before={
                                    <div
                                        style={{
                                            paddingTop: 1,
                                            width: 72,
                                            height: 72,
                                            borderRadius: 6,
                                            marginRight: 12,
                                            border: "0.5px solid rgba(0, 0, 0, 0.08)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                background: `url(${item.image})`,
                                                backgroundSize: "cover",
                                                backgroundPosition:
                                                    "center center",
                                                height: "100%",
                                                boxSizing: "border-box",
                                                marginTop: "-1px",
                                                borderRadius: 6,
                                            }}
                                        ></div>
                                    </div>
                                }
                            >
                                {item.name}
                            </Cell>
                            {useDefault && item.type === "empty" ? (
                                <Div style={{ paddingBottom: 0 }}>
                                    <Subhead
                                        style={{
                                            marginBottom: 10,
                                            color: "var(--vkui--color_text_secondary)",
                                        }}
                                        weight="2"
                                    >
                                        Популярные
                                    </Subhead>
                                </Div>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="PagesTemplates">
            <Search
                onChange={(e) => {
                    setSearch(e.currentTarget.value);
                }}
                after=""
            />
            {mode === "cards" ? renderCards() : renderList()}
        </div>
    );
};
