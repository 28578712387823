const initialState = {
    title: "",
    title_url: "",
    more: "",
    more_url: "",
    rows: [
        {
            button: "",
            button_url: "",
            descr: "",
            icon_id: "",
            img: "",
            title: "",
            url: "",
            text: "",
            geo: "",
            time: "",
        },
    ],
    has_images: false,
    has_buttons: false,
    has_text: false,
};
export default initialState;
