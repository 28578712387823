function reducer(state, action) {
    let feedback = "";
    const newRows = state.rows.map((item) => ({ ...item }));
    switch (action.type) {
        case "title":
            return {
                ...state,
                title: action.payload[0],
                title_url: action.payload[1],
            };
        case "more":
            return {
                ...state,
                more: action.payload[0],
                more_url: action.payload[1],
            };
        case "newList":
            return { ...state, rows: [...action.payload] };
        case "update":
            newRows[action.payload[0]].title = action.payload[1].title;
            newRows[action.payload[0]].descr = action.payload[1].feedback;
            newRows[action.payload[0]].img = action.payload[1].img;
            newRows[action.payload[0]].icon_id = action.payload[1].icon_id;
            return { ...state, rows: newRows };
        case "add":
            return {
                ...state,
                rows: [
                    ...state.rows,
                    {
                        img: action.payload[1].img,
                        icon_id: action.payload[1].icon_id,
                        title: action.payload[1].title,
                        descr: action.payload[1].feedback,
                        button: "",
                        button_url: "",
                    },
                ],
            };

        case "add_url":
            feedback = action.payload.feedback.replace(/\r?\n/g, " ");
            return {
                ...state,
                rows: [
                    ...state.rows,
                    {
                        img: action.payload.img,
                        icon_id: `id${action.payload.icon_id}`,
                        title: action.payload.title,
                        descr: feedback,
                        button: "Читать отзыв",
                        button_url: action.payload.button_url,
                    },
                ],
            };
        case "delete":
            state.rows.splice(action.payload, 1);
            return {
                ...state,
            };
        default:
            throw new Error();
    }
}
export default reducer;
