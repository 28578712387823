import RequestClientInterface from "../../Interfaces/RequestClientInterface";

class XhrRequestClient implements RequestClientInterface {
    async request(method: string, url: string, body: any) {}

    requestPromise(method: string, url: string, body: any) {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();

            xhr.open(method, url, true);

            xhr.onload = function () {
                try {
                    resolve(JSON.parse(xhr.response));
                } catch (e) {
                    reject({
                        message: `Bad json format - ${xhr.response}`,
                    });
                }
            };

            xhr.send(body);

            xhr.onerror = function () {
                reject({
                    message: `XHR request failed with code - ${xhr.status} - ${xhr.statusText}`,
                });
            };
        });
    }
}

export default XhrRequestClient;
