import RequestClientInterface from "../../Interfaces/RequestClientInterface";

class FetchRequestClient implements RequestClientInterface {
    async request(method: string, url: string, body: any) {
        try {
            const resp = await fetch(url, { method: method, body: body });

            try {
                const data = resp.json();
                return data;
            } catch (e) {
                return {
                    result: "error",
                    message: e.message,
                };
            }
        } catch (e) {
            return {
                result: "error",
                message: e.message,
            };
        }
    }

    requestPromise(method: string, url: string, body: any) {
        return fetch(url, { method: method, body: body }).then((result) =>
            result.json()
        );
    }
}

export default FetchRequestClient;
