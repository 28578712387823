export const VARIABLES_OPTIONS = [
    {
        value: "0",
        label: "Выберите переменную",
    },
    {
        value: "variable_global",
        label: "Глобальная переменная",
    },

    {
        value: "variable_lead",
        label: "Пользовательская переменная",
    },
];

export const VARIABLE_CONDITIONS = [
    { value: "in", label: "Переменная равна" },
    {
        value: "nin",
        label: "Переменная не равна",
    },
    { value: "lt", label: "Переменная меньше" },
    { value: "gt", label: "Переменная больше" },
    {
        value: "exists",
        label: "Переменная имеет значение",
    },
    {
        value: "nexists",
        label: "Переменная не имеет значения",
    },
    {
        value: "contain",
        label: "Переменная содержит значение",
    },
    {
        value: "ncontain",
        label: "Переменная не содержит значение",
    },
];

export const SUBSCRIBERS_MATCH = [
    {
        value: "and",
        label: "Всем условиям",
    },
    {
        value: "or",
        label: "Любому из условий",
    },
];
