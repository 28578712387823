import React from "react";
import { Div } from "@vkontakte/vkui";
import PageButton from "../hoc/PageButton";
import PageLink from "../hoc/PageLink";
import { BlockButtonData } from "../../includes/Structures";
import Twemoji from "react-twemoji";
import { simpleTextToHtml } from "../../includes/Helpers/TextToHtml";

type CardProductImg = {
    url: string;
};

type CardProductProps = {
    name?: string;
    text?: string;
    subhead?: string;
    price?: string;
    price_old?: string;
    img?: CardProductImg;
    button?: BlockButtonData;
    onButtonClick?: Function;
    blockId?: string;
    pageId?: string;
    isPreview: boolean;
    imgHeight?: number;
};

const CardProduct = (props: CardProductProps) => {
    const {
        img,
        name,
        text,
        subhead,
        price,
        price_old,
        button,
        blockId,
        pageId,
        isPreview,
        imgHeight = 0,
    } = props;

    const renderImage = () => {
        const styles = {
            background: `url(${img.url}) center center / cover no-repeat`,
        } as any;

        if (imgHeight > 0) {
            styles.height = imgHeight;
        }

        return (
            <div className="CardProduct__image">
                <div style={styles} className="CardProduct__image-inner"></div>
            </div>
        );
    };

    const renderButton = () => {
        if (!button) {
            return null;
        }

        if (button.action === "url") {
            return (
                <div className="CardProduct__button">
                    <PageLink
                        isPreview={isPreview}
                        id={button.id}
                        url={button.url}
                        text={button.text}
                        color={button.color}
                        blockId={blockId}
                        pageId={pageId}
                        textColor={button.text_color}
                    />
                </div>
            );
        }

        return (
            <div className="CardProduct__button">
                <PageButton
                    isPreview={isPreview}
                    pageId={pageId}
                    blockId={blockId}
                    data={button}
                />
            </div>
        );
    };

    const renderDescription = () => {
        return (
            <div className="CardProduct__desc">
                <Div>
                    {subhead && (
                        <div className="CardProduct__subhead">
                            <Twemoji options={{ className: "twemoji-promo" }}>
                                {subhead}
                            </Twemoji>
                        </div>
                    )}

                    {name && (
                        <div className="CardProduct__name">
                            <Twemoji options={{ className: "twemoji-promo" }}>
                                {name}
                            </Twemoji>
                        </div>
                    )}

                    <div style={{ display: "flex" }}>
                        {price && (
                            <div className="CardProduct__price">
                                <Twemoji
                                    options={{ className: "twemoji-promo" }}
                                >
                                    {price}
                                </Twemoji>
                            </div>
                        )}

                        {price_old && (
                            <div className="CardProduct__price_old">
                                <Twemoji
                                    options={{ className: "twemoji-promo" }}
                                >
                                    {price_old}
                                </Twemoji>
                            </div>
                        )}
                    </div>

                    {text && (
                        <div className="CardProduct__text">
                            <Twemoji options={{ className: "twemoji-promo" }}>
                                {simpleTextToHtml(text)}
                            </Twemoji>
                        </div>
                    )}

                    {button && renderButton()}
                </Div>
            </div>
        );
    };

    return (
        <div className="CardProduct">
            {img && renderImage()}
            {renderDescription()}
        </div>
    );
};

export default CardProduct;
