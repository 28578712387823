import { Input } from "@vkontakte/vkui";
import React, { useContext } from "react";
import { HexColorPicker } from "react-colorful";
import InputMask from "react-input-mask";
import { Icon28CopyOutline } from "@vkontakte/icons";
import { copyToClipboard } from "../../includes/Helpers/Helpers";
import { Context } from "../../context";

type CustomColorPickerPropsType = {
    value: string;
    colors: any[];
    pickerColor: string;
    onSelect: Function;
    setColors: Function;
    setPickerColor: Function;
};

const CustomColorPicker = (props: CustomColorPickerPropsType) => {
    const { pickerColor, setPickerColor, onSelect, value, setColors, colors } =
        props;

    const { snackbar } = useContext(Context);

    const copyPickerColor = () => {
        copyToClipboard(value.toUpperCase(), () => {
            snackbar.showSuccess("Цвет скопирован");
        });
    };

    const setNewPickerColor = (color: string) => {
        const filteredColors = colors.map((c) => {
            if (c.isNewColor) {
                return {
                    ...c,
                    value: color,
                };
            }

            return c;
        });

        setColors(filteredColors);
    };

    return (
        <div className="CustomColorPicker">
            <HexColorPicker
                className="HexColorPicker"
                color={pickerColor}
                onChange={(color) => {
                    setNewPickerColor(color);
                    setPickerColor(color);
                    onSelect({ value: color });
                }}
            />

            <InputMask
                maskChar=""
                mask="\#******"
                value={value.toUpperCase()}
                onChange={(e) => {
                    const inpValue = e.target.value;

                    setNewPickerColor(inpValue);
                    setPickerColor(inpValue);
                    onSelect({ value: inpValue });
                }}
                formatChars={{
                    "#": "#",
                    "*": "[0-9A-Fa-f]",
                }}
            >
                {(inputProps) => (
                    <div className="CustomColorPicker__input-wrapper">
                        <Input {...inputProps} />
                        <Icon28CopyOutline
                            width={16}
                            height={16}
                            onClick={copyPickerColor}
                        />
                    </div>
                )}
            </InputMask>
        </div>
    );
};

export default CustomColorPicker;
