import React, { ReactElement, useState, useEffect } from "react";
import { List, Cell, Avatar, Button, Div } from "@vkontakte/vkui";
import container from "../../../container";
import { VkApiCommunityData } from "../../../includes/Structures";
import { Icon24Done } from "@vkontakte/icons";

interface UserCommunitiesProps {
    items: VkApiCommunityData[];
    callback: Function;
    currentGroup: number;
}

const lp = container.get("LaunchParams");

const UserCommunities = (props: UserCommunitiesProps): ReactElement => {
    const { items, callback, currentGroup } = props;

    const [selectedGroups, setSelectedGroups] = useState([]);
    const [buttonShift, setButtonShift] = useState(0);

    useEffect(() => {
        const currentGroupInItems = items.filter((g) => g.id === currentGroup);
        if (currentGroupInItems.length > 0) {
            setSelectedGroups([currentGroupInItems[0]]);
        }

        let content = document.querySelector(
            ".vkuiModalPage__content"
        ) as HTMLElement;
        let contentInner = document.querySelector(
            ".vkuiModalPage__content-in"
        ) as HTMLElement;

        if (content && contentInner) {
            let contentHeight = content.offsetHeight;
            let contentInnerHeight = contentInner.offsetHeight;

            let heightDifference = contentHeight - contentInnerHeight;
            setButtonShift(heightDifference);
        }
    }, []);

    /**
     * Пока реализуем выбор только одной группы
     * @param item VkApiCommunityData
     */
    const handleItemClick = (item: VkApiCommunityData) => {
        const newSelectedGroups = [...selectedGroups];
        newSelectedGroups[0] = item;
        setSelectedGroups(newSelectedGroups);
    };

    const handleContinue = () => {
        if (selectedGroups.length > 0) {
            callback && callback(selectedGroups);
        }
    };

    const isIOS =
        ["mobile_iphone", "mobile_iphone_messenger"].indexOf(
            lp.gets.vk_platform
        ) >= 0;

    return (
        <>
            <div style={{ paddingBottom: isIOS ? 0 : 70 }}>
                <List className="UserCommunity-modal">
                    {items.map((item) => {
                        let selected =
                            selectedGroups.filter((g) => g.id === item.id)
                                .length > 0;

                        return (
                            <Cell
                                key={item.id}
                                onClick={() => {
                                    handleItemClick(item);
                                }}
                                before={
                                    <Avatar size={40} src={item.photo_50} />
                                }
                                after={
                                    selected && (
                                        <Icon24Done fill="var(--vkui--color_text_accent)" />
                                    )
                                }
                            >
                                {item.name}
                            </Cell>
                        );
                    })}
                </List>
            </div>
            {isIOS ? (
                <Div>
                    <Button size="l" onClick={handleContinue} stretched>
                        Продолжить
                    </Button>
                </Div>
            ) : (
                <Div
                    className={`ModalPage__bottom-button ${lp.gets.vk_platform}`}
                    style={buttonShift > 0 ? { bottom: buttonShift } : {}}
                >
                    <Button size="l" onClick={handleContinue} stretched>
                        Продолжить
                    </Button>
                </Div>
            )}
        </>
    );
};

export default UserCommunities;
