import React from "react";
import { SimpleCell, Group } from "@vkontakte/vkui";

import PagesTemplates from "../../../includes/PagesTemplates";

import {
    Icon28ArticleOutline,
    Icon28ChatsOutline,
    Icon28LinkCircleOutline,
    Icon28PictureOutline,
    Icon28RecentOutline,
    Icon28TagOutline,
    Icon28TargetOutline,
    Icon28VideocamOutline,
    Icon20GearOutline,
    Icon28FavoriteOutline,
} from "@vkontakte/icons";

type BlockTypeSelectProps = {
    onSelect: Function;
};

const BlockTypeSelect = (props: BlockTypeSelectProps) => {
    const { onSelect } = props;

    const types = { ...PagesTemplates.types };

    const getBlockTypeIcon = (type: string) => {
        switch (type) {
            case "cover":
                return <Icon28TargetOutline />;
            case "advantages":
                return <Icon28FavoriteOutline />;
            case "text":
                return <Icon28ArticleOutline />;
            case "button":
                return <Icon28LinkCircleOutline />;
            case "image":
                return <Icon28PictureOutline />;
            case "reviews":
                return <Icon28ChatsOutline />;
            case "products":
                return <Icon28TagOutline />;
            case "video":
                return <Icon28VideocamOutline />;
            case "timer":
                return <Icon28RecentOutline />;
            case "other":
                return <Icon20GearOutline width={28} height={28} />;
            default:
                throw new Error("Undefined block type");
        }
    };

    const renderTypes = () => {
        return Object.keys(types).map((type) => {
            const typeItem = types[type];
            return (
                <SimpleCell
                    key={type}
                    before={getBlockTypeIcon(type)}
                    onClick={() => {
                        onSelect(typeItem);
                    }}
                    expandable={true}
                >
                    {typeItem.name}
                </SimpleCell>
            );
        });
    };

    return (
        <div className="PagesBlockTypeSelect">
            <Group>{renderTypes()}</Group>
        </div>
    );
};

export default BlockTypeSelect;
