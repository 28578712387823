import config from "../Config";
import Service from "./Service";
import container from "../../container";

import LoggerInterface from "../Interfaces/LoggerInterface";
import { PageStatisticGoalData, PageStatisticHitData } from "../Structures";

type GetPageStatisticParams = {
    page_id: string;
};

type getPageStatisticByDateRangeDataToServer = {
    page_id: string;
    date_from: string;
    date_to: string;
    detailing?: string;
};

class PageStatisticService extends Service {
    launchParams: any;
    logger: LoggerInterface;

    constructor(launchParams, logger: LoggerInterface) {
        super();
        this.launchParams = launchParams;
        this.logger = logger;
    }

    async hit(data: PageStatisticHitData) {
        try {
            const requestData = this._createRequestData({
                data: JSON.stringify(data),
            });

            const response = await fetch(config.pageStatisticHitUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            return {
                result: "error",
                message: e.message,
            };
        }
    }

    async goal(data: PageStatisticGoalData) {
        try {
            const requestData = this._createRequestData({
                data: JSON.stringify(data),
            });

            const response = await fetch(config.pageStatisticGoalUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            return {
                result: "error",
                message: e.message,
            };
        }
    }

    async getPageStatistic(params: GetPageStatisticParams) {
        try {
            const requestData = this._createRequestData({
                data: JSON.stringify(params),
            });

            const response = await fetch(config.pageStatisticUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            return {
                result: "error",
                message: e.message,
            };
        }
    }

    async getPageStatisticByDateRange(
        dataToServer: getPageStatisticByDateRangeDataToServer
    ) {
        try {
            const requestData = this._createRequestData({
                data: JSON.stringify({
                    date_from: dataToServer.date_from,
                    date_to: dataToServer.date_to,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    detailing: dataToServer.detailing,
                }),
            });

            const response = await fetch(
                config.getPageStatisticByDateRangeUrl.replace(
                    /{page_id}/,
                    dataToServer.page_id
                ),
                {
                    method: "POST",
                    body: requestData,
                }
            );

            return this._formattedResponse(response);
        } catch (e) {
            return {
                result: "error",
                message: e.message,
            };
        }
    }

    async getSummaryPageStatisticByDateRange(
        dataToServer: getPageStatisticByDateRangeDataToServer
    ) {
        try {
            const requestData = this._createRequestData({
                data: JSON.stringify({
                    date_from: dataToServer.date_from,
                    date_to: dataToServer.date_to,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                }),
            });

            const response = await fetch(
                config.getSummaryPageStatisticByDateRange.replace(
                    /{page_id}/,
                    dataToServer.page_id
                ),
                {
                    method: "POST",
                    body: requestData,
                }
            );

            return this._formattedResponse(response);
        } catch (e) {
            return {
                result: "error",
                message: e.message,
            };
        }
    }
}

const instance = new PageStatisticService(
    container.get("LaunchParams"),
    container.get("logger")
);

export default instance;
