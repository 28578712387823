import React, { useReducer } from "react";
import { Div, FixedLayout, Separator } from "@vkontakte/vkui";
import FormSubmitButton from "../../FormSubmitButton";

import initialState from "./state";
import reducer, { SET_DESKTOP_IMAGE, SET_MOBILE_IMAGE } from "./reducer";
import { Icon16Cancel, Icon48Camera } from "@vkontakte/icons";

const ImagesSingleEdit = (props) => {
    const { data, onSubmit, openModal } = props;

    const [state, dispatch] = useReducer(reducer, { ...initialState, ...data });

    const handleSubmit = () => {
        onSubmit(state);
    };

    const renderImagePreview = (type: string) => {
        let actionType =
            type === "desktop" ? SET_DESKTOP_IMAGE : SET_MOBILE_IMAGE;

        return (
            <div className="GroupBannersForm__banner">
                <div>
                    <img
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        src={state.content[`${type}_img`]}
                        alt=""
                    />
                </div>
                <button
                    onClick={() => {
                        dispatch({ type: actionType, payload: "" });
                    }}
                    title={"Удалить"}
                    className="GroupBannersForm__button GroupBannersForm__button_delete"
                >
                    <Icon16Cancel fill="#fff" />
                </button>
            </div>
        );
    };

    const renderImageFilePicker = (type: string) => {
        let actionType =
            type === "desktop" ? SET_DESKTOP_IMAGE : SET_MOBILE_IMAGE;
        return (
            <div
                className="GroupBannersForm__banner GroupBannersForm__banner_filepicker"
                onClick={() => {
                    openModal({
                        id: "landing-img",
                        args: {
                            size: [1920, 1220],
                            hasDescription: false,
                            hasAddressUrl: true,
                            imageUrl: "",
                            address: "",
                            withCrop: false,
                            callback: (data) => {
                                dispatch({
                                    type: actionType,
                                    payload: {
                                        url: data.url,
                                        address_url: data.address_url,
                                    },
                                });
                            },
                        },
                    });
                }}
            >
                <Icon48Camera
                    width={32}
                    height={32}
                    fill="var(--vkui--color_write_bar_icon)"
                />
            </div>
        );
    };

    const hasDesktopImage = !!state.content.desktop_img;
    const hasMobileImage = !!state.content.mobile_img;

    return (
        <div style={{ paddingBottom: 60 }}>
            <Div>
                <div className="subhead">Для веб-версии</div>
                {hasDesktopImage
                    ? renderImagePreview("desktop")
                    : renderImageFilePicker("desktop")}
            </Div>
            <Separator />
            <Div>
                <div className="subhead">Для мобильной версии</div>
                {hasMobileImage
                    ? renderImagePreview("mobile")
                    : renderImageFilePicker("mobile")}
            </Div>

            <FixedLayout filled={true} vertical={"bottom"}>
                <FormSubmitButton handleSubmit={handleSubmit} />
            </FixedLayout>
        </div>
    );
};

export default ImagesSingleEdit;
