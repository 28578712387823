import { clone } from "../../../../../includes/Helpers/Helpers";

export default function (state, action) {
    const newState = clone(state);

    const { type, payload } = action;

    switch (type) {
        case "set-width":
            newState.meta.width = payload;
            return newState;
        case "set-padding-top":
            newState.meta.padding_top = payload;
            return newState;
        case "set-padding-bottom":
            newState.meta.padding_bottom = payload;
            return newState;

        default:
            return newState;
    }
}
