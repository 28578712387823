import React from "react";

interface CustomAvatarProps {
    img: string;
    style?: any;
}

const CustomAvatar = (props: CustomAvatarProps) => {
    const { img, style = {} } = props;

    const avatarStyle = {
        ...style,
        background: `url(${img})  center center / cover no-repeat`,
    };

    return <div style={avatarStyle} className="CustomAvatar"></div>;
};

export default CustomAvatar;
