import React, { ReactElement } from "react";
import { Alert } from "@vkontakte/vkui";

import { ReactComponent as Checkmark } from "../../../resources/icons/checkmark.svg";
import { ReactComponent as Cancelmark } from "../../../resources/icons/cancelmark.svg";
import { AlertActionInterface } from "@vkontakte/vkui/dist/components/Alert/Alert";

interface AlertSubscriptionSuccessProps {
    type: "success" | "error";
    text: string | ReactElement;
    title?: string | ReactElement;
    onClose: () => void;
    body?: any;
    actions?: AlertActionInterface[];
}

const AlertBase = (props: AlertSubscriptionSuccessProps) => {
    let { title, text, type, onClose, body, actions = [] } = props;

    let icon;

    switch (type) {
        case "success":
            icon = <Checkmark />;
            break;
        case "error":
            icon = <Cancelmark />;
            break;
        default:
            icon = <Checkmark />;
    }

    return (
        <Alert
            actions={
                actions.length > 0
                    ? actions
                    : [
                          {
                              title: "Закрыть",
                              autoClose: true,
                              mode: "default",
                          },
                      ]
            }
            onClose={onClose}
        >
            <div style={{ marginBottom: 20 }}>{icon}</div>
            {title && <h2 style={{ textAlign: "center" }}>{title}</h2>}
            {text && (
                <div
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        textAlign: "center",
                    }}
                >
                    {text}
                </div>
            )}
            {body ? body : null}
        </Alert>
    );
};

export default AlertBase;
