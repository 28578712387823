import React, { useReducer, useState, useEffect, useContext } from "react";
import { Context } from "../../../../../context";
import {
    FormLayout,
    Group,
    Div,
    FixedLayout,
    Button,
    Cell,
    List,
    Separator,
} from "@vkontakte/vkui";
import initialState from "./state";
import reducer from "./reducer";
import CustomAvatar from "../../../../Elements/CustomAvatar";
import FormSubmitButton from "../FormSubmitButton";
import TimerControl from "../Timer/TimerControl";
import { Icon20ArticleBoxOutline, Icon20DeleteOutline, Icon24Add } from "@vkontakte/icons";

type ProductsEditProps = {
    data: any;
    currentDynamicInput: string;
    onSubmit: Function;
    openModal: Function;
    maxItemsCount: number; // Максимальное возможное количество блоков
    block_id: string;
};

const ProductsEdit = (props: ProductsEditProps) => {
    const {
        data,
        currentDynamicInput,
        onSubmit,
        openModal,
        maxItemsCount,
        block_id,
    } = props;
    const { pages } = useContext(Context);

    const [state, dispatch] = useReducer(reducer, { ...initialState, ...data });
    const [editMode, setEditMode] = useState(false);

    const { has_title } = state;

    const isAllowAdd = state.items.length < maxItemsCount;

    useEffect(() => {
        return function cleanup() {
            pages.setCurrentEditableProducts([]);
        };
    }, []);

    useEffect(() => {
        pages.setCurrentEditableProducts(state.items);
    }, [state.items]);

    const handleSubmit = () => {
        onSubmit(state);
    };

    const renderItems = () => {
        return state.items.map((item, index) => {
            return (
                <Cell
                    key={index}
                    style={{ paddingLeft: 0 }}
                    draggable={editMode}
                    mode={editMode ? 'removable': undefined}
                    removePlaceholder={<Icon20DeleteOutline />}
                    multiline={true}
                    subtitle={item.text ? item.text : ""}
                    onClick={() => {
                        openModal({
                            id: "landing-product",
                            args: {
                                isEdit: true,
                                name: item.name,
                                text: item.text,
                                category: item.category,
                                price: item.price,
                                price_old: item.price_old,
                                button: item.button,
                                imgUrl: item.img ? item.img.url : "",
                                block_id: block_id,
                                index: index,
                                callback: (data) => {
                                    dispatch({
                                        type: "edit-item",
                                        payload: { index, data: data },
                                    });
                                },
                            },
                        });
                    }}
                    onRemove={() => {
                        dispatch({ type: "remove-item", payload: { index } });
                    }}
                    onDragFinish={({ from, to }) => {
                        dispatch({ type: "sort-items", payload: { from, to } });
                    }}
                    before={
                        <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                            <CustomAvatar
                                style={{ marginRight: 10 }}
                                img={
                                    item.img
                                        ? item.img.url
                                        : "https://i.yapx.ru/IoIwy.png"
                                }
                            />
                        </div>
                    }
                >
                    {item.name}
                </Cell>
            );
        });
    };

    const renderAddItemButton = () => {
        return (
            <div
                style={{ marginTop: 10 }}
                onClick={() => {
                    openModal({
                        id: "landing-product",
                        args: {
                            isEdit: false,
                            name: "",
                            text: "",
                            imgUrl: "",
                            button: {},
                            block_id: block_id,
                            index: state.items.length,
                            callback: (data) => {
                                dispatch({
                                    type: "add-item",
                                    payload: { data: data },
                                });
                            },
                        },
                    });
                }}
                className="BlockEdit__item-add"
            >
                <span>Добавить товар</span>
                <span>
                    <Icon24Add width={18} />
                </span>
            </div>
        );
    };

    return (
        <FormLayout style={{ paddingBottom: 100 }}>
            <TimerControl
                checked={has_title}
                title="Заголовок и описание"
                icon={
                    <Icon20ArticleBoxOutline
                        width={24}
                        height={24}
                        fill="var(--vkui--color_icon_accent_themed)"
                        style={{
                            marginRight: 19,
                            opacity: has_title ? 1 : 0.4,
                        }}
                    />
                }
                onChange={() => {
                    dispatch({
                        type: "set-has-title",
                        payload: !has_title,
                    });
                }}
                onEditClick={() => {
                    openModal({
                        id: "text",
                        args: {
                            content: state.content,
                            currentDynamicInput: currentDynamicInput,
                            callback: (data) => {
                                dispatch({
                                    type: "edit-text",
                                    payload: data,
                                });
                            },
                        },
                    });
                }}
            />

            <Separator />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        className="subhead"
                    >
                        <span>Товары</span>
                        <Button
                            style={{ marginRight: 0 }}
                            onClick={() => {
                                setEditMode(!editMode);
                            }}
                            mode="secondary"
                        >
                            {editMode ? "Готово" : "Править"}
                        </Button>
                    </div>
                </Div>

                <div>
                    <Group>
                        <List>{renderItems()}</List>
                    </Group>
                    {false === editMode && isAllowAdd
                        ? renderAddItemButton()
                        : null}
                </div>
            </div>

            {false === editMode && (
                <FixedLayout filled={true} vertical={"bottom"}>
                    <FormSubmitButton handleSubmit={handleSubmit} />
                </FixedLayout>
            )}
        </FormLayout>
    );
};

export default ProductsEdit;
