import React, { Component } from "react";
import {
    Cell,
    Group,
    Button,
    PanelHeader,
    Root,
    View,
    Panel,
    Div,
    List,
    Alert,
    Avatar,
    Card,
    Separator,
} from "@vkontakte/vkui";
import container from "../container";

import bridge from "@vkontakte/vk-bridge";

//@ts-ignore
import mainImage from "../resources/images/landing-route/main.png";
//@ts-ignore
import card1 from "../resources/images/landing-route/card1.png";
//@ts-ignore
import card2 from "../resources/images/landing-route/card2.png";
//@ts-ignore
import card3 from "../resources/images/landing-route/card3.png";
//@ts-ignore
import card4 from "../resources/images/landing-route/card4.png";
//@ts-ignore
import card5 from "../resources/images/landing-route/card5.png";
//@ts-ignore
import logo from "../resources/images/landing-route/logo.png";

import { Icon20Add, Icon28DoneOutline, Icon28ThumbsUpOutline } from "@vkontakte/icons";
import LaunchParams from "../includes/Services/LaunchParams";

class Landing extends Component<any, any> {
    launchParams: LaunchParams;

    constructor(props) {
        super(props);

        this.launchParams = container.get("LaunchParams");

        this.state = {
            vkIsRecommended: !!Number(
                this.launchParams.params.vk_is_recommended
            ),
        };

        bridge.subscribe((e) => {
            if (e.detail) {
                switch (e.detail.type) {
                    case "VKWebAppAddToCommunityResult":
                        if (e.detail.data) {
                            // Отправялем человека сразу в приложение внутри сообщества. Все дальнейшие инструкции там
                            const url = `https://vk.com/app${this.launchParams.params.vk_app_id}_-${e.detail.data.group_id}`;

                            this.props.mainPopout.open(
                                <Alert
                                    actions={[
                                        {
                                            title: "Закрыть",
                                            autoClose: true,
                                            mode: "cancel",
                                        },
                                    ]}
                                    onClose={() =>
                                        this.props.mainPopout.close()
                                    }
                                >
                                    <h2>Приложение добавлено</h2>
                                    <div
                                        style={{
                                            marginTop: 20,
                                            marginBottom: 20,
                                        }}
                                    >
                                        Следуйте дальнейшим инструкциям для
                                        подключения сообщества
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                window.top.location.href = url;
                                            }}
                                            size="l"
                                            stretched
                                        >
                                            Перейти в приложение
                                        </Button>
                                    </div>
                                </Alert>
                            );
                        }
                        break;
                    case "VKWebAppAddToCommunityFailed":
                        console.log("App Failed", e);
                        break;
                    default:
                }
            }
        });
    }

    componentDidMount() {
        const { resizer } = this.props;

        resizer.setHeight();
    }

    render() {
        const platform = this.launchParams.getPlatform();
        const { vkIsRecommended } = this.state;

        return (
            <Root activeView="landing">
                <View
                    className={`${
                        platform !== "desktop_web"
                            ? "landing-mobile"
                            : "landing-desktop"
                    }`}
                    id="landing"
                    // popout={this.props.popout}
                    activePanel="landing_main"
                >
                    <Panel id="landing_main">
                        <div
                            className="content--inner"
                            style={{
                                padding:
                                    platform === "desktop_web" ? "0 30px" : "0",
                            }}
                        >
                            <PanelHeader>Senler</PanelHeader>
                            <Group title="Главная информация">
                                <div className="landing-row">
                                    <Div className="landing-info-block">
                                        <Div>
                                            <h2 style={{ margin: 0 }}>
                                                Работайте с клиентами ваших
                                                сообществ
                                            </h2>
                                        </Div>
                                        <Div>
                                            Рассылки, чат-боты, виджеты и
                                            лендинги для ваших сообществ
                                            ВКонтакте
                                        </Div>

                                        <Div>
                                            <Button
                                                className="landing-button"
                                                // mode="commerce"
                                                onClick={() => {
                                                    bridge.send(
                                                        "VKWebAppAddToCommunity",
                                                        {}
                                                    );
                                                }}
                                                size="m"
                                                before={
                                                    <Icon20Add
                                                        style={{
                                                            marginRight: 11,
                                                        }}
                                                    />
                                                }
                                            >
                                                {" "}
                                                Установить в сообщество
                                            </Button>
                                        </Div>

                                        {/* <Div style={{ paddingTop: 0 }}>
                                            <Button
                                                className="landing-button"
                                                mode="outline"
                                                disabled={vkIsRecommended}
                                                size="m"
                                                style={{
                                                    pointerEvents:
                                                        vkIsRecommended
                                                            ? "none"
                                                            : "auto",
                                                }}
                                                onClick={async () => {
                                                    const response: any =
                                                        await bridge.send(
                                                            //@ts-ignore
                                                            "VKWebAppRecommend"
                                                        );

                                                    if (response.result) {
                                                        this.setState({
                                                            vkIsRecommended:
                                                                true,
                                                        });
                                                    }
                                                }}
                                                before={
                                                    vkIsRecommended ? (
                                                        <Icon28DoneOutline />
                                                    ) : (
                                                        <Icon28ThumbsUpOutline
                                                            style={{
                                                                marginRight: 11,
                                                            }}
                                                        />
                                                    )
                                                }
                                            >
                                                {vkIsRecommended
                                                    ? "Вы рекомендуете"
                                                    : "Рекомендовать"}
                                            </Button>
                                        </Div> */}
                                    </Div>

                                    <Div className="landing-main-img">
                                        <img src={mainImage} alt="" />
                                    </Div>
                                </div>
                            </Group>

                            <Group title="Особенности" separator="hide">
                                <Div
                                    className="landing-features"
                                    style={{
                                        textAlign:
                                            platform === "desktop_web"
                                                ? "left"
                                                : "center",
                                    }}
                                >
                                    <h2>Особенности</h2>
                                </Div>
                                <Div>
                                    <div className="landing-card-row">
                                        <Card
                                            className="landing-card"
                                            mode="shadow"
                                        >
                                            <img src={card1} alt="" />

                                            <Div className="landing-card__header">
                                                <h3>Рассылки</h3>
                                                <div>
                                                    Создание и управление
                                                    рассылками для ваших
                                                    сообществ ВКонтакте.
                                                </div>
                                            </Div>

                                            <Div>
                                                <List className="landing-card__list">
                                                    <li>
                                                        • персонализация
                                                        сообщений
                                                    </li>
                                                    <li>• платные подписки</li>
                                                    <li>• медиавложения</li>
                                                </List>
                                            </Div>
                                        </Card>

                                        <Card
                                            className="landing-card"
                                            mode="shadow"
                                        >
                                            <img src={card2} alt="" />

                                            <Div className="landing-card__header">
                                                <h3>Чат-боты</h3>
                                                <div>
                                                    Добавьте в свое сообщество{" "}
                                                    <br className="desktop-br" />
                                                    чат-бота и настройте его{" "}
                                                    <br className="desktop-br" />{" "}
                                                    под себя.
                                                </div>
                                            </Div>

                                            <Div>
                                                <List className="landing-card__list">
                                                    <li>
                                                        • визуальный редактор
                                                        чат-ботов
                                                    </li>
                                                    <li>• гибкие настройки</li>
                                                    <li>• кнопки действий</li>
                                                </List>
                                            </Div>
                                        </Card>

                                        <Card
                                            className="landing-card"
                                            mode="shadow"
                                        >
                                            <img src={card3} alt="" />

                                            <Div className="landing-card__header">
                                                <h3>Лендинги</h3>
                                                <div>
                                                    Создание уникальных
                                                    лендингов прямо ВКонтакте
                                                </div>
                                            </Div>

                                            <Div>
                                                <List className="landing-card__list">
                                                    <li>
                                                        • конструктор лендингов
                                                    </li>
                                                    <li>
                                                        • десктоп и мобильная
                                                        версия
                                                    </li>
                                                    <li>
                                                        • настройки целевых
                                                        действий
                                                    </li>
                                                </List>
                                            </Div>
                                        </Card>

                                        <Card
                                            className="landing-card"
                                            mode="shadow"
                                        >
                                            <img src={card4} alt="" />

                                            <Div className="landing-card__header">
                                                <h3>Виджеты</h3>
                                                <div>
                                                    Настройте привлекательный
                                                    виджет под уникальные
                                                    аудитории.
                                                </div>
                                            </Div>

                                            <Div>
                                                <List className="landing-card__list">
                                                    <li>
                                                        • конструктор виджетов
                                                    </li>
                                                    <li>
                                                        • десктоп и мобильная
                                                        версия
                                                    </li>
                                                    <li>
                                                        • настройки аудитории
                                                    </li>
                                                </List>
                                            </Div>
                                        </Card>
                                    </div>
                                </Div>
                            </Group>

                            <Separator />

                            <Group
                                title="Контакты"
                                separator="hide"
                                style={{
                                    marginBottom:
                                        platform === "desktop_web" ? 40 : 0,
                                }}
                            >
                                <List>
                                    <Cell
                                        style={{
                                            outline: 0,
                                            padding: "10px 0 18px 0",
                                        }}
                                        before={<Avatar src={logo} />}
                                        subtitle="Таргетированные рассылки личных сообщений от имени сообществ"
                                        href="http://vk.com/club139157852"
                                        target="_blank"
                                    >
                                        Senler | Сервис рассылок
                                    </Cell>
                                </List>
                            </Group>
                        </div>
                    </Panel>
                </View>
            </Root>
        );
    }
}

export default Landing;
