import React, { useRef } from "react";

import AddCircleIcon from "../../resources/icons/AddCircleIcon";
import { Icon24ArrowUturnLeftOutline, Icon24DoneOutline, Icon24MoreHorizontal, Icon28ArrowUturnRightOutline, Icon28ShareOutline } from "@vkontakte/icons";

type PagesEditBottomSnackbarProps = {
    isAllowUndo: boolean;
    isAllowRedo: boolean;
    isAllowAddBlock: boolean;
    isAllowPreview: boolean;
    hasUnpublishedChanges: boolean;
    isStateTravelMode: boolean;
    onAddClick?: Function;
    onPreviewClick?: Function;
    onUndoClick?: Function;
    onRedoClick?: Function;
    onSaveStateTravelMode: Function;
    onMoreClick: Function;
};
const PagesEditBottomSnackbar = (props: PagesEditBottomSnackbarProps) => {
    const {
        isAllowUndo, // Можно откатить изменения
        isAllowRedo, // Можно вернуть изменения
        isAllowAddBlock, // Можно добавить блок
        isAllowPreview, // Можно включить режим прдпросмотра
        hasUnpublishedChanges, // Страница имеет неопубликованные изменения
        isStateTravelMode,
        onAddClick,
        onPreviewClick,
        onRedoClick,
        onUndoClick,
        onSaveStateTravelMode,
        onMoreClick,
    } = props;

    const popoutRef = useRef()

    return (
        <div className="PagesBottomSnackbar">
            <div className="PagesBottomSnackbar__buttons">
                <button
                    title="Откатить изменения"
                    className="PagesBottomSnackbar__button"
                    onClick={() => {
                        isAllowUndo && onUndoClick();
                    }}
                >
                    <Icon24ArrowUturnLeftOutline
                        fill={
                            isAllowUndo
                                ? "var(--vkui--color_text_accent)"
                                : "var(--vkui--color_accent_gray)"
                        }
                        width={30}
                        height={30}
                    />
                </button>

                <button
                    title="Вернуть изменения"
                    className="PagesBottomSnackbar__button"
                    onClick={() => {
                        isAllowRedo && onRedoClick();
                    }}
                >
                    <Icon28ArrowUturnRightOutline
                        fill={
                            isAllowRedo
                                ? "var(--vkui--color_text_accent)"
                                : "var(--vkui--color_accent_gray)"
                        }
                    />
                </button>

                <button
                    title="Добавить блок"
                    className="PagesBottomSnackbar__button"
                    onClick={() => {
                        if (isAllowAddBlock) onAddClick();
                    }}
                >
                    <AddCircleIcon
                        fill={
                            isAllowAddBlock
                                ? "#4BB34B"
                                : "var(--vkui--color_accent_gray)"
                        }
                        width={28}
                        height={28}
                    />
                </button>

                <button
                    title="Опубликовать"
                    className="PagesBottomSnackbar__button"
                    onClick={() => {
                        if (isAllowPreview) onPreviewClick();
                    }}
                >
                    <span
                        className={`PagesBottomSnackbar__unpub-indicator ${
                            hasUnpublishedChanges ? "active" : ""
                        }`}
                    ></span>
                    <Icon28ShareOutline
                        fill={
                            isAllowPreview
                                ? "var(--vkui--color_text_accent)"
                                : "var(--vkui--color_accent_gray)"
                        }
                    />
                </button>

                <button
                    title={isStateTravelMode ? "Сохранить" : "Еще"}
                    className="PagesBottomSnackbar__button"
                    onClick={() => {
                        if (isStateTravelMode) {
                            onSaveStateTravelMode();
                        } else {
                            onMoreClick(popoutRef);
                        }
                    }}
                    ref={popoutRef}
                >
                    {isStateTravelMode ? (
                        <Icon24DoneOutline
                            fill="var(--vkui--color_text_accent)"
                            width={28}
                            height={28}
                        />
                    ) : (
                        <Icon24MoreHorizontal
                            fill="var(--vkui--color_text_accent)"
                            width={28}
                            height={28}
                        />
                    )}
                </button>
            </div>
        </div>
    );
};

export default PagesEditBottomSnackbar;
