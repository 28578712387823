import { createContext } from "react";

type ContextType = {
    location?: any;
    widgets?: any;
    currentModal?: any;
    groupSiteData?: any;
    initialSettings?: any;
    guide?: any;
    resizer?: any;
    snackbar?: any;
    popout?: any;
    subscriptions?: any;
    user?: any;
    lastActiveTypeFilter?: any;
    bots?: any;
    urlParams?: any;
    inputDynamic?: any;
    scheme?: any;
    payment?: any;
    pages?: any;
};

type WidgetStateType = {
    stateBodyWidget?: any;
    state?: any;
};

type EditWidgetType = {
    state?: any;
};
type ModalContextType = {
    setModalUpdateTrigger?: any;
    modalUpdateTirgger?: any;
};

export const Context = createContext<ContextType>({});
export const WidgetState = createContext<WidgetStateType>({});
export const EditWidget = createContext<EditWidgetType>({});
export const ModalContext = createContext<ModalContextType>({});
