import React from "react";
import { LaunchParams } from "../includes/Structures";
import { ReactComponent as Logo } from "../resources/VKBlueLogo.svg";
import container from "../container";
import * as Sentry from "@sentry/react";

interface NotSupportedRouteProps {
    urlParams: LaunchParams;
    popout: any;
    mainPopout: any;
}

const NotSupportedRoute = (props: NotSupportedRouteProps) => {
    const Logger = container.get("logger");

    const { urlParams } = props;

    let url_update, url_main, title, text;

    url_main = `https://vk.com/away.php?to=https://vk.com/app${urlParams.vk_app_id}_-${urlParams.vk_group_id}`;

    if (urlParams.s) {
        url_main += `#s=${urlParams.s}`;
    }

    try {
        Logger.error(
            {
                code: 999,
                message: "bridge.init - event not supported",
            },
            "not_supported_error",
            "NotSupportedRoute.tsx"
        );
    } catch (e) {
        console.log(e);
        Sentry.captureException(e);
    }

    switch (urlParams.vk_platform) {
        case "mobile_iphone":
            url_update = `https://vk.com/away.php?to=https://apps.apple.com/ru/app/vk-%D0%BE%D0%B1%D1%89%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BC%D1%83%D0%B7%D1%8B%D0%BA%D0%B0-%D0%B8-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE/id564177498`;
            title = `Обновите приложение`;
            text = (
                <>
                    Для полноценного использования сервиса необходимо обновить
                    приложение ВКонтакте до последней версии.
                </>
            );
            break;
        case "mobile_android":
            url_update = `https://vk.com/away.php?to=https://play.google.com/store/apps/details?id=com.vkontakte.android&hl=ru`;
            title = `Обновите приложение`;
            text = (
                <>
                    Для полноценного использования сервиса необходимо обновить
                    приложение ВКонтакте до последней версии.
                </>
            );
            break;
        // case "mobile_web":
        //     url_update = ``;
        //     title = `Откройте сервис через приложение ВКонтакте`;
        //     text = <>Для полноценного использования сервиса <br/>
        //         необходимо открыть его через приложение ВКонтакте.</>;
        //     break;
        default:
            url_update = ``;
            // url_main = ``;
            title = `Обновите приложение`;
            text = (
                <>
                    Для полноценного использования сервиса необходимо обновить
                    ваш браузер или приложение ВКонтакте до последней версии.
                </>
            );
            break;
    }

    return (
        <div className="NotSupportedRoute FatalError">
            <div className="FatalError__img FatalError__img_vk">
                <Logo />
            </div>
            <p className="bold head">{title}</p>
            <p className="sub">{text}</p>
            {url_update && (
                <p style={{ marginBottom: "10px" }}>
                    <a
                        style={{ width: "240px" }}
                        className="btn-link btn-link_reverse"
                        href={url_update}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Обновить приложение
                    </a>
                </p>
            )}
            {url_main && (
                <p>
                    <a
                        style={{ width: "240px" }}
                        className="btn-link"
                        href={url_main}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Открыть в браузере
                    </a>
                </p>
            )}
            <p className="FatalError__footer">
                <span>Senler - сервис рассылок</span>
            </p>
        </div>
    );
};

export default NotSupportedRoute;
