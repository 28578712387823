import { FixedLayout, FormLayout, Header, Slider } from "@vkontakte/vkui";
import React, { useContext, useReducer } from "react";
import FormSubmitButton from "../FormSubmitButton";
import initialState from "./state";
import reducer from "./reducer";
import { Context } from "../../../../../context";

type DividerEditProps = {
    data: any;
    onSubmit: Function;
};

const DividerEdit = (props: DividerEditProps) => {
    const { data, onSubmit } = props;

    const { snackbar } = useContext(Context);

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        ...data,
    });

    const handleSubmit = () => {
        if (!state.meta.width) {
            snackbar.showError("Ширина разделителя не задана");
            return;
        }

        if (!state.meta.padding_top) {
            snackbar.showError("Отступ сверху не задан");
            return;
        }

        if (!state.meta.padding_bottom) {
            snackbar.showError("Отступ снизу не задан");
            return;
        }

        onSubmit(state);
    };

    return (
        <div className="DividerEdit">
            <FormLayout>
                <>
                    <Header style={{ marginLeft: 13 }}>
                        Ширина {state.meta.width}%
                    </Header>
                    <div style={{ margin: "0 26px" }}>
                        <Slider
                            step={1}
                            min={1}
                            max={100}
                            value={Number(state.meta.width)}
                            onChange={(value) => {
                                dispatch({
                                    type: "set-width",
                                    payload: value,
                                });
                            }}
                        />
                    </div>
                </>

                <>
                    <Header style={{ marginLeft: 13 }}>
                        Отступ сверху {state.meta.padding_top}px
                    </Header>

                    <div style={{ margin: "0 26px" }}>
                        <Slider
                            step={1}
                            min={5}
                            max={100}
                            value={Number(state.meta.padding_top)}
                            onChange={(value) => {
                                dispatch({
                                    type: "set-padding-top",
                                    payload: value,
                                });
                            }}
                        />
                    </div>
                </>

                <>
                    <Header style={{ marginLeft: 13 }}>
                        Отступ снизу {state.meta.padding_bottom}px
                    </Header>
                    <div style={{ margin: "0 26px" }}>
                        <Slider
                            step={1}
                            min={5}
                            max={100}
                            value={Number(state.meta.padding_bottom)}
                            onChange={(value) => {
                                dispatch({
                                    type: "set-padding-bottom",
                                    payload: value,
                                });
                            }}
                        />
                    </div>
                </>
                <FixedLayout vertical={"bottom"} filled>
                    <FormSubmitButton handleSubmit={handleSubmit} />
                </FixedLayout>
            </FormLayout>
        </div>
    );
};

export default DividerEdit;
