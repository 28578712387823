import { deepFreeze } from "./Helpers/Helpers";

const APPBACK_HOST = process.env.REACT_APP_APPBACK_HOST;
const SENLER_HOST = process.env.REACT_APP_SENLER_HOST

const USE_PROXY = true

const config = {
    senlerVKMiniAppCurrentVersion: '5.7.5',

    appbackHost: APPBACK_HOST,

    createWidgetUrl: `${APPBACK_HOST}/create`,

    getWidgetsUrl: `${APPBACK_HOST}/get-all`,

    updateWidgetUrl: `${APPBACK_HOST}/update`,

    updateNameWidgetUrl: `${APPBACK_HOST}/update-name`,

    discardWidgeUrl: `${APPBACK_HOST}/discard`,

    updateWidgetAudienceUrl: `${APPBACK_HOST}/update-audience`,

    uploadImageUrl: `${APPBACK_HOST}/image`,

    deleteWidgetUrl: `${APPBACK_HOST}/delete`,

    sortWidgetsUrl: `${APPBACK_HOST}/sort`,

    publishWidgetsUrl: `${APPBACK_HOST}/publish`,

    changeWidgetStatusUrl: `${APPBACK_HOST}/change-status`,

    uploadDocumentAsImageUrl: `${APPBACK_HOST}/document`,

    cloneWidgetUrl: `${APPBACK_HOST}/clone`,

    batchCloneWidgetUrl: `${APPBACK_HOST}/batch-clone`,

    copyToCommunityUrl: `${APPBACK_HOST}/widgets/copy/community`,

    shareUrl: `${APPBACK_HOST}/share/create-collection`,

    getShareCollectionUrl: `${APPBACK_HOST}/share/get-collection`,

    copyShareCollectionUrl: `${APPBACK_HOST}/share/copy-collection`,

    guideGetUrl: `${APPBACK_HOST}/guide/get`,

    guideUpdateUrl: `${APPBACK_HOST}/guide/update`,

    errorsUrl: `${APPBACK_HOST}/log/error`,

    getGlobalVariables: `${SENLER_HOST}/ajax/vkapp/GetGlobalVariables`,

    getLeadVariables: `${SENLER_HOST}/ajax/vkapp/GetLeadVariables`,

    setLeadVariables: `${SENLER_HOST}/ajax/vkapp/SetLeadVariables`,

    getLeadVariablesByNames: `${SENLER_HOST}/ajax/vkapp/GetLeadVariablesByNames`,

    /**
     * Files api
     */

    filesUploadImageUrl: `${APPBACK_HOST}/files/image`,

    filesUploadDocumentImageUrl: `${APPBACK_HOST}/files/document-image`,

    /**
     * Pages api urls
     */

    getPageUrl: `${APPBACK_HOST}/pages/get-one`,

    createPageUrl: `${APPBACK_HOST}/pages/create`,

    getGroupPages: `${APPBACK_HOST}/pages/get-all`,

    renamePageUrl: `${APPBACK_HOST}/pages/rename`,

    deletePagesUrl: `${APPBACK_HOST}/pages/delete`,

    addPageBlockUrl: `${APPBACK_HOST}/pages/add-block`,

    addTemplateUrl: `${APPBACK_HOST}/pages/add-template`,

    deleteBlockUrl: `${APPBACK_HOST}/pages/delete-block`,

    insertBlockUrl: `${APPBACK_HOST}/pages/insert-block`,

    changePageStatus: `${APPBACK_HOST}/pages/change-status`,

    changeBlockStatus: `${APPBACK_HOST}/pages/change-block-status`,

    sortBlocksUrl: `${APPBACK_HOST}/pages/sort-blocks`,

    updateBlockUrl: `${APPBACK_HOST}/pages/update-block`,

    updateBlockAudienceUrl: `${APPBACK_HOST}/pages/update-block-audience`,

    publishPageUrl: `${APPBACK_HOST}/pages/publish`,

    savePageStateUrl: `${APPBACK_HOST}/pages/save-state`,

    copyPageUrl: `${APPBACK_HOST}/pages/copy`,

    copyPageToOtherGroupUrl: `${APPBACK_HOST}/pages/copy-to-other-group`,

    leadUrl: `${APPBACK_HOST}/pages/save-lead`,

    leadTriggerUrl: `${APPBACK_HOST}/pages/save-lead-trigger`,

    getPageStatisticByDateRangeUrl: `${APPBACK_HOST}/pages/{page_id}/stat/intervals`,

    getSummaryPageStatisticByDateRange: `${APPBACK_HOST}/pages/{page_id}/stat/summary`,

    /**
     * Page statistic api urls
     */
    pageStatisticUrl: `${APPBACK_HOST}/stat/get`,

    pageStatisticHitUrl: `${APPBACK_HOST}/stat/hit`,

    pageStatisticGoalUrl: `${APPBACK_HOST}/stat/goal`,

    app_width: 680,

    max_app_height: 8050,

    max_landing_app_height: 10000,

    /**
     * Главный урл для операций с группами подписок
     */
    getMainAppHost() {
        if (USE_PROXY) {
            return APPBACK_HOST;
        }

        return SENLER_HOST;
    },

    getTypeApiDescription: (widget_type_api) => {
        switch (widget_type_api) {
            case "carousel":
                return {
                    desc: "Плитка с прямоугольными изображениями",
                    img: "carousel.svg",
                    imgType: {
                        real: "160x240",
                        upload: "480x720",
                    },
                };
            case "message":
                return {
                    desc: "Важное сообщение",
                    img: "message.svg",
                    imgType: {},
                };
            case "personal":
                return {
                    desc: "Персональное предложение",
                    img: "personal.svg",
                    imgType: {},
                };
            case "banners":
                return {
                    desc: "Обложки с акцией",
                    img: "banners.svg",
                    imgType: {
                        real: "510x128",
                        upload: "1530x384",
                    },
                };
            case "promo":
                return {
                    desc: "Плитка с квадратными изображениями",
                    img: "goods.svg",
                    imgType: {
                        real: "160x160",
                        upload: "480x480",
                    },
                };
            // Для обратной совместимости, чтобы не нарушить работу старых виджетов
            case "recommended":
                return {
                    desc: "Плитка с квадратными изображениями",
                    img: "goods.svg",
                    imgType: {
                        real: "160x160",
                        upload: "480x480",
                    },
                };
            case "feedbacks":
                return {
                    desc: "Отзывы",
                    img: "feedbacks.svg",
                    imgType: {
                        real: "50x50",
                        upload: "150x150",
                    },
                };
            case "list":
                return {
                    desc: "Список",
                    img: "feedbacks.svg",
                    imgType: {
                        real: "50x50",
                        upload: "150x150",
                    },
                };
            case "compact_list":
                return {
                    desc: "Компактный список",
                    img: "feedbacks.svg",
                    imgType: {
                        real: "50x50",
                        upload: "150x150",
                    },
                };
            case "clients":
                return {
                    desc: "Наши клиенты",
                    img: "clients.svg",
                    imgType: {
                        real: "50x50",
                        upload: "150x150",
                    },
                };
            case "pointSale":
                return {
                    desc: "Точки продажи",
                    img: "sale-points.svg",
                    imgType: {
                        real: "50x50",
                        upload: "150x150",
                    },
                };
            case "events":
                return {
                    desc: "Мероприятия",
                    img: "events.svg",
                    imgType: {
                        real: "50x50",
                        upload: "150x150",
                    },
                };
            case "community":
                return {
                    desc: "Меню сообщества",
                    img: "community-menu.svg",
                    imgType: {
                        real: "50x50",
                        upload: "150x150",
                    },
                };
            case "subscription":
                return {
                    desc: "Подписка на рассылку",
                    img: "subscription.svg",
                    imgType: {
                        real: "50x50",
                        upload: "150x150",
                    },
                };
            default:
                return {
                    desc: "Важное сообщение",
                    img: "message.svg",
                    imgType: {},
                };
        }
    },

    mainCountries: [
        { title: "Россия", id: 1 },
        { title: "Украина", id: 2 },
        { title: "Беларусь", id: 3 },
    ],

    widgetsTypeMap: [
        {
            type: "list",
            name: "Списки",
            includes: [
                "personal",
                "feedbacks",
                "pointSale",
                "events",
                "community",
                "subscription",
            ],
        },
        {
            type: "compact_list",
            name: "Компактные списки",
            includes: ["compact_list"],
        },
        {
            type: "tiles",
            name: "Плитки",
            includes: ["carousel", "promo", "recommended"],
        },
        {
            type: "cover_list",
            name: "Обложки",
            includes: ["banners"],
        },
        {
            type: "table",
            name: "Таблицы",
            includes: ["clients"],
        },
        {
            type: "text",
            name: "Сообщение",
            includes: ["message"],
        },
    ],

    widgetLength: {
        title: 100,
        more: 100,
        text_text: 200,
    },

    catalogTypes: {
        rows: {
            name: "Список",
            value: "rows",
        },
        icons: {
            name: "Список с иконками",
            value: "icons",
        },
        tiles: {
            name: "Плитка",
            value: "tiles",
        },
        single: {
            name: "Определенная подписная страница",
            value: "single",
        },
    },

    getCatalogType(type) {
        if (this.catalogTypes[type]) {
            return this.catalogTypes[type];
        } else {
            return {
                name: "Список",
                value: "rows",
            };
        }
    },
};

export default deepFreeze(config);
