import React, { useState, useEffect, useContext } from "react";
import {
    List,
    Cell,
    Search,
    Avatar,
    Div,
    Group,
    Footer,
    Spinner,
    platform,
    Platform,
} from "@vkontakte/vkui";
import ApiVk from "../../../includes/ApiVk";
import { Context } from "../../../context";
import { USER_TOKEN_REQUEST_FAILED } from "../../../includes/ErrorMessages";

const UsersSelect = (props) => {
    const { sex, users, setUsers, onSelect } = props;

    const { popout } = useContext(Context);

    const api = ApiVk.getInstance(null);

    const [selected, setSelected] = useState(
        props.selected && props.selected.length > 0 ? [...props.selected] : []
    );
    const [search, setSearch] = useState("");
    const [requestTimeout, setRequestTimeout] = useState(null);
    const [bottomText, setBottomText] = useState("");
    const [isPending, setIsPending] = useState(false);

    const searchUsers = async (params) => {
        setIsPending(true);

        let result = await api.searchUsers(params);

        if (result.response && result.response.items) {
            setUsers(result.response.items);
            if (result.response.items.length <= 0) {
                setBottomText("По вашему запросу ничего не найдено");
            }
        } else if (result.result === "error") {
            popout.error(USER_TOKEN_REQUEST_FAILED);
        } else {
            console.log(result);
            setBottomText("Произошла ошибка. Попробуйте позднее.");
        }

        setIsPending(false);
    };

    useEffect(() => {
        let query = search.trim();

        if (requestTimeout) {
            clearTimeout(requestTimeout);
        }

        if (query.length < 2) {
            setUsers([]);
            setBottomText("");
            return;
        }

        /**
         * Откладываем запрос чтобы предотвратить отправку на каждое нажатие кнопки
         */
        const newTimeout = setTimeout(() => {
            searchUsers({ query, sex });
        }, 1000);

        setRequestTimeout(newTimeout);
    }, [search]);

    useEffect(() => {
        const input: any = document.querySelector(".vkuiSearch__input");
        if (input && platform() === Platform.ANDROID) {
            input.focus();
        }
    }, []);

    const addUser = (user) => {
        const newSelected = [...selected];
        newSelected.push(user);
        setSelected(newSelected);
        onSelect(newSelected);
    };

    const removeUser = (user) => {
        const newSelected = [...selected];
        const i = newSelected.findIndex((u) => u.id === user.id);
        newSelected.splice(i, 1);
        setSelected([...newSelected]);
        onSelect(newSelected);
    };

    return (
        <>
            <Search
                autoFocus={false}
                onChange={(e) => {
                    setSearch(e.currentTarget.value);
                }}
                style={{ background: "transparent" }}
                after=""
            />
            <Group title="Пользователи" className="mt-0">
                {isPending && (
                    <Div>
                        {" "}
                        <Spinner size="medium" />{" "}
                    </Div>
                )}
                {users.length > 0 && (
                    <List>
                        {users.map((item) => {
                            const isSelected =
                                selected.findIndex((o) => o.id === item.id) >
                                -1;
                            return (
                                <Cell
                                    key={item.id}
                                    checked={isSelected}
                                    onChange={() => {
                                        if (isSelected) removeUser(item);
                                        else addUser(item);
                                    }}
                                    mode="selectable"
                                    before={<Avatar src={item.photo_50} />}
                                >
                                    {`${item.first_name} ${item.last_name}`}
                                </Cell>
                            );
                        })}
                    </List>
                )}
                {bottomText !== "" && (
                    <Footer style={{ height: 40 }}>{bottomText}</Footer>
                )}
            </Group>
        </>
    );
};

export default UsersSelect;
