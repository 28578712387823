import React, { useState, useContext, useRef, useEffect } from "react";
import SubHeader from "../SubHeader";
import { FormLayout, Input, Button, FormItem, Div } from "@vkontakte/vkui";
import WidgetService from "../../includes/Services/WidgetService";
import { Context } from "../../context";
import { Analytics, WIDGET_CREATE } from "../../includes/Metrics/Analytics";

const WidgetSetName = (props) => {
    const { snackbar, widgets, resizer } = useContext(Context);

    const { widget } = props;

    const [text, setText] = useState(widget.name ? widget.name : "");
    const [bottomText, setBottomText] = useState("");
    const [isValue, setIsValue] = useState(true);

    const isEdit = props.edit === true ? props.edit : false;

    const inputElem = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            resizer.setHeight();
        }, 0);
    }, []);

    useEffect(() => {
        if (inputElem && inputElem.current) {
            inputElem.current.focus();
        }
    }, [inputElem]);

    const getNewWidget = async () => {
        if (text.trim() === "") {
            setBottomText("Заполните наименование виджета");
            setIsValue(false);
            return false;
        }

        const response = await WidgetService.create({
            type: props.widget.type,
            type_api: props.widget.type_api,
            name: text,
            group_id: props.group.vk_group_id,
        });

        if (response.result === "error") {
            snackbar.showError(response.message);
        } else if (response.result === "success") {
            Analytics.goal(WIDGET_CREATE);
            props.onWidgetChange(response.response);
        }
    };

    const handlerEditName = async () => {
        if (text.trim() === "") {
            setBottomText("Заполните наименование виджета");
            setIsValue(false);
            return false;
        }

        const response = await WidgetService.updateName(widget.id, text);

        if (response.result === "success") {
            snackbar.showSuccess("Виджет переименован");
            props.onBack();
            widget.name = text;
            widgets.replaceName(widget);
        } else {
            snackbar.showError(response.message);
        }
    };

    return (
        <>
            <SubHeader onBack={props.onBack}>
                {widget.name ? widget.name : widget.title}
            </SubHeader>
            <FormLayout>
                <FormItem
                    top="Наименование"
                    status={isValue ? null : "error"}
                    bottom={bottomText}
                >
                    <Input
                        type="text"
                        placeholder="Введите название"
                        defaultValue={text}
                        getRef={inputElem}
                        onChange={(e) => {
                            setText(e.target.value);
                        }}
                    />
                </FormItem>
                <Div>
                    <Button
                        onClick={() =>
                            isEdit ? handlerEditName() : getNewWidget()
                        }
                        size="l"
                        stretched
                    >
                        {isEdit ? "Сохранить" : "Продолжить"}
                    </Button>
                </Div>
            </FormLayout>
        </>
    );
};

export default WidgetSetName;
