import { isValidVkVideoUrl, isVkVideoIframe } from "./Helpers";

export function isYouTubeUrl(url: string): boolean {
    let match = url.match(/^(https?:\/\/)?((www\.)?youtube\.com)\/.+$/gi);
    return match && match.length > 0;
}

export function isYouTubeShortUrl(url: string): boolean {
    let match = url.match(/^(https?:\/\/)?((www\.)?youtu\.?be)\/.+$/gi);
    return match && match.length > 0;
}

export function isVimeoUrl(url: string): boolean {
    let match = url.match(/^https:\/\/(www\.)?vimeo.com\/.+$/gi);
    return match && match.length > 0;
}

export function isUrl(url: string): boolean {
    let match = url.match(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)\/.*/gi
    );
    return match && match.length > 0;
}

export function isValidBannerUrl(url: string): boolean {
    return (
        isYouTubeUrl(url) ||
        isYouTubeShortUrl(url) ||
        isVimeoUrl(url) ||
        isUrl(url)
    );
}

export function isValidYouTubeVideoUrl(url: string): boolean {
    let match = url.match(
        /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/gi
    );

    if (!match) {
        return false;
    }

    return match && match.length > 0;
}

export default function resolveBannerUrl(url: string): any {
    let vkey = "";
    let vtype = 0;
    let btype = "";
    let burl = "";

    if (isYouTubeUrl(url)) {
        // Если ссылка на основной ютуб
        let match = url.match(/(\Wv=([\w-]+)|\/embed\/([\w-]+))/gi);
        if (match && match.length > 0) {
            let key = match[0].replace("?v=", "").replace("/embed/", "");
            vkey = key;
            vtype = 1;
            btype = "youtube";
            burl = `https://www.youtube.com/embed/${key}?autoplay=0&rel=0&controls=0&showinfo=0&fs=0&loop=1&playlist=${key}`;
        }
    } else if (isYouTubeShortUrl(url)) {
        // Если ссылка на сокращенный
        let match = url.match(/\/youtu\.be\/([\w-]+)/gi);
        if (match && match.length > 0) {
            let key = match[0].replace("/youtu.be/", "");
            vkey = key;
            vtype = 1;
            btype = "youtube";
            burl = `https://www.youtube.com/embed/${key}?autoplay=0&rel=0&controls=0&showinfo=0&fs=0&loop=1&playlist=${key}`;
        }
    } else if (isVimeoUrl(url)) {
        // Если ссылка на Vimeo
        let match = url.match(/(videos|video|channels|\.com)\/([\d]+)/gi);
        if (match && match.length > 0) {
            let key = match[0]
                .replace("channels/", "")
                .replace(".com/", "")
                .replace("videos/", "")
                .replace("video/", "");
            vkey = key;
            vtype = 2;
            btype = "vimeo";
            burl = `https://player.vimeo.com/video/${key}?autoplay=0&loop=1`;
        }
    } else if (isVkVideoIframe(url) || isValidVkVideoUrl(url)) {
        vkey = url;
        vtype = 3;
        btype = "vk_video";
        burl = url;
    } else {
        // Все остальное расцениваем как ссылку на изображение
        vkey = url;
        vtype = 0;
        btype = "img";
        burl = url;
    }

    return {
        video_key: vkey,
        video_type: vtype,
        banner_url: burl,
        banner_type: btype,
        raw_url: url,
    };
}
