import React, { useEffect, useState, useContext } from "react";
import {
    PanelSpinner,
    Root,
    View,
    Panel,
    PanelHeader,
    PanelHeaderBack,
    SplitLayout,
} from "@vkontakte/vkui";
/**
 * Модули для аналитики
 */
import { YMInitializer } from "react-yandex-metrika";
import ReactGA from "react-ga4";
import VkPixelCommon from "../includes/Metrics/VkPixelCommon";
import ReactPixel from "../includes/Metrics/ReactPixel";
import TagManager from "react-gtm-module";
import { Analytics } from "../includes/Metrics/Analytics";

import container from "../container";
import { Context } from "../context";
import ModalController from "../Controllers/ModalController";
import PageView from "../Сomponents/Pages/PageView";
import PageToEntity from "../includes/Mappings/Pages/Page";

import PageStatisticService from "../includes/Services/PageStatisticService";
import { PageItem } from "../includes/Structures";

import { PAGES_VIEW_COMMON } from "../includes/Metrics/Analytics";
import Payments from "../Сomponents/Payments";
import { setHash } from "../includes/Helpers/Helpers";

const logger = container.get("logger");
const PagesService = container.get("PagesService");
const LaunchParams = container.get("LaunchParams");

const PAGE_RECONNECT_COUNT = 3; // Количество попыток переподключения, если при получении страницы возникает ошибка
const PAGE_STATUS = 3;

/**
 * Компонент роута страницы
 * - Логика данных и подготовка, асихронные запросы, метрики и тд.
 */

const PagesItemRoute = (props) => {
    const { urlParams, popout, mainPopout, user } = props;
    const { currentModal, pages, groupSiteData, resizer, location } =
        useContext(Context);

    const [isPendingPage, setIsPendingPage] = useState(false);
    const [page, setPage] = useState<PageItem | null>(null);

    const [ymHasInit, setYmHasInit] = useState(false);
    const [gaHasInit, setGaHasInit] = useState(false);
    const [tagManagerHasInit, setTagManagerHasInit] = useState(false);
    const [facebookPixelHasInit, setFacebookPixelHasInit] = useState(false);

    const [pageStatus, setPageStatus] = useState<
        "ok" | "disabled" | "not_found" | "fatal"
    >("ok");

    const pageIdForWebView = pages.getPageIdForWebView();

    useEffect(() => {
        if (urlParams.params.page) {
            getPage(urlParams.params.page);
        } else {
            throw new Error("Page not set");
        }
    }, [pageIdForWebView]);

    useEffect(() => {
        const role = urlParams.params.vk_viewer_group_role;

        if (page) {
            if (
                role !== "admin" &&
                role !== "moderator" &&
                (page.status === 0 || page.status === 1)
            ) {
                Analytics.goal(PAGES_VIEW_COMMON, "pages", { pageId: page.id });
            }
        }
    }, [page]);

    let reconnectCount = 0;

    /**
     * Получим данные для страницы - только блоки
     * @param page_id
     */
    const getPage = async (page_id: string) => {
        setIsPendingPage(true);

        const response = await PagesService.getPage(page_id);

        if (response.result === "success") {
            if (response.page_data) {
                if (response.page_data.status === 3) {
                    setPageStatus("disabled");
                } else if (response.page_data.status === 2) {
                    setPageStatus("not_found");
                }

                setPage(PageToEntity(response.page_data));
            }

            if (response.group_data && response.group_data.success) {
                groupSiteData.set(response.group_data.group);
                groupSiteData.setHash(response.group_data.hash);

                if (response.group_data.items.length > 0) {
                    const subs = {};
                    response.group_data.items.forEach((s) => {
                        subs[s.subscription_id] = s;
                    });
                    pages.setLandingSubscriptions(subs);
                }
            }

            setIsPendingPage(false);

            if (
                response.hit_data &&
                response.page_data.status !== PAGE_STATUS
            ) {
                pages.setHitData(response.hit_data);
                saveHit(response.hit_data);
            }
        } else {
            if (reconnectCount < PAGE_RECONNECT_COUNT) {
                reconnectCount += 1;
                setTimeout(() => {
                    getPage(page_id);
                }, 1000);
            } else {
                setPageStatus("not_found");
                setIsPendingPage(false);

                logger.error(
                    {
                        message: `${
                            response.message
                                ? response.message
                                : "Ошибка при получении страницы"
                        } -> ${JSON.stringify(response)}`,
                        code: 9102,
                    },
                    "pages_error",
                    "PagesItemRoute.tsx"
                );
            }
        }
    };

    const saveHit = async (hitData: any) => {
        await PageStatisticService.hit(hitData);
    };

    const groupData = groupSiteData.get();

    const initYMCounter = () => {
        return (
            <YMInitializer
                accounts={[parseInt(groupData.ya_metrica)]}
                version="2"
                options={{
                    webvisor: true,
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    trackHash: true,
                    params: {
                        utm_source: urlParams.params.utm_source,
                        utm_medium: urlParams.params.utm_medium,
                        utm_campaign: urlParams.params.utm_campaign,
                        utm_term: urlParams.params.utm_term,
                        utm_content: urlParams.params.utm_content,
                        rs: urlParams.params.rs,
                        roistat: urlParams.params.roistat,
                        delivery_id: urlParams.params.d,
                        user_id: user.id,
                        group_id: groupData.vk_group_id,
                        page_id: urlParams.params.page,
                    },
                }}
            />
        );
    };

    const initGoogleAnalytics = () => {
        setGaHasInit(true);
        ReactGA.initialize([
            {
                trackingId: groupData.ga_metrica,
                gaOptions: {
                    cookieFlags: "SameSite=None; Secure",
                },
            },
        ]);
        setTimeout(() => {
            ReactGA.send({ hitType: "pageview", page: window.location.href });
            ReactGA.event({
                category: "Page",
                action: "View",
                label: urlParams.params.page,
            });
        }, 2000);
    };

    const initFacebookPixel = () => {
        setFacebookPixelHasInit(true);
        ReactPixel.init(
            groupData.fb_pixel,
            {},
            {
                autoConfig: true,
                debug: false,
            },
            true,
            urlParams.params.page
        );
        setTimeout(() => {
            ReactPixel.trackCustom(`page_view`);
            ReactPixel.trackCustom(`page_view_${urlParams.params.page}`);
        }, 2000);
    };

    const initTagManager = () => {
        setTagManagerHasInit(true);
        TagManager.initialize({
            gtmId: groupData.google_tag,
        });

        Analytics.push({
            event: "pageview",
            pagePath: window.location.pathname,
            pageTitle: page.name
                ? page.name
                : `Промо страницы ${urlParams.params.page}`,
        });

        const utms = LaunchParams.getUtms();

        Analytics.push({
            event: `page_view`,
            vk_user_id: LaunchParams.params.vk_user_id,
            ...utms,
        });

        Analytics.push({
            event: `page_view_${urlParams.params.page}`,
            vk_user_id: LaunchParams.params.vk_user_id,
            ...utms,
        });
    };

    const modal = (
        <ModalController
            UrlParams={urlParams}
            mainPopout={mainPopout}
            setCurrentModal={currentModal.set}
            currentModal={currentModal.get()}
        />
    );

    if (isPendingPage) {
        return (
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <PanelSpinner height={120} />
            </div>
        );
    }

    if (page) {
        const yaMetrica = groupData.ya_metrica;
        const gaMetrica = groupData.ga_metrica;
        const vkPixel = groupData.vk_pixel;
        const fbPixel = groupData.fb_pixel;
        const googleTag = groupData.google_tag;

        if (yaMetrica && !ymHasInit) {
            setYmHasInit(true);
            setTimeout(() => {
                if (typeof window.ym !== "undefined") {
                    window.ym(yaMetrica, "reachGoal", "page_view");
                    window.ym(
                        yaMetrica,
                        "reachGoal",
                        `page_view_${urlParams.params.page}`
                    );
                }
            }, 2000);
        }

        if (gaMetrica && !gaHasInit && pageStatus === "ok") {
            initGoogleAnalytics();
        }

        if (vkPixel && pageStatus === "ok") {
            setTimeout(() => {
                VkPixelCommon.event(vkPixel, "page_view");
            }, 50);

            setTimeout(() => {
                VkPixelCommon.event(vkPixel, `page_view_${urlParams.params.page}`);
            }, 100);

            setTimeout(() => {
                VkPixelCommon.goal(vkPixel, "page_view");
            }, 150);
        }

        if (fbPixel && !facebookPixelHasInit && pageStatus === "ok") {
            initFacebookPixel();
        }

        if (googleTag && !tagManagerHasInit && pageStatus === "ok") {
            initTagManager();
        }
    }

    return (
        <SplitLayout popout={popout} modal={modal}>
        <Root activeView={`page-${location.getView()}`}>
            <View
                id="page-main"
                activePanel="page_main"
            >
                <Panel id="page_main">
                    {groupData.ya_metrica &&
                        pageStatus === "ok" &&
                        initYMCounter()}

                    <PageView
                        launchParamsProvider={LaunchParams}
                        status={pageStatus}
                        pageItem={page}
                        isPreview={false}
                        resizer={resizer}
                        platform={LaunchParams.params.vk_platform}
                    />
                </Panel>
            </View>

            {/* Отображение страницы со способами оплаты */}
            <View
                id="page-pay-methods"
                className="small--header"
                activePanel="page-pay-methods-list"
            >
                <Panel id="page-pay-methods-list">
                    <PanelHeader
                        before={
                            <PanelHeaderBack
                                onClick={() => {
                                    setHash({
                                        view: "main",
                                        list: "",
                                    });
                                }}
                            />
                        }
                        fixed
                    >
                        Выберите способ оплаты
                    </PanelHeader>
                    <Payments />
                </Panel>
            </View>
        </Root>
        </SplitLayout>
    );
};

export default PagesItemRoute;
