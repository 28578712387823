import React, { useState, useRef, useEffect } from "react";
import { FormLayout, Input, Button, FormItem } from "@vkontakte/vkui";

type PagesNameFormProps = {
    value?: string | "";
    onSubmit: Function;
};

const PagesNameForm = (props: PagesNameFormProps) => {
    const { onSubmit } = props;

    const [value, setValue] = useState(props.value ? props.value : "");
    const [hasError, setHasError] = useState(false);
    const [bottomText, setBottomText] = useState("");

    const inputElem = useRef(null);

    useEffect(() => {
        if (inputElem && inputElem.current) {
            inputElem.current.focus();
        }
    }, [inputElem]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (value.trim() === "") {
            setHasError(true);
            setBottomText("Заполните название");
            return false;
        }

        onSubmit(value.trim());
    };

    const hanldeChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <FormLayout onSubmit={handleSubmit}>
            <FormItem
                status={hasError ? "error" : "default"}
                bottom={bottomText}
            >
                <Input
                    getRef={inputElem}
                    placeholder="Введите название"
                    maxLength={300}
                    name="name"
                    value={value}
                    onChange={hanldeChange}
                />
            </FormItem>

            <FormItem>
                <Button type="submit" stretched={true} size="l">
                    Продолжить
                </Button>
            </FormItem>
        </FormLayout>
    );
};

export default PagesNameForm;
