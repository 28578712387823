import React, { ReactElement, useContext, useState, useEffect } from "react";
import { Alert } from "@vkontakte/vkui";
import { UNKNOWN_ERROR } from "../../../includes/ErrorMessages";
import { Context } from "../../../context";
import { Icon36Replay } from "@vkontakte/icons";

interface PublishPayload {
    result: string;
    message: string;
    isTimer: boolean;
    timeleft: number;
}

interface AlertPublish {
    payload: PublishPayload;
    timerCallback: Function;
}

const AlertPublish = (props: AlertPublish): ReactElement => {
    const { popout } = useContext(Context);

    const { payload, timerCallback } = props;

    const [timeleft, setTimeleft] = useState(0);

    useEffect(() => {
        if (payload.isTimer && payload.timeleft > 0) {
            initCountDown();
        }
    }, []);

    const initCountDown = () => {
        let delay = 1000;
        let timeleftIn = payload.timeleft;

        setTimeleft(timeleftIn);

        let timerId = setTimeout(function tick() {
            timeleftIn--;
            setTimeleft(timeleftIn);

            if (timeleftIn > 0) {
                timerId = setTimeout(tick, delay);
            } else {
                clearTimeout(timerId);
            }
        }, delay);
    };

    let title: string | ReactElement = "Ошибка";
    let text: string | ReactElement = payload.message;

    if (payload.isTimer) {
        text =
            timeleft > 0 ? (
                <div
                    style={{
                        marginTop: 3,
                        textAlign: "center",
                        marginBottom: 12,
                    }}
                >
                    Изменить виджет можно через
                    <div style={{ marginTop: 3, textAlign: "center" }}>
                        <b style={{ fontSize: 16 }}>{timeleft}</b>
                    </div>
                </div>
            ) : null;
    }

    if (payload.isTimer) {
        title = "Подождите";
    } else if (payload.result === "error") {
        title = "Ошибка";
    } else if (payload.result === "cancel") {
        title = "Отмена";
    } else {
        text = UNKNOWN_ERROR;
    }

    return (
        <Alert
            actions={[{ title: "Закрыть", autoClose: true, mode: "cancel" }]}
            onClose={() => {
                popout.close();
            }}
        >
            <h2 style={{ textAlign: "center" }}>{title}</h2>
            <div>{text}</div>
            {payload.isTimer && (
                <button
                    style={{
                        opacity: timeleft > 0 ? 0.5 : 1,
                        transition: "all 200ms ease",
                    }}
                    className="btn-link btn-link_wide AlertPublish__repeat-btn"
                    onClick={() => {
                        if (timeleft > 0) {
                            return false;
                        } else {
                            timerCallback && timerCallback();
                        }
                    }}
                >
                    <Icon36Replay width={20} height={20} />
                    <span>Повторить</span>
                </button>
            )}
        </Alert>
    );
};

export default AlertPublish;
