const initialState = {
    title: "",
    title_url: "",
    more: "",
    more_url: "",
    rows: [
        {
            img: "",
            img_vk: "",
            title: "",
            descr: "",
            url: "",
            button: "",
            button_url: "",
        },
    ],
};

export default initialState;
