import React, { useState, useEffect } from "react";
import { Link, Button, Cell } from "@vkontakte/vkui";
import { EMPTY_FUNC } from "../../includes/Constants";
import { Icon16Clear, Icon16Down, Icon16Up, Icon24Add, Icon32Gallery } from "@vkontakte/icons";

const icons = {
    gallery: () => <Icon32Gallery style={{ color: "var(--vkui--color_text_accent)" }} />,
    add: () => <Icon24Add style={{ color: "var(--vkui--color_icon_secondary)" }} />,
};

const ItemBannersCard = (props) => {
    const { edit } = props;

    const [errors, setErrors] = useState({
        img: false,
        title: false,
    });

    const nameBtn = props.button
        ? props.button.length > 10
            ? `${props.button.slice(0, 10)}...`
            : props.button
        : "+ Кнопка";

    useEffect(() => {
        if (props.hasError !== undefined) {
            setErrors(props.hasError);
        } else {
            setErrors({
                img: false,
                title: false,
            });
        }
    }, [props.hasError]);

    let img = props.img;
    if (img) {
        if (icons[img]) img = icons[img]();
    } else img = icons.gallery();

    return (
        <>
            <div className="ItemBannersCard">
                {props.edit && (
                    <div
                        className="tools d-flex flex-space-between flex-align-center"
                        style={{ paddingBottom: "10px" }}
                    >
                        <Icon16Up
                            onClick={props.onLeftClick}
                            className="c-ptr"
                            width={16}
                            height={16}
                            style={{
                                color: props.canMoveLeft
                                    ? "var(--vkui--color_text_accent)"
                                    : "var(--vkui--color_icon_secondary)",
                            }}
                        />
                        {props.length > 1 && (
                            <Icon16Clear
                                onClick={props.onRemoveClick}
                                className="c-ptr"
                                style={{ color: "var(--vkui--color_accent_red)" }}
                            />
                        )}
                        <Icon16Down
                            onClick={props.onRightClick}
                            className="c-ptr"
                            width={16}
                            height={16}
                            style={{
                                color: props.canMoveRight
                                    ? "var(--vkui--color_text_accent)"
                                    : "var(--vkui--color_icon_secondary)",
                            }}
                        />
                    </div>
                )}
                <div
                    onClick={edit === true ? EMPTY_FUNC : props.onCoverClick}
                    className={
                        errors.img
                            ? "ItemBannersCard-img c-ptr text-center bg-image ItemErrors"
                            : "ItemBannersCard-img c-ptr text-center bg-image "
                    }
                    style={
                        props.img && props.img !== "add"
                            ? { backgroundImage: `url(${props.img})` }
                            : null
                    }
                >
                    <div className="d-inline-block va-middle">
                        {typeof img === "string" ? "" : img}
                    </div>
                </div>
                {!props.imgOnly && (
                    <>
                        <Cell
                            className="ItemBannersCard-content"
                            hasHover={false}
                            after={
                                <Button
                                    onClick={
                                        edit === true
                                            ? EMPTY_FUNC
                                            : props.onButtonClick
                                    }
                                    mode="outline"
                                    style={
                                        props.hasErrorButton
                                            ? {
                                                  color: "var(--vkui--color_accent_red)",
                                                  border: "1px solid var(--vkui--color_accent_red)",
                                              }
                                            : null
                                    }
                                    stretched
                                >
                                    {nameBtn}
                                </Button>
                            }
                            disabled
                        >
                            <div
                                onClick={
                                    edit === true
                                        ? EMPTY_FUNC
                                        : props.onTitleClick
                                }
                            >
                                <Link
                                    style={
                                        errors.title || props.hasErrorUrl
                                            ? { color: "var(--vkui--color_accent_red)" }
                                            : null
                                    }
                                >
                                    {props.title ? props.title : "+ Заголовок"}
                                </Link>
                                <div
                                    style={
                                        props.hasErrorDescr
                                            ? { color: "var(--vkui--color_accent_red)" }
                                            : null
                                    }
                                    className="descr"
                                >
                                    {props.description
                                        ? props.description
                                        : "+ Описание"}
                                </div>
                            </div>
                        </Cell>
                    </>
                )}
            </div>
        </>
    );
};

export default ItemBannersCard;
