import React, { useState, useEffect } from "react";
import { Tabs, TabsItem } from "@vkontakte/vkui";
import ProductForm from "./ProductForm/ProductForm";
import ProductSelect from "./ProductSelect";
import { BlockButtonData } from "../../../../../includes/Structures";

type ModalProductProps = {
    isEdit: boolean;
    name: string;
    text: string;
    category: string;
    price: string;
    price_old: string;
    imgUrl: string;
    button: BlockButtonData;
    vk_group_id: number;
    callback: Function;
    close: Function;
    subscriptions: any[];
    block_id: string;
    index: number;
    addedProducts?: number[];
};

/**
 * Модальное окно добавления\редактирования товара для блоков промо-страниц
 */
const ModalProduct = (props: ModalProductProps) => {
    const [activeTab, setActiveTab] = useState("form");

    const {
        callback,
        close,
        subscriptions,
        block_id,
        index,
        addedProducts = [],
    } = props;

    const handleSubmit = (data) => {
        if (!data.button.id) {
            data.button.id = `p${block_id}${index}`;
        }

        callback(data);

        close();
    };

    const contents = {
        form: {
            render: () => (
                <ProductForm
                    subscriptions={subscriptions}
                    {...props}
                    onSubmit={handleSubmit}
                />
            ),
        },
        load: {
            render: () => (
                <ProductSelect
                    vk_group_id={props.vk_group_id}
                    addedProducts={addedProducts}
                    onSelect={handleSubmit}
                />
            ),
        },
    };


    return (
        <div className="ModalProduct">
            <Tabs>
                <TabsItem
                    onClick={() => {
                        setActiveTab("form");
                    }}
                    selected={activeTab === "form"}
                >
                    Редактировать
                </TabsItem>
                <TabsItem
                    onClick={() => {
                        setActiveTab("load");
                    }}
                    selected={activeTab === "load"}
                >
                    Товары сообщества
                </TabsItem>
            </Tabs>

            {contents[activeTab].render()}
        </div>
    );
};

export default ModalProduct;
