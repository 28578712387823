import React from "react";

const LeadButtonNotify = () => {
    return (
        <div className="subhead">
            Дополнительно у пользователя будет запрошено разрешение на получение
            сообщений от сообщества. Событие будет направлено в триггеры
        </div>
    );
};

export default LeadButtonNotify;
