import React, { useContext, useState } from "react";
import PagesNameForm from "../Сomponents/Forms/PagesNameForm/PagesNameForm";
import { Context } from "../context";
import container from "../container";
import { navigateToPage } from "../includes/Helpers/HelpersPages";
import PageToEntity from "../includes/Mappings/Pages/Page";
import PagesTemplates from "../Сomponents/Pages/PagesTemplates";
import {
    Analytics,
    PAGES_CREATE,
    PAGES_CREATE_EMPTY,
    PAGES_CREATE_TEMPLATE,
} from "../includes/Metrics/Analytics";
import FadeIn from "react-fade-in";

const PagesCreateController = () => {
    const PagesService = container.get("PagesService");
    const LaunchParams = container.get("LaunchParams");

    const { popout, pages, currentModal } = useContext(Context);

    const [pageCreated, setPageCreated] = useState(null);

    /**
     * Обработка формы ввода названия страницы
     * @param value
     */
    const handleCreateFormSubmit = async (value) => {
        popout.loading();

        const response = await PagesService.createPage({
            name: value,
        });

        popout.close();

        if (response.result === "success") {
            let page = PageToEntity(response.data);

            pages.addPage(page, () => {
                pages.setActivePageId(response.data.id);
                setPageCreated(page);
            });
        } else {
            popout.error(response.message);
        }
    };

    /**
     * Добавление шаблона к новой странице
     * @param template
     */
    const addTemplate = async (template) => {
        popout.loading();

        const template_response = await PagesService.addTemplate(
            pageCreated.id,
            template.blocks_edit
        );

        if (template_response.result !== "success") {
            popout.error(template_response.message);
            return false;
        }

        popout.close();

        pages.updatePageBlocks(
            pageCreated.id,
            template_response.data.blocks_edit,
            () => {
                const states =
                    template_response.data.states &&
                    template_response.data.states.length
                        ? template_response.data
                        : [];
                pages.setAvailablePageStates(states, () => {
                    navigateToPage(pageCreated.id);
                });
            }
        );
    };

    /**
     * Обработка формы выбора шаблона страницы
     * @param template
     */
    const handleSelectTemplate = (template) => {
        Analytics.goal(PAGES_CREATE, "pages");

        // Если выбор чистой страницы
        if (template.blocks_edit.length === 0) {
            Analytics.goal(PAGES_CREATE_EMPTY, "pages");
            pages.setAvailablePageStates([], () => {
                navigateToPage(pageCreated.id);
            });
        } else {
            // Если выбор шаблона
            currentModal.set({
                id: "landing-template",
                args: {
                    template: template,
                    onConfirm: () => {
                        currentModal.close();
                        Analytics.goal(PAGES_CREATE_TEMPLATE, "pages");
                        addTemplate(template);
                    },
                },
            });
        }
    };

    if (pageCreated) {
        return (
            <div key="pages-templates">
                <FadeIn delay={200}>
                    <PagesTemplates
                        platform={LaunchParams.params.vk_platform}
                        useDefault={true}
                        mode="list"
                        onSelect={handleSelectTemplate}
                    />
                </FadeIn>
            </div>
        );
    }

    return (
        <FadeIn delay={200}>
            <PagesNameForm onSubmit={handleCreateFormSubmit} />
        </FadeIn>
    );
};

export default PagesCreateController;
