import React, { useState, useContext } from "react";
import { Search, List, Cell, Caption } from "@vkontakte/vkui";
import { Context } from "../../context";
import PagesActionsController from "../../Controllers/PageActionsController";
import {
    getViewCountText,
    pageHasUnpublishedState,
} from "../../includes/Helpers/HelpersPages";
import EmptyPagesStub from "../Elements/Stubs/EmptyPagesStub";
import { setHash } from "../../includes/Helpers/Helpers";
import WithTooltip from "../hoc/WithTooltip";
import { Icon24MoreHorizontal, Icon24Write } from "@vkontakte/icons";

interface PagesListProps {
    pagesProvider: any;
}

const PagesList = (props: PagesListProps) => {
    const { popout, urlParams } = useContext(Context);

    const { pagesProvider } = props;

    const [search, setSearch] = useState("");
    let pages = pagesProvider.getAll();

    const vk_platform = urlParams.get().params.vk_platform;

    const renderPages = () => {
        if (search.trim()) {
            pages = pages.filter((p) => {
                if (
                    search.trim() === "" ||
                    p.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                )
                    return true;
                return false;
            });
        }

        return pages.map((page) => {
            const hasUnpublishedState = pageHasUnpublishedState(page);
            return (
                <Cell
                    key={page.id}
                    after={
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {hasUnpublishedState && (
                                <span style={{ marginRight: 6 }}>
                                    <WithTooltip
                                        tooltipContent={
                                            <span>
                                                Есть неопубликованные изменения
                                            </span>
                                        }
                                    >
                                        <Icon24Write
                                            width={18}
                                            height={18}
                                            fill="var(--vkui--color_text_secondary)"
                                        />
                                    </WithTooltip>
                                </span>
                            )}
                            <Icon24MoreHorizontal
                                fill={"var(--vkui--color_text_accent)"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    renderItemContextPopout(page);
                                }}
                            />
                        </div>
                    }
                >
                    <div
                        onClick={() => {
                            setHash({
                                list: "pages",
                                view: "pages-edit",
                                panel: "item",
                                page_id: page.id,
                            });

                            pagesProvider.setAvailablePageStates(page.states);
                        }}
                    >
                        <div>{page.name}</div>
                        <div>
                            <Caption
                                level="1"
                                weight="1"
                                style={{
                                    color: "var(--vkui--color_text_secondary)",
                                }}
                            >
                                <span
                                    className="PagesList_desktop-status"
                                    style={{
                                        background:
                                            page.status === 3
                                                ? "#d9534f"
                                                : "#5cb85c",
                                        marginRight: 6,
                                    }}
                                />
                                {getViewCountText(page.statisticSummary.hits)}
                            </Caption>
                        </div>
                    </div>
                </Cell>
            );
        });
    };

    const renderItemContextPopout = (item) => {
        popout.open(
            <PagesActionsController
                mode={"context"}
                item={item}
                isEdit={false}
            />,
            "context"
        );
    };

    return (
        <>
            <Search
                onChange={(e) => {
                    setSearch(e.currentTarget.value);
                }}
                after=""
            />
            {pages.length > 0 ? (
                <div
                    className="PagesList"
                    style={{
                        marginBottom: vk_platform === "mobile_iphone" ? 60 : 0,
                    }}
                >
                    <List>{renderPages()}</List>
                </div>
            ) : (
                <EmptyPagesStub />
            )}
        </>
    );
};

export default PagesList;
