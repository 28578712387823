import { isValidLink } from "../../../../../includes/Helpers/Helpers";
import { BlockButtonData } from "../../../../../includes/Structures";

type validationResultType = {
    status: boolean;
    errors: any;
};

export default function validator(state: BlockButtonData) {
    const result: validationResultType = {
        status: true,
        errors: {},
    };

    if (state.text.trim() === "") {
        result.status = false;
        result.errors.text = "Необходимо заполнить";
    } else {
        delete result.errors.text;
    }

    if (state.action === "url") {
        if (!state.url) {
            result.status = false;
            result.errors.url = "Необходимо заполнить";
            return result;
        }

        if (state.url.trim() === "") {
            result.status = false;
            result.errors.url = "Необходимо заполнить";
        } else {
            delete result.errors.url;
        }

        if (!isValidLink(state.url)) {
            result.status = false;
            result.errors.url = "Неверный формат ссылки";
        } else {
            delete result.errors.url;
        }
    }

    if (state.action === "lead") {
        if (!state.lead_admin) {
            result.status = false;
            result.errors.lead_admin = "Выберите администратора";
        }
    }

    if (state.action === "subscribe") {
        if (!state.subscription_id) {
            result.status = false;
            result.errors.subscription = "Выберите группу подписки";
            return result;
        }

        if (
            state.unsubscribe_text &&
            state.unsubscribe_text.trim() === ""
        ) {
            result.status = false;
            result.errors.unsubscribe_text = "Необходимо заполнить";
        } else {
            delete result.errors.unsubscribe_text;
        }
    }

    if (state.action === "bot") {
        if (!state.bot_id) {
            result.status = false;
            result.errors.bot_id = "Выберите бота";
            return result;
        }

        if (state.step && !state.step.value) {
            result.status = false;
            result.errors.step_id = "Выберите шаг";
            return result;
        }
    }

    return result;
}

export { validator };
