const moduleName = "widget-audience";

export const UPDATE_AUDIENCE = `${moduleName}/UPDATE_AUDIENCE`;
export const SET_SEX = `${moduleName}/SET_SEX`;
export const SET_AGE_FROM = `${moduleName}/SET_AGE_FROM`;
export const SET_AGE_TO = `${moduleName}/SET_AGE_TO`;
export const SET_BDAY = `${moduleName}/SET_BDAY`;
export const SET_CURRENT_COUNTRY = `${moduleName}/SET_CURRENT_COUNTRY`;
export const SET_CURRENT_CITY = `${moduleName}/CURRENT_CITY`;
export const SET_COMMUNITY_IN = `${moduleName}/COMMUNITY_IN`;
export const SET_COMMUNITY_OUT = `${moduleName}/COMMUNITY_OUT`;
export const SET_USERS = `${moduleName}/USERS`;
export const SET_PLATFORM = `${moduleName}/PLATFORM`;
export const SET_USER_INTERESTS = `${moduleName}/USER_INTERESTS`;
export const SET_USER_COUPLE_INTERESTS = `${moduleName}/USER_COUPLE_INTERESTS`;
export const SET_NAMES = `${moduleName}/SET_NAMES`;
export const SET_SURNAMES = `${moduleName}/SURNAMES`;
export const SET_RELATION = `${moduleName}/RELATION`;

export const initialState = {
    sex: "any",
    ageFrom: "any",
    ageTo: "any",
    bday: "any",
    currentCountry: [],
    currentCity: [],
    communityIn: [],
    communityOut: [],
    users: [],
    platform: [],
    userInterests: "any",
    userCoupleInterests: "any",
    names: "any",
    surnames: "any",
    relation: [],
};

export default function (state, action) {
    const { type, payload } = action;
    const newState = { ...state };

    switch (type) {
        case UPDATE_AUDIENCE:
            const data = { ...newState, ...payload };
            return data;

        case SET_SEX:
            newState.sex = payload;
            return newState;

        case SET_AGE_FROM:
            newState.ageFrom = payload;
            return newState;

        case SET_AGE_TO:
            newState.ageTo = payload;
            return newState;

        case SET_BDAY:
            newState.bday = payload;
            return newState;

        case SET_CURRENT_COUNTRY:
            newState.currentCountry = payload;
            return newState;

        case SET_CURRENT_CITY:
            newState.currentCity = payload;
            return newState;

        case SET_COMMUNITY_IN:
            newState.communityIn = payload;
            return newState;

        case SET_COMMUNITY_OUT:
            newState.communityOut = payload;
            return newState;

        case SET_USERS:
            newState.users = payload;
            return newState;

        case SET_PLATFORM:
            newState.platform = payload;
            return newState;

        case SET_USER_INTERESTS:
            newState.userInterests = payload;
            return newState;

        case SET_USER_COUPLE_INTERESTS:
            newState.userCoupleInterests = payload;
            return newState;

        case SET_NAMES:
            newState.names = payload;
            return newState;

        case SET_SURNAMES:
            newState.surnames = payload;
            return newState;

        case SET_RELATION:
            newState.relation = payload;
            return newState;

        default:
            return newState;
    }
}
