import React, { useState, useEffect, useContext } from "react";
import { FormLayout, Input, Button, FormItem, Div } from "@vkontakte/vkui";
import WidgetValidate from "../../includes/Validators/WidgetValidate";
import {
    countStringLength,
    cropStringForVk,
} from "../../includes/Helpers/Helpers";
import { URL_PLACEHOLDER } from "../../includes/Constants";
import InputDynamic from "../Controls/InputDynamic";
import { Context } from "../../context";

const ModalWidgetEditHeader = (props) => {
    const { inputDynamic } = useContext(Context);

    const [titleValue, setTitleValue] = useState("");
    const [titleInputHelp, setTitleInputHelp] = useState("");
    const [titleHasError, setTitleHasError] = useState(false);

    const [urlValue, setUrlValue] = useState("");
    const [urlInputHelp, setUrlInputHelp] = useState("");
    const [urlHasError, setUrlHasError] = useState(false);

    const maxLength = 100;

    useEffect(() => {
        setTitleValue(props.state.title);
        setTitleInputHelp(
            `Введено ${countStringLength(props.state.title)} из 100 симв.`
        );
        setUrlValue(props.state.url);
    }, []);

    const SaveModal = () => {
        let status = true;

        // Если редактируем заголовок
        if (props.type == "title") {
            if (titleValue.trim() == "") {
                status = false;
                setTitleHasError(true);
                setTitleInputHelp("Введите заголовок");
            } else if (countStringLength(titleValue) > 100) {
                setTitleHasError(true);
                status = false;
            }

            if (urlValue != "") {
                if (WidgetValidate.button(urlValue) === false) {
                    setUrlHasError(true);
                    setUrlInputHelp(URL_PLACEHOLDER);
                    status = false;
                }
            }
        }

        // Если редактируем нижний блок "Еще"
        if (props.type == "more") {
            if (titleValue.trim() != "") {
                if (WidgetValidate.button(urlValue) === false) {
                    setUrlHasError(true);
                    setUrlInputHelp(URL_PLACEHOLDER);
                    status = false;
                } else if (countStringLength(titleValue) > 100) {
                    setTitleHasError(true);
                    status = false;
                }
            } else {
                if (urlValue.trim() != "") {
                    setTitleHasError(true);
                    setTitleInputHelp("Введите текст ссылки");
                    status = false;
                }
            }
        }

        if (status) {
            if (typeof props.type === "undefined") {
                props.onSelect([cropStringForVk(titleValue), urlValue]);
            } else {
                props.onSelect({
                    type: props.type,
                    payload: [cropStringForVk(titleValue), urlValue],
                });
            }
            props.close({ id: null, args: {} });
        }
    };

    return (
        <FormLayout>
            <Div style={{ paddingTop: 0 }}>
                <InputDynamic
                    currentOpened={inputDynamic.getCurrent()}
                    name="title"
                    type="text"
                    top={props.type == "title" ? "Заголовок" : "Наименование"}
                    value={titleValue}
                    status={titleHasError ? "error" : null}
                    bottomText={titleInputHelp}
                    onChange={(value) => {
                        let length = countStringLength(value);
                        setTitleValue(value);
                        setTitleHasError(length > maxLength);
                        setTitleInputHelp(
                            "Введено " + length + " из " + maxLength + " симв."
                        );
                    }}
                    placeholder={`До 100 символов`}
                />
            </Div>
            <FormItem
                top="Ссылка"
                status={urlHasError ? "error" : null}
                bottom={urlInputHelp ? urlInputHelp : URL_PLACEHOLDER}
            >
                <Input
                    name="url"
                    type="url"
                    value={urlValue}
                    onChange={(e) => {
                        setUrlValue(e.target.value);
                        setUrlHasError(false);
                        setUrlInputHelp(URL_PLACEHOLDER);
                    }}
                    placeholder="vk.com"
                />
            </FormItem>
            <Div>
                <Button mode="secondary" size="l" onClick={SaveModal} stretched>
                    Сохранить
                </Button>
            </Div>
        </FormLayout>
    );
};

export default ModalWidgetEditHeader;
