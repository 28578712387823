import React from "react";
import { Header, Div, Group, Link } from "@vkontakte/vkui";
import ModalWidgetEditHeader from "../ModalWidgetEditHeader";
import ModalMessageEditItem from "../ModalEdit/Item";
import reducer from "./reducer";
import initialState from "./state";
import useUpdate from "../../../includes/Hooks/useUpdate";
import WidgetHeader from "../WidgetHeader";

export const initalErrors = {
    title: false,
    text: false,
};

const MessageEdit = (props) => {
    const [state, dispatch]: any = useUpdate({
        ...props,
        reducer,
        initialState,
        type: "message",
    });
    const errors = { ...initalErrors, ...props.errors.resp };

    const modals = {
        editHeader: {
            title: "Заголовок виджета",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{
                        title: state.title,
                        url: state.title_url,
                    }}
                    type="title"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editLink: {
            title: "Ссылка",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.more, url: state.more_url }}
                    type="more"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editItem: {
            title: "Редактировать элемент",
            body: (state, callback, close) => (
                <ModalMessageEditItem
                    state={{
                        title: state.text,
                        descr: state.descr,
                        url: state.url,
                    }}
                    onSelect={callback}
                    close={close}
                    type="message"
                />
            ),
            callback: dispatch,
            state,
        },
    };

    return (
        <Group>
            <Header>Важное сообщение</Header>
            <WidgetHeader
                onModalRequest={props.onModalRequest}
                state={state}
                modals={modals}
                errors={errors}
            />
            <Div
                onClick={() =>
                    props.onModalRequest({
                        id: "dynamic",
                        args: { ...modals.editItem },
                    })
                }
            >
                <div className="MessageEdit-text">
                    <Link
                        style={
                            errors.text ? { color: "var(--vkui--color_accent_red)" } : null
                        }
                    >
                        {state.text ? state.text : "+ Текст сообщения"}
                    </Link>
                </div>
                <div className="MessageEdit-desc">
                    {state.descr ? state.descr : "+ Описание"}
                </div>
            </Div>
        </Group>
    );
};

export default MessageEdit;
