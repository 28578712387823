import React, { useState } from "react";
import {
    Alert,
    Footer,
    Input,
    FormLayout,
    platform,
    Platform,
    Div,
} from "@vkontakte/vkui";
import { validateEmail } from "../../../includes/Validators/Validator";

type AlertGetEmailProps = {
    /**
     * Действие при нажатии накнопку "Продолжить"
     */
    onSubmit: (value: any) => string | void;

    /**
     * Действие при нажатиии на кнопку "Отмена"
     */
    onCancel: () => void;

    /**
     * Заголовок модального окна
     */
    title?: string;

    /**
     * Описание
     */
    description?: string;

    /**
     * Дефолтное значение email ( из localStorage )
     */
    defaultValue?: string;

    /**
     * Ссылка на оплату по UnitPay
     */
    unitPayUrl?: string;
};

const AlertGetEmail = (props: AlertGetEmailProps) => {
    const {
        title = "Введите email",
        description = "На указанный email придёт письмо с фискальным чеком",
        onCancel,
        onSubmit,
        defaultValue,
        unitPayUrl,
    } = props;

    const [value, setValue] = useState(defaultValue ? defaultValue : "");
    const [hasError, setHasError] = useState(false);

    const devicePlatform = platform();

    const handleSubmit = () => {
        if (hasError || value === "") {
            return false;
        }

        onSubmit(value);
    };

    return (
        <Alert
            actionsLayout="vertical"
            onClose={onCancel}
            className={`AlertGetEmail-wrapper ${
                devicePlatform === Platform.ANDROID
                    ? "AlertGetEmail-wrapper__android"
                    : "AlertGetEmail-wrapper__ios"
            }`}
            actions={[
                {
                    title: "Отмена",
                    mode: "cancel",
                    action: onCancel,
                },
            ]}
        >
            <h2 style={{ textAlign: "center" }}>{title}</h2>
            <div className="AlertGetEmail">
                <Footer>{description}</Footer>
                <div>
                    <FormLayout>
                        <Input
                            value={value}
                            onChange={(e) => {
                                const inpVal = e.currentTarget.value;

                                if (!validateEmail(inpVal.trim())) {
                                    setHasError(true);
                                } else {
                                    setHasError(false);
                                }

                                setValue(inpVal);
                            }}
                            className="unitpay-email"
                            type="email"
                            autoComplete="on"
                            placeholder="username@mail.ru"
                        />

                        <Div style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <a
                                href={unitPayUrl.replace("{email}", value)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="Alert__btn Alert__btn--default AlertGetEmail__link-btn"
                                onClick={handleSubmit}
                                style={{
                                    pointerEvents:
                                        hasError || value === ""
                                            ? "none"
                                            : "auto",
                                    color:
                                        hasError || value === ""
                                            ? "var(--vkui--color_text_secondary)"
                                            : "var(--vkui--color_text_accent)",
                                }}
                            >
                                Продолжить
                            </a>
                        </Div>
                    </FormLayout>
                </div>
            </div>
        </Alert>
    );
};

export default AlertGetEmail;
