export default function (state, action) {
    const newState = { ...state };

    const { type, payload } = action;

    switch (type) {
        case "set-data":
            newState.button = payload;
            return newState;
        case "set-errors":
            newState.errors = payload;
            return newState;

        default:
            return newState;
    }
}
