import React, { useContext, useState } from "react";
import ReactSelect from "react-select";
import { Div, Group, NativeSelect, platform, Platform } from "@vkontakte/vkui";
import { VARIABLES_OPTIONS } from "../../../includes/Constants/Conditions";
import AudienceVariable from "./AudienceVariable";
import AudienceContext from "../../../Context/AudienceContext";

const AudienceVariables = () => {
    const { variable_global, variable_lead, set } = useContext(AudienceContext);

    const [selectValue, setSelectValue] = useState("any");

    const chooseVariable = (variable) => {
        switch (variable.value) {
            case "0":
                setSelectValue("any");
                return false;

            case "variable_global":
                if (variable_global === "any") {
                    set("variable_global", {
                        name: "",
                        action: "in",
                        value: "",
                    });
                    setSelectValue("variable_global");
                }
                break;

            case "variable_lead":
                if (variable_lead === "any") {
                    set("variable_lead", {
                        name: "",
                        action: "in",
                        value: "",
                    });
                    setSelectValue("variable_lead");
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            {platform() !== Platform.IOS ? (
                <Div>
                    <ReactSelect
                        className={`ReactSelect Variables-select ${
                            selectValue === "any"
                                ? "ReactSelect__default-value"
                                : ""
                        }`}
                        classNamePrefix="Variables-select"
                        defaultValue={VARIABLES_OPTIONS[0]}
                        options={VARIABLES_OPTIONS}
                        onChange={chooseVariable}
                        isSearchable={false}
                        cacheOptions
                        defaultOptions
                        isOptionDisabled={(option) => {
                            if (
                                variable_global !== "any" &&
                                option.value === "variable_global"
                            ) {
                                return true;
                            }

                            if (
                                variable_lead !== "any" &&
                                option.value === "variable_lead"
                            ) {
                                return true;
                            }
                        }}
                        noOptionsMessage={() => {
                            return "Ничего не найдено";
                        }}
                    />
                </Div>
            ) : (
                <Div>
                    <NativeSelect
                        placeholder="Выберите переменную"
                        value={selectValue}
                        onChange={(e) => {
                            e.target.value === ""
                                ? chooseVariable(VARIABLES_OPTIONS[0])
                                : chooseVariable({
                                      value: e.target.value,
                                  });
                        }}
                        className="mt-0"
                    >
                        {VARIABLES_OPTIONS.map((varOption) => {
                            if (varOption.value !== "0") {
                                return (
                                    <option
                                        key={`varOption-${varOption.value}`}
                                        value={varOption.value}
                                    >
                                        {varOption.label}
                                    </option>
                                );
                            }
                        })}
                    </NativeSelect>
                </Div>
            )}

            {variable_global !== "any" && (
                <Group separator="hide">
                    <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <AudienceVariable type="variable_global" />
                    </Div>
                </Group>
            )}

            {variable_lead !== "any" && (
                <Group separator="hide">
                    <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <AudienceVariable type="variable_lead" />
                    </Div>
                </Group>
            )}
        </>
    );
};

export default AudienceVariables;
