import {
    FIRST_NAME_CASES,
    LAST_NAME_CASES,
    FIRST_NAME_VAR_REGEXP,
    LAST_NAME_VAR_REGEXP,
    USER_ID_REG_EXP,
    USER_FRIEND_COUNT_REG_EXP,
    GENDER_VAR_REGEXP,
} from "../Constants";

import TextVariableReplacerInterface from "../Interfaces/TextVariableReplacerInterface";

const FIRST_NAME_CASES_VALUES = FIRST_NAME_CASES.map((i) => i.value);
const LAST_NAME_CASES_VALUES = LAST_NAME_CASES.map((i) => i.value);

class VarReplacer implements TextVariableReplacerInterface {
    replaceVars(text: string) {
        if (!text || typeof text !== "string") return "";

        let stringWithoutVars = text
            .replace(FIRST_NAME_VAR_REGEXP, "")
            .replace(LAST_NAME_VAR_REGEXP, "")
            .replace(USER_ID_REG_EXP, "")
            .trim();

        let result = text.replace(new RegExp('"', "g"), '\\"');

        result = this.replaceGenderVars(result);
        result = this.replaceNameVars(result);
        result = this.replaceLastNameVars(result);
        result = this.replaceUserIdVars(result);
        result = this.replaceUserFriendsCountVars(result);

        // Строку только с переменными без до символов ВК воcпринимает как пустую.
        // Для таких случаев добавим на конце zero width joiner
        if (stringWithoutVars.length === 0) {
            result += "&#8205;";
        }

        return result;
    }

    /**
     * Заменяет переменные только для мужчин и только для женщин
     * @param text string
     */
    replaceGenderVars(text: string) {
        return text.replace(
            GENDER_VAR_REGEXP,
            (match, group1, group2, group3) => {
                if (!match || !group2 || !group3) return match;
                return `"+( user.sex == 2 ? "${group2}" : "${group3}" ) +"`;
            }
        );
    }

    /**
     * Заменяет переменные имен
     * @param text string
     */
    replaceNameVars(text: string): string {
        return text.replace(FIRST_NAME_VAR_REGEXP, function (match) {
            // К нижнему регистру приводим на тот случай, если кто-то ввел переменную в верхнем регистре
            let value = match.replace(/({|})/g, "").toLowerCase();
            if (false === FIRST_NAME_CASES_VALUES.includes(value)) {
                return `"+user.first_name+"`;
            }

            return `"+user.${value}+"`;
        });
    }

    /**
     * Заменяет переменные фамилий
     * @param text string
     */
    replaceLastNameVars(text: string): string {
        return text.replace(LAST_NAME_VAR_REGEXP, function (match) {
            // К нижнему регистру приводим на тот случай, если кто-то ввел переменную в верхнем регистре
            let value = match.replace(/({|})/g, "").toLowerCase();
            if (false === LAST_NAME_CASES_VALUES.includes(value)) {
                return `"user.last_name"`;
            }
            return `"+user.${value}+"`;
        });
    }

    /**
     * Заменяет переменные id пользователя
     * @param text string
     */
    replaceUserIdVars(text: string): string {
        return text.replace(USER_ID_REG_EXP, function (match) {
            return `"+user.id+"`;
        });
    }

    /**
     * Заменяет переменные счетчика друзей пользователя
     * @param text string
     */
    replaceUserFriendsCountVars(text: string): string {
        return text.replace(USER_FRIEND_COUNT_REG_EXP, function (match) {
            return `"+user.counters.friends+"`;
        });
    }
}

export default VarReplacer;
