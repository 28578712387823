import React from "react";

import {
    Icon28MessageOutline,
    Icon28MessageUnreadTop,
    Icon28MessageUnreadOutline,
    Icon28ChatsOutline,
    Icon28MoonOutline,
    Icon28MusicMicOutline,
    Icon28VolumeOutline,
    Icon28MuteOutline,
    Icon28PhoneOutline,
    Icon28PictureOutline,
    Icon28MusicOutline,
    Icon28PinOutline,
    Icon28PrivacyOutline,
    Icon28QrCodeOutline,
    Icon28PictureStackOutline,
    Icon28RadiowavesLeftAndRightOutline,
    Icon28ServicesOutline,
    Icon28SettingsOutline,
    Icon28RadiowavesAroundOutline,
    Icon28ShareOutline,
    Icon28StickerOutline,
    Icon28StoryOutline,
    Icon28ShareExternalOutline,
    Icon28TagOutline,
    Icon28UserIncomingOutline,
    Icon28UserOutgoingOutline,
    Icon28UserOutline,
    Icon28Users3Outline,
    Icon28AccessibilityOutline,
    Icon28AchievementCircleFillBlue,
    Icon28AdvertisingCircleFillRed,
    Icon28AdvertisingOutline,
    Icon28CheckCircleFill,
    Icon28CheckShieldOutline,
    Icon28CompassCircleFillBlue,
    Icon28CompassOutline,
    Icon28Document,
    Icon28DocumentOutline,
    Icon28GiftCircleFillRed,
    Icon28LogoSkype,
    Icon28HeadphonesOutline,
    Icon28MailCircleFillGray,
    Icon28MoneyTransferCircleFillRed,
    Icon28PollCircleFillGreen,
    Icon28RadiowavesLeftAndRightCircleFillRed,
    Icon28RoubleCircleFillBlue,
    Icon28Send,
    Icon28SongCircleFillViolet,
    Icon28StoryQuestionCircleFillViolet,
    Icon28TshirtOutline,
    Icon28UnpinOutline,
    Icon28VideoCameraCircleFillRed,
    Icon28Voice,
    Icon28VoiceOutline,
} from "@vkontakte/icons";

const vkIcons = [
    {
        id: 1,
        icon_name: "message_outline",
        icon: <Icon28MessageOutline width={36} height={36} />,
    },
    {
        id: 2,
        icon_name: "message_unread_top",
        icon: <Icon28MessageUnreadTop width={36} height={36} />,
    },
    {
        id: 3,
        icon_name: "message_unread_outline",
        icon: <Icon28MessageUnreadOutline width={36} height={36} />,
    },
    {
        id: 4,
        icon_name: "messages_outline",
        icon: <Icon28ChatsOutline width={36} height={36} />,
    },
    {
        id: 5,
        icon_name: "moon_outline",
        icon: <Icon28MoonOutline width={36} height={36} />,
    },
    {
        id: 6,
        icon_name: "music_mic_outline",
        icon: <Icon28MusicMicOutline width={36} height={36} />,
    },
    {
        id: 7,
        icon_name: "volume_outline",
        icon: <Icon28VolumeOutline width={36} height={36} />,
    },
    {
        id: 8,
        icon_name: "mute_outline",
        icon: <Icon28MuteOutline width={36} height={36} />,
    },
    {
        id: 9,
        icon_name: "phone_outline",
        icon: <Icon28PhoneOutline width={36} height={36} />,
    },
    {
        id: 10,
        icon_name: "picture_outline",
        icon: <Icon28PictureOutline width={36} height={36} />,
    },
    {
        id: 11,
        icon_name: "music_outline",
        icon: <Icon28MusicOutline width={36} height={36} />,
    },
    {
        id: 12,
        icon_name: "pin_outline",
        icon: <Icon28PinOutline width={36} height={36} />,
    },
    {
        id: 13,
        icon_name: "privacy_outline",
        icon: <Icon28PrivacyOutline width={36} height={36} />,
    },
    {
        id: 14,
        icon_name: "qr_code_outline",
        icon: <Icon28QrCodeOutline width={36} height={36} />,
    },
    {
        id: 15,
        icon_name: "picture_stack_outline",
        icon: <Icon28PictureStackOutline width={36} height={36} />,
    },
    {
        id: 16,
        icon_name: "radiowaves_left_and_right_outline",
        icon: <Icon28RadiowavesLeftAndRightOutline width={36} height={36} />,
    },
    {
        id: 17,
        icon_name: "services_outline",
        icon: <Icon28ServicesOutline width={36} height={36} />,
    },
    {
        id: 18,
        icon_name: "settings_outline",
        icon: <Icon28SettingsOutline width={36} height={36} />,
    },
    {
        id: 19,
        icon_name: "radiowaves_around_outline",
        icon: <Icon28RadiowavesAroundOutline width={36} height={36} />,
    },
    {
        id: 20,
        icon_name: "share_outline",
        icon: <Icon28ShareOutline width={36} height={36} />,
    },
    {
        id: 21,
        icon_name: "sticker_outline",
        icon: <Icon28StickerOutline width={36} height={36} />,
    },
    {
        id: 22,
        icon_name: "story_outline",
        icon: <Icon28StoryOutline width={36} height={36} />,
    },
    {
        id: 23,
        icon_name: "share_external_outline",
        icon: <Icon28ShareExternalOutline width={36} height={36} />,
    },
    {
        id: 24,
        icon_name: "tag_outline",
        icon: <Icon28TagOutline width={36} height={36} />,
    },
    {
        id: 25,
        icon_name: "user_incoming_outline",
        icon: <Icon28UserIncomingOutline width={36} height={36} />,
    },
    {
        id: 26,
        icon_name: "user_outgoing_outline",
        icon: <Icon28UserOutgoingOutline width={36} height={36} />,
    },
    {
        id: 27,
        icon_name: "user_outline",
        icon: <Icon28UserOutline width={36} height={36} />,
    },
    {
        id: 28,
        icon_name: "users_outline",
        icon: <Icon28Users3Outline width={36} height={36} />,
    },
    {
        id: 29,
        icon_name: "accessibility_outline",
        icon: <Icon28AccessibilityOutline width={36} height={36} />,
    },
    {
        id: 30,
        icon_name: "achievement_circle_fill_blue",
        icon: <Icon28AchievementCircleFillBlue width={36} height={36} />,
    },
    {
        id: 31,
        icon_name: "advertising_circle_fill_red",
        icon: <Icon28AdvertisingCircleFillRed width={36} height={36} />,
    },
    {
        id: 32,
        icon_name: "advertising_outline",
        icon: <Icon28AdvertisingOutline width={36} height={36} />,
    },
    {
        id: 33,
        icon_name: "check_circle_fill",
        icon: <Icon28CheckCircleFill width={36} height={36} />,
    },

    {
        id: 34,
        icon_name: "check_shield_outline",
        icon: <Icon28CheckShieldOutline width={36} height={36} />,
    },
    {
        id: 35,
        icon_name: "gift_circle_fill_red",
        icon: <Icon28GiftCircleFillRed width={36} height={36} />,
    },
    {
        id: 36,
        icon_name: "logo_skype",
        icon: <Icon28LogoSkype width={36} height={36} />,
    },
    {
        id: 37,
        icon_name: "headphones_outline",
        icon: <Icon28HeadphonesOutline width={36} height={36} />,
    },
    {
        id: 38,
        icon_name: "rouble_circle_fill_blue",
        icon: <Icon28RoubleCircleFillBlue width={36} height={36} />,
    },
    {
        id: 39,
        icon_name: "video_camera_circle_fill_red",
        icon: <Icon28VideoCameraCircleFillRed width={36} height={36} />,
    },
    {
        id: 40,
        icon_name: "voice",
        icon: <Icon28Voice width={36} height={36} />,
    },
    {
        id: 41,
        icon_name: "voice_outline",
        icon: <Icon28VoiceOutline width={36} height={36} />,
    },
    {
        id: 42,
        icon_name: "unpin_outline",
        icon: <Icon28UnpinOutline width={36} height={36} />,
    },
    {
        id: 43,
        icon_name: "tshirt_outline",
        icon: <Icon28TshirtOutline width={36} height={36} />,
    },
    {
        id: 44,
        icon_name: "radiowaves_left_and_right_circle_fill_red",
        icon: (
            <Icon28RadiowavesLeftAndRightCircleFillRed width={36} height={36} />
        ),
    },

    {
        id: 45,
        icon_name: "poll_circle_fill_green",
        icon: <Icon28PollCircleFillGreen width={36} height={36} />,
    },
    {
        id: 46,
        icon_name: "send",
        icon: <Icon28Send width={36} height={36} />,
    },
    {
        id: 47,
        icon_name: "circle_fill_violet ",
        icon: <Icon28SongCircleFillViolet width={36} height={36} />,
    },
    {
        id: 48,
        icon_name: "story_question_circle_fill_violet",
        icon: <Icon28StoryQuestionCircleFillViolet width={36} height={36} />,
    },
    {
        id: 49,
        icon_name: "money_transfer_circle_fill_red",
        icon: <Icon28MoneyTransferCircleFillRed width={36} height={36} />,
    },
    {
        id: 50,
        icon_name: "mail_circle_fill_gray",
        icon: <Icon28MailCircleFillGray width={36} height={36} />,
    },
    {
        id: 51,
        icon_name: "document",
        icon: <Icon28Document width={36} height={36} />,
    },
    {
        id: 52,
        icon_name: "document_outline",
        icon: <Icon28DocumentOutline width={36} height={36} />,
    },
    {
        id: 53,
        icon_name: "compass_circle_fill_blue",
        icon: <Icon28CompassCircleFillBlue width={36} height={36} />,
    },
    {
        id: 54,
        icon_name: "compass_outline",
        icon: <Icon28CompassOutline width={36} height={36} />,
    },
];

export default vkIcons;
