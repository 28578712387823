import React, { useReducer, useState } from "react";
import {
    FormLayout,
    Group,
    Div,
    FixedLayout,
    Button,
    Cell,
    List,
    Separator,
} from "@vkontakte/vkui";
import initialState from "./state";
import reducer from "./reducer";
import FormSubmitButton from "../../FormSubmitButton";
import TimerControl from "../../Timer/TimerControl";
import { Icon20ArticleBoxOutline, Icon20DeleteOutline, Icon24Add } from "@vkontakte/icons";

type ImagesEditProps = {
    data: any;
    currentDynamicInput: string;
    onSubmit: Function;
    openModal: Function;
    maxItemsCount: number; // Максимальное возможное количество блоков
};

const ImagesGalleryEdit = (props: ImagesEditProps) => {
    const { data, currentDynamicInput, onSubmit, openModal, maxItemsCount } =
        props;

    const [state, dispatch] = useReducer(reducer, { ...initialState, ...data });
    const [editMode, setEditMode] = useState(false);

    const { has_title } = state;

    const isAllowAddImages = state.items.length < maxItemsCount;

    const handleSubmit = () => {
        onSubmit(state);
    };

    const renderImages = () => {
        return state.items.map((item, index) => {
            return (
                <Cell
                    key={index}
                    className="BlockEdit__images-item"
                    style={{ paddingLeft: 0 }}
                    draggable={editMode}
                    mode={editMode ? 'removable': undefined}
                    removePlaceholder={<Icon20DeleteOutline />}
                    subtitle={item.text ? item.text : "+ Добавить описание"}
                    onClick={() => {
                        openModal({
                            id: "landing-img",
                            args: {
                                size: [1920, 1220],
                                hasDescription: true,
                                description: item.text,
                                imageUrl: item.url,
                                callback: (data) => {
                                    dispatch({
                                        type: "update-item",
                                        payload: {
                                            index: index,
                                            ...data,
                                        },
                                    });
                                },
                            },
                        });
                    }}
                    onRemove={() => {
                        dispatch({ type: "remove-item", payload: { index } });
                    }}
                    onDragFinish={({ from, to }) => {
                        dispatch({ type: "sort-items", payload: { from, to } });
                    }}
                    before={
                        <div
                            style={{
                                background: `url(${item.url}) center center / cover no-repeat`,
                            }}
                            className="BlockEdit__images-item-icon"
                        ></div>
                    }
                ></Cell>
            );
        });
    };

    const renderAddImageButton = () => {
        return (
            <div
                onClick={() => {
                    openModal({
                        id: "landing-img",
                        args: {
                            size: [1920, 1220],
                            hasDescription: true,
                            callback: (data) => {
                                dispatch({
                                    type: "add-item",
                                    payload: {
                                        ...data,
                                    },
                                });
                            },
                        },
                    });
                }}
                className="BlockEdit__images-item BlockEdit__images-add"
            >
                <span>Добавить изображение</span>
                <span>
                    <Icon24Add width={18} />
                </span>
            </div>
        );
    };

    return (
        <FormLayout style={{ paddingBottom: 100 }}>
            <TimerControl
                checked={has_title}
                title="Заголовок и описание"
                icon={
                    <Icon20ArticleBoxOutline
                        width={24}
                        height={24}
                        fill="var(--vkui--color_icon_accent_themed)"
                        style={{
                            marginRight: 19,
                            opacity: has_title ? 1 : 0.4,
                        }}
                    />
                }
                onChange={() => {
                    dispatch({
                        type: "set-has-title",
                        payload: !has_title,
                    });
                }}
                onEditClick={() => {
                    openModal({
                        id: "text",
                        args: {
                            content: state.content,
                            currentDynamicInput: currentDynamicInput,
                            callback: (data) => {
                                dispatch({
                                    type: "edit-text",
                                    payload: data,
                                });
                            },
                        },
                    });
                }}
            />

            <Separator />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        className="subhead"
                    >
                        <span>Изображения</span>
                        <Button
                            style={{ marginRight: 0 }}
                            onClick={() => {
                                setEditMode(!editMode);
                            }}
                            mode="secondary"
                        >
                            {editMode ? "Готово" : "Править"}
                        </Button>
                    </div>
                </Div>

                <div>
                    <Group>
                        <List>{renderImages()}</List>
                    </Group>
                    {false === editMode && isAllowAddImages
                        ? renderAddImageButton()
                        : null}
                </div>
            </div>

            {false === editMode && (
                <FixedLayout filled={true} vertical={"bottom"}>
                    <FormSubmitButton handleSubmit={handleSubmit} />
                </FixedLayout>
            )}
        </FormLayout>
    );
};

export default ImagesGalleryEdit;
