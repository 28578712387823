import React, { useEffect, useReducer, useMemo } from "react";
import {
    Group,
    Cell,
    FormLayout,
    FormLayoutGroup,
    Checkbox,
    SelectMimicry,
    FormItem,
    NativeSelect,
    Div,
    Headline,
} from "@vkontakte/vkui";

import Badges from "../../Controls/Badges";
import DetachedBadges from "../../Controls/DetachedBadges";

import { range } from "../../../includes/Helpers/Helpers";
import {
    Analytics,
    WIDGET_AUDIENCE_HIT,
} from "../../../includes/Metrics/Analytics";
import {
    platforms,
    sexList,
    bDayList,
    relations,
    WIDGET_MAX_AUDIENCE_COMMUNITIES_INFO,
} from "../../../includes/Constants";
import reducer, { UPDATE_AUDIENCE, initialState } from "./reducer";

/**
 * Проверка - задано ли value изначально в приходящем объекте audience
 * @param {any} value
 * @returns {boolean}
 */
const isset = (value) => !!value && value !== "any";

const WidgetAudienceForm = (props) => {
    const {
        onAudienceUpdate,
        audience,
        isPending,
        setEditMode,
        widget
    } = props;

    const editAudience = { ...audience };

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        sex: audience.sex ? audience.sex : "any",
        ageFrom: audience.ageFrom ? audience.ageFrom : "any",
        ageTo: audience.ageTo ? audience.ageTo : "any",
        bDay: audience.bDay ? audience.bDay : "any",
        currentCountry:
            audience.currentCountry && audience.currentCountry.length > 0
                ? audience.currentCountry
                : [],
        currentCity:
            audience.currentCity && audience.currentCity.length > 0
                ? audience.currentCity
                : [],
        communityIn: audience.communityIn ? audience.communityIn : [],
        communityOut: audience.communityOut ? audience.communityOut : [],
        users: audience.users ? audience.users : [],
        platform:
            audience.platform && audience.platform.length > 0
                ? audience.platform
                : [],
        userInterests: isset(audience.userInterests)
            ? audience.userInterests
            : "any",
        userCoupleInterests: isset(audience.userCoupleInterests)
            ? audience.userCoupleInterests
            : "any",
        names: isset(audience.names) ? audience.names : "any",
        surnames: isset(audience.surnames) ? audience.surnames : "any",
        relation: audience.relation ? audience.relation : [],
    });

    const set = (name, value) => {
        const newAudience = {
            ...editAudience,
            [name]: value,
        };
        onAudienceUpdate(newAudience);
    };

    useEffect(() => {
        dispatch({ type: UPDATE_AUDIENCE, payload: audience });
    }, [audience]);

    useEffect(() => {
        Analytics.goal(WIDGET_AUDIENCE_HIT);
        setEditMode && setEditMode(false);
    }, [setEditMode]);

    const ageListFrom = range(7, state.ageTo !== "any" ? state.ageTo - 1 : 89);
    const ageListTo = range(
        state.ageFrom !== "any" ? state.ageFrom + 1 : 7,
        90
    );

    const resolveBdayValue = (value, isChecked) => {
        let newVal = state.bDay === "any" ? [] : [...state.bDay];

        if (isChecked) {
            newVal.push(value);
        } else {
            newVal = newVal.filter((i) => i !== value);
        }

        set("bDay", newVal.length === 0 ? "any" : newVal);
    };

    const header = useMemo(() => {
        return (
            <Cell
                subtitle="Настройте показ виджета на свою целевую аудиторию, чтобы получить больше кликов по виджету и повысить конверсию"
                hasHover={false}
                multiline
                disabled
            >
                Настройка аудитории
            </Cell>
        );
    }, []);

    return (
        <div className="widget-audience-form-wrapper">
            <Group>{header}</Group>
            <Group
                header={
                    <Div style={{ paddingTop: 0, paddingBottom: 8 }}>
                        <Headline level="2" weight="1">
                            Демография
                        </Headline>
                    </Div>
                }
            >
                <FormLayout>
                    {/* Выбор пола */}
                    <FormItem top="Пол">
                        <NativeSelect
                            placeholder="Любой"
                            value={state.sex}
                            onChange={(e) => {
                                e.target.value === ""
                                    ? set("sex", "any")
                                    : set("sex", parseInt(e.target.value, 10));
                            }}
                        >
                            {sexList.map((item, index) => (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </NativeSelect>
                    </FormItem>

                    {/* Выбор диапазона возраста */}
                    <FormItem top="Возраст">
                        <FormLayoutGroup className="d-flex">
                            <NativeSelect
                                placeholder="от"
                                value={state.ageFrom}
                                onChange={(e) => {
                                    e.target.value === ""
                                        ? set("ageFrom", "any")
                                        : set(
                                              "ageFrom",
                                              parseInt(e.target.value, 10)
                                          );
                                }}
                                style={{ flexGrow: 1, marginRight: 5 }}
                            >
                                {ageListFrom.map((age) => (
                                    <option key={`age-from-${age}`} value={age}>
                                        {age}
                                    </option>
                                ))}
                            </NativeSelect>
                            <NativeSelect
                                placeholder="до"
                                value={state.ageTo}
                                onChange={(e) => {
                                    e.target.value === ""
                                        ? set("ageTo", "any")
                                        : set(
                                              "ageTo",
                                              parseInt(e.target.value, 10)
                                          );
                                }}
                                style={{ flexGrow: 1, marginLeft: 5 }}
                                className="mt-0"
                            >
                                {ageListTo.map((age) => (
                                    <option key={`age-to-${age}`} value={age}>
                                        {age}
                                    </option>
                                ))}
                            </NativeSelect>
                        </FormLayoutGroup>
                    </FormItem>

                    {/* Выбор дня рождения */}
                    <FormItem top="День рождения" style={{ paddingBottom: 8 }}>
                        {bDayList.map((item) => (
                            <Checkbox
                                name="bday"
                                key={`bday-${item.value}`}
                                value={item.value}
                                checked={
                                    state.bDay !== "any" &&
                                    state.bDay.indexOf(item.value) !== -1
                                }
                                onChange={(e) => {
                                    let isChecked = e.target.checked;
                                    let value = e.target.value;
                                    resolveBdayValue(value, isChecked);
                                }}
                            >
                                {item.title}
                            </Checkbox>
                        ))}
                    </FormItem>

                    <FormItem top="Семейное положение">
                        <SelectMimicry
                            onClick={() =>
                                props.onModalRequest({
                                    id: "relations-select",
                                    args: {
                                        selected: state.relation,
                                        relations: relations,
                                        callback: (val) => {
                                            set("relation", val);
                                        },
                                    },
                                })
                            }
                            placeholder="Выберите статусы"
                        />
                    </FormItem>

                    {state.relation.length > 0 && (
                        <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <DetachedBadges
                                items={relations.filter(
                                    (r) => state.relation.indexOf(r.id) > -1
                                )}
                                name="relation"
                                onChange={(val) => {
                                    set(
                                        "relation",
                                        val.map((i) => i.id)
                                    );
                                }}
                                getText={(item) => item.title}
                            />
                        </Div>
                    )}
                </FormLayout>
            </Group>
            <Group
                header={
                    <Div style={{ paddingTop: 0, paddingBottom: 8 }}>
                        <Headline level="2" weight="1">
                            География
                        </Headline>
                    </Div>
                }
            >
                <FormLayout>
                    {/* Выбор страны */}
                    <FormItem top="Страна">
                        <SelectMimicry
                            onClick={() =>
                                props.onModalRequest({
                                    id: "country-select",
                                    args: {
                                        currentCountry: state.currentCountry,
                                        callback: (val) => {
                                            set("currentCountry", val);
                                        },
                                    },
                                })
                            }
                            placeholder="Выбрать"
                        >
                            {state.currentCountry.length > 0
                                ? state.currentCountry[0].title
                                : ""}
                        </SelectMimicry>
                    </FormItem>

                    {/* Выбор города */}

                    {state.currentCountry.length > 0 && (
                        <FormItem top="Город">
                            <SelectMimicry
                                onClick={() =>
                                    props.onModalRequest({
                                        id: "city-select",
                                        args: {
                                            currentCity: state.currentCity,
                                            callback: (val) => {
                                                set("currentCity", val);
                                            },
                                            countryId:
                                                state.currentCountry[0].id,
                                        },
                                    })
                                }
                                placeholder="Выбрать"
                            ></SelectMimicry>
                        </FormItem>
                    )}

                    {state.currentCity.length > 0 && (
                        <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <DetachedBadges
                                items={state.currentCity}
                                name="city"
                                onChange={(val) => {
                                    set("currentCity", val);
                                }}
                                getText={(item) => item.title}
                            />
                        </Div>
                    )}
                </FormLayout>
            </Group>
            <Group
                header={
                    <Div style={{ paddingTop: 0, paddingBottom: 8 }}>
                        <Headline level="2" weight="1">
                            Интересы
                        </Headline>
                    </Div>
                }
            >
                <FormLayout>
                    {/* Выбор сообществ, в которых состоит пользователь */}
                    <FormItem top="Состоит в сообществе">
                        <SelectMimicry
                            onClick={() =>
                                props.onModalRequest({
                                    id: "community-select",
                                    args: {
                                        widget: widget,
                                        selected: state.communityIn,
                                        callback: (val) => {
                                            const filteredCommunities = val.map(
                                                (community) => {
                                                    return {
                                                        id: community.id,
                                                        name: community.name,
                                                    };
                                                }
                                            );

                                            set(
                                                "communityIn",
                                                filteredCommunities
                                            );
                                        },
                                    },
                                })
                            }
                            placeholder="Выбрать"
                        />
                    </FormItem>

                    {state.communityIn.length > 0 && (
                        <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <DetachedBadges
                                items={state.communityIn}
                                name="communityIn"
                                onChange={(val) => {
                                    set("communityIn", val);
                                }}
                                getText={(item) => item.name}
                            />
                        </Div>
                    )}

                    {/* Выбор сообществ, в которых пользователь не состоит */}
                    <FormItem
                        top="Не состоит в сообществе"
                        bottom={WIDGET_MAX_AUDIENCE_COMMUNITIES_INFO}
                    >
                        <SelectMimicry
                            onClick={() =>
                                props.onModalRequest({
                                    id: "community-select",
                                    args: {
                                        widget: widget,
                                        selected: state.communityOut,
                                        callback: (val) => {
                                            const filteredCommunities = val.map(
                                                (community) => {
                                                    return {
                                                        id: community.id,
                                                        name: community.name,
                                                    };
                                                }
                                            );

                                            set(
                                                "communityOut",
                                                filteredCommunities
                                            );
                                        },
                                    },
                                })
                            }
                            placeholder="Выбрать"
                        />
                    </FormItem>

                    {state.communityOut.length > 0 && (
                        <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <DetachedBadges
                                items={state.communityOut}
                                name="communityOut"
                                onChange={(val) => {
                                    console.log(val);

                                    set("communityOut", val);
                                }}
                                getText={(item) => item.name}
                            />
                        </Div>
                    )}

                    {/* Выбор конкретных пользователей */}
                    <FormItem top="Пользователи">
                        <SelectMimicry
                            onClick={() =>
                                props.onModalRequest({
                                    id: "users-select",
                                    args: {
                                        selected: state.users,
                                        callback: (val) => {
                                            const filteredUsers = val.map(
                                                (user) => {
                                                    return {
                                                        id: user.id,
                                                        first_name:
                                                            user.first_name,
                                                        last_name:
                                                            user.last_name,
                                                    };
                                                }
                                            );

                                            set("users", filteredUsers);
                                        },
                                        sex:
                                            state.sex === "any" ? 0 : state.sex,
                                    },
                                })
                            }
                            placeholder="Выбрать"
                        />
                    </FormItem>

                    {state.users.length > 0 && (
                        <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <DetachedBadges
                                items={state.users}
                                name="users"
                                onChange={(val) => {
                                    set("users", val);
                                }}
                                getText={(item) =>
                                    `${item.first_name} ${item.last_name}`
                                }
                            />
                        </Div>
                    )}

                    <FormItem top="Интересы пользователя">
                        <Badges
                            placeholder="Например, Автомобили"
                            items={state.userInterests}
                            name="userInterests"
                            isPending={isPending}
                            onChange={(keywords) => {
                                let values =
                                    keywords.length === 0 ? "any" : keywords;
                                set("userInterests", values);
                            }}
                        />
                    </FormItem>

                    <FormItem top="Интересы второй половинки">
                        <Badges
                            placeholder="Например, Спорт"
                            items={state.userCoupleInterests}
                            isPending={isPending}
                            name="userCoupleInterests"
                            onChange={(keywords) => {
                                let values =
                                    keywords.length === 0 ? "any" : keywords;
                                set("userCoupleInterests", values);
                            }}
                        />
                    </FormItem>
                </FormLayout>
            </Group>
            <Group
                header={
                    <Div style={{ paddingTop: 0, paddingBottom: 8 }}>
                        <Headline level="2" weight="1">
                            Дополнительные параметры
                        </Headline>
                    </Div>
                }
            >
                <FormLayout>
                    <FormItem top="Устройство">
                        <SelectMimicry
                            onClick={() =>
                                props.onModalRequest({
                                    id: "platform-select",
                                    usagePlace: "widget-audience",
                                    args: {
                                        platforms: platforms,
                                        selected: state.platform,
                                        callback: (val) => {
                                            set("platform", val);
                                        },
                                    },
                                })
                            }
                            placeholder="Выбрать"
                        />
                    </FormItem>

                    {state.platform.length > 0 && (
                        <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <DetachedBadges
                                items={platforms.filter((p) => {
                                    return state.platform.indexOf(p.id) > -1;
                                })}
                                name="platform"
                                onChange={(val) => {
                                    set(
                                        "platform",
                                        val.map((i) => i.id)
                                    );
                                }}
                                getText={(item) => item.title}
                            />
                        </Div>
                    )}

                    <FormItem top="Имя пользователя">
                        <Badges
                            placeholder="Введите имя"
                            items={state.names}
                            name="names"
                            isPending={isPending}
                            onChange={(keywords) => {
                                let values =
                                    keywords.length === 0 ? "any" : keywords;
                                set("names", values);
                            }}
                        />
                    </FormItem>

                    <FormItem top="Фамилия пользователя">
                        <Badges
                            placeholder="Введите фамилию"
                            items={state.surnames}
                            name="surnames"
                            isPending={isPending}
                            onChange={(keywords) => {
                                let values =
                                    keywords.length === 0 ? "any" : keywords;
                                set("surnames", values);
                            }}
                        />
                    </FormItem>
                </FormLayout>
            </Group>
        </div>
    );
};

export default WidgetAudienceForm;
