import React from "react";
import { Separator, Div } from "@vkontakte/vkui";
import Banner from "../../Elements/Banner";
import { GroupSiteData } from "../../../includes/Structures";
import resolveBannerUrl from "../../../includes/Helpers/resolveBannerUrl";
import { Icon16Cancel, Icon48Camera } from "@vkontakte/icons";

declare type GroupBannersFormProps = {
    groupData: GroupSiteData;
    deleteDesktopBanner: Function;
    deleteMobileBanner: Function;
    openBannerUploader: Function;
    onChange: Function;
    platform: string;
};

const GroupBannersForm = (props: GroupBannersFormProps) => {
    const {
        groupData,
        deleteDesktopBanner,
        deleteMobileBanner,
        openBannerUploader,
        onChange,
    } = props;

    const hasDesktopBanner =
        ["img", "youtube", "vimeo", "vk_video"].indexOf(
            groupData.banner_type
        ) >= 0 &&
        (groupData.banner_url || groupData.video_key);
    const hasMobileBanner =
        ["img", "youtube", "vimeo", "vk_video"].indexOf(
            groupData.mobile_banner_type
        ) >= 0 &&
        (groupData.mobile_banner_url || groupData.mobile_video_key);

    const deleteBanner = (type: string) => {
        if (type === "desktop") {
            deleteDesktopBanner && deleteDesktopBanner();
        } else {
            deleteMobileBanner && deleteMobileBanner();
        }
    };

    const generateBannerData = (data, type: string) => {
        const bannerData = resolveBannerUrl(data.url);

        onChange({
            type: type,
            data: bannerData,
        });
    };

    const renderBannerPreview = (type: string) => {
        return (
            <div className="GroupBannersForm__banner">
                <button
                    className="GroupBannersForm__button GroupBannersForm__button_delete"
                    onClick={() => deleteBanner(type)}
                >
                    <Icon16Cancel fill="#fff" />
                </button>
                <Banner
                    platform={type === "desktop" ? "desktop_web" : "mobile"}
                    video_autoplay={0}
                    video_key={groupData.video_key}
                    mobile_video_key={groupData.mobile_video_key}
                    type="catalog"
                    banner_type={groupData.banner_type}
                    mobile_banner_type={groupData.mobile_banner_type}
                    banner_url={groupData.banner_url}
                    mobile_banner_url={groupData.mobile_banner_url}
                />
            </div>
        );
    };

    const renderBannerFilePicker = (type: string) => {
        return (
            <div
                className="GroupBannersForm__banner GroupBannersForm__banner_filepicker"
                onClick={() => {
                    openBannerUploader({
                        id: "banner-img",
                        args: {
                            type: type,
                            groupData: groupData,
                            callback: (data) => {
                                generateBannerData(data, type);
                            },
                        },
                    });
                }}
            >
                <Icon48Camera
                    width={32}
                    height={32}
                    fill="var(--vkui--color_accent_gray)"
                />
            </div>
        );
    };

    return (
        <div className="GroupBannersForm">
            <Div>
                <div className="subhead">Баннер для мобильной версии</div>
                {hasMobileBanner
                    ? renderBannerPreview("mobile")
                    : renderBannerFilePicker("mobile")}
                <div className="subhead">
                    Рекомендуемый размер изображения 1125x540
                </div>
            </Div>
            <Separator />
            <Div>
                <div className="subhead">Баннер для веб-версии</div>
                {hasDesktopBanner
                    ? renderBannerPreview("desktop")
                    : renderBannerFilePicker("desktop")}
                <div className="subhead">
                    Рекомендуемый размер изображения 1360x300
                </div>
            </Div>
        </div>
    );
};

export default GroupBannersForm;
