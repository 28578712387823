import React, { useEffect, useState, useContext } from "react";
import ApiVk from "../../../../../includes/ApiVk";
import { Context } from "../../../../../context";
import { Footer, Spinner, List, Cell, Button, Div } from "@vkontakte/vkui";
import CustomAvatar from "../../../../Elements/CustomAvatar";

type ProductSelectType = {
    onSelect: Function;
    vk_group_id: number;
    addedProducts?: number[];
};

const STEP_PRODUCTS = 100;

const ProductSelect = (props: ProductSelectType) => {
    const api = ApiVk.getInstance({});
    const { pages } = useContext(Context);
    const vk_group_id = props.vk_group_id;

    const { onSelect } = props;

    const currentProducts = pages.getCurrentEditableProducts();
    const currentProductsVkIds = currentProducts
        .filter((p) => !!p.vk_id)
        .map((p) => p.vk_id);

    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState("");
    const [items, setItems] = useState([]);
    const [itemsIsEmpty] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [currentOffset, setCurrentOffset] = useState(0);

    useEffect(() => {
        loadProducts(0);
    }, []);

    const loadProducts = async (offset: number = 0) => {
        setIsPending(true);

        const resp = await api.getProducts(offset);

        setIsPending(false);

        if (resp.result === "success") {
            if (resp.response.items.length === 0) {
                setHasMore(false);
            } else {
                const newItems = [...items, ...resp.response.items];
                setItems(newItems);
                setCurrentOffset(offset + STEP_PRODUCTS);
                if (newItems.length < resp.response.count) {
                    setHasMore(true);
                } else {
                    setHasMore(false);
                }
            }
        } else {
            setError(resp.message);
        }
    };

    const getItemPhotoUrl = (item) => {
        if (item.photos.length === 0) return "";

        let photos = item.photos[0].sizes;

        let maxImageSize = 0;
        let imageIndex = 0;

        photos.forEach((photo, index) => {
            if (photo.width > maxImageSize) {
                maxImageSize = photo.width;
                imageIndex = index;
            }
        });

        let photo = photos[imageIndex];

        return photo.url;
    };

    const renderItems = () => {
        return items.map((item) => {
            const itemPhotoUrl = getItemPhotoUrl(item);
            const itemCategory = item.category ? item.category.name : "";
            const itemPrice = item.price ? item.price.text : "";
            let isAdded = currentProductsVkIds.indexOf(item.id) >= 0;

            return (
                <Cell
                    indicator={
                        <Button
                            onClick={() => {
                                if (isAdded) {
                                    return false;
                                }

                                onSelect({
                                    category: itemCategory,
                                    price: itemPrice,
                                    img: {
                                        url: itemPhotoUrl,
                                    },
                                    name: item.title,
                                    text: item.description,
                                    button: {
                                        text: "Подробнее",
                                        action: "url",
                                        url: `https://vk.com/market-${vk_group_id}?w=product-${vk_group_id}_${item.id}`,
                                    },
                                    vk_id: item.id,
                                });
                            }}
                            mode={isAdded ? "secondary" : "primary"}
                            size="m"
                        >
                            {isAdded ? "Добавлено" : "Добавить"}
                        </Button>
                    }
                    before={
                        <div>
                            <CustomAvatar
                                style={{ marginRight: 10 }}
                                img={itemPhotoUrl}
                            />
                        </div>
                    }
                    subtitle={`${itemCategory} ${" - " + itemPrice}`}
                    key={item.id}
                >
                    {item.title}
                </Cell>
            );
        });
    };

    if (!isPending && itemsIsEmpty) {
        return <Footer>Товаров не найдено</Footer>;
    }

    return (
        <div>
            {items.length > 0 && (
                <div style={{ padding: "20px 0" }}>
                    <List>{renderItems()}</List>
                </div>
            )}

            {error && (
                <Div style={{ textAlign: "center" }}>
                    <div className="has-error">{error}</div>
                </Div>
            )}

            {isPending && (
                <div style={{ padding: "20px 0" }}>
                    <Spinner size="regular" />
                </div>
            )}

            {hasMore && !isPending && (
                <Div style={{ textAlign: "center", paddingTop: 0 }}>
                    <Button
                        size="l"
                        onClick={() => {
                            loadProducts(currentOffset);
                        }}
                    >
                        Еще
                    </Button>
                </Div>
            )}
        </div>
    );
};

export default ProductSelect;
