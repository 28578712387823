import React, { useContext } from "react";
import ReactSelect from "react-select";
import { Cell, Div, Group, NativeSelect, platform, Platform } from "@vkontakte/vkui";
import { userCommunityRoles } from "../../../../includes/Constants";
import AudienceContext from "../../../../Context/AudienceContext";

const UserCommunityRole = () => {
    const { set, user_role } = useContext(AudienceContext);

    return (
        <>
            <Group>
                <Cell
                    hasHover={false}
                    hasActive={false}
                    subtitle="Блок будет показываться в зависимости от роли пользователя в сообществе"
                    multiline
                >
                    Роль пользователя в сообществе
                </Cell>
            </Group>

            {platform() !== Platform.IOS ? (
                <Div
                    className="ReactSelect-default__wrapper"
                    style={{ paddingBottom: 0 }}
                >
                    <ReactSelect
                        className={`Variables-select ReactSelect ${
                            user_role === "any"
                                ? "ReactSelect__default-value"
                                : ""
                        }`}
                        classNamePrefix="Variables-select"
                        defaultValue={userCommunityRoles[0]}
                        options={userCommunityRoles}
                        cacheOptions
                        defaultOptions
                        noOptionsMessage={() => {
                            return "Ничего не найдено";
                        }}
                        value={{
                            label: userCommunityRoles.find(
                                (role) => role.value === user_role
                            ).label,
                            value: user_role,
                        }}
                        onChange={(option) => {
                            set("user_role", option.value);
                        }}
                    />
                </Div>
            ) : (
                <Div>
                    <NativeSelect
                        placeholder="Любая"
                        value={user_role}
                        onChange={(e) => {
                            e.target.value === ""
                                ? set("user_role", 'any')
                                : set("user_role", e.target.value);
                        }}
                        className="mt-0"
                    >
                        {userCommunityRoles.map((userRole) => {
                            if (userRole.value !== "any") {
                                return (
                                    <option
                                        key={`userRole-${userRole.value}`}
                                        value={userRole.value}
                                    >
                                        {userRole.label}
                                    </option>
                                );
                            }
                        })}
                    </NativeSelect>
                </Div>
            )}
        </>
    );
};

export default UserCommunityRole;
