import { clone } from "../../../../../includes/Helpers/Helpers";
import { SET_TEXT, SET_TITLE } from "./state";

function reducer(state, action) {
    const newState = clone(state);

    switch (action.type) {
        case SET_TITLE:
            newState.content.title = action.payload;

            if (action.payload.trim() === "") {
                newState.titleHasError = true;
            } else {
                newState.titleHasError = false;
            }

            return newState;

        case SET_TEXT:
            newState.content.text = action.payload;

            if (action.payload.trim() === "") {
                newState.textHasError = true;
            } else {
                newState.textHasError = false;
            }

            return newState;

        default:
            throw new Error();
    }
}
export default reducer;
