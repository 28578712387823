const moduleName = "pages-block-audience";

export const UPDATE_AUDIENCE = `${moduleName}/UPDATE_AUDIENCE`;

export const initialBlockAudienceState = {
    status: 1,
    data: {
        sex: "any",
        age_from: "any",
        age_to: "any",
        b_day: "any",
        current_country: [],
        current_city: [],
        platform: [],
        names: "any",
        surnames: "any",
        relation: [],
        user_time: {
            type: "after",
            time: "17:00",
            is_active: false,
        },
        variable_global: "any",
        variable_lead: "any",
        user_role: "any",
    },
};

export default function (state, action) {
    const { type, payload } = action;

    const newState: any = {
        status: state.status,
        data: {
            ...state.data,
        },
    };

    switch (type) {
        case UPDATE_AUDIENCE:
            const data = {
                status: payload.status,
                data: {
                    ...newState.data,
                    ...payload.data,
                },
            };

            return data;

        default:
            return newState;
    }
}
