import React from "react";
import { FixedLayout, Button } from "@vkontakte/vkui";

import Page from "./Page";
import { Icon28ShareOutline } from "@vkontakte/icons";

type TemplatePreviewProps = {
    template: any;
    platform: string;
    onConfirm: () => {};
};

const emptyResizer = {
    setLandingHeight: () => {},
};

const TemplatePreview = (props: TemplatePreviewProps) => {
    const { platform, template, onConfirm = () => {} } = props;

    const renderConfirmButton = () => {
        return (
            <FixedLayout
                style={{
                    boxShadow:
                        "0 2px 24px 0 rgba(0,0,0,.08), 0 0 2px 0 rgba(0,0,0,.08)",
                }}
                vertical="bottom"
                className="TemplatePreview__fixedButton"
                filled
            >
                <Button
                    onClick={onConfirm}
                    mode="tertiary"
                    size="l"
                    stretched
                >
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <span>
                                <Icon28ShareOutline
                                    width={24}
                                    style={{ marginRight: 8 }}
                                />
                            </span>
                            <span>Создать лендинг из шаблона</span>
                        </div>
                    </div>
                </Button>
            </FixedLayout>
        );
    };

    return (
        <div style={{ paddingBottom: 40 }}>
            <Page
                resizer={emptyResizer}
                platform={platform}
                blocks={template.blocks_edit}
                isPreview
            />
            {renderConfirmButton()}
        </div>
    );
};

export default TemplatePreview;
