import React from "react";
import { Cell, Avatar } from "@vkontakte/vkui";
import VkLogo from "../../Elements/VkLogo";
import Twemoji from "react-twemoji";
import { simpleTextToHtml } from "../../../includes/Helpers/TextToHtml";
import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../includes/Constants/PageBlockConstants";

type ReviewsProps = {
    data: any;
    blockPadding?: number;
    blockAlignment?: BlockAlignment;
    textFontSize?: number;
};

const Reviews = (props: ReviewsProps) => {
    const {
        data,
        blockPadding = 25,
        blockAlignment = "center",
        textFontSize = DEFAULT_BLOCK_TEXT_FONT_SIZE,
    } = props;

    const renderItems = () => {
        return data.items.map((item) => {
            return (
                <Cell
                    href={item.vkUrl ? item.vkUrl : null}
                    target="_blank"
                    title={item.vkUrl ? "Читать реальный отзыв" : ""}
                    key={item.uuid}
                    multiline={true}
                    before={
                        <Avatar
                            size={72}
                            src={
                                item.img
                                    ? item.img.url
                                    : "https://i.yapx.ru/IkYec.png"
                            }
                        />
                    }
                    subtitle={
                        <Twemoji
                            options={{ className: "twemoji-promo" }}
                            style={{ lineHeight: "1.5em" }}
                        >
                            {item.text}
                        </Twemoji>
                    }
                    style={{ fontSize: "1em", lineHeight: "1em" }}
                >
                    <div className="B-Reviews__name">
                        <span
                            style={{ fontSize: "1.1em", lineHeight: "1.2em" }}
                        >
                            <Twemoji options={{ className: "twemoji-promo" }}>
                                {item.name}
                            </Twemoji>
                        </span>{" "}
                        {item.vkUrl && (
                            <span className="B-Reviews__vk-icon">
                                <VkLogo />
                            </span>
                        )}
                    </div>
                </Cell>
            );
        });
    };

    const noHeading = !data.content.title && !data.content.text;

    return (
        <div
            className={`B-Block B-Reviews ${
                noHeading ? "B-Reviews_noHeading" : ""
            } ${blockAlignment}`}
            style={{
                paddingTop: `${blockPadding}px`,
                paddingBottom: `${blockPadding}px`,
                fontSize: `${textFontSize}em`,
            }}
        >
            {(data.content.title || data.content.text) &&
                (data.has_title === undefined || data.has_title) && (
                    <div className="B-Block__head">
                        {data.content.title && (
                            <div className="B-Block__title">
                                <Twemoji
                                    options={{ className: "twemoji-promo" }}
                                >
                                    {data.content.title}
                                </Twemoji>
                            </div>
                        )}
                        {data.content.text && (
                            <div className="B-Block__subtitle">
                                <Twemoji
                                    options={{ className: "twemoji-promo" }}
                                >
                                    {simpleTextToHtml(data.content.text)}
                                </Twemoji>
                            </div>
                        )}
                    </div>
                )}
            <div className="B-Reviews__items">{renderItems()}</div>
        </div>
    );
};

export default Reviews;
