import React from "react";
import { ReactComponent as GhostIcon } from "../../../resources/icons/GhostIcon.svg";

const FatalError = () => {
    return (
        <>
            <div className="FatalError">
                <div className="FatalError__img">
                    <GhostIcon />
                </div>
                <p className="bold head">Возникла непредвиденная ошибка</p>
                <p className="sub">Пожалуйста, обратитесь в техподдержку</p>
                <p>
                    <a
                        className="btn-link"
                        href="https://vk.com/im?media=&sel=-139157852"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Сообщить об ошибке
                    </a>
                </p>
                <p className="FatalError__footer">
                    <span>Senler - сервис рассылок</span>
                </p>
            </div>
        </>
    );
};
export default FatalError;
