import React, { useEffect, useRef, useContext, useState } from "react";
import { Context } from "../../../context";
import {
    PAGES_BLOCK_EDIT_BUTTON,
    Analytics,
} from "../../../includes/Metrics/Analytics";
import PagesTemplates from "../../../includes/PagesTemplates";

import Cover from "./Cover";
import Video from "./Video";
import Text from "./Text";
import Button from "./Button";
import Advantages from "./Advantages";

import ImagesSingle from "./Images/ImagesSingle";
import ImagesGallery from "./Images/ImagesGallery";

import Reviews from "./Reviews";
import Products from "./Products";
import TimerDate from "./TimerDate";
import Divider from "./Divider";
import TimerFixed from "./TimerFixed";
import TimerUserFirstVisit from "./TimerUserFirstVisit";
import { Div, FixedLayout } from "@vkontakte/vkui";
import { Icon16Add, Icon28EditOutline, Icon28HideOutline, Icon28UsersOutline, Icon28ViewOutline } from "@vkontakte/icons";
import { blockHasAudience } from "../../../includes/Helpers/Helpers";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../includes/Constants/PageBlockConstants";

type BlockEditWrapperProps = {
    data: any;
    lastBlock: any;
    isActive: boolean;
    platform: string;
    isPreview: boolean;
    snackbar: any;
    currentEditableBlock: string;
    renderEditableBlockSnackbar: any;
    isStateTravelMode: any;
    onBlockClick: Function;
    onBlockHover: Function;
    onAddBlockClick: Function;
    onEditBlockClick: Function;
    onDisableClick: Function;
    renderCommonSnackbar: Function;
};

const HEADER_HEIGHT = 56;
const SANCKBAR_HEIGHT = 50;

const BlockEditWrapper = (props: BlockEditWrapperProps) => {
    const {
        data,
        onBlockClick,
        onAddBlockClick,
        onEditBlockClick,
        onDisableClick,
        onBlockHover,
        isActive,
        isPreview = false,
        snackbar,
        platform,
        currentEditableBlock,
        renderEditableBlockSnackbar,
        renderCommonSnackbar,
        isStateTravelMode,
        lastBlock,
    } = props;

    const { pages } = useContext(Context);

    const pagesBlocks = { ...PagesTemplates.types };

    const lastAddedBlock = pages.getLastAddedBlock();
    const isRecentlyAdded = lastAddedBlock && lastAddedBlock === data.id;
    const rootElem = useRef(null);

    const [blockPadding, setBlockPadding] = useState(() => {
        let default_block_padding;

        switch (data.type) {
            case "cover":
                default_block_padding = 100;
                break;
            case "advantages":
                default_block_padding = 40;
                break;
            case "text":
                default_block_padding = 20;
                break;
            case "button":
                default_block_padding = 20;
                break;
            case "image":
                default_block_padding = 0;
                break;
            case "reviews":
                default_block_padding = 20;
                break;
            case "products":
                default_block_padding = 25;
                break;
            case "video":
                default_block_padding = 0;
                break;
            case "timer":
                default_block_padding = 80;
                break;
            default:
                default_block_padding = 0;
                break;
        }

        return data.meta && (data.meta.padding || data.meta.padding === 0)
            ? data.meta.padding
            : default_block_padding;
    });

    const [textFontSize, setTextFontSize] = useState(() => {
        return data.meta &&
            (data.meta.text_font_size || data.meta.text_font_size === 0)
            ? data.meta.text_font_size
            : DEFAULT_BLOCK_TEXT_FONT_SIZE;
    });

    const [blockAlignment, setBlockAlignment] = useState(() => {
        return data.meta && data.meta.alignment
            ? data.meta.alignment
            : "center";
    });

    function findPos(obj) {
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return [curtop];
        }
    }

    const scrollHandler = () => {
        if (!isActive) return false;

        const elem = document.querySelector(
            `[data-id="${data.id}"]`
        ) as HTMLElement;

        if (!elem) return false;

        // Позиция блока относительно viewport
        const elemPosition = elem.getBoundingClientRect();
        const elemHeight = elem.offsetHeight;
        const viewPortHeight = window.innerHeight;
        // Позиция нижнего края блока относительно viewport
        const bottomEdgePosition = elemPosition.top + elemHeight;
        // Позиция нижнего края блока относительно viewport с учетом высоты снэкбара фиксированного внизу экрана
        const bottomEdgePositionRelativeToViewport =
            bottomEdgePosition - (viewPortHeight - SANCKBAR_HEIGHT);

        const topEdgePositionRelativeToViewport =
            elemPosition.top - HEADER_HEIGHT;

        const stickyElemSelector =
            platform === "desktop_web"
                ? ".B-Block__edit-actions_desktop"
                : ".B-Block__edit-button_edit";
        const stickyElem = elem.querySelector(stickyElemSelector);

        if (!stickyElem) return false;

        // На десктопах фиксируем снэкбар
        if (platform === "desktop_web") {
            if (topEdgePositionRelativeToViewport < 0) {
                stickyElem.classList.add("sticky");
            } else {
                stickyElem.classList.remove("sticky");
            }
            // На мобильных - фиксируем кнопку
        } else {
            if (bottomEdgePositionRelativeToViewport > 0) {
                stickyElem.classList.add("sticky");
            } else {
                stickyElem.classList.remove("sticky");
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        scrollHandler();
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        scrollHandler();
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [isActive]);

    useEffect(() => {
        let timeout = platform === "desktop_web" ? 800 : 700;

        if (isRecentlyAdded) {
            setTimeout(() => {
                if (rootElem.current) {
                    let rootElemPosition = findPos(rootElem.current);
                    if (rootElemPosition.length && rootElemPosition[0] > 0) {
                        document.querySelector("html").scrollTo({
                            top: rootElemPosition[0] - 100,
                            behavior: "smooth",
                        });
                    }
                }
                pages.setLastAddedBlock(null);
            }, timeout);
        }
    }, [rootElem]);

    const handleBlockClick = () => {
        onBlockClick(data);
    };

    const handleBlockEditClick = (e) => {
        e.stopPropagation();
        onEditBlockClick && onEditBlockClick(data);
    };

    const handleBlockHover = () => {
        if (pages.getLastAddedBlock()) return false;
        onBlockHover(data);
    };

    const handleAddBlockClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onAddBlockClick(data);
    };

    const blocks = {
        cover: {
            c1: (
                <Cover
                    data={data}
                    platform={platform}
                    isPreview={isPreview}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        advantages: {
            a1: (
                <Advantages
                    data={data}
                    platform={platform}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        text: {
            t1: (
                <Text
                    data={data}
                    isPreview={isPreview}
                    onButtonClick={() => {}}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        button: {
            b1: (
                <Button
                    data={data}
                    isPreview={isPreview}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                />
            ),
        },

        image: {
            i1: (
                <ImagesGallery
                    data={data}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
            i2: (
                <ImagesSingle
                    data={data}
                    isEdit={true}
                    platform={platform}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                />
            ),
        },

        reviews: {
            r1: (
                <Reviews
                    data={data}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        products: {
            p1: (
                <Products
                    data={data}
                    platform={platform}
                    isPreview={isPreview}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        video: {
            v1: (
                <Video
                    data={data}
                    isPreview={false}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                    editMode
                />
            ),
        },

        timer: {
            tm1: (
                <TimerDate
                    data={data}
                    platform={platform}
                    isPreview={isPreview}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
            tm2: (
                <TimerFixed
                    data={data}
                    platform={platform}
                    isPreview={isPreview}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
            tm3: (
                <TimerUserFirstVisit
                    data={data}
                    platform={platform}
                    isPreview={isPreview}
                    blockPadding={blockPadding}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        other: {
            ot1: <Divider data={data} isPreview={isPreview} />,
        },
    };

    let blockComponent = null;

    if (blocks[data.type] && blocks[data.type][data.key]) {
        blockComponent = blocks[data.type][data.key];
    } else {
        // log error
    }

    const classNames = ["B-Block__edit", blockAlignment];

    if (isActive) {
        classNames.push("B-Block__edit_active");
    }

    if (
        (lastBlock.type === "button" || lastBlock.type === "text") &&
        data.id === lastBlock.id
    ) {
        classNames.push("B-Block__last");
    }

    return (
        <>
            <div
                data-id={data.id}
                ref={rootElem}
                className={classNames.join(" ")}
                onClick={
                    platform === "desktop_web" ? () => {} : handleBlockClick
                }
                onMouseEnter={
                    platform === "desktop_web" ? handleBlockHover : () => {}
                }
            >
                {platform !== "desktop_web" &&
                    data.audience &&
                    blockHasAudience(data.audience.data) && (
                        <div
                            className={`BlockEdit__audience-status ${
                                data.status !== 1 ? "hidden" : ""
                            }`}
                        >
                            <Icon28UsersOutline
                                fill={
                                    data.audience.status === 1
                                        ? "#5cb85c"
                                        : "#E64646"
                                }
                                width={22}
                            />
                        </div>
                    )}

                {platform === "desktop_web"
                    ? snackbar(
                          blockPadding,
                          setBlockPadding,
                          setBlockAlignment,
                          blockAlignment,
                          textFontSize,
                          setTextFontSize
                      )
                    : null}

                {data.status === 1 ? (
                    blockComponent
                ) : (
                    <div className="B-Block-hidden">
                        <div>
                            <div className="B-Block-hidden__title">
                                Блок скрыт
                            </div>
                            <div className="B-Block-hidden__blockType">
                                {pagesBlocks[data.type].name}
                            </div>
                        </div>

                        <Icon28HideOutline
                            style={{ paddingRight: 3 }}
                            width={22}
                            height={22}
                            fill="var(--vkui--color_text_secondary)"
                        />
                    </div>
                )}

                <button
                    className={`B-Block__edit-button ${
                        isActive ? "active" : ""
                    }`}
                    onClick={handleAddBlockClick}
                >
                    <Icon16Add /> <span>Добавить блок</span>
                </button>

                {platform !== "desktop_web" && data.status === 0 && (
                    <button
                        className={`B-Block__edit-button_edit ${
                            isActive ? "active" : ""
                        } ${
                            platform !== "desktop_web"
                                ? "B-Block__edit-button_hoveroff"
                                : ""
                        }`}
                        onClick={(v) => {
                            v.stopPropagation();

                            const statusToSend = data.status === 1 ? 0 : 1;

                            onDisableClick &&
                                onDisableClick(
                                    data,
                                    data.page_id,
                                    data.id,
                                    statusToSend
                                );
                        }}
                    >
                        <Icon28ViewOutline
                            width={22}
                            height={22}
                            fill="var(--vkui--color_text_accent)"
                        />
                    </button>
                )}

                {platform !== "desktop_web" && data.status === 1 && (
                    <button
                        className={`B-Block__edit-button_edit ${
                            isActive ? "active" : ""
                        }`}
                        onClick={(v) => {
                            Analytics.goal(PAGES_BLOCK_EDIT_BUTTON, "pages");
                            handleBlockEditClick(v);
                        }}
                    >
                        <Icon28EditOutline
                            width={22}
                            height={22}
                            fill="var(--vkui--color_text_accent)"
                        />
                    </button>
                )}
            </div>

            {platform !== "desktop_web" &&
            currentEditableBlock &&
            data.id === currentEditableBlock &&
            !isStateTravelMode ? ( // Если редактируем с мобильного устройства и выбран текущий редактируемый блок
                <FixedLayout
                    style={{ zIndex: 100 }}
                    filled={true}
                    vertical="bottom"
                >
                    <Div style={{ padding: 0 }}>
                        {renderEditableBlockSnackbar(
                            blockPadding,
                            setBlockPadding,
                            setBlockAlignment,
                            blockAlignment,
                            textFontSize,
                            setTextFontSize
                        )}
                    </Div>
                </FixedLayout>
            ) : (
                renderCommonSnackbar()
            )}
        </>
    );
};

export default BlockEditWrapper;
