import { clone } from "../../../../../../includes/Helpers/Helpers";

export const MODULE_NAME = "product_form";

export const SET_PRODUCT_NAME = `${MODULE_NAME}/set-product-name`;
export const SET_PRODUCT_TEXT = `${MODULE_NAME}/set-product-text`;
export const SET_PRODUCT_CATEGORY = `${MODULE_NAME}/set-product-category`;
export const SET_PRODUCT_PRICE = `${MODULE_NAME}/set-product-price`;
export const SET_PRODUCT_OLD_PRICE = `${MODULE_NAME}/set-product-old-price`;
export const SET_PRODUCT_IMG = `${MODULE_NAME}/set-product-img`;
export const SET_PRODUCT_IMG_ERROR = `${MODULE_NAME}/set-product-img-error`;

export const SET_BUTTON_DATA = `${MODULE_NAME}/set-button-data`;
export const SET_BUTTON_ERRORS = `${MODULE_NAME}/set-button-errors`;

export const SET_IS_PENDING = `${MODULE_NAME}/set-is-pending`;

export const SET_ERRORS = `${MODULE_NAME}/set-errors`;

export const initialState = {
    isPending: false,

    name: "",
    text: "",
    price: "",
    price_old: "",
    category: "",

    button: {},

    img: "",

    errors: {
        button: {},
        img_error: "",
    },
};

export default function (state, action) {
    const { type, payload } = action;

    const newState = clone(state);

    switch (type) {
        case SET_IS_PENDING:
            newState.isPending = payload;
            return newState;

        case SET_PRODUCT_NAME:
            newState.name = payload;
            return newState;

        case SET_PRODUCT_TEXT:
            newState.text = payload;
            return newState;

        case SET_PRODUCT_PRICE:
            newState.price = payload;
            return newState;

        case SET_PRODUCT_OLD_PRICE:
            newState.price_old = payload;
            return newState;

        case SET_PRODUCT_CATEGORY:
            newState.category = payload;
            return newState;

        case SET_PRODUCT_IMG:
            newState.img = payload;
            return newState;

        case SET_PRODUCT_IMG_ERROR:
            newState.errors = { ...state.errors };
            newState.errors.img_error = payload;
            return newState;

        case SET_BUTTON_DATA:
            newState.button = { ...payload };
            return newState;

        case SET_BUTTON_ERRORS:
            newState.errors.button = payload;
            return newState;

        case SET_ERRORS:
            newState.errors = payload;
            return newState;

        default:
            return newState;
    }
}
