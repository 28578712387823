import React from "react";
import { ScreenSpinner, Div, Alert, Snackbar } from "@vkontakte/vkui";
import bridge from "@vkontakte/vk-bridge";

import {
    widgets as widgetsList,
    MODAL_NULL,
    LOCATION_WIDGET_HOME,
} from "./Constants";
import { setHash } from "./Helpers/Helpers";
import {
    SnackbarAction,
    BlockData,
    PageStateData,
    PageStateManageInterface,
} from "./Structures";
import pagesToEntities from "../includes/Mappings/PagesToEntities";
import pageToEntity from "./Mappings/Pages/Page";
import pageBlocksToEntities from "./Mappings/Pages/PageBlocksToEntities";
import Config from "./Config";

import AlertBase from "../Сomponents/Modals/Alert/AlertBase";
import { Icon24Report, Icon28CheckCircleOutline } from "@vkontakte/icons";
import * as Sentry from "@sentry/react";

export const getInitialState = (initialUrlParams, initialLocationList) => {
    return {
        popout: null,
        snackbar: null,
        subscription_id: initialUrlParams.params.s,
        route: "loading",
        published: [],
        widgets: [],
        activeWidget: null,
        activePage: null,
        newWidget: null,
        lastActiveTypeFilter: null,
        currentModal: MODAL_NULL,
        locationRoute: "loading",
        locationList: initialLocationList,
        locationView: "main",
        locationPanel: "add",
        locationWidget: "",
        locationPage: "",
        locationTab: "",
        locationSid: "",
        locationBlock: "",
        isPagesStatisticPreviousePage: false,
        blockType: "",

        groupSiteData: {
            default_subscription_id: 1,
        },

        groupIsConnected: false,

        admins: [],

        subscriptions: [],

        bots: [],

        pages: {
            items: [],
            currentEditableBlock: null, // string - block.id
            landingSubscriptions: {},
            /**
             * @TODO - Возможно стоит брать доступные стейты прямо из объекта страницы в общем стейте
             * и отдельно оставить только счетчик активного стейта
             */
            stateManage: {
                active: 0,
                available: [],
            },

            recentBlocks: [],

            lastAddedBlock: null, // Для отслеживания того, что был добавлен новый блок
        },

        pagesMaxItemsCounters: {},

        initialSettingsSlide: 0,

        guide: {
            seen_title_tooltip: true,
            seen_context_tooltip: true,
            visited_initial_settings: true,
        },

        emojiPopout: {
            isOpen: false,
            callback: () => {},
        },

        urlParams: initialUrlParams,

        inputDynamicName: "",

        scheme: "bright_light",

        page_hit_data: null,

        addBlockMode: "add", // add | insert

        currentEditableProducts: [],

        hash: "",

        copiedBlockAudience: {},

        pageIdForWebView: "",

        paymentMethods: [],

        isPaidSubscription: false,
    };
};

/**
 * Управление основным всплывающим окном приложения
 * @popout
 */
export const mainPopout = (App) => {
    return {
        /**
         * @param type - нужно ли учитывать всплывающий элемент в навигации.
         * Модалки (alert) не учитываются
         * ActionSheet учитываются
         */
        open: (component, type: string = "alert", cb = () => {}) => {
            App.setState({ popout: component }, cb);
            if (type === "context") {
                window.history.pushState(
                    null,
                    document.title,
                    window.location.href
                );
            }
        },

        loading: () => {
            App.setState({ popout: <ScreenSpinner /> });
        },

        close: (callback?: Function) => {
            App.setState({ popout: null }, () => {
                callback && callback();
            });
        },

        confirm: (text, title = "", actions = []) => {
            if (actions.length === 0) {
                actions = [
                    { title: "Закрыть", autoClose: true, mode: "destructive" },
                ];
            }

            App.setState({
                popout: (
                    <Alert
                        actions={actions}
                        actionsLayout="vertical"
                        onClose={() => App.setState({ popout: null })}
                    >
                        {title && <h2>{title}</h2>}
                        <Div style={{ paddingLeft: 0, paddingRight: 0 }}>
                            {text}
                        </Div>
                    </Alert>
                ),
            });
        },

        error: (text, title = "Ошибка", actions = []) => {
            if (actions.length === 0) {
                actions = [
                    { title: "Закрыть", autoClose: true, mode: "destructive" },
                ];
            }
            App.setState({
                popout: (
                    <Alert
                        actions={actions}
                        actionsLayout="vertical"
                        onClose={() => App.setState({ popout: null })}
                    >
                        <h2>{title}</h2>
                        <Div style={{ paddingLeft: 0, paddingRight: 0 }}>
                            {text}
                        </Div>
                    </Alert>
                ),
            });
        },
        isActive: () => {
            return !!App.state.popout;
        },

        successAnimation: (text: string, title: string = "", body = null) => {
            App.setState({
                popout: (
                    <AlertBase
                        type="success"
                        text={text}
                        body={body}
                        onClose={() => App.setState({ popout: null })}
                    />
                ),
            });
        },

        errorAnimation: (text: string, actions = []) => {
            App.setState({
                popout: (
                    <AlertBase
                        type="error"
                        text={text}
                        actions={actions}
                        onClose={() => App.setState({ popout: null })}
                    />
                ),
            });
        },
    };
};

/**
 * Управление основным снэкбаром приложения
 */
export const mainSnackbar = (App) => {
    return {
        open: (component) => {
            App.setState({ snackbar: component });
        },
        close: (callback) => {
            App.setState({ snackbar: null }, () => {
                callback && callback();
            });
        },
        showError: (message, action: SnackbarAction = {}) => {
            const snackbar = (
                <Snackbar
                    onClose={() => App.mainSnackbar.close()}
                    action={action.text ? action.text : null}
                    onActionClick={
                        action.callback
                            ? action.callback
                            : (e: React.MouseEvent) => {}
                    }
                    before={<Icon24Report fill={`var(--vkui--color_accent_red)`} />}
                >
                    {message}
                </Snackbar>
            );
            App.mainSnackbar.open(snackbar);
        },
        showSuccess: (message, action: SnackbarAction = {}) => {
            const snackbar = (
                <Snackbar
                    onClose={() => App.mainSnackbar.close()}
                    action={action.text ? action.text : null}
                    onActionClick={
                        action.callback
                            ? action.callback
                            : (e: React.MouseEvent) => {}
                    }
                    before={
                        <Icon28CheckCircleOutline
                            width={24}
                            height={24}
                            fill={`var(--vkui--color_accent_green)`}
                        />
                    }
                >
                    {message}
                </Snackbar>
            );
            App.mainSnackbar.open(snackbar);
        },
    };
};

// Управление данными о группе с сайта senler.ru и подключена ли она на сайте
export const groupSiteData = (App) => {
    return {
        isConnected: (): boolean => {
            return App.state.groupIsConnected;
        },

        setIsConnected: (value: boolean) => {
            App.setState({ groupIsConnected: value });
        },

        get: () => {
            return App.state.groupSiteData;
        },

        set: (data, callback) => {
            App.setState({ groupSiteData: data }, () => {
                callback && callback();
            });
        },

        setHash: (hash: string) => {
            App.setState({
                hash: hash,
            });
        },

        getHash: () => {
            return App.state.hash;
        },

        setDefaultSubscription: (state, callback) => {
            App.setState(
                {
                    defaultSubscription: state,
                },
                () => {
                    callback && callback();
                }
            );
        },

        getDefaultSubscriptionId: () => {
            return App.state.defaultSubscription;
        },

        setAdmins: (admins) => {
            App.setState({ admins: admins });
        },

        getAdmins: () => {
            return App.state.admins;
        },
    };
};

export const subscriptions = (App) => {
    return {
        set: (items) => {
            App.setState({ subscriptions: items });
        },

        get: () => {
            return App.state.subscriptions;
        },
    };
};

export const bots = (App) => {
    return {
        set: (items) => {
            App.setState({ bots: items });
        },

        get: () => {
            return App.state.bots;
        },
    };
};

export const initialSettings = (App) => {
    return {
        getCurrentSlide: () => {
            return App.state.initialSettingsSlide;
        },

        next: () => {
            let nextSlide = App.state.initialSettingsSlide + 1;
            App.setState({ initialSettingsSlide: nextSlide });
        },

        prev: () => {
            let prevSlide = App.state.initialSettingsSlide - 1;
            if (prevSlide < 0) {
                prevSlide = 0;
            }
            App.setState({ initialSettingsSlide: prevSlide });
        },

        changeSlide: (slideIdx) => {
            App.setState({ initialSettingsSlide: slideIdx });
        }
    };
};

export const guide = (App) => {
    return {
        get: () => {
            return App.state.guide;
        },

        set: (guide) => {
            App.setState({ guide });
        },
    };
};

/**
 * Управление виджетами приложения
 * @param App
 */
export const widgets = (App) => {
    return {
        set: (items) => {
            App.setState({
                widgets: items.all,
                published: items.published,
            });
        },
        setAll: (items, callback = () => {}) => {
            App.setState(
                {
                    widgets: items,
                },
                () => {
                    callback && callback();
                }
            );
        },
        add: (widget) => {
            const newWidgets = [...App.state.widgets];
            newWidgets.push(widget);
            App.setState({ widgets: newWidgets });
        },
        replaceName: (widget) => {
            const newWidgets = [...App.state.widgets];

            newWidgets.forEach((w, index) => {
                if (w.id === widget.id) {
                    w.name = widget.name;
                }
            });
            App.setState({ widgets: newWidgets });
        },

        updateBody: (widget, code) => {
            const newWidgets = [...App.state.widgets];

            newWidgets.forEach((w, index) => {
                if (w.id === widget.id) {
                    w.code = code;
                }
            });
            App.setState({ widgets: newWidgets });
        },

        updateAudience: (widget, audience) => {
            const newWidgets = [...App.state.widgets];

            newWidgets.forEach((w, index) => {
                if (w.id === widget.id) {
                    w.audience = audience;
                }
            });
            App.setState({ widgets: newWidgets });
        },

        setNew: (type_api) => {
            App.setState({
                newWidget: widgetsList.filter(
                    (w) => w.type_api === type_api
                )[0],
            });
        },
        getNew: () => {
            return App.state.newWidget;
        },
        setActiveById: (id) => {
            App.setState({
                activeWidget: App.state.widgets.filter((i) => i.id === id)[0],
            });
        },
        setActive: (w) => {
            App.setState({
                activeWidget: w,
            });
        },
        getActive: () => {
            return App.state.activeWidget;
        },
        getPublished: () => {
            return App.state.published;
        },
        getAll: () => {
            return App.state.widgets;
        },
        getAllByType: (widgetType: string) => {
            return App.state.widgets.filter(w => w.type === widgetType);
        },
        isExists: (widget_id) => {
            if (!App.state.widgets) return false;
            return (
                typeof App.state.widgets.filter(
                    (i) => i.id === widget_id
                )[0] !== "undefined"
            );
        },
    };
};

export const lastActiveTypeFilter = (App) => {
    return {
        set: (type) => {
            App.setState({ lastActiveTypeFilter: type });
        },
        get: () => {
            return App.state.lastActiveTypeFilter;
        },
    };
};

export const currentModal = (App) => {
    return {
        get: () => {
            return App.state.currentModal;
        },

        close: () => {
            App.setState({ currentModal: MODAL_NULL });
        },

        set: (modal) => {
            App.setState({ currentModal: modal });
            if (modal && modal.id) {
                window.history.pushState(
                    null,
                    document.title,
                    window.location.href
                );
            }
        },
        isActive: () => {
            return App.state.currentModal && App.state.currentModal.id !== null;
        },
    };
};

export const inputDynamic = (App) => {
    return {
        setCurrent: (name) => {
            App.setState({ inputDynamicName: name });
        },

        getCurrent: (name) => {
            return App.state.inputDynamicName;
        },
    };
};

/**
 * Управление навигацией приложения
 * @param App
 */
export const location = (App) => {
    return {
        getList: () => {
            return App.state.locationList;
        },

        getView: () => {
            return App.state.locationView;
        },

        getPanel: () => {
            return App.state.locationPanel;
        },

        getSubscriptionId: () => {
            return App.state.locationSid;
        },

        homeWidgets: () => {
            App.location.set(LOCATION_WIDGET_HOME);
        },

        homePages: () => {
            App.location.set({ list: "pages" });
        },

        getActiveTab: () => {
            return App.state.locationTab;
        },

        getSid: () => {
            return App.state.locationSid;
        },

        getPageId: () => {
            return App.state.locationPage;
        },

        getBlockType: () => {
            return App.state.blockType;
        },

        getEditBlockId: () => {
            return App.state.locationBlock;
        },

        set: (locationObject) => {
            let loc: any = {};

            if (locationObject.list) {
                loc.locationList = locationObject.list;
            } else {
                loc.locationList = "subscriptions";
            }

            if (locationObject.view) {
                loc.locationView = locationObject.view;
            }

            if (locationObject.panel) {
                loc.locationPanel = locationObject.panel;
            }

            if (locationObject.widget_id) {
                loc.locationWidget = locationObject.widget_id;
            } else {
                loc.locationWidget = "";
            }

            if (locationObject.tab) {
                loc.locationTab = locationObject.tab;
            } else {
                loc.locationTab = "";
            }

            if (locationObject.s_id) {
                loc.locationSid = locationObject.s_id;
            } else {
                loc.locationSid = "";
            }

            if (locationObject.page_id) {
                loc.locationPage = locationObject.page_id;
            }

            if (locationObject.block_type) {
                loc.blockType = locationObject.block_type;
            }

            if (locationObject.block_id) {
                loc.locationBlock = locationObject.block_id;
            }

            if (locationObject.paymentMethods) {
                loc.paymentMethods = locationObject.paymentMethods;
            }

            App.setState({ ...loc, currentModal: MODAL_NULL, popout: null });
        },

        setForceSubscriptionPage: (subscription_id) => {
            App.setState({
                subscription_id: subscription_id,
                locationSid: subscription_id,
            });
        },

        flushToCatalog: async () => {
            // Отправим событие в bridge на сброс хэша
            const resp = await bridge.sendPromise("VKWebAppSetLocation", {
                location: "#",
            });
            if (resp.result === true) {
                // Сбросим id страницы подписки
                App.setState(
                    {
                        subscription_id: undefined,
                        locationSid: "",
                    },
                    () => {
                        // Установим новый хэш внтури приложения
                        setHash({ list: "subscriptions" });
                    }
                );
            }
        },

        setIsPagesStatisticPreviousPage: (value: boolean) => {
            App.setState({ isPagesStatisticPreviousePage: value });
        },

        getIsPagesStatisticPreviousPage: () => {
            return App.state.isPagesStatisticPreviousePage;
        },
    };
};

/**
 * Управление темой приложения
 * @param App
 */
export const scheme = (App) => {
    return {
        get: () => {
            return App.state.theme;
        },

        set: (value) => {
            App.setState({ theme: value });
        },

        isLight: () => {
            return (
                App.state.theme === "bright_light" ||
                App.state.theme === "vkcom_light"
            );
        },

        isDark: () => {
            return (
                App.state.theme === "space_gray" ||
                App.state.theme === "vkcom_dark"
            );
        },
    };
};

export const urlParams = (App) => {
    return {
        get: () => {
            return App.state.urlParams;
        },
    };
};

export const resizer = (App, UrlParams, Logger) => {
    return {
        // Установит высоту iframe приложения исходя из высоты внутреннего контента приложения
        // Вызывать лучше асинхронно после получения контента и установки нового state
        // + необходимо обработать тот случай, если задана страница подписки по-умолчанию
        // Этот факт мы получаем асинхронно, поэтому передаем параметр после получения данных
        setHeight: (subscriptionPageAsDefault = false) => {
            try {
                const body = document.body;

                // Обертка всего внутреннего контента
                const contentInner = document.querySelector(
                    ".content--inner"
                ) as HTMLElement;

                if (
                    bridge.supports("VKWebAppResizeWindow") &&
                    UrlParams.params.vk_platform === "desktop_web"
                ) {
                    // Вычисляем высоту, подходящую под высоту внешнего окна, но не более 8050px
                    const fitDisplayHeight =
                        Math.min(
                            Config.max_app_height,
                            Math.min(
                                App.appInitialHeight,
                                window.outerHeight - 300
                            )
                        ) + 55;

                    let resultAppHeight = fitDisplayHeight;

                    const list = App.location.getList();

                    // Одиночная страница подписки
                    const isSingle =
                        !!App.location.getSid() || !!App.state.subscription_id;

                    if (
                        (contentInner && list !== "widgets" && isSingle) || // Если одиночная страница подписки
                        subscriptionPageAsDefault || // Если страница подписки задана по-уполчанию
                        App.state.route === "landing"
                    ) {
                        // Если лэндинг

                        if (UrlParams.params.vk_platform === "desktop_web") {
                            body.style.overflowY = "hidden";
                        }

                        if (contentInner) {
                            const realContentHeight = contentInner.offsetHeight;
                            const extraHeight =
                                App.state.route === "landing" ? 80 : 120;

                            resultAppHeight = Math.min(
                                realContentHeight + extraHeight,
                                Config.max_app_height
                            );
                        }
                    } else {
                        body.style.overflowY = "auto";
                    }

                    bridge.send("VKWebAppResizeWindow", {
                        height: resultAppHeight < 600 ? 600 : resultAppHeight,
                        width: Config.app_width,
                    });
                } else {
                    console.log("Event VKWebAppResizeWindow not supported");
                }
            } catch (e) {
                Logger.error(
                    {
                        code: 9012,
                        message: e.message,
                    },
                    "set_height_error",
                    "App.js"
                );

                Sentry.captureException(e);
            }
        },

        setLandingHeight: () => {
            try {
                const body = document.body;
                // Обертка всего внутреннего контента
                const contentInner = document.querySelector(
                    ".content--inner"
                ) as HTMLElement;

                if (
                    bridge.supports("VKWebAppResizeWindow") &&
                    UrlParams.params.vk_platform === "desktop_web"
                ) {
                    // Вычисляем высоту, подходящую под высоту внешнего окна, но не более заданной максимальной высоты
                    const fitDisplayHeight =
                        Math.min(
                            Config.max_landing_app_height,
                            Math.min(
                                App.appInitialHeight,
                                window.outerHeight - 300
                            )
                        ) + 55;

                    let resultAppHeight = fitDisplayHeight;

                    if (App.state.route === "page") {
                        // Если лэндинг

                        body.style.overflowY = "hidden";

                        if (contentInner) {
                            const realContentHeight = contentInner.offsetHeight;
                            resultAppHeight = Math.min(
                                realContentHeight,
                                Config.max_landing_app_height
                            );
                        }
                    } else {
                        body.style.overflowY = "auto";
                    }

                    bridge.send("VKWebAppResizeWindow", {
                        height: resultAppHeight,
                        width: Config.app_width,
                    });
                } else {
                    console.log("Event VKWebAppResizeWindow not supported");
                }
            } catch (e) {
                Logger.error(
                    { code: 9013, message: e.message },
                    "set_landing_height_error",
                    "App.js"
                );

                Sentry.captureException(e);
            }
        },
    };
};

/**
 * @pages
 */
export const pages = (App) => {
    class p implements PageStateManageInterface {
        getAll() {
            return [...App.state.pages.items];
        }

        setAll(items) {
            const state = { ...App.state.pages };
            state.items = pagesToEntities(items);
            App.setState({ pages: state });
        }

        setActivePageId(page_id: string) {
            App.setState({ activePage: page_id });
        }

        getPageById(id: string) {
            return App.state.pages.items.filter((i) => i.id === id)[0];
        }

        addPage(page, callback = () => {}) {
            const state = { ...App.state.pages };
            const newPage = pageToEntity(page);

            state.items.unshift(newPage);

            App.setState(
                {
                    activePage: page.id,
                    pages: state,
                },
                () => {
                    callback();
                }
            );
        }

        setActiveBlocks(blocks: any[], callback = () => {}) {
            const updatedActivePage = { ...App.state.activePage };
            updatedActivePage.blocks = blocks;

            App.setState(
                {
                    activePage: updatedActivePage,
                },
                () => {
                    callback();
                }
            );
        }

        pushBlock(page_id, block: any, callback = () => {}) {
            const state = { ...App.state.pages };
            state.items.forEach((page) => {
                if (page_id === page.id) {
                    page.blocks_edit.push(block);
                }
            });

            state.lastAddedBlock = block.id;

            App.setState(
                {
                    pages: state,
                },
                () => {
                    callback();
                }
            );
        }

        insertBlock(page_id, data, callback = () => {}) {
            const state = { ...App.state.pages };

            state.items.forEach((page) => {
                if (page.id === page_id) {
                    page.blocks_edit = pageBlocksToEntities(data.blocks);
                }
            });

            state.lastAddedBlock = data.newBlock.id;
            state.currentEditableBlock = data.newBlock.id;
            state.recentBlocks = data.recentBlocks;
            state.stateManage = {
                active: 0,
                available: [...data.states],
            };

            App.setState(
                {
                    pages: state,
                },
                () => {
                    callback && callback();
                }
            );
        }

        getLastAddedBlock() {
            return App.state.pages.lastAddedBlock;
        }

        setLastAddedBlock(value, callback = () => {}) {
            const state = { ...App.state.pages };
            state.lastAddedBlock = value;
            App.setState(
                {
                    pages: state,
                },
                () => {
                    callback();
                }
            );
        }

        deleteBlock(block_id, page_id) {
            const state = { ...App.state.pages };
            let page = null;
            let pageIndex = 0;

            state.items.forEach((p, i) => {
                if (p.id === page_id) {
                    page = p;
                    pageIndex = i;
                }
            });

            if (!page) {
                return false;
            }

            const blocks = page.blocks_edit.filter((b) => b.id !== block_id);
            page.blocks_edit = blocks;

            state.items[pageIndex] = page;

            App.setState({ pages: state, currentEditableBlock: null });
        }

        getPage(page_id: string) {
            return App.state.pages.items.filter((i) => i.id === page_id)[0];
        }

        getActivePage() {
            return App.state.activePage;
        }

        deletePages(ids: string[]) {
            const state = { ...App.state.pages };
            state.items = state.items.filter((i) => {
                if (ids.indexOf(i.id) < 0) return true;
                else return false;
            });

            App.setState({
                pages: state,
            });
        }

        updatePageMetaInfo(id: string, data, callback = () => {}) {
            const state = { ...App.state.pages };

            state.items.forEach((page) => {
                if (page.id === id) {
                    for (let i in data) {
                        if (page[i]) {
                            page[i] = data[i];
                        }
                    }
                }
            });

            App.setState(
                {
                    pages: state,
                },
                () => {
                    callback && callback();
                }
            );
        }

        updatePageBlocks(
            page_id: string,
            blocks: BlockData[],
            callback = () => {}
        ) {
            const state = { ...App.state.pages };

            state.items.forEach((page) => {
                if (page.id === page_id) {
                    page.blocks_edit = blocks;
                }
            });

            App.setState(
                {
                    pages: state,
                },
                () => {
                    callback && callback();
                }
            );
        }

        updatePagePublishedBlocks(
            page_id: string,
            blocks: BlockData[],
            callback = () => {}
        ) {
            const state = { ...App.state.pages };
            state.items.forEach((page) => {
                if (page.id === page_id) {
                    page.blocks = pageBlocksToEntities(blocks);
                }
            });

            App.setState(
                {
                    pages: state,
                },
                () => {
                    callback && callback();
                }
            );
        }

        isExists(page_id: string) {
            if (!App.state.pages.items) return false;
            return (
                typeof App.state.pages.items.filter(
                    (i) => i.id === page_id
                )[0] !== "undefined"
            );
        }

        setCurrentEditableBlock(block_id: string, callback = () => {}) {
            // Если выделяем блок - добавим действия в историю для корректной обработки кнопки "Назад" на андроиде
            if (
                block_id &&
                App.state.urlParams.params.vk_platform === "mobile_android"
            ) {
                window.history.pushState(
                    null,
                    document.title,
                    window.location.href
                );
            }

            App.setState(
                {
                    pages: {
                        ...App.state.pages,
                        currentEditableBlock: block_id,
                    },
                },
                () => {
                    callback && callback();
                }
            );
        }

        getCurrentEditableBlock() {
            return App.state.pages.currentEditableBlock;
        }

        sortBlocks(
            page_id: string,
            block_id: string,
            from: number,
            to: number,
            callback = (newBlocksOrder: any[]) => {}
        ) {
            const state = { ...App.state.pages };
            let page = null;
            let pageIndex = 0;

            state.items.forEach((p, i) => {
                if (p.id === page_id) {
                    page = p;
                    pageIndex = i;
                }
            });

            if (!page) {
                return false;
            }

            const newBlocks = [...page.blocks_edit];

            const oldSort = newBlocks[from].sort;

            newBlocks[from].sort = newBlocks[to].sort;
            newBlocks[to].sort = oldSort;

            newBlocks.splice(from, 1);
            newBlocks.splice(to, 0, page.blocks_edit[from]);

            page.blocks_edit = newBlocks;

            state.items[pageIndex] = page;

            App.setState({ pages: state }, () => {
                callback &&
                    callback(
                        newBlocks.map((b) => {
                            return { id: b.id, sort: b.sort };
                        })
                    );
            });
        }

        updateBlock(
            block_id: string,
            page_id: string,
            block_data: any,
            callback = () => {}
        ) {
            const state = { ...App.state.pages };
            let page = null;
            let pageIndex = 0;

            state.items.forEach((p, i) => {
                if (p.id === page_id) {
                    page = p;
                    pageIndex = i;
                }
            });

            if (!page) {
                return false;
            }

            const newBlocks = [...page.blocks_edit];

            newBlocks.forEach((block, index) => {
                if (block.id === block_id) {
                    newBlocks[index] = block_data;
                }
            });

            page.blocks_edit = newBlocks;

            state.items[pageIndex] = page;

            App.setState({ pages: state }, () => {
                callback && callback();
            });
        }

        getLandingSubscriptions() {
            return App.state.pages.landingSubscriptions;
        }

        setLandingSubscriptions(subscriptions: any) {
            const state = { ...App.state.pages };
            state.landingSubscriptions = subscriptions;

            App.setState({ pages: state });
        }

        /**
         * State management
         */

        setAvailablePageStates(states: PageStateData[], callback = () => {}) {
            const state = { ...App.state.pages };

            state.stateManage = {
                active: 0,
                available: [...states],
            };

            App.setState({ pages: state }, () => {
                callback && callback();
            });
        }

        getStateManagement() {
            return App.state.pages.stateManage;
        }

        undo(page_id: string) {
            const state = { ...App.state.pages };
            let active = state.stateManage.active + 1;

            if (active > state.stateManage.available.length - 1) {
                return false;
            }

            const blocks_edit = state.stateManage.available[active].blocks_edit;
            let page = null;
            let pageIndex = 0;

            state.items.forEach((p, i) => {
                if (p.id === page_id) {
                    page = p;
                    pageIndex = i;
                }
            });

            if (!page) {
                return false;
            }

            state.stateManage.active = active;

            page.blocks_edit = blocks_edit;
            state.items[pageIndex] = page;

            App.setState({ pages: state });
        }

        redo(page_id: string) {
            const state = { ...App.state.pages };
            let active = state.stateManage.active - 1;

            if (active < 0) {
                return false;
            }

            const blocks_edit = state.stateManage.available[active].blocks_edit;
            let page = null;
            let pageIndex = 0;

            state.items.forEach((p, i) => {
                if (p.id === page_id) {
                    page = p;
                    pageIndex = i;
                }
            });

            if (!page) {
                return false;
            }

            state.stateManage.active = active;

            page.blocks_edit = blocks_edit;
            state.items[pageIndex] = page;

            App.setState({ pages: state });
        }

        setRecentBlocks(items: any[]) {
            const state = { ...App.state.pages };
            state.recentBlocks = items;

            App.setState({ pages: state });
        }

        getRecentBlocks() {
            return [...App.state.pages.recentBlocks];
        }

        setHitData(hitData: any) {
            App.setState({ page_hit_data: hitData });
        }

        getHitId() {
            return App.state.page_hit_data.hit_id;
        }

        getMaxItemsCounters() {
            return { ...App.state.pagesMaxItemsCounters };
        }

        setMaxItemsCounters(counters: any) {
            App.setState({ pagesMaxItemsCounters: counters });
        }

        setAddBlockMode(value, callback = () => {}) {
            App.setState({ addBlockMode: value }, () => {
                callback && callback();
            });
        }

        getAddBlockMode() {
            return App.state.addBlockMode;
        }

        getCurrentEditableProducts() {
            return App.state.currentEditableProducts;
        }

        setCurrentEditableProducts(products) {
            App.setState({ currentEditableProducts: products });
        }

        updatePageStatus(id: string, status: number) {
            const state = { ...App.state.pages };

            state.items = App.state.pages.items.map((item) => {
                if (item.id === id) {
                    item.status = status;
                }
                return item;
            });

            App.setState({ pages: state });
        }

        setCopiedBlockAudience(blockAudience) {
            App.setState({ copiedBlockAudience: blockAudience });
        }

        getCopiedBlockAudience() {
            return App.state.copiedBlockAudience;
        }

        getPageIdForWebView() {
            return App.state.pageIdForWebView;
        }
    }

    return new p();
};

export const payment = (App) => {
    return {
        setPaymentMethods: (paymentMethods: []) => {
            return App.setState({ paymentMethods });
        },

        getPaymentMethods: () => {
            return App.state.paymentMethods;
        },

        setIsShowedPaymentsPage: (isShowedPaymentsPage: boolean) => {
            return App.setState({ isShowedPaymentsPage });
        },

        getIsShowedPaymentsPage: () => {
            return App.state.isShowedPaymentsPage;
        },

        setIsPaidSubscription: (isPaidSubscription: boolean) => {
            return App.setState({ isPaidSubscription });
        },

        getIsPaidSubscription: () => {
            return App.state.isPaidSubscription;
        },
    };
};
