function reducer(state, action) {
    switch (action.type) {
        case "title":
            return {
                ...state,
                title: action.payload[0],
                title_url: action.payload[1],
            };
        case "more":
            return {
                ...state,
                more: action.payload[0],
                more_url: action.payload[1],
            };
        case "add":
            return {
                ...state,
                body: [
                    ...state.body,
                    {
                        img: action.payload.img,
                        img_vk: action.payload.img_vk,
                        title: action.payload.title,
                        url: action.payload.url,
                    },
                ],
            };
        case "delete":
            state.body.splice(action.payload, 1);
            return {
                ...state,
            };
        case "newList":
            return { ...state, body: [...action.payload] };
        default:
            throw new Error();
    }
}
export default reducer;
