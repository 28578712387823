import { v4 as uuidv4 } from "uuid";
import { clone } from "../../../../../../includes/Helpers/Helpers";

export default function (state, action) {
    const newState = clone(state);

    const { type, payload } = action;

    switch (type) {
        case "edit-text":
            newState.content.title = payload.title;
            newState.content.text = payload.text;
            return newState;

        case "set-has-title":
            newState.has_title = payload;
            return newState;

        case "update-item":
            newState.items[payload.index].url = payload.url;
            newState.items[payload.index].addressUrl = payload.addressUrl;
            newState.items[payload.index].text = payload.desc;
            return newState;

        case "add-item":
            let item = {
                url: payload.url,
                addressUrl: payload.addressUrl,
                text: payload.desc,
                uuid: uuidv4(),
            };
            newState.items.push(item);
            return newState;

        case "sort-items":
            const { from, to } = action.payload;
            let newItems = [...newState.items];
            newItems.splice(from, 1);
            newItems.splice(to, 0, state.items[from]);
            newState.items = newItems;
            return newState;

        case "remove-item":
            let i = state.items.map((item) => ({ ...item }));
            i.splice(action.payload.index, 1);
            newState.items = i;
            return newState;

        default:
            return newState;
    }
}
