const initialState = {
    content: {
        title: "",
        text: "",
        addressUrl: "",
        desktop_img: "",
        mobile_img: "",
    },
};

export default initialState;
