import React, {
    useState,
    useContext,
    useEffect,
    useReducer,
    Suspense,
    lazy,
} from "react";
import bridge from "@vkontakte/vk-bridge";

import container from "../container";

import {
    Root,
    View,
    Panel,
    PanelHeader,
    PanelHeaderBack,
    FixedLayout,
    Button,
    Div,
    Spinner,
    SplitLayout,
} from "@vkontakte/vkui";
import * as Sentry from "@sentry/react";

import Subscriptions from "../Сomponents/Subscriptions";
import MySubscriptions from "../Сomponents/MySubscriptions";
import MainHeader from "../Сomponents/MainHeader";
import AddWidget from "../Сomponents/Widgets/AddWidget";
import WidgetSetName from "../Сomponents/Widgets/WidgetSetName";
import WidgetEdit from "../Сomponents/Widgets/WidgetEdit";
import ModalController from "../Controllers/ModalController";
import InitialSettings from "./InitialSettings";
import AppSettings from "./AppSettings";

import GuideService from "../includes/Services/GuideService";
import WidgetService from "../includes/Services/WidgetService";
import FileService from "../includes/Services/FileService";

import { EditWidget, Context } from "../context";
import Config from "../includes/Config";
import ApiVk from "./../includes/ApiVk";
import { LOCATION_WIDGET_HOME } from "../includes/Constants";
import { setHash } from "../includes/Helpers/Helpers";

import ErrorBoundary from "../ErrorBoundary";
import MetricsSettingsController from "../Controllers/MetricsSettingsController";
import GroupBannersForm from "../Сomponents/Forms/GroupBannersForm/GroupBannersForm";
import GroupSettingsNavigator from "../Сomponents/Controls/GroupSettingsNavigator";
import CatalogViewModeForm from "../Сomponents/Forms/CatalogViewModeForm/CatalogViewModeForm";
import EditTextForm from "../Сomponents/Forms/EditTextForm/EditTextForm";
import PagesBlockAudienceForm from "../Сomponents/Forms/PagesBlockAudienceForm/PagesBlockAudienceForm";
import SubscriptionsList from "../Сomponents/Controls/SubscriptionsList";
import AppSettingsReducer, {
    createState,
} from "../includes/reducers/appSettingsReducer";

import PagesIndexController from "../Controllers/PagesIndexController";
import PagesCreateController from "../Controllers/PagesCreateController";
import PagesRenameController from "../Controllers/PagesRenameController";
import PagesEditItemController from "../Controllers/PagesEdititemController";
import BlockTypeSelect from "../Сomponents/Controls/Selects/BlockTypeSelect";
import PagesAddBlockController from "../Controllers/PagesAddBlockController";
import BlockEditController from "../Controllers/BlockEditController";
import PageStatisticController from "../Controllers/PageStatisticController";
import PagesPreviewController from "../Controllers/PagesPreviewController";
import Payments from "../Сomponents/Payments";
import FatalError from "./FatalError";

const Widgets = lazy(() => import("../Сomponents/Widgets/Widgets"));

/**
 * @TODO
 * - Для урлов с РФ добавлять протокол в начале
 * - Подписка на рассылку - уточнить механику
 */

const LaunchParams = container.get("LaunchParams");

const IndexRoute = (props) => {
    ApiVk.getInstance(props.UrlParams.gets);

    const {
        location,
        widgets,
        currentModal,
        groupSiteData,
        initialSettings,
        guide,
        resizer,
        snackbar,
        popout,
        subscriptions,
    } = useContext(Context);

    const Logger = container.get("logger");
    const userRole = props.UrlParams.params.vk_viewer_group_role;
    const mainPanel = "main";

    let currentFullSubscriptionId = props.subscription_id;

    if (location.getSubscriptionId()) {
        currentFullSubscriptionId = location.getSubscriptionId();
    }

    const guideData = guide.get();
    const groupData = groupSiteData.get();
    const groupIsConnectedOnSite = !!groupSiteData.get();
    const isAdmin =
        ["moder", "editor", "admin"].indexOf(
            props.UrlParams.gets.vk_viewer_group_role
        ) >= 0;

    const resolveTitleTooltip = () => {
        let newGuide = { ...guide.get(), seen_title_tooltip: true };
        guide.set(newGuide);
        GuideService.update(newGuide);
    };

    const resolveContextTooltip = () => {
        let newGuide = { ...guide.get(), seen_context_tooltip: true };
        guide.set(newGuide);
        GuideService.update(newGuide);
    };

    const resolveContextNotify = () => {
        let newGuide = {
            ...guide.get(),
            seen_context_tooltip: true,
            visited_initial_settings: true,
        };
        guide.set(newGuide);
        GuideService.update(newGuide);
    };

    /**
     * Отсюда начинается логика обработки формы настроек сообщества
     * Форму захотели отправлять всю разом и редактировать по отдельным панелькам
     * Вынести View или Panel в отдельный файл пока не представялется возможным, так как VKUI выдает ошибку при таком рендере
     * Поэтому приходится колхозить именно так
     * @param
     */

    const [groupState, dispatch] = useReducer(
        AppSettingsReducer,
        createState(groupData)
    );
    const [errors, setErrors] = useState({});
    const [subscriptionsData, setSubscriptionsData] = useState({
        subscriptions: [],
        subscriptions_hidden: [],
        isEditMode: false,
        changed: false,
    });

    const [vkIsRecommended, setVkIsRecommended] = useState(
        !!Number(LaunchParams.params.vk_is_recommended)
    );

    useEffect(() => {
        dispatch({ type: "set-group-data", payload: createState(groupData) });
    }, [groupData]);

    const handleCatalogViewForm = (data) => {
        dispatch({ type: "set-group-data-catalog-view", payload: data });
        setHash({
            list: "app-settings",
            view: "settings-view",
            panel: "view-start",
        });
    };

    const handleTextForm = (value) => {
        dispatch({ type: "set-group-data-app-text", payload: value });
        setHash({
            list: "app-settings",
            view: "settings-view",
            panel: "view-start",
        });
    };

    const handleDeleteDesktopBanner = () => {
        dispatch({ type: "group-data-delete-desktop-banner", payload: {} });
    };

    const handleDeleteMobileBanner = () => {
        dispatch({ type: "group-data-delete-mobile-banner", payload: {} });
    };

    const handleBannerFormChange = (data) => {
        dispatch({ type: "group-data-upload-banner", payload: data });
    };

    const handleSubscriptionListChange = (data) => {
        setSubscriptionsData(data);
    };

    const handleSaveSettings = async () => {
        const groupDataService = container.get("GroupDataService");
        const subscriptionsService = container.get("SubscriptionsService");

        /**
         * Сохраняем новые настройки для сообщества
         */

        popout.loading();

        groupDataService
            .saveSettings({
                app_text: groupState.app_text,
                catalog_type: groupState.catalog_type,
                default_subscription_id: groupState.default_subscription_id,
                mobile_video_key: groupState.raw_mobile_video_key,
                mobile_video_type: groupState.mobile_video_type,
                video_key: groupState.raw_video_key,
                video_type: groupState.video_type,
            })
            .then((response) => {
                if (response.success === true) {
                    snackbar.showSuccess("Настройки сохранены");
                    setErrors({});
                } else if (response.errors) {
                    setErrors(errors);
                    snackbar.showError(
                        response.message
                            ? response.message
                            : "Ошибка при заполнении формы"
                    );
                } else {
                    snackbar.showError("Неизвестная ошибка", {
                        text: "Повторить",
                        callback: (e) => {
                            snackbar.close();
                            handleSaveSettings();
                        },
                    });
                }

                popout.close();
            });

        if (subscriptionsData.changed === true) {
            /**
             * Сохраняем новые настройки групп подписок
             */

            subscriptionsService.updateList(
                {
                    active_ids: subscriptionsData.subscriptions.map(
                        (s) => s.data.subscription_id
                    ),
                    hidden_ids: subscriptionsData.subscriptions_hidden.map(
                        (s) => s.data.subscription_id
                    ),
                    params: LaunchParams.params,
                },
                (response) => {
                    /**
                     * При успешном завершении операции обновим закешированный список групп
                     */
                    if (response.success === true) {
                        subscriptions.set([
                            ...subscriptionsData.subscriptions,
                            ...subscriptionsData.subscriptions_hidden,
                        ]);
                    } else {
                        snackbar.showError(
                            response.message
                                ? response.message
                                : "Ошибка при изменеии порядка групп подписчиков"
                        );
                    }
                }
            );
        }
    };

    /**
     * Конец логики настроек сообщества
     */

    const listContents = {
        subscriptions: {
            type: "subscriptions",
            render: () => {
                return (
                    <Subscriptions
                        subscription_id={currentFullSubscriptionId}
                        UrlParams={props.UrlParams}
                        group={props.group}
                        user={props.user}
                        mainPopout={props.mainPopout}
                        changeRoute={props.changeRoute}
                        location={location}
                        setGroup={groupSiteData.set}
                        setDefaultSubscription={
                            groupSiteData.setDefaultSubscription
                        }
                        setHeight={resizer.setHeight}
                        setForceSubscriptionPage={
                            location.setForceSubscriptionPage
                        }
                    />
                );
            },
        },
        mysubscriptions: {
            type: "mysubscriptions",
            render: () => (
                <MySubscriptions
                    UrlParams={props.UrlParams}
                    group={props.group}
                    user={props.user}
                    mainPopout={props.mainPopout}
                    changeRoute={props.changeRoute}
                    setGroup={groupSiteData.set}
                    setHeight={resizer.setHeight}
                />
            ),
        },
        widgets: {
            type: "widgets",
            render: () =>
                userRole === "admin" ? (
                    <Suspense fallback={<Spinner size="small" />}>
                        <Widgets
                            mainPopout={props.mainPopout}
                            params={{ ...props.UrlParams.params }}
                            onAddWidgetClick={() => {
                                setHash({
                                    list: "widgets",
                                    view: "widgets",
                                    panel: "add",
                                });
                            }}
                            onWidgetEdit={(widget) => {
                                setHash({
                                    list: "widgets",
                                    view: "edit",
                                    panel: "add",
                                    widget_id: widget.id,
                                });
                            }}
                            onWidgetNameEdit={(widget) => {
                                setHash({
                                    list: "widgets",
                                    view: "editName",
                                    panel: "name",
                                    widget_id: widget.id,
                                });
                            }}
                        />
                    </Suspense>
                ) : null,
        },
        "app-settings": {
            type: "app-settings",
            render: () =>
                isAdmin ? (
                    <AppSettings
                        groupData={groupData}
                        vkIsRecommended={vkIsRecommended}
                        onMenuItemClick={async (itemValue) => {
                            if (itemValue === "recommend-app") {
                                const response = await bridge.send(
                                    //@ts-ignore
                                    "VKWebAppRecommend"
                                );

                                //@ts-ignore
                                if (response.result) {
                                    snackbar.showSuccess("Успешно");
                                    setVkIsRecommended(true);
                                }

                                return;
                            }

                            if (itemValue === "refresh-token") {
                                const widgetsResult =
                                    await WidgetService.requestToken();
                                if (widgetsResult.success) {
                                    const filesResult =
                                        await FileService.requestToken();
                                    if (filesResult.success) {
                                        popout.successAnimation(
                                            "Токены приложения успешно обновлены"
                                        );
                                    }
                                }
                            } else {
                                setHash({
                                    list: "app-settings",
                                    view: `settings-${itemValue}`,
                                    panel: `${itemValue}-start`,
                                });
                            }
                        }}
                    />
                ) : null,
        },
        pages: {
            type: "pages",
            render: () => (isAdmin ? <PagesIndexController /> : null),
        },
    };

    const classNames = [];

    let catalogViewType = Config.getCatalogType(groupState.catalog_type);

    // Приложение запускается с параметром группы подписчиков либо в настройках указана группа подписчиков по умолчанию
    let fullSubscriptionAsDefault =
        !!props.subscription_id ||
        !!(groupData && groupData.default_subscription_id);

    if (fullSubscriptionAsDefault) {
        classNames.push("single");
    } else {
        classNames.push("catalog");
    }

    if (isAdmin) {
        classNames.push("Subscription-admin");
    } else {
        classNames.push("Subscription-user");
    }

    if (props.UrlParams.params.vk_platform === "desktop_web") {
        classNames.push("desktop");
    } else {
        classNames.push("mobile");
    }

    const modal = (
        <ModalController
            UrlParams={props.UrlParams}
            mainPopout={props.mainPopout}
            setCurrentModal={currentModal.set}
            currentModal={currentModal.get()}
        />
    );

    try {
        return (
            <SplitLayout modal={modal} popout={props.popout}>
                <Root activeView={`tree-${location.getView()}`}>
                    <View
                        id="tree-main"
                        className={classNames.join(" ")}
                        activePanel={`tree-main-${mainPanel}`}
                    >
                        <Panel id="tree-main-main">
                            <ErrorBoundary>
                                <MainHeader
                                    list_type={location.getList()}
                                    listContents={listContents}
                                    locationSubscription={location.getSubscriptionId()}
                                    subscription={
                                        !!currentFullSubscriptionId
                                    }
                                    initSubscriptionId={
                                        props.subscription_id
                                    }
                                    userRole={
                                        props.UrlParams.params
                                            .vk_viewer_group_role
                                    }
                                    groupIsConnectedOnSite={
                                        groupIsConnectedOnSite
                                    }
                                    isAdmin={isAdmin}
                                    isSinglePage={fullSubscriptionAsDefault}
                                    seenTitleTooltip={
                                        guideData.seen_title_tooltip
                                    }
                                    seenContextTooltip={
                                        guideData.seen_context_tooltip
                                    }
                                    visitedInitialSettings={
                                        guideData.visited_initial_settings
                                    }
                                    resolveTitleTooltip={
                                        resolveTitleTooltip
                                    }
                                    resolveContextTooltip={
                                        resolveContextTooltip
                                    }
                                    group={props.group}
                                    changeList={(val) => {
                                        if (val === "widgets") {
                                            setHash(LOCATION_WIDGET_HOME);
                                        } else {
                                            setHash({ list: val });
                                        }
                                    }}
                                />
                            </ErrorBoundary>

                            {listContents[location.getList()]
                                ? listContents[location.getList()].render()
                                : listContents["subscriptions"].render()}
                        </Panel>
                    </View>

                    <View
                        id="tree-initial-settings"
                        activePanel="tree-initial-settings-main"
                    >
                        <Panel id="tree-initial-settings-main">
                            <div className="content--inner">
                                <InitialSettings
                                    params={props.UrlParams.gets}
                                    currentSlide={initialSettings.getCurrentSlide()}
                                    next={initialSettings.next}
                                    prev={initialSettings.prev}
                                    changeSlide={initialSettings.changeSlide}
                                    resolveContextTooltip={
                                        resolveContextTooltip
                                    }
                                    resolveContextNotify={resolveContextNotify}
                                />
                            </div>
                        </Panel>
                    </View>

                    <View
                        id="tree-edit"
                        activePanel={`tree-edit-${location.getPanel()}`}
                    >
                        <Panel id="tree-edit-add">
                            <EditWidget.Provider
                                value={{ state: widgets.getActive() }}
                            >
                                <div className="content--inner">
                                    <WidgetEdit
                                        widget={widgets.getActive()}
                                        onBack={() =>
                                            setHash(LOCATION_WIDGET_HOME)
                                        }
                                        onModalRequest={currentModal.set}
                                        params={props.UrlParams}
                                        activeTab={location.getActiveTab()}
                                    />
                                </div>
                            </EditWidget.Provider>
                        </Panel>
                    </View>

                    <View id="tree-editName" activePanel="tree-edit-name">
                        <Panel id="tree-edit-name">
                            <div className="content--inner">
                                <WidgetSetName
                                    edit={true}
                                    widget={widgets.getActive()}
                                    onWidgetChange={(w) => widgets.setActive(w)}
                                    group={props.group}
                                    onBack={() => {
                                        window.history.back();
                                    }}
                                />
                            </div>
                        </Panel>
                    </View>
                    <View
                        id="tree-widgets"
                        activePanel={`tree-widgets-${location.getPanel()}`}
                    >
                        <Panel id="tree-widgets-add">
                            <div className="content--inner">
                                <AddWidget
                                    onWidgetSelect={(w) => {
                                        setHash({
                                            list: "widgets",
                                            view: "widgets",
                                            panel: "name",
                                            type: w.type,
                                            type_api: w.type_api,
                                        });
                                    }}
                                    onShowHelp={(widget) => {
                                        currentModal.set({
                                            id: "help",
                                            args: widget,
                                        });
                                    }}
                                    onBack={() => setHash(LOCATION_WIDGET_HOME)}
                                />
                            </div>
                        </Panel>
                        <Panel id="tree-widgets-name">
                            <div className="content--inner">
                                <WidgetSetName
                                    widget={widgets.getNew()}
                                    group={props.group}
                                    onWidgetChange={(w) => {
                                        w.code = {};
                                        w.audience = {};
                                        widgets.add(w);
                                        setHash({
                                            list: "widgets",
                                            view: "widgets",
                                            panel: "edit",
                                            widget_id: w.id,
                                        });
                                    }}
                                    onBack={() =>
                                        setHash({
                                            list: "widgets",
                                            view: "widgets",
                                            panel: "add",
                                        })
                                    }
                                />
                            </div>
                        </Panel>
                        <Panel id="tree-widgets-edit">
                            <div className="content--inner">
                                <WidgetEdit
                                    isNewModel={true}
                                    widget={widgets.getActive()}
                                    onWidgetChange={(w) => {
                                        widgets.setActive(w);
                                    }}
                                    onBack={() => setHash(LOCATION_WIDGET_HOME)}
                                    onModalRequest={currentModal.set}
                                    params={props.UrlParams}
                                    activeTab={location.getActiveTab()}
                                />
                            </div>
                        </Panel>
                    </View>

                    <View
                        id="tree-settings-view"
                        activePanel={`tree-settings-${location.getPanel()}`}
                    >
                        <Panel id="tree-settings-view-start">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            setHash({ list: "app-settings" });
                                        }}
                                    />
                                }
                                fixed
                            >
                                Вид главной страницы
                            </PanelHeader>
                            <div style={{ paddingBottom: 100 }}>
                                <GroupSettingsNavigator
                                    platform={
                                        props.UrlParams.params.vk_platform
                                    }
                                    groupData={groupState}
                                    viewType={catalogViewType}
                                    navigateView={() => {
                                        setHash({
                                            list: "app-settings",
                                            view: "settings-view",
                                            panel: "view-mode",
                                        });
                                    }}
                                    navigateBanners={() => {
                                        setHash({
                                            list: "app-settings",
                                            view: "settings-view",
                                            panel: "view-banners",
                                        });
                                    }}
                                    navigateText={() => {
                                        setHash({
                                            list: "app-settings",
                                            view: "settings-view",
                                            panel: "view-text",
                                        });
                                    }}
                                />
                                {catalogViewType.value !== "single" && (
                                    <SubscriptionsList
                                        urlParams={props.UrlParams}
                                        catalogType={catalogViewType.value}
                                        subscriptionsProvider={subscriptions}
                                        modalProvider={currentModal}
                                        onChange={handleSubscriptionListChange}
                                    />
                                )}
                            </div>
                            {subscriptionsData.isEditMode === false && (
                                <FixedLayout vertical="bottom" filled={true}>
                                    <Div>
                                        <Button
                                            size="l"
                                            mode="secondary"
                                            stretched
                                            onClick={handleSaveSettings}
                                        >
                                            Сохранить
                                        </Button>
                                    </Div>
                                </FixedLayout>
                            )}
                        </Panel>

                        <Panel id="tree-settings-view-mode">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            setHash({
                                                list: "app-settings",
                                                view: "settings-view",
                                                panel: "view-start",
                                            });
                                        }}
                                    />
                                }
                                fixed
                            >
                                Вид
                            </PanelHeader>
                            <CatalogViewModeForm
                                defaultSubscriptionId={
                                    groupState.default_subscription_id
                                }
                                types={Config.catalogTypes}
                                currentType={
                                    groupState.catalog_type
                                        ? groupState.catalog_type
                                        : "list"
                                }
                                subscriptions={subscriptions.get()}
                                onSubmit={handleCatalogViewForm}
                            />
                        </Panel>
                        <Panel id="tree-settings-view-banners">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            setHash({
                                                list: "app-settings",
                                                view: "settings-view",
                                                panel: "view-start",
                                            });
                                        }}
                                    />
                                }
                                fixed
                            >
                                Загрузить баннер
                            </PanelHeader>
                            <GroupBannersForm
                                platform={props.UrlParams.params.vk_platform}
                                groupData={groupState}
                                deleteDesktopBanner={handleDeleteDesktopBanner}
                                deleteMobileBanner={handleDeleteMobileBanner}
                                openBannerUploader={currentModal.set}
                                onChange={handleBannerFormChange}
                            />
                        </Panel>
                        <Panel id="tree-settings-view-text">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            setHash({
                                                list: "app-settings",
                                                view: "settings-view",
                                                panel: "view-start",
                                            });
                                        }}
                                    />
                                }
                                fixed
                            >
                                Описание
                            </PanelHeader>
                            <EditTextForm
                                text={groupState.app_text}
                                onSubmit={handleTextForm}
                            />
                        </Panel>
                    </View>

                    <View
                        id="tree-settings-metrics"
                        activePanel={`tree-settings-${location.getPanel()}`}
                    >
                        <Panel id="tree-settings-metrics-start">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            setHash({
                                                list: "app-settings",
                                            });
                                        }}
                                    />
                                }
                                fixed
                            >
                                Метрики
                            </PanelHeader>
                            <MetricsSettingsController
                                groupDataProvider={groupSiteData}
                                service={container.get("GroupDataService")}
                                snackbar={snackbar}
                                popout={popout}
                                onSuccess={() => {}}
                            />
                        </Panel>
                    </View>

                    <View
                        id="tree-pages"
                        className="small--header"
                        activePanel={`tree-pages-${location.getPanel()}`}
                    >
                        <Panel id="tree-pages-create">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            setHash({
                                                list: "pages",
                                            });
                                        }}
                                    />
                                }
                                fixed
                            >
                                Создать лендинг
                            </PanelHeader>
                            <PagesCreateController />
                        </Panel>

                        {/* Фикс-костыль для vk-ui чтобы при переходе с вьюшки создания виджета на вьюшку редактирования не выкидывало ошибку */}
                        <Panel id="tree-pages-item"></Panel>
                    </View>

                    <View
                        id="tree-pages-rename"
                        className="small--header"
                        activePanel={`tree-pages-rename-${location.getPanel()}`}
                    >
                        <Panel id="tree-pages-rename-item">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            window.history.back();
                                        }}
                                    />
                                }
                                fixed
                            >
                                Переименовать
                            </PanelHeader>
                            <PagesRenameController />
                        </Panel>
                    </View>

                    <View
                        id="tree-pages-edit"
                        className="small--header"
                        activePanel={`tree-pages-edit-${location.getPanel()}`}
                    >
                        <Panel id="tree-pages-edit-item">
                            <PagesEditItemController />
                        </Panel>
                        <Panel id="tree-pages-edit-blocks-types">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            window.history.back();
                                        }}
                                    />
                                }
                                fixed
                            >
                                Добавить блок
                            </PanelHeader>
                            <PagesAddBlockController isRecent={true} />
                            <BlockTypeSelect
                                onSelect={(val) => {
                                    setHash({
                                        list: "pages",
                                        view: `pages-edit`,
                                        panel: "blocks-items",
                                        block_type: val.type,
                                    });
                                }}
                            />
                        </Panel>
                        <Panel id="tree-pages-edit-blocks-items">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            window.history.back();
                                        }}
                                    />
                                }
                                fixed
                            >
                                Добавить блок
                            </PanelHeader>
                            <PagesAddBlockController />
                        </Panel>

                        {/* * Вьюшка редактирования блока */}
                        <Panel id="tree-pages-edit-blocks-edit">
                            <BlockEditController />
                        </Panel>
                        {/* Вьюшка редактирования блока END */}

                        <Panel id="tree-pages-edit-preview">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            window.history.back();
                                        }}
                                    />
                                }
                                fixed
                            >
                                Предпросмотр
                            </PanelHeader>
                            <PagesPreviewController type="edit" />
                        </Panel>

                        <Panel id="tree-pages-edit-audience">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            window.history.back();
                                        }}
                                    />
                                }
                                fixed
                            >
                                Аудитория блока
                            </PanelHeader>
                            <PagesBlockAudienceForm />
                        </Panel>
                    </View>

                    <View
                        id="tree-pages-preview"
                        className="small--header"
                        activePanel={`tree-pages-preview-${location.getPanel()}`}
                    >
                        <Panel id="tree-pages-preview-item">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            window.history.back();
                                        }}
                                    />
                                }
                                fixed
                            >
                                Предпросмотр
                            </PanelHeader>
                            <PagesPreviewController type="published" />
                        </Panel>
                    </View>

                    {/* Отображение статистики отдельной страницы */}
                    <View
                        id="tree-pages-statistic"
                        className="small--header"
                        activePanel={`tree-pages-statistic-${location.getPanel()}`}
                    >
                        <Panel id="tree-pages-statistic-item">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            window.history.back();
                                        }}
                                    />
                                }
                                fixed
                            >
                                Статистика
                            </PanelHeader>
                            <PageStatisticController />
                        </Panel>
                    </View>

                    {/* Отображение страницы со способами оплаты */}
                    <View
                        id="tree-pay-methods"
                        className="small--header"
                        activePanel={`tree-pay-methods-${location.getPanel()}`}
                    >
                        <Panel id="tree-pay-methods-list">
                            <PanelHeader
                                before={
                                    <PanelHeaderBack
                                        onClick={() => {
                                            setHash({
                                                view: "main",
                                                list: "",
                                            });
                                        }}
                                    />
                                }
                                fixed
                            >
                                Выберите способ оплаты
                            </PanelHeader>
                            <Payments />
                        </Panel>
                    </View>
                </Root>
            </SplitLayout>
        );
    } catch (e) {
        Logger.error(
            { code: 9011, message: e.message },
            "render_error",
            "IndexRoute.tsx"
        );

        Sentry.captureException(e);
    }
};

export default IndexRoute;
