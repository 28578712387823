import React from "react";
import {
    AudienceCurrentCityType,
    AudienceCurrentCountryType,
    AudienceUserTimeType,
} from "../includes/Structures";

type AudienceContextType = {
    set?: (name: string, value: any) => void;
    sex?: number | "any";
    age_from?: number | "any";
    age_to?: number | "any";
    b_day?: string[] | "any";
    relation?: number[];
    current_country?: AudienceCurrentCountryType[];
    current_city?: AudienceCurrentCityType[];
    platform?: number[];
    names?: string[];
    surnames?: string[];
    user_time?: AudienceUserTimeType;
    variable_global?: any;
    variable_lead?: any;
    user_role?: string | "any";
    subscriber?: boolean;
    subscribed_on_groups ?: []
    not_subscribed_on_groups ?: []
};

const AudienceContext = React.createContext<AudienceContextType>({});

export default AudienceContext;
