import React, { useContext } from "react";
import DetachedBadges from "../../Controls/DetachedBadges";
import { Div, FormItem, FormLayout, SelectMimicry } from "@vkontakte/vkui";

import { Context } from "../../../context";
import AudienceContext from "../../../Context/AudienceContext";

const Geography = () => {
    const { set, current_country, current_city } = useContext(AudienceContext);

    const { currentModal } = useContext(Context);

    const onModalRequest = currentModal.set;

    return (
        <FormLayout>
            {/* Выбор страны */}
            <FormItem top="Страна">
                <SelectMimicry
                    onClick={() =>
                        onModalRequest({
                            id: "country-select",
                            args: {
                                currentCountry: current_country,
                                callback: (val) => {
                                    set("current_country", val);
                                },
                            },
                        })
                    }
                    placeholder="Выбрать"
                >
                    {current_country.length > 0 ? current_country[0].title : ""}
                </SelectMimicry>
            </FormItem>

            {/* Выбор города */}
            {current_country.length > 0 && (
                <FormItem top="Город">
                    <SelectMimicry
                        onClick={() =>
                            onModalRequest({
                                id: "city-select",
                                args: {
                                    currentCity: current_city,
                                    callback: (val) => {
                                        set("current_city", val);
                                    },
                                    countryId: current_country[0].id,
                                },
                            })
                        }
                        placeholder="Выбрать"
                    />
                </FormItem>
            )}

            {current_city.length > 0 && (
                <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <DetachedBadges
                        items={current_city}
                        name="city"
                        onChange={(val) => {
                            set("current_city", val);
                        }}
                        getText={(item) => item.title}
                    />
                </Div>
            )}
        </FormLayout>
    );
};

export default Geography;
