import { v4 as uuidv4 } from "uuid";
import { clone } from "../../../../../includes/Helpers/Helpers";

export default function (state, action) {
    const newState = clone(state);

    const { type, payload } = action;

    switch (type) {
        case "edit-text":
            newState.content.title = payload.title;
            newState.content.text = payload.text;
            return newState;

        case "sort-items":
            const { from, to } = action.payload;
            let newItems = [...newState.items];
            newItems.splice(from, 1);
            newItems.splice(to, 0, state.items[from]);
            newState.items = newItems;
            return newState;

        case "add-item":
            newState.items = state.items.map((item) => ({ ...item }));
            let item = { ...payload.data };
            item.uuid = uuidv4();
            newState.items.push(item);
            return newState;

        case "set-has-title":
            newState.has_title = payload;
            return newState;

        case "remove-item":
            let i = state.items.map((item) => ({ ...item }));
            i.splice(action.payload.index, 1);
            newState.items = i;
            return newState;

        case "edit-item":
            let newItemsEdit = state.items.map((item) => ({ ...item }));

            newItemsEdit[payload.index].title = payload.data.title;
            newItemsEdit[payload.index].text = payload.data.text;
            newItemsEdit[payload.index].item_radius = payload.data.item_radius;

            if (payload.data.img) {
                delete newItemsEdit[payload.index].svg_icon_id;
                delete newItemsEdit[payload.index].svg_icon_color;
                delete newItemsEdit[payload.index].svg_icon_bg_color;
                newItemsEdit[payload.index].img = { ...payload.data.img };
            }

            if (payload.data.svg_icon_id) {
                delete newItemsEdit[payload.index].img;
                newItemsEdit[payload.index].svg_icon_id =
                    payload.data.svg_icon_id;
            }

            if (payload.data.svg_icon_color) {
                newItemsEdit[payload.index].svg_icon_color =
                    payload.data.svg_icon_color;
            }

            if (payload.data.svg_icon_bg_color) {
                newItemsEdit[payload.index].svg_icon_bg_color =
                    payload.data.svg_icon_bg_color;
            }

            newState.items = newItemsEdit;

            return newState;

        default:
            return newState;
    }
}
