import React, { useState } from "react";
import { Div, Button } from "@vkontakte/vkui";
import InputDynamic from "../../Controls/InputDynamic";

declare type EditTextFormProps = {
    text: string;
    onSubmit?: Function;
};

const EditTextForm = (props: EditTextFormProps) => {
    const { text, onSubmit } = props;

    const [value, setValue] = useState(text ? text: '');

    const handleInputChange = (value) => {
        setValue(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit && onSubmit(value);
    };

    return (
        <Div>
            <InputDynamic
                value={value}
                onChange={handleInputChange}
                top="Описание"
                name="description"
                type="text"
                status="default"
                currentOpened=""
                placeholder="Выберите материалы для получения рассылкой в личные сообщения"
                bottomText=""
                variablesMode="app-settings"
                textarea
            />
            <br />
            <Button
                size="l"
                mode="secondary"
                onClick={handleSubmit}
                stretched
            >
                Сохранить
            </Button>
        </Div>
    );
};

export default EditTextForm;
