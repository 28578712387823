import React from "react";

const WidgetHelpImage = (props) => {
    const { type, desc } = props;
    let imgData = null;

    switch (type) {
        case "carousel":
            imgData = {
                src: "img/help/HelpCarousel.svg",
                height: 351,
                alt: "Карусель",
            };
            break;
        case "promo":
            imgData = {
                src: "img/help/HelpPromo.svg",
                height: 306,
                alt: "",
            };
            break;
        case "recommended":
            imgData = {
                src: "img/help/HelpRecommend.svg",
                height: 306,
                alt: "",
            };
            break;
        case "banners":
            imgData = {
                src: "img/help/HelpBanners.svg",
                height: 420,
                alt: "",
            };
            break;
        case "message":
            imgData = {
                src: "img/help/HelpMessage.svg",
                height: 200,
                alt: "",
            };
            break;
        case "personal":
            imgData = {
                src: "img/help/HelpPersonal.svg",
                height: 136,
                alt: "",
            };
            break;
        case "feedbacks":
            imgData = {
                src: "img/help/HelpReviews.svg",
                height: 320,
                alt: "",
            };
            break;
        case "clients":
            imgData = {
                src: "img/help/HelpClients.svg",
                height: 204,
                alt: "",
            };
            break;
        case "pointSale":
            imgData = {
                src: "img/help/HelpPointSale.svg",
                height: 354,
                alt: "",
            };
            break;
        case "events":
            imgData = {
                src: "img/help/HelpEvents.svg",
                height: 200,
                alt: "",
            };
            break;
        case "community":
            imgData = {
                src: "img/help/HelpCommunity.svg",
                height: 380,
                alt: "",
            };
            break;
        case "subscription":
            imgData = {
                src: "img/help/HelpSubscriptions.svg",
                height: 382,
                alt: "",
            };
            break;
        default:
            break;
    }

    if (!imgData) {
        return null;
    }

    return (
        //@ts-ignore
        <div height={imgData.height}>
            <img
                height={imgData.height}
                width="auto"
                src={imgData.src}
                alt={desc}
                style={{
                    maxWidth: "100%",
                }}
            />
        </div>
    );
};

export default WidgetHelpImage;
