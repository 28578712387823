import React, { MouseEventHandler } from "react";
import { PanelHeader, PanelHeaderBack } from "@vkontakte/vkui";

type SubHeaderProps = {
    separator?: boolean;
    fixed?: boolean;
    onBack: MouseEventHandler<HTMLElement>;
    children: any;
};

const SubHeader = (props: SubHeaderProps) => {
    const { separator = true, fixed = false } = props;

    return (
        <PanelHeader
            before={
                <PanelHeaderBack onClick={props.onBack} aria-label="back" />
            }
            separator={separator}
            fixed={fixed}
        >
            {props.children}
        </PanelHeader>
    );
};

export default SubHeader;
