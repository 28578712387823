export const moduleName = "text-block-edit";
export const SET_TITLE = `${moduleName}/SET_TITLE`;
export const SET_TEXT = `${moduleName}/SET_TEXT`;

const initialState = {
    // Основной контент блока
    content: {
        title: "",
        text: "",
    },
};

export default initialState;
