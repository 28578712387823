import { Widget } from "./Structures";
import WidgetCodeFormatterInterface from "./Interfaces/WidgetCodeFormatterInterface";
import TextVarialbeReplacerInterface from "./Interfaces/TextVariableReplacerInterface";

export default class WidgetBodyCreator {
    codeFormatter: WidgetCodeFormatterInterface;
    varReplacer: TextVarialbeReplacerInterface;
    urlCreator: Function;

    constructor(
        codeFormatter: WidgetCodeFormatterInterface,
        varReplacer: TextVarialbeReplacerInterface,
        urlCreatorFunction
    ) {
        this.codeFormatter = codeFormatter;
        this.varReplacer = varReplacer;
        this.urlCreator = urlCreatorFunction;
    }

    collection(widgets: Widget[], type: String, widgetPreview: any = false) {
        let codes: Array<string> = [];
        let widgetPreviewCode = "";

        if (widgets.length < 1) {
            return {
                type,
                code: "return false;",
            };
        }

        switch (type) {
            case "list":
                widgets.forEach((widget: Widget) => {
                    if (widget.type_api === "list") {
                        codes.push(this.listCommon(widget));
                    } else {
                        codes.push(this.list(widget));
                    }
                });

                if (widgetPreview) {
                    if (widgetPreview.type_api === "list") {
                        widgetPreviewCode = this.listCommon(widgetPreview);
                    } else {
                        widgetPreviewCode = this.list(widgetPreview);
                    }
                }

                break;

            case "compact_list":
                widgets.forEach((widget) => {
                    codes.push(this.listCompact(widget));
                });

                if (widgetPreview) {
                    widgetPreviewCode = this.listCompact(widgetPreview);
                }

                break;

            case "cover_list":
                widgets.forEach((widget) => {
                    codes.push(this.cover_list(widget));
                });

                if (widgetPreview) {
                    widgetPreviewCode = this.cover_list(widgetPreview);
                }

                break;

            case "tiles":
                widgets.forEach((widget) => {
                    codes.push(this.tiles(widget));
                });

                if (widgetPreview) {
                    widgetPreviewCode = this.tiles(widgetPreview);
                }

                break;

            case "table":
                widgets.forEach((widget) => {
                    codes.push(this.table(widget));
                });

                if (widgetPreview) {
                    widgetPreviewCode = this.table(widgetPreview);
                }

                break;

            case "text":
                widgets.forEach((widget) => {
                    codes.push(this.text(widget));
                });

                if (widgetPreview) {
                    widgetPreviewCode = this.text(widgetPreview);
                }

                break;

            default: {
                return {
                    type,
                    code: "return false;",
                };
            }
        }

        return {
            type,
            code: this.codeFormatter.createCode(
                codes,
                widgetPreviewCode,
                widgetPreview.previewEndTime
            ),
        };
    }

    headerCreate(obj) {
        let title: String = `"title": "${this.replace(obj.title)}",`;
        let more: String = ``;

        if (obj.title_url != "")
            title = `"title": "${this.replace(
                obj.title
            )}", "title_url": "${this.createUrl(obj.title_url)}",`;
        if (obj.more_url != "")
            more = `"more": "${this.replace(
                obj.more
            )}", "more_url": "${this.createUrl(obj.more_url)}" ,`;

        return { title, more };
    }

    tiles(widget) {
        let obj = widget.code;
        const header = this.headerCreate(obj);
        let items = "";

        obj.tiles.forEach((item) => {
            let url: String = ``;
            let button: String = ``;
            if (item.button_url != "")
                button = `"link": "${this.replace(
                    item.button
                )}", "link_url": "${this.createUrl(item.button_url)}",`;
            if (item.url != "") url = `"url": "${this.createUrl(item.url)}",`;

            items =
                items +
                `{"title": "${this.replace(
                    item.title
                )}","descr": "${this.replace(
                    item.descr
                )}","icon_id": "${this.createIconId(
                    item.icon_id
                )}",${button}${url}},`;
        });

        const audience = this.codeFormatter.createAudience(widget);

        return `{code: {${header.title}"tiles":[${items}],${header.more}}, ${audience}}`;
    }

    table(widget) {
        let obj = widget.code;
        const header = this.headerCreate(obj);
        let items = "";

        obj.body.forEach((item) => {
            let title = this.replace(item.title);
            items =
                items +
                `[{"text": "${title}","icon_id": "id${item.img_vk}","url": "${item.url}"}],`;
        });

        let audience = this.codeFormatter.createAudience(widget);

        return `{code: {${header.title}"body":[${items}],${header.more}}, ${audience}}`;
    }

    cover_list(widget) {
        let obj = widget.code;
        const header = this.headerCreate(obj);
        let items = "";

        obj.rows.forEach((item) => {
            let url: String = ``;
            let button: String = ``;
            if (item.button_url != "")
                button = `"button": "${this.replace(
                    item.button
                )}", "button_url": "${this.createUrl(item.button_url)}",`;
            if (item.url != "") url = `"url": "${this.createUrl(item.url)}",`;

            items =
                items +
                `{"title": "${this.replace(
                    item.title
                )}","descr": "${this.replace(item.descr)}","cover_id": "${
                    item.img_vk
                }",${button}${url}},`;
        });

        const audience = this.codeFormatter.createAudience(widget);

        return `{code:{${header.title}"rows":[${items}],${header.more}}, ${audience}}`;
    }

    text(widget) {
        let obj = widget.code;
        const header = this.headerCreate(obj);
        const audience = this.codeFormatter.createAudience(widget);
        return `{code: {${header.title} ${header.more} "text": "${this.replace(
            obj.text
        )}", "descr": "${this.replace(obj.descr)}"}, ${audience}}`;
    }

    list(widget) {
        let obj = widget.code;
        let items: String = "";
        let geo: String = "";
        let time: String = "";

        const header = this.headerCreate(obj);

        obj.rows.forEach((item) => {
            let button: String = ``;
            let icon_id: String = `"icon_id": "id"+Args.uid,`;
            let url: String = ``;

            if (item.button_url != "" && item.button_url !== undefined)
                button = `"button":"${this.replace(
                    item.button
                )}","button_url":"${this.createUrl(item.button_url)}",`;
            if (item.icon_id != undefined)
                icon_id = `"icon_id": "${this.createIconId(item.icon_id)}",`;

            if (item.geo != undefined)
                geo = `"address": "${this.replace(item.geo)}",`;

            if (item.time != undefined)
                time = `"time": "${this.replace(item.time)}",`;
            if (item.url != "" && item.url !== undefined)
                url = `"title_url": "${this.createUrl(item.url)}",`;

            let text =
                widget.type_api === "feedbacks"
                    ? this.createText(item.descr)
                    : this.replace(item.descr);

            items =
                items +
                `{"title": "${this.replace(item.title)}",${
                    widget.type_api === "feedbacks" ? '"text"' : '"descr"'
                }: "${text}",${icon_id}${geo}${time}${button}${url}},`;
        });

        const audience = this.codeFormatter.createAudience(widget);

        return `{code: {${header.title}rows:[${items}],${header.more}}, ${audience}}`;
    }

    listCommon(widget) {
        let obj = widget.code;
        let items: String = "";
        let geo: String = "";
        let time: String = "";

        const header = this.headerCreate(obj);

        let rows = [...obj.rows];

        // Если в элементах списка содержится сопроводительный текст
        if (obj.has_text) {
            rows = rows.slice(0, 3);
        }

        rows.forEach((item) => {
            let button: String = ``;
            let icon_id: String = `"icon_id": "id"+Args.uid,`;
            let url: String = ``;
            let text = "";

            if (
                widget.code.has_buttons &&
                item.button_url != "" &&
                item.button_url !== undefined
            ) {
                button = `"button":"${this.replace(
                    item.button
                )}","button_url":"${this.createUrl(item.button_url)}",`;
            } else {
                button = ``;
            }

            if (widget.code.has_images && item.icon_id != undefined) {
                icon_id = `"icon_id": "${this.createIconId(item.icon_id)}",`;
            } else {
                icon_id = ``;
            }

            if (widget.code.has_text && item.text) {
                text = `"text": "${this.createText(item.text)}",`;
            }

            if (item.geo != undefined) {
                geo = `"address": "${this.replace(item.geo)}",`;
            }

            if (item.time != undefined) {
                time = `"time": "${this.replace(item.time)}",`;
            }

            if (item.url != "" && item.url !== undefined) {
                url = `"title_url": "${this.createUrl(item.url)}",`;
            }

            items =
                items +
                `{"title": "${this.replace(
                    item.title
                )}","descr": "${this.replace(
                    item.descr
                )}",${icon_id}${geo}${time}${button}${url}${text}},`;
        });

        const audience = this.codeFormatter.createAudience(widget);

        return `{code: {${header.title}rows:[${items}],${header.more}}, ${audience}}`;
    }

    listCompact(widget) {
        let obj = widget.code;
        let items: String = "";
        let geo: String = "";
        let time: String = "";

        const header = this.headerCreate(obj);

        let rows = [...obj.rows];

        // Если в элементах списка содержится сопроводительный текст
        if (obj.has_text) {
            rows = rows.slice(0, 3);
        }

        rows.forEach((item) => {
            let button: String = ``;
            let icon_id: String = `"icon_id": "id"+Args.uid,`;
            let url: String = ``;
            let text = "";

            if (
                widget.code.has_buttons &&
                item.button_url != "" &&
                item.button_url !== undefined
            ) {
                button = `"button":"${this.replace(
                    item.button
                )}","button_url":"${this.createUrl(item.button_url)}",`;
            } else {
                button = ``;
            }

            if (widget.code.has_images && item.icon_id != undefined) {
                icon_id = `"icon_id": "${this.createIconId(item.icon_id)}",`;
            } else {
                icon_id = ``;
            }

            if (widget.code.has_text && item.text) {
                text = `"text": "${this.createText(item.text)}",`;
            }

            if (item.geo != undefined) {
                geo = `"address": "${this.replace(item.geo)}",`;
            }

            if (item.time != undefined) {
                time = `"time": "${this.replace(item.time)}",`;
            }

            if (item.url != "" && item.url !== undefined) {
                url = `"title_url": "${this.createUrl(item.url)}",`;
            }

            items =
                items +
                `{"title": "${this.replace(
                    item.title
                )}","descr": "${this.replace(
                    item.descr
                )}",${icon_id}${geo}${time}${button}${url}${text}},`;
        });

        const audience = this.codeFormatter.createAudience(widget);

        return `{code: {${header.title}rows:[${items}],${header.more}}, ${audience}}`;
    }

    createUrl(url): string {
        return this.varReplacer.replaceVars(this.urlCreator(url));
    }

    createText(text: string) {
        let textWithLineBreaks = text.replace(/(\n|\r\n)/g, "\\r\\n");
        let textWithVariables =
            this.varReplacer.replaceVars(textWithLineBreaks);
        return textWithVariables;
    }

    replace(text: string) {
        let textWithVariables = this.varReplacer.replaceVars(text);
        return textWithVariables;
    }

    createIconId(icon) {
        switch (icon) {
            case "current-user":
                return 'id"+user.id+"';
            case "current-user-partner":
                return 'id" + (user.relation_partner ? user.relation_partner : -192849399) + "';
            case "current-user-friend":
                return 'id" + (userFriendId ? userFriendId : -192849399) + "';
            default:
                return icon;
        }
    }
}
