import bridge from '@vkontakte/vk-bridge';

class DomHelpers {
    getParents(elem) {
        // Set up a parent array
        var parents = [];

        // Push each parent element to the array
        for (; elem && elem !== document; elem = elem.parentNode) {
            parents.push(elem);
        }

        // Return our parent array
        return parents;
    }

    initDefferedScript(src: string): void {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        document.body.appendChild(script);
    }

    scrollToModal(elem = 'alert') {
        if (bridge.supports("VKWebAppScroll")) {
            setTimeout(() => {
                try {
                    let domElem

                    switch (elem) {
                        case 'alert':
                            domElem = document.querySelector('.vkuiAlert')
                            break;
                        default:
                            domElem = document.querySelector('.vkuiAlert')
                            break;
                    }

                    bridge.send("VKWebAppScroll", {
                        top: domElem.offsetTop - (domElem.offsetHeight / 2),
                        speed: 400,
                    });
                } catch (error) {}
            })
        }
    }
}

export default new DomHelpers();
