import React, { useState, useEffect } from "react";
import { Header, Button, Cell, Group, Div, Headline } from "@vkontakte/vkui";
import ItemCarouselCard from "../../Controls/ItemCarouselCard";
import ModalWidgetEditHeader from "../ModalWidgetEditHeader";
import ModalCarouselItem from "../ModalEdit/Item";
import ModalCarouselButton from "../ModalEdit/Button";
import reducer from "../../../includes/reducers/tilesReducer";
import initialState from "./state";
import WidgetTilesImgValidate from "../../../includes/Validators/WidgetTilesImgValidate";
import useUpdate from "../../../includes/Hooks/useUpdate";
import WidgetHeader from "../WidgetHeader";
import { widgetsImagesSetByIds } from "../../../includes/Helpers/Helpers";
import UpdateImagesSettedByIds from "../../../includes/Services/UpdateImagesSettedByIds";
import AdaptiveHorizontalScroll from "../../hoc/AdaptiveHorizontalScroll";
import container from "../../../container";
import {
    SortableContainer as sortableContainer,
    SortableElement as sortableElement,
} from "react-sortable-hoc";

export const initalErrors = {
    title: false,
    title_url: false,
    more_url: false,
    tiles: false,
    tiles_count: false,
    tiles_title: false,
    tiles_descr: false,
    tiles_button: false,
    tiles_url: false,
    tiles_item: [],
};

const CarouselEdit = (props) => {
    const { onEditMode } = props;

    const [editMode, setEditMode] = useState(false);
    const [state, dispatch]: any = useUpdate({
        ...props,
        reducer,
        initialState,
        type: "carousel",
    });
    const errors = { ...initalErrors, ...props.errors.resp };

    const LaunchParams = container.get("LaunchParams");

    useEffect(() => {
        const imageSetByIds = widgetsImagesSetByIds(state.tiles);

        if (true === imageSetByIds) {
            UpdateImagesSettedByIds(state.tiles, dispatch);
        }
    }, []);

    const modals = {
        editHeader: {
            title: "Заголовок виджета",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.title, url: state.title_url }}
                    type="title"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editLink: {
            title: "Ссылка",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.more, url: state.more_url }}
                    type="more"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editItem: {
            title: "Редактировать элемент",
            body: (state, callback, close, cardID) => (
                <ModalCarouselItem
                    state={state.tiles[cardID]}
                    onSelect={callback}
                    close={close}
                    cardID={cardID}
                />
            ),
            callback: dispatch,
            state,
        },
        addButton: {
            title: "Добавить кнопку",
            body: (state, callback, close, cardID) => (
                <ModalCarouselButton
                    state={state.tiles[cardID]}
                    onSelect={callback}
                    close={close}
                    cardID={cardID}
                />
            ),
            callback: dispatch,
            state,
        },
    };

    const handleCoverClick = (i) => {
        const type_img = WidgetTilesImgValidate(state.tiles);
        const tile = state.tiles[i];
        props.onModalRequest({
            id: "card-img",
            args: {
                callback: dispatch,
                cardID: i,
                iconId: tile.icon_id,
                type: "tiles",
                type_api: "carousel",
                type_img,
            },
        });
    };

    const renderEditMode = () => (
        <SortableContainer
            axis="x"
            lockToContainerEdges={true}
            useWindowAsScrollContainer={true}
            lockAxis="x"
            helperClass="tile-sort-helper"
            pressDelay={300}
            onSortStart={({ node, index, collection, isKeySorting }, event) => {
                muteElements();
            }}
            onSortEnd={({ oldIndex, newIndex }) => {
                dispatch({
                    type: "sort",
                    payload: {
                        oldIndex,
                        newIndex,
                    },
                });
            }}
        >
            {state.tiles.map((card, i) => (
                <SortableItem
                    key={`item-${i}`}
                    index={i}
                    value={{
                        card: card,
                        key: `item-${i}`,
                        index: i,
                    }}
                />
            ))}
        </SortableContainer>
    );

    const renderDefaultMode = () => (
        <div className="d-flex">
            {state.tiles.map((card, i) => renderCard(card, i))}
            {state.tiles.length < 10 && (
                <ItemCarouselCard
                    typeApi="carousel"
                    img="add"
                    edit={editMode}
                    imgOnly
                    onCoverClick={() => {
                        dispatch({ type: "createCard" });
                    }}
                />
            )}
        </div>
    );

    const SortableContainer = sortableContainer(({ children }) => {
        return <div className="d-flex">{children}</div>;
    });

    const SortableItem = sortableElement((localProps) => {
        const {
            value: { card, index },
        } = localProps;
        return renderCard(card, index);
    });

    const muteElements = () => {
        const elem = document.querySelectorAll(".ItemCarouselCard");
        Array.prototype.slice.call(elem).forEach((item) => {
            item.style.opacity = ".5";
        });
    };

    const renderCard = (card, i) => {
        return (
            <ItemCarouselCard
                key={i}
                length={state.tiles.length}
                edit={editMode}
                img={card.img}
                card={card}
                onTitleClick={() =>
                    props.onModalRequest({
                        id: "dynamic",
                        args: { ...modals.editItem, cardID: i },
                    })
                }
                onButtonClick={() =>
                    props.onModalRequest({
                        id: "dynamic",
                        args: { ...modals.addButton, cardID: i },
                    })
                }
                onCoverClick={() => {
                    handleCoverClick(i);
                }}
                onLeftClick={() => {
                    dispatch({ type: "itemLeft", payload: i });
                }}
                onRightClick={() => {
                    dispatch({ type: "itemRight", payload: i });
                }}
                onRemoveClick={() => {
                    dispatch({ type: "deleteCard", payload: i });
                }}
                title={card.title}
                description={card.descr}
                button={card.button}
                typeApi="carousel"
                hasError={errors.tiles_item[i]}
                hasErrorUrl={errors.tiles_url}
                hasErrorDescr={errors.tiles_descr}
            />
        );
    };

    return (
        <Group>
            <Header
                aside={
                    <Button
                        onClick={() => {
                            setEditMode(!editMode);
                            onEditMode && onEditMode(!editMode);
                        }}
                        mode="secondary"
                    >
                        {editMode ? "Готово" : "Править"}
                    </Button>
                }
            >
                <Headline level="2" weight="1">
                    Плитка с прямоугольными изображениями
                </Headline>
            </Header>
            <WidgetHeader
                onModalRequest={props.onModalRequest}
                state={state}
                edit={editMode}
                modals={modals}
                errors={errors}
            />
            <Div style={{ paddingTop: 0, paddingBottom: 8 }}>
                <AdaptiveHorizontalScroll
                    platform={LaunchParams.getPlatform()}
                    height={editMode ? 300 : 260}
                >
                    {editMode ? renderEditMode() : renderDefaultMode()}
                </AdaptiveHorizontalScroll>
            </Div>
        </Group>
    );
};

export default CarouselEdit;
