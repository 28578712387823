class Service {
    launchParams: any;

    /**
     * Generate data for the request in FormData object
     * @param data object
     * @returns requestData object
     */
    _createRequestData(data: object): BodyInit {
        let requestData = new FormData();

        for (let key in data) {
            if (
                data[key] !== null &&
                typeof data[key] !== "undefined" &&
                data[key] !== ""
            ) {
                if (Array.isArray(data[key])) {
                    if (data[key].length === 0) {
                        requestData.append(`${key}[]`, "");
                    } else {
                        data[key].forEach((i) => {
                            requestData.append(`${key}[]`, i);
                        });
                    }
                } else {
                    requestData.append(key, data[key]);
                }
            }
        }

        // requestData.append('vk_group_id', this.launchParams.params.vk_group_id.toString());

        try {
            requestData.append(
                "params",
                JSON.stringify(this.launchParams.params)
            );
        } catch (e) {
            console.log(e);
        }

        return requestData;
    }

    async _formattedResponse(response) {
        const result = await response.json();

        if (response.status === 200) {
            return result;
        } else {
            if (response.status === 404) {
                let message = result.errors ? result.errors[0] : result.message;

                return {
                    result: "error",
                    message: message ? message : "404 - страница не найдена",
                };
            } else {
                return {
                    result: "error",
                    message: result.errors ? result.errors[0] : result.message,
                };
            }
        }
    }
}

export default Service;
