/**
 * Сторонние зависимости
 */
import React, { useContext } from "react";

/**
 * React компоненты
 */
import AlertDeleteCommon from "../Сomponents/Modals/Alert/AlertDeleteCommon";
import PagesContextPopout from "../Сomponents/Controls/PagesContextPopout";
import PagesListDesktopActions from "../Сomponents/Controls/PagesListDesktopActions";

/**
 * Все остальное - хэлперы, функции, структуры данных
 */
import { Context } from "../context";
import { PageItem } from "../includes/Structures";
import { setHash, copyToClipboard } from "../includes/Helpers/Helpers";
import container from "../container";
import Api from "../includes/ApiVk";
import { VkApiCommunityData } from "../includes/Structures";

type PageContextControllerProps = {
    /**
     * Объект страницы со всеми данными
     */
    item: PageItem;

    /**
     * В режиме редактирования или нет
     */
    isEdit: boolean;

    /**
     * Режим отображения
     * context - в виде контекстного меню для отображения в modal page
     * icon - в виде списка иконок - для отображения в таблице
     */
    mode: "context" | "icons";

    popoutRef?: React.RefObject<Element>
};

const PageActionsController = (props: PageContextControllerProps) => {
    const { popout, snackbar, pages, currentModal } = useContext(Context);
    const { item, isEdit, mode, popoutRef = null } = props;

    const PagesService = container.get("PagesService");
    const LaunchParams = container.get("LaunchParams");

    const api = Api.getInstance(LaunchParams.params);

    const getPageUrl = (id: string) => {
        return `https://vk.com/app${LaunchParams.params.vk_app_id}_-${LaunchParams.params.vk_group_id}#page=${id}`;
    };

    const prepareDeletePage = (id: string) => {
        popout.open(
            <AlertDeleteCommon
                accept={() => {
                    deletePage(id);
                    popout.close();
                }}
                deny={() => {
                    popout.close();
                }}
            />
        );
    };

    /**
     * Удаление страницы
     * @param id
     */
    const deletePage = async (id: string) => {
        popout.loading();

        const response = await PagesService.deletePages([id]);

        if (response.result === "success") {
            popout.close();
            pages.deletePages([id]);
            snackbar.showSuccess("Лендинг удален");
            if (isEdit) {
                setHash({
                    list: "pages",
                });
            }
        } else {
            popout.error(response.message);
        }
    };

    /**
     * Подготовка копирования лендинга
     * @param id
     */
    const prepareCopyPage = async (id: string) => {
        const response = await api.getUserCommunities(
            LaunchParams.params.vk_user_id,
            "admin,editor"
        );

        if (!response.items) {
            popout.error("Не удалось получить сообщества");
            return false;
        }

        popout.close();

        currentModal.set({
            id: "user-communities",
            args: {
                items: response.items,
                currentGroup: LaunchParams.params.vk_group_id,
                callback: (communities: VkApiCommunityData[]) => {
                    if (communities.length === 0) {
                        // @TODO - показать модалку
                        return false;
                    }
                    const targetCommunity = communities[0];

                    if (
                        targetCommunity.id === LaunchParams.params.vk_group_id
                    ) {
                        copyPageToCurrentCommunity(id);
                    } else {
                        // Выведем предупреждение об ограничениях при копировании промки в другое сообщество
                        popout.confirm(
                            'При копировании лендинга в другое сообщество будут удалены все изображения, добавленные через загрузку файла. Все действия кнопок будут изменены на "Переход по ссылке"',
                            "Подтвердите действие",
                            [
                                {
                                    title: "Продолжить",
                                    autoClose: true,
                                    mode: "default",
                                    action: () => {
                                        copyPageToOtherCommunity(
                                            id,
                                            targetCommunity
                                        );
                                    },
                                },
                                {
                                    title: "Закрыть",
                                    autoClose: true,
                                    mode: "destructive",
                                },
                            ]
                        );
                    }
                },
            },
        });
    };

    /**
     * Копирование лендинга в текущее сообщество
     * После копирования перенаправляем пользователя на редактирование скопированной страницы
     */
    const copyPageToCurrentCommunity = async (page_id: string) => {
        popout.loading();

        const response = await PagesService.copyPage(page_id);

        popout.close();

        if (response.result === "success") {
            pages.addPage(response.data, () => {
                if (isEdit) {
                    setHash({
                        list: "pages",
                        view: "pages-edit",
                        panel: "item",
                        page_id: response.data.id,
                    });
                }
            });
            snackbar.showSuccess("Лендинг скопирован");
        } else {
            popout.error(response.message);
        }
    };

    /**
     * Копирование лендинга в другое сообщество (в котором пользователь является администратором или редактором)
     * После копирования выведем пользователю модалку с предложением перейти в целевое сообщество
     */
    const copyPageToOtherCommunity = async (
        page_id: string,
        community: VkApiCommunityData
    ) => {
        let user_token = await api.getUserTokenWithScope();

        if (!user_token) {
            popout.error("Отсутствет токен доступа пользователя");
        }

        popout.loading();

        const response = await PagesService.copyPageToOtherGroup(
            page_id,
            community.id
        );

        popout.close();

        if (response.result === "success") {
            popout.successAnimation(
                `Лендинг скопирован в сообщество "${community.name}"`,
                "",
                <div>
                    <a
                        style={{ width: "100%", textAlign: "center" }}
                        href={`https://vk.com/app${LaunchParams.params.vk_app_id}_-${community.id}`}
                        className="btn-link"
                        target="_blank"
                    >
                        Перейти
                    </a>
                </div>
            );
        } else {
            popout.error(response.message);
        }
    };

    /**
     * Перейти к редактированию страницы
     * @param id
     */
    const navigateEditPage = (id: string) => {
        setHash({
            list: "pages",
            view: "pages-edit",
            panel: "item",
            page_id: id,
        });
    };

    /**
     * Отключение страницы
     * @param id
     */
    const disablePage = async (id: string, status) => {
        popout.loading();

        let response = await PagesService.changePageStatus([id], status);

        if (response.result === "success") {
            popout.close();

            pages.updatePageStatus(id, status);

            if (status !== 3) {
                snackbar.showSuccess("Лендинг включен");
            } else {
                snackbar.showSuccess("Лендинг отключен");
            }
        } else {
            popout.error(response.message);
        }
    };

    /**
     * Перейти в форму переименованию страницы
     * @param id
     */
    const navigateRenamePage = (id: string) => {
        setHash({
            list: "pages",
            view: "pages-rename",
            panel: "item",
            page_id: id,
        });
    };

    /**
     * Перейти на вьюшку предпросмотра страницы
     * В зависимости от контеста вызова (из общего списка или из режиима редактирования) - разные вьюшки предпросмотра для отображения разных блоков
     * @param id
     */
    const navigatePagePreview = (id: string) => {
        if (isEdit) {
            setHash({
                list: "pages",
                view: "pages-edit",
                panel: "preview",
                page_id: id,
            });
        } else {
            setHash({
                list: "pages",
                view: "pages-preview",
                panel: "item",
                page_id: id,
            });
        }
    };

    /**
     * Перейти на вьюшку статистики страницы
     * @param id
     */
    const navigateToStatisticsPage = (id: string) => {
        setHash({
            list: "pages",
            view: "pages-statistic",
            panel: "item",
            page_id: id,
        });
    };

    /**
     * Скопировать ссылку на страницу в буфер обмена
     * @param id
     */
    const copyPageUrl = (id: string) => {
        let url = getPageUrl(id);
        copyToClipboard(url, () => {
            snackbar.showSuccess("Ссылка скопирована");
        });
    };

    if (mode === "icons") {
        return (
            <PagesListDesktopActions
                page={item}
                pageUrl={getPageUrl(item.id)}
                isEdit={isEdit}
                onEditClick={navigateEditPage}
                onDisableClick={disablePage}
                onCopyClick={prepareCopyPage}
                onCopyLinkClick={copyPageUrl}
                onDeleteClick={prepareDeletePage}
                onPreviewClick={navigatePagePreview}
                onStatClick={navigateToStatisticsPage}
                onRenameClick={navigateRenamePage}
            />
        );
    } else {
        return (
            <PagesContextPopout
                isEdit={isEdit}
                onEditClick={navigateEditPage}
                onCopyClick={prepareCopyPage}
                onCopyUrlClick={copyPageUrl}
                onDisableClick={disablePage}
                onRenameClick={navigateRenamePage}
                onDeleteClick={prepareDeletePage}
                onPreviewClick={navigatePagePreview}
                onStatsClick={navigateToStatisticsPage}
                item={item}
                popout={popout}
                pageUrl={getPageUrl(item.id)}
                popoutRef={popoutRef}
            />
        );
    }
};

export default PageActionsController;
