import React from "react";
import { TEXT_LINE_BREAKS_REGEXP } from "../Constants";

const TextToHtml = (html: string) => {
    if (!html) return html;

    html = html.trim();
    if (!html.length) return html;

    let arr = html.split(/<br\s*\/?>/i);
    let i = 0;
    return arr.reduce((el, a) => {
        i++;
        return el.concat(a.trim(), <br key={i} />);
    }, []);
};

export const lineBreaksToHtmlBreaks = (text: string): string => {
    if (!text) return "";
    return text.replace(TEXT_LINE_BREAKS_REGEXP, "<br>");
};

export const simpleTextToHtml = (text: string) => {
    return TextToHtml(lineBreaksToHtmlBreaks(text));
};

export const fixContentToMarkDown = (text) => {
    if (!text) return "";

    text = text.replace(
        /\[link\]([^|]+)\|?([^|]+)?\[\/link\]/im,
        function (match) {
            let link_data = match
                .replace(/\[link\]/im, "")
                .replace(/\[\/link\]/im, "")
                .split("|");
            return `[${link_data[0]}](${link_data[1]})`;
        }
    );

    text = text.replace(TEXT_LINE_BREAKS_REGEXP, function (match) {
        return "  " + match;
    });

    text = text.replace(/<(?!br\s*\/?)[^>]+>/gi, "");

    return text;
};

export default TextToHtml;
