export default (block: any) => {
    /**
     * Продумать маппинг
     */
    if (block.button) {
        if (!block.button.color || block.button.color === "#43513") {
            block.button.color = "var(--vkui--color_accent_blue)";
        }
    }

    if (block.items && block.items.length > 0) {
        block.items.forEach((item) => {
            if (!item.button) return;
            if (!item.button.color || item.button.color === "#43513") {
                item.button.color = "var(--vkui--color_accent_blue)";
            }
        });
    }

    return block;
};
