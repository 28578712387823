import React, { useState, useContext, useEffect, useRef } from "react";
import {
    Epic,
    Tabbar,
    TabbarItem,
    View,
    Panel,
    Tabs,
    TabsItem,
    FixedLayout,
    Alert,
    Spinner,
    Snackbar,
    ActionSheet,
    ActionSheetItem,
    Div,
    platform,
    Platform,
    ActionSheetDefaultIosCloseItem,
} from "@vkontakte/vkui";
import WidgetEditBody from "./WidgetEditBody";
import SubHeader from "../SubHeader";
import { WidgetState } from "../../context";
import { Context } from "../../context";
import container from "../../container";
import WidgetService from "../../includes/Services/WidgetService";
import WidgetValidate from "../../includes/Validators/WidgetValidate";
import WidgetAudienceForm from "../Forms/WidgetAudienceForm/WidgetAudienceForm";
import AlertEnable from "../Modals/Alert/AlertEnable";
import AlertPublish from "../Modals/Alert/AlertPublish";
import ApiVk from "../../includes/ApiVk";
import { copyToClipboard } from "../../includes/Helpers/Helpers";

import {
    widgetHasUnpublishedState,
    widgetHasAudience,
    setHash,
    getWidgetsWithLastPublishedState,
} from "../../includes/Helpers/Helpers";

import {
    Analytics,
    WIDGET_EDIT,
    WIDGET_PUBLISH,
} from "../../includes/Metrics/Analytics";

import { WIDGET_MAX_AUDIENCE_COMMUNITIES, WIDGET_STATUS_ACTIVE } from "../../includes/Constants";

import CopyToCommunityAction from "../Controls/Actions/CopyToCommunityAction";
import ShareAction from "../Controls/Actions/ShareAction";
import { Icon24Copy, Icon24Settings, Icon24Share, Icon28ErrorOutline } from "@vkontakte/icons";
import { WIDGET_MAX_AUDIENCE_COMMUNITIES_ERROR } from "../../includes/ErrorMessages";

const initalError = {
    status: true,
    resp: {},
    msg: "",
};

const WidgetBodyCreator = container.get("WidgetBodyCreator");

const WidgetEdit = (props) => {
    const { snackbar, widgets, popout, resizer } = useContext(Context);

    const { widget, params, activeTab, isNewModel } = props;

    const apiVk = ApiVk.getInstance(null);

    const currentView = activeTab === "" ? 0 : 1;

    const [bodyWidget, setBodyWidget] = useState(widget ? widget : {});
    const [widgetAudience, setWidgetAudience] = useState(
        props.widget.audience ? props.widget.audience : {}
    );
    const [isSave, setIsSave] = useState(true);
    const [isRun] = useState(true);
    const [editMode, setEditMode] = useState(false);

    const [errors, setErrors] = useState(initalError);

    const popoutRef = useRef();

    useEffect(() => {
        Analytics.goal(WIDGET_EDIT);

        setTimeout(() => {
            resizer.setHeight();
        }, 0);

        return () => {
            // При размонтировке компонента закрываем все snackbar,
            // так как там могут быть действия со стейтом
            snackbar.close();
        };
    }, []);

    /**
     * Действие на обновление тела виджета
     */
    useEffect(() => {
        if (
            "code" in bodyWidget &&
            Object.keys(bodyWidget.code).length > 0
        ) {
            wigetSave();
        }

        setErrors(initalError);
    }, [bodyWidget]);

    /**
     * Действие на обновление аудитории виджета
     */
    useEffect(() => {
        if (!!widgetAudience) {
            widgetSaveAudience();
            setErrors(initalError);
        }
    }, [widgetAudience]);

    const setSetting = () => {
        popout.open(
            <ActionSheet
                className="WidgetEdit__popout"
                onClose={() => popout.close()}
                iosCloseItem={<ActionSheetDefaultIosCloseItem />}
                toggleRef={popoutRef}
            >
                <ActionSheetItem
                    onClick={() => {
                        widget.code = { ...bodyWidget.code };
                        changeWidgetStatus([widget], Number(!widget.status));
                    }}
                    autoClose
                >
                    {widget.status === 1 ? "Отключить" : "Включить"}
                </ActionSheetItem>
                <ActionSheetItem
                    onClick={() => {
                        widget &&
                            widget.id &&
                            setHash({
                                list: "widgets",
                                view: "editName",
                                panel: "name",
                                widget_id: widget.id,
                            });
                    }}
                    autoClose
                >
                    Переименовать
                </ActionSheetItem>

                <ActionSheetItem
                    className="WidgetEdit__copy-widget-btn"
                    autoClose
                >
                    <CopyToCommunityAction
                        items={[widget]}
                        copyInCurrentGroup={(_, newCreatedWidgets) => {
                            if (newCreatedWidgets.length === 1) {
                                setHash({
                                    list: "widgets",
                                });
                            }
                        }}
                    />
                </ActionSheetItem>

                {false && <ShareAction type="popout-item" items={[widget]} />}

                {widgetHasAudience({ ...widget, audience: widgetAudience }) && (
                    <ActionSheetItem
                        onClick={() => setWidgetAudience({})}
                        autoClose
                    >
                        Сбросить настройки аудитории
                    </ActionSheetItem>
                )}
                <ActionSheetItem
                    mode="destructive"
                    onClick={confirmDelete}
                    //@ts-ignore
                    theme="destructive"
                    autoClose
                >
                    Удалить виджет
                </ActionSheetItem>
                {platform() !== Platform.IOS && (
                    <ActionSheetItem
                        className={
                            platform() === Platform.ANDROID
                                ? "action-sheet-android-cancel"
                                : ""
                        }
                        mode={"default"}
                        style={{
                            color:
                                platform() === Platform.ANDROID
                                    ? "var(--vkui--color_text_secondary)"
                                    : "var(--action_sheet_action_foreground)",
                        }}
                        autoClose
                    >
                        Отмена
                    </ActionSheetItem>
                )}
            </ActionSheet>,
            "context"
        );
    };

    const changeWidgetStatus = async (widgetsArray, status) => {
        if (isReachedChosenCommunitiesLimitInAudience(widget)) {
            snackbar.showError(WIDGET_MAX_AUDIENCE_COMMUNITIES_ERROR);
            return
        }

        if (status === WIDGET_STATUS_ACTIVE) {
            const isValid = WidgetValidate.isCode(bodyWidget);

            if (isValid.status === false) {
                setErrors(isValid);
                snackbar.showError(isValid.msg, 2000);
                return;
            }
        }

        const needValidation = false;

        // Если включаем
        if (status === WIDGET_STATUS_ACTIVE) {
            let isPublishedType = false; // Если публикуем тот же тип, что и опубликован сейчас
            widgets.getPublished().forEach((item, i) => {
                if (item.type === widgetsArray[0].type) isPublishedType = true;
            });

            let publishedWidgets = getWidgetsWithLastPublishedState(
                widgets.getPublished()
            );
            let updatePublishedStateIds = widgetsArray.map((i) => i.id);

            const widgetPreview = widgetsArray[0];

            if (isPublishedType || publishedWidgets.length === 0) {
                let widgetsToPublish = [...publishedWidgets, ...widgetsArray];
                changeWidgetsStatusToServer(
                    widgetsToPublish,
                    updatePublishedStateIds,
                    status,
                    widgetPreview,
                    needValidation
                );
            } else {
                popout.open(
                    <AlertEnable
                        popout={popout}
                        changeWidgetsStatusToServer={() => {
                            changeWidgetsStatusToServer(
                                widgetsArray,
                                updatePublishedStateIds,
                                status,
                                false,
                                needValidation
                            );
                        }}
                        widgetsArray={widgetsArray}
                        updatePublishedStateIds={updatePublishedStateIds}
                    />
                );
            }
            Analytics.goal(WIDGET_PUBLISH);
            // Если выключаем
        } else {
            const unpublishIds = widgetsArray.map((i) => i.id);
            const widgetsToPublish = widgets
                .getPublished()
                .filter((i) => unpublishIds.indexOf(i.id) < 0);

            let widgetPreview

            widgetsToPublish.length > 0
                ? widgetPreview = widgetsToPublish[0]
                : widgetPreview = false

            changeWidgetsStatusToServer(
                widgetsToPublish,
                [],
                status,
                widgetPreview,
                needValidation
            );
        }
    };

    const changeWidgetsStatusToServer = async (
        widgets_array,
        updatePublishedStateIds,
        status = 1,
        widgetPreview = false,
        needValidation = true
    ) => {
        // Выбираем только уникальные значения
        widgets_array = widgets_array.filter(
            (widget, index, self) =>
                index === self.findIndex((w) => w.id === widget.id)
        );
        // При включении\массовом включении Просто добавить в конце массива не достаточно.
        // Может быть так, что публикуемые выше опубликованных в списке. Либо в разных местах.
        // Поэтому определим порядок по параметру сортировки
        widgets_array.sort((a, b) => {
            if (a.sort > b.sort) return 1;
            else if (a.sort < b.sort) return -1;
            else return 0;
        });

        if (needValidation) {
            let validation = WidgetValidate.batch(widgets_array);

            if (validation.status === false) {
                // Какие-то виджеты не валидны
                popout.close();
                snackbar.showError(
                    "Некоторые из виджетов не заполнены или заполнены неверно"
                );
                return false;
            }
        }

        if (widgetPreview) {
            // Получим текущее время сервера вк
            const vkServerTime = await apiVk.getServerTime();

            if (vkServerTime.result === "error") {
                popout.close();
                snackbar.showError("Разрешение не получено");
                return false;
            }

            // Если получили успешный ответ, то присвоим время окончания показа превью - текущее серверное время + 60 сек.
            // @ts-ignore
            widgetPreview.previewEndTime =
                vkServerTime > 0 ? vkServerTime + 60 : 0;
        }

        let { code } = WidgetBodyCreator.collection(
            widgets_array,
            widget.type,
            widgetPreview
        );

        popout.loading();

        const payload = await WidgetService.publish({
            ids: widgets_array.map((item) => item.id),
            code: code,
            type: widget.type,
            group_id: params.params.vk_group_id,
            update_last_p_state_ids: updatePublishedStateIds,
        });

        if (payload.result === "success") {
            snackbar.showSuccess("Статус виджета изменен");
            widget.status = status;
            widgets.setActive(widget);
            widgets.set({
                all: payload.response,
                published: payload.response.filter((w) => w.status === 1),
            });
            popout.close();
        } else {
            if (payload.result !== "cancel") {
                popout.open(
                    <AlertPublish
                        payload={payload}
                        timerCallback={() => {
                            changeWidgetsStatusToServer(
                                widgets_array,
                                updatePublishedStateIds,
                                status,
                                widgetPreview
                            );
                            popout.close();
                        }}
                    />
                );
            } else {
                popout.close();
            }
        }
    };

    const confirmDelete = () => {
        popout.open(
            <Alert
                actionsLayout="vertical"
                actions={[
                    {
                        title: "Удалить",
                        autoClose: false,
                        mode: "destructive",
                        action: () => {
                            deleteWidget();
                        },
                    },
                    {
                        title: "Отменить",
                        autoClose: false,
                        mode: "cancel",
                        action: () => {
                            popout.close();
                        },
                    },
                ]}
                onClose={() => {
                    popout.close();
                }}
            >
                <h2>Подтвердите действие</h2>
                <p>Вы уверены, что хотите удалить этот виджет ?</p>
            </Alert>
        );
    };

    const deleteWidget = async () => {
        if (isReachedChosenCommunitiesLimitInAudience(widget)) {
            snackbar.showError(WIDGET_MAX_AUDIENCE_COMMUNITIES_ERROR);
            popout.close();
            return
        }

        const deleteIds = [widget.id];
        const publishedWidgets = widgets.getPublished();
        popout.loading();
        let result: any = "";

        if (widget.status) {
            const widgetsToPublish = publishedWidgets.filter(
                (i) => deleteIds.indexOf(i.id) < 0
            );

            let widgetPreview

            if (widgetsToPublish.length > 0) {
                widgetPreview = widgetsToPublish[0]

                // Получим текущее время сервера вк
                const vkServerTime = await apiVk.getServerTime();

                if (vkServerTime.result === "error") {
                    popout.close();
                    snackbar.showError("Разрешение не получено");
                    return false;
                }

                // Если получили успешный ответ, то присвоим время окончания показа превью - текущее серверное время + 60 сек.
                widgetPreview.previewEndTime =
                    vkServerTime > 0 ? vkServerTime + 60 : 0;
            } else {
                widgetPreview = false
            }

            result = await WidgetService.publish({
                ids: widgetsToPublish.map((i) => i.id),
                code: WidgetBodyCreator.collection(
                    widgetsToPublish,
                    widget.type,
                    widgetPreview
                ).code,
                type: widget.type,
                group_id: params.params.vk_group_id,
                delete_ids: deleteIds,
            });
        } else {
            result = await WidgetService.deleteWidgets(deleteIds);
        }

        popout.close();

        if (result.result === "success") {
            snackbar.showSuccess("Виджет удален");
            props.onBack();
        } else {
            if (result.result !== "cancel") {
                popout.open(
                    <AlertPublish
                        payload={result}
                        timerCallback={() => {
                            deleteWidget();
                            popout.close();
                        }}
                    />
                );
            } else {
                popout.close();
            }
        }
    };

    const validateAndPublishWidget = async () => {
        const isValid = WidgetValidate.isCode(bodyWidget);

        if (isValid.status === false) {
            setErrors(isValid);
            snackbar.showError(isValid.msg, 2000);
            return;
        }

        const publishedWidgets = [...widgets.getPublished()]
            .filter((i) => i.id !== widget.id)
            .map((item) => {
                if (widgetHasUnpublishedState(item)) {
                    let itemInPublishedState = { ...item };
                    try {
                        let lastPublishedState = JSON.parse(
                            item.last_published_state
                        );
                        itemInPublishedState.code = lastPublishedState.code;
                        itemInPublishedState.audience =
                            lastPublishedState.audience;
                        return itemInPublishedState;
                    } catch (e) {
                        return item;
                    }
                } else {
                    return item;
                }
            });

        const isPublishedType =
            publishedWidgets.filter((item) => item.type === widget.type)
                .length > 0;
        const widgetData = {
            ...widget,
            code: bodyWidget.code,
            audience: widgetAudience,
        };

        const widgetPreview = { ...widgetData };

        if (isPublishedType || publishedWidgets.length === 0) {
            publishWidgets(
                [...publishedWidgets, widgetData],
                [widgetData.id],
                widgetPreview
            );
        } else {
            popout.open(
                <Alert
                    actionsLayout="vertical"
                    actions={[
                        {
                            title: "Опубликовать",
                            autoClose: true,
                            mode: "default",
                            action: () => {
                                publishWidgets([widgetData]);
                            },
                        },
                        {
                            title: "Отменить",
                            autoClose: true,
                            mode: "cancel",
                        },
                    ]}
                    onClose={() => {
                        popout.close();
                    }}
                >
                    <h2>Подтвердите действие</h2>
                    <p>
                        Публикация виджета приведёт к отключению виджетов
                        другого типа.
                    </p>
                </Alert>
            );
        }
    };

    const isReachedChosenCommunitiesLimitInAudience = (widget) => {
        const allWidgetsByType = widgets.getAllByType(widget.type)
        const chosenCommunitiesInAudience = []

        allWidgetsByType.forEach(w => {
            if ('communityIn' in w.audience) {
                chosenCommunitiesInAudience.push(...w.audience.communityIn)
            }

            if ('communityOut' in w.audience) {
                chosenCommunitiesInAudience.push(...w.audience.communityOut)
            }
        })

        return chosenCommunitiesInAudience.length > WIDGET_MAX_AUDIENCE_COMMUNITIES
    }

    const publishWidgets = async (
        widgets_array,
        widgets_to_update_last_published_state?: any,
        widgetPreview?: any
    ) => {
        if (isReachedChosenCommunitiesLimitInAudience(widget)) {
            snackbar.showError(WIDGET_MAX_AUDIENCE_COMMUNITIES_ERROR);
            return
        }

        widgets_array.sort((a, b) => {
            if (a.sort > b.sort) return 1;
            else if (a.sort < b.sort) return -1;
            else return 0;
        });

        setIsSave(false);

        if (widgetPreview) {
            // Получим текущее время сервера вк
            const vkServerTime = await apiVk.getServerTime();

            if (vkServerTime.result === "error") {
                popout.close();
                snackbar.showError("Разрешение не получено");
                setIsSave(false);
                return false;
            }
            // Если получили успешный ответ, то присвоим время окончания показа превью - текущее серверное время + 60 сек.
            widgetPreview.previewEndTime =
                vkServerTime > 0 ? vkServerTime + 60 : 0;
        }

        const { code } = WidgetBodyCreator.collection(
            widgets_array,
            widget.type,
            widgetPreview
        );

        const payload = await WidgetService.publish({
            ids: widgets_array.map((item) => item.id),
            update_last_p_state_ids: widgets_to_update_last_published_state,
            code: code,
            type: widget.type,
            group_id: params.params.vk_group_id,
        });

        if (payload.result === "success") {
            snackbar.showSuccess("Виджет опубликован");
            widgets.set({
                published: payload.response.filter(
                    (item) => item.status === WIDGET_STATUS_ACTIVE
                ),
                all: payload.response,
            });
            Analytics.goal(WIDGET_PUBLISH);
            widget.status = 1;
        } else {
            if (payload.result !== "cancel") {
                popout.open(
                    <AlertPublish
                        payload={payload}
                        timerCallback={() => {
                            publishWidgets(
                                widgets_array,
                                widgets_to_update_last_published_state,
                                widgetPreview
                            );
                            popout.close();
                        }}
                    />
                );
            } else {
                popout.close();
            }
        }

        setIsSave(true);
    };

    const widgetSaveAudience = async () => {
        setIsSave(false);

        const response = await WidgetService.updateAudience(
            props.widget.id,
            widgetAudience
        )

        if (response.result === 'success') {
            setIsSave(true);
            widgets.updateAudience(widget, widgetAudience);
        } else {
            setIsSave(true);
            snackbar.showError(response.message);
        }
    };

    const wigetSave = async () => {
        setIsSave(false);

        const response = await WidgetService.updateBody(
            props.widget.id,
            bodyWidget.code
        )

        setIsSave(true);

        widgets.updateBody(widget, bodyWidget.code);

        if (response.result === "error") {
            snackbar.open(
                <Snackbar
                    className="WidgetEdit__snackbar"
                    onClose={() => {
                        snackbar.close();
                    }}
                    before={
                        <Icon28ErrorOutline fill="var(--vkui--color_accent_red)" />
                    }
                    duration={5000}
                    action="Повторить"
                    onActionClick={() => {
                        wigetSave();
                        snackbar.close();
                    }}
                >
                    <div>Не удалось сохранить.</div>
                    <div>Ошибка соединения.</div>
                </Snackbar>
            );
        }
    };

    /**
     * Скопировать id виджета в буфер обмена
     * @param {string} widget_id
     */
    const copyIdToClipboard = (widget_id) => {
        copyToClipboard(widget_id, () => {
            snackbar.showSuccess("Скопировано");
        });
    };

    const views = [
        {
            title: "Вид",
            body: () => (
                <WidgetEditBody
                    type={widget.type_api}
                    params={{
                        onModalRequest: props.onModalRequest,
                        params: props.params,
                        id: props.widget.id,
                        errors: errors,
                        onEditMode: setEditMode,
                    }}
                />
            ),
        },
        {
            title: "Аудитория",
            body: () => (
                <>
                    <WidgetAudienceForm
                        widget={widget}
                        isPending={!isSave}
                        audience={widgetAudience}
                        setEditMode={setEditMode}
                        onModalRequest={props.onModalRequest}
                        onAudienceUpdate={(data) => {
                            setWidgetAudience(data);
                        }}
                    />
                </>
            ),
        },
    ];

    const renderTabs = () => {
        return (
            <Tabbar className="WidgetEdit__bottom-tabbar">
                <TabbarItem
                    onClick={() => setSetting()}
                    // getRootRef={popoutRef}
                    selected
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        ref={popoutRef}
                    >
                        <Icon24Settings />
                        <span className="widget-edit-bottom-item">
                            Настройки
                        </span>
                    </div>
                </TabbarItem>
                <TabbarItem
                    selected={isRun}
                    onClick={
                        isSave === false ? () => {} : validateAndPublishWidget
                    }
                >
                    {isSave ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Icon24Share />
                            <span className="widget-edit-bottom-item">
                                Опубликовать
                            </span>
                        </div>
                    ) : (
                        <Spinner size="regular" />
                    )}
                </TabbarItem>
            </Tabbar>
        );
    };

    const currentEditableWidget = { ...props.widget };

    return (
        <WidgetState.Provider
            value={{
                stateBodyWidget: setBodyWidget,
                state: bodyWidget,
            }}
        >
            <SubHeader onBack={props.onBack} separator={false} fixed>
                {props.widget.name ? props.widget.name : "Мой виджет"}
            </SubHeader>
            <FixedLayout className="WidgetEdit__tabs-layout">
                <Tabs
                    //@ts-ignore
                    theme="header"
                    type="buttons"
                    style={{ background: "var(--vkui--color_background_content)" }}
                >
                    {views.map((v, i) => (
                        <TabsItem
                            key={i}
                            selected={i === currentView}
                            onClick={() => {
                                if (i === 0) {
                                    currentEditableWidget &&
                                        setHash({
                                            list: "widgets",
                                            view: isNewModel
                                                ? "widgets"
                                                : "edit",
                                            panel: isNewModel ? "edit" : "add",
                                            widget_id: currentEditableWidget.id,
                                        });
                                } else {
                                    currentEditableWidget &&
                                        setHash({
                                            list: "widgets",
                                            view: isNewModel
                                                ? "widgets"
                                                : "edit",
                                            panel: isNewModel ? "edit" : "add",
                                            widget_id: currentEditableWidget.id,
                                            tab: "audience",
                                        });
                                }
                            }}
                            style={{ flexGrow: 1, borderRadius: 0 }}
                        >
                            {v.title}
                        </TabsItem>
                    ))}
                </Tabs>
            </FixedLayout>

            <Epic
                activeStory="widget-edit-main"
                tabbar={editMode === false ? renderTabs() : null}
            >
                <View id="widget-edit-main" activePanel="widget-edit-main-main">
                    <Panel id="widget-edit-main-main">
                        {views[currentView].body()}

                        {/* Для плиток и обложек выводим информационное уведомление*/}
                        {editMode &&
                            ["tiles", "cover_list"].indexOf(widget.type) >=
                                0 && (
                                <Div className="widget-edit-sub">
                                    Для того чтобы переместить карточку
                                    используйте долгое нажатие
                                </Div>
                            )}

                        <Div
                            onClick={() => {
                                copyIdToClipboard(widget.id);
                            }}
                            style={{
                                fontSize: 13,
                                display: "flex",
                                cursor: "pointer",
                            }}
                        >
                            <Icon24Copy
                                fill={"var(--vkui--color_text_secondary)"}
                                width={14}
                                height={14}
                            />
                            <span
                                style={{
                                    marginLeft: 6,
                                    color: "var(--vkui--color_text_secondary)",
                                }}
                            >
                                ID виджета: {widget.id}
                            </span>
                        </Div>
                    </Panel>
                </View>
            </Epic>
        </WidgetState.Provider>
    );
};

export default WidgetEdit;
