import { CSSProperties } from "react";

export default (hasError: boolean, allowEdit: boolean) => {
    const styles: CSSProperties = {};

    if (hasError) {
        styles.color = "var(--vkui--color_accent_red)";
    }

    if (allowEdit === false) {
        styles.opacity = 0.2;
    }

    return styles;
};
