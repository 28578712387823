import ErrorInterface from "../Interfaces/ErrorInterface";

class Logger {
    launchParams: any;
    host: string;
    route: string;

    constructor(launchParams: any, host: string) {
        this.launchParams = launchParams;
        this.host = host;
        this.route = "/log/error";
    }

    async error(error: ErrorInterface, type: string = "", file: string) {
        const data: any = {
            file,
            error_type: type,
            created_at: new Date(),
        };

        if (error.message) {
            data.message = error.message;
        }

        if (error.code) {
            data.code = error.code;
        }

        const body = this._createRequestData({
            data: JSON.stringify(data),
        });

        const response = await fetch(`${this.host}${this.route}/1`, {
            method: "POST",
            body,
        });

        return this._formattedResponse(response);
    }

    /**
     * Generate data for the request in FormData object
     * @param data object
     * @returns requestData object
     */
    _createRequestData(data: object): BodyInit {
        let requestData = new FormData();

        for (let key in data) {
            if (
                data[key] !== null &&
                typeof data[key] !== "undefined" &&
                data[key] !== ""
            ) {
                if (Array.isArray(data[key])) {
                    if (data[key].length === 0) {
                        requestData.append(`${key}[]`, "");
                    } else {
                        data[key].forEach((i) => {
                            requestData.append(`${key}[]`, i);
                        });
                    }
                } else {
                    requestData.append(key, data[key]);
                }
            }
        }

        requestData.append(
            "vk_group_id",
            this.launchParams.params.vk_group_id.toString()
        );

        try {
            requestData.append(
                "params",
                JSON.stringify(this.launchParams.params)
            );
        } catch (e) {
            console.log(e);
        }

        return requestData;
    }

    async _formattedResponse(response) {
        const result = await response.json();
        if (response.status === 200) {
            return result;
        } else {
            /**
             * @TODO handle error
             */
            return {
                result: "error",
                message: result.errors ? result.errors[0] : result.message,
            };
        }
    }
}

export default Logger;
