export const initialState = {
    isEditMode: false,
    subscriptions: [],
    subscriptions_hidden: [],
};

export const MODULE_NAME = "subscriptionList";

export const PUT_SUBSCRIPTIONS = `${MODULE_NAME}/put_subscriptions`;
export const PUT_EDIT_MODE = `${MODULE_NAME}/put_edit_mode`;
export const HIDE_SUBSCRIPTION_GROUP = `${MODULE_NAME}/hide_subscription_group`;
export const SHOW_SUBSCRIPTION_GROUP = `${MODULE_NAME}/show_subscription_group`;
export const SORT_SUBSCRIPTION_GROUPS = `${MODULE_NAME}/sort_subscription_groups`;

export default function reducer(state, action) {
    const { type, payload } = action;

    const newState = { ...state };

    switch (type) {
        case PUT_EDIT_MODE:
            newState.isEditMode = payload;
            return newState;

        case PUT_SUBSCRIPTIONS:
            newState.subscriptions = payload.subscriptions;
            newState.subscriptions_hidden = payload.subscriptions_hidden;
            return newState;

        case SORT_SUBSCRIPTION_GROUPS:
            const { itemIndex, toIndex } = payload;
            const newSubscriptions = state.subscriptions.map((item) => item);
            newSubscriptions.splice(itemIndex, 1);
            newSubscriptions.splice(toIndex, 0, state.subscriptions[itemIndex]);
            newState.subscriptions = newSubscriptions;

            return newState;

        case HIDE_SUBSCRIPTION_GROUP:
            const item = state.subscriptions.filter(
                (sub) => sub.data.subscription_id === payload
            )[0];
            const newSubs = state.subscriptions.filter(
                (sub) => sub.data.subscription_id !== payload
            );
            const newHiddenSubs = [...state.subscriptions_hidden];
            if (item) {
                item.data.inactive = 1;
                newHiddenSubs.push(item);
            }
            newState.subscriptions = newSubs;
            newState.subscriptions_hidden = newHiddenSubs;
            return newState;

        case SHOW_SUBSCRIPTION_GROUP:
            const newActiveSub = payload;
            const newActiveSubscriptions = [...state.subscriptions];
            const newHiddenSubscriptions = state.subscriptions_hidden.filter(
                (sub) =>
                    sub.data.subscription_id !==
                    newActiveSub.data.subscription_id
            );

            newActiveSub.data.inactive = 0;
            newActiveSubscriptions.push(newActiveSub);

            newState.subscriptions = newActiveSubscriptions;
            newState.subscriptions_hidden = newHiddenSubscriptions;

            return newState;

        default:
            return state;
    }
}
