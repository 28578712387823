import React, { Component } from "react";
import { Button } from "@vkontakte/vkui";
import TextToHtml from "../includes/Helpers/TextToHtml";
import {
    dateToISOFormat,
    daysBetween,
    getDaysCount,
    setHash,
} from "../includes/Helpers/Helpers";
import {
    getSubscriptionPrice,
    cropDescription,
    getPayPeriod,
} from "../includes/Helpers/HelpersSubscriptions";
import container from "../container";
import Twemoji from "react-twemoji";
import Logger from "../includes/Services/Logger";
import { Icon20RecentOutline, Icon24Write } from "@vkontakte/icons";
import * as Sentry from "@sentry/react";

type SubscriptionShortProps = {
    opts: any;
    urlParams: any;
    unsubscribe: any;
    prepare_subscribe: any;
    type: any;
};

type SubscriptionShortState = {
    avatarHeight: any;
    windowWidth: any;
    windowHeight: any;
};

class SubscriptionShort extends Component<
    SubscriptionShortProps,
    SubscriptionShortState
> {
    ref: any;
    logger: Logger;

    closeTooltipExpire() {
        let t = {};
        t["t_expire_" + this.props.opts.subscription_id] = false;
        this.setState(t);
    }

    openTooltipExpire() {
        let t = {};
        t["t_expire_" + this.props.opts.subscription_id] = true;
        this.setState(t);
    }

    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            avatarHeight: 80,
        };

        this.ref = React.createRef();

        this.logger = container.get("logger");
    }

    handleResize = () => {
        let avHeight = 80;

        if (this.ref.current) {
            avHeight = this.ref.current.offsetWidth;
        }

        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            avatarHeight: avHeight,
        });
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        if (this.ref.current) {
            this.setState({ avatarHeight: this.ref.current.offsetWidth });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    getDaysCountNumber() {
        try {
            const { opts } = this.props;
            const date = dateToISOFormat(opts.pay_expired);
            if (!date) {
                return "";
            }

            const now = new Date();
            const endDate = new Date(date);

            const daysCount = daysBetween(now, endDate);

            return daysCount;
        } catch (e) {
            this.logger.error(
                { code: 9039, message: e.message },
                "runtime_error",
                "SubscriptionShort.jsx"
            );
            Sentry.captureException(e);
            return "";
        }
    }

    getHowMatchLeft(extended = false) {
        try {
            const { opts } = this.props;
            const date = dateToISOFormat(opts.pay_expired);
            if (!date) {
                return "";
            }

            const now = new Date();
            const endDate = new Date(date);

            const daysCount = daysBetween(now, endDate);

            if (daysCount <= 0) {
                return "";
            }

            if (extended) {
                return getDaysCount(daysCount);
            }

            if (daysCount === 1 || (daysCount % 10 === 1 && daysCount !== 11)) {
                return `Остался ${daysCount} день`;
            } else if (daysCount >= 12 && daysCount <= 14) {
                return `Осталось ${daysCount} дней`;
            } else if (
                (daysCount >= 2 && daysCount <= 4) ||
                (daysCount % 10 >= 2 && daysCount % 10 <= 4)
            ) {
                return `Осталось ${daysCount} дня`;
            } else {
                return `Осталось ${daysCount} дней`;
            }
        } catch (e) {
            this.logger.error(
                { code: 9040, message: e.message },
                "runtime_error",
                "SubscriptionShort.jsx"
            );
            Sentry.captureException(e);
            return "";
        }
    }

    getPrice() {
        const { opts } = this.props;
        return getSubscriptionPrice(opts);
    }

    getPayPeriod() {
        const { opts } = this.props;
        return getPayPeriod(opts);
    }

    handleClick = (e) => {
        const { opts } = this.props;
        try {
            setHash({
                list: "subscriptions",
                s_id: opts.subscription_id,
            });
        } catch (e) {
            this.logger.error(
                { code: 9041, message: e.message },
                "runtime_error",
                "SubscriptionsShort.jsx"
            );
            Sentry.captureException(e);
        }
    };

    renderAvatar() {
        const { opts, type } = this.props;

        return (
            <div>
                <div
                    onClick={this.handleClick}
                    className="Subscriptions-short__avatar"
                    style={{
                        width: type === "tiles" ? "100%" : 80,
                        height: type === "tiles" ? this.state.avatarHeight : 80,
                        backgroundImage: `url(${
                            opts.icon
                                ? opts.icon
                                : "img/SubscriptionCatalogPlaceholder.svg"
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: 6,
                    }}
                >
                    {/* Для платных подписок в плитке выводим стоимость или остаток времени (если пописка оформлена) */}
                    {type === "tiles" && opts.pay && (
                        <>
                            {opts.paid &&
                            opts.pay_expired &&
                            this.getDaysCountNumber() > 0 ? (
                                <div className="Subscriptions-short__avatar-info Subscriptions-short__avatar-info_time">
                                    <Icon20RecentOutline
                                        width={13}
                                        height={13}
                                    />
                                    <span>{this.getHowMatchLeft(true)}</span>
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
        );
    }

    showDescription = () => {
        const { opts } = this.props;
        const isSubscribed = opts.subscribed;

        return (
            <>
                <>
                    {isSubscribed
                        ? this.renderSubscribedButtons()
                        : this.renderNotSubscribedButtons()}
                </>
            </>
        );
    };

    renderSubscribedButtons() {
        const { opts, prepare_subscribe, unsubscribe, type } = this.props;
        let streched = type === "tiles";

        let classNames = ["ShortFooterSubscription_item-buttons"];
        let buttonClassName = streched
            ? "ShortFooterSubscription_item-button_tiles"
            : "";
        if (type === "rows")
            classNames.push("ShortFooterSubscription_item-buttons_rows");

        //  Для платных периодических подписок выводим только кнопку "Продлить"
        if (opts.pay && opts.pay_period > 0 && opts.status === "start") {
            return (
                <div className="ShortFooterSubscription ShortFooterSubscription_paid">
                    <div className={classNames.join(" ")}>
                        {type !== "tiles" && (
                            <div
                                className="ShortFooterSubscription_item-price"
                                style={{ marginTop: 5 }}
                            >
                                {opts.paid &&
                                    opts.pay_expired &&
                                    this.getDaysCountNumber() > 0 && (
                                        <div className="ShortFooterSubscription_item-price-left">
                                            <Icon20RecentOutline
                                                height={13}
                                                width={13}
                                            />
                                            <span>
                                                {this.getHowMatchLeft()}
                                            </span>
                                        </div>
                                    )}
                            </div>
                        )}

                        <div className="ShortFooterSubscription_item">
                            <div
                                className={`ShortFooterSubscription_item-button ${buttonClassName}`}
                            >
                                <div className="ShortFooterSubscription_item-price">
                                    <div className="ShortFooterSubscription_item-price-content">
                                        <span className="ShortFooterSubscription_item-price-cost">
                                            {opts.pay_price} <i>₽</i>
                                        </span>
                                        <span className="ShortFooterSubscription_item-price-period">
                                            {this.getPayPeriod()}
                                        </span>
                                    </div>
                                </div>
                                <Button
                                    mode="outline"
                                    stretched={streched}
                                    onClick={() =>
                                        prepare_subscribe && prepare_subscribe()
                                    }
                                >
                                    Продлить
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (opts.pay) {
            // Платная разовая
            return (
                <div className="ShortFooterSubscription ShortFooterSubscription_paid">
                    <div className="ShortFooterSubscription_item">
                        <div
                            className={`ShortFooterSubscription_item-button ${buttonClassName}`}
                        >
                            <div className="ShortFooterSubscription_item-price">
                                <div className="ShortFooterSubscription_item-price-content">
                                    <span className="ShortFooterSubscription_item-price-cost">
                                        {opts.pay_price} <i>₽</i>
                                    </span>
                                    <span className="ShortFooterSubscription_item-price-period">
                                        {this.getPayPeriod()}
                                    </span>
                                </div>
                            </div>
                            <Button
                                stretched={streched}
                                mode="secondary"
                                onClick={() => unsubscribe && unsubscribe()}
                            >
                                {opts.btn_cancel}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        } else {
            // Для всех остальных - отписаться
            return (
                <div className="ShortFooterSubscription ShortFooterSubscription_paid">
                    <div className="ShortFooterSubscription_item">
                        <div
                            className={`ShortFooterSubscription_item-button ${buttonClassName}`}
                        >
                            <Button
                                stretched={streched}
                                mode="secondary"
                                onClick={() => unsubscribe && unsubscribe()}
                            >
                                {opts.btn_cancel}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderNotSubscribedButtons() {
        const { opts, type, prepare_subscribe } = this.props;
        let streched = type === "tiles";

        return (
            <div className="ShortFooterSubscription">
                <div className="ShortFooterSubscription_item">
                    {(() => {
                        switch (opts.status) {
                            case "start":
                                return (
                                    <div
                                        className={`ShortFooterSubscription_item-buttons ${
                                            type == "rows"
                                                ? "ShortFooterSubscription_item-buttons_rows"
                                                : ""
                                        }`}
                                    >
                                        {type !== "tiles" && opts.pay && (
                                            <div className="ShortFooterSubscription_item-price">
                                                {opts.pay ? (
                                                    <>
                                                        {opts.paid &&
                                                        opts.pay_expired ? (
                                                            <div className="ShortFooterSubscription_item-price-left">
                                                                {this.getHowMatchLeft()}
                                                            </div>
                                                        ) : (
                                                            <div className="ShortFooterSubscription_item-price-content">
                                                                <span className="ShortFooterSubscription_item-price-cost">
                                                                    {
                                                                        opts.pay_price
                                                                    }{" "}
                                                                    <i>₽</i>
                                                                </span>
                                                                <span className="ShortFooterSubscription_item-price-period">
                                                                    {this.getPayPeriod()}
                                                                </span>
                                                            </div>
                                                        )}
                                                        {opts.paid &&
                                                            !opts.pay_expired}
                                                    </>
                                                ) : null}
                                            </div>
                                        )}

                                        <Button
                                            mode="primary"
                                            stretched={streched}
                                            onClick={() =>
                                                prepare_subscribe &&
                                                prepare_subscribe()
                                            }
                                        >
                                            {opts.btn_ok}
                                        </Button>
                                    </div>
                                );
                            case "not_start":
                                return (
                                    <div className="Subscriptions-short__date">
                                        <div>Начало</div>
                                        <div>{opts.limit_period_from}</div>
                                    </div>
                                );
                            case "finished":
                                return (
                                    <div className="Subscriptions-short__date">
                                        <div>Завершено</div>
                                        <div>{opts.limit_period_to}</div>
                                    </div>
                                );
                            default:
                                return "";
                        }
                    })()}
                </div>
            </div>
        );
    }

    render() {
        try {
            const { type, opts, urlParams } = this.props;

            const isAdmin = urlParams.params.vk_viewer_group_role === "admin";

            let showAvatar = type === "icons" || type === "tiles";

            return (
                <div ref={this.ref} className="Subscriptions-short">
                    {showAvatar && this.renderAvatar()}
                    <div
                        className={`Subscriptions-short__content ${
                            type !== "tiles"
                                ? "Subscriptions-short__content_row"
                                : ""
                        }`}
                    >
                        <div className="Subscriptions-short__content-main">
                            {type === "tiles" ? (
                                <div
                                    onClick={this.handleClick}
                                    className="Subscriptions-short__name_tile"
                                >
                                    <div className="Subscriptions-short__name-row">
                                        <Twemoji
                                            options={{
                                                className: "twemoji-item",
                                            }}
                                        >
                                            <span
                                                style={{ position: "relative" }}
                                            >
                                                {opts.name}

                                            </span>
                                        </Twemoji>
                                    </div>
                                </div>
                            ) : (
                                <div className="Subscriptions-short__name-row">
                                    <div
                                        onClick={this.handleClick}
                                        className="Subscriptions-short__name"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <Twemoji
                                            options={{
                                                className: "twemoji-item",
                                            }}
                                        >
                                            {opts.name}
                                        </Twemoji>
                                    </div>
                                </div>
                            )}
                            {type !== "tiles" && (
                                <div
                                    onClick={this.handleClick}
                                    className="Subscriptions-short__description-text"
                                >
                                    {opts.short_description
                                        ? TextToHtml(opts.short_description)
                                        : TextToHtml(
                                              cropDescription(opts.text, 100)
                                          )}
                                </div>
                            )}
                        </div>
                        <div className="Subscriptions-short__description">
                            {this.showDescription()}
                        </div>
                    </div>
                </div>
            );
        } catch (e) {
            this.logger.error(
                { code: 9042, message: e.message },
                "render_error",
                "SubscriptionShort.jsx"
            );
            Sentry.captureException(e);
        }
    }
}

export default SubscriptionShort;
