import React, {
    useState,
    useEffect,
    useReducer,
    useContext,
    Suspense,
    lazy,
} from "react";
import {
    Button,
    FormLayout,
    Input,
    Textarea,
    File,
    Spinner,
    Div,
    FormItem,
} from "@vkontakte/vkui";

import { BlockButtonData } from "../../../../../../includes/Structures";
import FileService from "../../../../../../includes/Services/FileService";

import { validator as ButtonValidator } from "../../ButtonCommon/validator";
import reducer, {
    initialState,
    SET_PRODUCT_NAME,
    SET_PRODUCT_TEXT,
    SET_PRODUCT_CATEGORY,
    SET_PRODUCT_IMG,
    SET_PRODUCT_IMG_ERROR,
    SET_PRODUCT_PRICE,
    SET_PRODUCT_OLD_PRICE,
    SET_BUTTON_DATA,
    SET_BUTTON_ERRORS,
    SET_IS_PENDING,
    SET_ERRORS,
} from "./reducer";
import validator from "./validator";
import ImagesHelper from "../../../../../../includes/Helpers/ImagesHelper";
import ButtonCommonForm from "../../ButtonCommon/ButtonCommonForm";
import { Context } from "../../../../../../context";
import container from "../../../../../../container";
import {
    IMAGE_SIZE_ERROR,
    IMAGE_UPLOAD_ERROR,
} from "../../../../../../includes/ErrorMessages";
import { Icon28Camera } from "@vkontakte/icons";
import * as Sentry from "@sentry/react";
import { UPLOAD_MAX_FILESIZE } from "../../../../../../includes/Constants";

const ImageCropper = lazy(() => import("../../../../../Controls/ImageCropper"));

type ProductFormProps = {
    isEdit: boolean;
    name: string;
    text: string;
    category: string;
    price: string;
    price_old: string;
    imgUrl: string;
    button: BlockButtonData;
    onSubmit: Function;
    vk_group_id: number;
    subscriptions: any[];
};

const logger = container.get("logger");

const ProductForm = (props: ProductFormProps) => {
    const {
        isEdit,
        name,
        text,
        category,
        price,
        price_old,
        imgUrl,
        button,
        onSubmit,
    } = props;

    const { inputDynamic } = useContext(Context);

    const [cropperImageDataUrl, setCropperImageDataUrl] = useState(null);

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        ...{
            name: name ? name : "",
            text: text ? text : "",
            price: price ? price : "",
            price_old: price_old ? price_old : "",
            category: category ? category : "",
            img: imgUrl ? imgUrl : "",
            button: { ...button },
        },
    });

    useEffect(() => {
        dispatch({ type: SET_PRODUCT_IMG, payload: imgUrl });
    }, [imgUrl]);

    const handleSubmit = () => {
        const validationResult = validator(state);

        if (false === validationResult.result) {
            dispatch({
                type: SET_ERRORS,
                payload: { ...validationResult.errors, button: {} },
            });
            return false;
        }

        const data = {
            name: state.name,
            text: state.text,
            category: state.category,
            price: state.price,
            price_old: state.price_old,
            img: {
                url: state.img,
            },
            button: { ...state.button },
        };

        const buttonValidationResult = ButtonValidator(data.button);

        if (buttonValidationResult.status === false) {
            dispatch({
                type: SET_BUTTON_ERRORS,
                payload: buttonValidationResult.errors,
            });
            return false;
        } else {
            dispatch({ type: SET_BUTTON_ERRORS, payload: {} });
        }

        onSubmit(data);
    };

    const renderImgIcon = () => {
        if (state.img) {
            return (
                <div
                    className="ImageUpload"
                    style={{
                        background: `url(${state.img}) center center / cover no-repeat`,
                    }}
                ></div>
            );
        }

        return (
            <div className="ImageUpload">
                <Icon28Camera fill={"var(--vkui--color_accent_gray)"} />
            </div>
        );
    };

    const resetImage = () => {
        setCropperImageDataUrl(null);
        const fileInput = document.querySelector(".File__input") as any;
        if (fileInput) {
            fileInput.value = null;
        }
    };

    const renderImgLoader = () => {
        return (
            <div className="ImageUpload">
                <Spinner size="small" />
            </div>
        );
    };

    const handleImageCropSubmit = async (data) => {
        try {
            resetImage();
            dispatch({ type: SET_IS_PENDING, payload: true });

            if (data.file.size > UPLOAD_MAX_FILESIZE) {
                dispatch({
                    type: SET_PRODUCT_IMG_ERROR,
                    payload: IMAGE_SIZE_ERROR,
                });
                dispatch({ type: SET_IS_PENDING, payload: false });
                return;
            }

            let response = await FileService.uploadImage({
                image: data.file,
                size: `${data.size_x}x${data.size_y}`,
            });

            dispatch({ type: SET_IS_PENDING, payload: false });

            if (response.result === "success") {
                dispatch({
                    type: SET_PRODUCT_IMG,
                    payload: response.data.filename,
                });
                dispatch({ type: SET_PRODUCT_IMG_ERROR, payload: "" });
            } else {
                let errorMsg = response.message ? response.message : IMAGE_UPLOAD_ERROR

                if (response.errors && response.errors.image) {
                    errorMsg = response.errors.image[0]
                }

                logger.error(
                    {
                        code: 9103,
                        message: errorMsg,
                    },
                    "images_error",
                    "ProductForm.tsx"
                );
                console.log(errorMsg);

                dispatch({
                    type: SET_PRODUCT_IMG_ERROR,
                    payload: errorMsg,
                });
            }
        } catch (error) {
            logger.error(
                {
                    code: 9104,
                    message: error.message,
                },
                "images_error",
                "ProductForm.tsx"
            );
            console.log(error);
            Sentry.captureException(error);

            dispatch({ type: SET_IS_PENDING, payload: false });
        }
    };

    const handleButtonCommonFormChange = (data) => {
        dispatch({ type: SET_BUTTON_DATA, payload: data });
    };
    /**
     *
     * @param e Обработчик изменения поля для выбора файла
     */
    const handleFileInputChange = async (e) => {
        if (!e.target.files[0]) {
            return false;
        }

        const file = e.target.files[0];
        // Считаем как base64 строку
        let imageDataUrl = await ImagesHelper.readImageFile(file);
        // Сохраняем данные для передачи в ImageCropper
        setCropperImageDataUrl(imageDataUrl);
    };

    return (
        <>
            <FormLayout
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                className="ProductForm__wrapper"
            >
                <Div
                    style={{
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}
                >
                    <FormItem
                        top="Изображение"
                        status={state.errors.img_error ? "error" : "default"}
                        bottom={
                            state.errors.img_error ? state.errors.img_error : ""
                        }
                    >
                        {state.isPending ? (
                            renderImgLoader()
                        ) : (
                            <File
                                className="FileInput"
                                style={{ padding: "0px", minWidth: 65 }}
                                onChange={handleFileInputChange}
                                before={renderImgIcon()}
                                accept=".png, .jpg, .jpeg"
                                mode="tertiary"
                            ></File>
                        )}
                    </FormItem>
                </Div>

                <FormItem
                    top="Название"
                    status={state.errors.name ? "error" : "default"}
                    bottom={state.errors.name ? state.errors.name : ""}
                >
                    <Input
                        name="name"
                        type="text"
                        value={state.name}
                        onChange={(e) => {
                            dispatch({
                                type: SET_PRODUCT_NAME,
                                payload: e.target.value,
                            });
                        }}
                        placeholder="Введите название"
                    />
                </FormItem>

                <FormItem top="Категория" status="default">
                    <Input
                        name="category"
                        type="text"
                        value={state.category}
                        onChange={(e) => {
                            dispatch({
                                type: SET_PRODUCT_CATEGORY,
                                payload: e.target.value,
                            });
                        }}
                        placeholder="Например, телефоны"
                    />
                </FormItem>

                <FormItem top="Цена" status="default">
                    <Input
                        name="price"
                        type="text"
                        value={state.price}
                        onChange={(e) => {
                            dispatch({
                                type: SET_PRODUCT_PRICE,
                                payload: e.target.value,
                            });
                        }}
                        placeholder="10000 ₽"
                    />
                </FormItem>

                <FormItem top="Старая цена" status="default">
                    <Input
                        name="price_old"
                        type="text"
                        value={state.price_old}
                        onChange={(e) => {
                            dispatch({
                                type: SET_PRODUCT_OLD_PRICE,
                                payload: e.target.value,
                            });
                        }}
                        placeholder="12000 ₽"
                    />
                </FormItem>

                <FormItem
                    top="Описание товара"
                    status="default"
                    style={{ paddingBottom: 6 }}
                >
                    <Textarea
                        name="text"
                        value={state.text}
                        onChange={(e) => {
                            dispatch({
                                type: SET_PRODUCT_TEXT,
                                payload: e.target.value,
                            });
                        }}
                        placeholder="Введите описание"
                    />
                </FormItem>

                <ButtonCommonForm
                    currentDynamicInput={inputDynamic.getCurrent()}
                    onChange={handleButtonCommonFormChange}
                    data={state.button}
                    errors={state.errors.button}
                    isInModal
                />

                <Div style={{ paddingTop: 0 }}>
                    <Button type="submit" size="l" stretched>
                        {isEdit ? "Сохранить" : "Добавить"}
                    </Button>
                </Div>
            </FormLayout>
            {
                <Suspense fallback={<Spinner size="small" />}>
                    <ImageCropper
                        imageInputData={cropperImageDataUrl}
                        onClose={resetImage}
                        onSubmit={handleImageCropSubmit}
                    />
                </Suspense>
            }
        </>
    );
};

export default ProductForm;
