import React, { useContext, useReducer } from "react";
import {
    FormLayout,
    Div,
    FixedLayout,
    Separator,
    Checkbox,
} from "@vkontakte/vkui";
import initialState from "./state";
import reducer from "./reducer";
import { validator as buttonValidator } from "../ButtonCommon/validator";
import "../../../../Elements/LeadButtonNotify";
import FormSubmitButton from "../FormSubmitButton";
import ReactSlider from "react-slider";
import { DEFAULT_OVERLAY_OPACITY_VALUE } from "../../../../../includes/Constants";
import TimerControl from "../Timer/TimerControl";
import { Icon20ArticleBoxOutline, Icon24LinkCircle, Icon48Camera } from "@vkontakte/icons";
import { Context } from "../../../../../context";

type CoverEditProps = {
    data: any;
    currentDynamicInput: string;
    onSubmit: Function;
    openModal: Function;
};

const SLIDER_STEP = 1;
const SLIDER_MIN_VALUE = 0;
const SLIDER_MAX_VALUE = 100;

const CoverEdit = (props: CoverEditProps) => {
    const { data, currentDynamicInput, onSubmit, openModal } = props;

    const { snackbar, groupSiteData, } = useContext(Context);

    const [state, dispatch] = useReducer(reducer, { ...initialState, ...data });

    const { has_button, has_title } = state;

    const handleSubmit = () => {
        if (has_button) {
            if (!state.button.text) {
                snackbar.showError("Кнопка заполнена неверно");
                return;
            }

            if (state.button.action === "lead" && !state.button.lead_admin) {
                const admins = groupSiteData.getAdmins();

                const default_lead_admin_id =
                    admins.length > 0
                        ? data.lead_admin
                            ? data.lead_admin
                            : admins[0].id
                        : 0;

                state.button = {
                    ...state.button,
                    lead_admin: default_lead_admin_id,
                };
            }

            const buttonValidationResult = buttonValidator(state.button);

            if (buttonValidationResult.status === false) {
                dispatch({
                    type: "set-button-errors",
                    payload: buttonValidationResult.errors,
                });

                return false;
            } else {
                dispatch({ type: "flush-button-errors" });
            }
        }

        const blockData = { ...state };

        delete blockData.errors;

        onSubmit(blockData);
    };

    const hasBackround = !!state.background && !!state.background.url;

    const overlayOpacity =
        state.background.overlay_opacity ||
        state.background.overlay_opacity === 0
            ? state.background.overlay_opacity
            : DEFAULT_OVERLAY_OPACITY_VALUE;

    const isFullscreen = state.meta.is_fullscreen
        ? state.meta.is_fullscreen
        : false;

    return (
        <FormLayout style={{ paddingBottom: 65 }}>
            <TimerControl
                checked={has_title}
                title="Заголовок и описание"
                icon={
                    <Icon20ArticleBoxOutline
                        width={24}
                        height={24}
                        fill="var(--vkui--color_icon_accent_themed)"
                        style={{
                            marginRight: 19,
                            opacity: has_title ? 1 : 0.4,
                        }}
                    />
                }
                onChange={(e) => {
                    dispatch({
                        type: "set-has-title",
                        payload: !has_title,
                    });
                }}
                onEditClick={() => {
                    openModal({
                        id: "text",
                        args: {
                            content: state.content,
                            currentDynamicInput: currentDynamicInput,
                            callback: (data) => {
                                dispatch({
                                    type: "edit-text",
                                    payload: data,
                                });
                            },
                        },
                    });
                }}
            />

            <TimerControl
                checked={has_button}
                title="Кнопка"
                icon={
                    <Icon24LinkCircle
                        fill="var(--vkui--color_icon_accent_themed)"
                        style={{
                            marginRight: 19,
                            opacity: has_button ? 1 : 0.4,
                        }}
                    />
                }
                onChange={(e) => {
                    dispatch({
                        type: "set-has-button",
                        payload: !has_button,
                    });
                }}
                onEditClick={() => {
                    openModal({
                        id: "button",
                        args: {
                            currentDynamicInput: currentDynamicInput,
                            has_button: state.has_button,
                            button: state.button,
                            errors: state.errors,
                            callback: (data) => {
                                dispatch({
                                    type: "set-button-data",
                                    payload: data,
                                });
                            },
                            setButtonErrorsCallback: (data) => {
                                dispatch({
                                    type: "set-button-errors",
                                    payload: data,
                                });
                            },
                            flushButtonErrorsCallback: () => {
                                dispatch({
                                    type: "flush-button-errors",
                                });
                            },
                        },
                    });
                }}
            />

            <Separator />

            <Div style={{ paddingBottom: 0 }}>
                <div className="subhead">Изображение</div>
                <div
                    className="GroupBannersForm__banner GroupBannersForm__banner_filepicker B-Cover_edit"
                    onClick={() => {
                        openModal({
                            id: "landing-img",
                            args: {
                                size: [1920, 1220],
                                callback: (data) => {
                                    dispatch({
                                        type: "background",
                                        payload: data,
                                    });
                                },
                            },
                        });
                    }}
                >
                    {hasBackround === false && (
                        <Icon48Camera
                            width={32}
                            height={32}
                            fill="var(--vkui--color_write_bar_icon)"
                        />
                    )}
                    {hasBackround && (
                        <>
                            <img src={state.background.url} alt="" />{" "}
                            <div
                                style={{
                                    background: `rgba(0, 0, 0, ${overlayOpacity}%)`,
                                }}
                                className="GroupBannersForm__overlay"
                            ></div>
                        </>
                    )}
                </div>
                <div className="subhead">
                    Рекомендуемый размер изображения 1920x1220
                </div>

                <div style={{ marginTop: 20 }}>
                    <div className="subhead">{overlayOpacity}% заливка</div>
                    <ReactSlider
                        step={SLIDER_STEP}
                        min={SLIDER_MIN_VALUE}
                        max={SLIDER_MAX_VALUE}
                        value={overlayOpacity}
                        className="ReactSlider"
                        trackClassName="ReactSlider__sliderTrack_line"
                        minDistance={10}
                        onChange={(value: number) => {
                            dispatch({
                                type: "set-overlay-opacity",
                                payload: value,
                            });
                        }}
                        renderThumb={(props) => (
                            <div
                                {...props}
                                className="ReactSlider__sliderThumb"
                            ></div>
                        )}
                    />
                </div>
            </Div>

            <Checkbox
                className="CoverEdit__checkbox"
                checked={isFullscreen}
                onChange={(e) => {
                    dispatch({
                        type: "set-is-fullscreen",
                        payload: e.currentTarget.checked,
                    });
                }}
            >
                На всю высоту экрана <span className="subhead">(для мобильных устройств)</span>
            </Checkbox>

            <FixedLayout filled={true} vertical="bottom">
                <FormSubmitButton handleSubmit={handleSubmit} />
            </FixedLayout>
        </FormLayout>
    );
};

export default CoverEdit;
