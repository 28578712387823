import React from "react";
import { Icon16Cancel } from "@vkontakte/icons";

const DetachedBadges = (props) => {
    const { items, name, getText, onChange } = props;

    return (
        <>
            {items.length > 0 && (
                <div className="multiple-select-badges">
                    {items.map((item, index) => {
                        return (
                            <span
                                className="multiple-select-badge"
                                key={`${name}-${index}`}
                            >
                                {getText && getText(item)}
                                <i
                                    onClick={() => {
                                        const newItems = [...items].filter(
                                            (i) => i.id !== item.id
                                        );
                                        onChange && onChange(newItems);
                                    }}
                                >
                                    <Icon16Cancel
                                        className="c-ptr"
                                        fill="var(--vkui--color_accent_gray)"
                                    />
                                </i>
                            </span>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default DetachedBadges;
