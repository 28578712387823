import React, {
    lazy,
    ReactElement,
    Suspense,
    useContext,
    useRef,
    useState,
} from "react";
import {
    Button,
    FormLayout,
    Separator,
    Avatar,
    Spinner,
    Div,
    FormItem,
} from "@vkontakte/vkui";
import FileService from "../../includes/Services/FileService";
import ImagesHelper from "../../includes/Helpers/ImagesHelper";
import AdvantagesEditPhotoPopout from "../Elements/Popouts/AdvantagesEditPhotoPopout";
import { Context } from "../../context";
import ColorPicker from "../Controls/ColorPicker";
import { isHex } from "../../includes/Helpers/Helpers";
import ReactSlider from "react-slider";
import InputDynamic from "../Controls/InputDynamic";
import container from "../../container";
import {
    IMAGE_SIZE_ERROR,
    IMAGE_UPLOAD_ERROR,
} from "../../includes/ErrorMessages";
import { Icon28Camera } from "@vkontakte/icons";
import * as Sentry from "@sentry/react";
import { UPLOAD_MAX_FILESIZE } from "../../includes/Constants";

const ImageCropper = lazy(() => import("../Controls/ImageCropper"));

type ModalAdvantageProps = {
    title: string;
    text: string;
    imgUrl: string;
    isEdit: boolean;
    svgIcons: { id: number; icon_name: string; icon: ReactElement }[];
    svgIconId: number | null;
    svgColor: string;
    svgBgColor: string;
    itemRadius: number;
    currentDynamicInput: string;
    callback: Function;
    close: Function;
};

const DEFAULT_SVG_ICON_COLORS = [
    { value: "var(--vkui--color_text_accent)", isNewColor: false },
    { value: "var(--vkui--color_accent_gray)", isNewColor: false },
    { value: "var(--vkui--color_accent_green)", isNewColor: false },
    { value: "var(--vkui--color_accent_orange)", isNewColor: false },
    { value: "var(--vkui--color_accent_purple)", isNewColor: false },
    { value: "var(--vkui--color_accent_red)", isNewColor: false },
    { value: "var(--vkui--color_accent_violet)", isNewColor: false },
];

const DEFAULT_SVG_ICON_BG_COLORS = [
    { value: "var(--vkui--color_image_placeholder)", isNewColor: false },
    { value: "#fff", isNewColor: false },
];

const SLIDER_STEP = 1;
const SLIDER_MIN_VALUE = 0;
const SLIDER_MAX_VALUE = 100;

const logger = container.get("logger");

const ModalAdvantage = (props: ModalAdvantageProps) => {
    const {
        title = "",
        text = "",
        imgUrl = "",
        isEdit = false,
        callback = () => {},
        close = () => {},
        svgIcons = [],
        svgColor = "",
        svgBgColor = "",
        svgIconId = null,
        itemRadius = 100,
        currentDynamicInput,
    } = props;

    const { popout, urlParams } = useContext(Context);

    const [advantageTitle, setAdvantageTitle] = useState(title ? title : "");
    const [advantageTitleHasError, setAdvantageTitleHasError] = useState(false);

    const [advantageText, setAdvantageText] = useState(text ? text : "");
    const [advantageTextHasError, setAdvantageTextHasError] = useState(false);

    const [cropperImageData, setCropperImageData] = useState(null);

    const [img, setImg] = useState(imgUrl ? imgUrl : "");
    const [imgHelpText, setImgHelpText] = useState("");

    const [svgIconObj, setSvgIconObj] = useState(() => {
        if (svgIconId && !img) {
            return svgIcons.find((icon) => icon.id === svgIconId);
        } else {
            return null;
        }
    });

    const [svgIconColor, setSvgIconColor] = useState(svgColor);
    const [svgIconPickerColor, setSvgIconPickerColor] = useState(
        isHex(svgIconColor) ? svgIconColor : "#3f8ae0"
    );

    const [svgIconBgColor, setSvgIconBgColor] = useState(svgBgColor);
    const [svgIconPickerBgColor, setSvgIconPickerBgColor] = useState(
        isHex(svgIconBgColor) ? svgIconBgColor : "#001c3d14"
    );

    const [isPending, setIsPending] = useState(false);

    const [borderRadiusValue, setBorderRadiusValue] = useState(itemRadius);

    const popoutRef = useRef();

    const changeSliderValue = (values: number): void => {
        setBorderRadiusValue(values);
    };

    const saveModal = () => {
        const data: any = {};

        const errors = [];

        if (advantageTitle.trim() === "") {
            errors.push(1);
            setAdvantageTitleHasError(true);
        }

        if (advantageText.trim() === "") {
            errors.push(1);
            setAdvantageTextHasError(true);
        }

        if (errors.length > 0) return false;

        data.title = advantageTitle;
        data.text = advantageText;
        data.item_radius = borderRadiusValue;

        if (img && !svgIconObj) {
            data.img = {
                url: img,
            };
        }

        if (svgIconObj) {
            data.svg_icon_id = svgIconObj.id;
            data.svg_icon_color = svgIconColor;
            data.svg_icon_bg_color = svgIconBgColor;
        }

        callback(data);
        close();
    };

    /**
     *
     * @param e Обработчик input type=file
     */
    const getImg = async (e) => {
        if (!e.target.files[0]) {
            return false;
        }

        // Считаем как base64 строку
        let imageDataUrl = await ImagesHelper.readImageFile(e.target.files[0]);
        // Сохраняем данные для передачи в ImageCropper
        setCropperImageData(imageDataUrl);
    };

    /**
     * Закрытие кропера изображений
     */
    const resetImage = () => {
        setCropperImageData(null);
        const fileInput = document.querySelector(".vkuiFile__input") as any;
        if (fileInput) {
            fileInput.value = null;
        }
    };

    const handleCropperClose = () => {
        resetImage();
    };

    const handleCropperSubmit = (data) => {
        resetImage();
        uploadImage(data.file);
    };

    /**
     * Загрузка файла на сервер
     * @param file File
     */
    const uploadImage = async (file) => {
        try {
            setIsPending(true);

            if (file.size > UPLOAD_MAX_FILESIZE) {
                setImgHelpText(IMAGE_SIZE_ERROR);
                setIsPending(false);

                popout.close();

                return;
            }

            let response = await FileService.uploadImage({
                image: file,
                size: "100x100",
            });

            if (response.result === "error") {
                let errorMsg = response.message ? response.message : IMAGE_UPLOAD_ERROR

                if (response.errors && response.errors.image) {
                    errorMsg = response.errors.image[0]
                }

                setImgHelpText(errorMsg);
                setIsPending(false);

                popout.close();

                logger.error(
                    {
                        code: 9103,
                        message: errorMsg,
                    },
                    "images_error",
                    "ModalAdvantage.tsx"
                );
                console.log(errorMsg);

                return false;
            }

            setIsPending(false);
            setImgHelpText("");
            setImg(response.data.filename); // Возьмем последнее в списке изображение - с наибольшим разрешением
            setSvgIconObj(null);
            popout.close();
        } catch (error) {
            logger.error(
                {
                    code: 9104,
                    message: error.message,
                },
                "images_error",
                "ModalAdvantage.tsx"
            );
            console.log(error);
            Sentry.captureException(error);

            popout.close();

            setImgHelpText(IMAGE_UPLOAD_ERROR);
            setIsPending(false);
        }
    };

    const imgLoad = () => {
        if (svgIconObj) {
            return (
                <div
                    className="ModalAdvantage__imgOrIcon"
                    style={{
                        borderRadius: `${borderRadiusValue / 2}%`,
                    }}
                >
                    <Avatar
                        style={{
                            background: svgIconBgColor,
                        }}
                    >
                        <div style={{ color: svgIconColor }}>
                            {svgIconObj.icon}
                        </div>
                    </Avatar>
                </div>
            );
        }

        if (!img) {
            return (
                <div
                    className="ModalAdvantage__imgOrIcon"
                    style={{
                        borderRadius: `${borderRadiusValue / 2}%`,
                    }}
                >
                    <Avatar>
                        <Icon28Camera />
                    </Avatar>
                </div>
            );
        }
        return (
            <div
                className="ModalAdvantage__imgOrIcon"
                style={{
                    borderRadius: `${borderRadiusValue / 2}%`,
                }}
            >
                <Avatar src={img} />
            </div>
        );
    };

    const openEditPhotoPopout = (popoutRef: React.RefObject<Element>) => {
        popout.open(
            <AdvantagesEditPhotoPopout
                getImg={getImg}
                svgIcons={svgIcons}
                imgHelpText={imgHelpText}
                setSvgIconObj={setSvgIconObj}
                setImgHelpText={setImgHelpText}
                popoutRef={popoutRef}
            />
        );
    };

    return (
        <div className="ModalAdvantage">
            <Separator style={{ opacity: ".12" }} />

            <div
                style={{
                    overflowY: "auto",
                }}
            >
                <FormLayout>
                    <FormItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <InputDynamic
                            type="text"
                            name="title"
                            top="Заголовок"
                            useVariables={false}
                            variablesMode="pages"
                            value={advantageTitle}
                            placeholder="Введите заголовок"
                            currentOpened={currentDynamicInput}
                            status={
                                advantageTitleHasError ? "error" : "default"
                            }
                            bottomText={
                                advantageTitleHasError
                                    ? "Введите заголовок"
                                    : ""
                            }
                            onChange={(value) => {
                                setAdvantageTitle(value);
                                setAdvantageTitleHasError(false);
                            }}
                        />
                    </FormItem>

                    <FormItem style={{ paddingTop: 0 }}>
                        <InputDynamic
                            type="text"
                            name="text"
                            top="Текст"
                            textarea={true}
                            useVariables={false}
                            variablesMode="pages"
                            value={advantageText}
                            placeholder="Введите текст"
                            currentOpened={currentDynamicInput}
                            status={advantageTextHasError ? "error" : "default"}
                            bottomText={
                                advantageTextHasError ? "Введите текст" : ""
                            }
                            onChange={(value) => {
                                setAdvantageText(value);
                                setAdvantageTextHasError(false);
                            }}
                        />
                    </FormItem>

                    {isPending ? (
                        <Spinner size="medium" />
                    ) : (
                        <div
                            className="B-Advantages__edit-photo-wrapper"
                            onClick={() => {
                                openEditPhotoPopout(popoutRef);
                            }}
                            ref={popoutRef}
                        >
                            {imgLoad()}
                            <button className="B-Advantages__edit-photo-btn">
                                {isEdit ? "Изменить" : "Добавить"} фото
                            </button>
                        </div>
                    )}

                    {svgIconObj ? (
                        <>
                            <div
                                style={{
                                    paddingBottom: 24,
                                }}
                            >
                                <Div
                                    style={{
                                        marginBottom: 8,
                                        paddingBottom: 0,
                                    }}
                                    className="subhead"
                                >
                                    Цвет иконки
                                </Div>
                                <Div
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                    <ColorPicker
                                        value={svgIconColor}
                                        defaultNewColorValue="#3f8ae0"
                                        pickerColor={svgIconPickerColor}
                                        setPickerColor={setSvgIconPickerColor}
                                        defaultColors={DEFAULT_SVG_ICON_COLORS}
                                        onSelect={(color) => {
                                            setSvgIconColor(color.value);
                                        }}
                                        inputName="icon-color"
                                    />
                                </Div>
                            </div>
                            <div style={{ marginBottom: 12 }}>
                                <Div
                                    style={{
                                        marginBottom: 8,
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                    }}
                                    className="subhead"
                                >
                                    Цвет фона
                                </Div>
                                <Div
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                    <ColorPicker
                                        value={svgIconBgColor}
                                        defaultNewColorValue="#001c3d14"
                                        pickerColor={svgIconPickerBgColor}
                                        setPickerColor={setSvgIconPickerBgColor}
                                        defaultColors={
                                            DEFAULT_SVG_ICON_BG_COLORS
                                        }
                                        onSelect={(color) => {
                                            setSvgIconBgColor(color.value);
                                        }}
                                        inputName="bg-icon-color"
                                    />
                                </Div>
                            </div>
                        </>
                    ) : null}

                    <div className="ModalAdvantage__sliderWrapper">
                        <Div
                            style={{
                                paddingTop: 0,
                            }}
                            className="subhead"
                        >
                            Радиус закругления {borderRadiusValue}%
                        </Div>
                        <Div
                            style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                        >
                            <ReactSlider
                                step={SLIDER_STEP}
                                min={SLIDER_MIN_VALUE}
                                max={SLIDER_MAX_VALUE}
                                value={borderRadiusValue}
                                className="ReactSlider"
                                trackClassName="ReactSlider__sliderTrack_line"
                                onChange={changeSliderValue}
                                minDistance={10}
                                renderThumb={(props) => (
                                    <div
                                        {...props}
                                        className="ReactSlider__sliderThumb"
                                    ></div>
                                )}
                            />
                        </Div>
                    </div>

                    {imgHelpText && (
                        <Div
                            style={{
                                marginTop: -10,
                            }}
                        >
                            <div style={{ fontSize: 14 }} className="has-error">
                                {imgHelpText}
                            </div>
                        </Div>
                    )}

                    <Div>
                        <Button size="l" stretched onClick={saveModal}>
                            {isEdit ? "Сохранить" : "Добавить"}
                        </Button>
                    </Div>
                </FormLayout>
            </div>

            <Suspense fallback={<Spinner size="small" />}>
                <ImageCropper
                    imageInputData={cropperImageData}
                    onClose={handleCropperClose}
                    aspectRatio={1} // 1 к 1 - квадрат
                    onSubmit={handleCropperSubmit}
                />
            </Suspense>
        </div>
    );
};

export default ModalAdvantage;
