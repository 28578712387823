import React, { useReducer } from "react";
import { FormLayout, FixedLayout } from "@vkontakte/vkui";
import initialState from "./state";
import reducer from "./reducer";
import validator from "../ButtonCommon/validator";
import FormSubmitButton from "../FormSubmitButton";
import ButtonCommonForm from "../ButtonCommon/ButtonCommonForm";

type ButtonEditProps = {
    data: any;
    currentDynamicInput: string;
    onSubmit: Function;
};

const ButtonEdit = (props: ButtonEditProps) => {
    const { data, currentDynamicInput, onSubmit } = props;

    const [state, dispatch] = useReducer(reducer, { ...initialState, ...data });

    const handleButtonCommonFormChange = (data) => {
        dispatch({ type: "set-data", payload: data });
    };

    const handleSubmit = () => {
        const validationResult = validator(state.button);
        if (validationResult.status === false) {
            dispatch({ type: "set-errors", payload: validationResult.errors });
            return false;
        } else {
            dispatch({ type: "set-errors", payload: {} });
        }

        onSubmit(state);
    };

    return (
        <FormLayout style={{ paddingBottom: 65 }}>
            <ButtonCommonForm
                errors={state.errors}
                data={state.button}
                onChange={handleButtonCommonFormChange}
                currentDynamicInput={currentDynamicInput}
                isInModal={false}
            />

            <FixedLayout vertical="bottom" filled>
                <FormSubmitButton handleSubmit={handleSubmit} />
            </FixedLayout>
        </FormLayout>
    );
};

export default ButtonEdit;
