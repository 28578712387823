function reducer(state, action) {
    switch (action.type) {
        case "title":
            return {
                ...state,
                title: action.payload[0],
                title_url: action.payload[1],
            };
        case "title_url":
            return {
                ...state,
                more: action.payload[0],
                more_url: action.payload[1],
            };
        case "more":
            return {
                ...state,
                more: action.payload[0],
                more_url: action.payload[1],
            };
        case "text":
            return { ...state, text: action.payload };
        case "descr":
            return { ...state, descr: action.payload };
        case "item":
            return {
                ...state,
                text: action.payload.title,
                descr: action.payload.descr,
                url: action.payload.url,
            };
        case "update":
            return {
                title: action.payload.title,
                title_url: action.payload.title_url,
                more: action.payload.more,
                more_url: action.payload.more_url,
                text: action.payload.text,
                descr: action.payload.descr,
            };
        default:
            throw new Error();
    }
}
export default reducer;
