export const SET_GROUP_DATA = "set-group-data";
export const SET_GROUP_DATA_CATALOG_VIEW = "set-group-data-catalog-view";
export const SET_GROUP_DATA_APP_TEXT = "set-group-data-app-text";
export const GROUP_DATA_DELETE_DESKTOP_BANNER =
    "group-data-delete-desktop-banner";
export const GROUP_DATA_DELETE_MOBILE_BANNER =
    "group-data-delete-mobile-banner";
export const GROUP_DATA_UPLOAD_BANNER = "group-data-upload-banner";

export const initialState = {
    default_subscription_id: null,
    catalog_type: "list",
    raw_video_key: "",
    raw_mobile_video_key: "",
};

/**
 * Костыль, который желательно и можно устранить. Но это не точно
 */
export const createState = (initialData) => {
    const state = {
        ...initialState,
        ...initialData,
    };

    if (state.banner_type === "img" && state.banner_url) {
        state.raw_video_key = state.banner_url;
    }

    if (state.mobile_banner_type === "img" && state.mobile_banner_url) {
        state.raw_mobile_video_key = state.mobile_banner_url;
    }

    if (state.banner_type === "youtube" && state.video_key) {
        state.raw_video_key = `https://www.youtube.com/watch?v=${state.video_key}`;
    }

    if (state.mobile_banner_type === "youtube" && state.mobile_video_key) {
        state.raw_mobile_video_key = `https://www.youtube.com/watch?v=${state.mobile_video_key}`;
    }

    if (state.banner_type === "vimeo" && state.video_key) {
        state.raw_video_key = `https://vimeo.com/${state.video_key}`;
    }

    if (state.mobile_banner_type === "vimeo" && state.mobile_video_key) {
        state.raw_mobile_video_key = `https://vimeo.com/${state.mobile_video_key}`;
    }

    return state;
};

export default (state, action) => {
    const { type, payload } = action;
    const newState = { ...state };

    switch (type) {
        case SET_GROUP_DATA:
            return { ...state, ...payload };

        case SET_GROUP_DATA_CATALOG_VIEW:
            newState.catalog_type = payload.catalogType;
            newState.default_subscription_id = payload.defaultSubscriptionId;
            return newState;

        case SET_GROUP_DATA_APP_TEXT:
            newState.app_text = payload;
            return newState;

        case GROUP_DATA_DELETE_DESKTOP_BANNER:
            newState.banner_url = "";
            newState.banner_type = "img";
            newState.video_key = "";
            newState.video_type = "0";
            newState.raw_video_key = "";
            return newState;

        case GROUP_DATA_DELETE_MOBILE_BANNER:
            newState.mobile_banner_url = "";
            newState.mobile_banner_type = "img";
            newState.mobile_video_key = "";
            newState.mobile_video_type = "0";
            newState.raw_mobile_video_key = "";
            return newState;

        case GROUP_DATA_UPLOAD_BANNER:
            if (payload.type === "desktop") {
                return {
                    ...newState,
                    banner_type: payload.data.banner_type,
                    banner_url: payload.data.banner_url,
                    video_key: payload.data.video_key,
                    video_type: payload.data.video_type,
                    raw_video_key: payload.data.raw_url,
                };
            } else {
                return {
                    ...newState,
                    mobile_banner_type: payload.data.banner_type,
                    mobile_banner_url: payload.data.banner_url,
                    mobile_video_key: payload.data.video_key,
                    mobile_video_type: payload.data.video_type,
                    raw_mobile_video_key: payload.data.raw_url,
                };
            }

        default:
            return state;
    }
};
