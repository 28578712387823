import React from "react";
import { Button, Div } from "@vkontakte/vkui";

type FormSubmitButtonProps = {
    handleSubmit: (e) => void;
};

const FormSubmitButton = (props: FormSubmitButtonProps) => {
    const { handleSubmit } = props;

    return (
        <>
            <Div>
                <Button
                    style={{
                        marginLeft: 0,
                        marginRight: 0,
                        width: "100%",
                    }}
                    onClick={handleSubmit}
                    stretched={true}
                    size="l"
                >
                    Сохранить
                </Button>
            </Div>
        </>
    );
};

export default FormSubmitButton;
