import React from "react";
import { PageStatisticSummary } from "../../includes/Structures";

type PageStatisticTableProps = {
    data: PageStatisticSummary;
    lineChartRef: any;
};

const PageStatisticTable = (props: PageStatisticTableProps) => {
    const { data, lineChartRef } = props;

    const onHoverTableParam = (tableParamIndex = null) => {
        const chart = lineChartRef.current;

        const datasets = chart.data.datasets;

        for (let i = 0; i < datasets.length; i++) {
            const dataset = datasets[i];

            if (tableParamIndex !== null) {
                if (i === tableParamIndex) {
                    dataset.borderColor = dataset.borderColor.replace(
                        /, 0.2\)$/,
                        ", 1)"
                    );
                    continue;
                }

                dataset.borderColor = dataset.borderColor.replace(
                    /, 1\)$/,
                    ", 0.2)"
                );
            } else {
                dataset.borderColor = dataset.borderColor.replace(
                    /, 0.2\)$/,
                    ", 1)"
                );
            }
        }

        chart.update();
    };

    return (
        <table className="PageStatisticTable">
            <thead>
            </thead>
            <tbody
                onMouseLeave={() => {
                    onHoverTableParam();
                }}
            >
                <tr
                    onMouseEnter={() => {
                        onHoverTableParam(0);
                    }}
                >
                    <td>
                        <div className="PageStatisticTable__param">
                            <span
                                style={{ background: "rgba(253, 127, 111, 1)" }}
                                className="PageStatisticTable__param-color"
                            ></span>
                            <span>Просмотры</span>
                        </div>
                    </td>
                    <td>{data.hits}</td>
                </tr>
                <tr
                    onMouseEnter={() => {
                        onHoverTableParam(1);
                    }}
                >
                    <td>
                        <div className="PageStatisticTable__param">
                            <span
                                style={{ background: "rgba(126, 176, 213, 1)" }}
                                className="PageStatisticTable__param-color"
                            ></span>
                            <span>Уникальные пользователи</span>
                        </div>
                    </td>
                    <td>{data.unique_user_count}</td>
                </tr>
                <tr>
                    <td>
                        <span style={{ marginLeft: 20 }}>Целевые действия</span>
                    </td>
                    <td>{data.goals}</td>
                </tr>
                <tr
                    onMouseEnter={() => {
                        onHoverTableParam(2);
                    }}
                >
                    <td>
                        <div className="PageStatisticTable__param">
                            <span
                                style={{ background: "rgba(178, 224, 97, 1)" }}
                                className="PageStatisticTable__param-color"
                            ></span>
                            <span>Подписки на рассылки</span>
                        </div>
                    </td>
                    <td>{data.subscription}</td>
                </tr>
                <tr
                    onMouseEnter={() => {
                        onHoverTableParam(3);
                    }}
                >
                    <td>
                        <div className="PageStatisticTable__param">
                            <span
                                style={{ background: "rgba(189, 126, 190, 1)" }}
                                className="PageStatisticTable__param-color"
                            ></span>
                            <span>Добавить в бота</span>
                        </div>
                    </td>
                    <td>{data.bot_add}</td>
                </tr>
                <tr
                    onMouseEnter={() => {
                        onHoverTableParam(4);
                    }}
                >
                    <td>
                        <div className="PageStatisticTable__param">
                            <span
                                style={{ background: "rgba(255, 181, 90, 1)" }}
                                className="PageStatisticTable__param-color"
                            ></span>
                            <span>Заявки</span>
                        </div>
                    </td>
                    <td>{data.lead}</td>
                </tr>
                <tr
                    onMouseEnter={() => {
                        onHoverTableParam(5);
                    }}
                >
                    <td>
                        <div className="PageStatisticTable__param">
                            <span
                                style={{ background: "rgba(255, 238, 101, 1)" }}
                                className="PageStatisticTable__param-color"
                            ></span>
                            <span>Подписки на сообщество</span>
                        </div>
                    </td>
                    <td>{data.join_community}</td>
                </tr>
                <tr
                    onMouseEnter={() => {
                        onHoverTableParam(6);
                    }}
                >
                    <td>
                        <div className="PageStatisticTable__param">
                            <span
                                style={{ background: "rgba(39, 135, 245, 1)" }}
                                className="PageStatisticTable__param-color"
                            ></span>
                            <span>Переходы по ссылкам</span>
                        </div>
                    </td>
                    <td>{data.url}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default PageStatisticTable;
