import React, { useState, useEffect } from "react";
import { Link, Button } from "@vkontakte/vkui";
import { EMPTY_FUNC } from "../../includes/Constants";
import { Icon16Clear, Icon24Add, Icon24BrowserBack, Icon24BrowserForward, Icon28UserOutline, Icon32Gallery } from "@vkontakte/icons";

const icons = {
    gallery: <Icon32Gallery style={{ color: "var(--vkui--color_text_accent)" }} />,
    add: <Icon24Add style={{ color: "var(--vkui--color_icon_secondary)" }} />,
    person: (
        <Icon28UserOutline
            width={36}
            height={36}
            style={{ color: "var(--vkui--color_text_accent)" }}
        />
    ),
};

const ItemCarouselCard = (props) => {
    const { typeApi, edit, card } = props;

    const [errors, setErrors] = useState({
        img: false,
        title: false,
        button: false,
    });

    const nameBtn = props.button
        ? props.button.length > 10
            ? `${props.button.slice(0, 10)}...`
            : props.button
        : "+ Кнопка";

    useEffect(() => {
        if (props.hasError !== undefined) {
            setErrors(props.hasError);
        } else {
            setErrors({
                img: false,
                title: false,
                button: false,
            });
        }
    }, [props.hasError]);

    const height = typeApi === "carousel" ? 180 : 120;

    const stylesWithImage: any = {
        height: height,
        lineHeight: `${height}px`,
    };

    if (props.img !== "add" && props.img !== "") {
        stylesWithImage.backgroundImage = `url(${props.img})`;
    }

    const stylesWithoutImage = {
        height: height,
        lineHeight: `${height}px`,
    };

    let img = props.img;

    if (img) {
        if (icons[img]) img = icons[img];
    } else img = icons.gallery;

    let style = {};

    if (card && card.img === "person") {
        style = {
            height: height,
            lineHeight: `${height}px`,
        };
    } else if (typeof props.img === "string") {
        style = stylesWithImage;
    } else {
        style = stylesWithoutImage;
    }

    return (
        <>
            <div className="ItemCarouselCard">
                {props.edit && (
                    <div className="tools d-flex flex-space-between flex-align-center">
                        <Icon24BrowserBack
                            onClick={props.onLeftClick}
                            className="c-ptr"
                            width={16}
                            height={16}
                            style={{
                                color: props.canMoveLeft
                                    ? "var(--vkui--color_text_accent)"
                                    : "var(--vkui--color_icon_secondary)",
                            }}
                        />

                        {props.length > 3 && (
                            <Icon16Clear
                                onClick={props.onRemoveClick}
                                className="c-ptr"
                                style={{ color: "var(--vkui--color_accent_red)" }}
                            />
                        )}
                        <Icon24BrowserForward
                            onClick={props.onRightClick}
                            className="c-ptr"
                            width={16}
                            height={16}
                            style={{
                                color: props.canMoveRight
                                    ? "var(--vkui--color_text_accent)"
                                    : "var(--vkui--color_icon_secondary)",
                            }}
                        />
                    </div>
                )}
                <div
                    onClick={edit === true ? EMPTY_FUNC : props.onCoverClick}
                    className={
                        errors.img
                            ? "ItemCarouselCard-img c-ptr text-center bg-image ItemErrors"
                            : "ItemCarouselCard-img c-ptr text-center bg-image "
                    }
                    style={style}
                >
                    <div className="d-inline-block va-middle">
                        {typeof img === "string" ? "" : img}
                    </div>
                </div>
                {!props.imgOnly && (
                    <>
                        <Link
                            Component="a"
                            className="ItemCarouselCard-title ItemCarouselCard-text"
                            style={
                                errors.title || props.hasErrorUrl
                                    ? { color: "var(--vkui--color_accent_red)" }
                                    : null
                            }
                            onClick={
                                edit === true ? EMPTY_FUNC : props.onTitleClick
                            }
                        >
                            {props.title ? props.title : "+ Заголовок"}
                        </Link>
                        <div
                            className="descr ItemCarouselCard-descr ItemCarouselCard-text"
                            style={
                                props.hasErrorDescr
                                    ? { color: "var(--vkui--color_accent_red)" }
                                    : null
                            }
                        >
                            {props.description
                                ? props.description
                                : "+ Описание"}
                        </div>
                        <div className="d-flex ItemCarouselCard-button">
                            <Button
                                style={
                                    errors.button
                                        ? { color: "var(--vkui--color_accent_red)" }
                                        : null
                                }
                                onClick={
                                    edit === true
                                        ? EMPTY_FUNC
                                        : props.onButtonClick
                                }
                                mode="outline"
                                stretched
                            >
                                {nameBtn}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default ItemCarouselCard;
