import config from "../../includes/Config";
import Service from "./Service";

interface CopyCollectionData {
    target_vk_group_id: number;
    collection_id: number;
}

class ShareService extends Service {
    launchParams: any;

    constructor(launchParams: any) {
        super();

        this.launchParams = launchParams;
    }

    async create(widget_ids: string[]) {
        try {
            const requestData = this._createRequestData({
                widget_ids,
            });

            const response = await fetch(config.shareUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            return {
                result: "error",
                message: e.message,
            };
        }
    }

    /**
     * Получение расшаренной коллекции виджетов
     */
    async getCollection(collection_id: number) {
        try {
            const requestData = this._createRequestData({
                collection_id: collection_id,
            });

            const response = await fetch(config.getShareCollectionUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            return {
                result: "error",
                message: e.message,
            };
        }
    }

    /**
     * Копирование расшаренной коллекции виджетов в сообщество data.target_vk_group_id
     */
    async copy(data: CopyCollectionData) {
        try {
            const requestData = this._createRequestData(data);

            const response = await fetch(config.copyShareCollectionUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            return {
                result: "error",
                message: e.message,
            };
        }
    }
}

export default ShareService;
