import React, { ReactElement } from "react";
import { Alert } from "@vkontakte/vkui";
import { AlertActionInterface } from "@vkontakte/vkui/dist/components/Alert/Alert";

import { ReactComponent as Checkmark } from "../../../resources/icons/checkmark.svg";
import { ReactComponent as Cancelmark } from "../../../resources/icons/cancelmark.svg";

interface AlertSubscriptionSuccessProps {
    actions?: AlertActionInterface[];
    type: "success" | "cancel";
    title?: string | ReactElement;
    desc: string | ReactElement;
    onClose: Function;
    body?: any;
}

const AlertSubscriptionSuccess = (props: AlertSubscriptionSuccessProps) => {
    let { actions, title, desc, type, onClose, body } = props;

    let icon;

    switch (type) {
        case "success":
            icon = <Checkmark />;
            break;
        case "cancel":
            icon = <Cancelmark />;
            break;
        default:
            icon = <Checkmark />;
    }

    if (!actions) {
        actions = [
            {
                title: "Закрыть",
                autoClose: true,
                mode: "default",
            },
        ];
    }

    return (
        <Alert
            actions={actions}
            onClose={() => {
                onClose && onClose();
            }}
        >
            <div style={{ marginBottom: 20 }}>{icon}</div>
            {title && <h2 style={{ textAlign: "center" }}>{title}</h2>}
            {desc && (
                <div
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        textAlign: "center",
                    }}
                >
                    {desc}
                </div>
            )}
            {body ? body : null}
        </Alert>
    );
};

export default AlertSubscriptionSuccess;
