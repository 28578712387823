import React from "react";
import { ReactComponent as GhostIcon } from "../../../resources/icons/GhostIcon.svg";

type PageNotFoundErrorProps = {
    /**
     * Вывести кнопку "Сообщить об ошибке"
     */
    reportErrorButton?: boolean;

    /**
     * Ссылка для кнопки "Сообщить об ошибке". По умолчанию -  ссылка на диалог с сообществом Senler
     */
    reportErrorButtonUrl?: string;
};

const PageNotFoundError = (props: PageNotFoundErrorProps) => {
    const {
        reportErrorButton = false,
        reportErrorButtonUrl = "https://vk.com/im?media=&sel=-139157852",
    } = props;

    return (
        <>
            <div className="FatalError FatalError_not-found">
                <div className="FatalError__img">
                    <GhostIcon />
                </div>
                <p className="bold head">Страница не найдена</p>
                {reportErrorButton && (
                    <div className="FatalError__report">
                        <a
                            className="btn-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={reportErrorButtonUrl}
                        >
                            Сообщить об ошибке
                        </a>
                    </div>
                )}
            </div>
        </>
    );
};

export default PageNotFoundError;
