import React, { ReactElement, useContext } from "react";
import {
    ActionSheet,
    ActionSheetDefaultIosCloseItem,
    ActionSheetItem,
    File,
    platform,
    Platform,
} from "@vkontakte/vkui";
import { Icon28SearchOutline, Icon28DocumentOutline } from "@vkontakte/icons";

import { Context } from "../../../context";
import VkIconsPopout from "./VkIconsPopout";

type AdvantagesEditPhotoPopoutPropsType = {
    imgHelpText: string;
    svgIcons: { id: number; icon_name: string; icon: ReactElement }[];
    getImg: (e) => Promise<boolean>;
    setImgHelpText: (imgHelpText: string) => void;
    setSvgIconObj: (obj: {
        id: number;
        icon_name: string;
        icon: ReactElement;
    }) => void;
    popoutRef: React.RefObject<Element>;
};

const AdvantagesEditPhotoPopout = (
    props: AdvantagesEditPhotoPopoutPropsType
) => {
    const {
        svgIcons,
        getImg,
        setSvgIconObj,
        imgHelpText,
        setImgHelpText,
        popoutRef,
    } = props;

    const { popout } = useContext(Context);

    return (
        <ActionSheet
            onClose={() => popout.close()}
            iosCloseItem={<ActionSheetDefaultIosCloseItem />}
            toggleRef={popoutRef}
        >
            <ActionSheetItem
                onClick={() => {
                    popout.open(
                        <VkIconsPopout
                            svgIcons={svgIcons}
                            imgHelpText={imgHelpText}
                            setSvgIconObj={setSvgIconObj}
                            setImgHelpText={setImgHelpText}
                        />
                    );
                }}
                before={<Icon28SearchOutline width={24} />}
                autoClose
            >
                Найти иконку
            </ActionSheetItem>
            <ActionSheetItem
                before={<Icon28DocumentOutline width={24} />}
                className="B-Advantages__file-popout-item"
            >
                <File
                    className="B-Advantages__file-input"
                    style={{ padding: 0 }}
                    onChange={getImg}
                    mode="tertiary"
                    accept=".png, .jpg, .jpeg"
                    children=""
                />
                Выбрать файл
            </ActionSheetItem>
            {platform() !== Platform.IOS && (
                <ActionSheetItem
                    className={`PagesContextPopout__item ${
                        platform() === Platform.ANDROID
                            ? "action-sheet-android-cancel"
                            : ""
                    } cancel`}
                    mode="default"
                    autoClose
                >
                    Отмена
                </ActionSheetItem>
            )}
        </ActionSheet>
    );
};

export default AdvantagesEditPhotoPopout;
