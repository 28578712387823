import { SubscriptionParams } from "../Structures";
import Subscription from "./Subscription";

export default (
    subscriptions: SubscriptionParams[],
    vk_group_id: number,
    vk_app_id: number
) => {
    /**
     * Можно выполнить дополнительные преобразования над каждым элементом подписок
     * Замапить данные, прописать значения по умолчанию
     * */
    return subscriptions.map(
        (s) => new Subscription(s, vk_group_id, vk_app_id)
    );
};
