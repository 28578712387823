import React from "react";
import Page from "./Page";
import PageNotFoundError from "../Elements/Errors/PageNotFoundError";
import PageDisabledError from "../Elements/Errors/PageDisabledError";
import { PageItem } from "../../includes/Structures";
import { ReactComponent as GhostIcon } from "../../resources/icons/GhostIcon.svg";

type PageViewProps = {
    /**
     * Сервис, предоставляющий параметры запуска приложения и методы работы с ними
     */
    launchParamsProvider: any;

    /**
     * Сущность страницы
     */
    pageItem: PageItem;

    /**
     * Статус страниы - активна, неактивная, не найдена, неизвестная ошибка
     */
    status: "ok" | "not_found" | "disabled" | "fatal";

    /**
     * Платформа просмотра приложения
     */
    platform: string;

    /**
     * Модуль ресайза окна iframe приложения
     */
    resizer: any;

    /**
     * Режим просмотра
     */
    isPreview: boolean;
};

/**
 * Отображение страницы. ЛОгика, отвечающая за визуальное представление
 */

const PageView = (props: PageViewProps) => {
    const {
        pageItem,
        status,
        platform,
        resizer,
        isPreview,
        launchParamsProvider,
    } = props;

    /**
     * Если возникла непредвиденная ошибка
     */
    if (status === "fatal") {
        return <div>fatal error</div>;
    }

    /**
     * Если страница деактивирована
     */
    if (status === "disabled") {
        return <PageDisabledError icon={<GhostIcon />} />;
    }

    /**
     * Если страница не найдена
     */
    if (status === "not_found") {
        return (
            <PageNotFoundError
                reportErrorButton={true}
                reportErrorButtonUrl={`https://vk.com/im?media=&sel=-${launchParamsProvider.params.vk_group_id}`}
            />
        );
    }

    if (pageItem && pageItem.blocks) {
        return (
            <Page
                blocks={pageItem.blocks}
                platform={platform}
                resizer={resizer}
                isPreview={isPreview}
            />
        );
    } else {
        return <div>fatal error</div>;
    }
};

export default PageView;
