import React, { ReactElement, useRef } from "react";
import ReactDOM from "react-dom";

interface WithTooltipProps {
    /**
     * Дополнительный CSS класс
     */
    className?: string;

    /**
     * Элемент, который нужно обернуть
     */
    children: any;

    /**
     * Контент тултипа
     */
    tooltipContent?: ReactElement;
}

const WithHoverTooltip = (props: WithTooltipProps) => {
    const { className, tooltipContent, children } = props;

    const rootElem = useRef(null);

    const renderTooltip = () => {
        if (rootElem.current) {
            /**
             * Создаим элементы тултипа
             */
            const contentElem = document.createElement("div");
            contentElem.classList.add("WithTooltip__content");
            contentElem.classList.add("WithTooltip__content_hover");

            const arrowElem = document.createElement("div");
            arrowElem.classList.add("WithTooltip__content_hover-arrow");

            /**
             * Добавим их в основную обертку
             */
            rootElem.current.appendChild(contentElem);
            rootElem.current.appendChild(arrowElem);

            /**
             * Отрендерим через ReactDom.render так как контент может быть ReactElement
             */
            ReactDOM.render(tooltipContent, contentElem);

            /**
             * Спозиционируем
             */
            const coords = rootElem.current.getBoundingClientRect();
            const contentCoords = contentElem.getBoundingClientRect();
            const windowWidth = document.body.clientWidth;

            contentElem.style.bottom = `${coords.height + 10}px`;
            arrowElem.style.left = `${coords.width / 2 - 4}px`;
            arrowElem.style.bottom = `${coords.height + 4}px`;

            // Правый край тултипа сразу после вставки и вычисления его ширины
            let tooltipEnds = contentCoords.left + contentCoords.width;

            // Смещение тултипа по Х относительно искомого элемента (смещаем так, чтобы середина тултипа совпадала с серединой искомого элемента)
            let tooltipLeftPosInElement =
                contentCoords.width / 2 - coords.width / 2;

            // Положение левого края тултипа относительно страницы после вычисления его полложения относительно элемента
            let tooltipStartComputed =
                contentCoords.left - tooltipLeftPosInElement;

            // Положение правого края тултипа относительно документа
            let tooltipEndComputed = tooltipStartComputed + contentCoords.width;

            if (tooltipEndComputed > windowWidth) {
                // Если правый край выходит за пределы документа
                // Зададим допполнительное смещение на разность + 10 пикселей запаса от правого края
                contentElem.style.left = `-${tooltipEnds - windowWidth + 5}px`;
            } else if (tooltipStartComputed <= 0) {
                // Если левый край выходит за пределы документа
                // Зададим допполнительное смещение на разность + 10 пикселей запаса от правого края
                contentElem.style.left = `-${
                    tooltipLeftPosInElement - Math.abs(tooltipStartComputed) - 5
                }px`;
            } else {
                // Иначе значение по умолчанию - по центу относительно искомого элемента
                contentElem.style.left = `-${tooltipLeftPosInElement}px`;
            }

            contentElem.classList.add("opened");
            arrowElem.classList.add("opened");
        }
    };

    const removeTooltip = () => {
        const c = rootElem.current.querySelector(".WithTooltip__content");
        const a = rootElem.current.querySelector(
            ".WithTooltip__content_hover-arrow"
        );
        if (c) rootElem.current.removeChild(c);
        if (a) rootElem.current.removeChild(a);
    };

    const classNames = ["WithTooltip WithTooltip_hover"];

    if (className) {
        classNames.push(className);
    }

    return (
        <div
            onMouseEnter={renderTooltip}
            onMouseLeave={removeTooltip}
            ref={rootElem}
            className={classNames.join(" ")}
        >
            {children}
        </div>
    );
};

export default React.memo(WithHoverTooltip, () => true);
