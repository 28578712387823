import { Separator } from "@vkontakte/vkui";
import React from "react";

const isInteger = require("is-integer");

type DividerProps = {
    data: any;
    isPreview?: boolean;
};

const Divider = (props: DividerProps) => {
    const { data } = props;

    let separator_width = Math.round(Number(data.meta.width));
    let separator_padding_top = Math.round(Number(data.meta.padding_top));
    let separator_padding_bottom = Math.round(Number(data.meta.padding_bottom));

    if (
        !isInteger(Number(separator_width)) ||
        !isInteger(Number(separator_padding_top)) ||
        !isInteger(Number(separator_padding_bottom))
    ) {
        console.log("Ожидалось число");
        return <></>;
    }

    if (separator_width > 100) {
        separator_width = 100;
    }

    if (separator_width < 0) {
        separator_width = 10;
    }

    if (separator_padding_top > 100) {
        separator_padding_top = 100;
    }

    if (separator_padding_top < 0) {
        separator_padding_top = 5;
    }

    if (separator_padding_bottom > 100) {
        separator_padding_bottom = 100;
    }

    if (separator_padding_bottom < 0) {
        separator_padding_bottom = 5;
    }

    return (
        <Separator
            className="B-Divider"
            style={{
                width: `${separator_width}%`,
                paddingTop: `${separator_padding_top}px`,
                paddingBottom: `${separator_padding_bottom}px`,
            }}
        />
    );
};

export default Divider;
