import {
    Checkbox,
    FormItem,
    FormLayout,
    FormLayoutGroup,
    NativeSelect,
    platform,
    Platform,
} from "@vkontakte/vkui";
import React, { useContext } from "react";
import DetachedBadges from "../../Controls/DetachedBadges";
import ReactSelect from "react-select";

import { sexList, bDayList, relations } from "../../../includes/Constants";
import AudienceContext from "../../../Context/AudienceContext";
import { getAgesForReactSelect, range } from "../../../includes/Helpers/Helpers";

const Demography = () => {
    const { set, sex, age_from, age_to, b_day, relation } =
        useContext(AudienceContext);

    const ageListFrom = range(7, age_to !== "any" ? age_to - 1 : 89);
    const ageListTo = range(age_from !== "any" ? age_from + 1 : 7, 90);

    const resolveBdayValue = (value, isChecked) => {
        let newVal = b_day === "any" ? [] : [...b_day];

        if (isChecked) {
            newVal.push(value);
        } else {
            newVal = newVal.filter((i) => i !== value);
        }

        set("b_day", newVal.length === 0 ? "any" : newVal);
    };

    let reactSelectSexList = [];
    let reactSelectAgesFrom = [];
    let reactSelectAgesTo = [];

    if (platform() !== Platform.IOS) {
        reactSelectSexList = [{ label: "Любой", value: "any" }, ...sexList];
        reactSelectAgesFrom = getAgesForReactSelect(ageListFrom, "from");
        reactSelectAgesTo = getAgesForReactSelect(ageListTo, "to");
    }

    return (
        <FormLayout>
            {/* Выбор пола */}
            <FormItem top="Пол">
                {platform() !== Platform.IOS ? (
                    <div className="ReactSelect-default__wrapper">
                        <ReactSelect
                            placeholder="test"
                            className={`Variables-select ReactSelect ${
                                sex === "any"
                                    ? "ReactSelect__default-value"
                                    : ""
                            }`}
                            classNamePrefix="Variables-select"
                            defaultValue={reactSelectSexList[0]}
                            options={reactSelectSexList}
                            cacheOptions
                            defaultOptions
                            noOptionsMessage={() => {
                                return "Ничего не найдено";
                            }}
                            value={{
                                label: reactSelectSexList.find(
                                    (gender) => gender.value === sex
                                ).label,
                                value: sex,
                            }}
                            onChange={(option) => {
                                set("sex", option.value);
                            }}
                        />
                    </div>
                ) : (
                    <NativeSelect
                        placeholder="Любой"
                        value={sex}
                        onChange={(e) => {
                            e.target.value === ""
                                ? set("sex", "any")
                                : set("sex", parseInt(e.target.value, 10));
                        }}
                    >
                        {sexList.map((item, index) => (
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </NativeSelect>
                )}
            </FormItem>

            {/* Выбор диапазона возраста */}
            <FormItem top="Возраст">
                <FormLayoutGroup className="d-flex">
                    {platform() !== Platform.IOS ? (
                        <div
                            className="ReactSelect-default__wrapper"
                            style={{ width: "50%", marginRight: 5 }}
                        >
                            <ReactSelect
                                placeholder="test"
                                className={`Variables-select ReactSelect ${
                                    age_from === "any"
                                        ? "ReactSelect__default-value"
                                        : ""
                                }`}
                                classNamePrefix="Variables-select"
                                defaultValue={reactSelectAgesFrom[0]}
                                options={reactSelectAgesFrom}
                                cacheOptions
                                defaultOptions
                                noOptionsMessage={() => {
                                    return "Ничего не найдено";
                                }}
                                value={{
                                    label: reactSelectAgesFrom.find(
                                        (age) => age.value == age_from
                                    ).label,
                                    value: age_from,
                                }}
                                onChange={(option) => {
                                    set("age_from", option.value);
                                }}
                            />
                        </div>
                    ) : (
                        <NativeSelect
                            placeholder="От"
                            value={age_from}
                            onChange={(e) => {
                                e.target.value === ""
                                    ? set("age_from", "any")
                                    : set(
                                          "age_from",
                                          parseInt(e.target.value, 10)
                                      );
                            }}
                            style={{ flexGrow: 1, marginRight: 5 }}
                        >
                            {ageListFrom.map((age) => (
                                <option key={`age-from-${age}`} value={age}>
                                    {age}
                                </option>
                            ))}
                        </NativeSelect>
                    )}

                    {platform() !== Platform.IOS ? (
                        <div
                            className="ReactSelect-default__wrapper"
                            style={{ width: "50%", marginLeft: 5 }}
                        >
                            <ReactSelect
                                placeholder="test"
                                className={`Variables-select ReactSelect ${
                                    age_to === "any"
                                        ? "ReactSelect__default-value"
                                        : ""
                                }`}
                                classNamePrefix="Variables-select"
                                defaultValue={reactSelectAgesTo[0]}
                                options={reactSelectAgesTo}
                                cacheOptions
                                defaultOptions
                                noOptionsMessage={() => {
                                    return "Ничего не найдено";
                                }}
                                value={{
                                    label: reactSelectAgesTo.find(
                                        (age) => age.value === age_to
                                    ).label,
                                    value: age_to,
                                }}
                                onChange={(option) => {
                                    set("age_to", option.value);
                                }}
                            />
                        </div>
                    ) : (
                        <NativeSelect
                            placeholder="До"
                            value={age_to}
                            onChange={(e) => {
                                e.target.value === ""
                                    ? set("age_to", "any")
                                    : set(
                                          "age_to",
                                          parseInt(e.target.value, 10)
                                      );
                            }}
                            style={{ flexGrow: 1, marginLeft: 5 }}
                            className="mt-0"
                        >
                            {ageListTo.map((age) => (
                                <option key={`age-to-${age}`} value={age}>
                                    {age}
                                </option>
                            ))}
                        </NativeSelect>
                    )}
                </FormLayoutGroup>
            </FormItem>

            {/* Выбор дня рождения */}
            <FormItem top="День рождения">
                <FormLayoutGroup>
                    {bDayList.map((item) => (
                        <Checkbox
                            name="bday"
                            key={`bday-${item.value}`}
                            value={item.value}
                            checked={
                                b_day !== "any" &&
                                b_day.indexOf(item.value) !== -1
                            }
                            onChange={(e) => {
                                let isChecked = e.target.checked;
                                let value = e.target.value;
                                resolveBdayValue(value, isChecked);
                            }}
                        >
                            {item.title}
                        </Checkbox>
                    ))}
                </FormLayoutGroup>
            </FormItem>

            {/* Выбор семейного положения */}
            {/* <FormItem top="Семейное положение">
                <SelectMimicry
                    onClick={() =>
                        onModalRequest({
                            id: "relations-select",
                            args: {
                                selected: relation,
                                relations: relations,
                                callback: (val) => {
                                    set("relation", val);
                                },
                            },
                        })
                    }
                    placeholder="Выберите статусы"
                ></SelectMimicry>
            </FormItem> */}

            {relation.length > 0 && (
                <DetachedBadges
                    items={relations.filter((r) => relation.indexOf(r.id) > -1)}
                    name="relation"
                    onChange={(val) => {
                        set(
                            "relation",
                            val.map((i) => i.id)
                        );
                    }}
                    getText={(item) => item.title}
                />
            )}
        </FormLayout>
    );
};

export default Demography;
