import { LaunchParams as LaunchParamsType } from "../Structures";

class LaunchParams {
    raw_launch_url: string; // Строка запроса при запуске приложения в оригинальном виде
    gets: any;
    hash: any;
    raw_hash: string;
    params: LaunchParamsType;

    constructor(searchStr: string, hashStr: string) {
        this.raw_launch_url = searchStr;
        this.raw_hash = hashStr;

        this.gets = {};
        this.hash = {};

        this.params = {
            sign: "",
            vk_access_token_settings: "",
            vk_app_id: 0,
            vk_are_notifications_enabled: 0,
            vk_group_id: 0,
            vk_is_app_user: 0,
            vk_is_favorite: "",
            vk_language: "",
            vk_platform: "",
            vk_ref: "",
            vk_user_id: 0,
            vk_viewer_group_role: "",
            utm_source: "",
            utm_medium: "",
            utm_campaign: "",
            utm_term: "",
            utm_content: "",
            rs: "",
            roistat: "",
            source: "",
        };

        this.init();
    }

    init() {
        try {
            this.gets = this.get_url_params();
            for (let name in this.gets) {
                if (this.gets.hasOwnProperty(name)) {
                    this.params[name] = this.gets[name];
                }
            }

            this.hash = this.get_hash_params();

            let page_id = this.hash["page"];

            if (page_id) {
                this.hash["page"] = page_id.replace(/\?.*/g, "");
            }

            for (let name in this.hash) {
                if (this.hash.hasOwnProperty(name)) {
                    this.params[name] = this.hash[name];
                }
            }

            // Если не передан источник подписки вручную
            if (!this.params.source) {
                // Если передана подписная страница
                if (this.params.s) {
                    this.params.source = "subscription";
                } else {
                    // Если подписная страница не передана - каталог
                    this.params.source = "subscriptions";
                }
            }

            // Object.freeze(this.params);
            // Object.freeze(this.gets);
            // Object.freeze(this.hash);
            // Object.freeze(this);
        } catch (e) {
            console.log(e);
        }
    }

    get_url_params() {
        let a = this.raw_launch_url;
        let b = {} as LaunchParamsType;

        a = a.substring(1);
        if (!a) return b;

        let params = a.split("&");
        for (let i = 0; i < params.length; i++) {
            let c = params[i].split("=");
            if (
                [
                    "vk_app_id",
                    "vk_user_id",
                    "vk_group_id",
                    "vk_is_app_user",
                    "vk_are_notifications_enabled",
                ].indexOf(c[0]) >= 0
            ) {
                b[c[0]] = parseInt(c[1], 10);
            } else {
                b[c[0]] = c[1];
            }
        }

        return b;
    }

    get_hash_params() {
        let a = this.raw_hash;
        let b = {};

        a = a.substring(1);
        if (!a) return b;

        let params = a.split("&");
        for (let i = 0; i < params.length; i++) {
            let c = params[i].split("=");
            let key = c[0].replace("amp;", "");
            if (!c[1]) {
                b[key] = key; // hotfix бага с передачей в приложение неверной ссылки при запуске
            } else {
                b[key] = c[1];
            }
        }
        return b;
    }

    getPlatform(): string {
        return this.gets.vk_platform;
    }

    getUtms() {
        const result = {};

        if (!this.params) {
            return result;
        }

        Object.keys(this.params).forEach((key) => {
            if (key.indexOf("utm_") === 0) {
                result[key] = this.params[key];
            }
        });

        return result;
    }
}

export default LaunchParams;
