import React, { useContext } from "react";
import {
    Header,
    Group,
    List,
    Cell,
    Avatar,
    Link,
    Button,
} from "@vkontakte/vkui";
import ModalWidgetEditHeader from "../ModalWidgetEditHeader";
import ModalPersonalButton from "../ModalEdit/Button";
import ModalPersonalEditItem from "../ModalEdit/Item";
import { Context } from "../../../context";
import reducer from "./reduser";
import initialState from "./state";
import useUpdate from "../../../includes/Hooks/useUpdate";
import WidgetValidate from "../../../includes/Validators/WidgetValidate";
import WidgetHeader from "../WidgetHeader";

export const initalErrors = {
    title: false,
    rows: false,
    rows_count: false,
    rows_title: false,
    rows_button: false,
    rows_item: [],
};

const PersonalОfferEdit = (props) => {
    const { user } = useContext(Context);
    const [state, dispatch]: any = useUpdate({
        ...props,
        reducer,
        initialState,
        type: "personal",
    });
    const errors = { ...initalErrors, ...props.errors.resp };

    const modals = {
        editHeader: {
            title: "Заголовок виджета",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.title, url: state.title_url }}
                    type="title"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editLink: {
            title: "Ссылка",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.more, url: state.more_url }}
                    type="more"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editItem: {
            title: "Редактировать элемент",
            body: (state, callback, close, cardID) => (
                <ModalPersonalEditItem
                    state={state.rows[cardID]}
                    type="personalOfer"
                    onSelect={callback}
                    close={close}
                    cardID={cardID}
                />
            ),
            callback: dispatch,
            state,
        },
        addButton: {
            title: "Добавить кнопку",
            body: (state, callback, close, cardID) => (
                <ModalPersonalButton
                    state={state.rows[cardID]}
                    onSelect={callback}
                    close={close}
                    cardID={cardID}
                />
            ),
            callback: dispatch,
            state,
        },
    };
    return (
        <Group>
            <Header>Персональное предложение</Header>
            <WidgetHeader
                onModalRequest={props.onModalRequest}
                state={state}
                modals={modals}
                errors={errors}
            />
            <List>
                {state.rows.map((item, i) => (
                    <Cell
                        key={i}
                        before={<Avatar src={user.photo_100} />}
                        subtitle={
                            <div
                                style={
                                    WidgetValidate.isErrors(errors, i, "descr")
                                        ? { color: "var(--vkui--color_accent_red)" }
                                        : null
                                }
                            >
                                {item.descr ? item.descr : "+ Описание"}
                            </div>
                        }
                        //@ts-ignore
                        bottomContent={
                            <Button
                                onClick={() =>
                                    props.onModalRequest({
                                        id: "dynamic",
                                        args: {
                                            ...modals.addButton,
                                            cardID: i,
                                        },
                                    })
                                }
                                mode="outline"
                                style={
                                    WidgetValidate.isErrors(errors, i, "button")
                                        ? {
                                              color: "var(--vkui--color_accent_red)",
                                              border: "1px solid var(--vkui--color_accent_red)",
                                          }
                                        : null
                                }
                                size="m"
                            >
                                {item.button ? item.button : "+ Кнопка"}
                            </Button>
                        }
                    >
                        <Link
                            style={
                                WidgetValidate.isErrors(errors, i, "title")
                                    ? { color: "var(--vkui--color_accent_red)" }
                                    : null
                            }
                            onClick={() =>
                                props.onModalRequest({
                                    id: "dynamic",
                                    args: { ...modals.editItem, cardID: i },
                                })
                            }
                        >
                            {item.title ? item.title : "+ Заголовок"}
                        </Link>
                    </Cell>
                ))}
            </List>
        </Group>
    );
};

export default PersonalОfferEdit;
