import React from "react";
import { Placeholder } from "@vkontakte/vkui";
import { Icon56AddCircleOutline } from "@vkontakte/icons";

const EmptyPagesBlocksStub = (props) => {
    const { onClick } = props;

    return (
        <div className="Pages__add-block-stub" onClick={onClick}>
            <Placeholder icon={<Icon56AddCircleOutline />} stretched={true}>
                Начните добавление блоков
            </Placeholder>
        </div>
    );
};

export default EmptyPagesBlocksStub;
