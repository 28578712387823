import React from "react";
import { Link, RichCell } from "@vkontakte/vkui";
import Image from "./Image";

const ItemCardCell = (props) => (
    <RichCell
        className="ItemCardCell"
        onClick={props.onCardClick}
        text={props.description}
        before={
            <div className="ItemCardCell-wrapper">
                <Image src={props.img} className="ItemCardCell-img" />
            </div>
        }
        after={
            <div className="text-right">
                <Link
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onHelpClick();
                    }}
                    className="card-cell-help c-ptr pl-1 pb-1 pt-1"
                >
                    ?
                </Link>
            </div>
        }
        multiline
    >
        {props.title}
    </RichCell>
);

export default ItemCardCell;
