type ProductErrors = {
    name?: string;
};

export default function validator(state) {
    const r = {
        result: true,
        errors: {} as ProductErrors,
    };

    if (state.name.trim() === "") {
        r.result = false;
        r.errors.name = "Необходимо заполнить";
    }

    return r;
}
