const initialState = {
    button: {
        action: "lead",
        color: "var(--vkui--color_accent_blue)",
        text_color: "var(--vkui--color_background_content)",
        send_trigger: false,
        lead_admin: 0,
        text: "Подписаться",
        unsubscribe_text: "Отписаться",
        url: "",
        bot_id: 0,
        step: {
            label: '',
            value: '',
        },
        hide_button: false,
    },
    errors: {},
};

export default initialState;
