import React, { useState, useRef, useContext } from "react";
import WithHoverTooltip from "../hoc/WithHoverTooltip";
import { PageItem } from "../../includes/Structures";
import FadeIn from "react-fade-in";
import { Context } from "../../context";
import {
    Icon24Linked,
    Icon24MoreVertical,
    Icon24StatisticsOutline,
    Icon28CopyOutline,
    Icon28DeleteOutlineAndroid,
    Icon28HideOutline,
    Icon28LinkOutline,
    Icon28SettingsOutline,
    Icon28ViewOutline,
    Icon28WriteOutline,
} from "@vkontakte/icons";

type PagesListDesktopActionsProps = {
    /**
     * Даннные страницы
     */
    page: PageItem;

    /**
     * В режиме редактирования
     */
    isEdit: Boolean;

    /**
     * Ссылка на страницу
     */
    pageUrl: string;

    /**
     * Действие при клике на кнопку "Статистика"
     */
    onStatClick: (id: string) => void;

    /**
     * Действие при клике на кнопку "Дублировать"
     */
    onCopyClick: (id: string) => void;

    /**
     * Действие при клике на кнопку "Скопировать ссылку"
     */
    onCopyLinkClick: (id: string) => void;

    /**
     * Действие при клике на кнопку "Редактировать"
     */
    onEditClick: (id: string) => void;

    /**
     * Действие при клике на кнопку "Отключить"
     */
    onDisableClick: (id: string, status: number) => void;

    /**
     * Действие при клике на кнопку "Предпросмотр"
     */
    onPreviewClick: (id: string) => void;

    /**
     * Действие при клике на кнопку "Переименовать"
     */
    onRenameClick: (id: string) => void;

    /**
     * Действие при клике на кнопку "Удалить"
     */
    onDeleteClick: (id: string) => void;
};

const PagesListDesktopActions = (props: PagesListDesktopActionsProps) => {
    const {
        page,
        onCopyClick,
        onCopyLinkClick,
        onDeleteClick,
        onEditClick,
        onDisableClick,
        onRenameClick,
        onStatClick,
        pageUrl,
    } = props;

    const { pages, snackbar, groupSiteData } = useContext(Context);

    const moreElem = useRef(null);
    const contextRef = useRef(null);
    const [contextIsActive, setContextIsActive] = useState(false);

    const menuHeight = 232;
    const headerHeight = 52;

    const allPages = pages.getAll();

    const getContextPopout = () => {
        /**
         * По умолчанию показываем снизу
         */
        let position = {
            top: "calc(100% + 10px)",
            right: 10,
            bottom: "initial",
        };

        if (moreElem) {
            const coords = moreElem.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const bottomEdge = coords.top + menuHeight;
            const topEdge = coords.top - menuHeight - headerHeight;
            const buttonFixedLayout = document.querySelector(
                ".Pages__create-button"
            ) as HTMLElement;
            const buttonHeight = buttonFixedLayout.clientHeight;

            const beyondTheTopEdge = topEdge < 10;
            const beyondTheBottomEdge =
                bottomEdge > windowHeight - buttonHeight - 10;

            // Если выходит на обе границы
            if (beyondTheBottomEdge && beyondTheTopEdge) {
                // Покажем по  центру
                position = {
                    top: `${-(menuHeight / 2)}px`,
                    right: 10,
                    bottom: "initial",
                };
                // если выходит за нижнюю границу
            } else if (bottomEdge > windowHeight - buttonHeight - 10) {
                // Покажем сверху
                position = {
                    top: "initial",
                    right: 10,
                    bottom: "calc(100% + 10px)",
                };
            }
        }

        return (
            <FadeIn delay={50} transitionDuration={200}>
                <div
                    ref={contextRef}
                    className={`PagesList_desktop-context ${
                        contextIsActive ? "active" : ""
                    }`}
                    style={{ ...position }}
                >
                    <div
                        className="PagesList_desktop-context-item"
                        onClick={(e) => {
                            e.stopPropagation();
                            onEditClick && onEditClick(page.id);
                        }}
                    >
                        <Icon28SettingsOutline
                            width={24}
                            height={24}
                            fill={"var(--vkui--color_text_accent)"}
                        />
                        <span>Редактировать</span>
                    </div>

                    {page.status !== 3 ? (
                        <div
                            className="PagesList_desktop-context-item"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDisableClick && onDisableClick(page.id, 3);
                            }}
                        >
                            <Icon28HideOutline
                                width={24}
                                height={24}
                                fill="var(--vkui--color_accent_gray)"
                            />
                            <span>Отключить</span>
                        </div>
                    ) : (
                        <div
                            className="PagesList_desktop-context-item"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDisableClick && onDisableClick(page.id, 1);
                            }}
                        >
                            <Icon28ViewOutline
                                width={24}
                                height={24}
                                fill="var(--vkui--color_accent_green)"
                            />
                            <span>Включить</span>
                        </div>
                    )}
                    <a
                        href={pageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="PagesList_desktop-context-item"
                    >
                        <Icon28LinkOutline
                            width={24}
                            height={24}
                            fill={"var(--vkui--color_text_accent)"}
                        />
                        <span>Перейти</span>
                    </a>
                    <div
                        className="PagesList_desktop-context-item"
                        onClick={(e) => {
                            e.stopPropagation();
                            onRenameClick && onRenameClick(page.id);
                        }}
                    >
                        <Icon28WriteOutline
                            width={24}
                            height={24}
                            fill={"var(--vkui--color_text_accent)"}
                        />
                        <span>Переименовать</span>
                    </div>
                    <div
                        className="PagesList_desktop-context-item"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDeleteClick && onDeleteClick(page.id);
                        }}
                    >
                        <Icon28DeleteOutlineAndroid
                            width={24}
                            height={24}
                            fill={"var(--vkui--color_accent_red)"}
                        />
                        <span style={{ color: "var(--vkui--color_accent_red)" }}>
                            Удалить
                        </span>
                    </div>
                </div>
            </FadeIn>
        );
    };

    return (
        <>
            <div className="PagesList_desktop-actions">
                <div className="PagesList_desktop-actions-item">
                    <WithHoverTooltip tooltipContent={<span>Статистика</span>}>
                        <Icon24StatisticsOutline
                            fill="var(--vkui--color_icon_accent)"
                            onClick={(e) => {
                                e.preventDefault();
                                onStatClick && onStatClick(page.id);
                            }}
                        />
                    </WithHoverTooltip>
                </div>
                <div className="PagesList_desktop-actions-item">
                    <WithHoverTooltip
                        tooltipContent={<span>Создать дубликат</span>}
                    >
                        <Icon28CopyOutline
                            fill="var(--vkui--color_icon_accent)"
                            width={24}
                            height={24}
                            onClick={(e) => {
                                e.preventDefault();
                                onCopyClick && onCopyClick(page.id);
                            }}
                        />
                    </WithHoverTooltip>
                </div>
                <div className="PagesList_desktop-actions-item">
                    <WithHoverTooltip
                        tooltipContent={<span>Скопировать ссылку</span>}
                    >
                        <Icon24Linked
                            fill="var(--vkui--color_icon_accent)"
                            onClick={(e) => {
                                e.preventDefault();
                                onCopyLinkClick && onCopyLinkClick(page.id);
                            }}
                        />
                    </WithHoverTooltip>
                </div>
                <div
                    ref={moreElem}
                    className="PagesList_desktop-actions-item PagesList_desktop-actions-item_more"
                    onMouseEnter={() => {
                        setContextIsActive(true);
                    }}
                    onMouseLeave={() => {
                        setContextIsActive(false);
                    }}
                >
                    <Icon24MoreVertical style={{ color: 'var(--vkui--color_text_secondary)' }} />
                    {contextIsActive && getContextPopout()}
                </div>
            </div>
        </>
    );
};

export default PagesListDesktopActions;
