import { PageData, PageItem } from "../../Structures";
import { DEFAULT_PAGE_SUMMARY_STATISTIC_DATA } from "../../Constants";
import PageBlocksToEntities from "./PageBlocksToEntities";

export default (pageData: PageData): PageItem => {
    return {
        // ID промо-страницы
        id: pageData.id ? pageData.id : "Not set",

        // Название промо-страницы
        name: pageData.name ? pageData.name : "Not set",

        // Статус промо-страницы
        status: pageData.status ? pageData.status : 0,

        // Автор промо-страницы
        author_vk_user_id: pageData.author_vk_user_id
            ? pageData.author_vk_user_id
            : 0,

        // Дата создания промо-страницы
        created_at: pageData.created_at ? new Date(pageData.created_at) : null,

        // Дата редактирования промо-страницы
        updated_at: pageData.updated_at ? new Date(pageData.updated_at) : null,

        // Порядок сортировки
        sort: pageData.sort ? pageData.sort : 0,

        // ID группы к которой принадлежит промо-страница
        vk_group_id: pageData.vk_group_id ? pageData.vk_group_id : 0,

        //
        viewCount: pageData.viewCount ? pageData.viewCount : 0,

        // актуальные опубликованные блоки
        blocks:
            pageData.blocks && Array.isArray(pageData.blocks)
                ? PageBlocksToEntities([...pageData.blocks])
                : [],

        // Блоки для редактирования
        blocks_edit:
            pageData.blocks_edit && Array.isArray(pageData.blocks_edit)
                ? PageBlocksToEntities([...pageData.blocks_edit])
                : [],

        // Лог изменений
        states:
            pageData.states && Array.isArray(pageData.states)
                ? [...pageData.states]
                : [],

        // Краткая сводка по статистике
        statisticSummary: pageData.statisticSummary
            ? {
                  ...DEFAULT_PAGE_SUMMARY_STATISTIC_DATA,
                  ...pageData.statisticSummary,
              }
            : DEFAULT_PAGE_SUMMARY_STATISTIC_DATA,
    };
};
