import React, { useContext } from "react";
import { Context } from "../context";
import container from "../container";
import { setHash } from "../includes/Helpers/Helpers";
import BlockSelect from "../Сomponents/Controls/Selects/BlockSelect";
import PagesBlocks from "../includes/PagesBlocks";
import PagesTemplates from "../includes/PagesTemplates";
import {
    Analytics,
    PAGES_BLOCK_ADD,
    PAGES_BLOCK_ADD_RECENT,
} from "../includes/Metrics/Analytics";

type Props = {
    isNewItem?: boolean; // Добавляем блок для новой страницы
    isRecent?: boolean; // Добавляем блок из недавно использованных
};

const PagesAddBlockController = (props: Props) => {
    const PagesService = container.get("PagesService");

    const { isNewItem = false, isRecent = false } = props;

    const { popout, pages, location, urlParams } = useContext(Context);

    const page_id = location.getPageId();
    const activePage = pages.getPage(page_id);
    const blockInsertAfter = pages.getCurrentEditableBlock();

    const navigateToEditPage = () => {
        setHash({
            list: "pages",
            view: `pages${isNewItem ? "" : "-edit"}`,
            panel: "item",
            page_id: activePage.id,
        });
    };

    /**
     * Добавление нового блока в конец страницы
     */
    const addBlock = async (block: any) => {
        popout.loading();

        const response = await PagesService.addBlock(activePage.id, block);

        popout.close();

        if (response.result === "success") {
            pages.pushBlock(activePage.id, response.data, () => {
                pages.setAvailablePageStates(response.states, () => {
                    pages.setRecentBlocks(response.recent_blocks);
                    navigateToEditPage();
                });
            });
        } else {
            popout.error(response.message);
        }
    };

    /**
     * Вставка нового блока после другого блока
     */
    const insertBlock = async (block: any) => {
        popout.loading();

        const response = await PagesService.insertBlock(
            activePage.id,
            block,
            blockInsertAfter
        );

        popout.close();

        if (response.result === "success") {
            const payload = {
                blocks: response.data.blocks,
                newBlock: response.data.new_block,
                recentBlocks: response.recent_blocks,
                states: response.states,
            };

            pages.insertBlock(activePage.id, payload, () => {
                navigateToEditPage();
            });
        } else {
            popout.error(response.message);
        }
    };

    const handleBlockSelect = async (block, source = "common") => {
        if (blockInsertAfter && pages.getAddBlockMode() === "insert") {
            insertBlock(block);
        } else {
            addBlock(block);
        }
    };

    const getRecentBlocks = () => {
        const recentBlocks = pages.getRecentBlocks();
        let allBlocks = {};

        Object.keys(PagesTemplates.types).forEach((type) => {
            PagesTemplates.types[type].children.forEach((block) => {
                if (PagesBlocks[type] && PagesBlocks[type][block.key]) {
                    allBlocks[block.key] = {
                        ...block,
                        type: type,
                        data: PagesBlocks[type][block.key],
                    };
                }
            });
        });

        const blocks = [];

        recentBlocks.forEach((b) => {
            if (allBlocks[b.key]) {
                blocks.push(allBlocks[b.key]);
            }
        });

        return blocks;
    };

    const withMetrics = (
        handler: CallableFunction,
        source: string = "common"
    ): CallableFunction => {
        return (value) => {
            Analytics.goal(PAGES_BLOCK_ADD, "pages");

            if (source === "recent") {
                Analytics.goal(PAGES_BLOCK_ADD_RECENT, "pages");
            }

            handler(value);
        };
    };

    return (
        <>
            {isRecent ? null : (
                <BlockSelect
                    platform={urlParams.get().params.vk_platform}
                    type={location.getBlockType()}
                    onSelect={withMetrics(handleBlockSelect, "common")}
                />
            )}
        </>
    );
};

export default PagesAddBlockController;
