import React, { useContext, useReducer } from "react";
import TimerControl from "../TimerControl";
import {
    FixedLayout,
    FormLayout,
    Div,
    Checkbox,
    Separator,
    Select,
    CustomSelectOption,
} from "@vkontakte/vkui";
import FormSubmitButton from "../../FormSubmitButton";
import { validator as buttonValidator } from "../../ButtonCommon/validator";
import { Context } from "../../../../../../context";
import { Icon20ArticleBoxOutline } from "@vkontakte/icons";
import { Icon24LinkCircle } from "@vkontakte/icons";
import { Icon24PictureOutline } from "@vkontakte/icons";
import { Icon24TextOutline } from "@vkontakte/icons";
import initialState from "./state";
import reducer from "./reducer";
import FadeIn from "react-fade-in";
import "react-datepicker/dist/react-datepicker.css";
import {
    DEFAULT_OVERLAY_OPACITY_VALUE,
} from "../../../../../../includes/Constants";
import ReactSlider from "react-slider";

import DateTimeHelper from "../../../../../../includes/Helpers/DateTimeHelper";

type TimerFixedEditProps = {
    data: any;
    onSubmit: Function;
    openModal: Function;
    currentDynamicInput: string;
};

const SLIDER_STEP = 1;
const SLIDER_MIN_VALUE = 0;
const SLIDER_MAX_VALUE = 100;

const dateTimeHelper = new DateTimeHelper();

const TimerUserFirstVisitEdit = (props: TimerFixedEditProps) => {
    const { data, onSubmit, openModal, currentDynamicInput } = props;

    const { snackbar } = useContext(Context);

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        ...data,
        content: {
            ...data.content,
        },
    });

    const {
        has_button,
        has_title,
        has_background,
        has_text_after_expiration,
        content: {
            days,
            hours,
            minutes,
            seconds,
        }
    } = state;

    const handleSubmit = () => {
        if (has_button) {
            if (!state.button.text) {
                snackbar.showError("Кнопка заполнена неверно");
                return false;
            }

            const buttonValidationResult = buttonValidator(state.button);

            if (buttonValidationResult.status === false) {
                dispatch({
                    type: "set-button-errors",
                    payload: buttonValidationResult.errors,
                });
                return false;
            } else {
                dispatch({ type: "flush-button-errors" });
            }
        }

        if (has_background) {
            if (!state.background.url) {
                snackbar.showError("Изображение не выбрано");
                return false;
            }
        }

        if (has_text_after_expiration) {
            if (!state.content.text_after_expiration) {
                snackbar.showError("Введите текст, который будет отображаться после завершения таймера");
                return false;
            }
        }

        const data = { ...state };

        delete data.errors;

        onSubmit(data);
    };

    const overlayOpacity =
        state.background.overlay_opacity ||
            state.background.overlay_opacity === 0
            ? state.background.overlay_opacity
            : DEFAULT_OVERLAY_OPACITY_VALUE;

    const isFullscreen = state.meta.is_fullscreen
        ? state.meta.is_fullscreen
        : false;

    return (
        <div className="TimerEdit TimerUserFirstVisitEdit">
            <FormLayout style={{ paddingBottom: 65 }}>
                <TimerControl
                    checked={has_title}
                    title="Заголовок и описание"
                    icon={
                        <Icon20ArticleBoxOutline
                            width={24}
                            height={24}
                            fill="var(--vkui--color_icon_accent_themed)"
                            style={{
                                marginRight: 19,
                                opacity: has_title ? 1 : 0.4,
                            }}
                        />
                    }
                    onChange={(e) => {
                        dispatch({
                            type: "set-has-title",
                            payload: !has_title,
                        });
                    }}
                    onEditClick={() => {
                        openModal({
                            id: "text",
                            args: {
                                content: state.content,
                                currentDynamicInput: currentDynamicInput,
                                callback: (data) => {
                                    dispatch({
                                        type: "edit-text",
                                        payload: data,
                                    });
                                },
                            },
                        });
                    }}
                />

                <TimerControl
                    checked={has_button}
                    title="Кнопка"
                    icon={
                        <Icon24LinkCircle
                            fill="var(--vkui--color_icon_accent_themed)"
                            style={{
                                marginRight: 19,
                                opacity: has_button ? 1 : 0.4,
                            }}
                        />
                    }
                    onChange={(e) => {
                        dispatch({
                            type: "set-has-button",
                            payload: !has_button,
                        });
                    }}
                    onEditClick={() => {
                        openModal({
                            id: "button",
                            args: {
                                timerFixedEdit: true,
                                currentDynamicInput: currentDynamicInput,
                                has_button: state.has_button,
                                button: state.button,
                                errors: state.errors,
                                callback: (data) => {
                                    dispatch({
                                        type: "set-button-data",
                                        payload: data,
                                    });
                                },
                                setButtonErrorsCallback: (data) => {
                                    dispatch({
                                        type: "set-button-errors",
                                        payload: data,
                                    });
                                },
                                flushButtonErrorsCallback: (data) => {
                                    dispatch({
                                        type: "flush-button-data",
                                    });
                                },
                            },
                        });
                    }}
                />

                <Separator />

                <Div
                    className="TimerEdit-settings-title"
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                >
                    Установите таймер
                </Div>

                <Div className="TimerUserFirstVisitEdit__selects" style={{ display: "flex" }}>
                    <div className="TimerUserFirstVisitEdit__select-wrapper">
                        <div className="subhead" style={{ marginBottom: 10, textAlign: 'center' }}>
                            д
                        </div>
                        <Select
                            className="TimerUserFirstVisitEdit__select"
                            placeholder={days ? days: "00"}
                            options={dateTimeHelper.getTimeRange(0, 30).map((val) => ({
                                label: val,
                                value: Number(val)
                            }))}
                            renderOption={({ option, ...restProps }) => (
                                <CustomSelectOption
                                    {...restProps}
                                    key={option.value}
                                />
                            )}
                            onChange={(e: any) => {
                                dispatch({
                                    type: "days",
                                    payload: e.target.value,
                                });
                            }}
                        />
                    </div>

                    <div className="TimerUserFirstVisitEdit__select-wrapper">
                        <div className="subhead" style={{ marginBottom: 10, textAlign: 'center' }}>
                            ч
                        </div>
                        <Select
                            className="TimerUserFirstVisitEdit__select"
                            placeholder={hours ? hours: "00"}
                            options={dateTimeHelper.getTimeRange(0, 23).map((val) => ({
                                label: val,
                                value: Number(val)
                            }))}
                            renderOption={({ option, ...restProps }) => (
                                <CustomSelectOption
                                    {...restProps}
                                    key={option.value}
                                />
                            )}
                            onChange={(e: any) => {
                                dispatch({
                                    type: "hours",
                                    payload: e.target.value,
                                });
                            }}
                        />
                    </div>

                    <div className="TimerUserFirstVisitEdit__select-wrapper">
                        <div className="subhead" style={{ marginBottom: 10, textAlign: 'center' }}>
                            мин
                        </div>
                        <Select
                            className="TimerUserFirstVisitEdit__select"
                            placeholder={minutes ? minutes: "00"}
                            options={dateTimeHelper.getTimeRange(0, 59).map((val) => ({
                                label: val,
                                value: Number(val)
                            }))}
                            renderOption={({ option, ...restProps }) => (
                                <CustomSelectOption
                                    {...restProps}
                                    key={option.value}
                                />
                            )}
                            onChange={(e: any) => {
                                dispatch({
                                    type: "minutes",
                                    payload: e.target.value,
                                });
                            }}
                        />
                    </div>

                    <div>
                        <div className="subhead" style={{ marginBottom: 10, textAlign: 'center' }}>
                            сек
                        </div>
                        <Select
                            className="TimerUserFirstVisitEdit__select"
                            placeholder={seconds ? seconds: "00"}
                            options={dateTimeHelper.getTimeRange(0, 59).map((val) => ({
                                label: val,
                                value: Number(val)
                            }))}
                            renderOption={({ option, ...restProps }) => (
                                <CustomSelectOption
                                    {...restProps}
                                    key={option.value}
                                />
                            )}
                            onChange={(e: any) => {
                                dispatch({
                                    type: "seconds",
                                    payload: e.target.value,
                                });
                            }}
                        />
                    </div>
                </Div>

                <TimerControl
                    checked={has_text_after_expiration}
                    title="Текст после завершения таймера"
                    icon={
                        <Icon24TextOutline
                            fill="var(--vkui--color_icon_accent_themed)"
                            style={{
                                marginRight: 19,
                                opacity: has_text_after_expiration ? 1 : 0.4,
                            }}
                        />
                    }
                    onChange={() => {
                        dispatch({
                            type: "set-has-text-after-expiration",
                            payload: !has_text_after_expiration,
                        });
                    }}
                    onEditClick={() => {
                        openModal({
                            id: "textarea",
                            args: {
                                title: 'Текст после завершения таймера',
                                content: {
                                    text: state.content.text_after_expiration
                                },
                                currentDynamicInput: currentDynamicInput,
                                callback: (data) => {
                                    dispatch({
                                        type: "set-text-after-expiration",
                                        payload: data,
                                    });
                                },
                            },
                        });
                    }}
                />

                <TimerControl
                    checked={has_background}
                    title="Фоновое изображение"
                    icon={
                        <Icon24PictureOutline
                            fill="var(--vkui--color_icon_accent_themed)"
                            style={{
                                marginRight: 19,
                                opacity: has_background ? 1 : 0.4,
                            }}
                        />
                    }
                    onChange={(e) => {
                        dispatch({
                            type: "set-has-background",
                            payload: !has_background,
                        });
                    }}
                    onEditClick={() => {
                        openModal({
                            id: "landing-img",
                            args: {
                                size: [1920, 1220],
                                callback: (data) => {
                                    dispatch({
                                        type: "background",
                                        payload: data,
                                    });
                                },
                            },
                        });
                    }}
                />

                {has_background && (
                    <FadeIn>
                        <Div style={{ paddingBottom: 0 }}>
                            <div className="subhead">Изображение</div>
                            <div className="GroupBannersForm__banner GroupBannersForm__banner_filepicker B-TimerEdit">
                                {!!state.background &&
                                    !!state.background.url ? (
                                    <>
                                        <img
                                            alt=""
                                            src={state.background.url}
                                        />{" "}
                                        <div
                                            style={{
                                                background: `rgba(0, 0, 0, ${overlayOpacity}%)`,
                                            }}
                                            className="GroupBannersForm__overlay"
                                        ></div>
                                    </>
                                ) : (
                                    <span>Изображение не найдено</span>
                                )}
                            </div>
                            <div className="subhead">
                                Рекомендуемый размер изображения 1920x1220
                            </div>
                        </Div>
                        <Div style={{ paddingTop: 0, paddingBottom: 4 }}>
                            <div style={{ marginTop: 20 }}>
                                <div className="subhead">
                                    {overlayOpacity}% заливка
                                </div>
                                <ReactSlider
                                    step={SLIDER_STEP}
                                    min={SLIDER_MIN_VALUE}
                                    max={SLIDER_MAX_VALUE}
                                    value={overlayOpacity}
                                    className="ReactSlider"
                                    trackClassName="ReactSlider__sliderTrack_line"
                                    minDistance={10}
                                    onChange={(value: number) => {
                                        dispatch({
                                            type: "set-overlay-opacity",
                                            payload: value,
                                        });
                                    }}
                                    renderThumb={(props) => (
                                        <div
                                            {...props}
                                            className="ReactSlider__sliderThumb"
                                        ></div>
                                    )}
                                />
                            </div>
                        </Div>
                    </FadeIn>
                )}

                <Checkbox
                    className="CoverEdit__checkbox"
                    checked={isFullscreen}
                    onChange={(e) => {
                        dispatch({
                            type: "set-is-fullscreen",
                            payload: e.currentTarget.checked,
                        });
                    }}
                >
                    На всю высоту экрана{" "}
                    <span className="subhead">(для мобильных устройств)</span>
                </Checkbox>

                <FixedLayout filled={true} vertical={"bottom"}>
                    <FormSubmitButton handleSubmit={handleSubmit} />
                </FixedLayout>
            </FormLayout>
        </div>
    );
};

export default TimerUserFirstVisitEdit;
