import React, { useEffect, useState } from "react";
import {
    List,
    Cell,
    CellButton,
    Search,
    Spinner,
    Div,
    Footer,
    platform,
    Platform,
} from "@vkontakte/vkui";
import ApiVk from "../../../includes/ApiVk";
import { USER_TOKEN_REQUEST_FAILED } from "../../../includes/ErrorMessages";
import { Icon24Done } from "@vkontakte/icons";

const initialCities = [];

/**
 * Пагинация для запросов к апи вк
 */
let count = 100;

const CitySelect = (props) => {
    const api = ApiVk.getInstance(null);

    const { cities, setCities } = props;

    const [search, setSearch] = useState("");
    const [requestTimeout, setRequestTimeout] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [bottomText, setBottomText] = useState("");
    const [offset, setOffset] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedCities, setSelectedCities] = useState(
        props.currentCity && props.currentCity.length > 0
            ? props.currentCity
            : []
    );

    const getCities = async (
        query,
        countryId,
        req_offset = 0,
        req_count,
        loadMore = false
    ) => {
        setIsPending(true);

        let result = await api.getCities(
            query,
            countryId,
            req_offset,
            req_count
        );

        if (result.response && result.response.items) {
            const { items, count } = result.response;

            setCities(loadMore ? [...cities, ...items] : items);

            if (count > items.length) {
                setTotalCount(count);
            }

            if (items.length <= 0) {
                setBottomText("По вашему запросу ничего не найдено");
            } else {
                setBottomText("");
            }
        } else if (result.result === "error") {
            setBottomText(USER_TOKEN_REQUEST_FAILED);
        } else {
            setBottomText("Произошла ошибка. Попробуйте позднее.");
            setBottomText("");
        }

        setIsPending(false);
    };

    const loadMore = async () => {
        let newOffset = offset + count;
        setOffset(newOffset);
        getCities(search.trim(), props.countryId, newOffset, count, true);
    };

    useEffect(() => {
        let query = search.trim();

        setOffset(0);
        setTotalCount(0);

        if (requestTimeout) {
            clearTimeout(requestTimeout);
        }

        if (query.length < 1) {
            setCities(initialCities);
            return;
        }

        /**
         * Откладываем запрос чтобы предотвратить отправку на каждое нажатие кнопки
         */
        const newTimeout = setTimeout(() => {
            getCities(query, props.countryId, 0, count);
        }, 1000);

        setRequestTimeout(newTimeout);
    }, [search]);

    useEffect(() => {
        const input: any = document.querySelector(".vkuiSearch__input");
        if (input && platform() === Platform.ANDROID) {
            input.focus();
        }
    }, []);

    const addSelectedCity = (city) => {
        const newSelectedCities = [...selectedCities];
        newSelectedCities.push(city);
        setSelectedCities(newSelectedCities);
        props.onSelect(newSelectedCities);
    };

    const removeSelectedCity = (city) => {
        const newSelectedCities = [...selectedCities].filter(
            (c) => c.id !== city.id
        );
        setSelectedCities(newSelectedCities);
        props.onSelect(newSelectedCities);
    };

    const discardCities = () => {
        setSelectedCities([]);
        props.onSelect([]);
    };

    const isSelected = (city) => {
        return selectedCities.filter((c) => c.id === city.id).length > 0;
    };

    return (
        <>
            <Search
                onChange={(e) => {
                    setSearch(e.currentTarget.value);
                }}
                autoFocus={true}
                style={{ background: "transparent" }}
                after=""
            />
            <List>
                <Cell
                    key="city-null"
                    onClick={discardCities}
                    subtitle="Не учитывать город"
                    after={
                        selectedCities.length === 0 && (
                            <Icon24Done fill="var(--vkui--color_text_accent)" />
                        )
                    }
                >
                    Любой
                </Cell>
                {cities.map((city) => {
                    let selected = isSelected(city);
                    return (
                        <Cell
                            key={city.id}
                            onClick={() => {
                                if (selected) {
                                    removeSelectedCity(city);
                                } else {
                                    addSelectedCity(city);
                                }
                            }}
                            subtitle={city.region}
                            after={
                                selected && <Icon24Done fill="var(--vkui--color_text_accent)" />
                            }
                        >
                            {city.title}
                        </Cell>
                    );
                })}
                {totalCount > 0 && totalCount > cities.length ? (
                    <CellButton onClick={loadMore}>Показать еще</CellButton>
                ) : null}
            </List>
            <Div>{isPending && <Spinner size="medium" />}</Div>
            {bottomText !== "" && (
                <Footer style={{ height: 40 }}>{bottomText}</Footer>
            )}
        </>
    );
};

export default CitySelect;
