import React from "react";
import { Snackbar } from "@vkontakte/vkui";
import { Icon28ErrorOutline } from "@vkontakte/icons";

type RepeatActionPropsType = {
    btnText?: string;
    message?: string;
    duration?: number;
    action?: Function;
};

const RepeatAction = (props: RepeatActionPropsType) => {
    const {
        btnText = "Повторить",
        message = "Ошибка соединения",
        action = () => {},
        duration = 5000,
    } = props;

    return (
        <Snackbar
            action={btnText}
            duration={duration}
            before={<Icon28ErrorOutline fill="var(--vkui--color_accent_red)" />}
            onActionClick={() => {
                action();
            }}
            onClose={() => {
                action();
            }}
        >
            {message}
        </Snackbar>
    );
};

export default RepeatAction;
