import React, { useEffect, useState } from "react";
import { Div, Button, Headline } from "@vkontakte/vkui";
import {
    VARIABLES,
    PROMO_PAGES_VARIABLES,
    APP_SETTINGS_VARIABLES,
    GENDER_VAR_REGEXP,
} from "../../includes/Constants";
import { Variable, VariableType } from "../../includes/Structures";
import FadeIn from "react-fade-in";
import VariablesSelect from "./Selects/VariablesSelect";
import VariablesService from "../../includes/Services/VariablesService";

export type VariablesTypes = "widgets" | "app-settings" | "pages";

declare type VariablesProps = {
    onSelect: Function;
    mode?: VariablesTypes; // Отделяет переменные для виджетов от переменных для общих настроек приложения
    variablesService?: VariablesService;
};

const Variables = (props: VariablesProps) => {
    const {
        onSelect,
        mode = "widgets",
        variablesService = {
            async getGlobalVariables() {},
            async getLeadVariables() {},
        },
    } = props;

    const [globalVariables, setGlobalVariables] = useState([]);
    const [leadVariables, setLeadVariables] = useState([]);

    const loadGlobalVariables = async () => {
        try {
            let resp = await variablesService.getGlobalVariables();

            const filteredVarsForReactSelect = resp.items.map((v) => {
                return {
                    label: v.n,
                    value: v.v,
                };
            });

            setGlobalVariables(filteredVarsForReactSelect);
        } catch (err) {
            console.log(err);
        }
    };

    const loadLeadVariables = async () => {
        try {
            let resp = await variablesService.getLeadVariables();

            const filteredVarsForReactSelect = resp.items.map((v) => {
                return {
                    label: v.n,
                    value: v.v,
                };
            });

            setLeadVariables(filteredVarsForReactSelect);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (mode === "pages") {
            loadGlobalVariables();
            loadLeadVariables();
        }
    }, []);

    const renderTypes = () => {
        if (mode === "widgets") {
            return VARIABLES.map((type: VariableType, index) => {
                return (
                    <div
                        key={index}
                        className="Variables__row"
                        style={{ marginBottom: 5 }}
                    >
                        <Headline
                            level="2"
                            weight="1"
                            style={{
                                marginBottom: 7,
                                fontSize: 14,
                            }}
                        >
                            {type.name}
                        </Headline>

                        <div className="Variables__row-buttons">
                            {type.value.map((variable: Variable) => {
                                return renderVariable(variable);
                            })}
                        </div>
                    </div>
                );
            });
        } else if (mode === "pages") {
            return PROMO_PAGES_VARIABLES.map((type: VariableType, index) => {
                return (
                    <div
                        key={index}
                        className="Variables__row"
                        style={{ marginBottom: 5 }}
                    >
                        <Headline
                            level="2"
                            weight="1"
                            style={{
                                marginBottom: 7,
                                fontSize: 14,
                            }}
                        >
                            {type.name}
                        </Headline>

                        <div
                            className="Variables__row-buttons"
                            style={{ marginBottom: 5 }}
                        >
                            {type.value.map((variable: Variable) => {
                                return renderVariable(variable);
                            })}
                        </div>

                        <div style={{ marginBottom: 13 }}>
                            <VariablesSelect
                                label="Глобальные"
                                variables={globalVariables}
                                onChange={(v) => {
                                    onSelect(`[%${v.label}%]`);
                                }}
                            />
                        </div>

                        <div style={{ marginBottom: 8 }}>
                            <VariablesSelect
                                label="Пользовательские"
                                variables={leadVariables}
                                onChange={(v) => {
                                    onSelect(`{%${v.label}%}`);
                                }}
                            />
                        </div>
                    </div>
                );
            });
        } else {
            throw new Error(`Переменные: тип ${mode} не поддерживается`);
        }
    };

    const renderVariable = (variable) => {
        return (
            <Button
                key={`var-${variable.value}`}
                mode="secondary"
                onClick={() => {
                    if (mode === "widgets") {
                        if (variable.value.match(GENDER_VAR_REGEXP)) {
                            onSelect(variable.value);
                        } else {
                            onSelect(`{${variable.value}}`);
                        }
                    } else {
                        onSelect(variable.value);
                    }
                }}
            >
                {variable.name}
            </Button>
        );
    };

    const renderAppSettingsVariables = () => {
        return (
            <div className="Variables__row" style={{ marginBottom: 5 }}>
                {APP_SETTINGS_VARIABLES.map((variable) => {
                    return (
                        <Button
                            key={`var-${variable.value}`}
                            mode="secondary"
                            onClick={() => {
                                onSelect(variable.value);
                            }}
                        >
                            {variable.name}
                        </Button>
                    );
                })}
            </div>
        );
    };

    if (mode === "app-settings") {
        return (
            <Div>
                <div className="Variables">
                    <div className="Variables__row-buttons">
                        {renderAppSettingsVariables()}
                    </div>
                </div>
            </Div>
        );
    }

    return (
        <Div>
            <div className="Variables">
                <FadeIn>{renderTypes()}</FadeIn>
            </div>
        </Div>
    );
};

export default Variables;
