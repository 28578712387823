import React, { useContext, useState } from "react";
import {
    CustomSelectOption,
    FormItem,
    Select,
} from "@vkontakte/vkui";
import container from "../../../../../container";
import { Context } from "../../../../../context";

const BotSelect = (props: any) => {
    const { isInModal, errors, state, dispatch, botsList } = props;

    const { snackbar } = useContext(Context);

    const botService = container.get("BotService");

    const [isFetchingBotSteps, setIsFetchingBotSteps] = useState(false);
    const [botSteps, setBotSteps] = useState([state.button.step]);

    const fetchBotSteps = async () => {
        try {
            setIsFetchingBotSteps(true);

            const response = await botService.getBotSteps({
                bot_id: state.button.bot_id
            });

            if (response.success) {
                const steps = [];

                response.items.forEach((item) => {
                    let stepKey = ''

                    if (item.key && item.index !== '') {
                        stepKey = `${item.key}-${item.index}`
                    }

                    const stepForSelect = {
                        label: `${stepKey} | ${item.name}`,
                        value: item.id,
                    };

                    steps.push(stepForSelect);
                });

                setBotSteps(steps);
            } else {
                snackbar.showError("Возникла ошибка при получении шагов бота");
            }

            setIsFetchingBotSteps(false);
        } catch (error) {
            setIsFetchingBotSteps(false);
            snackbar.showError("Возникла ошибка при получении шагов бота");
        }
    };

    const botsSelectOptions = React.useMemo(() => {
       const options =  botsList.map(bot => {
            let botStatus = "";

            if (bot.disabled) {
                botStatus = "удален";
            } else if (bot.active) {
                botStatus = "активен";
            } else {
                botStatus = "не активен";
            }

            return {
                label: `${bot.key} | ${bot.name} (${botStatus})`,
                value: bot.id,
                botStatus
            }
        })

        return options
    }, [])

    return (
        <div style={{ marginBottom: !isInModal ? 67 : 12 }}>
            <FormItem
                top="Выберите бота"
                status={errors.bot_id ? "error" : "default"}
            >
                <Select
                    placeholder="Выберите бота"
                    options={botsSelectOptions}
                    value={state.button.bot_id ? state.button.bot_id : 0}
                    renderOption={({ option, ...restProps }) => (
                        <CustomSelectOption
                            {...restProps}
                            key={option.value}
                        />
                    )}
                    onChange={(e) => {
                        dispatch({
                            type: "set-button-bot-id",
                            payload: e.currentTarget.value,
                        });

                        dispatch({
                            type: "set-button-step",
                            payload: {
                                value: '',
                                label: '',
                            },
                        });

                        setBotSteps([])
                    }}
                    searchable
                />
            </FormItem>

            <FormItem
                top="Выберите шаг"
                status={errors.step_id ? "error" : "default"}
            >
                <Select
                    placeholder="Выберите шаг"
                    fetching={isFetchingBotSteps}
                    options={botSteps}
                    value={
                        state.button.step.value ? state.button.step.value : 0
                    }
                    renderOption={({ option, ...restProps }) => (
                        <CustomSelectOption
                            {...restProps}
                            key={option.value}
                        />
                    )}
                    onChange={(e) => {
                        const selectedStep = botSteps.find(
                            (s) => s.value === e.currentTarget.value
                        );

                        dispatch({
                            type: "set-button-step",
                            payload: {
                                value: e.currentTarget.value,
                                label: selectedStep.label,
                            },
                        });
                    }}
                    onOpen={fetchBotSteps}
                    searchable
                />
            </FormItem>
        </div>
    );
};

export default BotSelect;
