import React, { useReducer, useState } from "react";
import {
    FormLayout,
    Group,
    Div,
    FixedLayout,
    Button,
    Cell,
    List,
    Separator,
    Avatar,
} from "@vkontakte/vkui";
import VkLogo from "../../../../Elements/VkLogo";
import initialState from "./state";
import reducer from "./reducer";
import FormSubmitButton from "../FormSubmitButton";
import TimerControl from "../Timer/TimerControl";
import { Icon20ArticleBoxOutline, Icon20DeleteOutline, Icon24Add } from "@vkontakte/icons";

type ReviewsEditProps = {
    data: any;
    currentDynamicInput: string;
    onSubmit: Function;
    openModal: Function;
    maxItemsCount: number; // Максимальное возможное количество блоков
};

const ReviewsEdit = (props: ReviewsEditProps) => {
    const { data, currentDynamicInput, onSubmit, openModal, maxItemsCount } =
        props;

    const [state, dispatch] = useReducer(reducer, { ...initialState, ...data });
    const [editMode, setEditMode] = useState(false);

    const { has_title } = state;

    const isAllowAdd = state.items.length < maxItemsCount;

    const handleSubmit = () => {
        onSubmit(state);
    };

    const renderItems = () => {
        return state.items.map((item, index) => {
            return (
                <Cell
                    title="Редактировать отзыв"
                    key={index}
                    className="BlockEdit__review-item"
                    style={{ paddingLeft: 0 }}
                    draggable={editMode}
                    mode={editMode ? 'removable': undefined}
                    removePlaceholder={<Icon20DeleteOutline />}
                    multiline={true}
                    subtitle={item.text ? item.text : ""}
                    onClick={() => {
                        openModal({
                            id: "landing-review",
                            args: {
                                isEdit: true,
                                name: item.name,
                                text: item.text,
                                vkUrl: item.vkUrl ? item.vkUrl : "",
                                imgUrl: item.img ? item.img.url : "",
                                callback: (data) => {
                                    dispatch({
                                        type: "edit-item",
                                        payload: { index, data: data },
                                    });
                                },
                            },
                        });
                    }}
                    onRemove={() => {
                        dispatch({ type: "remove-item", payload: { index } });
                    }}
                    onDragFinish={({ from, to }) => {
                        dispatch({ type: "sort-items", payload: { from, to } });
                    }}
                    before={
                        <Avatar
                            size={48}
                            src={
                                item.img
                                    ? item.img.url
                                    : "https://i.yapx.ru/IkYec.png"
                            }
                        />
                    }
                >
                    <div className="B-Reviews__name">
                        <span>{item.name}</span>{" "}
                        {item.vkUrl && (
                            <span>
                                <VkLogo />
                            </span>
                        )}
                    </div>
                </Cell>
            );
        });
    };

    const renderAddItemButton = () => {
        return (
            <div
                onClick={() => {
                    openModal({
                        id: "landing-review",
                        args: {
                            isEdit: false,
                            callback: (data) => {
                                dispatch({
                                    type: "add-item",
                                    payload: { data: data },
                                });
                            },
                        },
                    });
                }}
                className="BlockEdit__item-add"
            >
                <span>Добавить отзыв</span>
                <span>
                    <Icon24Add width={18} />
                </span>
            </div>
        );
    };

    return (
        <FormLayout style={{ paddingBottom: 100 }}>
            <TimerControl
                checked={has_title}
                title="Заголовок и описание"
                icon={
                    <Icon20ArticleBoxOutline
                        width={24}
                        height={24}
                        fill="var(--vkui--color_icon_accent_themed)"
                        style={{
                            marginRight: 19,
                            opacity: has_title ? 1 : 0.4,
                        }}
                    />
                }
                onChange={() => {
                    dispatch({
                        type: "set-has-title",
                        payload: !has_title,
                    });
                }}
                onEditClick={() => {
                    openModal({
                        id: "text",
                        args: {
                            content: state.content,
                            currentDynamicInput: currentDynamicInput,
                            callback: (data) => {
                                dispatch({
                                    type: "edit-text",
                                    payload: data,
                                });
                            },
                        },
                    });
                }}
            />

            <Separator />

            <Div style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Div style={{ paddingTop: 0 }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                            className="subhead"
                        >
                            <span>
                                Отзывы {state.items.length} из {maxItemsCount}
                            </span>
                            <Button
                                style={{ marginRight: 0 }}
                                onClick={() => {
                                    setEditMode(!editMode);
                                }}
                                mode="secondary"
                            >
                                {editMode ? "Готово" : "Править"}
                            </Button>
                        </div>
                    </Div>

                    <div className="B-Reviews_edit">
                        <Group>
                            <List>{renderItems()}</List>
                        </Group>
                        {false === editMode && isAllowAdd
                            ? renderAddItemButton()
                            : null}
                    </div>
                </div>
            </Div>

            {false === editMode && (
                <FixedLayout filled={true} vertical={"bottom"}>
                    <FormSubmitButton handleSubmit={handleSubmit} />
                </FixedLayout>
            )}
        </FormLayout>
    );
};

export default ReviewsEdit;
