import React from "react";
import { FixedLayout, Button, Div } from "@vkontakte/vkui";
import { BlockData } from "../../includes/Structures";

import Page from "./Page";
import { Icon28ShareOutline } from "@vkontakte/icons";

type PagesPreviewProps = {
    blocks: BlockData[];
    isAllowPublish: boolean;
    type: string;
    platform: string;
    onPublishClick: Function;
    resizer: any;
};

const PagesPreviewItem = (props: PagesPreviewProps) => {
    const { blocks, type, isAllowPublish, platform, onPublishClick, resizer } =
        props;

    const renderPublishButton = () => {
        return (
            <FixedLayout
                style={{
                    boxShadow:
                        "0 2px 24px 0 rgba(0,0,0,.08), 0 0 2px 0 rgba(0,0,0,.08)",
                }}
                filled={true}
                vertical={"bottom"}
            >
                <Button
                    onClick={
                        isAllowPublish
                            ? () => {
                                  onPublishClick();
                              }
                            : () => {}
                    }
                    mode="tertiary"
                    stretched={true}
                    size="l"
                    className={`${isAllowPublish ? "" : "disabled"}`}
                >
                    <Div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <span>
                                <Icon28ShareOutline
                                    width={24}
                                    style={{ marginRight: 8 }}
                                />
                            </span>
                            <span>Опубликовать</span>
                        </div>
                        {isAllowPublish && (
                            <div className="PagesPreview__caption subhead">
                                <span className="PagesPreview__caption-indicator"></span>
                                Есть неопубликованные изменения
                            </div>
                        )}
                    </Div>
                </Button>
            </FixedLayout>
        );
    };

    return (
        <div style={{ paddingBottom: 100 }}>
            <Page
                resizer={resizer}
                platform={platform}
                blocks={blocks.filter((b) => b.status === 1)}
                isPreview
            />
            {type === "edit" ? renderPublishButton() : null}
        </div>
    );
};

export default PagesPreviewItem;
