import React, { useReducer, useState } from "react";
import {
    FormLayout,
    Group,
    Div,
    FixedLayout,
    Button,
    Cell,
    List,
    Separator,
    Avatar,
} from "@vkontakte/vkui";
import FormSubmitButton from "../FormSubmitButton";
import initialState from "./state";
import reducer from "./reducer";
import vkIcons from "../../../../Elements/vkIcons";
import TimerControl from "../Timer/TimerControl";
import { Icon20ArticleBoxOutline, Icon20DeleteOutline, Icon24Add } from "@vkontakte/icons";

type AdvantagesEditProps = {
    data: any;
    currentDynamicInput: string;
    onSubmit: Function;
    openModal: Function;
    maxItemsCount: number; // Максимальное возможное количество блоков
};

const AdvantagesEdit = (props: AdvantagesEditProps) => {
    const { data, currentDynamicInput, onSubmit, openModal, maxItemsCount } =
        props;

    const [state, dispatch] = useReducer(reducer, { ...initialState, ...data });
    const [editMode, setEditMode] = useState(false);

    const { has_title } = state;

    const isAllowAdd = state.items.length < maxItemsCount;

    const handleSubmit = () => {
        onSubmit(state);
    };

    const renderItems = () => {
        return state.items.map((item, index) => {
            return (
                <Cell
                    key={index}
                    style={{ paddingLeft: 0 }}
                    draggable={editMode}
                    mode={editMode ? 'removable': undefined}
                    removePlaceholder={<Icon20DeleteOutline />}
                    multiline={true}
                    subtitle={item.text ? item.text : ""}
                    onClick={() => {
                        openModal({
                            id: "landing-advantage",
                            args: {
                                isEdit: true,
                                title: item.title,
                                text: item.text,
                                imgUrl: item.img ? item.img.url : "",
                                svgIconId: item.svg_icon_id
                                    ? item.svg_icon_id
                                    : null,
                                svgColor: item.svg_icon_color
                                    ? item.svg_icon_color
                                    : "var(--vkui--color_text_accent)",
                                svgBgColor: item.svg_icon_bg_color
                                    ? item.svg_icon_bg_color
                                    : "var(--vkui--color_write_bar_icon)",
                                itemRadius:
                                    item.item_radius || item.item_radius === 0
                                        ? item.item_radius
                                        : 100,
                                svgIcons: vkIcons,
                                callback: (data) => {
                                    dispatch({
                                        type: "edit-item",
                                        payload: { index, data },
                                    });
                                },
                            },
                        });
                    }}
                    onRemove={() => {
                        dispatch({ type: "remove-item", payload: { index } });
                    }}
                    onDragFinish={({ from, to }) => {
                        dispatch({ type: "sort-items", payload: { from, to } });
                    }}
                    before={
                        <div
                            className="ModalAdvantage__imgOrIcon"
                            style={{
                                borderRadius:
                                    item.item_radius || item.item_radius === 0
                                        ? `${item.item_radius / 2}%`
                                        : `${100 / 2}%`,
                            }}
                        >
                            <Avatar
                                style={{
                                    background: item.svg_icon_bg_color
                                        ? item.svg_icon_bg_color
                                        : "var(--vkui--color_write_bar_icon)",
                                }}
                                size={48}
                                src={
                                    item.img && !item.svg_icon_id
                                        ? item.img.url
                                        : ""
                                }
                            >
                                {item.svg_icon_id ? (
                                    <div
                                        style={{
                                            color: item.svg_icon_color
                                                ? item.svg_icon_color
                                                : "var(--vkui--color_text_accent)",
                                        }}
                                    >
                                        {vkIcons[item.svg_icon_id - 1].icon}
                                    </div>
                                ) : null}
                            </Avatar>
                        </div>
                    }
                >
                    {item.title}
                </Cell>
            );
        });
    };

    const renderAddItemButton = () => {
        return (
            <div
                onClick={() => {
                    openModal({
                        id: "landing-advantage",
                        args: {
                            isEdit: true,
                            title: "",
                            text: "",
                            imgUrl: "",
                            itemRadius: 100,
                            svgIcons: vkIcons,
                            svgColor: "var(--vkui--color_text_accent)",
                            svgBgColor: "var(--vkui--color_write_bar_icon)",
                            callback: (data) => {
                                dispatch({
                                    type: "add-item",
                                    payload: { data: data },
                                });
                            },
                        },
                    });
                }}
                className="BlockEdit__item-add"
            >
                <span>Добавить преимущество</span>
                <span>
                    <Icon24Add width={18} />
                </span>
            </div>
        );
    };

    return (
        <FormLayout style={{ paddingBottom: 100 }}>
            <TimerControl
                checked={has_title}
                title="Заголовок и описание"
                icon={
                    <Icon20ArticleBoxOutline
                        width={24}
                        height={24}
                        fill="var(--vkui--color_icon_accent_themed)"
                        style={{
                            marginRight: 19,
                            opacity: has_title ? 1 : 0.4,
                        }}
                    />
                }
                onChange={(e) => {
                    dispatch({
                        type: "set-has-title",
                        payload: !has_title,
                    });
                }}
                onEditClick={() => {
                    openModal({
                        id: "text",
                        args: {
                            content: state.content,
                            currentDynamicInput: currentDynamicInput,
                            callback: (data) => {
                                dispatch({
                                    type: "edit-text",
                                    payload: data,
                                });
                            },
                        },
                    });
                }}
            />

            <Separator />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        className="subhead"
                    >
                        <span>Преимущества</span>
                        <Button
                            style={{ marginRight: 0 }}
                            onClick={() => {
                                setEditMode(!editMode);
                            }}
                            mode="secondary"
                        >
                            {editMode ? "Готово" : "Править"}
                        </Button>
                    </div>
                </Div>

                <div>
                    <Group>
                        <List>{renderItems()}</List>
                    </Group>
                    {false === editMode && isAllowAdd
                        ? renderAddItemButton()
                        : null}
                </div>
            </div>

            {false === editMode && (
                <FixedLayout filled={true} vertical={"bottom"}>
                    <FormSubmitButton handleSubmit={handleSubmit} />
                </FixedLayout>
            )}
        </FormLayout>
    );
};

export default AdvantagesEdit;
