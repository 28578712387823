import React, { ReactElement, useEffect } from "react";

import {
    Button,
    PanelHeader,
    PanelHeaderButton,
    platform,
    Platform,
} from "@vkontakte/vkui";
import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel";

import { ReactComponent as Logo } from "../resources/logo.svg";

import { LaunchParams } from "../includes/Structures";

import "pure-react-carousel/dist/react-carousel.es.css";
import { Icon24Back, Icon28ChevronBack } from "@vkontakte/icons";

interface InitialSettingsProps {
    currentSlide: number;
    params: LaunchParams;
    next: Function; // Показать следующий слайд
    prev: Function; // Показать предыдущий слайд
    changeSlide: Function;
    resolveContextTooltip?: Function; // Закрыть тултип над пунктом меню
    resolveContextNotify?: Function; // Закрыть значок уведомления над пунктом меню
}

interface InitialSettingsSlide {
    title: string;
    text?: ReactElement;
    img: ReactElement;
    buttonPrimary?: ReactElement;
    buttonSecondary?: any;
}

const InitialSettings = (props: InitialSettingsProps) => {
    const { next, prev, changeSlide, resolveContextTooltip, resolveContextNotify, params } =
        props;

    let currentSlide = props.currentSlide ? props.currentSlide : 0;
    let messagesSettingsUrl = `https://vk.com/club${params.vk_group_id}?act=messages`;
    let botsSettingsUrl = `https://vk.com/club${params.vk_group_id}?act=messages&tab=bots`;

    const slides: InitialSettingsSlide[] = [
        {
            title: "Начало работы",
            img: <Logo />,
            buttonPrimary: (
                <Button
                    className="S-Button S-Button_primary"
                    onClick={(e) => {
                        next && next();
                    }}
                >
                    Далее
                </Button>
            ),
        },
        {
            title: "Сообщения сообщества",
            text: (
                <div>
                    Перейдите в управление сообществом ВКонтакте, в разделе{" "}
                    <b>Сообщения,</b>
                    <br /> напротив сообщений сообщества <br /> установить
                    статус <b>Включены.</b>
                </div>
            ),
            img: <img alt="" src="img/initial-settings-messages.png" />,
            buttonSecondary: (
                <a
                    className="S-Button S-Button_secondary"
                    href={messagesSettingsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Перейти в настройки
                </a>
            ),
            buttonPrimary: (
                <Button
                    className="S-Button S-Button_primary"
                    onClick={(e) => {
                        next && next();
                    }}
                >
                    Далее
                </Button>
            ),
        },
        {
            title: "Настройки для бота",
            text: (
                <div style={{ paddingBottom: 10 }}>
                    Чтобы к сообщениям можно было <br /> добавлять кнопки
                    перейдите в <br /> <b>Настройки для бота</b> и напротив{" "}
                    <br /> возможностей ботов установить статус <br />{" "}
                    <b>Включены.</b>
                </div>
            ),
            img: <img alt="" src="img/initial-settings-bots.png" />,
            buttonPrimary: (
                <Button
                    className="S-Button S-Button_primary"
                    onClick={(e) => {
                        next && next();
                    }}
                >
                    Далее
                </Button>
            ),
            buttonSecondary: (
                <a
                    className="S-Button S-Button_secondary"
                    href={botsSettingsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Перейти в настройки
                </a>
            ),
        },
        {
            title: "Группа подписчиков",
            text: (
                <div>
                    С помощью группы подписчиков можно <br /> сегминтировать
                    пользователей, <br /> создавая платные или бесплатные <br />{" "}
                    рассылки.
                </div>
            ),
            img: <img alt="" src="img/initial-settings-group.png" />
        },
    ];

    useEffect(() => {
        resolveContextTooltip && resolveContextTooltip();
        resolveContextNotify && resolveContextNotify();
    }, []);

    return (
        <>
            <PanelHeader
                before={
                    <PanelHeaderButton
                        onClick={() => {
                            window.history.back()
                        }}
                        aria-label="back"
                    >
                        {platform() === Platform.IOS ? (
                            <Icon28ChevronBack />
                        ) : (
                            <Icon24Back />
                        )}
                    </PanelHeaderButton>
                }
                fixed
            />
            <div className="InitialSettings">
                <div style={{ paddingTop: 60 }}>
                    <CarouselProvider
                        naturalSlideWidth={400}
                        naturalSlideHeight={600}
                        totalSlides={4}
                        currentSlide={currentSlide}
                        touchEnabled={false}
                        dragEnabled={false}
                    >
                        <Slider style={{ height: 420 }}>
                            {slides.map((slide, index) => {
                                return (
                                    <Slide
                                        key={index}
                                        className="InitialSettings__slide"
                                        index={index}
                                    >
                                        <div className="InitialSettings__slide-top">
                                            <div className="InitialSettings__slide-title">
                                                {slide.title}
                                            </div>
                                            <div className="InitialSettings__slide-text">
                                                {slide.text}
                                            </div>
                                        </div>

                                        <div className="InitialSettings__slide-bottom">
                                            <div className="InitialSettings__slide-img">
                                                {slide.img}
                                            </div>
                                            <div className="InitialSettings__slide-buttons">
                                                {slide.buttonSecondary ? (
                                                    <div className="InitialSettings__slide-button">
                                                        {slide.buttonSecondary}
                                                    </div>
                                                ) : null}
                                                <div className="InitialSettings__slide-button">
                                                    {slide.buttonPrimary}
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                );
                            })}
                        </Slider>
                    <div
                        style={{ marginTop: 20 }}
                        className="InitialSettings__dots"
                    >
                        {Object.keys(slides).map((key, index) => {
                            return (
                                <Dot
                                    slide={index}
                                    key={`dot-${index}`}
                                    className={`InitialSettings__dots-item ${
                                        currentSlide === index ? "active" : ""
                                    }`}
                                    onClick={() => {
                                        changeSlide && changeSlide(index)
                                    }}
                                >
                                    <></>
                                </Dot>
                            );
                        })}
                    </div>
                    </CarouselProvider>
                </div>
            </div>
        </>
    );
};

export default InitialSettings;
