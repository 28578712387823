import { clone } from "../../../../../includes/Helpers/Helpers";

export default function (state, action) {
    const newState = clone(state);

    const { type, payload } = action;

    switch (type) {
        case "change-type":
            newState.button = { ...state.button };
            newState.button.action = payload;
            return newState;

        case "change-url":
            newState.button = { ...state.button };
            newState.button.url = payload;
            return newState;

        case "text":
            newState.button = { ...state.button };
            newState.button.text = payload;
            return newState;

        case "set-unsubscribe-text":
            newState.button = { ...state.button };
            newState.button.unsubscribe_text = payload;
            return newState;

        case "set-send-trigger":
            newState.button = { ...state.button };
            newState.button.send_trigger = payload;
            return newState;

        case "set-lead-admin":
            newState.button = { ...state.button };
            newState.button.lead_admin = payload;
            return newState;

        case "set-color":
            newState.button = { ...state.button };
            newState.button.color = payload;
            return newState;

        case "set-text-color":
            newState.button = { ...state.button };
            newState.button.text_color = payload;
            return newState;

        case "set-errors":
            newState.errors = payload;
            return newState;

        case "button-subscription-id":
            newState.button = { ...state.button };
            newState.button.subscription_id = payload.subscription_id;
            newState.button.text = payload.button_text;
            return newState;

        case "set-button-bot-id":
            newState.button = { ...state.button };
            newState.button.bot_id = payload;
            return newState;

        case "set-button-step":
            newState.button = { ...state.button };
            newState.button.step = payload;
            return newState;

        case "set-hide-button":
            newState.button = { ...state.button };
            newState.button.hide_button = payload;
            return newState;

        default:
            return newState;
    }
}
