import React from "react";
import { Alert } from "@vkontakte/vkui";

type AlertDeleteCommonProps = {
    accept: () => void;
    deny: () => void;
    destructiveTitle?: string;
};

const AlertDeleteCommon = (props: AlertDeleteCommonProps) => {
    const { accept, deny, destructiveTitle = "Удалить" } = props;

    return (
        <Alert
            actionsLayout="vertical"
            actions={[
                {
                    title: destructiveTitle,
                    autoClose: false,
                    mode: "destructive",
                    action: accept,
                },
                {
                    title: "Отменить",
                    autoClose: false,
                    mode: "cancel",
                    action: deny,
                },
            ]}
            onClose={deny}
        >
            <h2>Вы уверены?</h2>
        </Alert>
    );
};

export default AlertDeleteCommon;
