import React, { useEffect, useState } from "react";
import { useTimer } from "react-compound-timer";
import Twemoji from "react-twemoji";
import { simpleTextToHtml } from "../../../includes/Helpers/TextToHtml";
import DateTimeHelper from "../../../includes/Helpers/DateTimeHelper";
import PageButton from "../../hoc/PageButton";
import PageLink from "../../hoc/PageLink";
import {
    DEFAULT_OVERLAY_OPACITY_VALUE,
} from "../../../includes/Constants";
import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../includes/Constants/PageBlockConstants";

type TimerDateProps = {
    data: any;
    isPreview?: boolean;
    blockPadding?: number;
    platform: string;
    blockAlignment?: BlockAlignment;
    textFontSize?: number;
};

const dateTimeHelper = new DateTimeHelper();

const TimerUserFirstVisit = (props: TimerDateProps) => {
    const {
        data,
        isPreview = false,
        blockPadding = 80,
        platform,
        blockAlignment = "center",
        textFontSize = DEFAULT_BLOCK_TEXT_FONT_SIZE,
    } = props;

    const backgroundStyles: any = {};

    if (data.background && data.background.url) {
        backgroundStyles.background = `url(${data.background.url})`;
        backgroundStyles.backgroundSize = "cover";
        backgroundStyles.backgroundPosition = "center";
    }

    const {
        days,
        hours,
        minutes,
        seconds
    } = data.content

    const {
        value,
        controls: { setTime, start: startTimer },
    } = useTimer({
        direction: "backward",
        startImmediately: true,
    });

    useEffect(() => {
        const individualUserBlockContext = data.individual_user_block_context || {
            first_visit_date: ''
        }

        const start = new Date(individualUserBlockContext.first_visit_date).getTime();

        const end = new Date(start);
        end.setDate(end.getDate() + Number(days))
        end.setHours(end.getHours() + Number(hours))
        end.setMinutes(end.getMinutes() + Number(minutes))
        end.setSeconds(end.getSeconds() + Number(seconds))

        const dateDifference = end.getTime() - Date.now();

        if (dateDifference > 0 && Date.now() >= start) {
            setTime(dateDifference);
            startTimer();
        } else {
            setTime(0);
        }
    }, []);

    const [isDisabledTimerBtn, setIsDisabledTimerBtn] = useState(false);

    useEffect(() => {
        /*
            Если не активна опция 'Скрывать кнопку после истечения таймера',
            то после истечения таймера делаем кнопку disabled
        */
            if (
                !data.button.hide_button &&
                value.h <= 0 &&
                value.m <= 0 &&
                value.s <= 0 &&
                value.state === 'PLAYING'
            ) {
                setIsDisabledTimerBtn(true)
            }
    }, [value])

    const renderButton = () => {
        /*
            Если активна опция 'Скрывать кнопку после истечения таймера',
            то после истечения таймера скрываем кнопку
        */
        if (
            data.button.hide_button &&
            value.h <= 0 &&
            value.m <= 0 &&
            value.s <= 0
        ) {
            return null;
        }

        if (data.button.action === "url") {
            return (
                <div
                    className={`B-Cover__button ${
                        isDisabledTimerBtn ? "disabled" : ""
                    }`}
                >
                    {getPageLink()}
                </div>
            );
        } else {
            return (
                <div
                    className="B-Cover__button"
                >
                    {getPageButton()}
                </div>
            );
        }
    };

    const renderTextAfterExpiration = () => {
        if (
            value.h <= 0 &&
            value.m <= 0 &&
            value.s <= 0
        ) {
            return (
                <div
                    className="TimerUserFirstVisit__text-after-expiration"
                    style={{ marginBottom: 30, textAlign: 'center' }}
                >
                    {data.content.text_after_expiration}
                </div>
            )
        }

        return null
    };

    const getPageLink = () => {
        return (
            <PageLink
                className="B-Button__link_product"
                isPreview={isPreview}
                id={data.button.id}
                pageId={data.page_id}
                blockId={data.id}
                url={data.button.url}
                text={data.button.text}
                color={data.button.color}
                textColor={data.button.text_color}
            />
        );
    };

    const getPageButton = () => {
        return (
            <PageButton
                isPreview={isPreview}
                pageId={data.page_id}
                blockId={data.id}
                data={data.button}
                isDisabledTimerBtn={isDisabledTimerBtn}
            />
        );
    };

    const renderTitle = () => {
        if (data.has_title) {
            return (
                <>
                    {data.content.title && (
                        <div className="B-Timer__title B-Block__title">
                            <Twemoji options={{ className: "twemoji-promo" }}>
                                {data.content.title}
                            </Twemoji>
                        </div>
                    )}
                    {data.content.text && (
                        <div className="B-Timer__text B-Block__subtitle">
                            <Twemoji options={{ className: "twemoji-promo" }}>
                                {simpleTextToHtml(data.content.text)}
                            </Twemoji>
                        </div>
                    )}
                </>
            );
        }
    };

    const setClassIfBlockEmpty = () => {
        if (!data.has_title && !data.has_button && !data.has_background) {
            return "B-Timer-empty";
        }
        return "";
    };

    const overlayOpacity =
        data.background.overlay_opacity || data.background.overlay_opacity === 0
            ? data.background.overlay_opacity
            : DEFAULT_OVERLAY_OPACITY_VALUE;

    const isFullscreen =
        data.meta && data.meta.is_fullscreen ? data.meta.is_fullscreen : false;

    return (
        <div
            className={`B-Block B-Timer ${platform} ${setClassIfBlockEmpty()} ${blockAlignment} ${
                isFullscreen && platform !== "desktop_web" ? "fullscreen" : ""
            }`}
            style={{
                fontSize: `${textFontSize}em`,
            }}
        >
            {data.has_background && data.background.url && (
                <div className="B-Timer__background" style={backgroundStyles}>
                    <div
                        style={{
                            background: `rgba(0, 0, 0, ${overlayOpacity}%)`,
                        }}
                        className="GroupBannersForm__overlay"
                    ></div>
                </div>
            )}
            <div
                className={`B-Timer__content ${
                    !data.has_background ? "B-Timer__content_bg_none" : ""
                }`}
                style={{
                    paddingTop: `${blockPadding}px`,
                    paddingBottom: `${blockPadding}px`,
                }}
            >
                {renderTitle()}
                <div className="timer timer-tiles">
                    <div className="timer__content">
                        <div className="timer__row">
                            <div className="timer__block">
                                <div className="timer__col">{value.d}</div>
                                <span>
                                    {dateTimeHelper.getDeclarationOfDays(
                                        value.d
                                    )}
                                </span>
                            </div>

                            <div className="timer__block">
                                <div className="timer__col">{value.h}</div>
                                <span>
                                    {dateTimeHelper.getDeclarationOfHours(
                                        value.h
                                    )}
                                </span>
                            </div>

                            <div className="timer__block">
                                <div className="timer__col">{value.m}</div>
                                <span>
                                    {dateTimeHelper.getDeclarationOfMinutes(
                                        value.m
                                    )}
                                </span>
                            </div>

                            <div className="timer__block">
                                <div className="timer__col">{value.s}</div>
                                <span>
                                    {dateTimeHelper.getDeclarationOfSeconds(
                                        value.s
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    data.has_text_after_expiration &&
                    data.content.text_after_expiration &&
                    renderTextAfterExpiration()
                }

                {data.has_button && data.button.text && renderButton()}
            </div>
        </div>
    );
};

export default TimerUserFirstVisit;
