import React, { useState, useContext } from "react";
import {
    Button,
    Div,
    FormItem,
    FormLayout,
    Input,
    Spinner,
} from "@vkontakte/vkui";
import ApiVk from "../../../includes/ApiVk";
import { Context } from "../../../context";

const ModalClient = (props) => {
    const api = ApiVk.getInstance(null);

    const { popout } = useContext(Context);

    const [url, setUrl] = useState("");
    const [statusUrl, setStatusUrl] = useState(false);
    const [inputBottom_url, setInputBottomUrl] = useState("");

    const [isLoad, setisLoad] = useState(false);

    const getPublic = async () => {
        if (url.indexOf("vk.com") >= 0) {
            setisLoad(true);
            let group: any = url.slice(url.indexOf("vk.com/") + 7, url.length);

            if (url.indexOf("public") > 0)
                group = url.slice(url.indexOf("public") + 6, url.length);

            const resp = await api.getPublicInfo(group);

            if (resp.result && resp.result === "error") {
                setStatusUrl(true);
                setisLoad(false);
                props.close({ id: null, args: {} });
                popout.error(resp.message);
                console.log(resp);
            } else if ("response" in resp) {
                props.onSelect({
                    type: "add",
                    payload: {
                        img: resp.response[0].photo_50,
                        img_vk: `-${resp.response[0].id}`,
                        title: resp.response[0].name,
                        url: `https://vk.com/${resp.response[0].screen_name}`,
                    },
                });
                setisLoad(false);
                props.close({ id: null, args: {} });
            } else {
                setStatusUrl(true);
                setisLoad(false);
                props.close({ id: null, args: {} });
                popout.error(`Ошибка API - ${JSON.stringify(resp)}`);
                console.log(resp);
            }
        } else {
            setStatusUrl(true);
            setInputBottomUrl("Введите внутреннюю ссылку vk.com");
        }
    };

    return (
        <>
            <FormLayout>
                <FormItem
                    status={statusUrl ? "error" : null}
                    bottom={
                        statusUrl
                            ? inputBottom_url
                            : "*cсылка должна быть на сообщество"
                    }
                >
                    <Input
                        name="link"
                        type="text"
                        value={url}
                        maxLength={100}
                        onChange={(e) => {
                            setUrl(e.target.value);
                            setStatusUrl(false);
                        }}
                        placeholder="Ссылка vk.com"
                    />
                </FormItem>
                <Div>
                    <Button
                        disabled={isLoad}
                        mode="secondary"
                        size="l"
                        stretched
                        onClick={getPublic}
                    >
                        {isLoad ? <Spinner size="regular" /> : "Загрузить"}
                    </Button>
                </Div>
            </FormLayout>
        </>
    );
};

export default ModalClient;
