import React, { useState } from "react";
import { List, Cell } from "@vkontakte/vkui";
import { Icon24Done } from "@vkontakte/icons";

const RelationsSelect = (props) => {
    const { relations, selected, onSelect } = props;

    const [selectedRelations, setSelectedRelations] = useState(
        selected && selected.length > 0 ? selected : []
    );

    const discardRelations = () => {
        setSelectedRelations([]);
        onSelect && onSelect([]);
    };

    const addRelation = (rel) => {
        const newSelectedRelations = [...selectedRelations];
        newSelectedRelations.push(rel.id);
        setSelectedRelations(newSelectedRelations);
        onSelect && onSelect(newSelectedRelations);
    };

    const removeRelation = (rel) => {
        const newSelectedRelations = [...selectedRelations].filter(
            (p) => p !== rel.id
        );
        setSelectedRelations(newSelectedRelations);
        onSelect && onSelect(newSelectedRelations);
    };

    return (
        <>
            <List>
                <Cell
                    key="relation-any"
                    onClick={discardRelations}
                    after={
                        selectedRelations &&
                        selectedRelations.length === 0 && (
                            <Icon24Done fill="var(--vkui--color_text_accent)" />
                        )
                    }
                >
                    Любое
                </Cell>
                {relations.map((rel, index) => {
                    let isSelected = selectedRelations.indexOf(rel.id) >= 0;
                    return (
                        <Cell
                            key={rel.id}
                            onClick={() => {
                                if (isSelected) {
                                    removeRelation(rel);
                                } else {
                                    addRelation(rel);
                                }
                            }}
                            after={
                                isSelected && (
                                    <Icon24Done fill="var(--vkui--color_text_accent)" />
                                )
                            }
                        >
                            {rel.title}
                        </Cell>
                    );
                })}
            </List>
        </>
    );
};

export default RelationsSelect;
