import React, { useContext, useState, useMemo, useEffect } from "react";
import ReactSelect from "react-select";
import {
    Cell,
    Div,
    Group,
    NativeSelect,
    platform,
    Platform,
    Switch,
} from "@vkontakte/vkui";

import AudienceContext from "../../../../Context/AudienceContext";
import { getTimeValuesForSelect } from "../../../../includes/Helpers/Helpers";
import { timeRanges } from "../../../../includes/Constants";

const UserTime = () => {
    const { set, user_time } = useContext(AudienceContext);

    const [isUserTimeActive, setIsUserTimeActive] = useState(
        user_time.is_active
    );

    const timeValuesForSelect = useMemo(() => {
        return getTimeValuesForSelect();
    }, []);

    useEffect(() => {
        setIsUserTimeActive(user_time.is_active);
    }, [user_time.is_active]);

    const onMenuOpen = () => {
        setTimeout(() => {
            const currentElementIndex = timeValuesForSelect.findIndex(
                (el) => el.value === user_time.time
            );

            const selectedEl = document.getElementsByClassName(
                "Variables-select__option"
            )[currentElementIndex];
            if (selectedEl) {
                selectedEl.scrollIntoView({
                    behavior: "auto",
                    block: "nearest",
                    inline: "start",
                });
            }
        });
    };

    return (
        <>
            <Group style={{ marginBottom: 12 }}>
                <Cell
                    subtitle="Блок будет показываться до или после заданного времени"
                    hasHover={false}
                    hasActive={false}
                    after={
                        <Switch
                            checked={isUserTimeActive}
                            onChange={() => {
                                switch (isUserTimeActive) {
                                    case true:
                                        set("user_time", {
                                            type: user_time.type,
                                            time: user_time.time,
                                            is_active: false,
                                        });
                                        break;
                                    case false:
                                        set("user_time", {
                                            type: user_time.type,
                                            time: user_time.time,
                                            is_active: true,
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            }}
                        />
                    }
                    multiline
                >
                    Показывать по времени
                </Cell>
            </Group>

            <Div
                className="ExtraOptions__selects-wrapper"
                style={{
                    opacity: isUserTimeActive ? 1 : 0.5,
                    pointerEvents: !isUserTimeActive ? "none" : "initial",
                }}
            >
                {platform() !== Platform.IOS ? (
                    <ReactSelect
                        className="ReactSelect Variables-select"
                        classNamePrefix="Variables-select"
                        options={timeRanges}
                        cacheOptions
                        defaultOptions
                        defaultValue={{
                            label: user_time.type === "after" ? "После" : "До",
                            value: user_time.type,
                        }}
                        noOptionsMessage={() => {
                            return "Ничего не найдено";
                        }}
                        onChange={(option) => {
                            set("user_time", {
                                type: option.value,
                                time: user_time.time,
                                is_active: user_time.is_active,
                            });
                        }}
                    />
                ) : (
                    <NativeSelect
                        value={user_time.type}
                        onChange={(e) => {
                            set("user_time", {
                                type: e.target.value,
                                time: user_time.time,
                                is_active: user_time.is_active,
                            });
                        }}
                        style={{ width: '50%', marginRight: 5 }}
                        className="mt-0"
                    >
                        {timeRanges.map((timeRange) => (
                            <option
                                key={`timeRange-${timeRange.value}`}
                                value={timeRange.value}
                            >
                                {timeRange.label}
                            </option>
                        ))}
                    </NativeSelect>
                )}

                {platform() !== Platform.IOS ? (
                    <ReactSelect
                        className="ReactSelect Variables-select TimeValues-select"
                        classNamePrefix="Variables-select"
                        options={timeValuesForSelect}
                        menuPlacement="top"
                        onMenuOpen={onMenuOpen}
                        cacheOptions
                        defaultOptions
                        defaultValue={{
                            label: user_time.time,
                            value: user_time.time,
                        }}
                        value={{
                            label: user_time.time,
                            value: user_time.time,
                        }}
                        noOptionsMessage={() => {
                            return "Ничего не найдено";
                        }}
                        onChange={(option) => {
                            set("user_time", {
                                type: user_time.type,
                                time: option.value,
                                is_active: user_time.is_active,
                            });
                        }}
                    />
                ) : (
                    <NativeSelect
                        value={user_time.time}
                        onChange={(e) => {
                            set("user_time", {
                                type: user_time.type,
                                time: e.target.value,
                                is_active: user_time.is_active,
                            });
                        }}
                        style={{ width: '50%' }}
                        className="mt-0"
                    >
                        {timeValuesForSelect.map((timeObj) => (
                            <option
                                key={`timeRange-${timeObj.value}`}
                                value={timeObj.value}
                            >
                                {timeObj.label}
                            </option>
                        ))}
                    </NativeSelect>
                )}
            </Div>
        </>
    );
};

export default UserTime;
