import React from "react";
import { Icon28DevicesOutline, Icon28ArticleOutline } from "@vkontakte/icons";

import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";

type IndentsAndAlignBlockPopupPropsType = {
    blockRef?: any;
    platform: string;
    blockAlignment: BlockAlignment;
    alignBlockPopupOpened: boolean;
    indentsBlockPopupOpened: boolean;
    getCurrentAlignment: (blockAlignment: BlockAlignment) => string;
    setIndentsBlockPopupOpened: Function;
    setAlignBlockPopupOpened: Function;
};

const IndentsAndAlignBlockPopup = (
    props: IndentsAndAlignBlockPopupPropsType
) => {
    const {
        alignBlockPopupOpened,
        indentsBlockPopupOpened,
        setIndentsBlockPopupOpened,
        setAlignBlockPopupOpened,
        platform,
        blockAlignment,
        getCurrentAlignment,
        blockRef,
    } = props;

    return (
        <div
            ref={blockRef}
            className={`IndentsAndAlignPopup ${
                platform !== "desktop_web" ? "IndentsAndAlignPopup__mobile" : ""
            }`}
        >
            <div className="IndentsAndAlignPopup__wrapper">
                <div
                    className={`IndentsAndAlignPopup__menuItem ${
                        indentsBlockPopupOpened ? "active" : ""
                    }`}
                    onClick={() => {
                        setAlignBlockPopupOpened(false);
                        setIndentsBlockPopupOpened(
                            (prevVal: boolean) => !prevVal
                        );
                    }}
                >
                    <Icon28DevicesOutline />
                    <span>Отступы</span>
                </div>
                <div
                    className={`IndentsAndAlignPopup__menuItem ${
                        alignBlockPopupOpened ? "active" : ""
                    }`}
                    onClick={() => {
                        setIndentsBlockPopupOpened(false);
                        setAlignBlockPopupOpened(
                            (prevVal: boolean) => !prevVal
                        );
                    }}
                >
                    <Icon28ArticleOutline />
                    <span>{getCurrentAlignment(blockAlignment)}</span>
                </div>
            </div>
        </div>
    );
};

export default IndentsAndAlignBlockPopup;
