import React from "react";
import YouTube from "react-youtube";
import container from "../../container";
import * as Sentry from "@sentry/react";

interface BannerProps {
    banner_url: string;
    mobile_banner_url?: string;
    banner_type: string;
    mobile_banner_type?: string;
    type: "catalog" | "page";
    name?: string;
    video_key?: string;
    mobile_video_key?: string;
    video_autoplay?: number;
    iFrameOnLoad?: Function;
    imgOnLoad?: Function;
    platform?: string;
}

const Banner = (props: BannerProps) => {
    const Logger = container.get("logger");

    try {
        const {
            iFrameOnLoad,
            imgOnLoad,
            banner_url,
            mobile_banner_url,
            mobile_video_key,
            mobile_banner_type,
            video_key,
            video_autoplay,
            banner_type,
            name,
            platform = "desktop_web",
        } = props;

        /**
         * Для десктопов
         */
        if (platform === "desktop_web") {
            switch (banner_type) {
                case "img":
                    return banner_url ? (
                        <div id="full-banner-wrapper">
                            <img
                                id="full-img"
                                onLoad={(e) => {
                                    imgOnLoad && imgOnLoad(e);
                                }}
                                src={banner_url}
                                alt={name}
                                style={{ width: "100%" }}
                            />
                        </div>
                    ) : null;

                case "vimeo":
                    let video_url = banner_url;

                    if (banner_url && video_autoplay === 0) {
                        video_url = banner_url.replace(
                            "autoplay=1",
                            "autoplay=0"
                        );
                    }

                    return banner_url ? (
                        <div className="video-wrapper">
                            <div className="video-inner">
                                <iframe
                                    onLoad={(e) => {
                                        iFrameOnLoad && iFrameOnLoad(e);
                                    }}
                                    data-key={video_key}
                                    src={video_url}
                                    title="Видео"
                                    width="auto"
                                    height="auto"
                                    frameBorder="0"
                                    allow="autoplay"
                                ></iframe>
                            </div>
                        </div>
                    ) : null;
                case "youtube":
                    let options = {};

                    if (video_key) {
                        options = {
                            height: "100%",
                            width: "100%",
                            playerVars: {
                                mute: video_autoplay ? 1: 0,
                                controls: 1, // Show pause/play buttons in player
                                rel: 0,
                                origin:
                                    window.location.protocol +
                                    "//" +
                                    window.location.host,
                                autoplay: video_autoplay, // Auto-play the video on load
                                showinfo: 0, // Hide the video title
                                modestbranding: 1, // Hide the Youtube Logo
                                loop: 1, // Run the video in a loop
                                fs: 0, // Hide the full screen button
                                cc_load_policy: 0, // Hide closed captions
                                iv_load_policy: 3, // Hide the Video Annotations
                                autohide: 0, // Hide video controls when playing
                            },
                        };
                        return (
                            <div className="video-wrapper">
                                <div className="video-inner">
                                    <YouTube
                                        onReady={(e) => {
                                            iFrameOnLoad && iFrameOnLoad(e);
                                        }}
                                        videoId={video_key}
                                        opts={options}
                                    />
                                </div>
                            </div>
                        );
                    } else return null;
                case "vk_video":
                    let vk_video_url = banner_url;

                    if (video_autoplay === 1) {
                        vk_video_url = vk_video_url + "&autoplay=1";
                    } else {
                        vk_video_url = vk_video_url.replace(
                            "autoplay=1",
                            "autoplay=0"
                        );
                    }

                    return banner_url ? (
                        <div className="video-wrapper">
                            <div className="video-inner">
                                <iframe
                                    onLoad={(e) => {
                                        iFrameOnLoad && iFrameOnLoad();
                                    }}
                                    data-key={443437002} //@TODO
                                    src={vk_video_url + "&loop=1"}
                                    title="Видео"
                                    width="auto"
                                    height="auto"
                                    frameBorder="0"
                                    allow="autoplay"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    ) : null;
                default:
                    return null;
            }
        } else {
            /**
             * Для мобильных устройств
             */
            let key;
            let b_type =
                mobile_banner_type && mobile_video_key
                    ? mobile_banner_type
                    : banner_type;
            switch (b_type) {
                case "img":
                    // Если есть урл для мобильного - используем для моибльного. Иначе фолбэк до десктопа
                    let url = mobile_banner_url
                        ? mobile_banner_url
                        : banner_url;
                    return url ? (
                        <div id="full-banner-wrapper">
                            <img
                                id="full-img"
                                onLoad={(e) => {
                                    imgOnLoad && imgOnLoad(e);
                                }}
                                src={url}
                                alt={name}
                                style={{ width: "100%" }}
                            />
                        </div>
                    ) : null;

                case "vimeo":
                    let video_url = mobile_banner_url
                        ? mobile_banner_url
                        : banner_url;
                    key = mobile_video_key ? mobile_video_key : video_key;

                    if (video_url && video_autoplay === 0) {
                        video_url = video_url.replace(
                            "autoplay=1",
                            "autoplay=0"
                        );
                    }

                    return video_url ? (
                        <div className="video-wrapper">
                            <div className="video-inner">
                                <iframe
                                    onLoad={(e) => {
                                        iFrameOnLoad && iFrameOnLoad(e);
                                    }}
                                    data-key={key}
                                    src={video_url}
                                    title="Видео"
                                    width="auto"
                                    height="auto"
                                    frameBorder="0"
                                    allow="autoplay"
                                ></iframe>
                            </div>
                        </div>
                    ) : null;
                case "youtube":
                    let options = {};
                    key = mobile_video_key ? mobile_video_key : video_key;

                    if (key) {
                        options = {
                            height: "100%",
                            width: "100%",
                            playerVars: {
                                controls: 1, // Show pause/play buttons in player
                                rel: 0,
                                origin:
                                    window.location.protocol +
                                    "//" +
                                    window.location.host,
                                autoplay: video_autoplay, // Auto-play the video on load
                                showinfo: 0, // Hide the video title
                                modestbranding: 1, // Hide the Youtube Logo
                                loop: 1, // Run the video in a loop
                                fs: 0, // Hide the full screen button
                                cc_load_policy: 0, // Hide closed captions
                                iv_load_policy: 3, // Hide the Video Annotations
                                autohide: 0, // Hide video controls when playing
                            },
                        };
                        return (
                            <div className="video-wrapper">
                                <div className="video-inner">
                                    <YouTube
                                        onReady={(e) => {
                                            iFrameOnLoad && iFrameOnLoad(e);
                                        }}
                                        videoId={key}
                                        opts={options}
                                    />
                                </div>
                            </div>
                        );
                    } else return null;
                case "vk_video":
                    let vk_video_url = mobile_banner_url
                        ? mobile_banner_url
                        : banner_url;

                    if (video_autoplay === 1) {
                        vk_video_url = vk_video_url + "&autoplay=1";
                    } else {
                        vk_video_url = vk_video_url.replace(
                            "autoplay=1",
                            "autoplay=0"
                        );
                    }

                    return vk_video_url ? (
                        <div className="video-wrapper">
                            <div className="video-inner">
                                <iframe
                                    onLoad={(e) => {
                                        iFrameOnLoad && iFrameOnLoad();
                                    }}
                                    data-key={443437002} //@TODO
                                    src={vk_video_url + "&loop=1"}
                                    title="Видео"
                                    width="auto"
                                    height="auto"
                                    frameBorder="0"
                                    allow="autoplay"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    ) : null;
                default:
                    return null;
            }
        }
    } catch (e) {
        console.log(e);
        Logger.error(
            { code: 9059, message: e.message },
            "render_error",
            "Banner.tsx"
        );
        Sentry.captureException(e);
        return null;
    }
};

export default Banner;
