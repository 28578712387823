import React from "react";
import { Title, Div } from "@vkontakte/vkui";
import PagesTemplates from "../../../includes/PagesTemplates";
import PagesBlocks from "../../../includes/PagesBlocks";
import Card from "../../Elements/Card";
import { v4 as uuidv4 } from "uuid";
import { clone } from "../../../includes/Helpers/Helpers";

type BlockSelectProps = {
    type: string;
    onSelect: Function;
    platform?: string;
};

const BlockSelect = (props: BlockSelectProps) => {
    const { type, onSelect, platform = "desktop_web" } = props;

    if (!PagesTemplates.types[type]) {
        throw new Error("Unsupported type");
    }

    const blockTypeData = PagesTemplates.types[type];

    const addBlock = (block: any) => {
        const new_block = clone(block);

        if (new_block.button) {
            new_block.button = Object.assign({}, block.button);
            new_block.button.id = "";
        }

        if (
            new_block.hasOwnProperty("items") &&
            Array.isArray(new_block.items)
        ) {
            new_block.items.forEach((item) => {
                item.uuid = uuidv4();

                if (new_block.type === "products" && item.button) {
                    item.button.id = "";
                }
            });
        }

        onSelect(new_block);
    };

    const renderItems = () => {
        return blockTypeData.children.map((item) => {
            const block = PagesBlocks[type][item.key];

            return (
                <Card
                    key={item.key}
                    style={{ cursor: "pointer", marginBottom: 20 }}
                    background={item.image}
                    backgroundMinHeight={platform === "desktop_web" ? 350 : 200}
                    text={item.name}
                    onClick={() => {
                        addBlock(block);
                    }}
                    textBold={item.desc}
                />
            );
        });
    };

    return (
        <Div className="PagesBlockSelect">
            <Title
                style={{
                    color: "var(--vkui--color_text_secondary)",
                    marginBottom: 10,
                }}
                weight="2"
                level="3"
            >
                {blockTypeData.name}
            </Title>
            {renderItems()}
        </Div>
    );
};

export default BlockSelect;
