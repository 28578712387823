const initialState = {
    title: "",
    status_title: false,
    bottom_title: "",
    url: "",
    status_title_url: false,
    bottom_title_url: "",
    descr: "",
    status_descr: false,
    bottom_descr: "",
    text: "",
    status_text: false,
    bottom_text: "",
    geo: "",
    status_geo: false,
    bottom_geo: "",
    time: "",
    status_time: false,
    bottom_time: "",
};

export default initialState;
