import React, { useState, useEffect } from "react";
import { Button, Div, FormItem, FormLayout, Input } from "@vkontakte/vkui";
import WidgetValidate from "../../../includes/Validators/WidgetValidate";
import { countStringLength } from "../../../includes/Helpers/Helpers";
import {
    MAX_BUTTON_STRING_LENGTH,
    URL_PLACEHOLDER,
} from "../../../includes/Constants";
import InputDynamic from "../../Controls/InputDynamic";

export default (props) => {
    const [name, setName] = useState("");
    const [nameHasError, setNameHasError] = useState(false);
    const [nameInputHelp, setNameInputHelp] = useState("");

    const [url, setUrl] = useState("");
    const [urlHasError, setUrlHasError] = useState(false);
    const [urlInputHelp, setUrlInputHelp] = useState("");

    const id = props.cardID;

    useEffect(() => {
        setName(props.state.button);
        setNameInputHelp(
            `Введено ${countStringLength(
                props.state.button
            )} из ${MAX_BUTTON_STRING_LENGTH} симв.`
        );
        setUrl(props.state.button_url);
    }, []);

    const SaveModal = () => {
        let isValid = true;

        if (name.trim() != "") {
            if (WidgetValidate.button(url) === false) {
                setUrlHasError(true);
                setUrlInputHelp(URL_PLACEHOLDER);
                isValid = false;
            } else if (countStringLength(name) > MAX_BUTTON_STRING_LENGTH) {
                setNameHasError(true);
                isValid = false;
            }
        } else {
            if (url.trim() != "") {
                setNameHasError(true);
                setNameInputHelp("Введите текст кнопки");
                isValid = false;
            }
        }

        if (isValid) {
            if (typeof props.cardID == "undefined") {
                props.onSelect({ type: "button", payload: [name, url] });
            } else {
                props.onSelect({ type: "button", payload: [id, [name, url]] });
            }
            props.close({ id: null, args: {} });
        }
    };

    return (
        <FormLayout>
            <Div style={{ paddingTop: 0 }}>
                <InputDynamic
                    name="title"
                    type="text"
                    top="Наименование"
                    value={name ? name : ""}
                    status={nameHasError ? "error" : null}
                    bottomText={nameInputHelp ? nameInputHelp : "_"}
                    onChange={(value) => {
                        let length = countStringLength(value);
                        setName(value);
                        setNameInputHelp(
                            "Введено " +
                                length +
                                " из " +
                                MAX_BUTTON_STRING_LENGTH +
                                " симв."
                        );
                        setNameHasError(length > MAX_BUTTON_STRING_LENGTH);
                    }}
                    placeholder={`До ${MAX_BUTTON_STRING_LENGTH} символов`}
                />
            </Div>

            <FormItem
                top="Ссылка"
                status={urlHasError ? "error" : null}
                bottom={urlInputHelp ? urlInputHelp : URL_PLACEHOLDER}
            >
                <Input
                    name="url"
                    type="url"
                    value={url ? url : ""}
                    onChange={(e) => {
                        setUrl(e.target.value);
                        setUrlHasError(false);
                        setUrlInputHelp(URL_PLACEHOLDER);
                    }}
                    placeholder="vk.com"
                />
            </FormItem>

            <Div>
                <Button mode="secondary" size="l" stretched onClick={SaveModal}>
                    Сохранить
                </Button>
            </Div>
        </FormLayout>
    );
};
