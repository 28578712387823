import React, { useEffect, useState, useContext } from "react";
import { List, Cell, Search } from "@vkontakte/vkui";
import ApiVk from "../../../includes/ApiVk";
import config from "../../../includes/Config";
import { Context } from "../../../context";
import { Icon24Done } from "@vkontakte/icons";

const CountrySelect = (props) => {
    const api = ApiVk.getInstance(null);

    const { popout } = useContext(Context);

    const { countries, setCountries } = props;

    const [allCountries, setAllCountries] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedCountries, setSelectedCountries] = useState(
        props.currentCountry && props.currentCountry.length > 0
            ? [props.currentCountry[0]]
            : []
    );

    const getCountries = async () => {
        const result = await api.getCountries();
        if (result.result === "error") {
            popout.error(result.message);
        } else {
            setAllCountries(result.response.items);
        }
    };

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if (search === "") {
            setCountries(config.mainCountries);
        } else {
            const countries = allCountries.filter((i) => {
                let title = i.title.toLowerCase();
                let searchValue = search.toLowerCase();
                return title.indexOf(searchValue) !== -1;
            });
            setCountries(countries);
        }
    }, [search]);

    const add = (country) => {
        const newSelectedCountries = [country];
        setSelectedCountries(newSelectedCountries);
        props.onSelect(newSelectedCountries);
    };

    const remove = (country) => {
        const newSelectedCountries = [];
        setSelectedCountries(newSelectedCountries);
        props.onSelect(newSelectedCountries);
    };

    const discardCountry = () => {
        setSelectedCountries([]);
        props.onSelect([]);
    };

    const isSelected = (country) => {
        return selectedCountries.filter((c) => c.id === country.id).length > 0;
    };

    return (
        <>
            <Search
                onChange={(e) => {
                    setSearch(e.currentTarget.value);
                }}
                style={{ background: "transparent" }}
                after=""
            />
            <List>
                <Cell
                    key="country-any"
                    onClick={discardCountry}
                    after={
                        selectedCountries.length === 0 && (
                            <Icon24Done fill="var(--vkui--color_text_accent)" />
                        )
                    }
                >
                    Любая
                </Cell>
                {countries.map((country) => {
                    let selected = isSelected(country);
                    return (
                        <Cell
                            key={country.id}
                            onClick={() => {
                                if (selected) {
                                    remove(country);
                                } else {
                                    add(country);
                                }
                            }}
                            after={
                                selected && <Icon24Done fill="var(--vkui--color_text_accent)" />
                            }
                        >
                            {country.title}
                        </Cell>
                    );
                })}
            </List>
        </>
    );
};

export default CountrySelect;
