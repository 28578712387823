import React, { useContext, useState, useEffect } from "react";
import {
    Cell,
    Group,
    Button,
    PanelHeader,
    Root,
    View,
    Panel,
    Div,
    List,
    Alert,
    Spinner,
    SplitLayout,
} from "@vkontakte/vkui";
import Image from "../Сomponents/Controls/Image";
import ModalController from "../Controllers/ModalController";
import { Context } from "../context";
import { Widget } from "../includes/Structures";
import Config from "../includes/Config";
import container from "../container";
import Api from "../includes/ApiVk";
import FatalError from "./FatalError";

interface ShareRootProps {
    popout: any;
    mainPopout: any;
    UrlParams: any;
}

const ShareRoute = (props: ShareRootProps) => {
    const { mainPopout, popout } = props;
    const { urlParams, currentModal } = useContext(Context);

    const [isPendingCollection, setIsPendingCollection] = useState(true);
    const [collection, setCollection] = useState([]);

    const params = urlParams.get();
    const api = Api.getInstance(params.gets);

    const collection_id = parseInt(params.hash.share);

    const fetchCollection = async () => {
        try {
            const service = container.get("ShareService");
            const resp = await service.getCollection(collection_id);

            if (resp.result === "success") {
                setCollection(resp.data);
            } else {
                let errorMessage = "Ошибка при получении коллекции виджетов";
                if (resp.errors && resp.errors[0]) {
                    errorMessage = resp.errors[0];
                }
                renderPopoutError(errorMessage, fetchCollection);
            }
        } catch (e) {
            renderPopoutError(e.message, fetchCollection);
        } finally {
            setIsPendingCollection(false);
        }
    };

    // Если id коллекции присутсвует в хэше и id коллекции - это число
    const collectionIdValid =
        params.hash.share && false === isNaN(params.hash.share);

    // При монтировании компонента - если есть валидный id коллекции - получим коллекцию
    useEffect(() => {
        if (collectionIdValid) {
            fetchCollection();
        }
    }, []);

    const handleApply = async (communities) => {
        if (communities.length === 0) {
            popout.error("Сообщество не выбрано");
            return false;
        }
        const targetCommunity = communities[0];

        mainPopout.loading();

        try {
            const service = container.get("ShareService");
            const response = await service.copy({
                target_vk_group_id: targetCommunity.id,
                collection_id: collection_id,
            });

            if (response.result === "success") {
                let redirectUrl = `https://vk.com/app${params.gets.vk_app_id}_-${targetCommunity.id}`;
                mainPopout.open(
                    <Alert
                        actions={[
                            {
                                title: "Закрыть",
                                autoClose: true,
                                mode: "cancel",
                            },
                        ]}
                        onClose={() => {
                            mainPopout.close();
                        }}
                    >
                        {collection.length > 1
                            ? "Виджеты скопированы"
                            : "Виджет скопирован"}
                        .
                        <div style={{ padding: "10px 0 14px 0" }}>
                            Вы можете отредактировать и опубликовать их в
                            приложении сообщества
                        </div>
                        <a
                            style={{ width: "100%", textAlign: "center" }}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn-link"
                            href={redirectUrl}
                        >
                            Перейти
                        </a>
                    </Alert>
                );
            } else {
                let errorMessage = "Ошибка при копировании коллекции виджетов";
                if (response.errors && response.errors[0]) {
                    errorMessage = response.errors[0];
                }
                renderPopoutError(errorMessage, fetchCollection);
            }
        } catch (e) {
            mainPopout.error(e.message);
        }
    };

    const showUserCommunities = async () => {
        const response = await api.getUserCommunities(
            params.gets.vk_user_id,
            "admin"
        );

        if (!response.items) {
            mainPopout.error("Не удалось получить сообщества");
            return false;
        }

        currentModal.set({
            id: "user-communities",
            args: {
                items: response.items,
                currentGroup: params.gets.vk_group_id,
                callback: (communities) => {
                    handleApply(communities);
                },
            },
        });
    };

    const renderPopoutError = (text: string, action: Function) => {
        mainPopout.error(text, "Ошибка", [
            {
                title: "Повторить",
                autoClose: true,
                mode: "default",
                action: action,
            },
            { title: "Закрыть", autoClose: true, mode: "cancel" },
        ]);
    };

    const renderLoading = () => {
        return (
            <Div>
                <Spinner size="regular" />
            </Div>
        );
    };

    const renderContent = () => {
        return (
            <>
                <Group>
                    <List>
                        {collection.map((widget: Widget) => {
                            const widgetMetaData = Config.getTypeApiDescription(
                                widget.type_api
                            );
                            return (
                                <Cell
                                    before={
                                        <div className="ItemCardCell-wrapper">
                                            <Image
                                                src={`/img/widgets/${widgetMetaData.img}`}
                                                className="ItemCardCell-img"
                                            />
                                        </div>
                                    }
                                    className="ItemCardCell c-ptr"
                                    // size="l"
                                    key={widget.id}
                                >
                                    <Div className="py-0">
                                        <Cell subtitle={widgetMetaData.desc}>
                                            {widget.name}
                                        </Cell>
                                    </Div>
                                </Cell>
                            );
                        })}
                    </List>
                </Group>
                {collection.length > 0 && (
                    <Div style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={showUserCommunities} size="l">
                            Скопировать
                        </Button>
                    </Div>
                )}
            </>
        );
    };

    // Если id коллекции не валиден или отсутствует
    if (false === collectionIdValid) {
        return <FatalError />;
    }

    const modal = (
        <ModalController
            UrlParams={props.UrlParams}
            mainPopout={props.mainPopout}
            setCurrentModal={currentModal.set}
            currentModal={currentModal.get()}
        />
    );

    return (
        <SplitLayout modal={modal} popout={popout}>
            <Root activeView="share">
                <View
                    id="share"
                    activePanel="share_main"
                >
                    <Panel id="share_main">
                        <PanelHeader>Скопировать виджеты</PanelHeader>
                        {isPendingCollection
                            ? renderLoading()
                            : renderContent()}
                    </Panel>
                </View>
            </Root>
        </SplitLayout>
    );
};

export default ShareRoute;
