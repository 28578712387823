import React, { useState, useEffect } from "react";
import {
    Button,
    FormLayout,
    Input,
    Cell,
    Switch,
    Textarea,
    Avatar,
    File,
    Spinner,
    FormItem,
    Div,
} from "@vkontakte/vkui";
import ApiVk from "../../../includes/ApiVk";
import WidgetService from "../../../includes/Services/WidgetService";
import {
    countStringLength,
    cropStringForVk,
} from "../../../includes/Helpers/Helpers";
import { MAX_STRING_LENGTH } from "../../../includes/Constants";
import { Icon28Camera } from "@vkontakte/icons";

const ModalReview = (props) => {
    const api = ApiVk.getInstance(null);

    const [url, setUrl] = useState("");
    const [statusUrl, setStatusUrl] = useState(false);
    const [inputBottom_url, setInputBottomUrl] = useState("");

    const [title, setTitle] = useState("");
    const [statusTitle, setStatusTitle] = useState(false);
    const [inputBottom_title, setInputBottom_title] = useState(
        `Введено 0 из 100 симв.`
    );

    const [feedback, setFeedback] = useState("");
    const [inputBottom_text, setInputBottom_text] = useState(
        `Введено 0 из 300 симв.`
    );
    const [statusFeedBack, setStatusFeedBack] = useState(false);

    const [img, setImg] = useState(null);
    const [inputBottom_img, setInputBottom_img] = useState("");
    const [statusImg, setStatusImg] = useState(false);

    const [inputSwitchLink, setInputSwitchLink] = useState(
        props.type == "url" ? true : false
    );
    const [isLoad, setisLoad] = useState(false);

    const SaveModal = () => {
        if (title.trim() === "") {
            setStatusTitle(true);
            setInputBottom_title("Введите имя");
        }

        if (feedback.trim() === "") {
            setStatusFeedBack(true);
            setInputBottom_text("Введите текст отзыва");
        }

        if (!!img === false) {
            setStatusImg(true);
            setInputBottom_img("Выберите фото");
        }

        if (title.trim() !== "" && feedback.trim() !== "" && !!img) {
            props.onSelect({
                type: props.cardID === undefined ? "add" : "update",
                payload: [
                    props.cardID,
                    {
                        title,
                        feedback,
                        icon_id: img.icon_id,
                        img: img.img,
                    },
                ],
            });
            props.close({ id: null, args: {} });
        }
    };
    /**
     * Side effect на получение публичного токена VK при загрузке
     */

    useEffect(() => {
        if (props.cardID !== undefined)
            update_feedback(props.state.rows[props.cardID]);
    }, []);

    const update_feedback = (item) => {
        setTitle(item.title);
        setInputBottom_title(
            `Введено ${countStringLength(item.title)} из 100 симв.`
        );
        setFeedback(item.descr);
        setInputBottom_text(
            `Введено ${countStringLength(item.descr)} из 300 симв.`
        );
        setImg({ icon_id: item.icon_id, img: item.img });
    };

    const getImg = async (e) => {
        try {
            if (!e.target.files[0]) {
                return false;
            }

            setisLoad(true);

            let response = await WidgetService.uploadImage({
                image: e.target.files[0],
                image_type: "50x50",
            });

            if (response.result === "error") {
                setStatusImg(true);
                setInputBottom_img(response.errors.image[0]);
                setisLoad(false);
                return false;
            }

            setisLoad(false);
            setStatusImg(false);
            setInputBottom_img("");
            setImg({
                icon_id: response.response.id,
                img: response.response.images[0].url,
            });
        } catch (error) {
            setStatusImg(true);
            setInputBottom_img("Ошибка при загрузке изображения");
            setisLoad(false);
            console.error(error);
        }
    };

    const getFeedBack = async () => {
        if (url.indexOf("vk.com") < 0) {
            setStatusUrl(true);
            setInputBottomUrl("Введите внутреннюю ссылку vk.com");
            return;
        }

        setisLoad(true);

        const response = await api.getComment(url);

        if (response.result === "success") {
            props.onSelect({
                type: "add_url",
                payload: {
                    title: response.name,
                    feedback: cropStringForVk(response.text, 300),
                    img: response.img.url,
                    icon_id: response.icon_id,
                    button_url: url,
                },
            });

            setisLoad(false);
            props.close({ id: null, args: {} });
        } else {
            setisLoad(false);
            setStatusUrl(true);
            setInputBottomUrl(response.message);
        }
    };

    const imgLoad = () => {
        if (img == null) {
            return (
                <div style={{ marginRight: 4 }}>
                    <Avatar>
                        <Icon28Camera />
                    </Avatar>
                </div>
            );
        }
        return (
            <div style={{ marginRight: 4 }}>
                <Avatar src={img.img} />
            </div>
        );
    };

    return (
        <>
            {props.type === "all" && (
                <Cell
                    className="ModalReview__cell"
                    hasActive={false}
                    hasHover={false}
                    after={
                        <Switch
                            disabled={props.type == "all" ? false : true}
                            checked={inputSwitchLink}
                            onChange={() => {
                                setInputSwitchLink(
                                    inputSwitchLink ? false : true
                                );
                            }}
                        />
                    }
                >
                    Ссылка на отзыв
                </Cell>
            )}

            {inputSwitchLink && (
                <FormLayout>
                    <FormItem
                        status={statusUrl ? "error" : null}
                        bottom={
                            statusUrl
                                ? inputBottom_url
                                : "*cсылка должна быть на отзыв из обсуждения или комментарий к посту."
                        }
                    >
                        <Input
                            name="link"
                            type="text"
                            value={url}
                            maxLength={100}
                            onChange={(e) => {
                                setUrl(e.target.value);
                                setStatusUrl(false);
                            }}
                            placeholder="Ссылка vk.com"
                        />
                    </FormItem>
                    <Div>
                        <Button
                            disabled={isLoad}
                            size="l"
                            stretched
                            onClick={getFeedBack}
                        >
                            {isLoad ? <Spinner size="regular" /> : "Загрузить"}
                        </Button>
                    </Div>
                </FormLayout>
            )}

            {!inputSwitchLink && (
                <FormLayout>
                    <FormItem
                        top="Имя"
                        status={statusTitle ? "error" : null}
                        bottom={inputBottom_title ? inputBottom_title : "_"}
                    >
                        <Input
                            name="title"
                            type="text"
                            value={title}
                            maxLength={100}
                            onChange={(e) => {
                                let value = e.target.value;
                                let stringLength = countStringLength(value);
                                if (stringLength <= MAX_STRING_LENGTH) {
                                    setInputBottom_title(
                                        "Введено " +
                                            stringLength +
                                            " из " +
                                            MAX_STRING_LENGTH +
                                            " симв."
                                    );
                                    setTitle(e.target.value);
                                    setStatusTitle(false);
                                }
                            }}
                            placeholder="Кто оставил отзыв?"
                        />
                    </FormItem>
                    <FormItem
                        type="text"
                        top="Текст"
                        status={statusFeedBack ? "error" : null}
                        bottom={inputBottom_text ? inputBottom_text : "_"}
                    >
                        <Textarea
                            name="text"
                            value={feedback}
                            maxLength={300}
                            onChange={(e) => {
                                let value = e.target.value;
                                let stringLength = countStringLength(value);
                                if (stringLength <= 300) {
                                    setInputBottom_text(
                                        "Введено " +
                                            stringLength +
                                            " из " +
                                            300 +
                                            " симв."
                                    );
                                    setFeedback(e.target.value);
                                    setStatusFeedBack(false);
                                }
                            }}
                            placeholder="Текст отзыва"
                        />
                    </FormItem>

                    {isLoad ? (
                        <Spinner size="medium" />
                    ) : (
                        <FormItem
                            status={statusImg ? "error" : null}
                            bottom={inputBottom_img}
                            className="ModalReview__add-photo"
                        >
                            <File
                                style={{ padding: "0px" }}
                                onChange={getImg}
                                before={imgLoad()}
                                mode="tertiary"
                                accept=".png, .jpg, .jpeg"
                            >
                                Добавить фото
                            </File>
                        </FormItem>
                    )}

                    <Div>
                        <Button size="l" onClick={SaveModal} stretched>
                            Добавить
                        </Button>
                    </Div>
                </FormLayout>
            )}
        </>
    );
};

export default ModalReview;
