import React, { useReducer, useEffect, useState, useContext } from "react";
import { Button, Div, FormItem, FormLayout, Input } from "@vkontakte/vkui";
import reducer from "./state/reducer";
import initialState from "./state/state";
import WidgetValidate from "../../../includes/Validators/WidgetValidate";
import SubscriptionSelect from "../../Controls/Selects/SubscriptionSelect";
import { countStringLength } from "../../../includes/Helpers/Helpers";
import {
    MAX_STRING_LENGTH,
    MAX_BUTTON_STRING_LENGTH,
    URL_PLACEHOLDER,
} from "../../../includes/Constants";
import InputDynamic from "../../Controls/InputDynamic";
import { Context } from "../../../context";

const ModalListItem = (props) => {
    const [state, dispatch]: any = useReducer(reducer, initialState);
    const [name, setName]: any = useState({});

    const { subscriptions, inputDynamic } = useContext(Context);

    /**
     * @TODO Можно рефакторить
     * Нужен универсальный, конфигурируемый валидатор
     * Который будет оперировать данными, без коллбэков
     * Все коллбэки на dispatch выпилены
     */
    const SaveModal = () => {
        let isValid = true;

        let titleLength = countStringLength(state.title);
        if (titleLength > MAX_STRING_LENGTH) {
            isValid = false;
            dispatch({
                type: "title",
                payload: {
                    text: state.title,
                    status: true,
                    buttom: `Введено ${titleLength} из ${MAX_STRING_LENGTH} симв.`,
                },
            });
        } else if (state.title.trim() === "") {
            isValid = false;
            dispatch({
                type: "title",
                payload: {
                    text: state.title,
                    status: true,
                    buttom: `Заполните заголовок`,
                },
            });
        }

        let descrLength = countStringLength(state.descr);
        if (descrLength > MAX_STRING_LENGTH) {
            isValid = false;
            dispatch({
                type: "descr",
                payload: {
                    text: state.descr,
                    status: true,
                    buttom: `Введено ${descrLength} из ${MAX_STRING_LENGTH} симв.`,
                },
            });
        }

        let textLength = countStringLength(state.text);
        if (textLength > 300) {
            isValid = false;
            dispatch({
                type: "text",
                payload: {
                    text: state.text,
                    status: true,
                    buttom: `Введено ${textLength} из 300 симв.`,
                },
            });
        }

        let geoLength = countStringLength(state.geo);
        if (geoLength > MAX_BUTTON_STRING_LENGTH) {
            isValid = false;
            dispatch({
                type: "geo",
                payload: {
                    text: state.geo,
                    status: true,
                    buttom: `Введено ${geoLength} из ${MAX_BUTTON_STRING_LENGTH} симв.`,
                },
            });
        }

        let timeLength = countStringLength(state.time);
        if (timeLength > MAX_BUTTON_STRING_LENGTH) {
            isValid = false;
            dispatch({
                type: "time",
                payload: {
                    text: state.time,
                    status: true,
                    buttom: `Введено ${timeLength} из ${MAX_BUTTON_STRING_LENGTH} симв.`,
                },
            });
        }

        // Проверяем валидность ссылки только в том случае, если они заполнена
        if (state.url !== "" && false === WidgetValidate.button(state.url)) {
            isValid = false;
            dispatch({
                type: "title_url",
                payload: {
                    text: state.url,
                    status: true,
                    buttom: URL_PLACEHOLDER,
                },
            });
        }

        if (isValid) {
            props.onSelect({ type: "item", payload: [props.cardID, state] });
            props.close({ id: null, args: {} });
        } else {
            return false;
        }
    };

    const topTitle = (type) => {
        return {
            geo: "Адрес",
            time: "Время",
        };
    };

    useEffect(() => {
        setName(topTitle(props.type));
        if (props.state !== "undefined")
            dispatch({ type: "update", payload: props.state });
    }, []);

    const handleInputChange = (type, value, maxLength) => {
        let length = countStringLength(value);
        dispatch({
            type: type,
            payload: {
                text: value,
                buttom: `Введено ${length} из ${maxLength} симв.`,
                status: length > maxLength,
            },
        });
    };

    const handleSubscriptionSelectChange = (subscription) => {
        const itemData = subscription.getWidgetItemData();
        // Обновим внутренний стейт формы
        dispatch({
            type: "update",
            payload: {
                title: itemData.title,
                descr: itemData.desc,
                text: itemData.text,
                url: itemData.url,
                time: "",
                geo: "",
            },
        });

        // Обновим кнопки в стейте элемента
        // Остальные данные обновятся только после валидации и сохранения формы
        props.onSelect({
            type: "sub",
            payload: {
                item_id: props.cardID,
                data: {
                    title: itemData.title,
                    descr: itemData.desc,
                    text: itemData.text,
                    url: itemData.url,
                    time: "",
                    geo: "",
                    button: itemData.button,
                    button_url: itemData.button_url,
                },
            },
        });
    };

    const divStyle = {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    };

    return (
        <FormLayout>
            <Div style={divStyle}>
                <Div style={divStyle}>
                    <SubscriptionSelect
                        placeholder="Быстрый выбор из групп подписчиков"
                        items={subscriptions.get()}
                        onSelect={handleSubscriptionSelectChange}
                    />
                </Div>
            </Div>

            <Div style={{ paddingTop: 0 }}>
                <InputDynamic
                    name="title"
                    type="text"
                    currentOpened={inputDynamic.getCurrent()}
                    top="Заголовок"
                    value={state.title || ""}
                    status={state.status_title ? "error" : null}
                    bottomText={state.bottom_title ? state.bottom_title : "_"}
                    onChange={(value) => {
                        handleInputChange("title", value, MAX_STRING_LENGTH);
                    }}
                    placeholder="До 100 символов"
                />

                <FormItem
                    top="Ссылка для заголовка"
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    status={state.status_title_url ? "error" : null}
                    bottom={
                        state.bottom_title_url ? state.bottom_title_url : "_"
                    }
                >
                    <Input
                        name="title_url"
                        type="text"
                        maxLength={100}
                        value={state.url || ""}
                        onChange={(e) => {
                            dispatch({
                                type: "title_url",
                                payload: {
                                    text: e.target.value,
                                    buttom: URL_PLACEHOLDER,
                                },
                            });
                        }}
                        placeholder={URL_PLACEHOLDER}
                    />
                </FormItem>

                <InputDynamic
                    name="descr"
                    type="text"
                    top="Краткое описание"
                    currentOpened={inputDynamic.getCurrent()}
                    value={state.descr || ""}
                    status={state.status_descr ? "error" : null}
                    bottomText={state.bottom_descr ? state.bottom_descr : "_"}
                    onChange={(value) => {
                        handleInputChange("descr", value, MAX_STRING_LENGTH);
                    }}
                    placeholder={`До ${MAX_STRING_LENGTH} символов`}
                />

                <InputDynamic
                    textarea={true}
                    currentOpened={inputDynamic.getCurrent()}
                    name="text"
                    top="Подробное описание"
                    value={state.text || ""}
                    status={state.status_text ? "error" : null}
                    bottomText={state.bottom_text ? state.bottom_text : "_"}
                    onChange={(value) => {
                        handleInputChange("text", value, 300);
                    }}
                    placeholder="До 300 символов"
                />

                <InputDynamic
                    currentOpened={inputDynamic.getCurrent()}
                    name="geo"
                    type="text"
                    top={name.geo}
                    value={state.geo || ""}
                    status={state.status_geo ? "error" : null}
                    bottomText={state.bottom_geo ? state.bottom_geo : "_"}
                    onChange={(value) => {
                        handleInputChange(
                            "geo",
                            value,
                            MAX_BUTTON_STRING_LENGTH
                        );
                    }}
                    placeholder={`До ${MAX_BUTTON_STRING_LENGTH} символов`}
                />

                <InputDynamic
                    currentOpened={inputDynamic.getCurrent()}
                    name="time"
                    type="text"
                    top={name.time}
                    value={state.time || ""}
                    status={state.status_time ? "error" : null}
                    bottomText={state.bottom_time ? state.bottom_time : "_"}
                    onChange={(value) => {
                        handleInputChange(
                            "time",
                            value,
                            MAX_BUTTON_STRING_LENGTH
                        );
                    }}
                    placeholder={`До ${MAX_BUTTON_STRING_LENGTH} символов`}
                />
            </Div>
            <Div>
                <Button mode="secondary" size="l" stretched onClick={SaveModal}>
                    Сохранить
                </Button>
            </Div>
        </FormLayout>
    );
};

export default ModalListItem;
