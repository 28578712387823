import React, { useState, useContext, useEffect } from "react";
import { FixedLayout, Div } from "@vkontakte/vkui";

import PagesEditBottomSnackbar from "../Controls/PagesEditBottomSnackbar";
import PagesEditableBlockSnackbar from "../Controls/PagesEditableBlockSnackbar";
import EmptyPagesBlocksStub from "../Elements/Stubs/EmptyPagesBlocksStub";
import BlockEditWrapper from "./Blocks/BlockEditWrapper";
import AlertDeleteCommon from "../Modals/Alert/AlertDeleteCommon";
import PagesActionsController from "../../Controllers/PageActionsController";

import { PageItem } from "../../includes/Structures";
import { pageHasUnpublishedState } from "../../includes/Helpers/HelpersPages";

import { Context } from "../../context";

import { PAGES_BLOCK_EDIT, Analytics } from "../../includes/Metrics/Analytics";
import { BlockAlignment } from "../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";

type PagesEditItemProps = {
    page: PageItem;
    stateManage: any;
    onAddBlockClick: Function;
    onEditBlockClick: Function;
    onInsertBlockClick: Function;
    deleteBlock: Function;
    handleSort: Function;
    onPreviewClick: Function;
    onSaveStateClick: Function;
    copyBlock: Function;
    disableBlock: Function;
    insertBlockAudience: Function;
};

export default (props: PagesEditItemProps) => {
    const {
        page,
        stateManage,
        onAddBlockClick,
        onPreviewClick = () => {},
        onEditBlockClick = () => {},
        onSaveStateClick,
        deleteBlock,
        onInsertBlockClick,
        handleSort,
        copyBlock,
        disableBlock,
        insertBlockAudience
    } = props;

    if (!page) {
        return null;
    }

    const { popout, urlParams, pages } = useContext(Context);

    const states = stateManage.getStateManagement();
    const isStateTravelMode = states.active > 0;

    const [blocks, setBlocks] = useState([]);

    const currentEditableBlock = pages.getCurrentEditableBlock();

    let platform = urlParams.get().params.vk_platform;

    useEffect(() => {
        if (
            page.blocks_edit &&
            Array.isArray(page.blocks_edit) &&
            page.blocks_edit.length > 0 &&
            platform === "desktop_web"
        ) {
            /**
             * По умолчанию на десктопах выбираем текущий редактируемый блок - если блоки есть, то это будет первый из них
             */
            pages.setCurrentEditableBlock(page.blocks_edit[0].id);
        }
    }, []);

    useEffect(() => {
        if (page.blocks_edit && Array.isArray(page.blocks_edit)) {
            setBlocks(page.blocks_edit);
        }
    }, [page.blocks_edit]);

    const handleBlockClick = (block) => {
        if (!currentEditableBlock || currentEditableBlock !== block.id) {
            pages.setCurrentEditableBlock(block.id);
        } else {
            pages.setCurrentEditableBlock(null);
        }
    };

    const handleBlockHover = (block) => {
        pages.setCurrentEditableBlock(block.id);
    };

    const getBlockById = (block_id) => {
        let index = 0;
        let block_data = null;

        blocks.forEach((b, i) => {
            if (b.id === block_id) {
                index = i;
                block_data = b;
            }
        });

        return { index, block_data };
    };

    const handleAddBlockClick = () => {
        onAddBlockClick && onAddBlockClick();
    };

    /**
     * Вставка блока между другими
     * @param blockInsertAfter
     */
    const handleInsertBlockClick = (blockInsertAfter) => {
        onInsertBlockClick && onInsertBlockClick(blockInsertAfter);
    };

    /**
     * Удаление блока
     */
    const handleDeleteBlockClick = () => {
        popout.open(
            <AlertDeleteCommon
                accept={() => {
                    deleteBlock(currentEditableBlock);
                    pages.setCurrentEditableBlock(null);
                }}
                deny={() => {
                    popout.close();
                }}
            />
        );
    };

    /**
     * Смещение блока вниз
     * @param block
     */
    const handleBlockMoveDownClick = (block) => {
        let b = getBlockById(block.id);
        let currentIndex = b.index;
        let nextIndex = currentIndex + 1;
        handleSort && handleSort(block, currentIndex, nextIndex);
    };

    /**
     * Смещение блока вверх
     * @param block
     */
    const handleBlockMoveUpClick = (block) => {
        let b = getBlockById(block.id);
        let currentIndex = b.index;
        let nextIndex = currentIndex--;
        handleSort && handleSort(block, currentIndex, nextIndex);
    };

    /**
     * Зафиксировать перемещение по состояниям
     */
    const handleSaveStateTravelMode = () => {
        onSaveStateClick();
    };

    /**
     * Включение или отключение блока
     * @param block
     */
    const handleDisableBlockClick = (block_data, page_id, block_id, status) => {
        disableBlock && disableBlock(block_data, page_id, block_id, status);
    };

    const renderEditBlocks = () => {
        return blocks.map((block) => {
            return (
                <BlockEditWrapper
                    isPreview={true}
                    key={block.id}
                    onBlockClick={handleBlockClick}
                    onBlockHover={handleBlockHover}
                    onAddBlockClick={handleInsertBlockClick}
                    onDisableClick={handleDisableBlockClick}
                    data={block}
                    platform={platform}
                    onEditBlockClick={onEditBlockClick}
                    isStateTravelMode={isStateTravelMode}
                    currentEditableBlock={currentEditableBlock}
                    renderEditableBlockSnackbar={renderEditableBlockSnackbar}
                    renderCommonSnackbar={renderCommonSnackbar}
                    lastBlock={blocks[blocks.length - 1]}
                    isActive={
                        currentEditableBlock &&
                        currentEditableBlock === block.id &&
                        !isStateTravelMode
                    }
                    snackbar={(
                        blockPadding,
                        setBlockPadding,
                        setBlockAlignment,
                        blockAlignment,
                        textFontSize,
                        setTextFontSize
                    ) => {
                        return platform === "desktop_web" &&
                            currentEditableBlock &&
                            block.id === currentEditableBlock &&
                            !isStateTravelMode ? (
                            <div className="B-Block__edit-actions_desktop">
                                {renderEditableBlockSnackbar(
                                    blockPadding,
                                    setBlockPadding,
                                    setBlockAlignment,
                                    blockAlignment,
                                    textFontSize,
                                    setTextFontSize
                                )}
                            </div>
                        ) : null;
                    }}
                />
            );
        });
    };

    const renderCommonSnackbar = () => {
        return (
            <FixedLayout style={{ zIndex: 10 }} filled={true} vertical="bottom">
                <Div
                    style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        borderTop: "1px solid var(--vkui--color_separator_primary)",
                    }}
                >
                    <PagesEditBottomSnackbar
                        isAllowRedo={states.active > 0}
                        isAllowAddBlock={states.active === 0}
                        onAddClick={handleAddBlockClick}
                        isAllowPreview={states.active === 0}
                        onPreviewClick={onPreviewClick}
                        isStateTravelMode={states.active > 0}
                        onSaveStateTravelMode={handleSaveStateTravelMode}
                        onMoreClick={renderPageContextPopout}
                        hasUnpublishedChanges={pageHasUnpublishedState(page)}
                        onRedoClick={() => {
                            stateManage.redo(page.id);
                        }}
                        isAllowUndo={
                            states.available.length > 0 &&
                            states.active < states.available.length - 1
                        }
                        onUndoClick={() => {
                            stateManage.undo(page.id);
                        }}
                    />
                </Div>
            </FixedLayout>
        );
    };

    const renderEditableBlockSnackbar = (
        blockPadding: number = 0,
        setBlockPadding = () => {},
        setBlockAlignment = () => {},
        blockAlignment: BlockAlignment = "center",
        textFontSize: number = 0,
        setTextFontSize = () => {},
    ) => {
        if (!currentEditableBlock) return null;

        const b = getBlockById(currentEditableBlock);

        return (
            <PagesEditableBlockSnackbar
                platform={platform}
                block={b.block_data}
                onDownClick={handleBlockMoveDownClick}
                onUpClick={handleBlockMoveUpClick}
                onDeleteClick={handleDeleteBlockClick}
                onCopyClick={copyBlock}
                onDisableClick={handleDisableBlockClick}
                insertBlockAudience={insertBlockAudience}
                isAllowMoveDown={b.index < page.blocks_edit.length - 1}
                isAllowMoveUp={b.index > 0}
                setBlockPadding={setBlockPadding}
                blockPadding={blockPadding}
                textFontSize={textFontSize}
                setTextFontSize={setTextFontSize}
                setBlockAlignment={setBlockAlignment}
                blockAlignment={blockAlignment}
                onEditClick={(v) => {
                    Analytics.goal(PAGES_BLOCK_EDIT, "pages");
                    onEditBlockClick(v);
                }}
            />
        );
    };

    const renderPageContextPopout = (popoutRef: React.RefObject<Element>) => {
        popout.open(
            <PagesActionsController
                item={page}
                isEdit={true}
                mode={"context"}
                popoutRef={popoutRef}
            />,
            "context"
        );
    };

    return (
        <div id="page-edit" style={{ paddingBottom: 80 }}>
            {blocks.length === 0 ? (
                <EmptyPagesBlocksStub onClick={onAddBlockClick} />
            ) : (
                renderEditBlocks()
            )}
        </div>
    );
};
