import config from "../Config";
import WidgetTilesImgValidate from "./WidgetTilesImgValidate";
import { TILES_IMAGES_ARE_NOT_THE_SAME_TYPE } from "../ErrorMessages";

export interface widget_main {
    title: string;
    title_url: string;
    more: string;
    more_url: string;
    type_api: string;
}

const typesWithRequiredButtons = ["personal", "subscription", "community"];

export interface pointSale {
    title: string;
    url: string;
    descr: string;
    geo: string;
    time: string;
}

export interface widget {
    type: string;
    type_api: string;
    code: any;
    id: string;
}

export interface tiles extends widget_main {
    tiles: Array<object>;
}

export interface coverList extends widget_main {
    rows: Array<object>;
}

export interface text extends widget_main {
    text: string;
    descr: string;
}

export interface list extends widget_main {
    rows: Array<object>;
}

export interface array_item {
    img: string;
    icon_id: string;
    title: string;
    descr: string;
    url: string;
    button: string;
    button_url: string;
}

export interface list_item extends array_item {
    geo: string;
    time: string;
    text: string;
}

export interface table extends widget_main {
    body: Array<object>;
}

export const messageErrors = {
    pointSale: (type) => {
        switch (type) {
            case "title_error":
                return "Введите заголовок";
            case "title_url_error":
                return "Введите правильную ссылку";
            case "descr_error":
                return "Введите описание";
            case "geo_error":
                return "Введите адрес";
            case "time_error":
                return "Введите время работы";
            default:
                return "Ошибка";
        }
    },
    tiles: {
        descr: "Описание должно быть заполнено у всех элементов или не заполнено ни у одного их них",
        price: "Цена должна быть указана у всех элементов или не указана ни у одного их них",
        count: "Должно быть не менее 3 и не более 10 элементов",
        url: "Ссылка должна быть указана у всех элементов или не указана ни у одного их них",
    },
    coverList: {
        count: "Должно быть не менее 1 и не более 3 элементов",
        descr: "Описание должно быть заполнено у всех элементов или не заполнено ни у одного их них",
        url: "Ссылка должна быть указана у всех элементов или не указана ни у одного их них",
        button: "Кнопка должна быть указана у всех элементов или не указана ни у одного их них",
    },
    list: {
        count: "Должно быть не менее 1 и не более 6 элементов",
        countFeedbacks: "Должно быть не менее 1 и не более 3 элементов",
        button: "Кнопка должна быть указана у всех элементов или не указана ни у одного их них",
    },
    feedbacks: {
        count_min: "Должно быть не менее 1 элемента",
        count_max: "Должно быть не более 3 элементов",
    },
    listCommon: {
        count: "Должно быть не менее 1 и не более 6 элементов",
        countMin: "Должно быть не менее 1 элемента",
        countMax:
            "Должно быть не более 3 элементов, если указано подробное описание",
        button: "Кнопка должна быть указана у всех элементов или не указана ни у одного их них",
    },
    table: {
        count: "Должно быть не менее 1 и не более 11 элементов",
    },
    default: "Красные поля не заполнены или заполнены не верно",
};

// Переделать
// Название метода не понятное
// Убрать сайд-эффекты и колбэки
// Почему возвращает true когда строка пустая?
export const isString = (text: string, type: string, callback: Function) => {
    if (text === "") {
        callback({
            type,
            payload: {
                text: text,
                buttom: messageErrors.pointSale(type),
                status: true,
            },
        });
        return true;
    }
    return false;
};

export const isValidString = (string: string) => {
    return string.trim() !== "";
};

export const isUrl = (text: string, type: string, callback: Function) => {
    if (text === null || text === undefined) return true;
    if (text !== "") {
        if (WidgetValidate.button(text) === true) {
            return false;
        } else {
            callback({
                type,
                payload: {
                    text: text,
                    buttom: messageErrors.pointSale(type),
                    status: true,
                },
            });
            return true;
        }
    }
    return false;
};

const WidgetValidate = {
    code: (type: string, data: any) => {
        if (data.title.trim() === "") return false;
        switch (type) {
            case "carousel":
                if ("tiles" in data)
                    if (data.tiles.length > 2 && data.tiles.length < 11)
                        return true;
                return false;
            case "message":
                if (data.text.trim() !== "" && data.descr.trim() !== "") {
                    return true;
                }
                return false;
            case "personal":
                if (
                    data.rows[0].title.trim() !== "" &&
                    data.rows[0].descr.trim() !== ""
                ) {
                    return true;
                }
                return false;
            case "banners":
                if ("rows" in data)
                    if (data.rows.length > 0 && data.rows.length < 4)
                        return true;
                return false;
            case "feedbacks":
                if ("rows" in data)
                    if (data.rows.length > 0 && data.rows.length < 4)
                        return true;
                return false;
            case "clients":
                if ("body" in data)
                    if (data.body.length > 0 && data.body.length < 11)
                        return true;
                return false;
            case "pointSale":
                if (data.rows.length > 0 && data.rows.length < 7) return true;
                return false;
            case "events":
                if (data.rows.length > 0 && data.rows.length < 7) return true;
                return false;
            case "community":
                if (data.rows.length > 0 && data.rows.length < 7) return true;
                return false;
            case "subscription":
                if (data.rows.length > 0 && data.rows.length < 7) return true;
                return false;
            default:
                return false;
        }
    },

    button: (link: string) => {
        // Проверим, что ссылка на внутренний ресурс VK
        let isVkUrl =
            /^(http:\/\/|https:\/\/)?vk(\.com|\.me|\.cc(\/.{1,}|)|\.link)/g.test(link);

        if (!isVkUrl) return false;

        // Проверим, что ссылка не содержит away.php в любом виде - с параметрами или без
        let hasAway = /away\.php/g.test(link);

        if (hasAway) {
            // Если содержит - ссылка не валидна
            return false;
        }

        return true;
    },

    isCode: (widget: widget) => {
        switch (widget.type) {
            case "carousel":
                return WidgetValidate.tiles(widget.code, widget.type);
            case "promo":
                return WidgetValidate.tiles(widget.code, widget.type);
            case "recommended":
                return WidgetValidate.tiles(widget.code, widget.type);
            case "banners":
                return WidgetValidate.coverLlist(widget.code, widget.type);
            case "message":
                return WidgetValidate.text(widget.code, widget.type);
            case "clients":
                return WidgetValidate.table(widget.code, widget.type);
            case "personal":
                return WidgetValidate.list(widget.code, widget.type);
            case "feedbacks":
                return WidgetValidate.feedbacks(widget.code, widget.type);
            case "pointSale":
                return WidgetValidate.list(widget.code, widget.type);
            case "events":
                return WidgetValidate.list(widget.code, widget.type);
            case "community":
                return WidgetValidate.list(widget.code, widget.type);
            case "subscription":
                return WidgetValidate.list(widget.code, widget.type);
            case "list":
            case "compact_list":
                return WidgetValidate.listCommon(widget.code, widget.type);
        }
        return {
            status: false,
            resp: [],
            msg: "",
        };
    },

    batch: (widget_array: widget[]) => {
        let validation_results = widget_array
            .map((a) => ({ ...a }))
            .map((widget) => {
                widget.type = widget.type_api; // Костыль из-за разных структур данных в WidgetEdit и в Widgets
                return WidgetValidate.isCode(widget);
            });

        let errors = {};

        validation_results.forEach((res, i) => {
            if (res.status === false) {
                errors[widget_array[i].id] = res.msg;
            }
        });

        return {
            status:
                validation_results.filter((res) => res.status === false)
                    .length <= 0,
            errors: errors,
        };
    },

    tiles: (widget: tiles, type: String) => {
        let status = true;
        let msg = messageErrors.default;
        let resp = {
            title: false,
            tiles_count: false,
            tiles: false,
            tiles_descr: false,
            tiles_url: false,
            tiles_item: [],
            tiles_imgs: true,
        };

        if (WidgetValidate.isTitle(widget.title)) {
            resp.title = true;
            status = false;
        }

        if (WidgetTilesImgValidate(widget.tiles) === "mixed") {
            status = false;
            resp.tiles_imgs = false;
            msg = TILES_IMAGES_ARE_NOT_THE_SAME_TYPE;
        }

        if (widget.tiles.length > 0) {
            let ds = 0;
            let url = 0;
            widget.tiles.forEach((item: array_item) => {
                let card = {
                    img: false,
                    title: false,
                    descr: false,
                    button: false,
                };
                if (item.img === "") {
                    status = false;
                    card.img = true;
                    resp.tiles = true;
                }

                if (item.descr.trim() === "") {
                    ds++;
                    card.descr = true;
                }

                if (item.url === "") url++;

                if (WidgetValidate.isTitle(item.title)) {
                    card.title = true;
                    status = false;
                }

                resp.tiles_item.push(card);
            });

            if (widget.tiles.length !== ds && ds !== 0) {
                status = false;
                resp.tiles_descr = true;
                resp.tiles = true;
                if (type === "carousel") {
                    msg = messageErrors.tiles.descr;
                } else {
                    msg = messageErrors.tiles.price;
                }
            }

            if (widget.tiles.length !== url && url !== 0) {
                status = false;
                resp.tiles_url = true;
                resp.tiles = true;
                msg = messageErrors.tiles.url;
            }
        }

        if (widget.tiles.length <= 2 || widget.tiles.length >= 11) {
            status = false;
            resp.tiles = true;
            resp.tiles_count = true;
            msg = messageErrors.tiles.count;
        }

        return {
            status,
            resp,
            msg,
        };
    },

    coverLlist: (widget: coverList, type: String) => {
        let status = true;
        let msg = messageErrors.default;
        let resp = {
            title: false,
            rows_count: false,
            rows: false,
            rows_button: false,
            rows_descr: false,
            rows_url: false,
            rows_item: [],
        };

        if (WidgetValidate.isTitle(widget.title)) {
            resp.title = true;
            status = false;
        }

        if (widget.rows.length > 0) {
            let bt = 0;
            let descr = 0;
            let url = 0;
            widget.rows.forEach((item: array_item) => {
                let card = {
                    img: false,
                    title: false,
                    descr: false,
                    button: false,
                };
                if (item.img == "") {
                    status = false;
                    card.img = true;
                    resp.rows = true;
                }

                if (WidgetValidate.isTitle(item.title)) {
                    card.title = true;
                    status = false;
                }

                if (item.button.trim() === "") bt++;

                if (item.descr.trim() === "") descr++;

                if (item.url === "") url++;

                resp.rows_item.push(card);
            });

            if (widget.rows.length != bt && bt != 0) {
                status = false;
                resp.rows = true;
                resp.rows_button = true;
                msg = messageErrors.coverList.button;
            }

            if (widget.rows.length != descr && descr != 0) {
                status = false;
                resp.rows = true;
                resp.rows_descr = true;
                msg = messageErrors.coverList.descr;
            }

            if (widget.rows.length != url && url != 0) {
                status = false;
                resp.rows = true;
                resp.rows_url = true;
                msg = messageErrors.coverList.url;
            }
        }

        if (widget.rows.length < 1 || widget.rows.length > 3) {
            status = false;
            resp.rows = true;
            resp.rows_count = true;
            msg = messageErrors.coverList.count;
        }

        return {
            status,
            resp,
            msg,
        };
    },

    text: (widget: text, type: String) => {
        let status = true;
        let msg = messageErrors.default;
        let resp = {
            title: false,
            text: false,
        };

        if (WidgetValidate.isTitle(widget.title)) {
            resp.title = true;
            status = false;
        }

        if (WidgetValidate.isText(widget.text, config.widgetLength.text_text)) {
            resp.text = true;
            status = false;
        }

        return {
            status,
            resp,
            msg,
        };
    },

    table: (widget: table, type: String) => {
        let status = true;
        let msg = messageErrors.default;
        let resp = {
            title: false,
            count: false,
        };

        if (WidgetValidate.isTitle(widget.title)) {
            resp.title = true;
            status = false;
        }

        if (widget.body.length < 1 || widget.body.length > 11) {
            resp.count = false;
            status = false;
            msg = messageErrors.table.count;
        }

        return {
            status,
            resp,
            msg,
        };
    },

    list: function (widget: list, type: string) {
        let status = true;
        let msg = messageErrors.default;
        let resp = {
            title: false,
            rows_count: false,
            rows: false,
            rows_imgs: true,
            rows_button: false,
            rows_item: [],
        };

        let buttonIsRequired: boolean =
            typesWithRequiredButtons.indexOf(type) > -1;

        const max_item_count: number =
            widget.rows.filter((it: list_item) => typeof it.text != "undefined")
                .length > 0 || type === "feedbacks"
                ? 3
                : 6;

        if (WidgetValidate.isTitle(widget.title)) {
            resp.title = true;
            status = false;
        }

        if (WidgetTilesImgValidate(widget.rows) === "mixed") {
            status = false;
            resp.rows_imgs = false;
            msg = TILES_IMAGES_ARE_NOT_THE_SAME_TYPE;
        }

        if (widget.rows.length < 1 || widget.rows.length > max_item_count) {
            resp.rows_count = false;
            status = false;
            msg =
                type === "feedbacks"
                    ? messageErrors.list.countFeedbacks
                    : messageErrors.list.count;
        }

        if (widget.rows.length > 0) {
            let bt = 0;
            let descr = 0;

            widget.rows.forEach((item: list_item) => {
                let card = {
                    img: false,
                    title: false,
                    descr: false,
                    button: false,
                    geo: false,
                    time: false,
                    text: false,
                };

                if (item.img == "") {
                    status = false;
                    card.img = true;
                    resp.rows = true;
                }

                if (WidgetValidate.isTitle(item.title)) {
                    card.title = true;
                    status = false;
                }

                if (item.descr.trim() === "") descr++;

                // Если незаполнена кнопка
                if (item.button.trim() == "" || item.button_url == "") {
                    //Если в виджете необязательно заполнять кнопки
                    if (false === buttonIsRequired) {
                        // Проверим, есть ли заполненные кнопки
                        if (this.checkRowsHasFullButtons(widget.rows)) {
                            // Если есть заполненные - отметим текущую кнопку
                            bt++;
                            card.button = true;
                        }
                    }
                }

                if (item.geo == "") {
                    card.geo = true;
                    status = false;
                }

                if (item.time == "") {
                    card.time = true;
                    status = false;
                }

                if (item.text == "") {
                    card.text = true;
                    status = false;
                }

                // Если заполнена ссылка заголовка
                if (item.url !== undefined && item.url.trim() === "") {
                    // Проверим - заполнены ли остальные
                    if (this.checkRowsHasFilledTitleUrls(widget.rows)) {
                        // Если есть заполненные ссылки
                        msg = messageErrors.coverList.url;
                        card.title = true;
                        status = false;
                    }
                }

                if (buttonIsRequired) {
                    if (item.button.trim() == "") {
                        card.button = true;
                        status = false;
                    }
                }

                resp.rows_item.push(card);
            });

            if (widget.rows.length != bt && bt != 0) {
                status = false;
                resp.rows = true;
                resp.rows_button = true;
                msg = messageErrors.list.button;
            }

            if (widget.rows.length != descr && descr != 0) {
                status = false;
                resp.rows = true;
                msg = messageErrors.coverList.descr;
            }
        }

        return {
            status,
            resp,
            msg,
        };
    },

    /**
     * Валидация виджета "Список" с произвольными блоками
     * @param widget
     * @param type
     */
    listCommon: function (widget: any, type: string = "list") {
        let status = true;
        let msg = messageErrors.default;
        let resp = {
            title: false,
            rows_count: false,
            rows: false,
            rows_imgs: true,
            rows_button: false,
            rows_item: [],
        };

        let buttonsAreRequired: boolean = widget.has_buttons; // Выбрано отображение кнопок
        let imagesAreRequired: boolean = widget.has_images; // Выбрано отображение картинок
        let textAreRequired: boolean = widget.has_text; // Выбрано отображение полного описания

        const max_count_item: number = textAreRequired ? 3 : 6; // Максимальное количество элементов для виджета

        if (textAreRequired) {
            if (widget.rows.length < 1) {
                resp.rows_count = false;
                status = false;
                msg = messageErrors.listCommon.countMin;
            }

            if (widget.rows.length > max_count_item) {
                resp.rows_count = false;
                status = false;
                msg = messageErrors.listCommon.countMax;

                return {
                    status,
                    resp,
                    msg,
                };
            }
        } else {
            if (widget.rows.length < 1 || widget.rows.length > max_count_item) {
                resp.rows_count = false;
                status = false;
                msg = messageErrors.listCommon.count;
            }
        }

        // Валидируем заголовок виджета
        if (WidgetValidate.isTitle(widget.title)) {
            resp.title = true;
            status = false;
        }

        // Если заданы картинки - проверим являются ли они все одного типа
        if (imagesAreRequired) {
            if (WidgetTilesImgValidate(widget.rows) === "mixed") {
                status = false;
                resp.rows_imgs = false;
                msg = TILES_IMAGES_ARE_NOT_THE_SAME_TYPE;
            }
        }

        if (widget.rows.length > 0) {
            let bt = 0;

            widget.rows.forEach((item: list_item) => {
                let card = {
                    img: false,
                    title: false,
                    descr: false,
                    button: false,
                    geo: false,
                    time: false,
                    text: false,
                };

                if (WidgetValidate.isTitle(item.title)) {
                    card.title = true;
                    status = false;
                }

                if (imagesAreRequired) {
                    // Проверим, что заполнена и миниатюра и id иконки
                    if (item.img === "" || item.icon_id === "") {
                        status = false;
                        card.img = true;
                        resp.rows = true;
                    }
                }

                if (textAreRequired) {
                    if (item.text == "") {
                        card.text = true;
                        status = false;
                    }
                }

                // Если незаполнена кнопка
                if (item.button.trim() == "" || item.button_url == "") {
                    //Если в виджете обязательно заполнять кнопки
                    if (buttonsAreRequired) {
                        // Проверим, есть ли заполненные кнопки
                        if (this.checkRowsHasFullButtons(widget.rows)) {
                            // Если есть заполненные - отметим текущую кнопку
                            bt++;
                            card.button = true;
                        }
                    }
                }

                // Если заполнена ссылка заголовка
                if (item.url !== undefined && item.url.trim() === "") {
                    // Проверим - заполнены ли остальные
                    if (this.checkRowsHasFilledTitleUrls(widget.rows)) {
                        // Если есть заполненные ссылки
                        msg = messageErrors.coverList.url;
                        card.title = true;
                        status = false;
                    }
                }

                if (buttonsAreRequired) {
                    if (item.button.trim() == "") {
                        card.button = true;
                        status = false;
                    }
                }

                resp.rows_item.push(card);
            });

            if (widget.rows.length != bt && bt != 0) {
                status = false;
                resp.rows = true;
                resp.rows_button = true;
                msg = messageErrors.list.button;
            }
        }

        return {
            status,
            resp,
            msg,
        };
    },

    isTitle: (title: String) => {
        if (title.trim() === "" || title == null || title === undefined)
            return true;

        if (title.length > config.widgetLength.title) return true;

        return false;
    },

    isText: (title: String, length: number) => {
        if (title === null || title === undefined || title.trim() == "")
            return true;

        if (title.length > length) return true;

        return false;
    },

    isErrors: (errors, id, type) => {
        if (errors.rows_item.length <= id) return false;
        switch (type) {
            case "title":
                return errors.rows_item[id].title;
            case "img":
                return errors.rows_item[id].img;
            case "descr":
                return errors.rows_item[id].descr;
            case "geo":
                return errors.rows_item[id].geo;
            case "time":
                return errors.rows_item[id].time;
            case "button":
                return errors.rows_item[id].button;
            case "text":
                return errors.rows_item[id].text;
        }
        return false;
    },

    PointSale: (widget: widget) => {
        if (widget.code.more != "")
            if (widget.code.more_url == "") return false;

        if (widget.code.title == "") return false;

        return true;
    },

    checkRowsHasFullButtons: (rows: Array<object>): boolean => {
        let fullButtons = rows.filter((row: array_item) => {
            if (
                typeof row.button === "undefined" ||
                typeof row.button_url === "undefined"
            ) {
                return false;
            }

            let buttonText = row.button.trim();
            let buttonUrl = row.button_url.trim();
            return buttonText !== "" && buttonUrl !== "";
        });
        return fullButtons.length > 0;
    },

    checkRowsHasFilledTitleUrls: (rows: Array<object>): boolean => {
        let filledTitleUrls = rows.filter((row: array_item) => {
            if (typeof row.url === "undefined") {
                return false;
            }

            let titleUrl = row.url.trim();
            return titleUrl !== "";
        });
        return filledTitleUrls.length > 0;
    },

    feedbacks: function (widget: any, type: string) {
        let status = true;
        let msg = "";
        let resp = {
            title: false,
            rows_count: false,
            rows: false,
            rows_imgs: true,
            rows_button: false,
            rows_item: [],
        };

        const max_item_count: number = 3;

        if (WidgetValidate.isTitle(widget.title)) {
            msg = messageErrors.default;
            resp.title = true;
            status = false;
        }

        if (widget.rows.length < 1) {
            resp.rows_count = false;
            status = false;
            msg = messageErrors.feedbacks.count_min;
        } else if (widget.rows.length > max_item_count) {
            resp.rows_count = false;
            status = false;
            msg = messageErrors.feedbacks.count_max;
        }

        if (widget.rows.length > 0) {
            widget.rows.forEach((item: list_item) => {
                let card = {
                    img: false,
                    title: false,
                    descr: false,
                    button: false,
                };

                if (item.img == "") {
                    status = false;
                    card.img = true;
                    resp.rows = true;
                }

                if (WidgetValidate.isTitle(item.title)) {
                    card.title = true;
                    status = false;
                }

                resp.rows_item.push(card);
            });
        }

        return {
            status,
            resp,
            msg,
        };
    },
};

export default WidgetValidate;
