import React, { useContext } from "react";
import PagesNameForm from "../Сomponents/Forms/PagesNameForm/PagesNameForm";
import container from "../container";
import { Context } from "../context";

const PagesRenameController = () => {
    const PagesService = container.get("PagesService");
    const { pages, popout, location } = useContext(Context);

    const pageId = location.getPageId();
    const activePage = pages.getPage(pageId);

    const handleFormSubmit = async (value) => {
        popout.loading();

        const response = await PagesService.renamePage(activePage.id, value);

        popout.close();

        if (response.result === "success") {
            pages.updatePageMetaInfo(
                response.data.id,
                { name: response.data.name },
                () => {
                    window.history.back();
                }
            );
        } else {
            popout.error(response.message);
        }
    };

    return (
        <PagesNameForm value={activePage.name} onSubmit={handleFormSubmit} />
    );
};

export default PagesRenameController;
