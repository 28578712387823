import React from "react";
import { List, Cell, Placeholder } from "@vkontakte/vkui";
import {
    Icon16Add,
    Icon28ArticleOutline,
    Icon28DoneOutline,
    Icon28GraphOutline,
    Icon28RefreshOutline,
    Icon28ThumbsUpOutline,
} from "@vkontakte/icons";

declare type AppSettingsProps = {
    onMenuItemClick: Function;
    groupData?: any;
    vkIsRecommended?: boolean;
};

const AppSettings = (props: AppSettingsProps) => {
    const { onMenuItemClick, groupData, vkIsRecommended } = props;

    const menuItems = [
        {
            name: "Вид главной страницы",
            value: "view",
            icon: <Icon28ArticleOutline />,
        },
        {
            name: "Метрики",
            value: "metrics",
            icon: <Icon28GraphOutline />,
        },
        {
            name: "Обновить токены",
            value: "refresh-token",
            icon: <Icon28RefreshOutline />,
        },
        // {
        //     name: vkIsRecommended
        //         ? "Вы рекомендуете"
        //         : "Рекомендовать приложение",
        //     value: "recommend-app",
        //     icon: vkIsRecommended ? (
        //         <Icon28DoneOutline />
        //     ) : (
        //         <Icon28ThumbsUpOutline />
        //     ),
        // },
    ];

    const renderMenuItems = () => {
        return menuItems.map((item) => {
            return (
                <Cell
                    // style={{
                    //     pointerEvents:
                    //         item.value === "recommend-app" && vkIsRecommended
                    //             ? "none"
                    //             : "auto",
                    //     opacity:
                    //         item.value === "recommend-app" && vkIsRecommended
                    //             ? 0.5
                    //             : 1,
                    // }}
                    key={item.value}
                    onClick={() => {
                        onMenuItemClick(item.value);
                    }}
                    before={item.icon}
                >
                    {item.name}
                </Cell>
            );
        });
    };

    if (!groupData) {
        return (
            <Placeholder
                action={<></>}
                stretched
            >
                <div>Сообщество не подключено</div>
            </Placeholder>
        );
    }

    return (
        <div className="content--inner">
            <div className="AppSettings">
                <List>{renderMenuItems()}</List>
            </div>
        </div>
    );
};

export default AppSettings;
