import React from "react";
import PageButton from "../../hoc/PageButton";
import PageLink from "../../hoc/PageLink";
import { BlockData } from "../../../includes/Structures";
import Twemoji from "react-twemoji";
import { simpleTextToHtml } from "../../../includes/Helpers/TextToHtml";
import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import { DEFAULT_OVERLAY_OPACITY_VALUE } from "../../../includes/Constants";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../includes/Constants/PageBlockConstants";

type CoverProps = {
    data: BlockData;
    platform: string;
    isPreview: boolean;
    blockPadding?: number;
    blockAlignment?: BlockAlignment;
    textFontSize?: number;
};

const Cover = (props: CoverProps) => {
    const {
        data,
        platform,
        isPreview = false,
        blockPadding = 100,
        blockAlignment = "center",
        textFontSize = DEFAULT_BLOCK_TEXT_FONT_SIZE
    } = props;

    const backgroundStyles: any = {};

    if (data.background && data.background.url) {
        backgroundStyles.background = `url(${data.background.url})`;
        backgroundStyles.backgroundSize = "cover";
        backgroundStyles.backgroundPosition = "center";
    }

    const renderButton = () => {
        return (
            <PageButton
                isPreview={isPreview}
                pageId={data.page_id}
                blockId={data.id}
                data={data.button}
            />
        );
    };

    const renderUrl = () => {
        return (
            <PageLink
                isPreview={isPreview}
                id={data.button.id}
                pageId={data.page_id}
                blockId={data.id}
                url={data.button.url}
                text={data.button.text}
                textColor={data.button.text_color}
                color={data.button.color}
                className="B-Button__link_product"
            />
        );
    };

    const overlayOpacity =
        data.background.overlay_opacity || data.background.overlay_opacity === 0
            ? data.background.overlay_opacity
            : DEFAULT_OVERLAY_OPACITY_VALUE;

    const isFullscreen =
        data.meta && data.meta.is_fullscreen ? data.meta.is_fullscreen : false;

    return (
        <div
            className={`B-Block B-Cover ${blockAlignment} ${
                isFullscreen && platform !== "desktop_web" ? "fullscreen" : ""
            }`}
            style={{
                fontSize: `${textFontSize}em`,
            }}
        >
            <div className="B-Cover__background" style={backgroundStyles}>
                <div
                    className="GroupBannersForm__overlay"
                    style={{
                        background: `rgba(0, 0, 0, ${overlayOpacity}%)`,
                    }}
                ></div>
            </div>
            <div
                className="B-Cover__content"
                style={{
                    paddingTop: `${blockPadding}px`,
                    paddingBottom: `${blockPadding}px`,
                }}
            >
                {data.content.title &&
                    (data.has_title === undefined || data.has_title) && (
                        <div className="B-Cover__title B-Block__title">
                            <Twemoji options={{ className: "twemoji-promo" }}>
                                {data.content.title}
                            </Twemoji>
                        </div>
                    )}
                {data.content.text &&
                    (data.has_title === undefined || data.has_title) && (
                        <div
                            className="B-Cover__text B-Block__subtitle"
                            style={{ fontSize: "1em" }}
                        >
                            <Twemoji options={{ className: "twemoji-promo" }}>
                                {simpleTextToHtml(data.content.text)}
                            </Twemoji>
                        </div>
                    )}

                {data.button &&
                    (data.has_button === undefined || data.has_button) && (
                        <div className="B-Cover__button">
                            {data.button.action === "url"
                                ? renderUrl()
                                : renderButton()}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default Cover;
