import React, { useEffect, useRef, useState } from "react";

import CustomColorPicker from "../Elements/CustomColorPicker";
import { isHex } from "../../includes/Helpers/Helpers";
import { Icon16Add, Icon16PaletteOutline } from "@vkontakte/icons";

type ColorPickerProps = {
    /**
     * Выбранное значение
     */
    value?: string;

    /**
     * Коллбэк при выборе цвета
     */
    onSelect: (color) => void;

    /**
     * Цвета по умолчанию
     */
    defaultColors: { value: string }[];

    /**
     * Дефолтное значение нового цвета
     */
    defaultNewColorValue?: string;

    /**
     * Значение для атрибута name тега input с type=radio
     */
    inputName: string;

    /**
     * Кастомный цвет пикера
     */
    pickerColor: string;

    /**
     * Функция для изменения pickerColor
     */
    setPickerColor: Function;
};

const ColorPicker = (props: ColorPickerProps) => {
    const {
        onSelect,
        value = "var(--vkui--color_accent_blue)",
        defaultNewColorValue = "#E70B0B",
        defaultColors,
        pickerColor,
        inputName,
        setPickerColor,
    } = props;

    const pickerRef = useRef<any>();
    const paletteBtnRef = useRef<any>();

    const [isColorPickerOpened, setIsColorPickerOpened] = useState(false);

    const [colors, setColors] = useState(() => {
        if (isHex(value)) {
            return [{ value, isNewColor: true }, ...defaultColors];
        } else {
            return defaultColors;
        }
    });

    const [isAddedColor, setIsAddedColor] = useState(isHex(value));

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (
                isColorPickerOpened &&
                pickerRef.current &&
                !pickerRef.current.contains(e.target) &&
                !paletteBtnRef.current.contains(e.target)
            ) {
                setIsColorPickerOpened(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isColorPickerOpened]);

    const renderColorItem = (color, idx) => {
        let isActive =
            color.value === value || (color.isNewColor && isHex(value));

        return (
            <label
                className={`ColorPicker__label ${isActive ? "active" : ""}`}
                key={color.value + idx}
                htmlFor={color.value}
                style={{
                    backgroundColor: color.isNewColor
                        ? pickerColor
                        : color.value,
                }}
            >
                <input
                    checked={isActive}
                    className="ColorPicker__input"
                    name={inputName}
                    id={color.value}
                    value={color.value}
                    type="radio"
                    onChange={() => {
                        onSelect(color);
                    }}
                />
            </label>
        );
    };

    return (
        <div className="ColorPicker">
            <div className="ColorPicker__container">
                {!isAddedColor ? (
                    <label
                        className="ColorPicker__label ColorPicker__add"
                        onClick={() => {
                            const newColor = {
                                value: defaultNewColorValue,
                                isNewColor: true,
                            };

                            setColors([newColor, ...colors]);
                            setIsColorPickerOpened(true);
                            setIsAddedColor(true);
                            onSelect(newColor);
                        }}
                    >
                        <Icon16Add fill="var(--vkui--color_icon_tertiary)" />
                    </label>
                ) : (
                    <label
                        className="ColorPicker__label ColorPicker__add"
                        ref={paletteBtnRef}
                        onClick={() => {
                            setIsColorPickerOpened((prevVal) => !prevVal);
                        }}
                    >
                        <Icon16PaletteOutline fill="var(--vkui--color_icon_tertiary)" />
                    </label>
                )}

                {isColorPickerOpened ? (
                    <div ref={pickerRef}>
                        <CustomColorPicker
                            value={value}
                            colors={colors}
                            onSelect={onSelect}
                            setColors={setColors}
                            pickerColor={pickerColor}
                            setPickerColor={setPickerColor}
                        />
                    </div>
                ) : null}

                {colors.map(renderColorItem)}
            </div>
        </div>
    );
};

export default ColorPicker;
