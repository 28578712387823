import ApiVk from "../ApiVk";
import container from "../../container";
import { TileParams } from "../Structures";
import * as Sentry from "@sentry/react";

const cache = {};

export default (tiles: Array<TileParams>, dispatchCallback: Function) => {
    const api = ApiVk.getInstance({});

    const Logger = container.get("logger");

    if (!tiles && tiles.length == 0) return false;

    tiles.forEach(async (tile: TileParams, index: number) => {
        if (tile.icon_id.indexOf("id") < 0) return false;

        setTimeout(async () => {
            try {
                if (cache[tile.icon_id]) return false; // Уже менялось

                let id = tile.icon_id.replace("id", "");

                let result;

                if (id[0] === "-") {
                    // Если сообщество
                    id = id.replace("-", "");
                    result = await api.getCommunityById(parseInt(id, 10));
                } else {
                    // Если пользователь
                    result = await api.getUserImg(parseInt(id, 10));
                }

                if (result && result.response && result.response[0]) {
                    const item = result.response[0];
                    cache[tile.icon_id] = item.photo_200;
                    if (item.photo_200 && item.photo_200 !== tile.img) {
                        // Если изображение поменялось
                        dispatchCallback({
                            type: "img",
                            payload: {
                                cardID: index,
                                img: item.photo_200,
                                img_vk: tile.icon_id,
                            },
                        });
                    }
                }
            } catch (e) {
                Logger.error(
                    { code: 9034, message: e.message },
                    "runtime_error",
                    "UpdateImagesSettedByIds.ts"
                );

                Sentry.captureException(e);
            }
        }, index * 300);
    });
};
