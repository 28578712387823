import config from "../../includes/Config";
import Service from "./Service";

import LoggerInterface from "../Interfaces/LoggerInterface";
import { BlockData, PageLeadData } from "../Structures";
import moment from "moment";
import * as Sentry from "@sentry/react";

class PagesService extends Service {
    launchParams: any;
    logger: LoggerInterface;

    constructor(launchParams, logger: LoggerInterface) {
        super();

        this.launchParams = launchParams;
        this.logger = logger;
    }

    async getPage(page_id: string) {
        try {
            const requestData = this._createRequestData({
                id: page_id,
                user_time: moment(new Date()).format(),
            });

            const response = await fetch(config.getPageUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            return {
                result: "error",
                message: "Возникла ошибка при получении лендинга",
            };
        }
    }

    async getGroupPages(vk_group_id: number) {
        try {
            const requestData = this._createRequestData({
                vk_group_id: vk_group_id,
            });

            const response = await fetch(config.getGroupPages, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9110, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при получении лендингов",
            };
        }
    }

    async createPage(data: any) {
        try {
            const requestData = this._createRequestData(data);

            const response = await fetch(config.createPageUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9111, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при создании лендинга",
            };
        }
    }

    async renamePage(id: string, name: string) {
        try {
            const requestData = this._createRequestData({ id, name });

            const response = await fetch(config.renamePageUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9112, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при переименовании лендинга",
            };
        }
    }

    async deletePages(ids: string[]) {
        try {
            const requestData = this._createRequestData({ ids });

            const response = await fetch(config.deletePagesUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9113, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при удалении лендинга",
            };
        }
    }

    async addBlock(page_id: string, block: any) {
        try {
            const requestData = this._createRequestData({
                page_id: page_id,
                block: JSON.stringify(block),
            });

            const response = await fetch(config.addPageBlockUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9114, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при добавлении блока в конец лендинга",
            };
        }
    }

    async addTemplate(page_id: string, blocks: any[]) {
        try {
            const requestData = this._createRequestData({
                page_id: page_id,
                blocks: JSON.stringify(blocks),
            });

            const response = await fetch(config.addTemplateUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9115, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при создании лендинга из шаблона",
            };
        }
    }

    /**
     * Вставка блока
     * @param page_id - страница
     * @param block - данные блока
     * @param block_insert_after - id блока после которого необходимо вставить новый блок
     */
    async insertBlock(page_id: string, block: any, block_insert_after: string) {
        try {
            const requestData = this._createRequestData({
                page_id: page_id,
                block: JSON.stringify(block),
                block_insert_after: block_insert_after,
            });

            const response = await fetch(config.insertBlockUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9116, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при добавлении блока",
            };
        }
    }

    /**
     * Удаление блока
     * @param block_id
     */
    async deleteBlock(block_id: string, page_id: string) {
        try {
            const requestData = this._createRequestData({
                block_id: block_id,
                page_id: page_id,
            });

            const response = await fetch(config.deleteBlockUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9117, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при удалении блока",
            };
        }
    }

    /**
     * Изменения порядка блоков внутри страницы
     * @param page_id
     * @param sortData
     */
    async sortBlocks(page_id: string, sortData: any) {
        try {
            const requestData = this._createRequestData({
                page_id: page_id,
                sort_data: JSON.stringify(sortData),
            });

            const response = await fetch(config.sortBlocksUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            return {
                result: "error",
                message: "Возникла ошибка при изменении порядка блоков",
            };
        }
    }

    /**
     * Обновить данные блока страницы
     * @param blockData
     */
    async updateBlock(blockData: BlockData) {
        try {
            const requestData = this._createRequestData({
                block_data: JSON.stringify(blockData),
            });

            const response = await fetch(config.updateBlockUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9118, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при обновлении блока",
            };
        }
    }

    async publish(page_id: string) {
        try {
            const requestData = this._createRequestData({
                page_id: page_id,
            });

            const response = await fetch(config.publishPageUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9119, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при публикации лендинга",
            };
        }
    }

    async savePageState(page_id: string, state_index: number) {
        try {
            const requestData = this._createRequestData({
                page_id: page_id,
                state_index: state_index,
            });

            const response = await fetch(config.savePageStateUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9120, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при сохранении изменений лендинга",
            };
        }
    }

    /**
     * Отправить контактные данные лида
     * @param blockData
     */
    async sendLead(leadData: PageLeadData) {
        try {
            const requestData = this._createRequestData({
                data: JSON.stringify(leadData),
            });

            const response = await fetch(config.leadUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9121, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при отправке контактных данных",
            };
        }
    }

    /**
     * Отправить лида в триггер
     * @param blockData
     */
    async sendLeadTrigger(leadData: PageLeadData) {
        try {
            const requestData = this._createRequestData({
                data: JSON.stringify(leadData),
            });

            const response = await fetch(config.leadTriggerUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9122, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при отправке лида в триггер",
            };
        }
    }

    /**
     * Создать дубликат страницы
     * @param page_id
     */
    async copyPage(page_id: string) {
        try {
            const requestData = this._createRequestData({
                page_id: page_id,
            });

            const response = await fetch(config.copyPageUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9123, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при копировании лендинга",
            };
        }
    }

    /**
     * Копирование промо-страницы в другое сообщество
     * @param page_id
     * @param community_id
     */
    async copyPageToOtherGroup(page_id: string, community_id: number) {
        try {
            const requestData = this._createRequestData({
                page_id: page_id,
                target_vk_group_id: community_id,
            });

            const response = await fetch(config.copyPageToOtherGroupUrl, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9124, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при копировании лендинга в другое сообщество",
            };
        }
    }

    /**
     * Изменение статуса страницы
     * @param id
     * @param status
     */
    async changePageStatus(ids: string[], status: number) {
        try {
            const requestData = this._createRequestData({
                ids: ids,
                status: status,
            });

            const response = await fetch(config.changePageStatus, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9125, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при изменении статуса лендинга",
            };
        }
    }

    /**
     * Изменение статуса блока
     *
     * @param pageId
     * @param blockId
     * @param status
     */
    async changeBlockStatus(page_id: string, block_id: string, status: number) {
        try {
            const requestData = this._createRequestData({
                page_id,
                block_id,
                status,
            });

            const response = await fetch(config.changeBlockStatus, {
                method: "POST",
                body: requestData,
            });

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9126, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при изменении статуса блока",
            };
        }
    }

    /**
     * Изменение аудитории блока
     *
     * @param page_id
     * @param block_id
     * @param audience
     */
    async updateAudience(page_id: string, block_id: string, audience: object) {
        try {
            const requestData = this._createRequestData({
                audience: JSON.stringify(audience),
            });

            const response = await fetch(
                `${config.updateBlockAudienceUrl}/${page_id}/${block_id}`,
                {
                    method: "POST",
                    body: requestData,
                }
            );

            return this._formattedResponse(response);
        } catch (e) {
            this.logger.error(
                { code: 9127, message: e.message },
                "request_error",
                "PagesService.ts"
            );

            Sentry.captureException(e);

            return {
                result: "error",
                message: "Возникла ошибка при изменении аудитории блока",
            };
        }
    }
}

export default PagesService;
