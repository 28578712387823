import { countStringLength } from "../../../../includes/Helpers/Helpers";

function reducer(state, action) {
    switch (action.type) {
        case "title":
            return {
                ...state,
                status_title: action.payload.status,
                bottom_title: action.payload.buttom,
                title: action.payload.text,
            };
        case "title_error":
            return {
                ...state,
                status_title: action.payload.status,
                bottom_title: action.payload.buttom,
                title: action.payload.text,
            };
        case "title_url":
            return {
                ...state,
                status_title_url: action.payload.status
                    ? action.payload.status
                    : false,
                bottom_title_url: action.payload.buttom,
                url: action.payload.text,
            };
        case "title_url_error":
            return {
                ...state,
                status_title_url: action.payload.status,
                bottom_title_url: action.payload.buttom,
                destitle_urlcr: action.payload.text,
            };
        case "descr":
            return {
                ...state,
                status_descr: action.payload.status,
                bottom_descr: action.payload.buttom,
                descr: action.payload.text,
            };
        case "descr_error":
            return {
                ...state,
                status_descr: action.payload.status,
                bottom_descr: action.payload.buttom,
                descr: action.payload.text,
            };
        case "text":
            return {
                ...state,
                status_text: action.payload.status
                    ? action.payload.status
                    : false,
                bottom_text: action.payload.buttom,
                text: action.payload.text,
            };
        case "text_error":
            return {
                ...state,
                status_descr: action.payload.status,
                bottom_descr: action.payload.buttom,
                descr: action.payload.text,
            };

        case "geo":
            return {
                ...state,
                status_geo: action.payload.status
                    ? action.payload.status
                    : false,
                bottom_geo: action.payload.buttom,
                geo: action.payload.text,
            };
        case "geo_error":
            return {
                ...state,
                status_geo: action.payload.status,
                bottom_geo: action.payload.buttom,
                geo: action.payload.text,
            };
        case "time":
            return {
                ...state,
                status_time: action.payload.status
                    ? action.payload.status
                    : false,
                bottom_time: action.payload.buttom,
                time: action.payload.text,
            };
        case "time_error":
            return {
                ...state,
                status_time: action.payload.status,
                bottom_time: action.payload.buttom,
                time: action.payload.text,
            };
        case "update":
            return {
                ...state,
                title: action.payload.title,
                bottom_title: `Введено ${countStringLength(
                    action.payload.title
                )} из 100 симв.`,
                url: action.payload.url,
                bottom_title_url:
                    "Внутренняя ссылка vk.com, vk.me, vk.cc/{id} или vk.link",
                descr: action.payload.descr,
                bottom_descr: `Введено ${countStringLength(
                    action.payload.descr
                )} из 100 симв.`,
                text: action.payload.text,
                bottom_text: `Введено ${countStringLength(
                    action.payload.text
                )} из 300 симв.`,
                geo: action.payload.geo,
                bottom_geo: `Введено ${countStringLength(
                    action.payload.geo
                )} из 50 симв.`,
                time: action.payload.time,
                bottom_time: `Введено ${countStringLength(
                    action.payload.time
                )} из 50 симв.`,
            };

        default:
            throw new Error();
    }
}
export default reducer;
