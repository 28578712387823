function reducer(state, action) {
    const newRows = state.rows.map((item) => ({ ...item }));

    switch (action.type) {
        case "title":
            return {
                ...state,
                title: action.payload[0],
                title_url: action.payload[1],
            };
        case "more":
            return {
                ...state,
                more: action.payload[0],
                more_url: action.payload[1],
            };
        case "button":
            state.rows[action.payload[0]].button = action.payload[1][0];
            state.rows[action.payload[0]].button_url = action.payload[1][1];
            return { ...state };
        case "img":
            state.rows[action.payload.cardID].img = action.payload.img;
            state.rows[action.payload.cardID].img_vk = action.payload.img_vk;
            return { ...state };
        case "item":
            state.rows[action.payload[0]].title = action.payload[1].title;
            state.rows[action.payload[0]].descr = action.payload[1].descr;
            state.rows[action.payload[0]].url = action.payload[1].url;
            return { ...state };
        case "createCard":
            return {
                ...state,
                rows: [
                    ...state.rows,
                    {
                        img: "",
                        img_vk: "",
                        title: "",
                        descr: "",
                        url: "",
                        button: "",
                        button_url: "",
                    },
                ],
            };
        case "deleteCard":
            state.rows.splice(action.payload, 1);
            return { ...state };
        case "itemLeft":
            state.rows.splice(
                action.payload - 1,
                0,
                state.rows.splice(action.payload, 1)[0]
            );
            return { ...state };
        case "itemRight":
            state.rows.splice(
                action.payload + 1,
                0,
                state.rows.splice(action.payload, 1)[0]
            );
            return { ...state };
        case "sort":
            const { oldIndex, newIndex } = action.payload;
            let newTiles = [...state.rows];
            newTiles.splice(oldIndex, 1);
            newTiles.splice(newIndex, 0, state.rows[oldIndex]);
            state.rows = newTiles;
            return { ...state };
        case "sub":
            const item_id = action.payload.item_id;
            newRows[item_id].button = action.payload.data.button;
            newRows[item_id].button_url = action.payload.data.button_url;
            return { ...state, rows: newRows };
        default:
            throw new Error();
    }
}
export default reducer;
