import config from "../../includes/Config";
import LoggerInterface from "../Interfaces/LoggerInterface";
import LaunchParams from "./LaunchParams";
import Service from "./Service";

type leadVar = {
    n: string
    v: string | number
}

type leadVariablesRequestBody = {
    params: LaunchParams,
    vars: string[] | leadVar[],
}

class VariablesService extends Service {
    launchParams: any;
    logger: LoggerInterface;

    constructor(launchParams, logger: LoggerInterface) {
        super();

        this.launchParams = launchParams;
        this.logger = logger;
    }

    /**
     * Метод для получения всех глобальных переменных группы
     */
    async getGlobalVariables() {
        try {
            const response = await fetch(config.getGlobalVariables, {
                method: "POST",
                body: JSON.stringify({
                    params: this.launchParams.params,
                    vk_group_id: this.launchParams.params.vk_group_id,
                }),
            });

            return this._formattedResponse(response);
        } catch (e) {
            console.error(e);
            return {
                result: "error",
                message: e.message,
            };
        }
    }

    /**
     * Метод для получения всех пользовательский переменных группы
     */
    async getLeadVariables() {
        try {
            const response = await fetch(config.getLeadVariables, {
                method: "POST",
                body: JSON.stringify({
                    params: this.launchParams.params,
                    vk_group_id: this.launchParams.params.vk_group_id,
                }),
            });

            return this._formattedResponse(response);
        } catch (e) {
            console.error(e);
            return {
                result: "error",
                message: e.message,
            };
        }
    }

    /**
     * Метод для установки пользовательских переменных
     */
    async setLeadVariables(body: leadVariablesRequestBody) {
        try {
            const response = await fetch(config.setLeadVariables, {
                method: "POST",
                body: JSON.stringify({
                    params: body.params,
                    vars: body.vars
                }),
            });

            return this._formattedResponse(response);
        } catch (e) {
            console.error(e);
            return {
                result: "error",
                message: e.message,
            };
        }
    }

    /**
     * Метод для получения пользовательских переменных по их названиям
     */
    async getLeadVariablesByNames(body: leadVariablesRequestBody) {
        try {
            const response = await fetch(config.getLeadVariablesByNames, {
                method: "POST",
                body: JSON.stringify({
                    params: body.params,
                    vars: body.vars,
                }),
            });

            return this._formattedResponse(response);
        } catch (e) {
            console.error(e);
            return {
                result: "error",
                message: e.message,
            };
        }
    }
}

export default VariablesService;
