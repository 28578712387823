import { LaunchParams } from "../Structures";
import RequestClientInterface from "../Interfaces/RequestClientInterface";
import GroupDataServiceInterface from "../Interfaces/GroupDataServiceInterface";

class GroupDataService implements GroupDataServiceInterface {
    host: string;
    params: LaunchParams;
    saveSettingsUrl: string;
    saveMetricsUrl: string;
    saveFileUrl: string;

    client: RequestClientInterface;

    constructor(
        host: string,
        params: LaunchParams,
        client: RequestClientInterface
    ) {
        this.host = host;
        this.params = params;
        this.client = client;
        this.saveSettingsUrl = "/ajax/vkapp/SaveAppSettings";
        this.saveMetricsUrl = "/ajax/vkapp/SaveAppMetrics";
        this.saveFileUrl = "/ajax/vkapp/SaveAppBannerFile";
    }

    /**
     * Сохранение данных систем аналитик для сообществ
     * @param data
     */
    async saveMetrics(data: object) {
        const body = this._createRequestData(data);
        const url = `${this.host}${this.saveMetricsUrl}`;
        const resp = await this.client.request("POST", url, body);
        return resp;
    }

    /**
     * Сохранение общих настроек приложения
     * @param type srting
     */
    async saveSettings(data: any): Promise<any> {
        try {
            const body = this._createRequestData(data);
            const url = `${this.host}${this.saveSettingsUrl}`;
            const resp = await this.client.request("POST", url, body);
            return resp;
        } catch (e) {
            return {
                success: false,
                message: e.message,
            };
        }
    }

    async saveBannerFile(file: any) {
        try {
            const body = this._createRequestData({ file: file });
            const url = `${this.host}${this.saveFileUrl}`;
            const resp = await this.client.request("POST", url, body);
            return resp;
        } catch (e) {
            return {
                success: false,
                message: e.message,
            };
        }
    }

    _createRequestData(data) {
        let requestData = new FormData();

        for (let key in data) {
            if (Array.isArray(data[key])) {
                if (data[key].length === 0) {
                    requestData.append(`${key}[]`, "");
                } else {
                    data[key].forEach((i) => {
                        requestData.append(`${key}[]`, i);
                    });
                }
            } else {
                requestData.append(key, data[key]);
            }
        }

        try {
            requestData.append("params", JSON.stringify(this.params));
        } catch (e) {
            console.log(e);
        }

        return requestData;
    }
}

export default GroupDataService;
