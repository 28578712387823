import React from "react";

import TextEdit from "./Edit/Text/TextEdit";
import CoverEdit from "./Edit/Cover/CoverEdit";
import VideoEdit from "./Edit/Video/VideoEdit";

import ImagesGalleryEdit from "./Edit/Images/ImagesGallery/ImagesGalleryEdit";
import ImagesSingleEdit from "./Edit/Images/ImagesSingle/ImagesSingleEdit";

import AdvantagesEdit from "./Edit/Advantages/AdvantagesEdit";
import ReviewsEdit from "./Edit/Reviews/ReviewsEdit";
import ProductsEdit from "./Edit/Products/ProductsEdit";
import ButtonEdit from "./Edit/Button/ButtonEdit";
import TimerDateEdit from "./Edit/Timer/TimerDate/TimerDateEdit";
import DividerEdit from "./Edit/Divider/DividerEdit";
import TimerFixedEdit from "./Edit/Timer/TimerFixed/TimerFixedEdit";
import TimerUserFirstVisitEdit from "./Edit/Timer/TimerUserFirstVisit/TimerUserFirstVisitEdit";

type BlockEditProps = {
    data: any;
    onSubmit: Function;
    currentDynamicInput: string;
    openModal: Function;
    maxItemsCount?: number;
};

const BlockEdit = (props: BlockEditProps) => {
    const {
        data,
        onSubmit,
        currentDynamicInput,
        openModal,
        maxItemsCount = 3,
    } = props;

    const handleSubmit = (state) => {
        const blockData = { ...data, ...state };
        onSubmit(blockData);
    };

    const getEditForm = (data) => {
        let key_path = `${data.type}.${data.key}`;

        switch (key_path) {
            case "text.t1":
                return (
                    <TextEdit
                        data={{
                            content: data.content,
                            meta: data.meta ? data.meta : {},
                        }}
                        onSubmit={handleSubmit}
                        currentDynamicInput={currentDynamicInput}
                        openModal={openModal}
                    />
                );
            case "timer.tm1":
                return (
                    <TimerDateEdit
                        data={{
                            content: data.content,
                            background: data.background,
                            has_button: data.has_button,
                            has_title: data.has_title,
                            has_background: data.has_background,
                            button: data.button,
                            meta: data.meta ? data.meta : {},
                        }}
                        onSubmit={handleSubmit}
                        openModal={openModal}
                        currentDynamicInput={currentDynamicInput}
                    />
                );
            case "timer.tm2":
                return (
                    <TimerFixedEdit
                        data={{
                            content: data.content,
                            background: data.background,
                            has_button: data.has_button,
                            has_title: data.has_title,
                            has_background: data.has_background,
                            button: data.button,
                            meta: data.meta ? data.meta : {},
                        }}
                        onSubmit={handleSubmit}
                        openModal={openModal}
                        currentDynamicInput={currentDynamicInput}
                    />
                );
            case "timer.tm3":
                return (
                    <TimerUserFirstVisitEdit
                        data={{
                            content: data.content,
                            background: data.background,
                            has_button: data.has_button,
                            has_title: data.has_title,
                            has_background: data.has_background,
                            has_text_after_expiration: !!data.has_text_after_expiration,
                            button: data.button,
                            meta: data.meta ? data.meta : {},
                        }}
                        onSubmit={handleSubmit}
                        openModal={openModal}
                        currentDynamicInput={currentDynamicInput}
                    />
                );
            case "other.ot1":
                return (
                    <DividerEdit
                        data={{
                            meta: data.meta ? data.meta : {},
                        }}
                        onSubmit={handleSubmit}
                    />
                );
            case "cover.c1":
                return (
                    <CoverEdit
                        data={{
                            content: data.content,
                            background: data.background,
                            button: data.button,
                            has_button: !!data.has_button,
                            has_title: !!data.has_title,
                            meta: data.meta ? data.meta : {},
                        }}
                        openModal={openModal}
                        currentDynamicInput={currentDynamicInput}
                        onSubmit={handleSubmit}
                    />
                );

            case "button.b1":
                return (
                    <ButtonEdit
                        data={{
                            button: data.button,
                        }}
                        currentDynamicInput={currentDynamicInput}
                        onSubmit={handleSubmit}
                    />
                );

            case "image.i1":
                return (
                    <ImagesGalleryEdit
                        data={{
                            content: data.content,
                            items: data.items,
                            has_title:
                                data.has_title === undefined
                                    ? true
                                    : data.has_title,
                        }}
                        openModal={openModal}
                        currentDynamicInput={currentDynamicInput}
                        onSubmit={handleSubmit}
                        maxItemsCount={maxItemsCount}
                    />
                );

            case "image.i2":
                return (
                    <ImagesSingleEdit
                        data={{
                            content: data.content,
                        }}
                        openModal={openModal}
                        onSubmit={handleSubmit}
                    />
                );

            case "video.v1":
                return (
                    <VideoEdit
                        data={{
                            content: data.content,
                            video: data.video,
                            has_title:
                                data.has_title === undefined
                                    ? true
                                    : data.has_title,
                        }}
                        openModal={openModal}
                        currentDynamicInput={currentDynamicInput}
                        onSubmit={handleSubmit}
                    />
                );

            case "advantages.a1":
                return (
                    <AdvantagesEdit
                        data={{
                            content: data.content,
                            items: data.items,
                            has_title:
                                data.has_title === undefined
                                    ? true
                                    : data.has_title,
                        }}
                        openModal={openModal}
                        currentDynamicInput={currentDynamicInput}
                        onSubmit={handleSubmit}
                        maxItemsCount={maxItemsCount}
                    />
                );

            case "products.p1":
                return (
                    <ProductsEdit
                        data={{
                            content: data.content,
                            items: data.items,
                            has_title:
                                data.has_title === undefined
                                    ? true
                                    : data.has_title,
                        }}
                        block_id={data.id}
                        openModal={openModal}
                        currentDynamicInput={currentDynamicInput}
                        onSubmit={handleSubmit}
                        maxItemsCount={maxItemsCount}
                    />
                );

            case "reviews.r1":
                return (
                    <ReviewsEdit
                        data={{
                            content: data.content,
                            items: data.items,
                            has_title:
                                data.has_title === undefined
                                    ? true
                                    : data.has_title,
                        }}
                        openModal={openModal}
                        currentDynamicInput={currentDynamicInput}
                        onSubmit={handleSubmit}
                        maxItemsCount={maxItemsCount}
                    />
                );
            default:
                return <div>Неизвестный тип блока</div>;
        }
    };

    return <div className="BlockEdit">{getEditForm(data)}</div>;
};

export default BlockEdit;
