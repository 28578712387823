import React, { useContext, useState } from "react";
import { Header, Spinner } from "@vkontakte/vkui";
import CloseOrSavePopupFooter from "./CloseOrSavePopupFooter";

import { Context } from "../../../context";
import container from "../../../container";
import { BlockData } from "../../../includes/Structures";
import ReactSlider from "react-slider";
import {
    CHANGE_FONT_SIZE_POPUP_SLIDER_MAX_VALUE,
    CHANGE_FONT_SIZE_POPUP_SLIDER_MIN_VALUE,
    CHANGE_FONT_SIZE_POPUP_SLIDER_STEP,
} from "../../../includes/Constants/PageBlockConstants";

type ChangeFontSizePopupPropsType = {
    blockRef?: any;
    block: BlockData;
    platform: string;
    textFontSize: number;
    setTextFontSize: (values: number) => void;
    setTextFontSizePopupOpened: (textFontSizePopupOpened: boolean) => void;
};

const POPUP_FOOTER_TYPE = {
    popup_type: "text-font-size-popup",
    label: "Размер текста",
};

const ChangeFontSizePopup = (props: ChangeFontSizePopupPropsType) => {
    const {
        blockRef,
        block,
        platform,
        setTextFontSizePopupOpened,
        textFontSize,
        setTextFontSize,
    } = props;

    const PagesService = container.get("PagesService");

    const { popout, snackbar, location, pages } = useContext(Context);

    const page_id = location.getPageId();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const saveBlockTextFontSize = async (
        blockData: BlockData
    ): Promise<void> => {
        const dataToBackend = {
            ...blockData,
            meta: {
                ...blockData.meta,
                text_font_size: textFontSize,
            },
        };

        setIsLoading(true);

        const response = await PagesService.updateBlock(dataToBackend);

        if (response.result === "success") {
            snackbar.showSuccess("Размер текста изменен");

            pages.updateBlock(block.id, page_id, dataToBackend);

            setIsLoading(false);
        } else {
            popout.error(response.message);
            setIsLoading(false);
        }
    };

    const popup_classnames = ["IndentsPopup"];

    if (platform !== "desktop_web") {
        popup_classnames.push("ChangeFontSizePopup__mobile");
    }

    if (isLoading) {
        popup_classnames.push("loading");
    }

    return (
        <div className="IndentsPopup__box" ref={blockRef}>
            <div
                className={popup_classnames.join(" ")}
                style={{
                    top: platform !== "desktop_web" ? -132 : 50,
                }}
            >
                {isLoading ? <Spinner size="regular" /> : null}

                <div className="IndentsPopup__wrapper">
                    <div>
                        <Header>Размер текста: {textFontSize}em</Header>

                        <div className="IndentsPopup__slider">
                            <ReactSlider
                                step={CHANGE_FONT_SIZE_POPUP_SLIDER_STEP}
                                min={CHANGE_FONT_SIZE_POPUP_SLIDER_MIN_VALUE}
                                max={CHANGE_FONT_SIZE_POPUP_SLIDER_MAX_VALUE}
                                value={textFontSize}
                                className="ReactSlider"
                                trackClassName="ReactSlider__sliderTrack_line"
                                onChange={setTextFontSize}
                                minDistance={10}
                                renderThumb={(props) => (
                                    <div
                                        {...props}
                                        className="ReactSlider__sliderThumb"
                                    ></div>
                                )}
                            />
                        </div>
                    </div>
                </div>

                <CloseOrSavePopupFooter
                    type={POPUP_FOOTER_TYPE}
                    closePopup={() => {
                        setTextFontSizePopupOpened(false);
                    }}
                    savePopupResult={() => {
                        saveBlockTextFontSize(block);
                    }}
                />
            </div>
        </div>
    );
};

export default ChangeFontSizePopup;
