import React, { Component } from "react";
import {
    Cell,
    PanelHeader,
    PanelHeaderContent,
    List,
    PanelHeaderContext,
    platform,
    Platform,
    PanelHeaderButton,
    Tooltip,
    Div,
} from "@vkontakte/vkui";

import container from "../container";

import { ReactComponent as SubscriptionsIcon } from "../resources/icons/mail.svg";
import { ReactComponent as MySubscriptionsIcon } from "../resources/icons/my.svg";
import { ReactComponent as WidgetsIcon } from "../resources/icons/widgets.svg";
import { ReactComponent as SettingsIcon } from "../resources/icons/settings.svg";
import { ReactComponent as SettingsColoredIcon } from "../resources/icons/settings_colored.svg";
import { ReactComponent as PersonalIcon } from "../resources/icons/personal.svg";
import { ReactComponent as LinkCircleIcon } from "../resources/icons/link-circle.svg";
import { ReactComponent as ChatIcon } from "../resources/icons/chat.svg";
import { ReactComponent as PromosIcon } from "../resources/icons/promos.svg";
import Logger from "../includes/Services/Logger";
import { Icon16Dropdown, Icon24Back, Icon24Done, Icon28ChevronBack } from "@vkontakte/icons";
import * as Sentry from "@sentry/react";

const getIcon = (type: string) => {
    switch (type) {
        case "subscriptions":
            return (
                <span className="MainHeader__icon">
                    <SubscriptionsIcon />
                </span>
            );
        case "mysubscriptions":
            return (
                <span className="MainHeader__icon">
                    <MySubscriptionsIcon />
                </span>
            );
        case "widgets":
            return (
                <span className="MainHeader__icon">
                    <WidgetsIcon />
                </span>
            );
        case "initial-settings":
            return (
                <span className="MainHeader__icon">
                    <SettingsIcon />
                </span>
            );
        case "cabinet-link":
            return (
                <span className="MainHeader__icon">
                    <PersonalIcon />
                </span>
            );
        case "chat-link":
            return (
                <span className="MainHeader__icon">
                    <ChatIcon />
                </span>
            );
        case "pages":
            return (
                <span className="MainHeader__icon">
                    <PromosIcon />
                </span>
            );
        case "app-settings":
            return (
                <span className="MainHeader__icon">
                    <SettingsColoredIcon />
                </span>
            );
        default:
            return null;
    }
};

const launchParams = container.get("LaunchParams");

class MainHeader extends Component<any, any> {
    modes: object;
    logger: Logger;

    constructor(props) {
        super(props);

        const { userRole } = props;

        this.modes = {
            subscriptions: {
                text: this.props.subscription
                    ? "Оформление подписки"
                    : "Главная страница",
            },
            mysubscriptions: {
                text: "Мои подписки",
            },
        };

        if (userRole === "admin") {
            this.modes["app-settings"] = {
                text: "Настройки",
            };
            this.modes["widgets"] = {
                text: "Виджеты",
            };
            this.modes["pages"] = {
                text: "Лендинги",
            };
        }

        this.logger = container.get("logger");

        this.state = {
            contextOpened: false,
            mode: props.list_type,
            mode_text: this.modes[props.list_type].text,
        };
        this.toggleContext = this.toggleContext.bind(this);
        this.select = this.select.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            mode: props.list_type,
            mode_text:
                props.initSubscriptionId && props.list_type === "subscriptions"
                    ? "Оформление подписки"
                    : this.modes[props.list_type].text,
        });
    }

    toggleContext() {
        const { seenContextTooltip, resolveContextTooltip } = this.props;
        if (this.state.contextOpened && !seenContextTooltip) {
            resolveContextTooltip && resolveContextTooltip();
        }
        this.setState({ contextOpened: !this.state.contextOpened });
    }

    select(e) {
        const mode = e.currentTarget.dataset.mode;

        let mode_text = this.modes[mode].text;
        this.setState({ mode, mode_text });
        this.props.changeList(mode);
        requestAnimationFrame(this.toggleContext);
    }

    renderBackButton() {
        const { locationSubscription, initSubscriptionId } = this.props;

        const isLocationsSubscription =
            locationSubscription && !initSubscriptionId;

        if (isLocationsSubscription) {
            return (
                <PanelHeaderButton
                    onClick={() => {
                        window.history.back();
                    }}
                    aria-label="back"
                >
                    {platform() === Platform.IOS ? (
                        <Icon28ChevronBack />
                    ) : (
                        <Icon24Back />
                    )}
                </PanelHeaderButton>
            );
        }

        return null;
    }

    renderAdminTitleContent() {
        const { seenTitleTooltip, resolveTitleTooltip } = this.props;

        if (seenTitleTooltip) {
            return <span style={{ fontSize: 17 }}>{this.state.mode_text}</span>;
        }

        return (
            <Tooltip
                offsetX={-20}
                text="Нажмите, чтобы открыть меню приложения."
                onClose={resolveTitleTooltip}
            >
                <span>{this.state.mode_text}</span>
            </Tooltip>
        );
    }

    renderUserTitleContent() {
        return <span>{this.state.mode_text}</span>;
    }

    renderInitialSettingsCellContent() {
        const { seenContextTooltip, seenTitleTooltip, visitedInitialSettings } =
            this.props;

        if (seenContextTooltip) {
            const notVisitedSettingsAfterGuide =
                seenContextTooltip &&
                seenTitleTooltip &&
                !visitedInitialSettings;

            return (
                <span
                    className={
                        notVisitedSettingsAfterGuide
                            ? "MainHeader__cell_notify"
                            : ""
                    }
                >
                    Настройки рассылок
                </span>
            );
        }

        return (
            <Tooltip
                offsetX={-20}
                alignY="top"
                text="Для правильной работы приложения ознакомтесь с инструкцией по настройке"
                onClose={() => {}}
            >
                <span>Настройки рассылок</span>
            </Tooltip>
        );
    }

    renderSubscriberHeader() {
        const { group } = this.props;

        let title = "";

        if (group && group.data && group.data.title) {
            title = group.data.title;
        }

        return (
            <PanelHeader>
                <PanelHeaderContent>{title}</PanelHeaderContent>
            </PanelHeader>
        );
    }

    render() {
        const {
            groupIsConnectedOnSite,
            isAdmin,
            list_type,
            group,
            isSinglePage,
        } = this.props;

        try {
            // Если просмотр от простого пользователя и страница подписки
            if (false === isAdmin && isSinglePage) {
                return this.renderSubscriberHeader();
            }

            return (
                <div className="MainHeader__wrapper">
                    <PanelHeader
                        before={this.renderBackButton()}
                        fixed
                    >
                        <PanelHeaderContent
                            aside={<Icon16Dropdown style={{ marginRight: 9 }} />}
                            onClick={this.toggleContext}
                        >
                            <Div style={{ padding: launchParams.params.vk_platform === 'desktop_web' ? "16px": '0 16px' }}>
                                {isAdmin
                                    ? this.renderAdminTitleContent()
                                    : this.renderUserTitleContent()}
                            </Div>
                        </PanelHeaderContent>
                    </PanelHeader>
                    <PanelHeaderContext
                        opened={this.state.contextOpened}
                        onClose={this.toggleContext}
                    >
                        <List>
                            {Object.keys(this.modes).map((key) => {
                                if (
                                    key === "initial-settings" &&
                                    groupIsConnectedOnSite
                                ) {
                                    return null;
                                }

                                let title = this.modes[key].text;
                                let mode = key;

                                return (
                                    <Cell
                                        key={mode}
                                        after={
                                            this.state.mode === mode ? (
                                                <Icon24Done fill="var(--vkui--color_text_accent)" />
                                            ) : null
                                        }
                                        onClick={this.select}
                                        before={getIcon(key)}
                                        data-mode={mode}
                                        data-text={title}
                                    >
                                        {title}
                                    </Cell>
                                );
                            })}
                            {isAdmin && (
                                <>
                                    <Cell
                                        key="chat-link"
                                        after={<LinkCircleIcon />}
                                        before={getIcon("chat-link")}
                                        className="MainHeader__fillwidth-cell"
                                    >
                                        <a
                                            className="MainHeader__link"
                                            href="https://vk.com/write-139157852"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Чат с поддержкой
                                        </a>
                                    </Cell>
                                </>
                            )}
                        </List>
                    </PanelHeaderContext>
                </div>
            );
        } catch (e) {
            this.logger.error(
                { code: 9057, message: e.message },
                "render_error",
                "MainHeader.jsx"
            );

            Sentry.captureException(e);

            return null;
        }
    }
}

export default MainHeader;
