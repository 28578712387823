import React, { useContext, useEffect, useRef, useState } from "react";
import IndentsAndAlignBlockPopup from "../Elements/Popups/IndentsAndAlignBlockPopup";
import IndentsBlockPopup from "../Elements/Popups/IndentsBlockPopup";
import AlignBlockPopup from "../Elements/Popups/AlignBlockPopup";

import {
    Icon28EditOutline,
    Icon28ArrowDownOutline,
    Icon28ArrowUpOutline,
    Icon28CopyOutline,
    Icon28HideOutline,
    Icon28DeleteOutlineAndroid,
    Icon28MoreHorizontal,
    Icon28ViewOutline,
    Icon24Filter,
    Icon28UsersOutline,
    Icon28UserOutgoingOutline,
    Icon28UserIncomingOutline,
    Icon28TextOutline
} from "@vkontakte/icons";

import {
    ActionSheet,
    ActionSheetDefaultIosCloseItem,
    ActionSheetItem,
    platform as VKPlatform,
    Platform
} from "@vkontakte/vkui";

import { BlockAlignment } from "../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import { Context } from "../../context";
import { BlockData } from "../../includes/Structures";
import { blockHasAudience, setHash } from "../../includes/Helpers/Helpers";
import container from "../../container";
import ChangeFontSizePopup from "../Elements/Popups/ChangeFontSizePopup";

type PagesEditableBlockSnackbarProps = {
    block: BlockData;
    platform: string;
    isAllowMoveUp: boolean;
    isAllowMoveDown: boolean;
    blockPadding: number;
    textFontSize: number;
    blockAlignment?: BlockAlignment;
    onUpClick?: Function;
    onDownClick?: Function;
    onEditClick?: Function;
    onCopyClick?: Function;
    onDeleteClick?: Function;
    onDisableClick?: Function;
    insertBlockAudience?: Function;
    setBlockAlignment?: (blockAlignment: BlockAlignment) => void;
    setBlockPadding?: (values: number) => void;
    setTextFontSize?: (values: number) => void;
};

const getCurrentAlignment = (blockAlignment: BlockAlignment): string => {
    switch (blockAlignment) {
        case "center":
            return "По центру";
        case "left":
            return "Слева";
        case "right":
            return "Справа";
        default:
            break;
    }
};

const PagesEditableBlockSnackbar = (props: PagesEditableBlockSnackbarProps) => {
    const {
        block,
        platform,
        onEditClick,
        onDownClick,
        onUpClick,
        onCopyClick,
        onDeleteClick,
        isAllowMoveDown,
        isAllowMoveUp,
        onDisableClick,
        setBlockPadding,
        blockPadding,
        blockAlignment,
        setBlockAlignment,
        insertBlockAudience,
        textFontSize,
        setTextFontSize
    } = props;

    const { popout, location, snackbar, pages, groupSiteData } =
        useContext(Context);

    const launchParams = container.get("LaunchParams");

    const [
        indentsAndAlignBlockPopupOpened,
        setIndentsAndAlignBlockPopupOpened,
    ] = useState<boolean>(false);

    const [indentsBlockPopupOpened, setIndentsBlockPopupOpened] =
        useState<boolean>(false);

    const [alignBlockPopupOpened, setAlignBlockPopupOpened] =
        useState<boolean>(false);

    const [textFontSizePopupOpened, setTextFontSizePopupOpened] =
        useState<boolean>(false);

    const indentsAndAlignBlockPopupRef = useRef<any>();
    const indentsBlockPopupRef = useRef<any>();
    const textFontSizeBlockPopupRef = useRef<any>();
    const alignBlockPopupRef = useRef<any>();
    const indentsSnackbarBtnRef = useRef<any>();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (
                indentsAndAlignBlockPopupOpened &&
                indentsAndAlignBlockPopupRef.current &&
                !indentsAndAlignBlockPopupRef.current.contains(e.target) &&
                !indentsSnackbarBtnRef.current.contains(e.target)
            ) {
                if (!indentsBlockPopupOpened && !alignBlockPopupOpened) {
                    setIndentsAndAlignBlockPopupOpened(false);
                }

                if (
                    indentsBlockPopupOpened &&
                    indentsBlockPopupRef.current &&
                    !indentsBlockPopupRef.current.contains(e.target)
                ) {
                    setIndentsBlockPopupOpened(false);
                    setIndentsAndAlignBlockPopupOpened(false);
                }

                if (
                    alignBlockPopupOpened &&
                    alignBlockPopupRef.current &&
                    !alignBlockPopupRef.current.contains(e.target)
                ) {
                    setAlignBlockPopupOpened(false);
                    setIndentsAndAlignBlockPopupOpened(false);
                }
            }

            if (
                textFontSizePopupOpened &&
                textFontSizeBlockPopupRef.current &&
                !textFontSizeBlockPopupRef.current.contains(e.target)
            ) {
                setTextFontSizePopupOpened(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [
        indentsAndAlignBlockPopupOpened,
        indentsBlockPopupOpened,
        alignBlockPopupOpened,
        textFontSizePopupOpened
    ]);

    const copiedAudience = pages.getCopiedBlockAudience();
    const isUserAdmin =
        launchParams.get_url_params().vk_viewer_group_role === "admin";

    const popoutRef = useRef();

    return (
        <div className="PagesEditableBlockSnackbar">
            <div className="PagesEditableBlockSnackbar__buttons">
                <button
                    title="Редактировать"
                    className="PagesEditableBlockSnackbar__button"
                    onClick={() => {
                        onEditClick && onEditClick(block);
                    }}
                >
                    <Icon28EditOutline
                        fill="#fff"
                        width={platform === "desktop_web" ? 20 : 24}
                    />
                </button>

                {indentsAndAlignBlockPopupOpened && (
                    <IndentsAndAlignBlockPopup
                        platform={platform}
                        blockAlignment={blockAlignment}
                        blockRef={indentsAndAlignBlockPopupRef}
                        getCurrentAlignment={getCurrentAlignment}
                        alignBlockPopupOpened={alignBlockPopupOpened}
                        indentsBlockPopupOpened={indentsBlockPopupOpened}
                        setAlignBlockPopupOpened={setAlignBlockPopupOpened}
                        setIndentsBlockPopupOpened={setIndentsBlockPopupOpened}
                    />
                )}

                {indentsBlockPopupOpened && (
                    <IndentsBlockPopup
                        block={block}
                        platform={platform}
                        blockRef={indentsBlockPopupRef}
                        blockPadding={blockPadding}
                        blockAlignment={blockAlignment}
                        setBlockPadding={setBlockPadding}
                        setIndentsBlockPopupOpened={setIndentsBlockPopupOpened}
                    />
                )}

                {alignBlockPopupOpened && (
                    <AlignBlockPopup
                        block={block}
                        platform={platform}
                        blockRef={alignBlockPopupRef}
                        blockPadding={blockPadding}
                        blockAlignment={blockAlignment}
                        setBlockAlignment={setBlockAlignment}
                        getCurrentAlignment={getCurrentAlignment}
                        setAlignBlockPopupOpened={setAlignBlockPopupOpened}
                    />
                )}

                {textFontSizePopupOpened && (
                    <ChangeFontSizePopup
                        block={block}
                        platform={platform}
                        blockRef={textFontSizeBlockPopupRef}
                        textFontSize={textFontSize}
                        setTextFontSize={setTextFontSize}
                        setTextFontSizePopupOpened={setTextFontSizePopupOpened}
                    />
                )}

                {block && block.sub_type !== "other_separator" ? (
                    <button
                        ref={indentsSnackbarBtnRef}
                        title="Настроить отступы"
                        className={`PagesEditableBlockSnackbar__button PagesEditableBlockSnackbar__button_indents ${
                            indentsAndAlignBlockPopupOpened
                                ? "active"
                                : "inactive"
                        }`}
                        onClick={() => {
                            if (
                                alignBlockPopupOpened ||
                                indentsBlockPopupOpened
                            ) {
                                setAlignBlockPopupOpened(false);
                                setIndentsBlockPopupOpened(false);
                            }

                            setIndentsAndAlignBlockPopupOpened(
                                (prevVal) => !prevVal
                            );
                        }}
                    >
                        <Icon24Filter
                            fill="#fff"
                            width={platform === "desktop_web" ? 22 : 28}
                        />
                    </button>
                ) : (
                    ""
                )}

                <button
                    title="Переместить вниз"
                    className={`PagesEditableBlockSnackbar__button ${
                        isAllowMoveDown ? "" : "disabled"
                    }`}
                    onClick={() => {
                        isAllowMoveDown && onDownClick && onDownClick(block);
                    }}
                >
                    <Icon28ArrowDownOutline
                        fill="#fff"
                        width={platform === "desktop_web" ? 22 : 28}
                    />
                </button>

                <button
                    title="Переместить вверх"
                    className={`PagesEditableBlockSnackbar__button ${
                        isAllowMoveUp ? "" : "disabled"
                    }`}
                    onClick={() => {
                        isAllowMoveUp && onUpClick && onUpClick(block);
                    }}
                >
                    <Icon28ArrowUpOutline
                        fill="#fff"
                        width={platform === "desktop_web" ? 22 : 28}
                    />
                </button>

                {platform === "desktop_web" && isUserAdmin && (
                    <button
                        title="Настроить аудиторию"
                        className="PagesEditableBlockSnackbar__button"
                        onClick={() => {
                            setHash({
                                list: "pages",
                                view: "pages-edit",
                                panel: "audience",
                                page_id: location.getPageId(),
                                block_id: block.id,
                            });
                        }}
                    >
                        <div className="PagesEditableBlockSnackbar__audience-wrapper">
                            <div className="PagesEditableBlockSnackbar__audience-icon-wrapper">
                                <Icon28UsersOutline
                                    fill="#fff"
                                    width={platform === "desktop_web" ? 22 : 28}
                                />
                            </div>

                            {block.audience &&
                                blockHasAudience(block.audience.data) && (
                                    <span
                                        className="PagesEditableBlockSnackbar__audience-indicator"
                                        style={{
                                            background:
                                                block.audience.status === 1
                                                    ? "#5cb85c"
                                                    : "#E64646",
                                        }}
                                    />
                                )}
                        </div>
                    </button>
                )}

                <button
                    title="Дублировать"
                    className="PagesEditableBlockSnackbar__button"
                    onClick={() => {
                        onCopyClick && onCopyClick(block);
                    }}
                >
                    <Icon28CopyOutline
                        fill="#fff"
                        width={platform === "desktop_web" ? 22 : 28}
                    />
                </button>

                <button
                    title="Еще опции"
                    className="PagesEditableBlockSnackbar__button"
                    ref={popoutRef}
                    onClick={(e) => {
                        e.stopPropagation();

                        popout.open(
                            <ActionSheet
                                onClose={() => popout.close()}
                                toggleRef={popoutRef}
                                iosCloseItem={
                                    <ActionSheetDefaultIosCloseItem />
                                }
                                className={`PagesEditableBlockSnackbar__popout ${
                                    platform == "desktop_web" ? "desktop" : ""
                                }`}
                            >
                                {platform !== "desktop_web" && (
                                    <ActionSheetItem
                                        onClick={() => {
                                            setHash({
                                                list: "pages",
                                                view: "pages-edit",
                                                panel: "audience",
                                                page_id: location.getPageId(),
                                                block_id: block.id,
                                            });
                                        }}
                                        before={
                                            <Icon28UsersOutline
                                                width={
                                                    platform === "desktop_web"
                                                        ? 22
                                                        : 24
                                                }
                                            />
                                        }
                                        autoClose
                                    >
                                        <div
                                            className="PagesEditableBlockSnackbar__mobile-audience-button"
                                        >
                                            <div
                                                style={{
                                                    color: "var(--vkui--color_text_primary)",
                                                }}
                                            >
                                                Настроить аудиторию
                                            </div>
                                        </div>
                                    </ActionSheetItem>
                                )}

                                {block.audience && (
                                    <ActionSheetItem
                                        onClick={() => {
                                            pages.setCopiedBlockAudience({
                                                blockId: block.id,
                                                status: block.audience.status,
                                                data: block.audience.data,
                                            });

                                            snackbar.showSuccess(
                                                "Аудитория скопирована"
                                            );
                                        }}
                                        before={
                                            <Icon28UserOutgoingOutline
                                                width={
                                                    platform === "desktop_web"
                                                        ? 22
                                                        : 24
                                                }
                                            />
                                        }
                                        autoClose
                                    >
                                        <span
                                            style={{
                                                color: "var(--vkui--color_text_primary)",
                                            }}
                                        >
                                            Скопировать аудиторию
                                        </span>
                                    </ActionSheetItem>
                                )}

                                {copiedAudience.blockId &&
                                    block.id !== copiedAudience.blockId && (
                                        <ActionSheetItem
                                            onClick={() => {
                                                const blockData = {
                                                    ...block,
                                                    audience: {
                                                        status: copiedAudience.status,
                                                        data: copiedAudience.data,
                                                    },
                                                };

                                                insertBlockAudience &&
                                                    insertBlockAudience(
                                                        block.id,
                                                        block.page_id,
                                                        blockData
                                                    );
                                            }}
                                            before={
                                                <Icon28UserIncomingOutline
                                                    width={
                                                        platform ===
                                                        "desktop_web"
                                                            ? 22
                                                            : 28
                                                    }
                                                />
                                            }
                                            autoClose
                                        >
                                            <span
                                                style={{
                                                    color: "var(--vkui--color_text_primary)",
                                                }}
                                            >
                                                Вставить аудиторию
                                            </span>
                                        </ActionSheetItem>
                                    )}

                                {block.sub_type !== "image_single" &&
                                    block.sub_type !== "button_base" &&
                                    block.sub_type !== "other_separator" && (
                                        <ActionSheetItem
                                            onClick={() => {
                                                setTextFontSizePopupOpened(
                                                    (prevVal) => !prevVal
                                                );
                                            }}
                                            before={
                                                <Icon28TextOutline width={24} />
                                            }
                                            style={{
                                                color: "var(--vkui--color_text_primary)",
                                            }}
                                            autoClose
                                        >
                                            Размер текста
                                        </ActionSheetItem>
                                    )}

                                <ActionSheetItem
                                    onClick={() => {
                                        const statusToSend =
                                            block.status === 1 ? 0 : 1;

                                        onDisableClick &&
                                            onDisableClick(
                                                block,
                                                block.page_id,
                                                block.id,
                                                statusToSend
                                            );
                                    }}
                                    before={
                                        block && block.status === 0 ? (
                                            <Icon28ViewOutline
                                                width={24}
                                                fill="var(--vkui--color_accent_green)"
                                            />
                                        ) : (
                                            <Icon28HideOutline
                                                style={{ paddingRight: 3 }}
                                                fill="var(--vkui--color_accent_gray)"
                                                width={22}
                                                height={22}
                                            />
                                        )
                                    }
                                    autoClose
                                >
                                    <span
                                        style={{
                                            color:
                                                block && block.status === 0
                                                    ? "var(--vkui--color_accent_green)"
                                                    : "var(--vkui--color_text_primary)",
                                        }}
                                    >
                                        {block && block.status === 1
                                            ? "Скрыть блок"
                                            : "Показать блок"}
                                    </span>
                                </ActionSheetItem>
                                <ActionSheetItem
                                    mode="destructive"
                                    onClick={() => {
                                        onDeleteClick && onDeleteClick();
                                    }}
                                    before={
                                        <Icon28DeleteOutlineAndroid
                                            width={24}
                                            fill={
                                                "var(--vkui--color_accent_red)"
                                            }
                                        />
                                    }
                                    autoClose
                                >
                                    Удалить блок
                                </ActionSheetItem>

                                {VKPlatform() !== Platform.IOS && (
                                    <ActionSheetItem
                                        className={`${
                                            VKPlatform() === Platform.ANDROID
                                                ? "action-sheet-android-cancel"
                                                : ""
                                        } PagesContextPopout__item cancel`}
                                        mode="default"
                                        autoClose
                                    >
                                        Отмена
                                    </ActionSheetItem>
                                )}
                            </ActionSheet>
                        );
                    }}
                >
                    <Icon28MoreHorizontal
                        fill="#fff"
                        width={platform === "desktop_web" ? 22 : 28}
                    />
                </button>
            </div>
        </div>
    );
};

export default PagesEditableBlockSnackbar;
