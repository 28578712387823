import React, { useState } from "react";
import { Header, Div, Group, Button, Headline } from "@vkontakte/vkui";
import ItemBannersCard from "../../Controls/ItemBannersCard";
import ModalWidgetEditHeader from "../ModalWidgetEditHeader";
import ModalBannerItem from "../ModalEdit/Item";
import ModalBannerButton from "../ModalEdit/Button";
import reducer from "./reducer";
import initialState from "./state";
import useUpdate from "../../../includes/Hooks/useUpdate";
import WidgetHeader from "../WidgetHeader";

import {
    SortableContainer as sortableContainer,
    SortableElement as sortableElement,
} from "react-sortable-hoc";

export const initalErrors = {
    title: false,
    title_url: false,
    more_url: false,
    rows: false,
    rows_count: false,
    rows_title: false,
    rows_descr: false,
    rows_button: false,
    rows_url: false,
    rows_item: [],
};

const BannersEdit = (props) => {
    const { onEditMode } = props;

    const [editMode, setEditMode] = useState(false);
    const [state, dispatch]: any = useUpdate({
        ...props,
        reducer,
        initialState,
        type: "banners",
    });
    const errors = { ...initalErrors, ...props.errors.resp };

    const modals = {
        editHeader: {
            title: "Заголовок виджета",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.title, url: state.title_url }}
                    type="title"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editLink: {
            title: "Ссылка",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.more, url: state.more_url }}
                    type="more"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editItem: {
            title: "Редактировать элемент",
            body: (state, callback, close, cardID) => (
                <ModalBannerItem
                    state={state.rows[cardID]}
                    type="cover_list"
                    onSelect={callback}
                    close={close}
                    cardID={cardID}
                />
            ),
            callback: dispatch,
            state,
        },
        addButton: {
            title: "Добавить кнопку",
            body: (state, callback, close, cardID) => (
                <ModalBannerButton
                    state={state.rows[cardID]}
                    onSelect={callback}
                    close={close}
                    cardID={cardID}
                />
            ),
            callback: dispatch,
            state,
        },
    };

    const handleCoverClick = (i) => {
        props.onModalRequest({
            id: "card-img",
            args: {
                callback: dispatch,
                cardID: i,
                type: "cover_list",
                type_api: "banners",
            },
        });
    };

    const renderEditMode = () => (
        <SortableContainer
            axis="y"
            lockToContainerEdges={true}
            useWindowAsScrollContainer={true}
            lockAxis="y"
            helperClass="tile-sort-helper"
            pressDelay={300}
            onSortStart={({ node, index, collection, isKeySorting }, event) => {
                muteElements();
            }}
            onSortEnd={({ oldIndex, newIndex }) => {
                dispatch({
                    type: "sort",
                    payload: {
                        oldIndex,
                        newIndex,
                    },
                });
            }}
        >
            {state.rows.map((card, i) => (
                <SortableItem
                    key={`item-${i}`}
                    index={i}
                    value={{
                        card: card,
                        key: `item-${i}`,
                        index: i,
                    }}
                />
            ))}
        </SortableContainer>
    );

    const SortableContainer = sortableContainer(({ children }) => {
        return <div>{children}</div>;
    });

    const SortableItem = sortableElement((localProps) => {
        const {
            value: { card, index },
        } = localProps;
        return renderCard(card, index);
    });

    const muteElements = () => {
        const elem = document.querySelectorAll(".ItemBannersCard");
        Array.prototype.slice.call(elem).forEach((item) => {
            item.style.opacity = ".5";
        });
    };

    const renderDefaultMode = () => (
        <div>
            {state.rows.map((card, i) => renderCard(card, i))}
            {state.rows.length < 3 && (
                <ItemBannersCard
                    img="add"
                    edit={editMode}
                    onCoverClick={() => {
                        dispatch({ type: "createCard" });
                    }}
                    imgOnly
                />
            )}
        </div>
    );

    const renderCard = (card, i) => {
        return (
            <ItemBannersCard
                key={i}
                length={state.rows.length}
                edit={editMode}
                img={card.img}
                onTitleClick={() =>
                    props.onModalRequest({
                        id: "dynamic",
                        args: { ...modals.editItem, cardID: i },
                    })
                }
                onButtonClick={() =>
                    props.onModalRequest({
                        id: "dynamic",
                        args: { ...modals.addButton, cardID: i },
                    })
                }
                onCoverClick={() => handleCoverClick(i)}
                onLeftClick={() => {
                    dispatch({ type: "itemLeft", payload: i });
                }}
                onRightClick={() => {
                    dispatch({ type: "itemRight", payload: i });
                }}
                onRemoveClick={() => {
                    dispatch({ type: "deleteCard", payload: i });
                }}
                title={card.title}
                description={card.descr}
                button={card.button}
                hasError={errors.rows_item[i]}
                hasErrorUrl={errors.rows_url}
                hasErrorButton={errors.rows_button}
                hasErrorDescr={errors.rows_descr}
            />
        );
    };

    return (
        <Group>
            <Header
                aside={
                    <Button
                        onClick={() => {
                            setEditMode(!editMode);
                            onEditMode && onEditMode(!editMode);
                        }}
                        mode="secondary"
                    >
                        {editMode ? "Готово" : "Править"}
                    </Button>
                }
            >
                <Headline level="2" weight="1">
                    Обложки с акцией
                </Headline>
            </Header>
            <WidgetHeader
                onModalRequest={props.onModalRequest}
                state={state}
                modals={modals}
                edit={editMode}
                errors={errors}
            />
            <Div>
                {state.rows && (
                    <>{editMode ? renderEditMode() : renderDefaultMode()}</>
                )}
            </Div>
        </Group>
    );
};

export default BannersEdit;
