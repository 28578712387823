import bridge from '@vkontakte/vk-bridge';
import container from "../../container";
import LoggerInterface from "../Interfaces/LoggerInterface";
import * as Sentry from "@sentry/react";

declare global {
    interface Window {
        VK: any;
    }
}

class VkPixelCommon {
    logger: LoggerInterface;

    constructor() {
        this.logger = container.get("logger");
    }

    event(pixelCode: string, eventName: string) {
        try {
            bridge.send("VKWebAppRetargetingPixel", {
                pixel_code: pixelCode,
                event: eventName,
            });
        } catch (error) {
            this.logError(
                `Pixel event with pixel_code=${pixelCode} and event=${eventName} wasn't sent because of error`,
                11003
            );

            Sentry.captureException(error);
        }
    }

    goal(
        pixelCode: string,
        conversionEvent: string,
        conversionValue: number = null
    ) {
        try {
            const bridgeData: any = {
                pixel_code: pixelCode,
                conversion_event: conversionEvent,
            };

            if (conversionValue) {
                bridgeData.conversion_value = conversionValue;
            }

            bridge.send("VKWebAppConversionHit", bridgeData);
        } catch (error) {
            this.logError(
                `Pixel conversion hit with pixel_code=${pixelCode} and conversionEvent=${conversionEvent} wasn't sent because of error`,
                11004
            );

            Sentry.captureException(error);
        }
    }

    logError(message: string, code: number) {
        this.logger.error(
            {
                message,
                code,
            },
            "pixel_error",
            "VkPixelCommon.ts"
        );
    }
}

export default new VkPixelCommon();
