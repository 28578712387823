import React from "react";

import Cover from "./Cover";
import Video from "./Video";
import Text from "./Text";
import Button from "./Button";
import Advantages from "./Advantages";

import ImagesGallery from "./Images/ImagesGallery";
import ImagesSingle from "./Images/ImagesSingle";

import Reviews from "./Reviews";
import Products from "./Products";
import TimerDate from "./TimerDate";
import Divider from "./Divider";
import TimerFixed from "./TimerFixed";
import TimerUserFirstVisit from "./TimerUserFirstVisit";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../includes/Constants/PageBlockConstants";

type BlockWrapperProps = {
    data: any; // Данные блока
    platform: string; // Платформа, на которой запущено приложение
    resizer: any;
    isPreview: boolean; // Страница запущена в режиме предпросмотра или нет
};

const BlockWrapper = (props: BlockWrapperProps) => {
    const { data, platform, resizer, isPreview } = props;

    const getBlockPadding = () => {
        let default_block_padding;

        switch (data.type) {
            case "cover":
                default_block_padding = 100;
                break;
            case "advantages":
                default_block_padding = 40;
                break;
            case "text":
                default_block_padding = 20;
                break;
            case "button":
                default_block_padding = 20;
                break;
            case "image":
                default_block_padding = 0;
                break;
            case "reviews":
                default_block_padding = 20;
                break;
            case "products":
                default_block_padding = 25;
                break;
            case "video":
                default_block_padding = 0;
                break;
            case "timer":
                default_block_padding = 80;
                break;
            default:
                default_block_padding = 0;
                break;
        }

        return data.meta && (data.meta.padding || data.meta.padding === 0)
            ? data.meta.padding
            : default_block_padding;
    };

    const blockAlignment =
        data.meta && data.meta.alignment ? data.meta.alignment : "center";

    const textFontSize =
        data.meta &&
        (data.meta.text_font_size || data.meta.text_font_size === 0)
            ? data.meta.text_font_size
            : DEFAULT_BLOCK_TEXT_FONT_SIZE;

    const blocks = {
        cover: {
            c1: (
                <Cover
                    data={data}
                    platform={platform}
                    isPreview={isPreview}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        timer: {
            tm1: (
                <TimerDate
                    data={data}
                    platform={platform}
                    isPreview={isPreview}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
            tm2: (
                <TimerFixed
                    data={data}
                    platform={platform}
                    isPreview={isPreview}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
            tm3: (
                <TimerUserFirstVisit
                    data={data}
                    platform={platform}
                    isPreview={isPreview}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        other: {
            ot1: <Divider data={data} isPreview={isPreview} />,
        },

        advantages: {
            a1: (
                <Advantages
                    data={data}
                    resizer={resizer}
                    platform={platform}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        text: {
            t1: (
                <Text
                    data={data}
                    isPreview={isPreview}
                    onButtonClick={() => {}}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        button: {
            b1: (
                <Button
                    data={data}
                    isPreview={isPreview}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                />
            ),
        },

        image: {
            // Галерея изображений
            i1: (
                <ImagesGallery
                    data={data}
                    isEdit={false}
                    resizer={resizer}
                    platform={platform}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                    hasButtons={platform === "desktop_web"}
                    textFontSize={textFontSize}
                />
            ),
            // Изобаржение
            i2: (
                <ImagesSingle
                    data={data}
                    isEdit={false}
                    resizer={resizer}
                    platform={platform}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                />
            ),
        },

        reviews: {
            r1: (
                <Reviews
                    data={data}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        products: {
            p1: (
                <Products
                    data={data}
                    resizer={resizer}
                    platform={platform}
                    isPreview={isPreview}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                />
            ),
        },

        video: {
            v1: (
                <Video
                    data={data}
                    blockPadding={getBlockPadding()}
                    blockAlignment={blockAlignment}
                    textFontSize={textFontSize}
                    isPreview={isPreview}
                    iframeOnLoad={() => {
                        resizer.setLandingHeight();
                    }}
                />
            ),
        },
    };

    let blockComponent = null;

    if (blocks[data.type] && blocks[data.type][data.key]) {
        blockComponent = blocks[data.type][data.key];
    } else {
        // log error
    }

    return <>{blockComponent}</>;
};

export default BlockWrapper;
