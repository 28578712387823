import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
    ActionSheet,
    ActionSheetDefaultIosCloseItem,
    ActionSheetItem,
    Div,
    Platform,
    platform,
} from "@vkontakte/vkui";
import { Scrollbars } from "react-custom-scrollbars";

import { Context } from "../../../context";

type VkIconsPopoutPropsType = {
    imgHelpText?: string;
    svgIcons: { id: number; icon_name: string; icon: ReactElement }[];
    setSvgIconObj: (obj: { id: number; icon: ReactElement }) => void;
    setImgHelpText?: (imgHelpText: string) => void;
};

const VkIconsPopout = (props: VkIconsPopoutPropsType) => {
    const { setSvgIconObj, imgHelpText, setImgHelpText } = props;

    let { svgIcons } = props;

    const [search, setSearch] = useState("");

    const { popout } = useContext(Context);

    const chooseIcon = (icon: { id: number; icon: ReactElement }): void => {
        if (imgHelpText) {
            setImgHelpText("");
        }

        setSvgIconObj(icon);
        popout.close();
    };

    if (search.trim()) {
        svgIcons = svgIcons.filter((p) => {
            if (
                search.trim() === "" ||
                p.icon_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
            )
                return true;
            return false;
        });
    }

    const turnOnPopoutScroll = (e) => {
        return e.stopPropagation();
    };

    useEffect(() => {
        const popoutWrapper =
            document.querySelector(".vkuiPopoutRoot__popout") || null;

        if (popoutWrapper) {
            popoutWrapper.addEventListener("touchmove", turnOnPopoutScroll);
        }

        return () => {
            if (popoutWrapper) {
                popoutWrapper.removeEventListener(
                    "touchmove",
                    turnOnPopoutScroll
                );
            }
        };
    }, []);

    return (
        <ActionSheet
            className="VkIconsPopout__ActionSheet"
            onClose={() => popout.close()}
            iosCloseItem={<ActionSheetDefaultIosCloseItem />}
        >
            <ActionSheetItem className="VkIconsPopout" autoClose={false}>
                {/* Поиск для иконок. Может пригодиться. Не убирать!!! */}
                {/* <Search
                    onChange={(e) => {
                        setSearch(e.currentTarget.value);
                    }}
                    after={platform() === IOS ? "Отмена" : ""}
                /> */}

                <Scrollbars autoHeightMax={480} thumbMinSize={60} autoHeight>
                    <Div className="VkIconsPopout__wrapper">
                        {svgIcons.map((el) => (
                            <div
                                className="VkIconsPopout__icon-block"
                                key={el.id}
                                onClick={() => {
                                    chooseIcon(el);
                                }}
                            >
                                {el.icon}
                            </div>
                        ))}
                    </Div>
                </Scrollbars>
            </ActionSheetItem>

            {platform() !== Platform.IOS && (
                <ActionSheetItem
                    className={`PagesContextPopout__item ${
                        platform() === Platform.ANDROID
                            ? "action-sheet-android-cancel"
                            : ""
                    } cancel`}
                    mode="default"
                    autoClose
                >
                    Отмена
                </ActionSheetItem>
            )}
        </ActionSheet>
    );
};

export default VkIconsPopout;
