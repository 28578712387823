class ImagesHelper {
    /**
     * Считывает файл как base64 строку
     * @param file File
     */
    readImageFile(file: File): Promise<string | ArrayBuffer> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            /*
             * Подписываемся на событие окончания действия FileReader
             * В данном случае - readAsDataUrl
             */
            reader.addEventListener(
                "load",
                () => resolve(reader.result),
                false
            );

            /*
             * Запускает процесс чтения данных указанного File в base64 строку
             */
            reader.readAsDataURL(file);
        });
    }

    async getImageFileDimensions(imageDataUrl): Promise<any> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageDataUrl;
            image.onload = function () {
                resolve({ size_x: image.width, size_y: image.height });
            };
        });
    }

    readImageUrl(url: string) {}

    createImage(url): any {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", (error) => reject(error));
            image.setAttribute("crossOrigin", "anonymous");
            image.src = url;
        });
    }

    getRadianAngle(degreeValue) {
        return (degreeValue * Math.PI) / 180;
    }

    /**
     * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
     * @param {File} image - Image File url
     * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
     * @param {number} rotation - optional rotation parameter
     */
    async getCroppedImg(imageSrc, pixelCrop, rotation = 0): Promise<any> {
        const image = await this.createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxSize = Math.max(image.width, image.height);
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea;
        canvas.height = safeArea;

        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate(this.getRadianAngle(rotation));
        ctx.translate(-safeArea / 2, -safeArea / 2);

        // draw rotated image and store data.
        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        );
        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
            data,
            Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
            Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
        );
        let mimeData = this.getBase64MimeType(imageSrc);

        return new Promise((resolve) => {
            canvas.toBlob((file) => {
                resolve({
                    url: URL.createObjectURL(file),
                    blob: file,
                    mime: mimeData.mime,
                    ext: mimeData.ext,
                });
            }, mimeData.mime);
        });
    }

    getBase64MimeType(encoded) {
        let mime = "image/jpeg";
        let ext = "jpg";

        if (typeof encoded !== "string") {
            return { mime, ext };
        }

        var match = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (match && match.length) {
            mime = match[1];
            ext = mime.split("/")[1];
            if (ext === "jpeg") {
                ext = "jpg";
            }
        }

        return { mime, ext };
    }
}

export default new ImagesHelper();
