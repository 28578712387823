import { FormItem, FormLayout } from "@vkontakte/vkui";
import React from "react";
import InputDynamic from "../../Controls/InputDynamic";

const BlockHeadForm = (props) => {
    const {
        title,
        text,
        changeTitle,
        changeText,
        currentDynamicInput,
    } = props;

    return (
        <FormLayout>
            <FormItem style={{ paddingTop: 6, paddingBottom: 0 }}>
                <InputDynamic
                    currentOpened={currentDynamicInput}
                    type="text"
                    status="default"
                    bottomText=""
                    top="Заголовок"
                    value={title}
                    placeholder="Введите заголовок"
                    name="title"
                    useVariables={true}
                    variablesMode="pages"
                    onChange={(value) => {
                        changeTitle(value);
                    }}
                />
            </FormItem>

            <FormItem>
                <InputDynamic
                    currentOpened={currentDynamicInput}
                    type="text"
                    status="default"
                    bottomText=""
                    top="Текст"
                    value={text}
                    placeholder="Введите текст"
                    name="text"
                    textarea={true}
                    useVariables={true}
                    variablesMode="pages"
                    onChange={(value) => {
                        changeText(value);
                    }}
                />
            </FormItem>
        </FormLayout>
    );
};

export default BlockHeadForm;
