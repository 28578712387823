import React, { useContext, useState } from "react";
import { Footer, Button } from "@vkontakte/vkui";
import PagesPreviewItem from "../Сomponents/Pages/PagesPreviewItem";
import { Context } from "../context";
import container from "../container";
import { pageHasUnpublishedState } from "../includes/Helpers/HelpersPages";
import { Analytics, PAGES_PUBLISH } from "../includes/Metrics/Analytics";
import bridge from "@vkontakte/vk-bridge";

type PagesPreviewControllerType = {
    type: "edit" | "published";
};

const PagesPreviewController = (props: PagesPreviewControllerType) => {
    const { type } = props;

    const { location, popout, pages, resizer, groupSiteData } =
        useContext(Context);

    const page_id = location.getPageId();

    const [isPublished, setIsPublished] = useState(false);

    const PagesService = container.get("PagesService");
    const LaunchParams = container.get("LaunchParams");

    const page_url = `http://vk.com/app${LaunchParams.params.vk_app_id}_-${LaunchParams.params.vk_group_id}#page=${page_id}`;

    const publishPage = async () => {
        popout.loading();

        const response = await PagesService.publish(page.id);

        popout.close();

        if (response.result === "success") {
            if (bridge.supports("VKWebAppTapticNotificationOccurred")) {
                bridge.send("VKWebAppTapticNotificationOccurred", {
                    type: "success",
                });
            }
            popout.successAnimation(
                "Изменения опубликованы",
                "",
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        size="l"
                        href={page_url}
                        target="_blank"
                        mode="tertiary"
                    >
                        Перейти
                    </Button>
                </div>
            );
            setIsPublished(true);
            if (
                response.data.published_blocks &&
                Array.isArray(response.data.published_blocks)
            ) {
                pages.updatePagePublishedBlocks(
                    page.id,
                    response.data.published_blocks
                );
            }
            Analytics.goal(PAGES_PUBLISH, "pages");
        } else {
            if (bridge.supports("VKWebAppTapticNotificationOccurred")) {
                bridge.send("VKWebAppTapticNotificationOccurred", {
                    type: "error",
                });
            }

            let actions = [];

            response.message =
                response.message === "Внимание - на тарифе Free доступен только один активный лендинг"
                    ? 'Недоступно'
                    : response.message

            popout.errorAnimation(response.message, actions);
        }
    };

    if (!page_id) {
        return <Footer>Страница не найдена</Footer>;
    }

    const page = pages.getPage(page_id);

    if (!page) {
        return <Footer>Страница не найдена</Footer>;
    }

    const blocks = type === "edit" ? [...page.blocks_edit] : [...page.blocks];
    let isAllowPublish = pageHasUnpublishedState(page);

    return (
        <PagesPreviewItem
            blocks={blocks}
            isAllowPublish={isAllowPublish && !isPublished}
            type={type}
            platform={LaunchParams.params.vk_platform}
            onPublishClick={publishPage}
            resizer={resizer}
        />
    );
};

export default PagesPreviewController;
