import React, { useContext } from "react";
import { Button } from "@vkontakte/vkui";
import Api from "../../../includes/ApiVk";
import { Context } from "../../../context";
import WidgetService from "../../../includes/Services/WidgetService";
import { ReactComponent as IconCopy } from "../../../resources/icons/icon-copy.svg";
import container from "../../../container";

const launchParams = container.get("LaunchParams");

const CopyToCommunityAction = (props) => {
    const api = Api.getInstance({});

    const { snackbar, currentModal, popout } = useContext(Context);
    const params = launchParams.params;

    const { items, copyInCurrentGroup, batch = false } = props;

    const handleApply = async (selectedCommunities) => {
        popout.loading();

        const communitiesIds = selectedCommunities.map((c) => c.id);
        const widgetsIds = items.map((w) => w.id);
        const payload = await WidgetService.copyToCommunity(
            widgetsIds,
            communitiesIds,
            params.vk_group_id
        );

        if (payload.result === "success") {
            popout.close();
            snackbar.showSuccess(
                items.length > 1 ? "Виджеты скопированы" : "Виджет скопирован"
            );
            // Если скопировали виджет в рамках этого же сообщеста - выполним коллбэк на обновление виджетов
            if (communitiesIds.indexOf(params.vk_group_id) >= 0) {
                copyInCurrentGroup &&
                    copyInCurrentGroup(
                        payload.response.all,
                        payload.response.new
                    );
            }
        } else {
            popout.error(payload.message);
        }
    };

    const handleClick = async () => {
        const response = await api.getUserCommunities(
            params.vk_user_id,
            "admin"
        );

        if (!response.items) {
            popout.error("Не удалось получить сообщества");
            return false;
        }

        currentModal.set({
            id: "user-communities",
            args: {
                items: response.items,
                currentGroup: params.vk_group_id,
                callback: (communities) => {
                    handleApply(communities);
                },
            },
        });
    };

    const isAllowCopy = items.length > 0;

    if (batch) {
        return (
            <Button
                className="Widgets-snackbar-button"
                size="m"
                mode="tertiary"
                style={{
                    color: isAllowCopy
                        ? "var(--vkui--color_text_accent)"
                        : "var(--vkui--color_text_secondary)",
                }}
                onClick={
                    isAllowCopy
                        ? () => {
                              handleClick();
                          }
                        : () => {}
                }
            >
                <IconCopy
                    fill={
                        isAllowCopy
                            ? "var(--vkui--color_text_accent)"
                            : "var(--vkui--color_text_secondary)"
                    }
                />
            </Button>
        );
    }

    return (
        <div
            onClick={handleClick}
            style={{
                padding: "0px 20px",
            }}
        >
            Копировать
        </div>
    );
};

export default CopyToCommunityAction;
