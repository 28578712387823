import config from "../../includes/Config";
import Service from "./Service";
import container from "../../container";
import { Guide } from "../Structures";

class GuideService extends Service {
    launchParams: any;
    retries: number; // Количество повторных запросов при ошибке
    retriesCount: any;

    constructor(launchParams, retries = 1) {
        super();

        this.launchParams = launchParams;
        this.retries = retries;

        this.retriesCount = {
            get: 0,
            update: 0,
        };
    }

    async get() {
        const data = this._createRequestData({});
        const requestUrl = `${config.guideGetUrl}/${this.launchParams.params.vk_group_id}`;

        try {
            const response = await this.request("POST", requestUrl, data);
            this.retriesCount.get = 0;
            return this._formattedResponse(response);
        } catch (e) {
            if (this.retriesCount.get < this.retries) {
                this.retriesCount.get++;
                return this.get();
            }
            this.retriesCount.get = 0;
            return { result: "error", message: e.message };
        }
    }

    async update(guide: Guide) {
        const data = this._createRequestData({ guide: JSON.stringify(guide) });
        const requestUrl = `${config.guideUpdateUrl}/${this.launchParams.params.vk_group_id}`;
        let retries = 0;

        try {
            const response = await this.request("POST", requestUrl, data);
            return this._formattedResponse(response);
        } catch (e) {
            if (retries < this.retries) {
                retries++;
                return this.request("POST", requestUrl, data);
            }
            return { result: "error", message: e.message };
        }
    }

    async request(method: string, url: string, body: any) {
        return fetch(url, { method: method, body: body });
    }
}

const instance = new GuideService(container.get("LaunchParams"));

export default instance;
