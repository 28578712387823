import React, { useState } from "react";
import { File, Input, Button, Spinner, FormItem } from "@vkontakte/vkui";
import GroupDataServiceInterface from "../../../includes/Interfaces/GroupDataServiceInterface";
import { isValidBannerUrl } from "../../../includes/Helpers/resolveBannerUrl";
import bridge from "@vkontakte/vk-bridge";
import {
    getSrc,
    isValidVkVideoUrl,
    isVkVideoIframe,
} from "../../../includes/Helpers/Helpers";
import { Icon24CameraOutline } from "@vkontakte/icons";
import { UPLOAD_MAX_FILESIZE } from "../../../includes/Constants";
import { IMAGE_SIZE_ERROR } from "../../../includes/ErrorMessages";

declare type BannerSelectProps = {
    id: string;
    type: "mobile" | "desktop";
    service: GroupDataServiceInterface;
    callback: Function;
    params: any;
    groupData: any;
    mainPopout: any;
};

const BannerSelect = (props: BannerSelectProps) => {
    const { type, service, callback, params } = props;

    const [isPending, setIsPending] = useState(false);
    const [fileError, setFileError] = useState("");
    const [urlError, setUrlError] = useState("");
    const [url, setUrl] = useState("");

    const handleFileChange = async (e) => {
        let file = e.target.files[0];

        if (!file) {
            return false;
        }

        sendFile(file);
    };

    const sendFile = async (file) => {
        setIsPending(true);

        if (file.size > UPLOAD_MAX_FILESIZE) {
            setFileError(IMAGE_SIZE_ERROR);
            setIsPending(false);
            return;
        }

        const resp = await service.saveBannerFile(file);

        setIsPending(false);

        if (resp.success === true && resp.filename) {
            setFileError("");
            callback({
                url: resp.filename,
            });
        } else if (resp.success === false && resp.message) {
            setFileError(resp.message);

            if (resp.error_code === 901) {
                if (bridge.supports("VKWebAppAllowMessagesFromGroup")) {
                    const allowResult = await bridge.send(
                        "VKWebAppAllowMessagesFromGroup",
                        {
                            group_id: parseInt(params.vk_group_id),
                            key: params.vk_user_id,
                        }
                    );

                    if (allowResult.result === true) {
                        sendFile(file);
                    }
                }
            }
        } else if (resp.result === 'error' && resp.message) {
            setFileError(resp.message);
        } else {
            console.log(resp);
            setFileError("Неизвестная ошибка");
        }
    };

    const handleInputChange = (e) => {
        let value = e.currentTarget.value;
        setUrl(value);
        setUrlError("");
    };

    const handleSubmit = async () => {
        if (!url) {
            setUrlError("Необходимо заполнить");
            return false;
        }
        const isValid = isValidBannerUrl(url);
        if (!isValid) {
            setUrlError("Неверный формат ссылки");
            return false;
        }

        let vkUrl;
        if (isVkVideoIframe(url)) {
            vkUrl = getSrc(url);
        } else if (isValidVkVideoUrl(url)) {
            vkUrl = url;
        }

        callback({
            url: vkUrl ? vkUrl : url,
        });
    };

    return (
        <div className="BannerSelect">
            {isPending && (
                <div className="BannerSelect__spinner">
                    <Spinner size="regular" />
                </div>
            )}

            <FormItem
                status={fileError ? "error" : "default"}
                bottom={
                    fileError
                        ? fileError
                        : `Рекомендуемый размер изображения
                    ${type === "mobile" ? "1125x540" : "1360x300"}`
                }
                style={{ paddingLeft: 0, paddingRight: 0 }}
            >
                <File
                    className="BannerSelect__file"
                    size="l"
                    mode="outline"
                    onChange={handleFileChange}
                    before={<Icon24CameraOutline />}
                    align="center"
                    stretched
                    accept=".png, .jpg, .jpeg"
                >
                    <span>Загрузить изображение</span>
                </File>
            </FormItem>

            <FormItem
                top="Или вставьте ссылку"
                style={{ paddingLeft: 0, paddingRight: 0 }}
                status={urlError ? "error" : "default"}
                bottom={
                    urlError ? (
                        urlError
                    ) : (
                        <span>
                            Поддерживаются ссылки на изображения и видео
                        </span>
                    )
                }
            >
                <Input
                    value={url}
                    onChange={handleInputChange}
                    placeholder="Введите ссылку"
                />
            </FormItem>
            <Button stretched size="l" onClick={handleSubmit}>
                Сохранить
            </Button>
        </div>
    );
};

export default BannerSelect;
