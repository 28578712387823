import React from "react";
import { Alert } from "@vkontakte/vkui";
import { Icon28MailOutline } from "@vkontakte/icons";

type AlertPaymentsProps = {
    /**
     * Ссылка на чат с сообществом
     */
    communityChatUrl: string;

    onClose: () => void;
};

const AlertPayments = (props: AlertPaymentsProps) => {
    const { onClose, communityChatUrl } = props;

    return (
        <Alert
            onClose={onClose}
            actions={[
                {
                    title: "Закрыть",
                    autoClose: true,
                    mode: "cancel",
                },
            ]}
        >
            <h2 style={{ textAlign: "center" }}>Ошибка</h2>
            <p style={{ marginBottom: 14, textAlign: "center" }}>
                Не найдены способы оплаты. Обратитесь к администратору
                сообщества
            </p>
            <div style={{ textAlign: "center" }}>
                <a
                    className="btn-link"
                    href={communityChatUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span>
                        <Icon28MailOutline
                            width={24}
                            height={24}
                            style={{ marginRight: 6 }}
                        />
                    </span>
                    <span>Написать</span>
                </a>
            </div>
        </Alert>
    );
};

export default AlertPayments;
