import React from "react";

export default () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.4533 0C14.9333 0 16 1.06667 16 5.54667V10.4533C16 14.9333 14.9333 16 10.4533 16H5.54667C1.06667 16 0 14.9333 0 10.4533V5.54667C0 1.06667 1.06667 0 5.54667 0H10.4533Z"
                fill="#4680C2"
            />
            <path
                d="M4.16683 5H3.00016C2.66683 5 2.60016 5.15692 2.60016 5.32995C2.60016 5.63896 2.99569 7.1716 4.44179 9.19864C5.40586 10.5829 6.76416 11.3333 8.00016 11.3333C8.74175 11.3333 8.8335 11.1667 8.8335 10.8796V9.83333C8.8335 9.5 8.90375 9.43348 9.13859 9.43348C9.31162 9.43348 9.60827 9.51999 10.3004 10.1874C11.0915 10.9785 11.2219 11.3333 11.6668 11.3333H12.8335C13.1668 11.3333 13.3335 11.1667 13.2373 10.8378C13.1321 10.51 12.7545 10.0343 12.2533 9.47055C11.9814 9.1492 11.5735 8.80313 11.4499 8.63006C11.2769 8.4076 11.3263 8.3087 11.4499 8.11096L11.4707 8.08152C11.6465 7.83166 12.8819 6.06008 13.0196 5.42885C13.0938 5.18165 13.0196 5 12.6668 5H11.5002C11.2035 5 11.0668 5.15692 10.9926 5.32995C10.9926 5.32995 10.3993 6.77608 9.55882 7.71543C9.28691 7.98735 9.1633 8.07386 9.01498 8.07386C8.94083 8.07386 8.83347 7.98735 8.83347 7.74016V5.42885C8.83347 5.13221 8.74739 5 8.50016 5H6.66683C6.48145 5 6.36996 5.13768 6.36996 5.26816C6.36996 5.54938 6.79018 5.61423 6.8335 6.40528V8.12333C6.8335 8.5 6.76547 8.56829 6.61715 8.56829C6.22164 8.56829 5.25957 7.1157 4.68898 5.45356C4.57715 5.1305 4.465 5 4.16683 5Z"
                fill="white"
            />
        </svg>
    );
};
