import React from "react";
import YouTube from "react-youtube";
import resolveBannerUrl from "../../../includes/Helpers/resolveBannerUrl";
import Twemoji from "react-twemoji";
import { simpleTextToHtml } from "../../../includes/Helpers/TextToHtml";
import container from "../../../container";
import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import { getSrc } from "../../../includes/Helpers/Helpers";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../includes/Constants/PageBlockConstants";

type VideoProps = {
    data: any;
    isPreview: boolean
    blockPadding?: number;
    editMode?: boolean;
    blockAlignment?: BlockAlignment;
    iframeOnLoad?: Function;
    textFontSize?: number;
};

const Video = (props: VideoProps) => {
    const {
        data,
        isPreview,
        blockPadding = 0,
        editMode = false,
        iframeOnLoad,
        blockAlignment = "center",
        textFontSize = DEFAULT_BLOCK_TEXT_FONT_SIZE
    } = props;

    const LaunchParams = container.get("LaunchParams");

    const platform = LaunchParams.getPlatform();

    let videoContent = null;

    if (!data.video || !data.video.url) {
        return null;
    }

    let video_url = data.video.url;

    let meta = resolveBannerUrl(data.video.url);

    switch (data.video.type) {
        case "vimeo":
            if (data.video.autoplay === 0) {
                video_url = video_url.replace("autoplay=1", "autoplay=0");
            }

            videoContent = video_url ? (
                <div className="video-wrapper">
                    <div className="video-inner">
                        <iframe
                            onLoad={(e) => {
                                iframeOnLoad && iframeOnLoad();
                            }}
                            data-key={443437002} //@TODO
                            src={video_url}
                            title="Видео"
                            width="auto"
                            height="auto"
                            frameBorder="0"
                            allow="autoplay"
                        ></iframe>
                    </div>
                </div>
            ) : null;
            break;
        case "vk":
            if (data.video.autoplay === 0) {
                video_url = video_url.replace("autoplay=1", "autoplay=0");
            }

            const controls = [
                data.video.autoplay && !editMode ? "&autoplay=1" : "",
                data.video.disable_audio ? "&mute=1" : "",
                data.video.repeat ? "&loop=1" : "",
            ];

            videoContent = video_url ? (
                <div className="video-wrapper">
                    <div className="video-inner">
                        <iframe
                            onLoad={(e) => {
                                iframeOnLoad && iframeOnLoad();
                            }}
                            data-key={443437002} //@TODO
                            src={getSrc(video_url) + controls.join("")}
                            title="Видео"
                            width="auto"
                            height="auto"
                            frameBorder="0"
                            allow="autoplay"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            ) : null;
            break;
        case "youtube":
            let options = {};

            let autoplay = editMode ? 0: data.video.autoplay

            if (isPreview && data.video.disable_audio && data.video.autoplay) {
                autoplay = 1;
            } else if (isPreview && !data.video.disable_audio) {
                autoplay = 0
            }

            if (video_url) {
                options = {
                    height: "100%",
                    width: "100%",
                    playerVars: {
                        mute: editMode ? 0 : data.video.disable_audio,
                        controls: 1, // Show pause/play buttons in player
                        rel: 0,
                        origin:
                            window.location.protocol +
                            "//" +
                            window.location.host,
                        showinfo: 0, // Hide the video title
                        modestbranding: 1, // Hide the Youtube Logo
                        loop: data.video.repeat, // Run the video in a loop
                        fs: 0, // Hide the full screen button
                        cc_load_policy: 0, // Hide closed captions
                        iv_load_policy: 3, // Hide the Video Annotations
                        autohide: 0, // Hide video controls when playing
                        autoplay
                    },
                };

                videoContent = (
                    <div className="video-wrapper">
                        <div className="video-inner">
                            <YouTube
                                onReady={(event: any) => {
                                    if (
                                        data.video.disable_audio &&
                                        !editMode &&
                                        platform !== "desktop_web"
                                    ) {
                                        event.target.playVideo();
                                    }
                                    iframeOnLoad && iframeOnLoad();
                                }}
                                onEnd={(event) => {
                                    if (data.video.repeat) {
                                        event.target.seekTo(0);
                                        event.target.playVideo();
                                    }
                                }}
                                videoId={meta.video_key} //@TODO
                                opts={options}
                            />
                        </div>
                    </div>
                );
            }
            break;

        default:
            videoContent = null;
            break;
    }

    return (
        <div
            className={`B-Block B-Video ${blockAlignment}`}
            style={{
                paddingTop: `${blockPadding}px`,
                paddingBottom: `${blockPadding}px`,
                fontSize: `${textFontSize}em`,
            }}
        >
            {(data.content.title || data.content.text) &&
                (data.has_title === undefined || data.has_title) && (
                    <div className="B-Block__head" style={{ paddingTop: 20 }}>
                        {data.content.title && (
                            <div className="B-Block__title">
                                <Twemoji
                                    options={{ className: "twemoji-promo" }}
                                >
                                    {data.content.title}
                                </Twemoji>
                            </div>
                        )}
                        {data.content.text && (
                            <div className="B-Block__subtitle">
                                <Twemoji
                                    options={{ className: "twemoji-promo" }}
                                >
                                    {simpleTextToHtml(data.content.text)}
                                </Twemoji>
                            </div>
                        )}
                    </div>
                )}
            {videoContent}
        </div>
    );
};

export default Video;
