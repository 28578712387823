const initialState = {
    title: "",
    title_url: "",
    more: "",
    more_url: "",
    rows: [
        {
            button: "",
            button_url: "",
            descr: "",
            icon_id: "",
            img: "",
            title: "",
            url: "",
        },
    ],
};
export default initialState;
