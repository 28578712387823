import React, { useEffect, useReducer } from "react";
import {
    Input,
    Checkbox,
    FormLayout,
    FixedLayout,
    Separator,
    Radio,
    Link,
    Div,
    FormItem,
} from "@vkontakte/vkui";
import { isValidYouTubeVideoUrl } from "../../../../../includes/Helpers/resolveBannerUrl";
import {
    clone,
    isVkVideoIframe,
} from "../../../../../includes/Helpers/Helpers";
import FormSubmitButton from "../FormSubmitButton";

import initialState from "./state";
import reducer from "./reducer";
import TimerControl from "../Timer/TimerControl";
import { Icon20ArticleBoxOutline } from "@vkontakte/icons";

type VideoEditProps = {
    data: any;
    currentDynamicInput: string;
    onSubmit: Function;
    openModal: Function;
};

const VideoEdit = (props: VideoEditProps) => {
    const { data, currentDynamicInput, onSubmit, openModal } = props;

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        ...data,
    });

    useEffect(() => {
        dispatch({ type: "update-video-settings" });
    }, []);

    const { has_title } = state;

    const handleSubmit = async (e) => {
        e.preventDefault();

        const video_type = state.video.type;

        const prepared_video_data = {
            type: state.video.type,
            url: state.video[video_type].url,
            autoplay: state.video[video_type].autoplay,
            disable_audio: state.video[video_type].disable_audio,
            repeat: state.video[video_type].repeat,
        };

        const data = clone({ ...state, video: prepared_video_data });

        const video_url = state.video[state.video.type].url;

        if (video_url.trim() === "") {
            dispatch({ type: "video-error", payload: "Необходимо заполнить" });
            return false;
        } else {
            dispatch({ type: "video-error", payload: "" });
        }

        switch (state.video.type) {
            case "youtube":
                if (!isValidYouTubeVideoUrl(state.video.youtube.url)) {
                    dispatch({
                        type: "video-error",
                        payload: "Неверный формат ссылки",
                    });

                    return false;
                }

                break;
            case "vk":
                if (!isVkVideoIframe(video_url)) {
                    dispatch({
                        type: "video-error",
                        payload: "Неверный код для вставки",
                    });

                    return false;
                }

                break;
            default:
                break;
        }

        onSubmit(data);
    };

    const validateVideoUrl = (e) => {
        const input_value = e.currentTarget.value;

        dispatch({
            type: "video-url",
            payload: input_value,
            video_type: state.video.type,
        });
    };

    const selectVideoType = (e) => {
        dispatch({ type: "video-error", payload: "" });
        dispatch({ type: "video-type", payload: e.target.value });
    };

    return (
        <div style={{ paddingBottom: 60 }}>
            <FormLayout>
                <TimerControl
                    checked={has_title}
                    title="Заголовок и описание"
                    icon={
                        <Icon20ArticleBoxOutline
                            width={24}
                            height={24}
                            fill="var(--swiper-theme-color)"
                            style={{
                                marginRight: 19,
                                opacity: has_title ? 1 : 0.4,
                            }}
                        />
                    }
                    onChange={() => {
                        dispatch({
                            type: "set-has-title",
                            payload: !has_title,
                        });
                    }}
                    onEditClick={() => {
                        openModal({
                            id: "text",
                            args: {
                                content: state.content,
                                currentDynamicInput: currentDynamicInput,
                                callback: (data) => {
                                    dispatch({
                                        type: "edit-text",
                                        payload: data,
                                    });
                                },
                            },
                        });
                    }}
                />

                <Separator />

                <FormItem top="Тип видео" style={{ paddingBottom: 0 }}>
                    <Radio
                        style={{ cursor: "pointer" }}
                        name="video_type"
                        value="youtube"
                        checked={state.video.type === "youtube"}
                        onChange={selectVideoType}
                    >
                        Youtube
                    </Radio>
                    <Radio
                        style={{ cursor: "pointer" }}
                        name="video_type"
                        value="vk"
                        onChange={selectVideoType}
                        checked={state.video.type === "vk"}
                    >
                        VK
                    </Radio>
                </FormItem>

                <FormItem
                    top={
                        state.video.type === "youtube"
                            ? "Ссылка видео на YouTube"
                            : "Код для вставки"
                    }
                    bottom={state.videoHasError ? state.videoHasError : ""}
                    status={state.videoHasError ? "error" : "default"}
                >
                    <Input
                        name="video"
                        type="text"
                        value={
                            state.video.url
                                ? state.video.url
                                : state.video.type === "youtube"
                                ? state.video.youtube.url
                                : state.video.vk.url
                        }
                        onChange={validateVideoUrl}
                    />
                </FormItem>

                {state.video.type === "youtube" && (
                    <Checkbox
                        name="video-audio"
                        checked={
                            "disable_audio" in state.video
                                ? !!state.video.disable_audio
                                : !!state.video.youtube.disable_audio
                        }
                        onChange={(e) => {
                            let val = e.currentTarget.checked;
                            dispatch({ type: "video-audio", payload: +val });
                        }}
                    >
                        Не включать звук
                    </Checkbox>
                )}

                <Checkbox
                    name="video-autoplay"
                    checked={
                        "autoplay" in state.video
                            ? !!state.video.autoplay
                            : state.video.type === "youtube"
                                ? !!state.video.youtube.autoplay
                                : !!state.video.vk.autoplay
                    }
                    onChange={(e) => {
                        let val = e.currentTarget.checked;
                        dispatch({
                            type: "video-autoplay",
                            payload: +val,
                        });
                    }}
                    disabled={
                        state.video.type === "youtube"
                            ? "disable_audio" in state.video
                                ? !state.video.disable_audio
                                : !state.video.youtube.disable_audio
                            : false
                    }
                    description={
                        state.video.type === "youtube"
                            ? 'Работает только с активной опцией "Не включать звук"'
                            : ""
                    }
                >
                    Автоматическое воспроизведение видео
                </Checkbox>

                <Checkbox
                    name="video-repeat"
                    checked={
                        "repeat" in state.video
                            ? !!state.video.repeat
                            : state.video.type === "youtube"
                            ? !!state.video.youtube.repeat
                            : !!state.video.vk.repeat
                    }
                    onChange={(e) => {
                        let val = e.currentTarget.checked;
                        dispatch({ type: "video-repeat", payload: +val });
                    }}
                >
                    Повторять
                </Checkbox>

                <FixedLayout filled={true} vertical={"bottom"}>
                    <FormSubmitButton handleSubmit={handleSubmit} />
                </FixedLayout>
            </FormLayout>
        </div>
    );
};

export default VideoEdit;
