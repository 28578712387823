export default (items): string => {
    if (items.length > 0) {
        let hasIds = false;
        let hasImgs = false;

        if (
            items.filter((it) => it.icon_id && it.icon_id.indexOf("id") === 0)
                .length > 0 ||
            items.filter((it) => it.img === "person").length > 0
        ) {
            hasIds = true;
        }

        if (
            items.filter(
                (it) =>
                    it.icon_id &&
                    it.icon_id.indexOf("id") === -1 &&
                    it.img !== "person"
            ).length > 0
        ) {
            hasImgs = true;
        }

        if (hasImgs && hasIds) {
            return "mixed";
        } else if (hasImgs && !hasIds) {
            return "img";
        } else if (hasIds && !hasImgs) {
            return "id";
        } else {
            return "";
        }
    }
    return "";
};
