import React, { useState, useEffect, useContext } from "react";
import { Button, Div, FormItem, FormLayout, Input } from "@vkontakte/vkui";
import SubscriptionSelect from "../../Controls/Selects/SubscriptionSelect";
import WidgetValidate from "../../../includes/Validators/WidgetValidate";
import { countStringLength } from "../../../includes/Helpers/Helpers";
import {
    MAX_STRING_LENGTH,
    MAX_MESSAGE_DESCRIPTION_LENGTH,
    MAX_BUTTON_STRING_LENGTH,
    URL_PLACEHOLDER,
} from "../../../includes/Constants";
import { Context } from "../../../context";
import InputDynamic from "../../Controls/InputDynamic";

/**
 * @TODO Эту форму можно и нужно рефакторить
 */

const Item = (props) => {
    const { subscriptions, inputDynamic } = useContext(Context);

    const { type } = props;

    const getMaxDescriptionLength = () => {
        if (type === "message") {
            return MAX_MESSAGE_DESCRIPTION_LENGTH;
        } else if (["cover_list", "subscription"].includes(type)) {
            return MAX_STRING_LENGTH;
        }
        return MAX_BUTTON_STRING_LENGTH;
    };

    const getMaxTitleLength = () => {
        if (["subscription", "message", "cover_list"].includes(type)) {
            return MAX_STRING_LENGTH;
        }
        return MAX_BUTTON_STRING_LENGTH;
    };

    const [title, setTitle] = useState("");
    const [titleHasError, setTitleHasError] = useState(false);
    const [titleInputHelp, setTitleInputHelp] = useState("");

    const [descr, setDescr] = useState("");
    const [descrHasError, setDescrHasError] = useState(false);
    const [descrInputHelp, setDescrInputHelp] = useState("");

    const [url, setUrl] = useState("");
    const [urlHasError, setUrlHasError] = useState(false);
    const [urlInputHelp, setUrlInputHelp] = useState("");

    const id = props.cardID;

    useEffect(() => {
        setTitle(props.state.title ? props.state.title : "");
        setTitleHasError(
            countStringLength(props.state.title) > getMaxTitleLength()
        );
        setTitleInputHelp(
            `Введено ${countStringLength(
                props.state.title
            )} из ${getMaxTitleLength()} симв.`
        );

        setDescr(props.state.descr ? props.state.descr : "");
        setDescrInputHelp(
            `Введено ${countStringLength(
                props.state.descr
            )} из ${getMaxDescriptionLength()} симв.`
        );

        setUrl(props.state.url ? props.state.url : "");
    }, []);

    const SaveModal = () => {
        let status = true;

        if (title.trim() === "") {
            setTitleHasError(true);
            setTitleInputHelp("Введите заголовок");
            status = false;
        } else if (countStringLength(title) > getMaxTitleLength()) {
            setTitleHasError(true);
            status = false;
        }

        if (countStringLength(descr) > getMaxDescriptionLength()) {
            setDescrHasError(true);
            status = false;
        }

        if (url.trim() !== "") {
            if (WidgetValidate.button(url) === false) {
                setUrlHasError(true);
                setUrlInputHelp(URL_PLACEHOLDER);
                status = false;
            }
        }

        if (status) {
            if (typeof props.cardID === "undefined") {
                props.onSelect({
                    type: "item",
                    payload: { title, descr, url },
                });
            } else {
                props.onSelect({
                    type: "item",
                    payload: [id, { title, descr, url }],
                });
            }
            //props.onSelect([id, {title,descr,url}, 'item']);
            props.close({ id: null, args: {} });
        }
    };

    const handleSubscriptionSelectChange = (subscription) => {
        let itemData = subscription.getWidgetItemData(type);

        props.onSelect({
            type: "sub",
            payload: {
                item_id: id,
                data: itemData,
            },
        });

        setTitle(itemData.title);
        setTitleHasError(false);
        setTitleInputHelp(
            `Введено ${countStringLength(
                itemData.title
            )} из ${getMaxTitleLength()} симв.`
        );

        setDescr(itemData.desc);
        setDescrHasError(false);
        setDescrInputHelp(
            `Введено ${countStringLength(
                itemData.desc
            )} из ${getMaxDescriptionLength()} симв.`
        );

        setUrl(itemData.url);
        setUrlHasError(false);
    };

    const divStyle = {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    };

    return (
        <FormLayout>
            {type !== "message" && type !== "personalOfer" && (
                <Div style={divStyle}>
                    <Div style={divStyle}>
                        <SubscriptionSelect
                            placeholder="Быстрый выбор из групп подписчиков"
                            items={subscriptions.get()}
                            onSelect={handleSubscriptionSelectChange}
                        />
                    </Div>
                </Div>
            )}
            <Div style={{ paddingTop: 0 }}>
                <InputDynamic
                    name="title"
                    type="text"
                    currentOpened={inputDynamic.getCurrent()}
                    top={
                        props.type === "message"
                            ? "Текст сообщения"
                            : "Заголовок"
                    }
                    value={title}
                    bottomText={titleInputHelp ? titleInputHelp : "_"}
                    status={titleHasError ? "error" : null}
                    maxLength={getMaxTitleLength()}
                    onChange={(value) => {
                        let length = countStringLength(value);
                        setTitleInputHelp(
                            "Введено " +
                                length +
                                " из " +
                                getMaxTitleLength() +
                                " симв."
                        );
                        setTitle(value);
                        if (length > getMaxTitleLength()) {
                            setTitleHasError(true);
                        } else {
                            setTitleHasError(false);
                        }
                    }}
                    placeholder={`До ${getMaxTitleLength()} символов`}
                />

                <InputDynamic
                    name="text"
                    type="text"
                    currentOpened={inputDynamic.getCurrent()}
                    top={
                        props.type === "message"
                            ? "Описание"
                            : "Краткое описание"
                    }
                    value={descr}
                    status={descrHasError ? "error" : null}
                    bottomText={descrInputHelp ? descrInputHelp : "_"}
                    maxLength={getMaxDescriptionLength()}
                    onChange={(value) => {
                        let length = countStringLength(value);
                        setDescrInputHelp(
                            "Введено " +
                                length +
                                " из " +
                                getMaxDescriptionLength() +
                                " симв."
                        );
                        setDescr(value);
                        if (length > getMaxDescriptionLength()) {
                            setDescrHasError(true);
                        } else {
                            setDescrHasError(false);
                        }
                    }}
                    placeholder={`До ${getMaxDescriptionLength()} символов`}
                />
                {type !== "message" && (
                    <FormItem
                        top="Ссылка"
                        status={urlHasError ? "error" : null}
                        bottom={urlInputHelp ? urlInputHelp : URL_PLACEHOLDER}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                        <Input
                            name="url"
                            type="url"
                            value={url}
                            onChange={(e) => {
                                setUrl(e.target.value);
                                setUrlHasError(false);
                                setUrlInputHelp(URL_PLACEHOLDER);
                            }}
                            placeholder="vk.com"
                        />
                    </FormItem>
                )}
            </Div>
            <Div>
                <Button mode="secondary" size="l" onClick={SaveModal} stretched>
                    Сохранить
                </Button>
            </Div>
        </FormLayout>
    );
};

export default Item;
