import React, { ReactElement, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import DomHelper from "../../includes/Helpers/DomHelpers";

interface WithTooltipProps {
    className?: string;
    children: any;
    tooltipContent?: ReactElement;
    onOpen?: () => void;
    onClose?: () => void;
    onAllClose?: () => void;
}

const WithTooltip = (props: WithTooltipProps) => {
    const {
        className,
        tooltipContent,
        onOpen = () => {},
        onClose = () => {},
        onAllClose = () => {},
    } = props;

    const rootElem = useRef(null);

    useEffect(() => {
        const handler = function handler(e) {
            /**
             * Если кликнули по какому-то элементу
             */
            if (e.target.classList.contains("WithTooltip_click")) {
                return false;
            }

            const parents = DomHelper.getParents(e.target);
            /**
             * По клику на документ закрыть все тултипы, если кликнутый элемент не является потомком WithTooltip
             */
            let clickPastTheTooltipElement = true;
            parents.forEach((p) => {
                if (p.classList.contains("WithTooltip_click")) {
                    // Кликнули по какому-то элементу, внтури WithTooltip
                    clickPastTheTooltipElement = false;
                }
            });

            /**
             * Если кликнули по какому-то элементу внутри WithTooltip
             */
            if (!clickPastTheTooltipElement) return false;

            onAllClose();
            removeTooltips();
        };

        document.addEventListener("click", handler);

        return function () {
            document.removeEventListener("click", handler);
        };
    }, []);

    var classNames = ["WithTooltip WithTooltip_click"];

    if (className) {
        classNames.push(className);
    }

    const getTooltip = () => {
        if (rootElem) {
            /**
             * Вставляем элемент в разметку
             */
            const tooltipElem = document.createElement("div");
            tooltipElem.classList.add("WithTooltip__content");
            tooltipElem.classList.add("WithTooltip__content_click");

            ReactDOM.render(tooltipContent, tooltipElem);

            rootElem.current.appendChild(tooltipElem);

            onOpen();
            setTimeout(() => {
                tooltipElem.classList.add("showed");
            }, 100);
        }
    };

    const resolveOpen = () => {
        let isCurrentOpen = !!rootElem.current.querySelector(
            ".WithTooltip__content_click"
        );
        // Если открываем - нужно закрыть все другие тултипы
        if (!isCurrentOpen) {
            removeTooltips();
            getTooltip();
            onOpen();
        } else {
            const currentTooltip = rootElem.current.querySelector(
                ".WithTooltip__content"
            );
            rootElem.current.removeChild(currentTooltip);
            onClose();
        }
    };

    const removeTooltips = () => {
        const tooltips = document.querySelectorAll(
            ".WithTooltip__content_click"
        );
        tooltips.forEach((t) => {
            t.parentNode.removeChild(t);
        });
    };

    return (
        <div
            onClick={resolveOpen}
            ref={rootElem}
            className={classNames.join(" ")}
        >
            {props.children}
        </div>
    );
};

export default WithTooltip;
