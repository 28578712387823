import React from "react";

const PageDisabledError = (props) => {
    const { icon } = props;

    return (
        <div className="FatalError FatalError_disabled">
            <div
                className="FatalError__img"
                style={{ display: "flex", justifyContent: "center" }}
            >
                {icon}
            </div>
            <p className="bold head">Страница недоступна</p>
        </div>
    );
};

export default PageDisabledError;
