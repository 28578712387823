import React, { ReactElement } from "react";
import { Button, Cell, Switch } from "@vkontakte/vkui";

type TimerControlProps = {
    checked: boolean;
    icon: ReactElement;
    title: string;
    onChange: (e) => void;
    onEditClick: () => void;
};

const TimerControl = (props: TimerControlProps) => {
    const { checked, onChange, icon, title, onEditClick } = props;

    return (
        <Cell
            after={<Switch checked={checked} onChange={onChange} />}
            hasHover={false}
            hasActive={false}
        >
            <div className="TimerEdit-controls__row">
                {icon}

                <div className="TimerEdit-text">
                    <div className="TimerEdit-text__title">{title}</div>

                    <Button
                        style={{
                            paddingLeft: 0,
                            marginLeft: 0,
                            minHeight: 15
                        }}
                        mode="tertiary"
                        disabled={!checked}
                        onClick={onEditClick}
                        hasHover={false}
                    >
                        Редактировать
                    </Button>
                </div>
            </div>
        </Cell>
    );
};

export default TimerControl;
