const initialState = {
    content: {
        title: "",
        text: "",
    },

    background: {
        url: "",
        overlay_opacity: 40,
    },

    has_button: true,
    has_title: true,

    button: {},

    errors: {
        text: "",
        title: "",
        button: {},
    },
};

export default initialState;
