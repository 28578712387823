import { clone } from "../../../../../includes/Helpers/Helpers";

export default function (state, action) {
    const newState = clone(state);

    const { type, payload } = action;

    const video_type = newState.video.type;

    const initialVideoSettings = {
        url: "",
        autoplay: 0,
        disable_audio: 0,
        repeat: 0,
    };

    switch (type) {
        case "edit-text":
            newState.content.title = payload.title;
            newState.content.text = payload.text;
            return newState;

        case "set-has-title":
            newState.has_title = payload;
            return newState;

        case "video-type":
            newState.video.type = payload;
            return newState;

        case "video-url":
            newState.video[video_type].url = payload;
            return newState;

        case "video-autoplay":
            newState.video[video_type].autoplay = payload;
            return newState;

        case "video-audio":
            newState.video.youtube.disable_audio = payload;
            return newState;

        case "video-repeat":
            newState.video[video_type].repeat = payload;
            return newState;

        case "video-error":
            newState.videoHasError = payload;
            return newState;
        case "update-video-settings":
            if (!newState.video.youtube) {
                newState.video.youtube = { ...initialVideoSettings };
            }

            if (!newState.video.vk) {
                newState.video.vk = { ...initialVideoSettings };
            }

            Object.keys(newState.video).forEach((key) => {
                switch (key) {
                    case "url":
                        if (newState.video.type === "youtube") {
                            newState.video.youtube.url = newState.video.url;
                        } else {
                            newState.video.vk.url = newState.video.url;
                        }

                        delete newState.video[key];
                        break;
                    case "autoplay":
                        if (newState.video.type === "youtube") {
                            newState.video.youtube.autoplay =
                                newState.video.autoplay;
                        } else {
                            newState.video.vk.autoplay =
                                newState.video.autoplay;
                        }

                        delete newState.video[key];
                        break;
                    case "disable_audio":
                        if (newState.video.type === "youtube") {
                            newState.video.youtube.disable_audio =
                                newState.video.disable_audio;
                        } else {
                            newState.video.vk.disable_audio =
                                newState.video.disable_audio;
                        }

                        delete newState.video[key];
                        break;
                    case "repeat":
                        if (newState.video.type === "youtube") {
                            newState.video.youtube.repeat =
                                newState.video.repeat;
                        } else {
                            newState.video.vk.repeat = newState.video.repeat;
                        }

                        delete newState.video[key];
                        break;

                    default:
                        break;
                }
            });

            return newState;
        default:
            return newState;
    }
}
