import { useEffect, useContext, useReducer, useMemo } from "react";
import { WidgetState, Context } from "../../context";

const useUpdate = (props) => {
    const widget = useContext(WidgetState);
    const { widgets } = useContext(Context);

    const editableWidget = widgets.getActive();

    const defaultState = useMemo(() => {
        if (typeof editableWidget !== "undefined")
            if ("code" in widget.state)
                return { ...props.initialState, ...widget.state.code };
        return props.initialState;
    }, []);

    const [state, dispatch] = useReducer(props.reducer, defaultState);

    useEffect(() => {
        widget.stateBodyWidget({ type: props.type, code: state });
    }, [state]);

    return [state, dispatch];
};

export default useUpdate;
