import { Checkbox, Div, FormItem } from "@vkontakte/vkui";
import React, { useContext } from "react";
import AudienceContext from "../../../Context/AudienceContext";
import ReactSelect from "react-select";
import { Context } from "../../../context";

const AudienceSubscriber = () => {
    const {
        set,
        subscriber,
        subscribed_on_groups,
        not_subscribed_on_groups
    } = useContext(AudienceContext);

    const { subscriptions } = useContext(Context);

    const getFilteredSubscriptions = () => {
        const subscriptionGroups = subscriptions.get();

        let filteredSubscriptions: any = [
            {
                label: "Без группы",
                value: -1,
            },
        ];

        filteredSubscriptions = [
            filteredSubscriptions[0],
            ...subscriptionGroups.map((subscription) => {
                return {
                    label: subscription.data.name,
                    value: subscription.data.subscription_id,
                };
            }),
        ];

        return filteredSubscriptions;
    };

    return (
        <>
            <Div style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Checkbox
                    name="subscriber"
                    checked={subscriber}
                    onChange={(e) => {
                        let isChecked = e.target.checked;
                        set("subscriber", isChecked);
                    }}
                >
                    Является подписчиком
                </Checkbox>

                <Div
                    style={{
                        opacity: subscriber ? 1 : 0.5,
                        pointerEvents: !subscriber ? "none" : "initial",
                    }}
                >
                    <FormItem top="Cостоит в группе подписчиков" style={{ padding: 0 }}>
                        <ReactSelect
                            className="ReactSelect Variables-select AudienceSubscriberSelect"
                            classNamePrefix="Variables-select"
                            options={getFilteredSubscriptions()}
                            value={subscribed_on_groups}
                            placeholder="Выберите группу"
                            onChange={(groups) => {
                                set("subscribed_on_groups", groups);
                            }}
                            noOptionsMessage={() => {
                                return "Ничего не найдено";
                            }}
                            isMulti
                            cacheOptions
                            defaultOptions
                        />
                    </FormItem>
                </Div>


                <Div
                    style={{
                        opacity: subscriber ? 1 : 0.5,
                        pointerEvents: !subscriber ? "none" : "initial",
                    }}
                >
                    <FormItem top="Не состоит в группе подписчиков" style={{ padding: 0 }}>
                        <ReactSelect
                            className="ReactSelect Variables-select AudienceSubscriberSelect"
                            classNamePrefix="Variables-select"
                            options={getFilteredSubscriptions()}
                            value={not_subscribed_on_groups}
                            placeholder="Выберите группу"
                            onChange={(groups) => {
                                set("not_subscribed_on_groups", groups);
                            }}
                            noOptionsMessage={() => {
                                return "Ничего не найдено";
                            }}
                            isMulti
                            cacheOptions
                            defaultOptions
                        />
                    </FormItem>
                </Div>
            </Div>
        </>
    );
};

export default AudienceSubscriber;
