class LocalStorageProvider {
    getItem = (value: string): string => {
        try {
            return localStorage.getItem(value);
        } catch (e) {
            console.log(e);
            return "";
        }
    };

    setItem = (key: string, value: string): void => {
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            console.error(e);
        }
    };
}

export default new LocalStorageProvider();
