import React from "react";
import { Header, Link } from "@vkontakte/vkui";
import { EMPTY_FUNC } from "../../includes/Constants";

const WidgetHeader = (props) => {
    const { edit } = props;
    const link = props.state.more
        ? props.state.more.length > 15
            ? `${props.state.more.slice(0, 15)}...`
            : props.state.more
        : "+ Ссылка";

    return (
        <Header
            style={{ height: 30, marginBottom: 12 }}
            mode="secondary"
            aside={
                <Link
                    onClick={
                        edit === true
                            ? EMPTY_FUNC
                            : () =>
                                  props.onModalRequest({
                                      id: "dynamic",
                                      args: props.modals.editLink,
                                  })
                    }
                >
                    {link}
                </Link>
            }
        >
            <Link
                style={
                    props.errors.title
                        ? { color: "var(--vkui--color_accent_red)", fontSize: 14 }
                        : { fontSize: 14 }
                }
                onClick={
                    edit === true
                        ? EMPTY_FUNC
                        : () =>
                              props.onModalRequest({
                                  id: "dynamic",
                                  args: props.modals.editHeader,
                              })
                }
            >
                {props.state.title ? props.state.title : "+ Заголовок"}
            </Link>
        </Header>
    );
};

export default WidgetHeader;
