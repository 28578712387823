export default {
    cover: {
        c1: {
            key: "c1",
            page_id: "",
            vk_group_id: 0,
            type: "cover",
            sub_type: "cover_base",
            has_title: true,
            has_button: true,
            content: {
                title: "Заголовок",
                text: "Блок, побуждающий человека к совершению целевого действия - подписаться на рассылку, оставить заявку, стать участником сообщества и тд.",
            },
            background: {
                url: "/img/pages/blocks_created/cover_created.jpg",
                overlay_opacity: 40,
            },
            button: {
                id: "",
                text: "Оставить заявку",
                action: "lead", // lead | subsribe | url
                color: "var(--vkui--color_accent_blue)",
                text_color: "#fff",
            },
            meta: {
                padding: 100,
                alignment: "center",
                is_fullscreen: false,
            },
        },
    },
    timer: {
        tm1: {
            key: "tm1",
            page_id: "",
            vk_group_id: 0,
            type: "timer",
            sub_type: "timer_base",
            has_title: true,
            has_button: true,
            has_background: true,
            background: {
                url: "/img/pages/blocks_created/timer_1_created.jpg",
                overlay_opacity: 40,
            },
            content: {
                title: "Заголовок",
                datetime_end: new Date(
                    new Date().getTime() + 24 * 60 * 60 * 1000
                ).toISOString(),
                text: "Описание, раскрывающее содержание заголовка.",
            },

            button: {
                id: "",
                text: "Подробнее",
                action: "url",
                url: "https://senler.ru",
                color: "var(--vkui--color_accent_blue)",
                text_color: "#fff",
            },
            meta: {
                padding: 80,
                alignment: "center",
                is_fullscreen: false,
            },
        },
        tm2: {
            key: "tm2",
            page_id: "",
            vk_group_id: 0,
            type: "timer",
            sub_type: "timer_fixed",
            has_button: true,
            has_background: true,
            has_title: true,
            background: {
                url: "/img/pages/blocks_created/timer_2_created.jpg",
                overlay_opacity: 40,
            },
            content: {
                title: "Заголовок",
                start_time: "10:00",
                end_time: "20:00",
                weekdays: [1, 1, 1, 1, 1, 1, 1],
                text: "Описание, раскрывающее содержание заголовка.",
            },

            button: {
                id: "",
                text: "Подробнее",
                action: "url",
                url: "https://senler.ru",
                color: "var(--vkui--color_accent_blue)",
                text_color: "#fff",
                hide_button: false,
            },
            meta: {
                padding: 80,
                alignment: "center",
                is_fullscreen: false,
            },
        },
        tm3: {
            key: "tm3",
            page_id: "",
            vk_group_id: 0,
            type: "timer",
            sub_type: "timer_first_user_visit",
            has_button: true,
            has_background: true,
            has_title: true,
            has_text_after_expiration: false,
            background: {
                url: "/img/pages/blocks_created/timer_3_created.jpg",
                overlay_opacity: 40,
            },
            content: {
                title: "Заголовок",
                days: "00",
                hours: "00",
                minutes: "00",
                seconds: "00",
                text: "Описание, раскрывающее содержание заголовка.",
                text_after_expiration: "Текст, который будет отображаться после завершения таймера.",
            },

            button: {
                id: "",
                text: "Подробнее",
                action: "url",
                url: "https://senler.ru",
                color: "var(--vkui--color_accent_blue)",
                text_color: "#fff",
                hide_button: false,
            },
            meta: {
                padding: 80,
                alignment: "center",
                is_fullscreen: false,
            },
        },
    },
    other: {
        ot1: {
            key: "ot1",
            page_id: "",
            vk_group_id: 0,
            type: "other",
            sub_type: "other_separator",
            meta: {
                width: 80,
                padding_top: 30,
                padding_bottom: 30,
            },
        },
    },
    text: {
        t1: {
            key: "t1",
            page_id: "",
            vk_group_id: 0,
            type: "text",
            sub_type: "text_base",
            button: null,
            content: {
                title: "Текстовый блок",
                text: "Подробная и развёрнутая информация о компании, услуге, товаре",
            },
            meta: {
                padding: 30,
                alignment: "center",
            },
        },
    },
    button: {
        b1: {
            key: "b1",
            page_id: "",
            vk_group_id: 0,
            type: "button",
            sub_type: "button_base",
            button: {
                id: "",
                action: "url",
                text: "Обсудить подробности",
                url: "https://vk.com/im?sel=-139157852",
                color: "var(--vkui--color_accent_blue)",
                text_color: "#fff",
            },
            meta: {
                padding: 20,
                alignment: "center",
            },
        },
    },
    video: {
        v1: {
            key: "v1",
            page_id: "",
            vk_group_id: 0,
            type: "video",
            sub_type: "video_base",
            has_title: true,
            content: {
                title: "Заголовок",
                text: "Описание, раскрывающее содержание заголовка.",
            },
            video: {
                type: "youtube",
                url: "https://www.youtube.com/watch?v=IUN664s7N-c",
                autoplay: 0,
                disable_audio: 0,
                repeat: 0,
            },
            meta: {
                padding: 0,
                alignment: "center",
            },
        },
    },
    advantages: {
        a1: {
            key: "a1",
            page_id: "",
            vk_group_id: 0,
            type: "advantages",
            sub_type: "advantages_base",
            has_title: true,
            content: {
                title: "Преимущества",
                text: "Описание, раскрывающее содержание заголовка.",
            },
            meta: {
                padding: 40,
                alignment: "center",
            },
            items: [
                {
                    uuid: "",
                    title: "Заголовок",
                    text: "Краткое описание",
                    item_radius: 100,
                    img: {
                        url: "/img/pages/blocks_created/success_valid_icon.png",
                    },
                },
                {
                    uuid: "",
                    title: "Заголовок",
                    text: "Краткое описание",
                    item_radius: 100,
                    img: {
                        url: "/img/pages/blocks_created/success_valid_icon.png",
                    },
                },
                {
                    uuid: "",
                    title: "Заголовок",
                    text: "Краткое описание",
                    item_radius: 100,
                    img: {
                        url: "/img/pages/blocks_created/success_valid_icon.png",
                    },
                },
            ],
        },
    },
    image: {
        i1: {
            key: "i1",
            page_id: "",
            vk_group_id: 0,
            type: "image",
            sub_type: "image_base",
            has_title: true,
            content: {
                title: "Галерея изображений",
                text: "Описание раскрывающее содержание заголовка.",
            },
            meta: {
                padding: 0,
                alignment: "center",
            },
            items: [
                {
                    uuid: "",
                    title: "",
                    text: "",
                    url: "https://i.yapx.ru/IkVBn.gif",
                },
                {
                    uuid: "",
                    title: "",
                    text: "",
                    url: "https://i.yapx.ru/IkVBn.gif",
                },
                {
                    uuid: "",
                    title: "",
                    text: "",
                    url: "https://i.yapx.ru/IkVBn.gif",
                },
            ],
        },
        i2: {
            key: "i2",
            page_id: "",
            vk_group_id: 0,
            type: "image",
            sub_type: "image_single",
            content: {
                title: "",
                text: "",
                desktop_img: "https://i.yapx.ru/IkVBn.gif",
                mobile_img: "https://i.yapx.ru/IkVBn.gif",
            },
            meta: {
                padding: 0,
                alignment: "center",
            },
        },
    },
    reviews: {
        r1: {
            key: "r1",
            page_id: "",
            vk_group_id: 0,
            type: "reviews",
            sub_type: "reviews_base",
            has_title: true,
            content: {
                title: "Отзывы",
                text: "Описание раскрывающее содержание заголовка.",
            },
            meta: {
                padding: 25,
                alignment: "center",
            },
            items: [
                {
                    uuid: "",
                    name: "Имя Фамилия 1",
                    text: "Развернутый текст отзыва. Можно загрузить по ссылке на комментарий под постом на стене или по ссылке на комментарий в обсуждениях сообщества",
                    vkUrl: "https://vk.com/wall-139157852_5062?reply=5063",
                    img: {
                        url: "/img/pages/blocks_created/success_valid_icon.png",
                    },
                },
                {
                    uuid: "",
                    name: "Имя Фамилия 2",
                    text: "Развернутый текст отзыва. Можно загрузить по ссылке на комментарий под постом на стене или по ссылке на комментарий в обсуждениях сообщества",
                },
                {
                    uuid: "",
                    name: "Имя Фамилия 3",
                    text: "Развернутый текст отзыва. Можно загрузить по ссылке на комментарий под постом на стене или по ссылке на комментарий в обсуждениях сообщества",
                },
            ],
        },
    },
    products: {
        p1: {
            key: "p1",
            page_id: "",
            vk_group_id: 0,
            type: "products",
            sub_type: "products_base",
            has_title: true,
            content: {
                title: "Товары / Услуги",
                text: "Описание раскрывающее содержание заголовка.",
            },
            meta: {
                padding: 25,
                alignment: "center",
            },
            items: [
                {
                    uuid: "",
                    category: "Категория",
                    name: "Название товара",
                    text: "Краткое описание товара, особенности и характеристики",
                    price: "от 10 000 руб.",
                    img: {
                        url: "https://i.yapx.ru/IkVBn.gif",
                    },
                    button: {
                        id: "",
                        text: "Подробнее",
                        action: "url",
                        url: "https://senler.ru",
                        color: "var(--vkui--color_accent_blue)",
                        text_color: "#fff"
                    },
                },
                {
                    uuid: "",
                    category: "Категория",
                    name: "Название товара",
                    text: "Краткое описание товара, особенности и характеристики",
                    price: "от 10 000 руб.",
                    img: {
                        url: "https://i.yapx.ru/IkVBn.gif",
                    },
                    button: {
                        id: "",
                        text: "Подробнее",
                        action: "url",
                        url: "https://senler.ru",
                        color: "var(--vkui--color_accent_blue)",
                        text_color: "#fff"
                    },
                },
            ],
        },
    },
};
