import React, { useState, useEffect } from "react";
import {
    Header,
    Group,
    Link,
    Separator,
    List,
    Cell,
    Button,
} from "@vkontakte/vkui";
import ModalMenuItem from "../ModalEdit/Item";
import ModalMenuButton from "../ModalEdit/Button";
import ModalWidgetEditHeader from "../ModalWidgetEditHeader";
import reducer from "./reducer";
import initialState from "./state";
import useUpdate from "../../../includes/Hooks/useUpdate";
import WidgetValidate from "../../../includes/Validators/WidgetValidate";
import WidgetHeader from "../WidgetHeader";
import { EMPTY_FUNC } from "../../../includes/Constants";
import ListAvatar from "../../Elements/ListAvatar";
import WidgetTilesImgValidate from "../../../includes/Validators/WidgetTilesImgValidate";
import { widgetsImagesSetByIds } from "../../../includes/Helpers/Helpers";
import UpdateImagesSettedByIds from "../../../includes/Services/UpdateImagesSettedByIds";
import { Icon24Add } from "@vkontakte/icons";

export const initalErrors = {
    title: false,
    rows: false,
    rows_count: false,
    rows_title: false,
    rows_button: false,
    rows_item: [],
};

const СommunityMenuEdit = (props) => {
    const { onEditMode } = props;

    const [editMode, setEditMode] = useState(false);
    const [state, dispatch]: any = useUpdate({
        ...props,
        reducer,
        initialState,
        type: "community",
    });
    const errors = { ...initalErrors, ...props.errors.resp };

    useEffect(() => {
        const imageSetByIds = widgetsImagesSetByIds(state.rows);
        if (true === imageSetByIds) {
            UpdateImagesSettedByIds(state.rows, dispatch);
        }
    }, []);

    const modals = {
        editHeader: {
            title: "Заголовок виджета",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.title, url: state.title_url }}
                    type="title"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editLink: {
            title: "Ссылка",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.more, url: state.more_url }}
                    type="more"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editItem: {
            title: "Редактировать элемент",
            body: (state, callback, close, cardID) => (
                <ModalMenuItem
                    state={{
                        title: state.rows[cardID].title,
                        descr: state.rows[cardID].descr,
                        url: state.rows[cardID].url,
                    }}
                    type="subscription"
                    onSelect={callback}
                    close={close}
                    cardID={cardID}
                />
            ),
            callback: dispatch,
            state,
        },
        addButton: {
            title: "Добавить кнопку",
            body: (state, callback, close, cardID) => (
                <ModalMenuButton
                    state={state.rows[cardID]}
                    onSelect={callback}
                    close={close}
                    cardID={cardID}
                />
            ),
            callback: dispatch,
            state,
        },
    };

    const handleCoverClick = (i) => {
        const type_img = WidgetTilesImgValidate(state.rows);
        const row = state.rows[i];
        props.onModalRequest({
            id: "card-img",
            args: {
                callback: dispatch,
                cardID: i,
                iconId: row.icon_id,
                type: "tiles",
                type_api: "community",
                type_img,
            },
        });
    };

    const avatar = (img, isError) => {
        return <ListAvatar img={img} hasError={isError} />;
    };

    return (
        <Group>
            <Header
                aside={
                    <Button
                        onClick={() => {
                            setEditMode(!editMode);
                            onEditMode && onEditMode(!editMode);
                        }}
                        mode="secondary"
                    >
                        {editMode ? "Готово" : "Править"}
                    </Button>
                }
            >
                Меню сообщества
            </Header>
            <WidgetHeader
                onModalRequest={props.onModalRequest}
                state={state}
                modals={modals}
                edit={editMode}
                errors={errors}
            />
            <List>
                {state.rows.map((item, i) => (
                    <Cell
                        before={
                            <div
                                style={{ margin: "15px 10px 0 0" }}
                                onClick={
                                    editMode === true
                                        ? EMPTY_FUNC
                                        : () => handleCoverClick(i)
                                }
                            >
                                {avatar(
                                    item.img,
                                    WidgetValidate.isErrors(errors, i, "img")
                                )}
                            </div>
                        }
                        key={i}
                        draggable={editMode}
                        mode={editMode ? 'removable': undefined}
                        onRemove={() => {
                            dispatch({ type: "delete", payload: i });
                        }}
                        onDragFinish={({ from, to }) => {
                            const draggingList = [...state.rows];
                            draggingList.splice(from, 1);
                            draggingList.splice(to, 0, state.rows[from]);
                            dispatch({ type: "update", payload: draggingList });
                        }}
                        subtitle={
                            <span
                                onClick={
                                    editMode === true
                                        ? EMPTY_FUNC
                                        : () =>
                                              props.onModalRequest({
                                                  id: "dynamic",
                                                  args: {
                                                      ...modals.addButton,
                                                      cardID: i,
                                                  },
                                              })
                                }
                                style={
                                    WidgetValidate.isErrors(errors, i, "descr")
                                        ? {
                                              color: "var(--vkui--color_accent_red)",
                                              display: "block",
                                          }
                                        : { display: "block" }
                                }
                            >
                                {item.descr ? item.descr : "+ Описание"}
                            </span>
                        }
                        //@ts-ignore
                        bottomContent={
                            <Button
                                onClick={
                                    editMode === true
                                        ? EMPTY_FUNC
                                        : () =>
                                              props.onModalRequest({
                                                  id: "dynamic",
                                                  args: {
                                                      ...modals.addButton,
                                                      cardID: i,
                                                  },
                                              })
                                }
                                style={
                                    WidgetValidate.isErrors(errors, i, "button")
                                        ? {
                                              color: "var(--vkui--color_accent_red)",
                                              border: "1px solid var(--vkui--color_accent_red)",
                                          }
                                        : null
                                }
                                mode="outline"
                                size="m"
                            >
                                {item.button ? item.button : "+ Кнопка"}
                            </Button>
                        }
                    >
                        <Link
                            style={
                                WidgetValidate.isErrors(errors, i, "title")
                                    ? { color: "var(--vkui--color_accent_red)" }
                                    : null
                            }
                            onClick={
                                editMode === true
                                    ? EMPTY_FUNC
                                    : () =>
                                          props.onModalRequest({
                                              id: "dynamic",
                                              args: {
                                                  ...modals.editItem,
                                                  cardID: i,
                                              },
                                          })
                            }
                        >
                            {item.title ? item.title : "+ Заголовок"}
                        </Link>
                    </Cell>
                ))}
            </List>
            <Separator style={{ opacity: ".12" }} />
            {!editMode && state.rows.length < 6 ? (
                <Link
                    onClick={() => dispatch({ type: "add" })}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "24px",
                    }}
                >
                    <Icon24Add style={{ color: "#AAAEB3" }} />
                </Link>
            ) : null}
        </Group>
    );
};

export default СommunityMenuEdit;
