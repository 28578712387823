import React, { MouseEventHandler, useMemo } from 'react'
import { getDestinationLinkFromVkAwayLink, getHostnameFromLink, isValidUrl } from '../Helpers/Helpers'
import { Alert } from '@vkontakte/vkui'
import { SENLER_DIALOG_URL } from '../Constants'
import DomHelpers from '../Helpers/DomHelpers'
import container from '../../container'

const usePageExternalLink = (props): [boolean, MouseEventHandler<HTMLAnchorElement>] => {
    const { url, isPreview, popout, confirmAction } = props

    const LaunchParams = container.get("LaunchParams");
    const platform = LaunchParams.getPlatform();

    const destinationLink = useMemo(() => {
        return url.indexOf('https://vk.com/away.php?to=') === 0 ? getDestinationLinkFromVkAwayLink(url) : url
    }, [])

    const isExternalLink = useMemo(() => {
        return !isValidUrl(destinationLink)
    }, [destinationLink])

    const showAlertOnClickExternalLink = (e) => {
        e.preventDefault()

        if (isPreview) {
            return false;
        }

        popout.open(
            <Alert
                style={{ alignItems: "flex-start" }}
                actions={[
                    {
                        title: "Отмена",
                        autoClose: true,
                        mode: "cancel",
                    },
                    {
                        title: "Пожаловаться",
                        autoClose: true,
                        mode: "cancel",
                        href: SENLER_DIALOG_URL,
                        target: "_blank",
                    },
                    {
                        title: "Перейти",
                        autoClose: true,
                        mode: "destructive",
                        href: url,
                        target: "_blank",
                        action: () => {
                            confirmAction(e);
                        },
                    },
                ]}
                actionsLayout={
                    platform === "desktop_web" ? "horizontal" : "vertical"
                }
                onClose={popout.close}
                header="Открыть ссылку?"
                text={`Внимание! Вы переходите по сторонней ссылке ${getHostnameFromLink(
                    destinationLink
                )}`}
            />,
            "alert",
            () => {
                DomHelpers.scrollToModal();
            }
        );
    }

    return [isExternalLink, showAlertOnClickExternalLink]
}

export default usePageExternalLink
