import React, { useReducer } from "react";
import initialState from "./initialState";
import reducer, { CHANGE_METRIC_VALUE_ACTION } from "./reducer";
import {
    FormLayout,
    Input,
    Button,
    FixedLayout,
    Div,
    FormItem,
} from "@vkontakte/vkui";
import { GroupMetrics } from "../../../includes/Mappings/MetricsFromGroupData";

declare type Props = {
    data: GroupMetrics;
    onSubmit: Function;
    isPending?: boolean;
    errors: any;
};

const MetricsSettingsForm = (props: Props) => {
    const { data, onSubmit, isPending, errors } = props;

    const initState = { ...initialState, ...data };
    const [state, dispatch] = useReducer(reducer, initState);

    const handleSubmit = () => {
        if (isPending) return false;
        onSubmit && onSubmit(state);
    };

    const handleChange = (value: string, name: string) => {
        dispatch({
            type: CHANGE_METRIC_VALUE_ACTION,
            payload: { name, value },
        });
    };

    return (
        <div className="MetricsSettingsForm">
            <FormLayout
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormItem
                    top="Пиксель ВКонтакте"
                    status={errors["vk_pixel"] ? "error" : "default"}
                    bottom={
                        errors["vk_pixel"] ? errors["vk_pixel"].message : ""
                    }
                >
                    <Input
                        type="text"
                        name="vk_pixel"
                        value={state.vk_pixel}
                        onChange={(e) => {
                            handleChange(e.currentTarget.value, "vk_pixel");
                        }}
                    />
                </FormItem>

                <FormItem
                    top="Яндекс Метрика"
                    status={errors["ya_metrica"] ? "error" : "default"}
                    bottom={
                        errors["ya_metrica"] ? errors["ya_metrica"].message : ""
                    }
                >
                    <Input
                        type="text"
                        name="ya_metrica"
                        value={state.ya_metrica}
                        onChange={(e) => {
                            handleChange(e.currentTarget.value, "ya_metrica");
                        }}
                    />
                </FormItem>

                <FormItem
                    top="Google Analytics"
                    status={errors["ga_metrica"] ? "error" : "default"}
                    bottom={
                        errors["ga_metrica"] ? errors["ga_metrica"].message : ""
                    }
                >
                    <Input
                        type="text"
                        name="ga_metrica"
                        value={state.ga_metrica}
                        onChange={(e) => {
                            handleChange(e.currentTarget.value, "ga_metrica");
                        }}
                    />
                </FormItem>

                <FormItem
                    top="Пиксель Facebook"
                    status={errors["fb_pixel"] ? "error" : "default"}
                    bottom={
                        errors["fb_pixel"] ? errors["fb_pixel"].message : ""
                    }
                >
                    <Input
                        type="text"
                        name="fb_pixel"
                        value={state.fb_pixel}
                        onChange={(e) => {
                            handleChange(e.currentTarget.value, "fb_pixel");
                        }}
                    />
                </FormItem>

                <FormItem
                    top="Google Tag Manager"
                    status={errors["google_tag"] ? "error" : "default"}
                    bottom={
                        errors["google_tag"] ? errors["google_tag"].message : ""
                    }
                >
                    <Input
                        type="text"
                        name="google_tag"
                        value={state.google_tag}
                        onChange={(e) => {
                            handleChange(e.currentTarget.value, "google_tag");
                        }}
                    />
                </FormItem>

                <br />
                <FixedLayout vertical="bottom" filled={true}>
                    <Div>
                        <div className="MetricsSettingsForm__button_wrapper">
                            <Button
                                className="MetricsSettingsForm__button-submit"
                                size="l"
                                mode="secondary"
                                stretched={true}
                                type="submit"
                            >
                                Сохранить
                            </Button>
                        </div>
                    </Div>
                </FixedLayout>
            </FormLayout>
        </div>
    );
};

export default MetricsSettingsForm;
