import React, { ReactElement } from "react";
import { Card, Div, Headline, Text } from "@vkontakte/vkui";

type CardProps = {
    title?: string;
    subtitle?: string;
    text?: string;
    textBold?: string;
    background?: string;
    backgroundMinHeight?: number;
    children?: ReactElement[];
    style?: any;
    onClick?: Function;
};

export default (props: CardProps) => {
    const {
        title = "",
        subtitle = "",
        text = "",
        textBold = "",
        background = "",
        backgroundMinHeight = 100,
        children = [],
        style = {},
        onClick = () => {},
    } = props;

    const cardStyle = {
        ...style,
        ...{
            position: "relative",
            overflow: "hidden",
        },
    };

    return (
        <Card
            onClick={(e) => {
                onClick();
            }}
            mode="shadow"
            style={cardStyle}
        >
            {
                <Div
                    style={{
                        background: background
                            ? `url(${background})`
                            : "var(--vkui--color_background)",
                        backgroundSize: "cover",
                        backgroundPosition: "top center",
                        minHeight: backgroundMinHeight,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {children}
                </Div>
            }
            <Div>
                {title && (
                    <div className="Card__title" style={{ marginBottom: 3 }}>
                        <Headline weight="2">{title}</Headline>
                    </div>
                )}
                {subtitle && (
                    <div className="Card__title" style={{ marginBottom: 3 }}>
                        <Headline weight="2">{subtitle}</Headline>
                    </div>
                )}
                {text && (
                    <div className="Card__text">
                        <Text
                            style={{
                                fontSize: 13,
                                color: "var(--vkui--color_text_secondary)",
                            }}
                            weight="3"
                        >
                            {text}
                        </Text>
                    </div>
                )}
                {textBold && (
                    <div className="Card__text">
                        <Text weight="2">{textBold}</Text>
                    </div>
                )}
            </Div>
        </Card>
    );
};
