class CookieProvider {
    getAnalyticsIds() {
        return {
            gcid: this.getCookie("_ga"),
            ym_uid: this.getCookie("_ym_uid"),
        };
    }

    getCookie(name) {
        const matches = document.cookie.match(
            new RegExp(
                "(?:^|; )" +
                    name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
                    "=([^;]*)"
            )
        );

        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
}

export default new CookieProvider();
