import React, {
    useState,
    useEffect,
    useRef,
    useContext,
    lazy,
    Suspense,
} from "react";
import ReactDOM from "react-dom";
import { Context } from "../../context";
import { Input, Textarea, Div, platform, Spinner, FormItem } from "@vkontakte/vkui";

import BracketIcon from "../../resources/icons/BracketIcon";

import { insertInToString } from "../../includes/Helpers/Helpers";

import Variables, { VariablesTypes } from "./Variables";
import FadeIn from "react-fade-in";
import container from "../../container";
import { Icon24Dismiss, Icon28SmileOutline } from "@vkontakte/icons";

const EmojiPicker = lazy(() => import("./EmojiPicker"));

declare type InputDynamicProps = {
    name: string;
    type?: string;
    top: string;
    value: string;
    bottomText: string;
    status: "default" | "error" | "valid";
    placeholder: string;
    currentOpened?: string;
    onChange: Function;
    maxLength?: number;
    textarea?: boolean;
    useVariables?: boolean;
    variablesMode?: VariablesTypes; // Набор переменныл для - Виджетов, Настроек приложение, Промостраниц
    textareaHeight?: number;
    errorText?: string;
};

const VariablesService = container.get("VariablesService");

const InputDynamic = (props: InputDynamicProps) => {
    const {
        name,
        type,
        top,
        value,
        bottomText,
        status,
        placeholder,
        currentOpened,
        textarea,
        onChange,
        variablesMode = "widgets",
        useVariables = true,
        textareaHeight = 86,
        errorText = ''
    } = props;

    const { inputDynamic, scheme } = useContext(Context);

    const [openedBlock, setOpenedBlock] = useState("");
    const [inputRef, setInputRef] = useState(null);

    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            let input;
            if (textarea) {
                input = containerRef.current.querySelector("textarea");
            } else {
                input =
                    containerRef.current.querySelector('input[type="text"]');
            }
            if (input) {
                setInputRef(input);
            }
        }
    }, [containerRef]);

    useEffect(() => {
        if (currentOpened !== name) {
            setOpenedBlock("");
        }
    }, [currentOpened]);

    const handlePopoutSelection = (value: string) => {
        insertVariable(value);
        setOpenedBlock("");
        inputRef.focus();
    };

    const insertVariable = (variable: string) => {
        let newValue = "";
        let cursorPosition = 0;

        if (inputRef && inputRef.selectionStart >= 0) {
            cursorPosition = inputRef.selectionStart;
        } else {
            console.warn("Selection start is unsupported");
        }

        newValue = insertInToString(value, variable, cursorPosition);
        onChange(newValue);
    };

    const blockFactory = (type: string) => {
        switch (type) {
            case "emoji":
                return {
                    title: "Эмоджи",
                    body: (
                        <Suspense fallback={<Spinner size="small" />}>
                            <EmojiPicker
                                onSelect={(value) => {
                                    handlePopoutSelection(value.native);
                                }}
                            />
                        </Suspense>
                    ),
                };
            case "variables":
                return {
                    title: "Переменные",
                    body: (
                        <Variables
                            mode={variablesMode}
                            variablesService={VariablesService}
                            onSelect={(value) => {
                                handlePopoutSelection(value);
                            }}
                        />
                    ),
                };
            default:
                throw new Error("Undefined block type");
        }
    };

    const renderBlock = () => {
        if (!openedBlock) {
            return null;
        }

        return (
            <div
                onTouchStart={stopPropagation}
                onTouchMove={stopPropagation}
                className={`InputDynamic__block ${
                    blockIsVisible ? "opened" : ""
                }`}
            >
                <FadeIn delay={50}>
                    <div className="InputDynamic__block-inner">
                        <div
                            className={`InputDynamic__block-header ${platform()}`}
                        >
                            <Div>
                                {openedBlock && blockFactory(openedBlock).title}
                            </Div>
                            <button
                                onClick={() => {
                                    setOpenedBlock("");
                                }}
                                className="InputDynamic__block-header-close"
                                style={{ paddingRight: 12 }}
                            >
                                <Icon24Dismiss
                                    fill={`${
                                        scheme.isDark() ? "#fff" : "#444"
                                    }`}
                                />
                            </button>
                        </div>
                        <div
                            className={`InputDynamic__block-content ${platform()} ${openedBlock}`}
                        >
                            {openedBlock && blockFactory(openedBlock).body}
                        </div>
                    </div>
                </FadeIn>
            </div>
        );
    };

    const openBlock = (type: string) => {
        if (openedBlock && openedBlock === type) {
            setOpenedBlock("");
        } else {
            setOpenedBlock(type);
            inputDynamic.setCurrent(name);
        }
    };

    // Отменяем всплытие, чтобы на родительский элемент не срабатывали обработчики событий
    // чтобы сделать внутренний скролл
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const blockIsVisible = !!openedBlock;

    return (
        <div ref={containerRef} className="InputDynamic">
            <div className="InputDynamic__container">
                {ReactDOM.createPortal(
                    renderBlock(),
                    document.getElementById("root-input-dynamic-block")
                )}

                {textarea ? (
                    <FormItem
                        top={top}
                        status={status}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        bottom={errorText}
                    >
                        <Textarea
                            style={{ height: textareaHeight }}
                            name={name}
                            autoComplete="off"
                            value={value}
                            rows={10}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            placeholder={placeholder}
                        />
                    </FormItem>
                ) : (
                    <FormItem
                        top={top}
                        status={status}
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                        bottom={errorText}
                    >
                        <Input
                            name={name}
                            type={type}
                            autoComplete="off"
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            placeholder={placeholder}
                        />
                    </FormItem>
                )}

                <div
                    className="InputDynamic__bottom"
                >
                    <div className="InputDynamic__buttons">
                        <button
                            className={`InputDynamic__buttons-item emoji ${
                                openedBlock === "emoji" ? "active" : ""
                            }`}
                            onClick={(e) => {
                                e.preventDefault();
                                openBlock("emoji");
                            }}
                        >
                            <Icon28SmileOutline
                                height={20}
                                width={20}
                                fill="var(--vkui--color_accent_blue)"
                            />
                        </button>
                        {useVariables && (
                            <button
                                className={`InputDynamic__buttons-item variables ${
                                    openedBlock === "variables" ? "active" : ""
                                }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    openBlock("variables");
                                }}
                            >
                                <BracketIcon
                                    height={16}
                                    width={16}
                                    fill="var(--vkui--color_accent_blue)"
                                />
                            </button>
                        )}
                    </div>
                    <div
                        style={{
                            color:
                                status === "error" ? "var(--vkui--color_accent_red)" : "",
                        }}
                        className="InputDynamic__bottom-text"
                    >
                        {bottomText}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InputDynamic;
