import React, { useEffect } from "react";
import { blockHasAudience } from "../../includes/Helpers/Helpers";
import BlockWrapper from "./Blocks/BlockWrapper";

type PageProps = {
    blocks: any[];
    platform: string;
    resizer: any;
    isPreview: boolean;
};

const Page = (props: PageProps) => {
    const {
        blocks,
        platform,
        resizer,
        isPreview,
    } = props;

    useEffect(() => {
        resizer.setLandingHeight();
    }, []);

    const renderBlocks = () => {
        return blocks.map((block, index) => {
            return (
                <BlockWrapper
                    isPreview={isPreview}
                    key={block.id ? block.id : index}
                    platform={platform}
                    data={block}
                    resizer={resizer}
                />
            );
        });
    };

    return (
        <div className="content--inner">
            <div className="Page">{renderBlocks()}</div>
        </div>
    );
};

export default Page;
