import React from "react";

export default class CustomDateInput extends React.Component<{
    value: string;
    className?: string;
    onClick: () => void;
}> {
    render() {
        const { value, onClick, className = "" } = this.props;

        return (
            <div
                className={`FormField FormField--vkcom vkuiInput vkuiFormField vkuiFormField--default vkuiFormField vkuiFormField--mode-default vkuiFormField--status-default vkuiFormField--sizeY-none vkuiInput vkuiInput--sizeY-none ${className}`}
            >
                <button
                    onClick={onClick}
                    className="react-datepicker__custom-input vkuiInput__el vkuiFormField vkuiFormField--mode-default vkuiFormField--status-default vkuiFormField--sizeY-none vkuiInput vkuiInput--sizeY-none"
                >
                    {value}
                </button>
                <div className="vkuiFormField__border"></div>
            </div>
        );
    }
}
