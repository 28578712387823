const initialState = {
    content: {
        title: "",
        text: "",
    },
    video: {
        type: "",
        vk: {
            url: "",
            autoplay: 0,
            disable_audio: 0,
            repeat: 0,
        },

        youtube: {
            url: "",
            autoplay: 0,
            disable_audio: 0,
            repeat: 0,
        },
    },

    has_title: true,

    titleHasError: false,
    textHasError: false,
    videoHasError: false,
};

export default initialState;
