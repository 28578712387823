import React, { useContext } from "react";
import { Button, Div, Alert } from "@vkontakte/vkui";
import { Context } from "../../context";
import ym from "react-yandex-metrika";
import ReactGA from "react-ga4";
import ReactPixel from "../../includes/Metrics/ReactPixel";
import { Analytics } from "../../includes/Metrics/Analytics";
import VkPixelCommon from "../../includes/Metrics/VkPixelCommon";
import container from "../../container";

interface CancelAllButtonProps {
    centered: boolean;
    onClick: () => {};
}

const lp = container.get("LaunchParams");

const CancelAllButton = (props: CancelAllButtonProps) => {
    const { centered, onClick } = props;

    const { groupSiteData, popout } = useContext(Context);

    const groupData = groupSiteData.get();

    const unsubscribe = () => {
        onClick && onClick();

        try {
            if (groupData.ya_metrica) {
                ym("reachGoal", "unsubscribe_all");
            }

            if (groupData.ga_metrica) {
                ReactGA.event({
                    category: "Button",
                    action: "click",
                    label: `Unsubscribe_all`,
                });
            }

            if (groupData.fb_pixel) {
                ReactPixel.trackCustom("UnSubscribe_all", {});
            }

            if (groupData.google_tag) {
                const utms = lp.getUtms();

                Analytics.push({
                    event: "UnSubscribe_all",
                    vk_user_id: lp.params.vk_user_id,
                    ...utms,
                });
            }

            const vkPixel = groupData.vk_pixel;

            if (vkPixel) {
                VkPixelCommon.event(vkPixel, "UnSubscribe_all");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleClick = () => {
        popout.open(
            <Alert
                actionsLayout="vertical"
                actions={[
                    {
                        title: "Отписаться",
                        autoClose: true,
                        mode: "default",
                        action: () => {
                            unsubscribe();
                        },
                    },
                    {
                        title: "Отмена",
                        autoClose: true,
                        mode: "cancel",
                    },
                ]}
                onClose={() => {
                    popout.close();
                }}
            >
                <h2>Подтвердите действие</h2>
                <p>
                    Все цепочки сообщений будут завершены. Вы уверены, что
                    хотите отписаться от всех рассылок?
                </p>
            </Alert>
        );
    };

    return (
        <Div
            style={{
                paddingTop: 0,
                display: "flex",
                justifyContent: centered ? "center" : "flex-end",
            }}
        >
            <Button
                onClick={handleClick}
                className="Button_cancel-all"
                mode="tertiary"
            >
                Отписаться от всех рассылок
            </Button>
        </Div>
    );
};

export default CancelAllButton;
