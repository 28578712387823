import React from "react";
import CarouselEdit from "./Carousel/CarouselEdit";
import BannersEdit from "./Banners/BannersEdit";
import MessageEdit from "./Message/MessageEdit";
import PersonalOfferEdit from "./PersonalОffer/PersonalОfferEdit";
import PromoProductsEdit from "./PromoProducts/PromoProductsEdit";
import RecomProductsEdit from "./RecomProducts/RecomProductsEdit";
import ReviewsEdit from "./Reviews/ReviewsEdit";
import Clients from "./Clients/ClientsEdit";
import PointSale from "./PointSale/PointSaleEdit";
import Events from "./Events/EventsEdit";
import CommunityMenu from "./СommunityMenu/СommunityMenuEdit";
import SletterSubscription from "./SletterSubscription/SletterSubscriptionEdit";
import List from "./List/ListEdit";
import CompactList from "./CompactList/CompactListEdit";

const WidgetEditBody = ({ type, params }) => {
    switch (type) {
        case "carousel":
            return <CarouselEdit {...params} />;
        case "banners":
            return <BannersEdit {...params} />;
        case "message":
            return <MessageEdit {...params} />;
        case "personal":
            return <PersonalOfferEdit {...params} />;
        case "promo":
            return <PromoProductsEdit {...params} />;
        case "recommended":
            return <RecomProductsEdit {...params} />;
        case "feedbacks":
            return <ReviewsEdit {...params} />;
        case "clients":
            return <Clients {...params} />;
        case "pointSale":
            return <PointSale {...params} />;
        case "events":
            return <Events {...params} />;
        case "community":
            return <CommunityMenu {...params} />;
        case "subscription":
            return <SletterSubscription {...params} />;
        case "list":
            return <List {...params} />;
        case "compact_list":
            return <CompactList {...params} />;
        default:
            break;
    }
};

export default WidgetEditBody;
