import React, { useContext } from "react";
import { Context } from "../context";
import { Footer, PanelHeader, PanelHeaderBack } from "@vkontakte/vkui";
import BlockEdit from "../Сomponents/Pages/Blocks/BlockEdit";
import container from "../container";
import { setHash } from "../includes/Helpers/Helpers";

const BlockEditController = () => {
    const { location, pages, inputDynamic, popout, currentModal } =
        useContext(Context);

    const PagesService = container.get("PagesService");

    const block_id = location.getEditBlockId();
    const page_id = location.getPageId();
    const currentDynamicInput = inputDynamic.getCurrent;

    const page = pages.getPage(page_id);
    const block = page.blocks_edit.filter((b) => {
        return b.id === block_id;
    })[0];

    let blockMaxItemsCount = 0;
    let blocksMaxItemsCounters = pages.getMaxItemsCounters();

    if (blocksMaxItemsCounters[block.type]) {
        blockMaxItemsCount = blocksMaxItemsCounters[block.type];
    }

    const handleSubmit = async (blockData) => {
        popout.loading();

        const response = await PagesService.updateBlock(blockData);

        popout.close();

        if (response.result === "success") {
            pages.updateBlock(block_id, page_id, response.data, () => {
                pages.setAvailablePageStates(response.states);
                navigateToPage();
            });
        } else {
            popout.error(response.message);
        }
    };

    const handleOpenModal = (modalData) => {
        currentModal.set(modalData);
    };

    const navigateToPage = () => {
        setHash({
            list: "pages",
            view: "pages-edit",
            panel: "item",
            page_id: page_id,
        });
    };

    if (!block) {
        return <Footer>Блок не найден. Возможно он был удален.</Footer>;
    }

    return (
        <>
            <PanelHeader
                before={
                    <PanelHeaderBack
                        onClick={(e) => {
                            navigateToPage();
                        }}
                    />
                }
                fixed
            >
                Редактировать блок
            </PanelHeader>
            <BlockEdit
                openModal={handleOpenModal}
                currentDynamicInput={currentDynamicInput}
                data={block}
                onSubmit={handleSubmit}
                maxItemsCount={blockMaxItemsCount}
            />
        </>
    );
};

export default BlockEditController;
