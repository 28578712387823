import React, { useState, useEffect } from "react";
import {
    Header,
    Group,
    Link,
    Separator,
    List,
    Cell,
    Button,
    HorizontalScroll,
    Checkbox,
    Headline,
} from "@vkontakte/vkui";
import ModalListItem from "../ModalEdit/ModalListItem";
import ModalSubButton from "../ModalEdit/Button";
import ModalWidgetEditHeader from "../ModalWidgetEditHeader";
import reducer from "./reducer";
import initialState from "./state";
import WidgetValidate from "../../../includes/Validators/WidgetValidate";
import useUpdate from "../../../includes/Hooks/useUpdate";
import WidgetHeader from "../WidgetHeader";
import WidgetTilesImgValidate from "../../../includes/Validators/WidgetTilesImgValidate";
import container from "../../../container";
import { EMPTY_FUNC } from "../../../includes/Constants";
import ListAvatar from "../../Elements/ListAvatar";

import { widgetsImagesSetByIds } from "../../../includes/Helpers/Helpers";
import UpdateImagesSettedByIds from "../../../includes/Services/UpdateImagesSettedByIds";

import getButtonStyle from "./getButtonsStyle";
import getTextStyle from "./getTextStyle";
import { Icon16Place, Icon16Recent, Icon20DeleteOutline, Icon24Add } from "@vkontakte/icons";

const lp = container.get("LaunchParams");

export const initalErrors = {
    title: false,
    rows: false,
    rows_count: false,
    rows_title: false,
    rows_button: false,
    rows_item: [],
};

const CompactListEdit = (props) => {
    const { onEditMode } = props;

    const [editMode, setEditMode] = useState(false);
    const [state, dispatch]: any = useUpdate({
        ...props,
        reducer,
        initialState,
        type: "list",
    });
    const errors = { ...initalErrors, ...props.errors.resp };

    const allowEditImage = state.has_images;
    const allowEditButtons = state.has_buttons;
    const allowEditText = state.has_text;

    useEffect(() => {
        const imageSetByIds = widgetsImagesSetByIds(state.rows);
        if (true === imageSetByIds) {
            UpdateImagesSettedByIds(state.rows, dispatch);
        }
    }, []);

    const modals = {
        editHeader: {
            title: "Заголовок виджета",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.title, url: state.title_url }}
                    type="title"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editLink: {
            title: "Ссылка",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.more, url: state.more_url }}
                    type="more"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editItem: {
            title: "Редактировать элемент",
            body: (state, callback, close, cardID) => {
                return (
                    <ModalListItem
                        state={state.rows[cardID]}
                        type="list"
                        hasText={state.has_text}
                        onSelect={(data) => {
                            callback(data);
                        }}
                        close={close}
                        cardID={cardID}
                    />
                );
            },
            callback: dispatch,
            state,
        },
        addButton: {
            title: "Добавить кнопку",
            body: (state, callback, close, cardID) => (
                <ModalSubButton
                    state={state.rows[cardID]}
                    onSelect={callback}
                    close={close}
                    cardID={cardID}
                />
            ),
            callback: dispatch,
            state,
        },
    };

    const handleCoverClick = (i) => {
        const type_img = WidgetTilesImgValidate(state.rows);
        const row = state.rows[i];
        props.onModalRequest({
            id: "card-img",
            args: {
                callback: dispatch,
                cardID: i,
                iconId: row.icon_id,
                type: "tiles",
                type_api: "subscription",
                type_img,
            },
        });
    };

    const avatar = (img, isError) => {
        return <ListAvatar img={img} hasError={isError} />;
    };

    const renderButton = (item, i) => {
        return (
            <Button
                className="Widget--List__element"
                onClick={
                    allowEditButtons && !editMode
                        ? () =>
                              props.onModalRequest({
                                  id: "dynamic",
                                  args: { ...modals.addButton, cardID: i },
                              })
                        : EMPTY_FUNC
                }
                style={getButtonStyle(
                    WidgetValidate.isErrors(errors, i, "button"),
                    allowEditButtons
                )}
                mode="outline"
                size="m"
            >
                {item.button ? item.button : "+ Кнопка"}
            </Button>
        );
    };

    const handleContentFlagsChange = (e) => {
        let checked = e.currentTarget.checked;
        let name = e.currentTarget.name;

        dispatch({ type: name, payload: checked });
    };

    const isDesktop = lp.params.vk_platform === "desktop_web";

    return (
        <Group>
            <Header
                aside={
                    <Button
                        onClick={() => {
                            setEditMode(!editMode);
                            onEditMode && onEditMode(!editMode);
                        }}
                        mode="secondary"
                    >
                        {editMode ? "Готово" : "Править"}
                    </Button>
                }
            >
                <Headline level="2" weight="1">
                    Список
                </Headline>
            </Header>
            <HorizontalScroll>
                <div
                    className={`Widget--List__element ${
                        editMode ? "Widget--List__blocked" : ""
                    }`}
                    style={{ display: "flex", marginBottom: 10 }}
                >
                    <Checkbox
                        onChange={handleContentFlagsChange}
                        checked={state.has_images}
                        name="has_images"
                        className="Checkbox-horizontal"
                    >
                        Изображения
                    </Checkbox>
                    <Checkbox
                        onChange={handleContentFlagsChange}
                        name="has_buttons"
                        checked={state.has_buttons}
                        className="Checkbox-horizontal"
                    >
                        Кнопки
                    </Checkbox>
                    <Checkbox
                        onChange={handleContentFlagsChange}
                        name="has_text"
                        checked={state.has_text}
                        className="Checkbox-horizontal"
                    >
                        Подробное описание
                    </Checkbox>
                </div>
            </HorizontalScroll>
            <WidgetHeader
                onModalRequest={props.onModalRequest}
                state={state}
                modals={modals}
                edit={editMode}
                errors={errors}
            />
            <List>
                {state.rows.map((item, i) => (
                    <Cell
                        className="Widget--CompactList__cell"
                        before={
                            <div
                                style={{
                                    margin: "15px 10px 0 0",
                                    opacity: state.has_images ? 1 : 0.2,
                                    alignSelf: 'flex-start'
                                }}
                                className="Widget--List__element"
                                onClick={
                                    allowEditImage && !editMode
                                        ? () => handleCoverClick(i)
                                        : EMPTY_FUNC
                                }
                            >
                                {avatar(
                                    item.img,
                                    WidgetValidate.isErrors(errors, i, "img")
                                )}
                            </div>
                        }
                        after={isDesktop ? renderButton(item, i) : null}
                        key={i}
                        draggable={editMode}
                        mode={editMode ? 'removable': undefined}
                        removePlaceholder={<Icon20DeleteOutline />}
                        onRemove={() => {
                            dispatch({ type: "delete", payload: i });
                        }}
                        onDragFinish={({ from, to }) => {
                            const draggingList = [...state.rows];
                            draggingList.splice(from, 1);
                            draggingList.splice(to, 0, state.rows[from]);
                            dispatch({ type: "update", payload: draggingList });
                        }}
                        subtitle={
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <div
                                    style={{ width: "100%" }}
                                    onClick={
                                        editMode === true
                                            ? EMPTY_FUNC
                                            : () =>
                                                  props.onModalRequest({
                                                      id: "dynamic",
                                                      args: {
                                                          ...modals.editItem,
                                                          cardID: i,
                                                      },
                                                  })
                                    }
                                >
                                    <span
                                        className={"Widget--List__element"}
                                        style={
                                            WidgetValidate.isErrors(
                                                errors,
                                                i,
                                                "descr"
                                            )
                                                ? {
                                                      color: "var(--vkui--color_accent_red)",
                                                  }
                                                : null
                                        }
                                    >
                                        {item.descr
                                            ? item.descr
                                            : "+ Краткое описание"}
                                    </span>

                                    <span
                                        className={"Widget--List__element"}
                                        style={
                                            WidgetValidate.isErrors(
                                                errors,
                                                i,
                                                "geo"
                                            )
                                                ? {
                                                      display: "flex",
                                                      margin: "5px 0 3px",
                                                      color: "var(--vkui--color_accent_red)",
                                                  }
                                                : {
                                                      display: "flex",
                                                      margin: "5px 0 3px",
                                                  }
                                        }
                                    >
                                        <Icon16Place
                                            style={{ paddingRight: "5px" }}
                                        />
                                        {item.geo ? item.geo : "+ Адрес"}
                                    </span>

                                    <span
                                        className={"Widget--List__element"}
                                        style={
                                            WidgetValidate.isErrors(
                                                errors,
                                                i,
                                                "time"
                                            )
                                                ? {
                                                      display: "flex",
                                                      color: "var(--vkui--color_accent_red)",
                                                  }
                                                : { display: "flex" }
                                        }
                                    >
                                        <Icon16Recent
                                            style={{ paddingRight: "5px" }}
                                        />
                                        {item.time ? item.time : "+ Время"}
                                    </span>
                                </div>

                                <div
                                    className={"Widget--List__text"}
                                    style={{
                                        width: "100%",
                                        marginTop: "5px",
                                        cursor: "pointer",
                                    }}
                                    onClick={
                                        allowEditText && !editMode
                                            ? () =>
                                                  props.onModalRequest({
                                                      id: "dynamic",
                                                      args: {
                                                          ...modals.editItem,
                                                          cardID: i,
                                                      },
                                                  })
                                            : EMPTY_FUNC
                                    }
                                >
                                    <span
                                        className={"Widget--List__element"}
                                        style={getTextStyle(
                                            WidgetValidate.isErrors(
                                                errors,
                                                i,
                                                "text"
                                            ),
                                            allowEditText
                                        )}
                                    >
                                        {item.text
                                            ? item.text
                                            : "+ Подробное описание до 300 символов"}
                                    </span>
                                </div>

                                {!isDesktop ? renderButton(item, i) : null}
                            </div>
                        }
                    >
                        <Link
                            className={"Widget--List__element"}
                            style={
                                WidgetValidate.isErrors(errors, i, "title")
                                    ? { color: "var(--vkui--color_accent_red)" }
                                    : null
                            }
                            onClick={
                                editMode === true
                                    ? EMPTY_FUNC
                                    : () =>
                                          props.onModalRequest({
                                              id: "dynamic",
                                              args: {
                                                  ...modals.editItem,
                                                  cardID: i,
                                              },
                                          })
                            }
                        >
                            {item.title ? item.title : "+ Название"}
                        </Link>
                    </Cell>
                ))}
            </List>
            <Separator style={{ opacity: ".12" }} />
            {!editMode && state.rows.length < 6 ? (
                <Link
                    onClick={() => dispatch({ type: "add" })}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "24px",
                    }}
                >
                    <Icon24Add style={{ color: "#AAAEB3" }} />
                </Link>
            ) : null}
        </Group>
    );
};

export default CompactListEdit;
