function reducer(state, action) {
    let newRows = [];

    switch (action.type) {
        case "title":
            return {
                ...state,
                title: action.payload[0],
                title_url: action.payload[1],
            };
        case "more":
            return {
                ...state,
                more: action.payload[0],
                more_url: action.payload[1],
            };
        case "img":
            state.rows[action.payload.cardID].img = action.payload.img;
            state.rows[action.payload.cardID].icon_id = action.payload.img_vk;
            return { ...state };
        case "button":
            state.rows[action.payload[0]].button = action.payload[1][0];
            state.rows[action.payload[0]].button_url = action.payload[1][1];
            return { ...state };
        case "item":
            state.rows[action.payload[0]].title = action.payload[1].title;
            state.rows[action.payload[0]].url = action.payload[1].url;
            state.rows[action.payload[0]].descr = action.payload[1].descr;
            state.rows[action.payload[0]].geo = action.payload[1].geo;
            state.rows[action.payload[0]].time = action.payload[1].time;
            state.rows[action.payload[0]].text = action.payload[1].text;
            return { ...state };
        case "add":
            return {
                ...state,
                rows: [
                    ...state.rows,
                    {
                        button: "",
                        button_url: "",
                        descr: "",
                        icon_id: "",
                        img: "",
                        title: "",
                        url: "",
                        text: "",
                        geo: "",
                        time: "",
                    },
                ],
            };
        case "delete":
            state.rows.splice(action.payload, 1);
            return { ...state };
        case "update":
            return { ...state, rows: [...action.payload] };
        case "has_images":
        case "has_buttons":
        case "has_text":
            return { ...state, [action.type]: action.payload };

        // Заполнение из быстрого выбора рассылки
        case "sub":
            const item_id = action.payload.item_id;
            newRows = state.rows.map((row) => ({ ...row }));
            newRows[item_id].button = action.payload.data.button;
            newRows[item_id].button_url = action.payload.data.button_url;

            return { ...state, rows: newRows };
        default:
            throw new Error(`Unsupported type ${action.type}`);
    }
}
export default reducer;
