import { clone } from "../../../../../../includes/Helpers/Helpers";

export default function (state, action) {
    const newState = clone(state);

    const { type, payload } = action;

    switch (type) {
        case "edit-text":
            newState.content.title = payload.title;
            newState.content.text = payload.text;
            return newState;

        case "set-is-fullscreen":
            newState.meta.is_fullscreen = payload;
            return newState;

        case "start_time":
            newState.content.start_time = payload;
            return newState;

        case "end_time":
            newState.content.end_time = payload;
            return newState;

        case "set_weekdays":
            newState.content.weekdays = payload;
            return newState;

        case "background":
            newState.background = payload;
            return newState;

        case "set-overlay-opacity":
            newState.background.overlay_opacity = payload;
            return newState;

        case "set-button-data":
            newState.button = payload;
            return newState;

        case "set-button-errors":
            newState.errors.button = payload;
            return newState;

        case "flush-button-errors":
            newState.errors.button = {};
            return newState;

        case "set-has-button":
            newState.has_button = payload;
            return newState;

        case "set-has-title":
            newState.has_title = payload;
            return newState;

        case "set-has-background":
            newState.has_background = payload;
            return newState;

        default:
            return newState;
    }
}
