import { SubscriptionParams } from "../Structures";
import {
    cropStringForVk,
    replaceHtmlBreaks,
    replaceLineBreaks,
} from "../Helpers/Helpers";
import { MAX_STRING_LENGTH, MAX_BUTTON_STRING_LENGTH } from "../Constants";

export default class Subscription {
    data: SubscriptionParams;
    vk_group_id: number;
    vk_app_id: number;

    constructor(
        data: SubscriptionParams,
        vk_group_id: number,
        vk_app_id: number
    ) {
        this.data = data;
        this.vk_app_id = vk_app_id;
        this.vk_group_id = vk_group_id;
    }

    getWidgetItemData(type = "tiles") {
        let url = this.getUrl();
        let titleMaxLength =
            type === "tiles" ? MAX_BUTTON_STRING_LENGTH : MAX_STRING_LENGTH;
        let descMaxLenth =
            type === "tiles" ? MAX_BUTTON_STRING_LENGTH : MAX_STRING_LENGTH;

        return {
            url: url,
            button_url: url,
            button: cropStringForVk(this.data.btn_ok, MAX_BUTTON_STRING_LENGTH),
            title: cropStringForVk(this.data.title, titleMaxLength),
            title_url: url,
            desc: cropStringForVk(
                replaceLineBreaks(this.data.text),
                descMaxLenth
            ), // Для краткого описания убираем вообще все разрывы строк - обычные и html теги
            text: cropStringForVk(replaceHtmlBreaks(this.data.text), 300), // Для расширенного описания убираем html разрывы строк
        };
    }

    getUrl() {
        return `https://vk.com/app${this.vk_app_id}_-${this.vk_group_id}#s=${this.data.subscription_id}`;
    }
}
