import LoggerInterface from "../Interfaces/LoggerInterface";
import RequestClientInterface from "../Interfaces/RequestClientInterface";
import { RunBotRequest } from "../Structures/Requests";

class BotService {
    host: string;

    runUrl: string;

    getBotStepsUrl: string;

    client: RequestClientInterface;

    logger: LoggerInterface;

    LaunchParams: any;

    cookieProvider: any;

    localStorageProvider: any;

    constructor(
        /**
         * Основной хост, на который будут идти запросы
         */
        host: string,

        /**
         * Абстрактный логгер для ошибок и отладочной информации
         */
        logger: LoggerInterface,

        /**
         * Абстрактный клиент для HTTP запросов (может использовать fetch|xhr)
         */
        client: RequestClientInterface,

        LauchParams: any,

        /**
         * Объект длоя получения кук
         */
        cookieProvider: any,

        /**
         * Объект для получения данных из LocalStorage
         */
        localStorageProvider: any
    ) {
        this.host = host;
        this.logger = logger;
        this.client = client;
        this.LaunchParams = LauchParams;
        this.cookieProvider = cookieProvider;
        this.localStorageProvider = localStorageProvider;

        this.runUrl = "/ajax/vkapp/AddToBot";
        this.getBotStepsUrl = "/ajax/vkapp/GetBotSteps";
    }

    async run(data: RunBotRequest) {
        const url = this.host + this.runUrl;
        const gcid = this.cookieProvider.getCookie("_ga");
        const ym_uid = this.cookieProvider.getCookie("_ym_uid");
        const roistat = this.cookieProvider.getCookie("roistat_visit");
        const email = this.localStorageProvider.getItem("user_email");

        data.params = { ...this.LaunchParams.params };

        if (data.isPageSource) {
            data.params.source = "page";
        }

        if (gcid) {
            data.params.gcid = gcid;
        }

        if (ym_uid) {
            data.params.ym_uid = ym_uid;
        }

        if (roistat) {
            data.params.roistat_visit = roistat;
        }

        if (email) {
            data.params.user_email = email;
        }

        const requestData = JSON.stringify(data);
        const response = await this.request("POST", url, requestData);
        return this.formattedResponse(response);
    }

    async getBotSteps(data: any) {
        data.params = this.LaunchParams.params

        const url = this.host + this.getBotStepsUrl;
        const requestData = JSON.stringify(data);
        const response = await this.request("POST", url, requestData);

        return response;
    }

    async request(method: string, url: string, data: string) {
        return this.client.requestPromise(method, url, data);
    }

    formattedResponse(data: any) {
        if (data.success) {
            return { success: true, message: "" };
        } else {
            const resp = {
                success: false,
                message: "При запросе произошла ошибка",
            };

            if (data.message) {
                resp.message = data.message;
            }

            return resp;
        }
    }
}

export default BotService;
