import { Button, Div, FormItem, FormLayout } from "@vkontakte/vkui";
import React, { useContext, useState } from "react";
import InputDynamic from "../Controls/InputDynamic";
import { Context } from "../../context";

const ModalTextarea = (props: any) => {
    const {
        content = { text: "" },
        close = () => {},
        callback = () => {},
        currentDynamicInput
    } = props;

    const { snackbar } = useContext(Context);

    const [text, setText] = useState(content.text || "");

    const saveModal = () => {
        if (text.trim() === "") {
            snackbar.showError("Заполните поле");
            return false;
        }

        callback(text);
        close();
    };

    return (
        <div style={{ overflow: "auto" }}>
            <FormLayout>
                <Div style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <FormItem>
                        <InputDynamic
                            currentOpened={currentDynamicInput}
                            type="text"
                            status="default"
                            bottomText=""
                            top="Текст"
                            value={text}
                            placeholder="Введите текст"
                            name="text"
                            textarea={true}
                            useVariables={true}
                            variablesMode="pages"
                            onChange={(value) => {
                                setText(value);
                            }}
                        />
                    </FormItem>
                </Div>

                <Div style={{ paddingTop: 0 }}>
                    <Button size="l" onClick={saveModal} stretched>
                        Сохранить
                    </Button>
                </Div>
            </FormLayout>
        </div>
    );
};

export default ModalTextarea;
