import LoggerInterface from "../Interfaces/LoggerInterface";
import RequestClientInterface from "../Interfaces/RequestClientInterface";
import Service from "./Service";
import * as Sentry from "@sentry/react";

export default class SubscriptionsService extends Service {
    host: string;

    getUrl: string;
    getPromoPageDataUrl: string;
    getAllUrl: string;
    updateListUrl: string;

    retries: number;
    retryTimeout: number;
    retriesRequestCount: any;

    client: RequestClientInterface;

    logger: LoggerInterface;

    constructor(
        /**
         * Основной хост, на который будут идти запросы
         */
        host: string,

        /**
         * Абстрактный логгер для ошибок и отладочной информации
         */
        logger: LoggerInterface,

        /**
         * Абстрактный клиент для HTTP запросов (может использовать fetch|xhr)
         */
        client: RequestClientInterface,

        /**
         * Количество попыток при ошибке подключения (ошибка браузера, а не ошибочный ответ от сервера)
         */
        retries = 1
    ) {
        super();

        this.host = host;
        this.logger = logger;
        this.client = client;
        this.retries = retries;
        this.retryTimeout = 200;

        this.getUrl = "/ajax/vkapp/GetSubscriptions";
        this.getPromoPageDataUrl = "/ajax/vkapp/GetPromoPageData";
        this.getAllUrl = "/ajax/vkapp/GetAdminSubscriptions";
        this.updateListUrl = "/ajax/vkapp/UpdateSubscriptionsList";
    }

    /**
     * Получение групп подписок для просматривающего пользователя
     * @param data
     * @param callback
     */
    getSubscriptions(data, callback = (result: any) => {}) {
        let url = this.host + this.getUrl;
        let requestData = JSON.stringify(data);
        this.requestWithRetries(url, "POST", requestData, callback)();
    }

    /**
     * Получение данных для промо-страницы с основного сайта
     * - Данные о сообществе
     * - Группы подписок - если необходимо
     * @param data
     * @param callback
     */
    getPromoPageData(data, callback = (result: any) => {}) {
        let url = this.host + this.getPromoPageDataUrl;
        let requestData = JSON.stringify(data);
        this.requestWithRetries(url, "POST", requestData, callback)();
    }

    /**
     * Получение всего списка групп подписок для администратора
     * @param data
     * @param callback
     */
    getAdminSubscriptions(data, callback = (result: any) => {}) {
        fetch(this.host + this.getAllUrl, {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((result) => result.json())
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                this.logger.error(
                    { code: 9035, message: error.message },
                    "runtime_error",
                    "GetSubscriptions.jsx"
                );

                Sentry.captureException(error);

                callback({
                    success: false,
                    message: error.message,
                });
            });
    }

    /**
     * Редактирование порядка отображения списка групп подписок в каталоге
     * @param data
     * @param callback
     */
    updateList(data, callback = (result: any) => {}) {
        fetch(this.host + this.updateListUrl, {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((result) => result.json())
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                this.logger.error(
                    { code: 9036, message: error.message },
                    "runtime_error",
                    "GetSubscriptions.jsx"
                );

                Sentry.captureException(error);

                callback({
                    success: false,
                    message: error.message,
                });
            });
    }

    request(method: string, url: string, data: string) {
        return this.client.requestPromise(method, url, data);
    }

    requestWithRetries(
        url: string,
        method: string,
        data: string,
        callback = (result: any) => {}
    ) {
        let retries = 0;
        let self = this;
        return function makeRequest() {
            self.request("POST", url, data)
                .then((result) => {
                    callback(result);
                    retries = 0;
                })
                .catch((error) => {
                    if (retries < self.retries) {
                        retries++;

                        setTimeout(() => {
                            makeRequest();
                        }, self.retryTimeout);

                        return;
                    }

                    callback({
                        success: false,
                        message: error.message,
                    });

                    Sentry.captureException(error);

                    self.logger.error(
                        { code: 9037, message: error.message },
                        "runtime_error",
                        "GetSubscriptions.jsx"
                    );

                    retries = 0;
                });
        };
    }
}
