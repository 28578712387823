import UrlParams from "../UrlParams";
import bridge from "@vkontakte/vk-bridge";

/**
 * Терминология
 * - community token - токен сообщества
 */

class TokensVkStorage {
    params = new UrlParams();

    async getUserToken(scope: string) {
        if (!bridge.supports("VKWebAppStorageGet")) {
            throw new Error(
                "Не поддерживается хранение переменных для приложения ВК. Обновите приложение."
            );
        }

        const key = this.getUserTokenKey(scope);

        try {
            const resp = await bridge.send("VKWebAppStorageGet", {
                keys: [key],
            });
            let res = null;

            if (resp.keys) {
                resp.keys.forEach((k) => {
                    if (k.key === key && k.value) {
                        res = k.value;
                    }
                });
            }

            return res;
        } catch (e) {
            return null;
        }
    }

    async setUserToken(token: string, scope: string) {
        if (!bridge.supports("VKWebAppStorageSet")) {
            throw new Error(
                "Не поддерживается хранение переменных для приложения ВК. Обновите приложение."
            );
        }

        const key = this.getUserTokenKey(scope);
        const resp = await bridge.send("VKWebAppStorageSet", {
            key: key,
            value: token,
        });
        return resp;
    }

    async removeUserTokenWithScope(scope: string = "") {
        await this.setUserToken("", scope);
    }

    getUserTokenKey(scope: string): string {
        return `senler_user_token_${this.params.params.vk_user_id}_${scope}`;
    }

    async getCommunityToken(scope: string) {
        if (!bridge.supports("VKWebAppStorageGet")) {
            throw new Error(
                "Не поддерживается хранение переменных для приложения ВК. Обновите приложение."
            );
        }

        /**
         * Ключ для переменной вида
         * senler_comm_token_{vk_group_id}_{vk_app_id}_{scope}
         * Так как для одного пользователя могут быть несколько сообществ и несколько приложений
         * Префикс senler для того, чтобы избежать конфликта имен с другими сервисами(возможно)
         * `scope` передаем для того чтобы:
         * - отдельно хранить токены для разных действий
         * - не менять уровни доступа на лету
         * - не пугать пользователя слишком длинным списком разрешений при запросе доступа к тому или иному разделу
         */
        const key = this.getCommunityTokenKey(scope);

        try {
            const resp = await bridge.send("VKWebAppStorageGet", {
                keys: [key],
            });
            let res = null;

            if (resp.keys) {
                resp.keys.forEach((k) => {
                    if (k.key === key && k.value) {
                        res = k.value;
                    }
                });
            }

            return res;
        } catch (e) {
            return null;
        }
    }

    async setCommunityToken(scope: string, token: string) {
        if (!bridge.supports("VKWebAppStorageSet")) {
            throw new Error(
                "Не поддерживается хранение переменных для приложения ВК. Обновите приложение."
            );
        }

        const key = this.getCommunityTokenKey(scope);
        const resp = await bridge.send("VKWebAppStorageSet", {
            key: key,
            value: token,
        });
        return resp;
    }

    async dropCommunityToken(scope: string) {
        await this.setCommunityToken(scope, "");
    }

    getCommunityTokenKey(scope: string): string {
        return `senler_comm_token_${this.params.params.vk_group_id}_${this.params.params.vk_app_id}_${scope}`;
    }
}

export default new TokensVkStorage();
