import React, { ReactElement } from "react";
import { Alert } from "@vkontakte/vkui";
import { Popout, Widget } from "../../../includes/Structures";

interface AlertEnableProps {
    popout: Popout;
    changeWidgetsStatusToServer: Function;
    widgetsArray: Widget[];
    updatePublishedStateIds: string[];
}

const AlertEnable = (props: AlertEnableProps): ReactElement => {
    const {
        popout,
        changeWidgetsStatusToServer,
        widgetsArray,
        updatePublishedStateIds,
    } = props;

    return (
        <Alert
            actionsLayout="vertical"
            actions={[
                {
                    title: "Включить",
                    autoClose: true,
                    mode: "default",
                    action: () => {
                        changeWidgetsStatusToServer(
                            widgetsArray,
                            updatePublishedStateIds
                        );
                    },
                },
                {
                    title: "Отменить",
                    autoClose: true,
                    mode: "cancel",
                },
            ]}
            onClose={() => {
                popout.close();
            }}
        >
            <h2>Подтвердите действие</h2>
            <p>
                Включение {widgetsArray.length > 0 ? "виджетов" : "виджета"}{" "}
                приведёт к отключению виджетов другого типа.
            </p>
        </Alert>
    );
};

export default AlertEnable;
