import React, { useState, useEffect } from "react";
import { FormItem, NativeSelect } from "@vkontakte/vkui";
import Subscription from "../../../includes/Mappings/Subscription";

interface SubscriptionSelectProps {
    placeholder?: string;
    onSelect: Function;
    items: Subscription[];
    defaultValue?: number;
    top?: string;
    hasError?: boolean;
}

const SubscriptionSelect = (props: SubscriptionSelectProps) => {
    const {
        placeholder,
        items,
        onSelect,
        defaultValue,
        top,
        hasError = false,
    } = props;

    const [selectedValue, setSelectedValue] = useState(
        defaultValue ? defaultValue : ""
    );

    useEffect(() => {
        if (!placeholder && !defaultValue && items.length > 0) {
            onSelect(items[0]);
        }
    }, []);

    const renderItems = () => {
        return items.map((s) => {
            return (
                <option
                    key={s.data.subscription_id}
                    value={s.data.subscription_id}
                >
                    {s.data.name}
                </option>
            );
        });
    };

    const handleChange = (e) => {
        let sId = e.currentTarget.value;
        let selectedItem = items.filter(
            (i) => parseInt(i.data.subscription_id, 10) === parseInt(sId, 10)
        )[0];

        if (selectedItem) {
            setSelectedValue(sId);
            onSelect(selectedItem);
        }
    };

    return (
        <FormItem
            style={{ paddingTop: 0 }}
            top={top ? top : ""}
            status={hasError ? "error" : "default"}
        >
            <NativeSelect
                value={selectedValue}
                name="subscription-select"
                onChange={handleChange}
                placeholder={placeholder ? placeholder : null}
            >
                {renderItems()}
            </NativeSelect>
        </FormItem>
    );
};

export default SubscriptionSelect;
