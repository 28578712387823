import { Cell, Div } from "@vkontakte/vkui";
import React, { useContext, useEffect } from "react";
import { Context } from "../context";

const Payments = () => {
    const { payment } = useContext(Context);

    useEffect(() => {
        return () => {
            payment.setIsShowedPaymentsPage(false);
        };
    }, []);

    const isPaidSubscription = payment.getIsPaidSubscription();

    return (
        <Div
            style={{
                pointerEvents: isPaidSubscription ? "none" : "auto",
                opacity: isPaidSubscription ? 0.5 : 1,
            }}
        >
            {payment.getPaymentMethods().map((method, key) => (
                <a
                    className="LinkCell"
                    key={key}
                    rel="noreferrer"
                    {...method.link}
                >
                    <Cell
                        className="PayMethods"
                        key={key}
                        style={{
                            textAlign: "left",
                        }}
                        before={
                            <div className="payIcon">
                                <img alt={method.title} src={method.icon} />
                            </div>
                        }
                        subtitle={method.description}
                        expandable
                        multiline
                    >
                        {method.title}
                    </Cell>
                </a>
            ))}
        </Div>
    );
};

export default Payments;
