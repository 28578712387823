import React, { Component } from "react";
import { Div, Alert, PanelSpinner } from "@vkontakte/vkui";

import container from "../container";

import Subscription from "./Subscription";
import { YMInitializer } from "react-yandex-metrika";
import ReactGA from "react-ga4";
import ReactPixel from "../includes/Metrics/ReactPixel";
import InfiniteScroll from "react-infinite-scroll-component";
import { GROUP_NOT_FOUND_MESSAGE } from "../includes/Constants";
import { setHash } from "../includes/Helpers/Helpers";
import CancelAllButton from "./Controls/CancelAllButton";
import AlertSubscriptionSuccess from "./Modals/Alert/AlertSubscriptionSuccess";

import TagManager from "react-gtm-module";
import { Icon24Chevron } from "@vkontakte/icons";

let _Subscriptions = container.get("SubscriptionsService");
let _Subscribes = container.get("SubscribeService");

type MySubscriptionsProps = {
    UrlParams: any;
    user: any;
    group: any;
    setGroup: any;
    setHeight: any;
    changeRoute: any;
    mainPopout: any;
};

type MySubscriptionsState = {
    items: any;
    loading: any;
    success: any;
    isFetching: any;
    group_data: any;
    subscription_id?: any;
    isMessagesAllowed: any;
    hash: any;
    message: any;
    groupNotFoundMode: any;
};

class MySubscriptions extends Component<
    MySubscriptionsProps,
    MySubscriptionsState
> {
    offset: number;
    hasMore: boolean;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            hash: "",
            loading: true,
            success: true,
            isFetching: false,
            message: "",
            group_data: {},
            groupNotFoundMode: false,
            isMessagesAllowed: false,
        };
    }

    counter_params = {
        utm_source: this.props.UrlParams.params.utm_source,
        utm_medium: this.props.UrlParams.params.utm_medium,
        utm_campaign: this.props.UrlParams.params.utm_campaign,
        utm_term: this.props.UrlParams.params.utm_term,
        utm_content: this.props.UrlParams.params.utm_content,
        rs: this.props.UrlParams.params.rs,
        roistat: this.props.UrlParams.params.roistat,
        delivery_id: this.props.UrlParams.params.d,
        user_id: this.props.user.id,
        group_id: this.props.group.vk_group_id,
    };
    ya_options = {
        webvisor: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        trackHash: true,
        params: this.counter_params,
    };
    admin_roles = ["moder", "editor", "admin"];

    componentDidMount() {
        this.offset = 0;
        this.hasMore = true;

        this.fetchData();
    }

    fetchData() {
        const { setGroup, setHeight } = this.props;

        let data = {
            vk_group_id: this.props.group.vk_group_id,
            vk_user_id: this.props.user.id,
            params: this.props.UrlParams.params,
            subscribed: 1,
            offset: this.offset,
        };

        this.setState({ isFetching: true });

        _Subscriptions.getSubscriptions(data, (result) => {
            if (result.success) {
                if (result.group) {
                    this.props.group.data = result.group;
                    setGroup && setGroup(result.group);
                }
                if (result.end) {
                    this.hasMore = false;
                }
                if (this.offset === 0) {
                    this.setState(
                        {
                            loading: false,
                            success: true,
                            isFetching: false,
                            items: this.state.items.concat(result.items),
                            group_data: result.group
                                ? result.group
                                : this.state.group_data,
                            subscription_id: result.subscription_id,
                            isMessagesAllowed: result.is_messages_allowed,
                            hash: result.hash,
                        },
                        () => {
                            setTimeout(() => {
                                setHeight && setHeight();
                            }, 200);
                        }
                    );
                    if (result.group.ga_metrica) {
                        ReactGA.initialize([
                            {
                                trackingId: result.group.ga_metrica,
                                gaOptions: {
                                    cookieFlags: "SameSite=None; Secure",
                                },
                            },
                        ]);
                    }
                    if (result.group.fb_pixel) {
                        ReactPixel.init(
                            result.group.fb_pixel,
                            {},
                            {
                                autoConfig: true,
                                debug: false,
                            }
                        );
                    }

                    if (result.group.google_tag) {
                        try {
                            TagManager.initialize({
                                gtmId: result.group.google_tag,
                            });
                        } catch (e) {
                            console.log(e);
                        }
                    }
                } else {
                    this.setState(
                        {
                            success: true,
                            isFetching: false,
                            items: this.state.items.concat(result.items),
                        },
                        () => {
                            setTimeout(() => {
                                setHeight && setHeight();
                            }, 200);
                        }
                    );
                }
                this.offset++;
            } else {
                if (result.error_code && parseInt(result.error_code) === 1) {
                    if (result.message === GROUP_NOT_FOUND_MESSAGE) {
                        // Сообщество не подключено в Senler
                        this.setState({
                            groupNotFoundMode: true,
                            loading: false,
                            success: true,
                            isFetching: false,
                        });
                    } else {
                        this.props.changeRoute("landing");
                    }
                } else {
                    this.props.mainPopout.error(result.message);
                }
            }
        });
    }

    cancelAllSubscriptions = () => {
        const { group, user, UrlParams, mainPopout } = this.props;

        mainPopout.loading();

        let hash = this.state.hash;

        let data = {
            vk_group_id: group.vk_group_id,
            vk_user_id: user.id,
            hash: hash,
            params: UrlParams.params,
        };

        _Subscribes.unsubscribeAll(data, (result) => {
            if (result.success) {
                this.markSubscriptionsAsUnsubscribed();

                mainPopout.open(
                    <AlertSubscriptionSuccess
                        type="cancel"
                        onClose={() => {
                            mainPopout.close();
                        }}
                        desc="Вы успешно отписались от всех рассылок"
                    />
                );
            } else {
                mainPopout.open(
                    <Alert
                        actionsLayout="vertical"
                        actions={[
                            {
                                title: "Повторить",
                                autoClose: true,
                                mode: "default",
                                action: () => {
                                    this.cancelAllSubscriptions();
                                },
                            },
                            {
                                title: "Отменить",
                                autoClose: true,
                                mode: "cancel",
                            },
                        ]}
                        onClose={() => {
                            mainPopout.close();
                        }}
                    >
                        <h2>Ошибка</h2>
                    </Alert>
                );
            }
        });
    };

    markSubscriptionsAsUnsubscribed = () => {
        const { items } = this.state;
        const newItems = items.map((i) => ({
            ...i,
            subscribed: false,
            paid: false,
            count: i.count - 1,
        }));
        this.setState({ items: newItems });
    };

    showUnsubscribeAllBtn = () => {
        // const { items } = this.state;
        // const subscribed = items.filter(i => i.subscribed);
        // return subscribed.length > 0;
        return true;
    };

    render() {
        const { groupNotFoundMode, subscription_id, loading, isFetching } =
            this.state;
        const isSingle = !!subscription_id;

        // Если сообщество не подключено в Senler
        if (groupNotFoundMode) {
            return this.renderEmptyStub();
        }

        if (loading) {
            return <PanelSpinner size="regular" />;
        }

        return (
            <div>
                {this.state.items.length ? (
                    <>
                        {this.showUnsubscribeAllBtn() && (
                            <div style={{ paddingTop: 12 }}>
                                <CancelAllButton
                                    //@ts-ignore
                                    onClick={this.cancelAllSubscriptions}
                                    centered={true}
                                />
                            </div>
                        )}
                        <InfiniteScroll
                            dataLength={this.state.items.length} //This is important field to render the next data
                            next={() => {
                                this.fetchData();
                            }}
                            hasMore={this.hasMore}
                            loader={isFetching ? <PanelSpinner /> : null}
                        >
                            {isSingle
                                ? this.renderSingleSubscription()
                                : this.renderCatalog()}
                        </InfiniteScroll>
                    </>
                ) : (
                    this.renderEmptyStub()
                )}
                {this.state.group_data.ya_metrica ? (
                    <YMInitializer
                        accounts={[parseInt(this.state.group_data.ya_metrica)]}
                        version="2"
                        options={this.ya_options}
                    />
                ) : (
                    ""
                )}
            </div>
        );
    }

    renderEmptyStub() {
        return (
            <>
                <br />
                {this.showUnsubscribeAllBtn() && (
                    <CancelAllButton
                        //@ts-ignore
                        onClick={this.cancelAllSubscriptions}
                        centered={true}
                    />
                )}
            </>
        );
    }

    renderGoToSubscriptionsButton() {
        return (
            <button
                className="btn-link"
                onClick={() => {
                    setHash({ list: "subscriptions" });
                }}
            >
                <Icon24Chevron />
                Перейти в каталог
            </button>
        );
    }

    renderCatalog() {
        const { items, subscription_id, group_data } = this.state;
        const isSingle = !!subscription_id;

        /**
         * rows - для отображения каталога строками
         * icons - для отображения каталога строками с иконками
         * tiles - для отроюражения каталога карточками
         */
        const catalogType = group_data.catalog_type
            ? group_data.catalog_type
            : "icons";
        const catalogClassName = `Subscriptions-catalog Subscriptions-catalog_${
            catalogType === "tiles" ? "tiles" : "rows"
        }`;

        return (
            <div>
                <div className={catalogClassName}>
                    {items.map((item) => {
                        return (
                            <Div
                                key={item.subscription_id}
                                className="Subscriptions-catalog__item"
                            >
                                <Subscription
                                    single={isSingle}
                                    mainPopout={this.props.mainPopout}
                                    key={item.subscription_id}
                                    UrlParams={this.props.UrlParams}
                                    group={this.props.group}
                                    user={this.props.user}
                                    opts={item}
                                    type={catalogType}
                                    hash={this.state.hash}
                                />
                            </Div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderSingleSubscription() {
        const { items, group_data } = this.state;

        /**
         * rows - для отображения каталога строками
         * icons - для отображения каталога строками с иконками
         * tiles - для отроюражения каталога карточками
         */
        const catalogType = group_data.catalog_type
            ? group_data.catalog_type
            : "tiles";

        return items.map((item) => {
            return (
                <Subscription
                    single={true}
                    mainPopout={this.props.mainPopout}
                    key={item.subscription_id}
                    UrlParams={this.props.UrlParams}
                    group={this.props.group}
                    user={this.props.user}
                    opts={item}
                    type={catalogType}
                    hash={this.state.hash}
                />
            );
        });
    }
}

export default MySubscriptions;
