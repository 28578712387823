const initialState = {
    title: "",
    title_url: "",
    more: "",
    more_url: "",
    text: "",
    descr: "",
    url: "",
};
export default initialState;
