function reducer(state, action) {
    const newRows = state.rows.map((item) => ({ ...item }));
    switch (action.type) {
        case "title":
            return {
                ...state,
                title: action.payload[0],
                title_url: action.payload[1],
            };
        case "more":
            return {
                ...state,
                more: action.payload[0],
                more_url: action.payload[1],
            };
        case "item":
            newRows[action.payload[0]].title = action.payload[1].title;
            newRows[action.payload[0]].url = action.payload[1].url;
            newRows[action.payload[0]].descr = action.payload[1].descr;
            return { ...state, rows: newRows };
        case "button":
            newRows[action.payload[0]].button = action.payload[1][0];
            newRows[action.payload[0]].button_url = action.payload[1][1];
            return { ...state, rows: newRows };
        default:
            throw new Error();
    }
}

export default reducer;
