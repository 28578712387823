import React, { useState, useContext } from "react";
import {
    ModalPage,
    ModalCard,
    Button,
    ModalRoot,
    ModalPageHeader,
    PanelHeaderButton,
    Div,
    PanelHeaderClose,
    usePlatform,
    Platform,
} from "@vkontakte/vkui";

import CountrySelect from "../Сomponents/Controls/Selects/CountrySelect";
import CitySelect from "../Сomponents/Controls/Selects/CitySelect";
import CommunitySelect from "../Сomponents/Controls/Selects/CommunitySelect";
import UsersSelect from "../Сomponents/Controls/Selects/UsersSelect";
import ImgSelect from "../Сomponents/Controls/Selects/ImgSelect";
import BannerSelect from "../Сomponents/Controls/Selects/BannerSelect";
import PlatformSelect from "../Сomponents/Controls/Selects/PlatformSelect";
import RelatiosSelect from "../Сomponents/Controls/Selects/RelationsSelect";
import TemplatePreview from "../Сomponents/Pages/TemplatePreview";

import ImageUploader from "../Сomponents/Controls/Selects/ImageUploader";
import UserCommunities from "../Сomponents/Controls/Selects/UserCommunities";
import config from "../includes/Config";
import container from "../container";
import { ModalContext, Context } from "../context";
import WidgetHelpImage from "../Сomponents/Widgets/WidgetHelpImage";
import SubscriptionSelectModal from "../Сomponents/Controls/Selects/SubscriptionSelectModal";
import ReviewModal from "../Сomponents/Pages/Blocks/Edit/Reviews/ReviewModal/ReviewModal";
import ModalAdvantage from "../Сomponents/Modals/ModalAdvantage";
import ModalProduct from "../Сomponents/Pages/Blocks/Edit/Products/ModalProduct";
import { MODAL_NULL } from "../includes/Constants";
import ModalText from "../Сomponents/Modals/ModalText";
import ModalButton from "../Сomponents/Modals/ModalButton";
import { Icon24Cancel, Icon24Dismiss, Icon24Education, Icon56ErrorOutline } from "@vkontakte/icons";
import ModalTextarea from "../Сomponents/Modals/ModalTextarea";

const ModalController = (props) => {
    const { widgets, subscriptions } = useContext(Context);

    const { setCurrentModal, currentModal, UrlParams, mainPopout } = props;

    const [countries, setCountries] = useState(config.mainCountries);
    const [cities, setCities] = useState([]);
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);

    const [modalUpdateTirgger, setModalUpdateTrigger] = useState("");

    const launchParams = container.get("LaunchParams");

    const platform = usePlatform();

    return (
        <ModalContext.Provider
            value={{
                setModalUpdateTrigger: setModalUpdateTrigger,
                modalUpdateTirgger: modalUpdateTirgger,
            }}
        >
            <ModalRoot
                activeModal={
                    currentModal.id ? `modal-${currentModal.id}` : null
                }
            >
                <ModalCard
                    id="modal-card-img"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                >
                    {currentModal.id === "card-img" && (
                        <ImgSelect
                            callback={currentModal.args.callback}
                            cardID={currentModal.args.cardID}
                            iconId={currentModal.args.iconId}
                            close={setCurrentModal}
                            params={UrlParams.params}
                            mainPopout={mainPopout}
                            type={widgets.getActive().type}
                            type_api={widgets.getActive().type_api}
                            type_img={currentModal.args.type_img}
                        />
                    )}
                </ModalCard>

                <ModalPage
                    id="modal-text"
                    settlingHeight={100}
                    dynamicContentHeight={true}
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="dismiss"
                                    >
                                        <Icon24Dismiss />
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Заголовок и описание
                        </ModalPageHeader>
                    }
                >
                    {currentModal.id === "text" && (
                        <ModalText
                            close={() => setCurrentModal(MODAL_NULL)}
                            {...currentModal.args}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-button"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="dismiss"
                                    >
                                        <Icon24Dismiss />
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Заголовок и описание
                        </ModalPageHeader>
                    }
                    settlingHeight={100}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "button" && (
                        <ModalButton
                            close={() => setCurrentModal(MODAL_NULL)}
                            {...currentModal.args}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-markdown-preview"
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="dismiss"
                                    >
                                        <Icon24Dismiss />
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Предпросмотр
                        </ModalPageHeader>
                    }
                    onClose={() => setCurrentModal(MODAL_NULL)}
                >
                    <Div className="markdown-preview-text">
                        {currentModal.args.preview
                            ? currentModal.args.preview
                            : null}
                    </Div>
                </ModalPage>

                <ModalCard
                    id="modal-banner-img"
                    header={
                        currentModal.args.type === "desktop" ? (
                            <span className="BannerSelect__header">
                                Для веб-версии
                            </span>
                        ) : (
                            <span className="BannerSelect__header">
                                Для мобильной версии
                            </span>
                        )
                    }
                    onClose={() => setCurrentModal(MODAL_NULL)}
                >
                    {currentModal.id === "banner-img" && (
                        <BannerSelect
                            id={currentModal.id}
                            type={currentModal.args.type}
                            callback={(data) => {
                                setCurrentModal(MODAL_NULL);
                                currentModal.args.callback(data);
                            }}
                            groupData={currentModal.args.groupData}
                            params={UrlParams.params}
                            service={container.get("GroupDataService")}
                            mainPopout={mainPopout}
                        />
                    )}
                </ModalCard>

                <ModalCard
                    id="modal-landing-img"
                    header={
                        <span className="BannerSelect__header">
                            Загрузка изображения
                        </span>
                    }
                    onClose={() => setCurrentModal(MODAL_NULL)}
                >
                    {currentModal.id === "landing-img" && (
                        <ImageUploader
                            id={currentModal.id}
                            callback={(data) => {
                                setCurrentModal(MODAL_NULL);
                                currentModal.args.callback(data);
                            }}
                            size={currentModal.args.size}
                            hasDescription={
                                currentModal.args.hasDescription
                                    ? currentModal.args.hasDescription
                                    : false
                            }
                            hasAddressUrl={
                                currentModal.args.hasAddressUrl
                                    ? currentModal.args.hasAddressUrl
                                    : false
                            }
                            description={currentModal.args.description}
                            imageUrl={currentModal.args.imageUrl}
                            address={currentModal.args.address}
                            params={UrlParams.params}
                            mainPopout={mainPopout}
                            withCrop={currentModal.args.withCrop}
                        />
                    )}
                </ModalCard>

                <ModalPage
                    id="modal-textarea"
                    settlingHeight={100}
                    dynamicContentHeight={true}
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        currentModal.args.title && (
                            <ModalPageHeader
                                before={
                                    platform === Platform.ANDROID && (
                                        <PanelHeaderClose
                                            onClick={() =>
                                                setCurrentModal(MODAL_NULL)
                                            }
                                            aria-label="cancel"
                                        />
                                    )
                                }
                                after={
                                    platform === Platform.IOS && (
                                        <PanelHeaderButton
                                            onClick={() =>
                                                setCurrentModal(MODAL_NULL)
                                            }
                                            aria-label="dismiss"
                                        >
                                            <Icon24Dismiss />
                                        </PanelHeaderButton>
                                    )
                                }
                            >
                                {currentModal.args.title}
                            </ModalPageHeader>)
                    }
                >
                    {currentModal.id === "textarea" && (
                        <ModalTextarea
                            close={() => setCurrentModal(MODAL_NULL)}
                            {...currentModal.args}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-dynamic"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        currentModal.args.title ? (
                            <ModalPageHeader
                                before={
                                    platform === Platform.ANDROID && (
                                        <PanelHeaderClose
                                            onClick={() =>
                                                setCurrentModal(MODAL_NULL)
                                            }
                                            aria-label="cancel"
                                        />
                                    )
                                }
                                after={
                                    platform === Platform.IOS && (
                                        <PanelHeaderButton
                                            onClick={() =>
                                                setCurrentModal(MODAL_NULL)
                                            }
                                            aria-label="dismiss"
                                        >
                                            <Icon24Dismiss />
                                        </PanelHeaderButton>
                                    )
                                }
                            >
                                {currentModal.args.title}
                            </ModalPageHeader>
                        ) : (
                            ""
                        )
                    }
                    settlingHeight={100}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "dynamic" &&
                        currentModal.args.body(
                            currentModal.args.state,
                            currentModal.args.callback,
                            setCurrentModal,
                            currentModal.args.cardID
                        )}
                </ModalPage>

                <ModalPage
                    id="modal-country-select"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    dynamicContentHeight={true}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                    >
                                        Готово
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Выбор страны
                        </ModalPageHeader>
                    }
                >
                    {currentModal.id === "country-select" && (
                        <CountrySelect
                            countries={countries}
                            setCountries={setCountries}
                            currentCountry={currentModal.args.currentCountry}
                            onSelect={(res) => {
                                currentModal.args.callback(res);
                            }}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-relations-select"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    dynamicContentHeight={true}
                    settlingHeight={100}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                    >
                                        Готово
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Выбор семейного положения
                        </ModalPageHeader>
                    }
                >
                    {currentModal.id === "relations-select" && (
                        <RelatiosSelect
                            selected={currentModal.args.selected}
                            relations={currentModal.args.relations}
                            onSelect={(res) => {
                                currentModal.args.callback(res);
                            }}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-city-select"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                    >
                                        Готово
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Выбор города
                        </ModalPageHeader>
                    }
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "city-select" && (
                        <CitySelect
                            cities={cities}
                            setCities={setCities}
                            currentCity={currentModal.args.currentCity}
                            onSelect={(res) => {
                                currentModal.args.callback(res);
                            }}
                            countryId={currentModal.args.countryId}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-community-select"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                    >
                                        Готово
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Выбор сообщества
                        </ModalPageHeader>
                    }
                    settlingHeight={80}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "community-select" && (
                        <CommunitySelect
                            groups={groups}
                            widget={currentModal.args.widget}
                            setGroups={setGroups}
                            selected={currentModal.args.selected}
                            onSelect={(res) => {
                                currentModal.args.callback(res);
                            }}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-platform-select"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                    >
                                        Готово
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Выбор устройства
                        </ModalPageHeader>
                    }
                    settlingHeight={80}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "platform-select" && (
                        <PlatformSelect
                            platforms={currentModal.args.platforms}
                            selected={currentModal.args.selected}
                            usagePlace={currentModal.usagePlace}
                            onSelect={(res) => {
                                currentModal.args.callback(res);
                            }}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-users-select"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                    >
                                        Готово
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Выбор пользователей
                        </ModalPageHeader>
                    }
                    settlingHeight={80}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "users-select" && (
                        <UsersSelect
                            users={users}
                            setUsers={setUsers}
                            selected={currentModal.args.selected}
                            onSelect={(res) => {
                                currentModal.args.callback(res);
                            }}
                            sex={currentModal.args.sex}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-help"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        currentModal.args.title && (
                            <ModalPageHeader
                                before={
                                    platform === Platform.ANDROID && (
                                        <PanelHeaderClose
                                            onClick={() =>
                                                setCurrentModal(MODAL_NULL)
                                            }
                                            aria-label="cancel"
                                        />
                                    )
                                }
                                after={
                                    platform === Platform.IOS && (
                                        <PanelHeaderButton
                                            onClick={() =>
                                                setCurrentModal(MODAL_NULL)
                                            }
                                            aria-label="dismiss"
                                        >
                                            <Icon24Dismiss />
                                        </PanelHeaderButton>
                                    )
                                }
                            >
                                {currentModal.args.title}
                            </ModalPageHeader>
                        )
                    }
                    dynamicContentHeight={true}
                >
                    <Div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    >
                        <WidgetHelpImage
                            type={currentModal.args.type_api}
                            desc={currentModal.args.description}
                        />
                    </Div>
                    <Div
                        style={{
                            display: "flex",
                            textAlign: "center",
                            justifyContent: "center",
                            paddingBottom: 30,
                        }}
                    >
                        {currentModal.args.description}
                    </Div>
                </ModalPage>

                <ModalPage
                    id="modal-user-communities"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    >
                                        <Icon24Cancel />
                                    </PanelHeaderClose>
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="dismiss"
                                    >
                                        <Icon24Dismiss />
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Выберите сообщество
                        </ModalPageHeader>
                    }
                    settlingHeight={100}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "user-communities" && (
                        <UserCommunities
                            callback={(values) => {
                                setCurrentModal(MODAL_NULL);
                                currentModal.args.callback(values);
                            }}
                            currentGroup={currentModal.args.currentGroup}
                            items={currentModal.args.items}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-subscriptions"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="dismiss"
                                    >
                                        <Icon24Dismiss />
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            Добавить группу подписчиков
                        </ModalPageHeader>
                    }
                    settlingHeight={100}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "subscriptions" && (
                        <SubscriptionSelectModal
                            subscriptions={currentModal.args.subscriptions}
                            onSelect={currentModal.args.callback}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-landing-review"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="dismiss"
                                    >
                                        <Icon24Dismiss />
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            {currentModal.args.isEdit
                                ? "Редактировать отзыв"
                                : "Добавить отзыв"}
                        </ModalPageHeader>
                    }
                    settlingHeight={100}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "landing-review" && (
                        <ReviewModal
                            close={() => setCurrentModal(MODAL_NULL)}
                            {...currentModal.args}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-landing-advantage"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="dismiss"
                                    >
                                        <Icon24Dismiss />
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            {currentModal.args.isEdit
                                ? "Редактировать"
                                : "Добавить"}
                        </ModalPageHeader>
                    }
                    settlingHeight={100}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "landing-advantage" && (
                        <ModalAdvantage
                            close={() => setCurrentModal(MODAL_NULL)}
                            {...currentModal.args}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-landing-product"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="dismiss"
                                    >
                                        <Icon24Dismiss />
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            {currentModal.args.isEdit
                                ? "Редактировать"
                                : "Добавить"}
                        </ModalPageHeader>
                    }
                    settlingHeight={100}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "landing-product" && (
                        <ModalProduct
                            close={() => setCurrentModal(MODAL_NULL)}
                            vk_group_id={launchParams.params.vk_group_id}
                            subscriptions={subscriptions.get()}
                            {...currentModal.args}
                        />
                    )}
                </ModalPage>

                <ModalPage
                    id="modal-landing-template"
                    onClose={() => setCurrentModal(MODAL_NULL)}
                    header={
                        <ModalPageHeader
                            before={
                                platform === Platform.ANDROID && (
                                    <PanelHeaderClose
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="cancel"
                                    />
                                )
                            }
                            after={
                                platform === Platform.IOS && (
                                    <PanelHeaderButton
                                        onClick={() =>
                                            setCurrentModal(MODAL_NULL)
                                        }
                                        aria-label="dismiss"
                                    >
                                        <Icon24Dismiss />
                                    </PanelHeaderButton>
                                )
                            }
                        >
                            {currentModal.args.template
                                ? currentModal.args.template.name
                                : "Предпросмотр"}
                        </ModalPageHeader>
                    }
                    settlingHeight={100}
                    dynamicContentHeight={true}
                >
                    {currentModal.id === "landing-template" &&
                    currentModal.args.template ? (
                        <TemplatePreview
                            platform={launchParams.params.vk_platform}
                            template={currentModal.args.template}
                            onConfirm={currentModal.args.onConfirm}
                        />
                    ) : null}
                </ModalPage>
            </ModalRoot>
        </ModalContext.Provider>
    );
};

export default ModalController;
