import React, { useState, useEffect } from "react";
import {
    Group,
    Header,
    Button,
    Link,
    Cell,
    Avatar,
    List,
    Headline,
} from "@vkontakte/vkui";
import ModalWidgetEditHeader from "../ModalWidgetEditHeader";
import ModalReview from "./ModalReview";
import WidgetHeader from "../WidgetHeader";

import reducer from "./reducer";
import initialState from "./state";

import { EMPTY_FUNC } from "../../../includes/Constants";

import useUpdate from "../../../includes/Hooks/useUpdate";
import { Icon20DeleteOutline, Icon24Add } from "@vkontakte/icons";

export const initalErrors = {
    title: false,
    rows: false,
    rows_count: false,
    rows_title: false,
    rows_button: false,
    rows_item: [],
};

const ReviewsEdit = (props) => {
    const { onEditMode } = props;

    const [editMode, setEditMode] = useState(false);
    const [state, dispatch]: any = useUpdate({
        ...props,
        reducer,
        initialState,
        type: "feedbacks",
    });
    const [typeLoad, setTypeLoad] = useState("all");
    const errors = { ...initalErrors, ...props.errors.resp };

    useEffect(() => {
        if (state.rows.length == 0) setTypeLoad("all");
        if (state.rows.length > 0) {
            setTypeLoad("");
            if (state.rows.filter((it) => it.button !== "").length > 0)
                setTypeLoad("url");
        }
    }, [state]);

    const modals = {
        editHeader: {
            title: "Заголовок виджета",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.title, url: state.title_url }}
                    type="title"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editLink: {
            title: "Ссылка",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.more, url: state.more_url }}
                    type="more"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        addReview: {
            title: "Добавить отзыв",
            body: (state, callback, close, cardID) => (
                <ModalReview
                    state={state}
                    type={typeLoad}
                    onSelect={callback}
                    close={close}
                    cardID={cardID}
                />
            ),
            callback: dispatch,
            state,
        },
    };

    return (
        <Group>
            <Header
                aside={
                    <Button
                        onClick={() => {
                            setEditMode(!editMode);
                            onEditMode && onEditMode(!editMode);
                        }}
                        mode="secondary"
                    >
                        {editMode ? "Готово" : "Править"}
                    </Button>
                }
            >
                <Headline level="2" weight="1">
                    Отзывы
                </Headline>
            </Header>
            <WidgetHeader
                onModalRequest={props.onModalRequest}
                state={state}
                modals={modals}
                edit={editMode}
                errors={errors}
            />
            <List>
                {state.rows.length > 0 &&
                    state.rows.map((item, i) => (
                        <Cell
                            key={i}
                            onClick={
                                editMode === true
                                    ? EMPTY_FUNC
                                    : () => {
                                          if (typeLoad == "")
                                              props.onModalRequest({
                                                  id: "dynamic",
                                                  args: {
                                                      ...modals.addReview,
                                                      title: "Редактировать отзыв",
                                                      cardID: i,
                                                  },
                                              });
                                      }
                            }
                            onRemove={() => {
                                dispatch({ type: "delete", payload: i });
                            }}
                            onDragFinish={({ from, to }) => {
                                const draggingList = [...state.rows];
                                draggingList.splice(from, 1);
                                draggingList.splice(to, 0, state.rows[from]);
                                dispatch({
                                    type: "newList",
                                    payload: draggingList,
                                });
                            }}
                            subtitle={
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <span
                                        style={{
                                            margin: "5px 0 3px",
                                            color: "var(--vkui--color_text_primary)",
                                        }}
                                    >
                                        {item.descr ? item.descr : "Описание"}
                                    </span>
                                    {item.button_url == "" ||
                                    item.button_url == undefined ? null : (
                                        <Button
                                            style={{ marginTop: "9px" }}
                                            mode="outline"
                                        >
                                            {item.button}
                                        </Button>
                                    )}
                                </div>
                            }
                            draggable={editMode}
                            before={<Avatar src={item.img} />}
                            multiline
                            mode={editMode ? 'removable': undefined}
                            removePlaceholder={<Icon20DeleteOutline />}

                        >
                            <Link>{item.title ? item.title : "Название"}</Link>
                        </Cell>
                    ))}
            </List>
            {!editMode && state.rows.length < 3 ? (

                    <Cell
                        before={
                            <Avatar>
                                <Icon24Add />
                            </Avatar>
                        }
                        onClick={() =>
                            props.onModalRequest({
                                id: "dynamic",
                                args: modals.addReview,
                            })
                        }
                    >
                        Добавить отзыв
                    </Cell>

            ) : null}
        </Group>
    );
};

export default ReviewsEdit;
