const initialState = {
    content: {
        title: "",
        text: "",
    },

    has_title: true,

    items: [],
};

export default initialState;
