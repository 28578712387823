import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context";
import PageStatisticService from "../includes/Services/PageStatisticService";
import PageStatistic from "../Сomponents/Pages/PageStatistic";
import { DEFAULT_PAGE_SUMMARY_STATISTIC_DATA } from "../includes/Constants";
import { Analytics, PAGES_STAT_VIEW } from "../includes/Metrics/Analytics";

const lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: {
            type: "linear",
            display: true,
            position: "left",
            borderWidth: 5,
            id: "y-axis-1",
            min: 0,
            ticks: {
                beginAtZero: true,
                stepSize: 50,
                suggestedMin: 0,
                precision: 0,
            },
        },
    },
    plugins: {
        legend: { display: false },
        tooltip: {
            enabled: false,
            mode: "index",
            intersect: false,
            position: "nearest",
            external: function (context) {
                // Tooltip Element
                let tooltipEl: any = document.getElementById("chartjs-tooltip");

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement("div");
                    tooltipEl.id = "chartjs-tooltip";
                    tooltipEl.innerHTML = "<table></table>";
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                const tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove("above", "below", "no-transform");
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add("no-transform");
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(getBody);

                    let innerHtml = "<thead>";

                    titleLines.forEach(function (title) {
                        innerHtml +=
                            '<tr><div style="margin-bottom: 5px;">' +
                            title +
                            "</div></tr>";
                    });
                    innerHtml += "</thead><tbody>";

                    bodyLines.forEach(function (body, i) {
                        const colors = tooltipModel.labelColors[i];
                        const splittedBody = body[0].split(":");
                        const paramName = splittedBody[0];
                        const paramValue = Number(
                            splittedBody[1].replace(/\s/g, "")
                        );

                        const span = `<div style="display: flex; align-items: center;">
                            <div style="margin-right: 5px; width: 6px; height: 6px; border-radius: 100%; background: ${colors.borderColor};">
                            </div>
                            <div style="width: 100%; display: flex; justify-content: space-between;">
                                <span style="margin-right: 10px;">${paramName}</span>
                                <span>${paramValue}</span>
                            </div>
                        </div>
                        `;

                        innerHtml += "<tr><td>" + span + "</td></tr>";
                    });
                    innerHtml += "</tbody>";

                    let tableRoot = tooltipEl.querySelector("table");
                    tableRoot.innerHTML = innerHtml;
                }

                const position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = "absolute";
                tooltipEl.style.zIndex = 1000;
                tooltipEl.style.left = context.tooltip.x + "px";
                tooltipEl.style.top =
                    position.top +
                    window.pageYOffset +
                    tooltipModel.caretY +
                    "px";
                tooltipEl.style.padding = "10px";
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.background = "var(--vkui--color_text_muted)";
                tooltipEl.style.borderRadius = "5px";
                tooltipEl.style.fontSize = "12px";
                tooltipEl.style.color = "var(--vkui--color_background_content)";
            },
        },
    },
};

const PageStatisticController = () => {
    const { location, popout } = useContext(Context);

    const [lineChartData, setLineChartData] = useState<any>({
        labels: [],
        datasets: [],
    });
    const [isPendingStatisticsByDateRange, setIsPendingStatisticsByDateRange] =
        useState(false);

    const [tableData, setTableData] = useState<any>(
        DEFAULT_PAGE_SUMMARY_STATISTIC_DATA
    );
    const [isPendingTableData, setIsPendingTableData] = useState(false);

    const lineChartDataTemplate = React.useMemo<any>(() => {
        return {
            labels: [],
            datasets: [
                {
                    id: "hits",
                    label: "Просмотры",
                    data: [],
                    borderColor: "rgba(253, 127, 111, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                },
                {
                    id: "unique_user_count",
                    label: "Уникальные пользователи",
                    data: [],
                    borderColor: "rgba(126, 176, 213, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                },
                {
                    id: "subscription",
                    label: "Подписки на рассылки",
                    data: [],
                    borderColor: "rgba(178, 224, 97, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                },
                {
                    id: "bot_add",
                    label: "Добавить в бота",
                    data: [],
                    borderColor: "rgba(189, 126, 190, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                },
                {
                    id: "lead",
                    label: "Заявки",
                    data: [],
                    borderColor: "rgba(255, 181, 90, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                },
                {
                    id: "join_community",
                    label: "Подписки на сообщество",
                    data: [],
                    borderColor: "rgba(255, 238, 101, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                },
                {
                    id: "url",
                    label: "Переходы по ссылкам",
                    data: [],
                    borderColor: "rgba(39, 135, 245, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                },
            ],
        };
    }, [lineChartData]);

    const page_id = location.getPageId();

    useEffect(() => {
        if (page_id) {
            fetchLineChartPageStatistics("today");
            fetchSummaryStatisticByDateRange("today");
            Analytics.goal(PAGES_STAT_VIEW, "pages");
        }
    }, []);

    const prepareData = (data) => {
        /**
         * Подготовим лейблы для графика
         */
        lineChartDataTemplate.labels = Object.keys(data);

        Object.values(data).forEach((s: any) => {
            for (const prop in s) {
                const dataset = lineChartDataTemplate.datasets.find(
                    (d) => d.id === prop
                );

                dataset.data.push(s[prop]);
            }
        });

        const meta = {
            scales: {
                yAxes: {
                    suggestedMax: 0,
                },
            },
        };

        let maxYValue = 0;

        lineChartDataTemplate.datasets.forEach((dataset: any) => {
            dataset.data.forEach((value: number) => {
                if (value > maxYValue) {
                    maxYValue = value;
                }
            });
        });

        if (maxYValue || maxYValue === 0) {
            meta.scales.yAxes.suggestedMax =
                Math.ceil(maxYValue / 10) * 10;
        }

        lineChartOptions.scales.yAxes.suggestedMax =
            meta.scales.yAxes.suggestedMax;

        return lineChartDataTemplate;
    };

    const getDateRangeParams = (
        dateInterval: string,
        dateRangeFrom: any = null,
        dateRangeTo: any = null
    ) => {
        let date_from;
        let detailing = 'days'

        if (
            dateRangeFrom &&
            dateRangeTo && (dateRangeFrom === dateRangeTo)
        ) {
            detailing = 'hours'
        }

        const date = new Date();

        const today = `${
            date.getFullYear() +
            "-" +
            String(date.getMonth() + 1).padStart(2, "0") +
            "-" +
            String(date.getDate()).padStart(2, "0")
        }`;

        let date_to = today + " 23:59:59";

        switch (dateInterval) {
            case "today":
                date_from = today + " 00:00:00";
                detailing = 'hours'
                break;
            case "yesterday":
                date.setDate(date.getDate() - 1);

                const yesterday = `${
                    date.getFullYear() +
                    "-" +
                    String(date.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(date.getDate()).padStart(2, "0")
                }`;

                date_from = yesterday + " 00:00:00";
                date_to = yesterday + " 23:59:59";

                detailing = 'hours'
                break;
            case "week":
                date.setDate(date.getDate() - 7);

                const weekAgo = `${
                    date.getFullYear() +
                    "-" +
                    String(date.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(date.getDate()).padStart(2, "0")
                }`;

                date_from = weekAgo + " 00:00:00";
                break;
            case "month":
                date.setMonth(date.getMonth() - 1);

                const monthAgo = `${
                    date.getFullYear() +
                    "-" +
                    String(date.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(date.getDate()).padStart(2, "0")
                }`;

                date_from = monthAgo + " 00:00:00";
                break;
            case "quarter":
                date.setMonth(date.getMonth() - 3);

                const quarterAgo = `${
                    date.getFullYear() +
                    "-" +
                    String(date.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(date.getDate()).padStart(2, "0")
                }`;

                date_from = quarterAgo + " 00:00:00";
                break;
            case "year":
                date.setFullYear(date.getFullYear() - 1);

                const yearAgo = `${
                    date.getFullYear() +
                    "-" +
                    String(date.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(date.getDate()).padStart(2, "0")
                }`;

                date_from = yearAgo + " 00:00:00";
                break;
            case "user-date-range":
                date_from = `${
                    dateRangeFrom.getFullYear() +
                    "-" +
                    String(dateRangeFrom.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(dateRangeFrom.getDate()).padStart(2, "0")
                } 00:00:00`;

                date_to = `${
                    dateRangeTo.getFullYear() +
                    "-" +
                    String(dateRangeTo.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(dateRangeTo.getDate()).padStart(2, "0")
                } 23:59:59`;
                break;
            default:
                break;
        }

        return { date_from, date_to, detailing };
    };

    const fetchLineChartPageStatistics = async (
        dateInterval: string,
        dateRangeFrom: any = null,
        dateRangeTo: any = null
    ) => {
        const { date_from, date_to, detailing } = getDateRangeParams(
            dateInterval,
            dateRangeFrom,
            dateRangeTo
        );

        setIsPendingStatisticsByDateRange(true);

        const response = await PageStatisticService.getPageStatisticByDateRange(
            {
                page_id,
                date_from,
                date_to,
                detailing
            }
        );

        if (response.success) {
            setLineChartData(prepareData(response.data));
            setIsPendingStatisticsByDateRange(false);

            return true;
        } else {
            popout.error(
                `Ошибка при получении статистики страницы
                по временному диапазону ${page_id} - ${response.message}`
            );
            setIsPendingStatisticsByDateRange(false);

            return false;
        }
    };

    const fetchSummaryStatisticByDateRange = async (
        dateInterval: string,
        dateRangeFrom: any = null,
        dateRangeTo: any = null
    ) => {
        const { date_from, date_to } = getDateRangeParams(
            dateInterval,
            dateRangeFrom,
            dateRangeTo
        );

        setIsPendingTableData(true);

        const response =
            await PageStatisticService.getSummaryPageStatisticByDateRange({
                page_id,
                date_from,
                date_to,
            });

        if (response.success) {
            setTableData(response.data);
            setIsPendingTableData(false);

            return true;
        } else {
            popout.error(
                `Ошибка при получении общей статистики страницы
                за определенный период ${page_id} - ${response.message}`
            );
            setIsPendingTableData(false);

            return false;
        }
    };

    return (
        <PageStatistic
            tableData={tableData}
            lineChartData={lineChartData}
            lineChartOptions={lineChartOptions}
            isPendingTableData={isPendingTableData}
            fetchLineChartPageStatistics={fetchLineChartPageStatistics}
            isPendingStatisticsByDateRange={isPendingStatisticsByDateRange}
            fetchSummaryStatisticByDateRange={fetchSummaryStatisticByDateRange}
        />
    );
};

export default PageStatisticController;
