import React, { useContext, useEffect, useState } from "react";
import { setHash } from "../includes/Helpers/Helpers";
import {
    Tabs,
    TabsItem,
    FixedLayout,
    Button,
    Div,
    PanelSpinner,
    Placeholder,
} from "@vkontakte/vkui";
import PageToEntity from "../includes/Mappings/Pages/Page";
import PagesToEntities from "../includes/Mappings/PagesToEntities";
import PagesList from "../Сomponents/Pages/PagesList";
import PagesListDesktop from "../Сomponents/Pages/PagesListDesktop";
import PagesTemplates from "../Сomponents/Pages/PagesTemplates";
import container from "../container";
import { Context } from "../context";
import RepeatAction from "../Сomponents/Elements/Snackbars/RepeatAction";
import {
    Analytics,
    PAGES_CREATE,
    PAGES_CREATE_TEMPLATE,
} from "../includes/Metrics/Analytics";

const PagesIndexController = () => {
    const {
        snackbar,
        popout,
        pages,
        resizer,
        currentModal,
        location,
        groupSiteData,
    } = useContext(Context);

    const [activeTab, setActiveTab] = useState("my-pages");
    const [isPendingLoadWidgets, setIsPendingLoadWidgets] = useState(false);

    const PagesService = container.get("PagesService");
    const launchParams = container.get("LaunchParams");

    useEffect(() => {
        resizer.setHeight();
        pages.setAvailablePageStates([]);

        pages.setCurrentEditableBlock(null);

        if (
            !location.getIsPagesStatisticPreviousPage() &&
            groupSiteData.isConnected()
        ) {
            loadGroupPages();
        }
    }, [groupSiteData.isConnected()]);

    const loadGroupPages = async () => {
        setIsPendingLoadWidgets(true);

        const response = await PagesService.getGroupPages(
            launchParams.params.vk_group_id
        );
        if (response.result === "success") {
            setIsPendingLoadWidgets(false);
            pages.setAll(PagesToEntities(response.data.pages));
            pages.setRecentBlocks(response.data.recent_blocks);
            pages.setMaxItemsCounters(response.data.max_items_counters);
        } else {
            setIsPendingLoadWidgets(false);

            snackbar.close(() => {
                snackbar.open(<RepeatAction action={loadGroupPages} />);
            });
        }
    };

    /**
     * Создание страницы из шаблона
     * @param template
     */
    const handleTemplateSelect = async (template) => {
        currentModal.set({
            id: "landing-template",
            args: {
                template: template,
                onConfirm: () => {
                    currentModal.close();
                    createPageFromTemplate(template);
                },
            },
        });
    };

    const createPageFromTemplate = async (template) => {
        popout.loading();

        /**
         * Создадим новую страницу
         */
        const page_response = await PagesService.createPage({
            name: template.name,
        });

        /**
         * Если с ошибкой - завершим процесс
         */
        if (page_response.result !== "success") {
            popout.error(page_response.message);
            return false;
        }

        /**
         * Добавим блоки для новой страницы
         */
        const template_response = await PagesService.addTemplate(
            page_response.data.id,
            template.blocks_edit
        );

        if (template_response.result !== "success") {
            popout.error(template_response.message);
            return false;
        }

        popout.close();

        Analytics.goal(PAGES_CREATE, "pages");
        Analytics.goal(PAGES_CREATE_TEMPLATE, "pages");

        pages.addPage(PageToEntity(template_response.data), () => {
            const states =
                template_response.data.states &&
                template_response.data.states.length
                    ? template_response.data
                    : [];
            pages.setAvailablePageStates(states, () => {
                setHash({
                    list: "pages",
                    view: "pages-edit",
                    panel: "item",
                    page_id: template_response.data.id,
                });
            });
        });
    };

    const navigateCreatePage = () => {
        setHash({
            list: "pages",
            view: "pages",
            panel: "create",
        });
    };

    const content = {
        "my-pages": {
            render: () => {
                return launchParams.params.vk_platform === "desktop_web" ? (
                    <PagesListDesktop pagesProvider={pages} />
                ) : (
                    <PagesList pagesProvider={pages} />
                );
            },
        },
        templates: {
            render: () => {
                return (
                    <PagesTemplates
                        platform={launchParams.params.vk_platform}
                        onSelect={handleTemplateSelect}
                    />
                );
            },
        },
    };

    if (!groupSiteData.isConnected()) {
        return (
            <Placeholder
                action={<></>}
                stretched
            >
                <div>Сообщество не подключено</div>
            </Placeholder>
        );
    }

    if (isPendingLoadWidgets) {
        return (
            <PanelSpinner
                size="regular"
                className="vertical-centered-spinner"
            />
        );
    }

    return (
        <div className="content--inner">
            <Tabs>
                <TabsItem
                    selected={activeTab === "my-pages"}
                    onClick={() => {
                        setActiveTab("my-pages");
                    }}
                    style={{ flexGrow: 1, borderRadius: 0 }}
                >
                    Мои лендинги
                </TabsItem>
                <TabsItem
                    selected={activeTab === "templates"}
                    onClick={() => {
                        setActiveTab("templates");
                    }}
                    style={{ flexGrow: 1, borderRadius: 0 }}
                >
                    Шаблоны
                </TabsItem>
            </Tabs>

            {content[activeTab].render()}

            {activeTab === "my-pages" && (
                <FixedLayout filled={true} vertical={"bottom"}>
                    <Div className="Pages__create-button">
                        <Button
                            onClick={() => {
                                navigateCreatePage();
                            }}
                            stretched
                            size="l"
                        >
                            Создать лендинг
                        </Button>
                    </Div>
                </FixedLayout>
            )}
        </div>
    );
};

export default PagesIndexController;
