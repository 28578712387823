import React, { useReducer, Suspense, lazy, useContext } from "react";
import { FormLayout, FixedLayout, Div, Spinner, FormItem } from "@vkontakte/vkui";
import InputDynamic from "../../../../Controls/InputDynamic";
import FormSubmitButton from "../FormSubmitButton";
import Text from "../../Text";

import initialState, { SET_TEXT, SET_TITLE } from "./state";
import reducer from "./reducer";
import { Context } from "../../../../../context";

const MarkdownEditor = lazy(() => import("./MarkdownEditor"));

type TextEditProps = {
    data: any;
    currentDynamicInput: string;
    onSubmit: Function;
    openModal: Function;
};

const TextEdit = (props: TextEditProps) => {
    const { data, currentDynamicInput, onSubmit, openModal } = props;

    const { snackbar } = useContext(Context)

    // Сольем инициализирующее состояние и текущее состояние блока
    // Так же сольем инициализирующее состояние мета-информации блока с текущим состоянием мета-информации блока
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        ...{
            ...data,
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        const { title, text } = state.content

        if (title.trim() === "" && text.trim() === "") {
            snackbar.showError("Должно быть заполнено хотя бы одно из полей ");
            return false;
        }

        onSubmit({
            content: state.content,
        });
    };

    return (
        <div style={{ paddingBottom: 60 }}>
            <FormLayout>
                <FormItem>
                    <InputDynamic
                        currentOpened={currentDynamicInput}
                        type="text"
                        status="default"
                        bottomText={""}
                        top="Заголовок"
                        value={state.content.title}
                        placeholder="Введите заголовок"
                        name="title"
                        useVariables={true}
                        variablesMode="pages"
                        onChange={(value) => {
                            dispatch({ type: SET_TITLE, payload: value });
                        }}
                    />
                </FormItem>

                <Div>
                    <Suspense fallback={<Spinner size="small" />}>
                        <MarkdownEditor
                            title="Текст"
                            onChange={(value) => {
                                dispatch({ type: SET_TEXT, payload: value });
                            }}
                            value={state.content.text}
                            showPreview={() => {
                                openModal({
                                    id: "markdown-preview",
                                    args: {
                                        preview: (
                                            <Text
                                                data={{
                                                    content: state.content,
                                                    meta: state.meta,
                                                }}
                                                isPreview={true}
                                            />
                                        ),
                                    },
                                });
                            }}
                        />
                    </Suspense>
                </Div>

                <FixedLayout filled={true} vertical={"bottom"}>
                    <FormSubmitButton handleSubmit={handleSubmit} />
                </FixedLayout>
            </FormLayout>
        </div>
    );
};

export default TextEdit;
