import React from "react";
import FatalError from "./Routes/FatalError";
import container from "./container";
import Logger from "./includes/Services/Logger";
import * as Sentry from "@sentry/react";

type ErrorBoundaryProps = {};

type ErrorBoundaryState = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    logger: Logger;

    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.logger = container.get("logger");
    }

    static getDerivedStateFromError(error) {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        this.logger.error(
            { code: 9060, message: error.message },
            "runtime_error",
            "ErrorBoundary.jsx"
        );

        Sentry.captureException(error);
    }

    render() {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return <FatalError />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
