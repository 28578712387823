import React, { useContext } from "react";
import {
    ActionSheet,
    ActionSheetDefaultIosCloseItem,
    ActionSheetItem,
    platform,
    Platform,
} from "@vkontakte/vkui";
import { Icon24Link, Icon24Linked, Icon28CopyOutline, Icon28DeleteOutline, Icon28EditOutline, Icon28ErrorOutline, Icon28HideOutline, Icon28StatisticsOutline, Icon28ViewOutline } from "@vkontakte/icons";
import { Context } from "../../context";

type PagesContextPopoutProps = {
    isEdit: boolean;
    item: any;
    popout: any;
    pageUrl: string;
    onEditClick: CallableFunction;
    onDeleteClick: CallableFunction;
    onRenameClick: CallableFunction;
    onCopyUrlClick: CallableFunction;
    onPreviewClick: CallableFunction;
    onStatsClick: CallableFunction;
    onCopyClick: CallableFunction;
    onDisableClick: CallableFunction;
    popoutRef?: React.RefObject<Element>;
};

const PagesContextPopout = (props: PagesContextPopoutProps) => {
    const {
        isEdit,
        item,
        popout,
        pageUrl,
        onEditClick,
        onDeleteClick,
        onRenameClick,
        onCopyClick,
        onCopyUrlClick,
        onStatsClick,
        onDisableClick,
        popoutRef,
    } = props;

    const { pages, snackbar, groupSiteData } = useContext(Context);

    const allPages = pages.getAll();

    return (
        <ActionSheet
            onClose={() => popout.close()}
            iosCloseItem={<ActionSheetDefaultIosCloseItem />}
            toggleRef={popoutRef}
        >
            {!isEdit && (
                <ActionSheetItem
                    className="PagesContextPopout__item"
                    onClick={() => {
                        onEditClick(item.id);
                    }}
                    before={<Icon28EditOutline width={24} />}
                    autoClose
                >
                    Редактировать
                </ActionSheetItem>
            )}
            <ActionSheetItem
                className="PagesContextPopout__item"
                autoClose
                onClick={() => {
                    onStatsClick(item.id);
                }}
                before={<Icon28StatisticsOutline width={24} />}
            >
                Статистика
            </ActionSheetItem>
            <ActionSheetItem
                className="PagesContextPopout__item"
                onClick={() => {
                    onCopyClick && onCopyClick(item.id);
                }}
                before={<Icon28CopyOutline width={23} />}
            >
                Копировать
            </ActionSheetItem>
            <ActionSheetItem
                href={pageUrl}
                target="blank"
                className="PagesContextPopout__item"
                before={<Icon24Link />}
                autoClose
            >
                Перейти
            </ActionSheetItem>
            <ActionSheetItem
                className="PagesContextPopout__item"
                onClick={() => {
                    onCopyUrlClick(item.id);
                }}
                before={<Icon24Linked />}
                autoClose
            >
                Копировать ссылку
            </ActionSheetItem>
            <ActionSheetItem
                className="PagesContextPopout__item"
                onClick={() => {
                    onRenameClick(item.id);
                }}
                before={<Icon28EditOutline width={24} />}
                autoClose
            >
                Переименовать
            </ActionSheetItem>
            {item.status !== 3 ? (
                <ActionSheetItem
                    className="PagesContextPopout__item"
                    onClick={() => {
                        onDisableClick(item.id, 3);
                    }}
                    before={<Icon28HideOutline width={24} />}
                    autoClose
                >
                    Отключить
                </ActionSheetItem>
            ) : (
                <ActionSheetItem
                    autoClose
                    className="PagesContextPopout__item"
                    onClick={() => {
                        onDisableClick(item.id, 1);
                    }}
                    before={
                        <Icon28ViewOutline
                            width={24}
                            fill={"var(--vkui--color_accent_green)"}
                        />
                    }
                >
                    <span style={{ color: "var(--vkui--color_accent_green)" }}>
                        Включить
                    </span>
                </ActionSheetItem>
            )}
            <ActionSheetItem
                className="PagesContextPopout__item"
                onClick={() => {
                    onDeleteClick(item.id);
                }}
                before={
                    <Icon28DeleteOutline
                        width={24}
                        fill={"var(--vkui--color_accent_red)"}
                    />
                }
                autoClose
            >
                <span style={{ color: "var(--vkui--color_accent_red)" }}>
                    Удалить
                </span>
            </ActionSheetItem>

            {platform() !== Platform.IOS && (
                <ActionSheetItem
                    className={`PagesContextPopout__item ${
                        platform() === Platform.ANDROID
                            ? "action-sheet-android-cancel"
                            : ""
                    } cancel`}
                    mode="default"
                    autoClose
                >
                    Отмена
                </ActionSheetItem>
            )}
        </ActionSheet>
    );
};

export default PagesContextPopout;
