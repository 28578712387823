import bridge, { CommunityWidgetType } from "@vkontakte/vk-bridge";
import TokenVkStorage from "../includes/Services/TokensVKStorage";
import LoggerInterface from "../includes/Interfaces/LoggerInterface";
import container from "../container";
import {
    USER_TOKEN_REQUEST_FAILED,
    UNKNOWN_ERROR,
} from "../includes/ErrorMessages";
import {
    USER_AUTH_ERROR,
    TOKEN_NOT_VALID_ERROR,
    USER_ACCES_TOKEN_HAS_EXPIRED,
} from "./Constants";
import { USER_TOKEN_SCOPE_ACCESS_ERROR } from "./errors/UserApiErrors";
import { VkApiGetUserCommunitiesData } from "./Structures";

export interface wiget {
    type: CommunityWidgetType;
    code: string;
}

export default class ApiVk {
    private static instance;
    private setting: any;
    private ver: string;
    private logger: LoggerInterface;

    constructor(props) {
        this.setting = props;
        this.ver = "5.103";
        this.logger = container.get("logger");
    }

    static getInstance(props): ApiVk {
        if (!this.instance) {
            this.instance = new ApiVk(props);
        }

        return this.instance;
    }

    /**
     * Возвращает токен пользователя из VkStorage с указанными правами доступа
     * Если токена в VkStorage нет -> запросит токен, сохранит в VkStorage, вернет его. Иначе залогирует и вернет false
     *
     * @param {string} scope - права доступа
     * @returns {string | false}
     */
    async getUserTokenWithScope(scope: string = "") {
        let user_token = await TokenVkStorage.getUserToken(scope);

        if (!user_token) {
            try {
                const { access_token } = await this.getScope(scope);
                await TokenVkStorage.setUserToken(access_token, scope);
                user_token = access_token;
            } catch (e) {
                this.logError(
                    {
                        code: 9014,
                        message: "Get user token error",
                    },
                    "getUserTokenWithScope"
                );

                return false;
            }
        }

        return user_token;
    }

    /**
     * Возвращает токен сообщества из VkStorage с правами доступа app_widget
     * Если токена в VkStorage нет -> запросит токен, сохранит в VkStorage, вернет его. Иначе залогирует и вернет false
     *
     * @returns {string | false}
     */
    async getCommunityToken(scope: string = "") {
        const vkStorageScope = scope.replace(",", "_");

        let community_token = await TokenVkStorage.getCommunityToken(
            vkStorageScope
        );

        if (!community_token) {
            try {
                const { access_token } = await this.getScopePublic(scope);
                await TokenVkStorage.setCommunityToken(
                    vkStorageScope,
                    access_token
                );
                community_token = access_token;
            } catch (e) {
                this.logError(
                    {
                        code: 9061,
                        message: "Get community token error",
                        ...e,
                    },
                    "getCommunityToken"
                );

                return false;
            }
        }

        return community_token;
    }

    /**
     * Запрос на получение токена пользователя с указанными правами доступа
     * @param {string} scope - права доступа
     * @returns {Promise}
     */
    getScope(scope: string) {
        return bridge.send("VKWebAppGetAuthToken", {
            app_id: this.setting.vk_app_id,
            scope: scope,
        });
    }

    /**
     * Запрос на получение токена сообщества с правами доступа - app_widget
     * @returns {Promise}
     */
    getScopePublic(scope: string) {
        return bridge.send("VKWebAppGetCommunityToken", {
            app_id: this.setting.vk_app_id,
            group_id: this.setting.vk_group_id,
            scope: scope,
        });
    }

    /**
     * Запрос на получение изображений пользователя в документах ВК
     * @param {number} id - id пользователя
     */
    async getImgDocument(id: number) {
        const access_token = await this.getUserTokenWithScope("docs");

        try {
            const data = await bridge.send("VKWebAppCallAPIMethod", {
                method: "docs.get",
                params: {
                    owner_id: id,
                    v: this.ver,
                    count: "2000",
                    type: "4",
                    access_token: access_token,
                },
            });
            return data;
        } catch (e) {
            if (
                this.isTokenError(e) ||
                e.error_data.error_reason.error_code === 15
            ) {
                await TokenVkStorage.removeUserTokenWithScope("docs");
                return await this.getImgDocument(id);
            }

            this.logError({ code: 9062, ...e }, "getImgDocument");

            return {
                result: "error",
                message: e.error_data.error_reason.error_msg,
            };
        }
    }

    /**
     * Возвращает информацию о заданном сообществе или о нескольких сообществах.
     * @param {number} group_id
     */
    async getPublicInfo(group_id: number) {
        let access_token = await this.getUserTokenWithScope();

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params = {
            group_id: group_id,
            v: this.ver,
            access_token: access_token,
        };

        try {
            const result = await bridge.send("VKWebAppCallAPIMethod", {
                method: "groups.getById",
                params: params,
            });

            return result;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope();
                return await this.getPublicInfo(group_id);
            }

            this.logError({ code: 9016, ...e }, "getPublicInfo");

            return {
                result: "error",
                message: `Неверные параметры`,
            };
        }
    }

    /**
     * Получение товаров
     * @param {number} offset
     */
    async getProducts(offset: number = 0) {
        /**
         * Получаем токен пользователя с уровнем доступа "market" - для доступа к товарам сообщества
         * Если у пользователя ранее уже запрашивался доступ к такому scope - разрешение вновь запрашиваться не будет
         * Иначе пользователю будет показано модальное окно с разрешение доступа
         */
        const access_token = await this.getUserTokenWithScope("market");

        /*
         * Если токен не удалось получить ни из хранилища, ни путем запроса - вернем ошибку - что-то пошло не так.
         * Например, пользователь не выдал разрешение
         */
        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        /**
         * Сформируем параметры запроса к АПИ
         */
        let params = {
            owner_id: -this.setting.vk_group_id, // ID текущего сообщества
            count: 100, // Всего товаров в запросе
            offset: offset,
            extended: 1, // Расширенная информация по товарам
            v: this.ver,
            access_token: access_token,
        };

        try {
            const data = await bridge.send("VKWebAppCallAPIMethod", {
                method: "market.get",
                params: params,
            });

            return {
                result: "success",
                response: data.response,
            };
        } catch (e) {
            // Если что-то пошло не так, при запроса товаров
            let message = "";
            let code = 0;
            /**
             * Некоторые ответы отличаются по структуре
             * Поэтому приводим все к единому виду
             */
            if (
                e.error_type &&
                e.error_data &&
                e.error_data.error_reason &&
                e.error_data.error_reason.error_code
            ) {
                code = e.error_data.error_reason.error_code;
                message = e.error_data.error_reason.error_msg;
            } else if (
                e.error_type &&
                e.error_data &&
                e.error_data.error_code
            ) {
                code = e.error_data.error_code;
                message = e.error_data.error_msg;
            }

            // С кодом 15 могут вернуться разные ошибки
            if (code === USER_TOKEN_SCOPE_ACCESS_ERROR) {
                // Например с кодом 15 вернется ошибка "Access denied" - если в сообществе не подключен раздел Товары
                if (message === "Access denied") {
                    message = 'В сообществе не подключен раздел "Товары"';
                } else {
                    // Иначе код 15 - это ошибка доступа для токена - нужно обновить токен
                    await TokenVkStorage.removeUserTokenWithScope("market");
                    return await this.getProducts();
                }
                // Проверим все другие коды ошибок токена пользователя
            } else if (
                [
                    USER_AUTH_ERROR,
                    TOKEN_NOT_VALID_ERROR,
                    USER_ACCES_TOKEN_HAS_EXPIRED,
                ].indexOf(code) >= 0
            ) {
                // Запросим новый токен - если нужно
                await TokenVkStorage.removeUserTokenWithScope("market");
                return await this.getProducts();
            }
            this.logError({ code: 9017, message, ...e }, "market.get");
            // Во всех иных случаях вернем ошибку- что-то пошло не так
            return {
                result: "error",
                message: message,
                code: code,
            };
        }
    }

    /**
     * Получение всех стран из базы данных VK
     */
    async getCountries() {
        const access_token = await this.getUserTokenWithScope();

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params = {
            need_all: 1123,
            count: 234,
            v: this.ver,
            access_token: access_token,
        };

        try {
            const data = await bridge.send("VKWebAppCallAPIMethod", {
                method: "database.getCountries",
                params: params,
            });

            return data;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope();
                return await this.getCountries();
            }

            this.logError({ code: 9018, ...e }, "getCountries");

            return {
                result: "error",
                message: e.error_data.error_reason.error_msg,
            };
        }
    }

    /**
     * Поиск городов по базе данных VK в пределах определенный страны
     * @param {string} query - запрос
     * @param {number} country_id - идентификатор страны
     * @param {number} offset - отступ, необходимый для получения определенного подмножества городов
     * @param {number} count - количество запрашиваемых городов
     * По умолчанию ищем все города по России
     */
    async getCities(
        query: string = "",
        country_id: number = 1,
        offset: number = 0,
        count: number = 100
    ) {
        const access_token = await this.getUserTokenWithScope();

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params = {
            q: query,
            country_id: country_id,
            need_all: 1,
            v: this.ver,
            access_token: access_token,
            offset: offset,
            count: count,
        };

        try {
            const data = await bridge.send("VKWebAppCallAPIMethod", {
                method: "database.getCities",
                params: params,
            });

            return data;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope();
                return await this.getCities(query, country_id, offset, count);
            }

            this.logError({ code: 9019, ...e }, "getCities");

            return {
                result: "error",
                message: e.error_data.error_reason.error_msg,
            };
        }
    }

    /**
     * Поиск по сообществам VK
     * Пока ограничен только публичными страницами
     *
     * @param {string} query
     */
    async searchGroups(query: string = "") {
        const access_token = await this.getUserTokenWithScope();

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params = {
            q: query,
            v: this.ver,
            access_token: access_token,
            type: "page",
        };

        try {
            const data = await bridge.send("VKWebAppCallAPIMethod", {
                method: "groups.search",
                params: params,
            });

            return data;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope();
                return await this.searchGroups(query);
            }

            this.logError({ code: 9020, ...e }, "searchGroups");

            return {
                result: "error",
                message: e.error_data.error_reason.error_msg,
            };
        }
    }

    /**
     * Возвращает список пользователей в соответствии с заданным критерием поиска.
     * @param {any} queryParams
     */
    async searchUsers(queryParams: any) {
        const access_token = await this.getUserTokenWithScope();

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params = {
            q: queryParams.query,
            v: this.ver,
            access_token: access_token,
            count: 100,
            fields: "photo_50,sex",
            sex: queryParams.sex,
        };

        try {
            const data = await bridge.send("VKWebAppCallAPIMethod", {
                method: "users.search",
                params: params,
            });

            return data;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope();
                return await this.searchUsers(queryParams);
            }

            this.logError({ code: 9021, ...e }, "searchUsers");

            return {
                result: "error",
                message: e.error_data.error_reason.error_msg,
            };
        }
    }

    /**
     * Определяет тип объекта (пользователь, сообщество, приложение) и его идентификатор по короткому имени screen_name.
     *
     * @param {string} screen_name
     */
    async resolveScreenName(screen_name: string) {
        const access_token = await this.getUserTokenWithScope();

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params = {
            v: this.ver,
            access_token: access_token,
            screen_name,
        };

        try {
            const data = await bridge.send("VKWebAppCallAPIMethod", {
                method: "utils.resolveScreenName",
                params: params,
            });

            return data.response;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope();
                return await this.resolveScreenName(screen_name);
            }

            this.logError({ code: 9023, ...e }, "resolveScreenName");

            return {
                result: "error",
                message: e.error_data,
            };
        }
    }

    /**
     * Возвращает информацию о заданном сообществе
     * @param {number} community_id
     */
    async getCommunityById(community_id: number) {
        const access_token = await this.getUserTokenWithScope();

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params = {
            group_id: community_id,
            v: this.ver,
            access_token: access_token,
        };

        try {
            const data = await bridge.send("VKWebAppCallAPIMethod", {
                method: "groups.getById",
                params: params,
            });

            return data;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope();
                return await this.getCommunityById(community_id);
            }

            this.logError({ code: 9022, ...e }, "getCommunityById");

            return {
                result: "error",
                message: e.error_data,
            };
        }
    }

    /**
     * Возвращает список сообществ указанного пользователя.
     * @param {number} user_id
     * @param {string} filter
     * @returns {Promise<VkApiGetUserCommunitiesData>}
     */
    async getUserCommunities(
        user_id: number,
        filter: string = ""
    ): Promise<VkApiGetUserCommunitiesData> {
        const access_token = await this.getUserTokenWithScope();

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params: {
            v: string;
            access_token: string;
            user_id: number;
            extended: any;
            filter?: string;
        } = {
            v: this.ver,
            access_token: access_token,
            user_id: user_id,
            extended: 1,
        };

        if (filter) {
            params.filter = filter;
        }

        try {
            const data = await bridge.send("VKWebAppCallAPIMethod", {
                method: "groups.get",
                params: params,
            });

            return data.response;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope();
                return await this.getUserCommunities(user_id, filter);
            }

            this.logError(
                { code: 9024, message: e.message, ...e },
                "getUserCommunities"
            );

            return {
                result: "error",
                message: e.error_data,
            };
        }
    }

    /**
     * Получает информацию о комментарии на стене.
     * @param config
     */
    async getWallComment(config) {
        let access_token = await this.getUserTokenWithScope("wall");

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params = {
            owner_id: config.group_id,
            comment_id: config.comment_id,
            extended: 1,
            v: this.ver,
            access_token: access_token,
        };

        try {
            let result = await bridge.send("VKWebAppCallAPIMethod", {
                method: "wall.getComment",
                params: params,
            });

            return result;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope("wall");
                return await this.getWallComment(config);
            }
            this.logError({ code: 9025, ...e }, "getWallComment");
            return {
                result: "error",
                message: `Ошибка API - неверные параметры`,
            };
        }
    }

    /**
     * Возвращает список сообщений в указанной теме.
     * @param config
     */
    async getTopicComment(config) {
        let access_token = await this.getUserTokenWithScope();

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params = {
            group_id: config.group_id,
            topic_id: config.topic_id,
            start_comment_id: config.comment_id,
            count: "1",
            extended: "1",
            v: this.ver,
            access_token: access_token,
        };

        try {
            let result = await bridge.send("VKWebAppCallAPIMethod", {
                method: "board.getComments",
                params: params,
            });

            return result;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope();
                return await this.getTopicComment(config);
            }

            this.logError({ code: 9026, ...e }, "getTopicComment");

            return {
                result: "error",
                message: `Ошибка API - неверные параметры`,
            };
        }
    }

    /**
     * Возвращает avatar пользователя размером 200x200 пикселей.
     * @param {number} user_id
     */
    async getUserImg(user_id: number) {
        let access_token = await this.getUserTokenWithScope();

        if (!access_token) {
            return {
                result: "error",
                message: USER_TOKEN_REQUEST_FAILED,
            };
        }

        let params = {
            user_ids: user_id,
            fields: "photo_200",
            v: this.ver,
            access_token: access_token,
        };

        try {
            let result = await bridge.send("VKWebAppCallAPIMethod", {
                method: "users.get",
                params: params,
            });

            return result;
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.removeUserTokenWithScope();
                return await this.getUserImg(user_id);
            }

            this.logError({ code: 9027, ...e }, "getUserImg");

            return {
                result: "error",
                message: `Ошибка API - неверные параметры`,
            };
        }
    }

    /**
     * Возвращает текущее время на сервере ВКонтакте в unixtime.
     */
    async getServerTime() {
        let community_token = await TokenVkStorage.getCommunityToken(
            "app_widget"
        );

        if (!community_token) {
            community_token = await this.getCommunityToken("app_widget");

            if (community_token === false) {
                return {
                    result: "error",
                };
            }
        }

        let params = {
            v: this.ver,
            access_token: community_token,
        };

        try {
            const data = await bridge.send("VKWebAppCallAPIMethod", {
                method: "utils.getServerTime",
                params: params,
            });

            if (data.response) {
                return data.response;
            } else {
                return 0;
            }
        } catch (e) {
            if (this.isTokenError(e)) {
                await TokenVkStorage.dropCommunityToken("app_widget");
                return await this.getServerTime();
            }

            this.logError({ code: 9028, ...e }, "getServerTime");

            return 0;
        }
    }

    /**
     * Возвращает информацию о комментарии в обсуждении, посте
     * @param {string} url
     */
    async getComment(url: string) {
        try {
            let str = "";
            url.indexOf("wall") > 0
                ? (str = url.slice(url.indexOf("wall") + 4, url.length))
                : (str = url.slice(url.indexOf("topic") + 5, url.length));

            let group_id = str.slice(
                url.indexOf("wall") > 0 ? 0 : 1,
                str.indexOf("_")
            );
            let topic_id = str.slice(str.indexOf("_") + 1, str.indexOf("?"));

            const strLength =
                str.indexOf("&") > 0 ? str.indexOf("&") : str.length;
            let comment_id = str.slice(str.indexOf("=") + 1, strLength);

            const isWallComment = url.indexOf("topic") > 0 ? false : true;

            let res = null;

            if (isWallComment) {
                res = await this.getWallComment({ group_id, comment_id });
            } else {
                res = await this.getTopicComment({
                    group_id,
                    topic_id,
                    comment_id,
                });
            }

            if (!res) {
                return { result: "error", message: UNKNOWN_ERROR };
            } else if (res.result && res.result === "error") {
                return { result: "error", message: res.message };
            } else if (
                res.response &&
                res.response.items &&
                res.response.items.length === 0
            ) {
                return { result: "error", message: "Комментарий не найден" };
            } else if (
                res.response &&
                res.response.items &&
                res.response.items.length > 0
            ) {
                let user;
                res.response.items[0].from_id < 0
                    ? (user = false)
                    : (user = true);

                let img_url = "";

                if (user) {
                    if (res.response.profiles[0].photo_100) {
                        img_url = res.response.profiles[0].photo_100;
                    } else {
                        img_url = res.response.profiles[0].photo_50;
                    }
                } else {
                    if (res.response.groups[0].photo_100) {
                        img_url = res.response.groups[0].photo_100;
                    } else {
                        img_url = res.response.groups[0].photo_50;
                    }
                }

                return {
                    result: "success",
                    name: user
                        ? `${res.response.profiles[0].first_name} ${res.response.profiles[0].last_name}`
                        : res.response.groups[0].name,
                    text: res.response.items[0].text,
                    img: {
                        url: img_url,
                    },
                    vkUrl: url,
                    icon_id: res.response.items[0].from_id,
                };
            } else {
                return { result: "error", message: UNKNOWN_ERROR };
            }
        } catch (e) {}
    }

    /**
     * Показывает экран предпросмотра виджета для сообщества.
     * @param {wiget} wiget
     * @returns {Promise}
     */
    wigetPreview(wiget: wiget) {
        return bridge.send("VKWebAppShowCommunityWidgetPreviewBox", {
            group_id: this.setting.vk_group_id,
            type: wiget.type,
            code: wiget.code,
        });
    }

    /**
     * Проверяет на валидность токена
     * @param {object} e
     * @returns {boolean}
     */
    isTokenError(e): boolean {
        if (!e || typeof e !== "object") {
            return false;
        }

        let code = null;

        if (
            e.error_type &&
            e.error_data &&
            e.error_data.error_reason &&
            e.error_data.error_reason.error_code
        ) {
            code = e.error_data.error_reason.error_code;
        } else if (e.error_type && e.error_data && e.error_data.error_code) {
            code = e.error_data.error_code;
        }

        return (
            [
                USER_AUTH_ERROR,
                TOKEN_NOT_VALID_ERROR,
                USER_ACCES_TOKEN_HAS_EXPIRED,
            ].indexOf(code) >= 0
        );
    }

    /**
     * Отправляет информацию об ошибках в Elasticsearch
     * @param {object} e
     * @param {string} method
     */
    logError(e, method: string) {
        let data: any = {};
        if (e.error_data.error_reason && e.error_data.error_reason.error_code) {
            data.code = e.error_data.error_reason.error_code;
        }

        if (e.error_data.error_reason && e.error_data.error_reason.error_msg) {
            data.message = e.error_data.error_reason.error_msg;
        }

        if (e.message) {
            data.message = e.message;
        }

        if (e.code) {
            data.code = e.code;
        }

        if (method) {
            data.message += `${data.message} ${method}`;
        }

        try {
            data.message += ` ${JSON.stringify(e)}`;
        } catch (e) {}
        this.logger.error(data, `client_vk_api_error`, "ApiVK.ts");
    }
}
