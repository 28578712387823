import React, { useState, useEffect, useReducer } from "react";
import { Cell, Button, Div, CellButton, Separator } from "@vkontakte/vkui";
import SubscriptionsProviderInterface from "../../includes/Interfaces/SubscriptionsProviderInterface";
import ModalProviderInterface from "../../includes/Interfaces/ModalProviderInterface";
import SubscriptionShort from "../SubscriptionShort";
import { Icon20HideOutline, Icon24Add } from '@vkontakte/icons';

import reducer, {
    initialState,
    PUT_EDIT_MODE,
    PUT_SUBSCRIPTIONS,
    SORT_SUBSCRIPTION_GROUPS,
    HIDE_SUBSCRIPTION_GROUP,
    SHOW_SUBSCRIPTION_GROUP,
} from "../../includes/reducers/subscriptionsList";

declare type SubscriptionsListProps = {
    subscriptionsProvider: SubscriptionsProviderInterface;
    modalProvider: ModalProviderInterface;
    catalogType: string;
    onChange: Function;
    urlParams: any;
};

const SubscriptionsList = (props: SubscriptionsListProps) => {
    const {
        subscriptionsProvider,
        modalProvider,
        onChange,
        catalogType,
        urlParams,
    } = props;

    const subscriptionsRaw = subscriptionsProvider.get();

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        subscriptions: subscriptionsRaw.filter((s) => s.data.inactive === 0),
        subscriptions_hidden: subscriptionsRaw.filter(
            (s) => s.data.inactive === 1
        ),
    });

    const [providerState, setProviderState] = useState(
        JSON.stringify({
            subscriptions: subscriptionsRaw.filter(
                (s) => s.data.inactive === 0
            ),
            subscriptions_hidden: subscriptionsRaw.filter(
                (s) => s.data.inactive === 1
            ),
        })
    );

    const catalogRenderType = catalogType ? catalogType : "icons";
    const catalogClassName = `Subscriptions-catalog Subscriptions-catalog_${
        catalogRenderType === "tiles" ? "tiles" : "rows"
    }`;

    useEffect(() => {
        dispatch({
            type: PUT_SUBSCRIPTIONS,
            payload: {
                isEditMode: state.isEditMode,
                subscriptions: subscriptionsRaw.filter(
                    (s) => s.data.inactive === 0
                ),
                subscriptions_hidden: subscriptionsRaw.filter(
                    (s) => s.data.inactive === 1
                ),
            },
        });

        setProviderState(
            JSON.stringify({
                subscriptions: subscriptionsRaw.filter(
                    (s) => s.data.inactive === 0
                ),
                subscriptions_hidden: subscriptionsRaw.filter(
                    (s) => s.data.inactive === 1
                ),
            })
        );
    }, [subscriptionsRaw]);

    useEffect(() => {
        const newState = JSON.stringify({
            subscriptions: state.subscriptions,
            subscriptions_hidden: state.subscriptions_hidden,
        });

        const hasChanged = providerState !== newState;

        onChange({
            subscriptions: state.subscriptions,
            subscriptions_hidden: state.subscriptions_hidden,
            isEditMode: state.isEditMode,
            changed: hasChanged,
        });
    }, [state]);

    const setEditMode = (value: boolean) => {
        dispatch({
            type: PUT_EDIT_MODE,
            payload: value,
        });
    };

    const handleOpenHiddenSubscriptionsModal = (e) => {
        modalProvider.set({
            id: "subscriptions",
            args: {
                subscriptions: state.subscriptions_hidden,
                callback: (item) => {
                    dispatch({
                        type: SHOW_SUBSCRIPTION_GROUP,
                        payload: item,
                    });
                },
            },
        });
    };

    const handleDragFinish = (data) => {
        dispatch({
            type: SORT_SUBSCRIPTION_GROUPS,
            payload: { itemIndex: data.from, toIndex: data.to },
        });
    };

    const handleHideSubscriptionGroup = (id) => {
        dispatch({
            type: HIDE_SUBSCRIPTION_GROUP,
            payload: id,
        });
    };

    const renderHeader = () => {
        return (
            <div className="SubscriptionList__header">
                <CellButton
                    before={
                        <Icon24Add
                            style={{
                                opacity: state.isEditMode ? 0.4 : 1,
                                cursor: state.isEditMode ? "auto" : "pointer",
                            }}
                            onClick={
                                state.isEditMode
                                    ? () => {}
                                    : handleOpenHiddenSubscriptionsModal
                            }
                        />
                    }
                    after={
                        <Button
                            className="SubscriptionList__editmode-button"
                            mode="tertiary"
                            onClick={() => {
                                setEditMode(!state.isEditMode);
                            }}
                        >
                            {state.isEditMode ? "Готово" : "Изменить"}
                        </Button>
                    }
                ></CellButton>

                <Separator wide />
            </div>
        );
    };

    const renderEditableSubscriptionsList = () => {
        return state.subscriptions.map((sub) => {
            return (
                <Cell
                    key={sub.data.subscription_id}
                    className="border-bottom"
                    draggable={state.isEditMode}
                    mode={state.isEditMode ? "removable": undefined}
                    removePlaceholder={<Icon20HideOutline />}
                    onRemove={(e) => {
                        handleHideSubscriptionGroup(sub.data.subscription_id);
                    }}
                    onDragFinish={({ from, to }) => {
                        handleDragFinish({
                            from,
                            to,
                            item: sub,
                        });
                    }}
                    subtitle={
                        state.isEditMode === false && (
                            <>
                                {sub.data.text}
                                <div style={{ marginTop: 5 }}>
                                    <Button>{sub.data.btn_ok}</Button>
                                </div>
                            </>
                        )
                    }
                >
                    {sub.data.name}
                </Cell>
            );
        });
    };

    const renderBaseSubscriptionsList = () => {
        return (
            <div className={catalogClassName}>
                {state.subscriptions.map((sub) => {
                    return (
                        <Div
                            className="Subscriptions-catalog__item"
                            key={sub.data.subscription_id}
                        >
                            <SubscriptionShort
                                urlParams={urlParams}
                                opts={sub.data}
                                unsubscribe={() => {}}
                                prepare_subscribe={() => {}}
                                type={catalogType}
                            />
                        </Div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="SubscriptionList">
            {renderHeader()}
            <div className="SubscriptionList__content">
                {state.isEditMode
                    ? renderEditableSubscriptionsList()
                    : renderBaseSubscriptionsList()}
            </div>
        </div>
    );
};

export default SubscriptionsList;
