import React from "react";
import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import PageButton from "../../hoc/PageButton";
import PageLink from "../../hoc/PageLink";

type ButtonBlockProps = {
    data: any;
    isPreview: boolean;
    blockPadding?: number;
    blockAlignment?: BlockAlignment;
};

const Button = (props: ButtonBlockProps) => {
    const {
        data,
        isPreview = false,
        blockPadding = 20,
        blockAlignment = "center",
    } = props;

    const renderButton = () => {
        return (
            <PageButton
                isPreview={isPreview}
                pageId={data.page_id}
                blockId={data.id}
                data={data.button}
            />
        );
    };

    const renderUrl = () => {
        return (
            <PageLink
                isPreview={isPreview}
                id={data.button.id}
                pageId={data.page_id}
                blockId={data.id}
                url={data.button.url}
                text={data.button.text}
                color={data.button.color}
                textColor={data.button.text_color}
            />
        );
    };

    return (
        <div
            className={`B-Block B-Button ${blockAlignment}`}
            style={{
                paddingTop: `${blockPadding}px`,
                paddingBottom: `${blockPadding}px`,
            }}
        >
            <div className="B-Button__inner">
                {data.button.action === "url" ? renderUrl() : renderButton()}
            </div>
        </div>
    );
};

export default Button;
