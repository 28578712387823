import WidgetCodeFormatterInterface from "../Interfaces/WidgetCodeFormatterInterface";
import { AUDIENCE_ANY } from "../Constants";
import { Widget } from "../Structures";

class WidgetCodeFormatter implements WidgetCodeFormatterInterface {
    userGetGroupsCount: number;

    constructor() {
        this.userGetGroupsCount = 300;
    }

    createCode(codes: string[], previewCode: string, previewEndTime: number) {
        let code = `

        #! Пример комментария - перед отправкой кода на публикацию все комментарии будут удалены #

        #! Запрашивaем данные просматривающего пользователя #
        var user = API.users.get({ id: Args.uid, fields: "${this.getUserApiFields()}" })[0];

        #! Запрашиваем данные случайного друга просматривающего пользователя #
        var userFriend = API.friends.get({user_id: user.id, order: 'random', count: 1});

        #! Запрашиваем комментарий из обсуждения частной группы (дата) #
        var storageTopic = API.board.getComments({ group_id: 215257734, topic_id: 48791589 });

        var topicDateStr = storageTopic.items ? storageTopic.items[0].text : '';

        var dates = topicDateStr.split(';');

        #! Кешируем количество запросов к апи вк внутри метода. Понадобиться при проверке подписок пользователя #
        var api_requests_count = 4;

        var userFriendId = userFriend.items ? userFriend.items[0] : null;

        #! Покажем превью виджета - необходимо перед публикацией. Превью устанавливается в сообщество на 60 секунд #
        ${
            previewCode && previewEndTime
                ? this.pushPreviewCode(previewCode, previewEndTime)
                : ""
        }

        var widgets = [${codes}];
        var currentYear  = dates[0];
        var currentMonth = dates[1];
        var currentDate  = dates[2];
        var today = currentDate + "." + currentMonth;
        var weekAheadDates = dates[3].split(',');
        var tomorrow = weekAheadDates[0];

        var userData = {
            id: user.id,
            name: user.first_name,
            last_name: user.last_name,
            interests: user.interests
        };

        var widgetByDate = {
            by: "",
            index: -1
        };

        if (user.sex) {
            userData.sex = user.sex;
        } else {
            userData.sex = 0;
        }

        if (user.relation) {
            userData.relation = user.relation;
        } else {
            userData.relation = 0;
        }

        if (user.city) {
            userData.city = user.city.id;
        } else {
            userData.city = -1;
        }

        if (user.country) {
            userData.country = user.country.id;
        } else {
            userData.country = -1;
        }

        if (user.bdate) {
            var userBirthDate  = user.bdate.split('.');
            if (userBirthDate.length == 3) {
                var userBirthYear  = parseInt(userBirthDate[2]);
                var userBirthMonth = parseInt(userBirthDate[1]);
                var userBirthDate  = parseInt(userBirthDate[0]);
                userData.bday = userBirthDate + '.' + userBirthMonth;
                var estimatedAge = currentYear - userBirthYear;
                if  (currentMonth < userBirthMonth) {
                    userData.age = estimatedAge - 1;
                }
                if (currentMonth > userBirthMonth) {
                    userData.age = estimatedAge;
                }
                if (currentMonth == userBirthMonth) {
                    if (userBirthDate <= currentDate) {
                        userData.age = estimatedAge;
                    } else {
                        userData.age = estimatedAge - 1;
                    }
                }
            } else {
                userData.bday = user.bdate;
            }
        }

        if (user.relation_partner) {
            var partner = API.users.get({ user_ids: user.relation_partner.id, fields: "interests" })[0];

            api_requests_count = api_requests_count + 1;

            userData.partner_interests = partner.interests;
            userData.partner_name = partner;
        } else {
            userData.partner_interests = '';
            userData.partner_name = '';
        }

        var resultWidgets = [];

        var i = 0;
        while(i < widgets.length) {

            var widget = widgets[i];
            var audience = widget.audience;

            widget.isMatch = true;

            if (audience.users) {
                if (audience.users.indexOf(userData.id) < 0) {
                    widget.isMatch = false;
                }
            }

            if (audience.sex) {
                if (audience.sex != userData.sex) {
                    widget.isMatch = false;
                }
            }

            if (audience.currentCountry) {
                if (audience.currentCountry.indexOf(userData.country) < 0) {
                    widget.isMatch = false;
                }
            }

            if (audience.currentCity) {
                var foundCity = false;
                var cities = audience.currentCity;
                var z = 0;

                while (z < cities.length) {
                    var id = cities[z];
                    if (userData.city == id) {
                        foundCity = true;
                    };
                    z = z + 1;
                }
                if (!foundCity) {
                    widget.isMatch = false;
                }
            }

            if (audience.platform) {
                var platforms = {
                    mobile: 1,
                    iphone: 2,
                    android: 4,
                    web: 5
                };

                if (audience.platform.indexOf(platforms[Args.platform]) < 0) {
                    widget.isMatch = false;
                }
            }

            if (audience.relation) {
                if (audience.relation.indexOf(userData.relation) < 0) {
                    widget.isMatch = false;
                }
            }

            if (audience.communityIn) {
                var found = false;
                var communities = audience.communityIn;
                var k = 0;

                while (k < communities.length) {
                    if (api_requests_count < 25 && !found) {
                        var group_id = communities[k];
                        if (API.groups.isMember({group_id:group_id, user_id: Args.uid}) == 1) {
                            found = true;
                        };
                        api_requests_count = api_requests_count + 1;
                    };
                    k = k + 1;
                }
                if (!found) {
                    widget.isMatch = false;
                }
            }

            if (audience.communityOut) {
                var foundCo = false;
                var communitiesOut = audience.communityOut;
                var j = 0;

                while (j < communitiesOut.length) {
                    if (api_requests_count < 25 && !foundCo) {
                        var group_id = communitiesOut[j];
                        if (API.groups.isMember({group_id:group_id, user_id: Args.uid}) == 1) {
                            foundCo = true;
                        };
                        api_requests_count = api_requests_count + 1;
                    }
                    j = j + 1;
                }
                if (foundCo) {
                    widget.isMatch = false;
                }
            }

            if (audience.ageFrom) {
                if (userData.age) {
                  if (audience.ageFrom > userData.age) {
                    widget.isMatch = false;
                  }
                }
            }

            if (audience.ageTo) {
                if (userData.age) {
                  if (audience.ageTo < userData.age) {
                    widget.isMatch = false;
                  }
                }
            }

            if (audience.names) {
                var match = false;
                var n = 0;
                while(n < audience.names.length) {
                    var name = audience.names[n];
                    if (name == userData.name) {
                        match = true;
                    }
                    n = n + 1;
                }

                if (!match) {
                    widget.isMatch = false;
                }
            }

            if (audience.surnames) {
                var matchSurname = false;
                var l = 0;
                while(l < audience.surnames.length) {
                    var surname = audience.surnames[l];
                    if (surname == userData.last_name) {
                        matchSurname = true;
                    }
                    l = l + 1;
                }

                if (!matchSurname) {
                    widget.isMatch = false;
                }
            }

            if (audience.userInterests) {
                if (userData.interests) {
                    var interests_match = false;
                    var m = 0;
                    while(m < audience.userInterests.length) {
                        var ph = audience.userInterests[m];
                        if (userData.interests.indexOf(ph) >= 0) {
                            interests_match = true;
                        }
                        m = m + 1;
                    }
                    if (!interests_match) {
                        widget.isMatch = false;
                    }
                } else {
                    widget.isMatch = false;
                }
            }

            if (audience.userCoupleInterests) {
                if (userData.partner_interests) {
                    var match = false;
                    var m = 0;
                    while(m < audience.userCoupleInterests.length) {
                        var phrase = audience.userCoupleInterests[m];
                        if (userData.partner_interests.indexOf(phrase) >= 0) {
                            match = true;
                        }
                        m = m + 1;
                    }
                    if (!match) {
                        widget.isMatch = false;
                    }
                } else {
                    widget.isMatch = false;
                }
            }

            if (audience.bDay) {
                if (userData.bday) {

                    var bDayMatch = false;

                    var i = 0;

                    while (i < audience.bDay.length) {
                        var audienceBday = audience.bDay[i];

                        if (audienceBday == 'today') {
                            if (userData.bday == today) {
                                bDayMatch = true;
                                widgetByDate.index = resultWidgets.length;
                            }
                        }

                        if (audienceBday == 'tomorrow') {
                            if (userData.bday == tomorrow && widgetByDate.by != 'tomorrow') {
                                bDayMatch = true;
                                widgetByDate.by = 'tomorrow';
                                widgetByDate.index = resultWidgets.length;
                            }
                        }

                        if (audienceBday == 'week') {
                            if (weekAheadDates.indexOf(userData.bday) >= 0 && widgetByDate.by != 'tomorrow') {
                                bDayMatch = true;
                                widgetByDate.by = 'week';
                                widgetByDate.index = resultWidgets.length;
                            }
                        }

                        i = i + 1;
                    }

                    if (!bDayMatch) {
                        widget.isMatch = false;
                    }
                }
            }

            if (widget.audience.length <= 0) {
                resultWidgets.push(widget);
            } else {
                if (widget.isMatch) {
                    resultWidgets.push(widget);
                }
            }

            i = i + 1;
        }

        if (resultWidgets.length > 0) {
            var index = 0;
            if (widgetByDate.index != -1) {
                index = widgetByDate.index;
            }
            var widget = resultWidgets[index].code;
            return widget;
        } else {
            return false;
        }

        `;

        code = this.prepareCode(code);

        return code;
    }

    pushPreviewCode(previewCode: string, previewEndTime: number) {
        return `
            var current_server_time = API.utils.getServerTime();
            if (current_server_time < ${previewEndTime}) {
                return ${previewCode}.code;
            }
        `;
    }

    /**
     * Удаление комментариев перед отправкой на публикацию в ВК
     * @param code
     */
    prepareCode(code: string): string {
        return (
            code
                // Удаляем комментарии
                .replace(/#!.*#/gm, "")
                // Удаляем пустые строки
                .replace(/(^[ \t]*\n)/gm, "")
        );
    }

    createAudience(widget: Widget) {
        let pairs = [];

        let audience = { ...widget.audience };

        for (let criteria in audience) {
            let variants = audience[criteria];

            if (variants === AUDIENCE_ANY) {
                continue;
            }

            switch (criteria) {
                case "sex":
                case "ageFrom":
                case "ageTo":
                    if (variants === AUDIENCE_ANY) continue;
                    else pairs.push(`${criteria}:${variants}`);
                    break;
                case "platform":
                case "relation":
                    if (variants.length === 0 || variants === AUDIENCE_ANY)
                        continue;
                    else pairs.push(`${criteria}:[${variants.join(",")}]`);
                    break;
                case "users":
                case "communityIn":
                case "communityOut":
                case "currentCity":
                case "currentCountry":
                    if (!variants.length || variants.length === 0) continue;
                    else
                        pairs.push(
                            `${criteria}:[${variants
                                .map((i) => i.id)
                                .join(",")}]`
                        );
                    break;
                case "bDay":
                case "names":
                case "surnames":
                case "userInterests":
                case "userCoupleInterests":
                    if (variants === AUDIENCE_ANY) continue;
                    else
                        pairs.push(
                            `${criteria}:[${variants
                                .map((i) => `"${i}"`)
                                .join(",")}]`
                        );
                    break;
            }
        }

        return `audience: {${pairs.join(",")}}`;
    }

    getUserApiFields() {
        const fields = [
            "first_name_gen",
            "first_name_dat",
            "first_name_acc",
            "first_name_ins",
            "first_name_abl",
            "last_name_gen",
            "last_name_dat",
            "last_name_acc",
            "last_name_ins",
            "last_name_abl",
            "photo_id",
            "counters",
            "sex",
            "bdate",
            "city",
            "country",
            "home_town",
            "has_photo",
            "online",
            "domain",
            "has_mobile",
            "contacts",
            "site",
            "education",
            "universities",
            "schools",
            "status",
            "nickname",
            "relatives",
            "relation",
            "personal",
            "connections",
            "exports",
            "activities",
            "interests",
            "music",
            "movies",
            "tv",
            "books",
            "games",
            "about",
            "quotes",
            "timezone",
            "screen_name",
        ];

        return fields.join(",");
    }
}

export default WidgetCodeFormatter;
