/**
 * Цели аналитики
 */
export const METRIKA_COUNTER = 57544282;

// Цели для внутренней аналитики по виджетам
export const WIDGET_CREATE = "widget-create"; //Создание нового виджета
export const WIDGET_DELETE = "widget-delete";
export const WIDGET_PUBLISH = "widget-publish";
export const WIDGET_EDIT = "widget-edit";
export const WIDGET_AUDIENCE_HIT = "widget-audience-hit";
export const WIDGET_UNDO_STATE = "widget-undo-state";
export const WIDGET_CLONE = "widget-clone";
export const ANALITICS_WIDGET_CATEGORY = "widget";

// Цели для внутренней аналитики по промо-страницам
/**
 * pages_create
 * pages_create_empty
 * pages_create_template
 * pages_block_add
 * pages_block_add_recent
 * pages_block_edit
 * pages_block_edit_button
 * pages_publish
 * pages_stat_view
 * page_view_common
 */

export const ANALITICS_PAGES_CATEGORY = "pages";
export const PAGES_CREATE = "pages_create";
export const PAGES_CREATE_EMPTY = "pages_create_empty";
export const PAGES_CREATE_TEMPLATE = "pages_create_template";
export const PAGES_BLOCK_ADD = "pages_block_add";
export const PAGES_BLOCK_ADD_RECENT = "pages_block_add_recent";
export const PAGES_BLOCK_EDIT = "pages_block_edit";
export const PAGES_BLOCK_EDIT_BUTTON = "pages_block_edit_button";
export const PAGES_BLOCK_AUDIENCE_HIT = "pages_block_audience_hit";
export const PAGES_PUBLISH = "pages_publish";
export const PAGES_STAT_VIEW = "pages_stat_view";
export const PAGES_VIEW_COMMON = "page_view_common";

interface MetricaGoal {
    target: string; // Goal identifier
    params?: object;
}

interface AnalyticsGoal {
    action: string; // Goal identifier,
    category: string;
}

declare global {
    interface Window {
        ym: any;
        gtag: any;
        dataLayer: any;
    }
}

const AnalyticsInstance = {
    goal: function (identifier: string, type = "widgets", params?: object) {
        try {
            this.sendYandex({
                target: identifier,
                params: params ? params : {},
            });
            this.sendGoogle({
                action: identifier,
                category:
                    type === "widgets"
                        ? ANALITICS_WIDGET_CATEGORY
                        : ANALITICS_PAGES_CATEGORY,
            });
        } catch (e) {
            console.log(e);
        }
    },

    sendYandex: function (goal: MetricaGoal) {
        if (typeof window.ym !== "undefined") {
            window.ym(METRIKA_COUNTER, "reachGoal", goal.target, goal.params);
        }
    },

    sendGoogle: function (goal: AnalyticsGoal) {
        if (typeof window.gtag !== "undefined") {
            window.gtag("event", goal.action, {
                event_category: goal.category,
                event_action: goal.action,
            });
        }
    },

    push: function (event: any) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    },
};

export const Analytics = Object.freeze(AnalyticsInstance);
