import React, { useContext, useState } from "react";
import BlockHeadForm from "../Pages/Blocks/BlockHeadForm";
import { Button, Div } from "@vkontakte/vkui";
import { Context } from "../../context";

type ModalTextProps = {
    content: { title: string; text: string };
    close: Function;
    callback: Function;
};

const ModalText = (props: ModalTextProps) => {
    const {
        content = { title: "", text: "" },
        close = () => {},
        callback = () => {},
    } = props;

    const { snackbar } = useContext(Context);

    const [title, setTitle] = useState(content.title || "");
    const [text, setText] = useState(content.text || "");

    const saveModal = () => {
        const data: any = {};

        if (title.trim() === "" && text.trim() === "") {
            snackbar.showError("Должно быть заполнено хотя бы одно из полей ");
            return false;
        }

        data.title = title;
        data.text = text;

        callback(data);
        close();
    };

    return (
        <>
            <BlockHeadForm
                title={title}
                text={text}
                changeTitle={setTitle}
                changeText={setText}
                {...props}
            />
            <Div>
                <Button stretched size="l" onClick={saveModal}>
                    Сохранить
                </Button>
            </Div>
        </>
    );
};

export default ModalText;
