import React from "react";
import { Footer } from "@vkontakte/vkui";
import { BlockAlignment } from "../../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";

const defaultResizer = {
    // Объект, управляющий высотой приложения на десктопе
    setLandingHeight: () => {},
};

type ImagesSingleProps = {
    data: any;
    blockPadding?: number;
    resizer?: any;
    platform: string;
    isEdit: boolean;
    blockAlignment?: BlockAlignment;
};

const ImagesSingle = (props: ImagesSingleProps) => {
    const {
        data,
        blockPadding = 0,
        isEdit = true,
        platform,
        resizer = defaultResizer,
        blockAlignment = "center",
    } = props;

    const imgRenderer = () => {
        resizer.setLandingHeight();
    };

    const imgStartRenderer = () => {
        requestAnimationFrame(imgRenderer);
    };

    let src = "";

    if (platform === "desktop_web" && data.content.desktop_img) {
        src = data.content.desktop_img;
    } else if (platform !== "desktop_web" && data.content.mobile_img) {
        src = data.content.mobile_img;
    }

    return (
        <div
            className={`B-Block B-Images B-Images_single ${blockAlignment}`}
            style={{
                paddingTop: `${blockPadding}px`,
                paddingBottom: `${blockPadding}px`,
            }}
        >
            {src &&
                (data.content.address_url ? (
                    <a
                        href={data.content.address_url}
                        title="Перейти"
                        target="_blank"
                    >
                        <img
                            src={src}
                            alt=""
                            onLoad={(e) => {
                                requestAnimationFrame(imgStartRenderer);
                            }}
                        />
                    </a>
                ) : (
                    <img
                        src={src}
                        alt=""
                        onLoad={(e) => {
                            requestAnimationFrame(imgStartRenderer);
                        }}
                    />
                ))}
            {isEdit && !src && (
                <div style={{ padding: 20 }}>
                    <Footer>Пустой блок "Изображение"</Footer>
                </div>
            )}
        </div>
    );
};

export default ImagesSingle;
