import React, { useReducer, useEffect, useState, useContext } from "react";
import { Button, FormLayout, Input } from "@vkontakte/vkui";
import reducer from "./state/reducer";
import initialState from "./state/state";
import WidgetValidate from "../../../includes/Validators/WidgetValidate";
import { countStringLength } from "../../../includes/Helpers/Helpers";
import {
    MAX_STRING_LENGTH,
    MAX_BUTTON_STRING_LENGTH,
    URL_PLACEHOLDER,
} from "../../../includes/Constants";
import InputDynamic from "../../Controls/InputDynamic";
import { Context } from "../../../context";

const ItemGeo = (props) => {
    const { inputDynamic } = useContext(Context);

    const [state, dispatch] = useReducer(reducer, initialState);
    const [name, setName]: any = useState({});

    const SaveModal = () => {
        let isValid = true;

        let titleLength = countStringLength(state.title);
        let descrLength = countStringLength(state.descr);
        let geoLength = countStringLength(state.geo);
        let timeLength = countStringLength(state.time);

        if (state.title.trim() === "") {
            isValid = false;
            dispatch({
                type: "title",
                payload: {
                    text: state.title,
                    status: true,
                    buttom: `Заполните заголовок`,
                },
            });
        } else if (titleLength > MAX_STRING_LENGTH) {
            isValid = false;
            dispatch({
                type: "title",
                payload: {
                    text: state.title,
                    status: true,
                    buttom: `Введено ${titleLength} из ${MAX_STRING_LENGTH} симв.`,
                },
            });
        }

        if (descrLength > MAX_BUTTON_STRING_LENGTH) {
            isValid = false;
            dispatch({
                type: "descr",
                payload: {
                    text: state.descr,
                    status: true,
                    buttom: `Введено ${descrLength} из ${MAX_BUTTON_STRING_LENGTH} симв.`,
                },
            });
        }

        if (geoLength > MAX_BUTTON_STRING_LENGTH) {
            isValid = false;
            dispatch({
                type: "geo",
                payload: {
                    text: state.geo,
                    status: true,
                    buttom: `Введено ${geoLength} из ${MAX_BUTTON_STRING_LENGTH} симв.`,
                },
            });
        }

        if (timeLength > MAX_BUTTON_STRING_LENGTH) {
            isValid = false;
            dispatch({
                type: "time",
                payload: {
                    text: state.time,
                    status: true,
                    buttom: `Введено ${timeLength} из ${MAX_BUTTON_STRING_LENGTH} симв.`,
                },
            });
        }

        // Проверяем валидность ссылки только в том случае, если они заполнена
        if (state.url !== "" && false === WidgetValidate.button(state.url)) {
            isValid = false;
            dispatch({
                type: "title_url",
                payload: {
                    text: state.url,
                    status: true,
                    buttom: URL_PLACEHOLDER,
                },
            });
        }

        if (isValid) {
            props.onSelect({ type: "item", payload: [props.cardID, state] });
            props.close({ id: null, args: {} });
        } else {
            return false;
        }
    };

    const topTitle = (type) => {
        switch (type) {
            case "events":
                return {
                    geo: "Место",
                    time: "Дата/Время",
                };
            case "pointSale":
                return {
                    geo: "Адрес",
                    time: "Режим работы",
                };
            case "subscription":
                return {
                    geo: "Место",
                    time: "Дата/Время",
                };
            default:
                return {
                    geo: "Адрес",
                    time: "Режим работы",
                };
        }
    };

    useEffect(() => {
        setName(topTitle(props.type));

        if (props.state !== "undefined") {
            dispatch({ type: "update", payload: props.state });
        }
    }, []);

    const handleInputChange = (type, value, maxLength) => {
        let length = countStringLength(value);
        dispatch({
            type: type,
            payload: {
                text: value,
                buttom: `Введено ${length} из ${maxLength} симв.`,
                status: length > maxLength,
            },
        });
    };

    return (
        <FormLayout>
            <InputDynamic
                currentOpened={inputDynamic.getCurrent()}
                name="title"
                type="text"
                top="Заголовок"
                value={state.title || ""}
                status={state.status_title ? "error" : null}
                bottomText={state.bottom_title ? state.bottom_title : "_"}
                onChange={(value) => {
                    handleInputChange("title", value, MAX_STRING_LENGTH);
                }}
                placeholder={`До ${MAX_STRING_LENGTH} символов`}
            />

            <Input
                name="title_url"
                type="text"
                //@ts-ignore
                top="Ссылка для заголовка"
                maxLength={100}
                value={state.url || ""}
                status={state.status_title_url ? "error" : null}
                bottom={state.bottom_title_url ? state.bottom_title_url : "_"}
                onChange={(e) => {
                    dispatch({
                        type: "title_url",
                        payload: {
                            text: e.target.value.trim(),
                            buttom: `Введено ${e.target.value.length} из 100 симв.`,
                        },
                    });
                }}
                placeholder={URL_PLACEHOLDER}
            />

            <InputDynamic
                currentOpened={inputDynamic.getCurrent()}
                name="descr"
                type="text"
                top="Краткое описание"
                value={state.descr || ""}
                status={state.status_descr ? "error" : null}
                bottomText={state.bottom_descr ? state.bottom_descr : "_"}
                onChange={(value) => {
                    handleInputChange("descr", value, MAX_BUTTON_STRING_LENGTH);
                }}
                placeholder={`До ${MAX_BUTTON_STRING_LENGTH} символов`}
            />

            <InputDynamic
                currentOpened={inputDynamic.getCurrent()}
                name="geo"
                type="text"
                top={name.geo}
                bottomText={state.bottom_geo ? state.bottom_geo : "_"}
                value={state.geo || ""}
                status={state.status_geo ? "error" : null}
                onChange={(value) => {
                    handleInputChange("geo", value, MAX_BUTTON_STRING_LENGTH);
                }}
                placeholder={`До ${MAX_BUTTON_STRING_LENGTH} символов`}
            />

            <InputDynamic
                currentOpened={inputDynamic.getCurrent()}
                name="time"
                type="text"
                top={name.time}
                bottomText={state.bottom_time ? state.bottom_time : "_"}
                value={state.time || ""}
                status={state.status_time ? "error" : null}
                onChange={(value) => {
                    handleInputChange("time", value, MAX_BUTTON_STRING_LENGTH);
                }}
                placeholder={`До ${MAX_BUTTON_STRING_LENGTH} символов`}
            />

            <Button mode="secondary" size="l" stretched onClick={SaveModal}>
                Сохранить
            </Button>

            {/* Android modal fix */}
            <br />
            <br />
        </FormLayout>
    );
};

export default ItemGeo;
