class GlobalTimer {
    timer: ReturnType<typeof setTimeout> | null;
    timeout: number;
    timeRemaning: number;
    running: boolean;
    started: number;

    constructor() {
        this.timer = null; // Current timer ID
        this.timeout = 1000 * 10; // Default delay time
        this.timeRemaning = 0; // How much time is left
        this.running = false; // Flag - is timer running or not
        this.started = 0; // Time when timer was started
    }

    set() {
        this.running = true;
        this.started = new Date().getTime();

        if (this.timeRemaning === 0) {
            this.timeRemaning = this.timeout;
        }

        this.timer = setTimeout(() => {
            this.unset();
        }, this.timeRemaning);
    }

    reset() {
        this.unset();
        this.set();
    }

    /**
     *
     */
    pause() {
        this.running = false;
        clearTimeout(this.timer);
        this.timeRemaning -= new Date().getTime() - this.started;
    }

    /**
     * Full timer reset
     */
    unset() {
        if (this.timer) {
            clearTimeout(this.timer); // Clear current timer ID
            this.timer = null; // Delete the variable reference
            this.running = false;
            this.timeRemaning = 0;
            this.started = 0;
        }
    }

    check() {
        return this.timer;
    }

    getTimeLeft(): number {
        if (this.running) {
            this.pause();
            this.set();
        }

        return Math.ceil(this.timeRemaning / 1000);
    }
}

export default new GlobalTimer();
