import React from "react";

const Image = (props) => {
    const className =
        "Image" + (props.className ? " " + props.className.trim() : "");

    return (
        <div
            className={className}
            style={{ ...{ backgroundImage: "url(" + props.src + ")" }, ...{} }}
        />
    );
};

export default Image;
