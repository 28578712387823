import config from "./Config";

const isInteger = require("is-integer");

export default class UrlParams {
    params: any = {};
    gets: any = {};
    hash: any = {};
    raw_hash: any = {};

    get_url_params() {
        let a: any = window.location.search;
        let b: any = {};

        a = a.substring(1);
        if (!a) return b;

        a = a.split("&");
        for (let i = 0; i < a.length; i++) {
            let c = a[i].split("=");
            b[c[0]] = c[1];
        }

        b.vk_app_id = parseInt(b.vk_app_id);
        b.vk_are_notifications_enabled = parseInt(
            b.vk_are_notifications_enabled
        );
        b.vk_is_app_user = parseInt(b.vk_is_app_user);
        b.vk_user_id = parseInt(b.vk_user_id);

        if (b.vk_group_id) b.vk_group_id = parseInt(b.vk_group_id);

        return b;
    }

    get_hash_params() {
        let a: any = window.location.hash;
        let b = {};

        a = a.substring(1);
        if (!a) return b;

        a = a.split("&");
        for (let i = 0; i < a.length; i++) {
            let c = a[i].split("=");
            let key = c[0].replace("amp;", "");
            if (!c[1]) {
                b[key] = key; // hotfix бага с передачей в приложение неверной ссылки при запуске
            } else {
                b[key] = c[1];
            }
        }
        return b;
    }

    getUtms() {
        const result = {};

        if (!this.params) {
            return result;
        }

        Object.keys(this.params).forEach((key) => {
            if (key.indexOf("utm_") === 0) {
                result[key] = this.params[key];
            }
        });

        return result;
    }

    load() {
        try {
            this.gets = this.get_url_params();
            for (let name in this.gets) {
                if (this.gets.hasOwnProperty(name)) {
                    this.params[name] = this.gets[name];
                }
            }

            this.hash = this.get_hash_params();
            for (let name in this.hash) {
                if (this.hash.hasOwnProperty(name)) {
                    this.params[name] = this.hash[name];
                }
            }
            this.raw_hash = window.location.hash;
            // Если не передан источник подписки вручную
            if (!this.params.source) {
                // Если передана подписная страница и параметр s является целым числом
                if (this.params.s && isInteger(Number(this.params.s))) {
                    this.params.source = "subscription";
                } else {
                    // Если подписная страница не передана - каталог
                    this.params.source = "subscriptions";
                }
            }
        } catch (e) {
            this.logError(e);
            console.log(e);
        }
    }

    logError(error) {
        const data: any = {
            file: "UrlParams.js",
            error_type: "runtime_error",
        };

        if (error.message) {
            data.message = error.message;
        }

        if (error.code) {
            data.code = error.code;
        }

        const body = this._createRequestData({ data: JSON.stringify(data) });

        fetch(`${config.errorsUrl}/1`, {
            method: "POST",
            body: body,
        });
    }

    _createRequestData(data) {
        let requestData = new FormData();

        for (let key in data) {
            if (
                data[key] !== null &&
                typeof data[key] !== "undefined" &&
                data[key] !== ""
            ) {
                if (Array.isArray(data[key])) {
                    if (data[key].length === 0) {
                        requestData.append(`${key}[]`, "");
                    } else {
                        data[key].forEach((i) => {
                            requestData.append(`${key}[]`, i);
                        });
                    }
                } else {
                    requestData.append(key, data[key]);
                }
            }
        }

        try {
            requestData.append("params", JSON.stringify(this.params));
        } catch (e) {
            console.log(e);
        }

        return requestData;
    }

    replaceState() {
        window.history.replaceState(
            null,
            null,
            window.location.href.replace("#", "&")
        );
    }

    constructor() {
        this.gets = {};
        this.hash = {};
        this.raw_hash = "";
        this.params = {
            vk_group_id: 0,
            vk_user_id: 0,
            vk_app_id: 0,
            utm_source: "",
            utm_medium: "",
            utm_campaign: "",
            utm_term: "",
            utm_content: "",
            rs: "",
            roistat: "",
            source: "",
        };

        this.load();
    }
}
