import React, { useState } from "react";
import {
    Header,
    Group,
    List,
    Cell,
    Avatar,
    Link,
    Button,
    Headline,
} from "@vkontakte/vkui";
import ModalWidgetEditHeader from "../ModalWidgetEditHeader";
import ModalClient from "./ModalClient";
import reducer from "./reducer";
import initialState from "./state";
import useUpdate from "../../../includes/Hooks/useUpdate";
import WidgetHeader from "../WidgetHeader";
import { Icon20DeleteOutline, Icon24Add } from "@vkontakte/icons";

export const initalErrors = {
    title: false,
    count: false,
};

const ClientsEdit = (props) => {
    const { onEditMode } = props;

    const [editMode, setEditMode] = useState(false);
    const [state, dispatch]: any = useUpdate({
        ...props,
        reducer,
        initialState,
        type: "clients",
    });
    const errors = { ...initalErrors, ...props.errors.resp };

    const modals = {
        editHeader: {
            title: "Заголовок виджета",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.title, url: state.title_url }}
                    type="title"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        editLink: {
            title: "Ссылка",
            body: (state, callback, close) => (
                <ModalWidgetEditHeader
                    state={{ title: state.more, url: state.more_url }}
                    type="more"
                    onSelect={callback}
                    close={close}
                />
            ),
            callback: dispatch,
            state,
        },
        addClient: {
            title: "Добавить клиента",
            body: (state, callback, close) => (
                <ModalClient onSelect={callback} close={close} />
            ),
            callback: dispatch,
        },
    };

    return (
        <Group>
            <Header
                aside={
                    <Button
                        onClick={() => {
                            setEditMode(!editMode);
                            onEditMode && onEditMode(!editMode);
                        }}
                        mode="secondary"
                    >
                        {editMode ? "Готово" : "Править"}
                    </Button>
                }
            >
                <Headline level="2" weight="1">
                    Наши клиенты
                </Headline>
            </Header>
            <WidgetHeader
                onModalRequest={props.onModalRequest}
                state={state}
                modals={modals}
                edit={editMode}
                errors={errors}
            />
            <List>
                {state.body.map((item, i) => (
                    <Cell
                        key={i}
                        draggable={editMode}
                        mode={editMode ? 'removable': undefined}
                        removePlaceholder={<Icon20DeleteOutline />}
                        onRemove={() => {
                            dispatch({ type: "delete", payload: i });
                        }}
                        onDragFinish={({ from, to }) => {
                            const draggingList = [...state.body];
                            draggingList.splice(from, 1);
                            draggingList.splice(to, 0, state.body[from]);
                            dispatch({
                                type: "newList",
                                payload: draggingList,
                            });
                        }}
                        before={<Avatar src={item.img} />}
                    >
                        {item.title}
                    </Cell>
                ))}
            </List>
            {!editMode && state.body.length <= 9 ? (
                <List
                    onClick={() =>
                        props.onModalRequest({
                            id: "dynamic",
                            args: modals.addClient,
                        })
                    }
                >
                    <Cell
                        before={
                            <Avatar>
                                <Icon24Add />
                            </Avatar>
                        }
                    >
                        Добавить клиента
                    </Cell>
                </List>
            ) : null}
        </Group>
    );
};

export default ClientsEdit;
