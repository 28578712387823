import { clone } from "./Helpers/Helpers";
import PagesBlocks from "./PagesBlocks";

const config = {
    types: {
        cover: {
            type: "cover",
            name: "Обложка",
            children: [
                {
                    key: "c1",
                    name: "C1",
                    image: "/img/pages/blocks/cover_1.png",
                    desc: "Изображение на фоне, заголовок, текст, кнопка.",
                },
            ],
        },
        advantages: {
            type: "advantages",
            name: "Преимущества",
            children: [
                {
                    key: "a1",
                    name: "A1",
                    image: "/img/pages/blocks/adv_1.png",
                    desc: "Особенности, которые выделяют вас среди конкурентов",
                },
            ],
        },
        text: {
            type: "text",
            name: "Текстовый блок",
            children: [
                {
                    key: "t1",
                    name: "T1",
                    image: "/img/pages/blocks/text_1.png",
                    desc: "Блок с текстом",
                },
            ],
        },
        button: {
            type: "button",
            name: "Кнопка",
            children: [
                {
                    key: "b1",
                    name: "B1",
                    image: "/img/pages/blocks/button_1.png",
                    desc: "Кнопка призыва к действию",
                },
            ],
        },
        image: {
            type: "image",
            name: "Изображение",
            children: [
                {
                    key: "i1",
                    name: "I1",
                    image: "/img/pages/blocks/images_1.png",
                    desc: "Галерея изображений",
                },
                {
                    key: "i2",
                    name: "I2",
                    image: "/img/pages/blocks/images_2.jpg",
                    desc: "Изображение - подойдет для баннера",
                },
            ],
        },
        reviews: {
            type: "reviews",
            name: "Отзывы",
            children: [
                {
                    key: "r1",
                    name: "R1",
                    image: "/img/pages/blocks/reviews_1.png",
                    desc: "Блок с отзывами",
                },
            ],
        },
        products: {
            type: "products",
            name: "Товары",
            children: [
                {
                    key: "p1",
                    name: "P1",
                    image: "/img/pages/blocks/products_1.png",
                    desc: "Блок с товарами",
                },
            ],
        },
        video: {
            type: "video",
            name: "Видео",
            children: [
                {
                    key: "v1",
                    name: "V1",
                    image: "/img/pages/blocks/video_1.png",
                    desc: "Видео Youtube или VK",
                },
            ],
        },
        timer: {
            type: "timer",
            name: "Таймеры",
            children: [
                {
                    key: "tm1",
                    name: "TM1",
                    image: "/img/pages/blocks/timer_1.png",
                    desc: "Блок с таймером до заданной даты",
                },
                {
                    key: "tm2",
                    name: "TM2",
                    image: "/img/pages/blocks/timer_2.png",
                    desc: "Блок с таймером с фиксированным количеством времени",
                },
                {
                    key: "tm3",
                    name: "TM3",
                    image: "/img/pages/blocks/timer_3.png",
                    desc: "Блок с таймером обратного отсчета, который запускается после первого захода пользователя на лендинг",
                },
            ],
        },
        other: {
            type: "other",
            name: "Другое",
            children: [
                {
                    key: "ot1",
                    name: "OT1",
                    image: "/img/pages/blocks/other_1.png",
                    desc: "Разделитель",
                },
            ],
        },
    },

    items: [
        {
            name: "Пустая страница",
            desc: "Выберите, если хотите начать с чистой страницы и добавить все блоки самостоятельно",
            image: "https://i.ibb.co/WgH2H67/empty-emplate-placeholder.png",
            type: "empty",
            blocks: [],
            blocks_edit: [],
        },
        {
            name: "Универсальный шаблон",
            desc: "Универсальная страница для бизнеса. Простая структура, подходит для большинства случаев.",
            image: "https://i.ibb.co/nmWDZ1n/photo-2020-10-19-15-18-22.jpg",
            type: "multipurpose",
            blocks_edit: [
                clone(PagesBlocks.cover.c1), // Обложка
                clone(PagesBlocks.advantages.a1), // Преимущества
                clone(PagesBlocks.products.p1), // Товары\Услуги
                clone(PagesBlocks.cover.c1), // Обложка
                clone(PagesBlocks.reviews.r1), // Отзывы
                clone(PagesBlocks.image.i1), // Галерея изображений
                clone(PagesBlocks.text.t1), // Текстовый блок
            ],
        },
        {
            name: "Продажа курсов",
            desc: "Отлично подходит для продажи любых курсов.",
            image: "https://i.ibb.co/wCv6rX4/anonymous-marking-3.jpg",
            type: "online-education",
            blocks_edit: [
                {
                    key: "c1",
                    page_id: "",
                    vk_group_id: 0,
                    type: "cover",
                    sub_type: "cover_base",
                    content: {
                        title: "Учите английский онлайн",
                        text: "Где угодно и когда удобно занимайтесь из дома, на работе или в путешествии - с компьютера или смартфона",
                    },
                    background: {
                        url: "https://i.ibb.co/wCv6rX4/anonymous-marking-3.jpg",
                    },
                    button: {
                        id: "",
                        text: "Оставить заявку",
                        action: "lead", // lead | subsribe | url
                        color: "var(--vkui--color_accent_blue)",
                        text_color: '#fff'
                    },
                    meta: {
                        padding: 100,
                        alignment: "center",
                    },
                },
                {
                    key: "p1",
                    page_id: "",
                    vk_group_id: 0,
                    type: "products",
                    sub_type: "products_base",
                    content: {
                        title: "Наши курсы",
                        text: "",
                    },
                    items: [
                        {
                            uuid: "",
                            category: "",
                            name: "Для взрослых",
                            text: "Взрослым английский для путешествий, карьеры и развлечений",
                            price: "от 1000 руб.",
                            img: {
                                url: "https://i.ibb.co/5Fjsvw7/male-student-1f468-200d-1f393.png",
                            },
                            button: {
                                id: "",
                                text: "Подробнее",
                                action: "url",
                                url: "https://senler.ru",
                            },
                        },
                        {
                            uuid: "",
                            category: "",
                            name: "Для детей",
                            text: "Детям английский и математика для развития навыков будущего",
                            price: "от 1000 руб.",
                            img: {
                                url: "https://i.ibb.co/4T8S3CX/baby-1f476.png",
                            },
                            button: {
                                id: "",
                                text: "Подробнее",
                                action: "url",
                                url: "https://senler.ru",
                            },
                        },
                        {
                            uuid: "",
                            category: "",
                            name: "Для организаций",
                            text: "Компаниям корпоративный английский для сотрудников",
                            price: "от 1000 руб.",
                            img: {
                                url: "https://i.ibb.co/TLpdv6F/office-building-1f3e2.png",
                            },
                            button: {
                                id: "",
                                text: "Подробнее",
                                action: "url",
                                url: "https://senler.ru",
                            },
                        },
                        {
                            uuid: "",
                            category: "",
                            name: "Для учителей",
                            text: "Повышение квалификации и сертификация",
                            price: "от 1000 руб.",
                            img: {
                                url: "https://i.ibb.co/47JFpRB/female-teacher-1f469-200d-1f3eb.png",
                            },
                            button: {
                                id: "",
                                text: "Подробнее",
                                action: "url",
                                url: "https://senler.ru",
                            },
                        },
                    ],
                    meta: {
                        padding: 25,
                        alignment: "center",
                    },
                },
                {
                    key: "c1",
                    page_id: "",
                    vk_group_id: 0,
                    type: "cover",
                    sub_type: "cover_base",
                    content: {
                        title: "Пройдите вводный урок 📚",
                        text: "Познакомимся, определим уровень и покажем, как проходит обучение на бесплатном занятии",
                    },
                    background: {
                        url: "https://i.ibb.co/CWN5j6h/blue.jpg",
                    },
                    button: {
                        id: "",
                        text: "Оставить заявку",
                        action: "lead", // lead | subsribe | url
                        color: "var(--vkui--color_accent_blue)",
                        text_color: '#fff'
                    },
                    meta: {
                        padding: 100,
                        alignment: "center",
                    },
                },
                {
                    key: "t1",
                    page_id: "",
                    vk_group_id: 0,
                    type: "text",
                    sub_type: "text_base",
                    content: {
                        title: "Немного о нас",
                        text: "🛋️  Где угодно и когда удобно.\n Занимайтесь из дома, на работе или в путешествии — с компьютера или смартфона. \n\n 🏆  Наглядный прогресс.\n Следим за вашими результатами, корректируем обучение и после каждого урока присылаем отчет. \n\n 🏋️‍♂️  Мотивация продолжать.\n Подбираем преподавателя под ваши цели, учим на интерактивных заданиях по вашим интересам.",
                    },
                    meta: {
                        padding: 30,
                        alignment: "left",
                    },
                    button: null,
                },
                {
                    key: "v1",
                    page_id: "",
                    vk_group_id: 0,
                    type: "video",
                    sub_type: "video_base",
                    content: {
                        title: "Как проходят наши занятия?",
                        text: "Посмотрите фрагмент урока",
                    },
                    video: {
                        type: "youtube",
                        url: "https://www.youtube.com/watch?v=lgiccRbLvaU",
                        autoplay: 0,
                        disable_audio: 0,
                        repeat: 0,
                    },
                    meta: {
                        padding: 0,
                        alignment: "center",
                    },
                },
                {
                    key: "p1",
                    page_id: "",
                    vk_group_id: 0,
                    type: "products",
                    sub_type: "products_base",
                    content: {
                        title: "Полезные рассылки",
                        text: "",
                    },
                    items: [
                        {
                            uuid: "",
                            category: "",
                            name: "Новости",
                            text: "Новинки сервиса, семинары, свежие курсы - вы узнаете о них первыми",
                            price: "",
                            img: {
                                url: "https://i.ibb.co/K54kTyn/postbox-1f4ee.png",
                            },
                            button: {
                                id: "",
                                text: "Подписаться",
                                action: "url",
                                url: "https://senler.ru",
                            },
                        },
                        {
                            uuid: "",
                            category: "",
                            name: "Акции и скидки",
                            text: "Распродажи, предпраздничные акции. Подпишитесь, чтобы приобретать курсы по самым выгодным ценам",
                            price: "",
                            img: {
                                url: "https://i.ibb.co/QpWVXsF/wrapped-present-1f381.png",
                            },
                            button: {
                                id: "",
                                text: "Подписаться",
                                action: "url",
                                url: "https://senler.ru",
                            },
                        },
                    ],
                    meta: {
                        padding: 25,
                        alignment: "center",
                    },
                },
            ],
        },
    ],
};

export default config;
