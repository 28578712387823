import React, { useContext, useReducer, useRef } from "react";
import TimerControl from "../TimerControl";
import {
    FixedLayout,
    FormLayout,
    Div,
    Separator,
    Checkbox,
} from "@vkontakte/vkui";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { registerLocale } from "react-datepicker";
import FormSubmitButton from "../../FormSubmitButton";
import { validator as buttonValidator } from "../../ButtonCommon/validator";
import { Context } from "../../../../../../context";
import { Icon20ArticleBoxOutline, Icon24Dismiss } from "@vkontakte/icons";
import { Icon24LinkCircle } from "@vkontakte/icons";
import { Icon24PictureOutline } from "@vkontakte/icons";
import initialState from "./state";
import reducer from "./reducer";
import FadeIn from "react-fade-in";
import "react-datepicker/dist/react-datepicker.css";
import CustomDateInput from "../CustomDateInput";
import { DEFAULT_OVERLAY_OPACITY_VALUE } from "../../../../../../includes/Constants";
import ReactSlider from "react-slider";

registerLocale("ru", ru);

type TimerEditProps = {
    data: any;
    onSubmit: Function;
    openModal: Function;
    currentDynamicInput: string;
};

const SLIDER_STEP = 1;
const SLIDER_MIN_VALUE = 0;
const SLIDER_MAX_VALUE = 100;

const TimerDateEdit = (props: TimerEditProps) => {
    const { data, onSubmit, openModal, currentDynamicInput } = props;

    const { snackbar, urlParams } = useContext(Context);

    let datepickerRef = useRef(null);

    const platform = urlParams.get().params.vk_platform;

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        ...data,
        content: {
            ...data.content,
            datetime_end: new Date(data.content.datetime_end),
        },
    });

    const { datetime_end } = state.content;
    const { has_button, has_title, has_background } = state;

    const filterPassedTime = (time: Date): boolean => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const handleSubmit = () => {
        if (!datetime_end) {
            snackbar.showError("Дата заполнена неверно");
            return;
        }

        if (has_button) {
            if (!state.button.text) {
                snackbar.showError("Кнопка заполнена неверно");
                return;
            }

            const buttonValidationResult = buttonValidator(state.button);

            if (buttonValidationResult.status === false) {
                dispatch({
                    type: "set-button-errors",
                    payload: buttonValidationResult.errors,
                });
                return false;
            } else {
                dispatch({ type: "flush-button-errors" });
            }
        }

        if (has_background) {
            if (!state.background.url) {
                snackbar.showError("Изображение не выбрано");
                return;
            }
        }

        const data = { ...state };

        delete data.errors;

        onSubmit(data);
    };

    const overlayOpacity =
        state.background.overlay_opacity ||
        state.background.overlay_opacity === 0
            ? state.background.overlay_opacity
            : DEFAULT_OVERLAY_OPACITY_VALUE;

    const isFullscreen = state.meta.is_fullscreen
        ? state.meta.is_fullscreen
        : false;

    return (
        <div className="TimerEdit">
            <FormLayout style={{ paddingBottom: 65 }}>
                <TimerControl
                    checked={has_title}
                    title="Заголовок и описание"
                    icon={
                        <Icon20ArticleBoxOutline
                            width={24}
                            height={24}
                            fill="var(--vkui--color_icon_accent_themed)"
                            style={{
                                marginRight: 19,
                                opacity: has_title ? 1 : 0.4,
                            }}
                        />
                    }
                    onChange={(e) => {
                        dispatch({
                            type: "set-has-title",
                            payload: !has_title,
                        });
                    }}
                    onEditClick={() => {
                        openModal({
                            id: "text",
                            args: {
                                content: state.content,
                                currentDynamicInput: currentDynamicInput,
                                callback: (data) => {
                                    dispatch({
                                        type: "edit-text",
                                        payload: data,
                                    });
                                },
                            },
                        });
                    }}
                />

                <TimerControl
                    checked={has_button}
                    title="Кнопка"
                    icon={
                        <Icon24LinkCircle
                            fill="var(--vkui--color_icon_accent_themed)"
                            style={{
                                marginRight: 19,
                                opacity: has_button ? 1 : 0.4,
                            }}
                        />
                    }
                    onChange={(e) => {
                        dispatch({
                            type: "set-has-button",
                            payload: !has_button,
                        });
                    }}
                    onEditClick={() => {
                        openModal({
                            id: "button",
                            args: {
                                currentDynamicInput: currentDynamicInput,
                                has_button: state.has_button,
                                button: state.button,
                                errors: state.errors,
                                callback: (data) => {
                                    dispatch({
                                        type: "set-button-data",
                                        payload: data,
                                    });
                                },
                                setButtonErrorsCallback: (data) => {
                                    dispatch({
                                        type: "set-button-errors",
                                        payload: data,
                                    });
                                },
                                flushButtonErrorsCallback: () => {
                                    dispatch({
                                        type: "flush-button-data",
                                    });
                                },
                            },
                        });
                    }}
                />

                <Separator />

                <Div>
                    <div className="TimerEdit-settings-title">
                        Настройки таймера
                    </div>
                    <div className={`TimerEdit-datepicker ${platform}`}>
                        <div className="TimerEdit-datepicker__title">
                            Время окончания
                        </div>
                        <DatePicker
                            ref={datepickerRef}
                            locale={ru}
                            selected={datetime_end}
                            dateFormat={"dd.MM.yyyy, HH:mm"}
                            minDate={new Date()}
                            withPortal={
                                platform === "desktop_web" ? true : false
                            }
                            timeFormat="HH:mm"
                            timeCaption="Время"
                            filterTime={filterPassedTime}
                            showDisabledMonthNavigation={true}
                            customInput={
                                <CustomDateInput
                                    value={datetime_end}
                                    onClick={() => {
                                        datepickerRef.current.setOpen(true);
                                    }}
                                />
                            }
                            onChange={(date: any) => {
                                dispatch({
                                    type: "datetime_end",
                                    payload: date,
                                });
                            }}
                            showTimeSelect={true}
                            fixedHeight={true}
                        >
                            <Icon24Dismiss
                                width={22}
                                height={22}
                                onClick={() => {
                                    datepickerRef.current.setOpen(false);
                                }}
                                className="TimerEdit-datepicker__cancel-icon"
                            />
                        </DatePicker>
                    </div>
                </Div>

                <TimerControl
                    checked={has_background}
                    title="Фоновое изображение"
                    icon={
                        <Icon24PictureOutline
                            fill="var(--vkui--color_icon_accent_themed)"
                            style={{
                                marginRight: 19,
                                opacity: has_background ? 1 : 0.4,
                            }}
                        />
                    }
                    onChange={(e) => {
                        dispatch({
                            type: "set-has-background",
                            payload: !has_background,
                        });
                    }}
                    onEditClick={() => {
                        openModal({
                            id: "landing-img",
                            args: {
                                size: [1920, 1220],
                                callback: (data) => {
                                    dispatch({
                                        type: "background",
                                        payload: data,
                                    });
                                },
                            },
                        });
                    }}
                />

                {has_background && (
                    <FadeIn>
                        <Div style={{ paddingBottom: 0 }}>
                            <div className="subhead">Изображение</div>
                            <div className="GroupBannersForm__banner GroupBannersForm__banner_filepicker B-TimerEdit">
                                {!!state.background &&
                                !!state.background.url ? (
                                    <>
                                        <img
                                            alt=""
                                            src={state.background.url}
                                        />
                                        <div
                                            style={{
                                                background: `rgba(0, 0, 0, ${overlayOpacity}%)`,
                                            }}
                                            className="GroupBannersForm__overlay"
                                        ></div>
                                    </>
                                ) : (
                                    <span>Изображение не найдено</span>
                                )}
                            </div>
                            <div className="subhead">
                                Рекомендуемый размер изображения 1920x1220
                            </div>
                        </Div>

                        <Div style={{ paddingTop: 0, paddingBottom: 4 }}>
                            <div style={{ marginTop: 20 }}>
                                <div className="subhead">
                                    {overlayOpacity}% заливка
                                </div>
                                <ReactSlider
                                    step={SLIDER_STEP}
                                    min={SLIDER_MIN_VALUE}
                                    max={SLIDER_MAX_VALUE}
                                    value={overlayOpacity}
                                    className="ReactSlider"
                                    trackClassName="ReactSlider__sliderTrack_line"
                                    minDistance={10}
                                    onChange={(value: number) => {
                                        dispatch({
                                            type: "set-overlay-opacity",
                                            payload: value,
                                        });
                                    }}
                                    renderThumb={(props) => (
                                        <div
                                            {...props}
                                            className="ReactSlider__sliderThumb"
                                        ></div>
                                    )}
                                />
                            </div>
                        </Div>
                    </FadeIn>
                )}

                <Checkbox
                    className="CoverEdit__checkbox"
                    checked={isFullscreen}
                    onChange={(e) => {
                        dispatch({
                            type: "set-is-fullscreen",
                            payload: e.currentTarget.checked,
                        });
                    }}
                >
                    На всю высоту экрана{" "}
                    <span className="subhead">(для мобильных устройств)</span>
                </Checkbox>

                <FixedLayout filled={true} vertical="bottom">
                    <FormSubmitButton handleSubmit={handleSubmit} />
                </FixedLayout>
            </FormLayout>
        </div>
    );
};

export default TimerDateEdit;
