import React, { useState } from "react";
import { List, Cell } from "@vkontakte/vkui";
import { Icon24Done } from "@vkontakte/icons";

const PlatformSelect = (props) => {
    const { platforms, selected, onSelect, usagePlace } = props;

    const [selectedPlatforms, setSelectedPlatforms] = useState(
        selected && selected.length > 0 ? selected : []
    );

    const addPlatform = (platform) => {
        const newSelectedPlatforms = [...selectedPlatforms];
        newSelectedPlatforms.push(platform.id);
        setSelectedPlatforms(newSelectedPlatforms);
        onSelect && onSelect(newSelectedPlatforms);
    };

    const removePlatform = (platform) => {
        const newSelectedPlatforms = [...selectedPlatforms].filter((p) => {
            return p !== platform.id;
        });

        setSelectedPlatforms(newSelectedPlatforms);
        onSelect && onSelect(newSelectedPlatforms);
    };

    const discardPlatforms = () => {
        setSelectedPlatforms([]);
        onSelect && onSelect([]);
    };

    const filteredPlatforms =
        usagePlace === "widget-audience"
            ? platforms.filter((p) => p.id !== 3)
            : platforms;

    return (
        <>
            <List>
                <Cell
                    key="platform-any"
                    onClick={discardPlatforms}
                    after={
                        selectedPlatforms &&
                        selectedPlatforms.length === 0 && (
                            <Icon24Done fill="var(--vkui--color_text_accent)" />
                        )
                    }
                >
                    Любое
                </Cell>
                {filteredPlatforms.map((platform) => {
                    let isSelected =
                        selectedPlatforms.indexOf(platform.id) >= 0;

                    return (
                        <Cell
                            key={platform.id}
                            onClick={() => {
                                if (isSelected) {
                                    removePlatform(platform);
                                } else {
                                    addPlatform(platform);
                                }
                            }}
                            after={
                                isSelected && (
                                    <Icon24Done fill="var(--vkui--color_text_accent)" />
                                )
                            }
                        >
                            {platform.title}
                        </Cell>
                    );
                })}
            </List>
        </>
    );
};

export default PlatformSelect;
