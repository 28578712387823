import React, { useContext } from "react";
import { PanelHeader, PanelHeaderBack } from "@vkontakte/vkui";
import PagesEditItem from "../Сomponents/Pages/PagesEditItem";
import container from "../container";
import { Context } from "../context";
import { setHash } from "../includes/Helpers/Helpers";
import { v4 as uuidv4 } from "uuid";

let sortRequestTimer = null;

const PagesEdititemController = (props) => {
    const { popout, pages, location, snackbar } = useContext(Context);

    const PagesService = container.get("PagesService");
    const Logger = container.get("logger");

    const { isNewItem = false } = props;
    const page_id = location.getPageId();
    const page = pages.getPage(page_id);

    const deleteBlock = async (block_id: string) => {
        popout.loading();

        const response = await PagesService.deleteBlock(block_id, page_id);

        if (response.result === "success") {
            popout.close();
            pages.deleteBlock(block_id, page_id);
            pages.setAvailablePageStates(response.states);
        } else {
            popout.close();
            popout.error(response.message);
        }
    };

    const handleSortBlocks = (block, currentIndex, nextIndex) => {
        pages.sortBlocks(
            page_id,
            block.id,
            currentIndex,
            nextIndex,
            (newBlocksOrder) => {
                if (sortRequestTimer) clearTimeout(sortRequestTimer);
                sortRequestTimer = setTimeout(() => {
                    applyNewBlocksOrder(newBlocksOrder);
                }, 400);
            }
        );
    };

    const applyNewBlocksOrder = async (newBlocksOrder) => {
        const response = await PagesService.sortBlocks(page_id, newBlocksOrder);

        if (response.result === "success") {
            pages.setAvailablePageStates(response.states);
        } else {
            snackbar.showError(response.message);
            Logger.error(
                {
                    message: `Sort blocks error > page_id:${page_id} newBlocksOrder:${JSON.stringify(
                        newBlocksOrder
                    )} response:${JSON.stringify(response)}`,
                    code: 3001,
                },
                "pages",
                "PagesEditItemController.tsx"
            );
        }
    };

    const savePageState = async () => {
        const stateManager = pages.getStateManagement();

        popout.loading();

        const response = await PagesService.savePageState(
            page_id,
            stateManager.active
        );

        if (response.result === "success") {
            popout.close();
            pages.updatePageBlocks(page_id, response.data, () => {
                pages.setAvailablePageStates(response.states);
            });
        } else {
            popout.close();
            popout.error(response.message);
        }
    };

    const handleCopyBlock = async (block) => {
        popout.loading();

        let blockInsertAfter = block.id;

        const newBlock = Object.assign({}, block);

        delete newBlock.created;
        delete newBlock.updated;
        delete newBlock.id;
        delete newBlock.page_id;

        if (newBlock.button) {
            newBlock.button = Object.assign({}, block.button);
            newBlock.button.id = "";
        }

        if (block.hasOwnProperty("items") && Array.isArray(block.items)) {
            newBlock.items = block.items.map((item) => {
                return { ...item };
            });
            newBlock.items.forEach((item) => {
                item.uuid = uuidv4();

                if (newBlock.type === "products" && item.button) {
                    item.button.id = "";
                }
            });
        }

        /**
         * При копировании блока - blockInsertAfter - это сам копируемый блок. Нам нужно вставить копию после него
         */
        const response = await PagesService.insertBlock(
            page_id,
            newBlock,
            blockInsertAfter
        );

        popout.close();

        if (response.result === "success") {
            const payload = {
                blocks: response.data.blocks,
                newBlock: response.data.new_block,
                recentBlocks: response.recent_blocks,
                states: response.states,
            };

            pages.insertBlock(page_id, payload, () => {});
        } else {
            popout.error(response.message);
        }
    };

    const handleAddBlockClick = () => {
        pages.setAddBlockMode("add", () => {
            setHash({
                list: "pages",
                view: `pages${isNewItem ? "" : "-edit"}`,
                panel: "blocks-types",
            });
        });
    };

    const handleInsertBlockClick = () => {
        pages.setAddBlockMode("insert", () => {
            setHash({
                list: "pages",
                view: `pages${isNewItem ? "" : "-edit"}`,
                panel: "blocks-types",
            });
        });
    };

    const handlePreviewClick = () => {
        setHash({
            list: "pages",
            view: `pages${isNewItem ? "" : "-edit"}`,
            panel: "preview",
        });
    };

    const handleEditBlockClick = (block) => {
        setHash({
            list: "pages",
            view: "pages-edit",
            panel: "blocks-edit",
            page_id: page.id,
            block_id: block.id,
        });
    };

    const handleDisableblock = async (
        block_data,
        page_id,
        block_id,
        status
    ) => {
        popout.loading();

        let response = await PagesService.changeBlockStatus(
            page_id,
            block_id,
            status
        );

        if (response.result === "success") {
            popout.close();

            const currectBlockData = {
                ...block_data,
                status,
            };

            pages.updateBlock(block_id, page_id, currectBlockData);

            if (status === 1) {
                snackbar.showSuccess("Блок включен");
            } else {
                snackbar.showSuccess("Блок скрыт");
            }
        } else {
            popout.error(response.message);
        }
    };

    const insertCopiedBlockAudience = async (blockId, pageId, blockData) => {
        try {
            popout.loading();

            const response = await PagesService.updateAudience(
                pageId,
                blockId,
                blockData.audience
            );

            if (response.result === "success") {
                popout.close();

                pages.updateBlock(blockId, pageId, blockData);

                snackbar.showSuccess("Аудитория вставлена");
            } else {
                popout.error(response.message);
            }
        } catch (error) {
            popout.close();
            console.log(error);
        }
    };

    return (
        <>
            <PanelHeader
                before={
                    <PanelHeaderBack
                        onClick={() => {
                            setHash({ list: "pages" });
                        }}
                    />
                }
                fixed
            >
                {page && page.name ? page.name : ""}
            </PanelHeader>
            <PagesEditItem
                page={page}
                stateManage={pages}
                onAddBlockClick={handleAddBlockClick}
                onEditBlockClick={handleEditBlockClick}
                onPreviewClick={handlePreviewClick}
                handleSort={handleSortBlocks}
                deleteBlock={deleteBlock}
                onInsertBlockClick={handleInsertBlockClick}
                onSaveStateClick={savePageState}
                copyBlock={handleCopyBlock}
                disableBlock={handleDisableblock}
                insertBlockAudience={insertCopiedBlockAudience}
            />
        </>
    );
};

export default PagesEdititemController;
