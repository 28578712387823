import React from "react";
import { Avatar } from "@vkontakte/vkui";
import { Icon28Camera, Icon28UserOutline } from "@vkontakte/icons";

interface ListAvatarProps {
    img: string;
    hasError: boolean;
}

const ListAvatar = (props: ListAvatarProps) => {
    const { img, hasError } = props;

    if (!img) {
        return (
            <Avatar
                className="Widget--List__element"
                style={
                    hasError ? { border: "1px solid var(--vkui--color_accent_red)" } : null
                }
            >
                <Icon28Camera />
            </Avatar>
        );
    }

    switch (img) {
        case "person":
            return (
                <Avatar
                    className="Widget--List__element"
                    style={
                        hasError
                            ? { border: "1px solid var(--vkui--color_accent_red)" }
                            : null
                    }
                >
                    <Icon28UserOutline />
                </Avatar>
            );
        default:
            return (
                <Avatar
                    className="Widget--List__element"
                    style={
                        hasError
                            ? { border: "1px solid var(--vkui--color_accent_red)" }
                            : null
                    }
                    src={img}
                />
            );
    }
};

export default ListAvatar;
