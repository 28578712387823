import "core-js/es/map";
import "core-js/es/set";
import "core-js/es/object/entries";
import "polyfill-array-includes";
import "whatwg-fetch";
import "./index.less";
import container from "./container";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import FatalError from "./Routes/FatalError";
import { AdaptivityProvider, AppRoot, ConfigProvider } from "@vkontakte/vkui";
import * as Sentry from "@sentry/react";
import config from "./includes/Config";
import "@vkontakte/vkui/dist/vkui.css";

container.init();

const Logger = container.get("logger");

console.log(`Senler VK App. Version: ${config.senlerVKMiniAppCurrentVersion}`);

Sentry.init({
    dsn: 'https://276bd364cd05b1939aec897d4e97fa30@sentry.streamvi.ru/6',
    environment: process.env.NODE_ENV,
    release: `senler-vk-app@${config.senlerVKMiniAppCurrentVersion}`,
    integrations: [new Sentry.Replay()],
    ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
    ],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend: function (event, hint) {
        const error: any = hint.originalException;

        // Исключим отправку ошибки domain_mismatch в development окружении
        if (
            process.env.NODE_ENV === "development" &&
            error &&
            error.error_data &&
            error.error_data.error_reason &&
            error.error_data.error_reason[0] &&
            error.error_data.error_reason[0].error_code &&
            error.error_data.error_reason[0].error_code === "domain_mismatch"
        ) {
            return null;
        }

        // Исключим отправку ошибки Unsupported platform
        if (
            error &&
            error.error_data &&
            error.error_data.error_code &&
            error.error_data.error_code == 6
        ) {
            return null;
        }

        return event;
    },
});

const launchParams = container.get("LaunchParams");

if (launchParams.gets) {
    for (let paramName in launchParams.gets) {
        Sentry.setTag(paramName, launchParams.gets[paramName]);
    }
}

try {
    ReactDOM.render(
        <ConfigProvider>
            <AdaptivityProvider>
                <AppRoot>
                    <BrowserRouter>
                        <ErrorBoundary>
                            <App />
                        </ErrorBoundary>
                    </BrowserRouter>
                </AppRoot>
            </AdaptivityProvider>
        </ConfigProvider>,
        document.getElementById("root")
    );
} catch (e) {
    Logger.error(
        { code: 9001, message: e.message },
        "init_app_error",
        "Index.js"
    );

    Sentry.captureException(e);

    console.log(e.toString());

    ReactDOM.render(<FatalError />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
