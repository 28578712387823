import LoggerInterface from "../Interfaces/LoggerInterface";

export default class Subscribes {
    host: string;
    logger: LoggerInterface;

    constructor(host: string, logger: LoggerInterface) {
        this.host = host;
        this.logger = logger;
    }
    createOrder(data, callback = (result: any) => {}) {
        fetch(this.host + "/ajax/vkapp/CreateOrder", {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((result) => result.json())
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                callback({
                    success: false,
                    message: error.message,
                });
            });
    }
    checkOrder(data, callback = (result: any) => {}) {
        fetch(this.host + "/ajax/vkapp/CheckOrder", {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((result) => result.json())
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                callback({
                    success: false,
                    message: error.message,
                });
            });
    }
    subscribe(data, callback = (result: any) => {}) {
        fetch(this.host + "/ajax/vkapp/Subscribe", {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((result) => result.json())
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                callback({
                    success: false,
                    message: error.message,
                });
            });
    }
    unSubscribe(data, callback = (result: any) => {}) {
        fetch(this.host + "/ajax/vkapp/UnSubscribe", {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((result) => result.json())
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                callback({
                    success: false,
                    message: error.message,
                });
            });
    }
    unsubscribeAll(data, callback = (result: any) => {}) {
        fetch(this.host + "/ajax/vkapp/UnSubscribeAll", {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((result) => result.json())
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                callback({
                    success: false,
                    message: error.message,
                });
            });
    }
}
