export const CHANGE_METRIC_VALUE_ACTION = "change-metric-value";

export default (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case CHANGE_METRIC_VALUE_ACTION:
            if (false === state.hasOwnProperty(payload.name)) {
                throw new Error(`Unsupported field name - ${payload.name}`);
            }
            const newState = { ...state };
            newState[payload.name] = payload.value;
            return newState;
        default:
            return state;
    }
};
