export default function tilesReducer(state, action) {
    let newTiles = [];

    switch (action.type) {
        case "title":
            return {
                ...state,
                title: action.payload[0],
                title_url: action.payload[1],
            };
        case "more":
            return {
                ...state,
                more: action.payload[0],
                more_url: action.payload[1],
            };
        case "button":
            newTiles = state.tiles.map((item) => ({ ...item }));

            newTiles[action.payload[0]].button = action.payload[1][0];
            newTiles[action.payload[0]].button_url = action.payload[1][1];

            return { ...state, tiles: newTiles };

        case "img":
            newTiles = state.tiles.map((item) => ({ ...item }));

            newTiles[action.payload.cardID].img = action.payload.img;
            newTiles[action.payload.cardID].icon_id = action.payload.img_vk;

            return { ...state, tiles: newTiles };

        case "item":
            const newTilesItems = state.tiles.map((item) => ({ ...item }));

            newTilesItems[action.payload[0]].title = action.payload[1].title;
            newTilesItems[action.payload[0]].descr = action.payload[1].descr;
            newTilesItems[action.payload[0]].url = action.payload[1].url;

            return { ...state, tiles: newTilesItems };

        case "createCard":
            return {
                ...state,
                tiles: [
                    ...state.tiles,
                    {
                        img: "",
                        icon_id: "",
                        title: "",
                        descr: "",
                        url: "",
                        button: "",
                        button_url: "",
                    },
                ],
            };
        case "deleteCard":
            newTiles = state.tiles.map((item) => ({ ...item }));
            newTiles.splice(action.payload, 1);
            return { ...state, tiles: newTiles };
        case "itemLeft":
            newTiles = state.tiles.map((item) => ({ ...item }));
            newTiles.splice(
                action.payload - 1,
                0,
                newTiles.splice(action.payload, 1)[0]
            );
            return { ...state, tiles: newTiles };
        case "itemRight":
            newTiles = state.tiles.map((item) => ({ ...item }));
            newTiles.splice(
                action.payload + 1,
                0,
                newTiles.splice(action.payload, 1)[0]
            );
            return { ...state, tiles: newTiles };
        case "update":
            return {
                ...state,
                title: action.payload.title,
                title_url: action.payload.title_url,
                more: action.payload.more,
                more_url: action.payload.more_url,
                tiles: action.payload.tiles.map((item) => ({ ...item })),
            };
        case "sort":
            const { oldIndex, newIndex } = action.payload;

            newTiles = state.tiles.map((item) => ({ ...item }));
            newTiles.splice(oldIndex, 1);
            newTiles.splice(newIndex, 0, state.tiles[oldIndex]);

            return { ...state, tiles: newTiles };

        // Быстрый выбор из группы подписчиков
        case "sub":
            newTiles = state.tiles.map((item) => ({ ...item }));

            // Обновиим только кнопки, чтобы при сохранеии формы она прошла валидацию

            // newTiles[action.payload.item_id].title = action.payload.data.title;
            // newTiles[action.payload.item_id].desc = action.payload.data.desc;
            // newTiles[action.payload.item_id].url = action.payload.data.url;
            newTiles[action.payload.item_id].button =
                action.payload.data.button;
            newTiles[action.payload.item_id].button_url =
                action.payload.data.button_url;

            return { ...state, tiles: newTiles };

        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
}
