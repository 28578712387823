import { clone } from "../../../../../../includes/Helpers/Helpers";

const moduleName = "single_imag_edit";
export const SET_DESKTOP_IMAGE = `${moduleName}/SET_DESKTOP_IMAGE`;
export const SET_MOBILE_IMAGE = `${moduleName}/SET_MOBILE_IMAGE`;

export default function reducer(state, action) {
    const { type, payload } = action;

    const newState = clone(state);

    switch (type) {
        case SET_DESKTOP_IMAGE:
            newState.content.desktop_img = payload.url;
            newState.content.address_url = payload.address_url;
            return newState;

        case SET_MOBILE_IMAGE:
            newState.content.mobile_img = payload.url;
            newState.content.address_url = payload.address_url;
            return newState;

        default:
            return newState;
    }
}
