import React from "react";

type AddCircleIconProps = {
    fill?: string;
    width?: number;
    height?: number;
    style?: object;
};

const AddCircleIcon = (props: AddCircleIconProps) => {
    const { fill = "#4BB34B", width = 28, height = 28, style = {} } = props;

    return (
        <svg
            style={style}
            width={width}
            height={height}
            viewBox="0 0 32 32"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.9997 1.33301C24.0998 1.33301 30.6663 7.8995 30.6663 15.9997C30.6663 24.0998 24.0998 30.6663 15.9997 30.6663C7.8995 30.6663 1.33301 24.0998 1.33301 15.9997C1.33301 7.8995 7.8995 1.33301 15.9997 1.33301ZM15.9997 7.99967C15.2633 7.99967 14.6663 8.59663 14.6663 9.33301V14.6663H9.33301C8.64923 14.6663 8.08566 15.1811 8.00864 15.8442L7.99967 15.9997C7.99967 16.7361 8.59663 17.333 9.33301 17.333H14.6663V22.6663C14.6663 23.3501 15.1811 23.9137 15.8442 23.9907L15.9997 23.9997C16.7361 23.9997 17.333 23.4027 17.333 22.6663V17.333H22.6663C23.3501 17.333 23.9137 16.8183 23.9907 16.1552L23.9997 15.9997C23.9997 15.2633 23.4027 14.6663 22.6663 14.6663H17.333V9.33301C17.333 8.64923 16.8183 8.08566 16.1552 8.00864L15.9997 7.99967Z"
                fill={fill}
            />
        </svg>
    );
};

export default AddCircleIcon;
