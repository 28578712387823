import { GroupSiteData } from "../../includes/Structures";

export type GroupMetrics = {
    rs_metrica: string;
    ya_metrica: string;
    ga_metrica: string;
    fb_pixel: string;
    google_tag: string;
    vk_pixel: string;
};

export default (data: GroupSiteData): GroupMetrics => {
    return {
        rs_metrica: data.rs_metrica ? data.rs_metrica : "",
        ya_metrica: data.ya_metrica ? data.ya_metrica : "",
        ga_metrica: data.ga_metrica ? data.ga_metrica : "",
        fb_pixel: data.fb_pixel ? data.fb_pixel : "",
        google_tag: data.google_tag ? data.google_tag : "",
        vk_pixel: data.vk_pixel ? data.vk_pixel : "",
    };
};
