import React from "react";
import { Placeholder } from "@vkontakte/vkui";
import { Icon56NewsfeedOutline } from "@vkontakte/icons";

type EmptyPagesStubProps = {
    stretched?: boolean;
};

const EmptyPagesStub = (props: EmptyPagesStubProps) => {
    const { stretched = false } = props;

    return (
        <Placeholder icon={<Icon56NewsfeedOutline />} stretched={stretched}>
            Список лендингов пуст
        </Placeholder>
    );
};

export default EmptyPagesStub;
