import React, { useState } from "react";
import { FormLayout, Radio, Button, Placeholder, Div } from "@vkontakte/vkui";
import SubscriptionsSelect from "../../../Сomponents/Controls/Selects/SubscriptionSelect";
import Subscription from "../../../includes/Mappings/Subscription";

declare type CatalogViewModeFormProps = {
    currentType: string;
    defaultSubscriptionId: number;
    types: object;
    subscriptions: Subscription[];
    onSubmit?: Function;
    isPending?: boolean;
};

const CatalogViewModeForm = (props: CatalogViewModeFormProps) => {
    const {
        currentType,
        defaultSubscriptionId,
        types,
        subscriptions,
        onSubmit,
    } = props;

    const [state, setState] = useState({
        catalogType: currentType,
        defaultSubscriptionId: defaultSubscriptionId
            ? defaultSubscriptionId
            : null,
    });

    const getFirstSubscriptionId = () => {
        if (subscriptions && subscriptions.length > 0) {
            return parseInt(subscriptions[0].data.subscription_id);
        } else {
            return null;
        }
    };

    const handleRadioChange = (e) => {
        let value = e.currentTarget.value;

        let defaultSubscriptionId =
            value === "single" ? getFirstSubscriptionId() : null;

        const newState = {
            catalogType: value,
            defaultSubscriptionId: defaultSubscriptionId,
        };
        setState(newState);
    };

    const handleSubscripionChange = (item: Subscription) => {
        const newState = {
            ...state,
            defaultSubscriptionId: parseInt(item.data.subscription_id, 10),
        };
        setState(newState);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit && onSubmit(state);
    };

    const renderRadios = () => {
        return Object.keys(types).map((key) => {
            const type = types[key];
            const isActive = type.value === state.catalogType;
            return (
                <Radio
                    className="Radio_clickable"
                    key={type.value}
                    name="catalog_type"
                    value={type.value}
                    checked={isActive}
                    onChange={handleRadioChange}
                >
                    {type.name}
                </Radio>
            );
        });
    };

    const renderSubscriptionsSelect = () => {
        return (
            <SubscriptionsSelect
                top="Выберите группу подписчиков"
                defaultValue={state.defaultSubscriptionId}
                onSelect={handleSubscripionChange}
                items={subscriptions}
            />
        );
    };

    if (subscriptions.length === 0) {
        return (
            <Placeholder stretched>
                <p>
                    В сообществе пока нет групп <br /> подписчиков, на которые
                    смогут <br /> подписываться пользователи.
                </p>
            </Placeholder>
        );
    }

    return (
        <div className="CatalogViewModeForm">
            <FormLayout onSubmit={handleSubmit}>
                {renderRadios()}
                {state.catalogType === "single" && renderSubscriptionsSelect()}
                <Div>
                    <Button size="l" mode="secondary" type="submit" stretched>
                        Продолжить
                    </Button>
                </Div>
            </FormLayout>
        </div>
    );
};

export default CatalogViewModeForm;
