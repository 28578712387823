function reducer(state, action) {
    const newRows = state.rows.map((item) => ({ ...item }));

    switch (action.type) {
        case "title":
            return {
                ...state,
                title: action.payload[0],
                title_url: action.payload[1],
            };
        case "more":
            return {
                ...state,
                more: action.payload[0],
                more_url: action.payload[1],
            };
        case "img":
            state.rows[action.payload.cardID].img = action.payload.img;
            state.rows[action.payload.cardID].icon_id = action.payload.img_vk;
            return { ...state };
        case "button":
            state.rows[action.payload[0]].button = action.payload[1][0];
            state.rows[action.payload[0]].button_url = action.payload[1][1];
            return { ...state };
        case "item":
            state.rows[action.payload[0]].title = action.payload[1].title;
            state.rows[action.payload[0]].url = action.payload[1].url;
            state.rows[action.payload[0]].descr = action.payload[1].descr;
            return { ...state };
        case "add":
            return {
                ...state,
                rows: [
                    ...state.rows,
                    {
                        img: "",
                        icon_id: "",
                        title: "",
                        url: "",
                        descr: "",
                        button: "",
                        button_url: "",
                    },
                ],
            };
        case "delete":
            state.rows.splice(action.payload, 1);
            return { ...state };
        case "update":
            return { ...state, rows: [...action.payload] };
        case "sub":
            const item_id = action.payload.item_id;
            newRows[item_id].button = action.payload.data.button;
            newRows[item_id].button_url = action.payload.data.button_url;
            return { ...state, rows: newRows };
        default:
            throw new Error();
    }
}
export default reducer;
