import React, { useContext } from "react";
import Badges from "../../../Controls/Badges";
import DetachedBadges from "../../../Controls/DetachedBadges";
import UserCommunityRole from "./UserCommunityRole";
import { Div, FormItem, FormLayout, SelectMimicry } from "@vkontakte/vkui";

import { platforms } from "../../../../includes/Constants";
import { Context } from "../../../../context";
import AudienceContext from "../../../../Context/AudienceContext";

const blockAudiencePlatforms = [
    ...platforms,
    {
        id: 6,
        title: "Мобильный мессенджер для Android",
    },
    {
        id: 7,
        title: "Мобильный мессенджер для iPhone",
    },
];

const ExtraOptions = () => {
    const { set, platform, names, surnames } = useContext(AudienceContext);

    const { currentModal } = useContext(Context);

    const onModalRequest = currentModal.set;

    return (
        <FormLayout>
            <FormItem top="Устройство">
                <SelectMimicry
                    onClick={() => {
                        onModalRequest({
                            id: "platform-select",
                            usagePlace: "pages-block-audience",
                            args: {
                                platforms: blockAudiencePlatforms,
                                selected: platform,
                                callback: (val) => {
                                    set("platform", val);
                                },
                            },
                        });
                    }}
                    placeholder="Выбрать"
                />
            </FormItem>
            {platform.length > 0 && (
                <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <DetachedBadges
                        items={blockAudiencePlatforms.filter(
                            (p) => platform.indexOf(p.id) > -1
                        )}
                        name="platform"
                        onChange={(val) => {
                            set(
                                "platform",
                                val.map((i) => i.id)
                            );
                        }}
                        getText={(item) => item.title}
                    />
                </Div>
            )}

            <FormItem top="Имя пользователя">
                <Badges
                    placeholder="Введите имя"
                    items={names}
                    name="names"
                    onChange={(keywords) => {
                        let values = keywords.length === 0 ? "any" : keywords;
                        set("names", values);
                    }}
                />
            </FormItem>

            <FormItem top="Фамилия пользователя">
                <Badges
                    placeholder="Введите фамилию"
                    items={surnames}
                    name="surnames"
                    onChange={(keywords) => {
                        let values = keywords.length === 0 ? "any" : keywords;
                        set("surnames", values);
                    }}
                />
            </FormItem>

            {/* Выбор роли пользователя в сообществе */}
            <UserCommunityRole />
        </FormLayout>
    );
};

export default ExtraOptions;
