import React, { useContext, useState } from "react";
import CloseOrSavePopupFooter from "./CloseOrSavePopupFooter";
import { Spinner } from "@vkontakte/vkui";

import container from "../../../container";
import { Context } from "../../../context";
import { BlockData } from "../../../includes/Structures";

type AlignBlockPopupPropsType = {
    blockRef?: any;
    block: any;
    platform: string;
    blockPadding: any;
    blockAlignment?: string;
    setBlockAlignment?: Function;
    getCurrentAlignment?: Function;
    setAlignBlockPopupOpened: Function;
};

const alignments = [
    {
        id: 1,
        label: "Слева",
        value: "left",
    },
    {
        id: 2,
        label: "По центру",
        value: "center",
    },
    {
        id: 3,
        label: "Справа",
        value: "right",
    },
];

const AlignBlockPopup = (props: AlignBlockPopupPropsType) => {
    const {
        blockRef,
        setAlignBlockPopupOpened,
        platform,
        blockAlignment,
        setBlockAlignment,
        getCurrentAlignment,
        block,
        blockPadding,
    } = props;

    const { popout, snackbar, location, pages } = useContext(Context);

    const PagesService = container.get("PagesService");

    const page_id = location.getPageId();

    const [isLoading, setIsLoading] = useState(false);

    const closeAlignBlockPopup = (): void => {
        setAlignBlockPopupOpened(false);
    };

    const saveBlockAlignment = async (blockData: BlockData): Promise<void> => {
        const dataToBackend: any = {
            ...blockData,
            meta: {
                ...blockData.meta,
                padding: blockPadding,
                alignment: blockAlignment,
            },
        };

        setIsLoading(true);

        const response = await PagesService.updateBlock(dataToBackend);

        if (response.result === "success") {
            snackbar.showSuccess("Выравнивание изменено");

            pages.updateBlock(block.id, page_id, dataToBackend);

            setIsLoading(false);
        } else {
            popout.error(response.message);
            setIsLoading(false);
        }
    };

    const popup_classnames = ["AlignBlockPopup"];

    if (platform !== "desktop_web") {
        popup_classnames.push("AlignBlockPopup__mobile");
    }

    if (isLoading) {
        popup_classnames.push("loading");
    }

    return (
        <div
            className={`AlignBlockPopup__box ${
                block.meta.is_fullscreen && platform !== "desktop_web"
                    ? "AlignBlockPopup__fullscreen"
                    : ""
            }`}
            ref={blockRef}
        >
            <div className={popup_classnames.join(" ")}>
                {isLoading ? <Spinner size="regular" /> : null}

                <div className="AlignBlockPopup__wrapper">
                    <div className="AlignBlockPopup__badges">
                        {alignments.map((el) => (
                            <div
                                className={`AlignBlockPopup__badge ${
                                    el.value === blockAlignment ? "active" : ""
                                }`}
                                key={el.id}
                                onClick={() => {
                                    setBlockAlignment(el.value);
                                }}
                            >
                                {el.label}
                            </div>
                        ))}
                    </div>
                </div>

                <CloseOrSavePopupFooter
                    closePopup={closeAlignBlockPopup}
                    type={{
                        popup_type: "alignment-popup",
                        label: getCurrentAlignment(blockAlignment),
                    }}
                    savePopupResult={() => {
                        saveBlockAlignment(block);
                    }}
                />
            </div>
        </div>
    );
};

export default AlignBlockPopup;
