import React from "react";
import { Button } from "@vkontakte/vkui";

type SaveButtonPropsType = {
    handleSubmit: () => Promise<any>;
};

const SaveButton = (props: SaveButtonPropsType) => {
    const { handleSubmit } = props;

    return (
        <Button
            style={{
                marginLeft: 0,
                marginRight: 0,
                width: "100%",
            }}
            onClick={handleSubmit}
            stretched={true}
            size="l"
        >
            Сохранить
        </Button>
    );
};

export default SaveButton;
