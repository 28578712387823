import React, { useEffect, useReducer, useContext, useState } from "react";
import {
    Group,
    Cell,
    FixedLayout,
    Div,
    Switch,
    Headline,
} from "@vkontakte/vkui";
import Demography from "../../Elements/Audience/Demography";
import Geography from "../../Elements/Audience/Geography";
import ExtraOptions from "../../Elements/Audience/ExtraOptions/ExtraOptions";
import SaveButton from "../../Elements/Audience/SaveButton";

import {
    Analytics,
    PAGES_BLOCK_AUDIENCE_HIT,
} from "../../../includes/Metrics/Analytics";

import reducer, { initialBlockAudienceState, UPDATE_AUDIENCE } from "./reducer";
import container from "../../../container";
import { Context } from "../../../context";
import AlertDeleteCommon from "../../Modals/Alert/AlertDeleteCommon";
import AudienceVariables from "../../Elements/Audience/AudienceVariables";
import { setHash } from "../../../includes/Helpers/Helpers";
import AudienceContext from "../../../Context/AudienceContext";
import UserTime from "../../Elements/Audience/ExtraOptions/UserTime";
import AudienceSubscriber from "../../Elements/Audience/AudienceSubscriber";

/**
 * Проверка - задано ли value изначально в приходящем объекте audience
 * @param {any} value
 * @returns {boolean}
 */
const isset = (value) => !!value && value !== "any";

const PagesBlockAudienceForm = () => {
    const { pages, location, popout } = useContext(Context);

    const PagesService = container.get("PagesService");

    const currentPageId = location.getPageId();
    const currentBlockId = pages.getCurrentEditableBlock();
    const currentPage = pages.getPage(currentPageId);
    const currentBlock = currentPage.blocks_edit.find(
        (b) => b.id === currentBlockId
    );

    const initialAudienceData = {
        status: 1,
        data: {},
    };

    const [audience, setAudience] = useState(
        currentBlock.audience ? currentBlock.audience : initialAudienceData
    );

    const audienceData = audience.data;

    const [state, dispatch] = useReducer(reducer, {
        status: audience.status,
        data: {
            sex: audienceData.sex ? audienceData.sex : "any",
            age_from: audienceData.age_from ? audienceData.age_from : "any",
            age_to: audienceData.age_to ? audienceData.age_to : "any",
            b_day: audienceData.b_day ? audienceData.b_day : "any",
            current_country:
                audienceData.current_country &&
                    audienceData.current_country.length > 0
                    ? audienceData.current_country
                    : [],
            current_city:
                audienceData.current_city &&
                    audienceData.current_city.length > 0
                    ? audienceData.current_city
                    : [],
            platform:
                audienceData.platform && audienceData.platform.length > 0
                    ? audienceData.platform
                    : [],
            names: isset(audienceData.names) ? audienceData.names : "any",
            surnames: isset(audienceData.surnames)
                ? audienceData.surnames
                : "any",
            relation: audienceData.relation ? audienceData.relation : [],
            user_time: audienceData.user_time
                ? audienceData.user_time
                : {
                    type: "after",
                    time: "17:00",
                    is_active: false,
                },
            variable_global: audienceData.variable_global
                ? audienceData.variable_global
                : "any",
            variable_lead: audienceData.variable_lead
                ? audienceData.variable_lead
                : "any",
            user_role: audienceData.user_role ? audienceData.user_role : "any",

            subscriber: audienceData.subscriber ? audienceData.subscriber : false,
            subscribed_on_groups: audienceData.subscribed_on_groups ? audienceData.subscribed_on_groups : [],
            not_subscribed_on_groups: audienceData.not_subscribed_on_groups ? audienceData.not_subscribed_on_groups : []
        },
    });

    const set = (name: string, value: any): void => {
        const newAudience = {
            status: audience.status,
            data: {
                ...audience.data,
                [name]: value,
            },
        };

        setAudience(newAudience);
    };

    const handleSubmit = async () => {
        try {
            popout.loading();

            const response = await PagesService.updateAudience(
                currentPageId,
                currentBlockId,
                audience
            );

            popout.close();

            if (response.result === "success") {
                pages.updateBlock(
                    currentBlockId,
                    currentPageId,
                    {
                        ...currentBlock,
                        audience,
                    },
                    () => {
                        navigateToPage();
                    }
                );
            } else {
                popout.error(response.message);
            }
        } catch (error) {
            popout.error("Возникла ошибка при обновлении аудитории");
            console.error(error.message);
        }
    };

    const navigateToPage = () => {
        setHash({
            list: "pages",
            view: "pages-edit",
            panel: "item",
            page_id: currentPageId,
        });
    };

    useEffect(() => {
        dispatch({ type: UPDATE_AUDIENCE, payload: audience });
    }, [audience]);

    useEffect(() => {
        Analytics.goal(PAGES_BLOCK_AUDIENCE_HIT);
    }, []);

    const resetAudienceSettings = async () => {
        popout.open(
            <AlertDeleteCommon
                destructiveTitle="Сбросить"
                accept={async () => {
                    try {
                        popout.loading();

                        const resettedAudience = {
                            status: audience.status,
                            data: {},
                        };

                        for (let key in audience.data) {
                            resettedAudience["data"][key] =
                                initialBlockAudienceState["data"][key];
                        }

                        const response = await PagesService.updateAudience(
                            currentPageId,
                            currentBlockId,
                            resettedAudience
                        );

                        popout.close();

                        if (response.result === "success") {
                            setAudience(resettedAudience);
                            pages.updateBlock(currentBlockId, currentPageId, {
                                ...currentBlock,
                                audience: resettedAudience,
                            });
                        } else {
                            popout.error(response.message);
                        }
                    } catch (error) {
                        popout.error(
                            "Возникла ошибка при обновлении аудитории"
                        );
                        console.log(error);
                    }
                }}
                deny={() => {
                    popout.close();
                }}
            />
        );
    };

    return (
        <AudienceContext.Provider
            value={{
                sex: state.data.sex,
                age_from: state.data.age_from,
                age_to: state.data.age_to,
                b_day: state.data.b_day,
                relation: state.data.relation,
                current_country: state.data.current_country,
                current_city: state.data.current_city,
                platform: state.data.platform,
                names: state.data.names,
                surnames: state.data.surnames,
                user_time: state.data.user_time,
                variable_global: state.data.variable_global,
                variable_lead: state.data.variable_lead,
                user_role: state.data.user_role,
                subscriber: state.data.subscriber,
                subscribed_on_groups: state.data.subscribed_on_groups,
                not_subscribed_on_groups: state.data.not_subscribed_on_groups,
                set
            }}
        >
            <div className="PagesBlockAudienceForm">
                <Group>
                    <Cell
                        subtitle="Блок будет показываться в соответствии с настройками аудитории"
                        hasHover={false}
                        hasActive={false}
                        multiline
                        disabled
                        after={
                            <Switch
                                checked={!!state.status}
                                onChange={() => {
                                    switch (state.status) {
                                        case 0:
                                            const newAudience1 = {
                                                status: 1,
                                                data: {
                                                    ...audience.data,
                                                },
                                            };

                                            setAudience(newAudience1);
                                            break;
                                        case 1:
                                            const newAudience2 = {
                                                status: 0,
                                                data: {
                                                    ...audience.data,
                                                },
                                            };

                                            setAudience(newAudience2);
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            />
                        }
                    >
                        Включить
                    </Cell>
                </Group>

                <>
                    <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <h3>Данные пользователя ВК</h3>
                    </Div>
                    {/* Демография */}
                    <Group
                        header={
                            <Div style={{ paddingTop: 0, paddingBottom: 8 }}>
                                <Headline level="2" weight="1">
                                    Демография
                                </Headline>
                            </Div>
                        }
                    >
                        <Demography />
                    </Group>

                    {/* География */}
                    <Group
                        header={
                            <Div style={{ paddingTop: 0, paddingBottom: 8 }}>
                                <Headline level="2" weight="1">
                                    География
                                </Headline>
                            </Div>
                        }
                    >
                        <Geography />
                    </Group>

                    {/* Дополнительные параметры */}
                    <Group
                        header={
                            <Div style={{ paddingTop: 0, paddingBottom: 8 }}>
                                <Headline level="2" weight="1">
                                    Дополнительные параметры
                                </Headline>
                            </Div>
                        }
                    >
                        <ExtraOptions />
                    </Group>
                </>

                <>
                    <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <h3>Данные в Senler</h3>
                    </Div>
                    {/* Переменные */}
                    <Group
                        className="PagesBlockAudienceForm-conditions"
                        header={
                            <Div style={{ paddingTop: 0, paddingBottom: 8 }}>
                                <Headline level="2" weight="1">
                                    Переменные
                                </Headline>
                            </Div>
                        }
                    >
                        <AudienceVariables />
                    </Group>

                    {/* Является подписчиком */}
                    <Group
                        className="PagesBlockAudienceForm-conditions"
                        header={
                            <Div style={{ paddingTop: 0, paddingBottom: 8 }}>
                                <Headline level="2" weight="1">
                                    Подписчик
                                </Headline>
                            </Div>
                        }
                    >
                        <AudienceSubscriber />
                    </Group>
                </>

                <Div style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
                    <Div style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <h3>Другие фильтры</h3>
                    </Div>

                    {/* Выбор пользовательского времени */}
                    <UserTime />
                </Div>

                <Group>
                    <Div>
                        <button
                            className="PagesBlockAudienceForm__clear-audience-btn"
                            onClick={resetAudienceSettings}
                        >
                            Сбросить настройки аудитории
                        </button>
                    </Div>
                </Group>

                <FixedLayout vertical="bottom" filled>
                    <Div>
                        <SaveButton handleSubmit={handleSubmit} />
                    </Div>
                </FixedLayout>
            </div>
        </AudienceContext.Provider>
    );
};

export default PagesBlockAudienceForm;
