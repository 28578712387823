import React from "react";
import { HorizontalScroll as VKUIHorizontalScroll } from "@vkontakte/vkui";
import HorizontalScroll from "react-scroll-horizontal";

declare type AdaptiveHorizontalScroll = {
    platform: string;
    height: number;
    children: any;
};

/**
 * На десктопах используем другой компонент HR
 * так как стандартный VK UI на десктопах работает некорректно
 */

const AdaptiveHorizontalScroll = (props: AdaptiveHorizontalScroll) => {
    const { children, platform, height } = props;

    const appWidth = window.innerWidth;
    const desktopChildren = (
        <div key="adaptive-scroll" style={{ width: appWidth + 1 }}>
            {[children]}
        </div>
    );

    return platform === "desktop_web" ? (
        <div style={{ height: height, width: "100%" }}>
            <HorizontalScroll reverseScroll={true}>
                {[desktopChildren]}
            </HorizontalScroll>
        </div>
    ) : (
        <VKUIHorizontalScroll>{children}</VKUIHorizontalScroll>
    );
};

export default AdaptiveHorizontalScroll;
