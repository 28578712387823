import { Button, Div, FormLayout } from "@vkontakte/vkui";
import React, { useState } from "react";
import FadeIn from "react-fade-in";
import ButtonCommonForm from "../Pages/Blocks/Edit/ButtonCommon/ButtonCommonForm";
import { validator as buttonValidator } from "../../Сomponents/Pages/Blocks/Edit/ButtonCommon/validator";

const ModalButton = (props: any) => {
    const {
        errors = {},
        button = {},
        currentDynamicInput,
        close = () => {},
        callback = () => {},
        timerFixedEdit = false,
    } = props;

    const [buttonObj, setButtonObj] = useState({ ...button });
    const [buttonErrors, setButtonErrors] = useState({ ...errors });

    const saveModal = () => {
        const buttonValidationResult = buttonValidator(buttonObj);

        if (buttonValidationResult.status === false) {
            setButtonErrors({
                ...buttonErrors,
                button: { ...buttonValidationResult.errors },
            });
            return false;
        } else {
            setButtonErrors({});
        }

        setButtonErrors({});

        callback(buttonObj);
        close();
    };

    return (
        <div style={{ overflow: "auto" }}>
            <FormLayout>
                <FadeIn>
                    <ButtonCommonForm
                        data={buttonObj}
                        onChange={setButtonObj}
                        errors={buttonErrors.button}
                        currentDynamicInput={currentDynamicInput}
                        timerFixedEdit={timerFixedEdit}
                        isInModal
                    />
                </FadeIn>

                <Div style={{ paddingTop: 0 }}>
                    <Button size="l" onClick={saveModal} stretched>
                        Сохранить
                    </Button>
                </Div>
            </FormLayout>
        </div>
    );
};

export default ModalButton;
