import React, { useEffect, useState } from "react";
import CardProduct from "../../Elements/CardProduct";
import Twemoji from "react-twemoji";
import { simpleTextToHtml } from "../../../includes/Helpers/TextToHtml";
import { BlockAlignment } from "../../../includes/Types/PageBlocks/IndentsAndAlignBlockPopup";
import { DEFAULT_BLOCK_TEXT_FONT_SIZE } from "../../../includes/Constants/PageBlockConstants";

type ProductsProps = {
    /**
     * Данные блока
     */
    data: any;

    /**
     * В режиме превью или полноценного просмотра
     */
    isPreview: boolean;

    /**
     * Глобальный объект для изменения высоты приложения
     */
    resizer?: any;

    /**
     * Платформа запуска приложения
     */
    platform: string;

    /**
     * Отступ блока
     */
    blockPadding?: number;

    /**
     * Выравнивание блока
     */
    blockAlignment?: BlockAlignment;

    textFontSize?: number;
};

const IMAGES_SIDE_RATIO = 1.6;

const defaultResizer = {
    // Объект, управляющий высотой приложения на десктопе
    setLandingHeight: () => {},
};

const Products = (props: ProductsProps) => {
    const [imageHeight, setImageHeight] = useState(200);

    const {
        data,
        isPreview = false,
        blockPadding = 25,
        resizer = defaultResizer,
        platform = "desktop_web",
        blockAlignment = "center",
        textFontSize = DEFAULT_BLOCK_TEXT_FONT_SIZE
    } = props;

    const noHeading = !data.content.title && !data.content.text;

    useEffect(() => {
        window.addEventListener("resize", () => {
            updateImagesHeight();
        });

        updateImagesHeight();

        resizer.setLandingHeight();
    }, []);

    const updateImagesHeight = () => {
        const image = document.querySelector(".CardProduct__image");
        if (!image) return false;
        const imageWidth = image.clientWidth;
        setImageHeight(imageWidth / IMAGES_SIDE_RATIO);
        resizer.setLandingHeight();
    };

    const renderItems = () => {
        return data.items.map((product) => {
            return (
                <div key={product.uuid} className="B-Products__item">
                    <CardProduct
                        isPreview={isPreview}
                        name={product.name}
                        text={product.text}
                        subhead={product.category}
                        price={product.price}
                        price_old={product.price_old}
                        img={product.img}
                        button={product.button}
                        blockId={data.id}
                        pageId={data.page_id}
                        imgHeight={imageHeight}
                        onButtonClick={() => {
                            /** @TODO */
                        }}
                    />
                </div>
            );
        });
    };

    const isSingleProduct = data.items.length === 1;

    return (
        <div
            className={`B-Block B-Products ${
                noHeading ? "B-Products_noHeading" : ""
            } ${blockAlignment}`}
            style={{
                paddingTop: `${blockPadding}px`,
                paddingBottom: `${blockPadding}px`,
                fontSize: `${textFontSize}em`,
            }}
        >
            {(data.content.title || data.content.text) &&
                (data.has_title === undefined || data.has_title) && (
                    <div className="B-Block__head">
                        {data.content.title && (
                            <div className="B-Block__title">
                                <Twemoji
                                    options={{ className: "twemoji-promo" }}
                                >
                                    {data.content.title}
                                </Twemoji>
                            </div>
                        )}
                        {data.content.text && (
                            <div className="B-Block__subtitle">
                                <Twemoji
                                    options={{ className: "twemoji-promo" }}
                                >
                                    {simpleTextToHtml(data.content.text)}
                                </Twemoji>
                            </div>
                        )}
                    </div>
                )}
            <div className="B-Products__items">
                <div
                    className={`B-Products__items-inner ${
                        isSingleProduct && platform === "desktop_web"
                            ? "B-Products__items-inner_single"
                            : ""
                    }`}
                >
                    {renderItems()}
                </div>
            </div>
        </div>
    );
};

export default Products;
