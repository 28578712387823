import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactSelect from "react-select";
import { FormItem, Input, NativeSelect, platform, Platform } from "@vkontakte/vkui";
import { Icon28DeleteOutline } from "@vkontakte/icons";
import VariablesSelect from "../../Controls/Selects/VariablesSelect";

import { VARIABLE_CONDITIONS } from "../../../includes/Constants/Conditions";
import container from "../../../container";
import AudienceContext from "../../../Context/AudienceContext";

type AudienceVariablePropsType = {
    type: string;
};

const AudienceVariable = (props: AudienceVariablePropsType) => {
    const { type } = props;

    const { set, variable_global, variable_lead } = useContext(AudienceContext);

    const [globalVariables, setGlobalVariables] = useState([]);
    const [leadVariables, setLeadVariables] = useState([]);

    const variablesService = container.get("VariablesService");

    useEffect(() => {
        switch (type) {
            case "variable_global":
                loadGlobalVariables();
                break;

            case "variable_lead":
                loadLeadVariables();
                break;

            default:
                break;
        }
    }, []);

    const loadGlobalVariables = async () => {
        try {
            let resp = await variablesService.getGlobalVariables();

            const filteredVarsForReactSelect = resp.items.map((v) => {
                return {
                    label: v.n,
                    value: v.v,
                };
            });

            setGlobalVariables(filteredVarsForReactSelect);
        } catch (err) {
            console.log(err);
        }
    };

    const loadLeadVariables = async () => {
        try {
            let resp = await variablesService.getLeadVariables();

            const filteredVarsForReactSelect = resp.items.map((v) => {
                return {
                    label: v.n,
                    value: v.lead_var_id,
                };
            });

            setLeadVariables(filteredVarsForReactSelect);
        } catch (err) {
            console.log(err);
        }
    };

    const deleteVariable = () => {
        switch (type) {
            case "variable_global":
                set("variable_global", "any");
                break;
            case "variable_lead":
                set("variable_lead", "any");
                break;
            default:
                break;
        }
    };

    const chooseVariableCondition = (condition) => {
        switch (type) {
            case "variable_global":
                if (
                    condition.value === "exists" ||
                    condition.value === "nexists"
                ) {
                    variable_global.action = condition.value;

                    delete variable_global.value;

                    set("variable_global", variable_global);

                    return;
                }

                variable_global.action = condition.value;

                set("variable_global", variable_global);

                break;
            case "variable_lead":
                if (
                    condition.value === "exists" ||
                    condition.value === "nexists"
                ) {
                    variable_lead.action = condition.value;

                    delete variable_lead.value;

                    set("variable_lead", variable_lead);

                    return;
                }

                variable_lead.action = condition.value;

                set("variable_lead", variable_lead);

                break;
            default:
                break;
        }
    };

    const changeVariableValue = (e) => {
        switch (type) {
            case "variable_global":
                variable_global.value = e.target.value;

                set("variable_global", variable_global);

                break;
            case "variable_lead":
                variable_lead.value = e.target.value;

                set("variable_lead", variable_lead);

                break;
            default:
                break;
        }
    };

    const renderVariableValueInput = () => {
        switch (type) {
            case "variable_global":
                if (
                    variable_global.action !== "exists" &&
                    variable_global.action !== "nexists"
                ) {
                    return (
                        <div className="AudienceVariable__input-wrapper">
                            <Input
                                placeholder="Введите значение"
                                onChange={changeVariableValue}
                                value={variable_global.value}
                            />
                        </div>
                    );
                }

                break;
            case "variable_lead":
                if (
                    variable_lead.action !== "exists" &&
                    variable_lead.action !== "nexists"
                ) {
                    return (
                        <div className="AudienceVariable__input-wrapper">
                            <Input
                                placeholder="Введите значение"
                                onChange={changeVariableValue}
                                value={variable_lead.value}
                            />
                        </div>
                    );
                }
                break;

            default:
                break;
        }
    };

    const currentVariables =
        type === "variable_global" ? globalVariables : leadVariables;

    const currentVariable =
        type === "variable_global" ? variable_global : variable_lead;

    const currentLabel =
        type === "variable_global"
            ? "Глобальная переменная"
            : "Пользовательская переменная";

    const globalVariableCondition = useMemo(() => {
        return VARIABLE_CONDITIONS.find(
            (c) => c.value === variable_global.action
        );
    }, [variable_global.action]);

    const leadVariableCondition = useMemo(() => {
        return VARIABLE_CONDITIONS.find(
            (c) => c.value === variable_lead.action
        );
    }, [variable_lead.action]);

    const currentConditionValue =
        type === "variable_global"
            ? globalVariableCondition
            : leadVariableCondition;

    return (
        <div className="AudienceVariable">
            <div className="AudienceVariable__label-wrapper">
                <FormItem
                    className="AudienceVariable__headline"
                    top={currentLabel}
                    style={{ padding: 0 }}
                />
                <Icon28DeleteOutline
                    onClick={deleteVariable}
                    style={{ cursor: "pointer" }}
                    fill="var(--vkui--color_accent_red)"
                    width={15}
                />
            </div>

            <div className="AudienceVariable__input-wrapper">
                <VariablesSelect
                    currentAudienceVariable={currentVariable}
                    variables={currentVariables}
                    onChange={(v) => {
                        switch (type) {
                            case "variable_global":
                                variable_global.name = v.label;

                                set("variable_global", variable_global);

                                break;
                            case "variable_lead":
                                variable_lead.name = v.label;

                                set("variable_lead", variable_lead);

                                break;
                            default:
                                break;
                        }
                    }}
                />
            </div>

            <div className="AudienceVariable__input-wrapper">
                {platform() !== Platform.IOS ? (
                    <ReactSelect
                        className="ReactSelect Variables-select"
                        classNamePrefix="Variables-select"
                        defaultValue={VARIABLE_CONDITIONS[0]}
                        options={VARIABLE_CONDITIONS}
                        onChange={chooseVariableCondition}
                        value={currentConditionValue}
                        isSearchable={false}
                        cacheOptions
                        defaultOptions
                        noOptionsMessage={() => {
                            return "Ничего не найдено";
                        }}
                    />
                ) : (
                    <NativeSelect
                        value={currentConditionValue.value}
                        onChange={(e) => {
                            chooseVariableCondition({
                                value: e.target.value,
                            });
                        }}
                        className="mt-0"
                    >
                        {VARIABLE_CONDITIONS.map((varCondition) => (
                            <option
                                key={`varCondition-${varCondition.value}`}
                                value={varCondition.value}
                            >
                                {varCondition.label}
                            </option>
                        ))}
                    </NativeSelect>
                )}
            </div>

            {renderVariableValueInput()}
        </div>
    );
};

export default AudienceVariable;
