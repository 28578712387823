import { PAY_PERIOD_DAY, PAY_PERIOD_MONTH } from "../Constants";
import { SubscriptionParams } from "../Structures";

/**
 * Вернет информацию о периодах платежей за подписку в формате 100 р/месяц, 3 р/день
 * @param subscription
 */
export const getSubscriptionPrice = (
    subscription: SubscriptionParams
): string => {
    if (
        !subscription ||
        false === subscription.pay ||
        !subscription.pay_price
    ) {
        return "";
    }

    if (subscription.pay_period !== 0) {
        return `${subscription.pay_price} р/${
            subscription.pay_period == 2 ? "мес" : "день"
        }`;
    } else {
        return `${subscription.pay_price}`;
    }
};

/**
 * Обрежет полное описание подписки до заданого количества символов maxLength
 * с учетом html тегов
 * @param text
 */
export const cropDescription = (
    text: string,
    maxLength: number = 200
): string => {
    if (typeof text !== "string") {
        return "";
    }

    if (text.length <= maxLength) {
        return text;
    }

    let newText = text;

    while (newText.length > maxLength) {
        if (/<br\s*\/?>$/gm.test(newText)) {
            newText = newText.replace(/<br\s*\/?>$/gm, "");
        } else {
            newText = newText.slice(0, -1);
        }
    }

    return `${newText.trim()}...`;
};

/**
 * Вернет отформатированный платный период подписки
 * @param subscription
 */
export const getPayPeriod = (subscription: SubscriptionParams): string => {
    if (!subscription.pay_period) {
        return null;
    }

    if (
        subscription.pay_period === PAY_PERIOD_DAY &&
        subscription.pay_period_count > 0
    ) {
        return `/ ${subscription.pay_period_count} дн`;
    }

    if (
        subscription.pay_period === PAY_PERIOD_MONTH &&
        subscription.pay_period_count > 0
    ) {
        return `/  ${subscription.pay_period_count} мес`;
    }

    return "";
};
