import { CSSProperties } from "react";

export default (hasError: boolean, allowEdit: boolean) => {
    const styles: CSSProperties = { marginTop: 9 };

    if (hasError) {
        styles.color = "var(--vkui--color_accent_red)";
        styles.border = "1px solid var(--vkui--color_accent_red)";
        styles.boxShadow = 'none'
    }

    if (allowEdit === false) {
        styles.opacity = 0.2;
    }

    return styles;
};
