import React, { useState, useEffect, useContext } from "react";
import {
    List,
    Cell,
    Search,
    Avatar,
    Div,
    Group,
    Footer,
    Spinner,
    platform,
    Platform,
} from "@vkontakte/vkui";
import ApiVk from "../../../includes/ApiVk";
import {
    isValidUrl,
    getScreenNameFromUrl,
} from "../../../includes/Helpers/Helpers";
import { USER_TOKEN_REQUEST_FAILED, WIDGET_MAX_AUDIENCE_COMMUNITIES_ERROR } from "../../../includes/ErrorMessages";
import { Context } from "../../../context";
import { WIDGET_MAX_AUDIENCE_COMMUNITIES } from "../../../includes/Constants";

const isInteger = require("is-integer");

const getGroupType = (type) => {
    switch (type) {
        case "group":
            return "Группа";
        case "page":
            return "Публичная страница";
        case "event":
            return "Событие";
        default:
            return "Публичная страница";
    }
};

const community_types = ["group", "page", "event"];

const CommunitySelect = (props) => {
    const api = ApiVk.getInstance(null);

    const { groups, setGroups, onSelect, widget } = props;

    const { popout, snackbar, widgets } = useContext(Context);

    const [selected, setSelected] = useState(
        props.selected && props.selected.length > 0 ? [...props.selected] : []
    );
    const [search, setSearch] = useState("");
    const [requestTimeout, setRequestTimeout] = useState(null);
    const [bottomText, setBottomText] = useState("");
    const [isPending, setIsPending] = useState(false);

    const searchGroups = async (query) => {
        setIsPending(true);

        let communities = [];

        /**
         * Можно рефакторить
         */

        // Если введена ссылка - попробуем искать по screen_name
        if (isValidUrl(query)) {
            let screen_name = getScreenNameFromUrl(query);

            if (screen_name) {
                let resultScreenName = await api.resolveScreenName(screen_name);

                if (
                    resultScreenName.type &&
                    community_types.includes(resultScreenName.type) &&
                    resultScreenName.object_id
                ) {
                    let communityResp = await api.getCommunityById(
                        resultScreenName.object_id
                    );

                    if (
                        communityResp.response &&
                        communityResp.response.length > 0
                    ) {
                        communities.push(communityResp.response[0]);
                    } else if (communityResp.result === "error") {
                        setIsPending(false);
                        popout.error(USER_TOKEN_REQUEST_FAILED);
                        return;
                    }
                } else if (resultScreenName.result === "error") {
                    popout.error(USER_TOKEN_REQUEST_FAILED);
                    setIsPending(false);

                    return;
                }
            }
        } else if (isInteger(parseInt(query))) {
            let communityResp = await api.getCommunityById(parseInt(query));

            if (communityResp.response && communityResp.response.length > 0) {
                communities.push(communityResp.response[0]);
            } else if (communityResp.result === "error") {
                setIsPending(false);
                popout.error(USER_TOKEN_REQUEST_FAILED);
                return;
            }
        } else {
            let result = await api.searchGroups(query);
            if (result.response && result.response.items) {
                communities = [...result.response.items];
            } else if (result.result === "error") {
                popout.error(USER_TOKEN_REQUEST_FAILED);
                setIsPending(false);
                return;
            }
        }

        setGroups(communities);
        setIsPending(false);

        if (communities.length <= 0) {
            setBottomText("По вашему запросу ничего не найдено");
        }
    };

    useEffect(() => {
        const input: any = document.querySelector(".vkuiSearch__input");
        if (input && platform() === Platform.ANDROID) {
            input.focus();
        }
    }, []);

    useEffect(() => {
        let query = search.trim();

        if (requestTimeout) {
            clearTimeout(requestTimeout);
        }

        if (query.length < 2) {
            setGroups([]);
            setBottomText("");
            return;
        }

        /**
         * Откладываем запрос чтобы предотвратить отправку на каждое нажатие кнопки
         */
        const newTimeout = setTimeout(() => {
            searchGroups(query);
        }, 1000);

        setRequestTimeout(newTimeout);
    }, [search]);

    const add = (comm) => {
        const allWidgetsByType = widgets.getAllByType(widget.type)
        const chosenCommunitiesInAudience = []

        allWidgetsByType.forEach(w => {
            if ('communityIn' in w.audience) {
                chosenCommunitiesInAudience.push(...w.audience.communityIn)
            }

            if ('communityOut' in w.audience) {
                chosenCommunitiesInAudience.push(...w.audience.communityOut)
            }
        })

        if (
            chosenCommunitiesInAudience.length >= WIDGET_MAX_AUDIENCE_COMMUNITIES
        ) {
            snackbar.showError(WIDGET_MAX_AUDIENCE_COMMUNITIES_ERROR);
            return
        }

        const newSelected = [...selected];
        newSelected.push(comm);
        setSelected(newSelected);
        onSelect(newSelected);
    };

    const remove = (comm) => {
        const newSelected = [...selected];
        const i = newSelected.findIndex((c) => c.id === comm.id);
        newSelected.splice(i, 1);
        setSelected([...newSelected]);
        onSelect(newSelected);
    };

    return (
        <>
            <Search
                autoFocus={false}
                onChange={(e) => {
                    setSearch(e.currentTarget.value);
                }}
                style={{ background: "transparent" }}
                after=""
            />

            <Group title="Сообщества" className="mt-0">
                {isPending && (
                    <Div>
                        {" "}
                        <Spinner size="medium" />{" "}
                    </Div>
                )}
                {groups.length > 0 && (
                    <List>
                        {groups.map((item) => {
                            const isSelected =
                                selected.findIndex((o) => o.id === item.id) >
                                -1;
                            return (
                                <Cell
                                    key={item.id}
                                    checked={isSelected}
                                    onChange={() => {
                                        if (isSelected) remove(item);
                                        else add(item);
                                    }}
                                    subtitle={getGroupType(item.type)}
                                    mode="selectable"
                                    before={<Avatar src={item.photo_50} />}
                                >
                                    {item.name}
                                </Cell>
                            );
                        })}
                    </List>
                )}
                {bottomText !== "" && (
                    <Footer style={{ height: 40 }}>{bottomText}</Footer>
                )}
            </Group>
        </>
    );
};

export default CommunitySelect;
