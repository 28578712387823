import React, { useState } from "react";
import MetricsSettingsForm from "../Сomponents/Forms/MetricsSettingsForm/MetricsSettingsForm";
import MetricsFromGroupData from "../includes/Mappings/MetricsFromGroupData";
import GroupDataServiceInterface from "../includes/Interfaces/GroupDataServiceInterface";
import GroupDataProviderInterface from "../includes/Interfaces/GroupDataProviderInterface";
import PopoutInterface from "../includes/Interfaces/PopoutInterface";
import SnackbarInterface from "../includes/Interfaces/SnackbarInterface";

declare type Props = {
    service: GroupDataServiceInterface;
    groupDataProvider: GroupDataProviderInterface;
    snackbar: SnackbarInterface;
    onSuccess?: Function;
    popout: PopoutInterface;
};

const MetricsSettingsController = (props: Props) => {
    const { service, groupDataProvider, snackbar, popout, onSuccess } = props;

    const [isPending, setIsPending] = useState(false);
    const [errors, setErrors] = useState({});

    const data = MetricsFromGroupData(groupDataProvider.get());

    const handleFormSubmit = async (metricsValues) => {
        setIsPending(true);

        popout.loading();

        const resp = await service.saveMetrics(metricsValues);
        if (true === resp.success) {
            const newGroupData = {
                ...groupDataProvider.get(),
                ...metricsValues,
            };

            groupDataProvider.set(newGroupData);
            setErrors({});
            snackbar.showSuccess("Настройки сохранены");
            onSuccess && onSuccess();
        } else if (false === resp.success && resp.errors) {
            const responseErrors = {};
            resp.errors.forEach((e) => {
                responseErrors[e.field] = {
                    message: e.message,
                };
            });
            setErrors(responseErrors);
        } else {
            snackbar.showError("Неизвестная ошибка", {
                text: "Повторить",
                callback: (e) => {
                    snackbar.close();
                    handleFormSubmit(metricsValues);
                },
            });
        }

        popout.close();
        setIsPending(false);
    };

    return (
        <MetricsSettingsForm
            isPending={isPending}
            onSubmit={handleFormSubmit}
            data={data}
            errors={errors}
        />
    );
};

export default MetricsSettingsController;
