import React from "react";
import ReactSelect from "react-select";
import { Scrollbars } from "react-custom-scrollbars";
import { Headline, NativeSelect, platform, Platform } from "@vkontakte/vkui";
import { AudienceVariableType } from "../../../includes/Structures";

type SelectVariableType = {
    label: string;
    value: string;
};

type VariablesSelectPropsType = {
    label?: string;
    onChange: (v: SelectVariableType) => void;
    variables: SelectVariableType[];
    currentAudienceVariable?: AudienceVariableType;
};

export const renderSelectScrollbar = (
    selectProps: any,
    autoHeightMax: number = 125,
    thumbMinSize: number = 60,
    autoHeight: boolean = true
) => {
    return (
        <div className="Variables-select__menu-list">
            <Scrollbars
                thumbMinSize={thumbMinSize}
                autoHeightMax={autoHeightMax}
                autoHeight={autoHeight}
                renderThumbVertical={(selectProps) => (
                    <div {...selectProps} className="track-vertical" />
                )}
            >
                {" "}
                <div style={{ height: "100%" }}>{selectProps.children}</div>
            </Scrollbars>
        </div>
    );
};

const VariablesSelect = (props: VariablesSelectPropsType) => {
    const {
        label,
        onChange,
        variables,
        currentAudienceVariable = null,
    } = props;

    return (
        <>
            {label && (
                <Headline
                    level="2"
                    weight="1"
                    style={{
                        marginBottom: 7,
                        fontSize: 14,
                    }}
                >
                    {label}
                </Headline>
            )}
            {currentAudienceVariable ? (
                platform() !== Platform.IOS ? (
                    <ReactSelect
                        cacheOptions
                        defaultOptions
                        className={`ReactSelect Variables-select ${
                            !currentAudienceVariable.name
                                ? "ReactSelect__default-value"
                                : ""
                        }`}
                        classNamePrefix="Variables-select"
                        placeholder="Выберите переменную"
                        value={{
                            label: currentAudienceVariable.name
                                ? currentAudienceVariable.name
                                : "Выберите переменную",
                        }}
                        options={variables}
                        onChange={onChange}
                        noOptionsMessage={() => {
                            return "Ничего не найдено";
                        }}
                        components={{
                            MenuList: (selectProps) =>
                                renderSelectScrollbar(selectProps),
                        }}
                    />
                ) : (
                    <NativeSelect
                        placeholder={
                            !currentAudienceVariable.name
                                ? "Выберите переменную"
                                : ""
                        }
                        value={currentAudienceVariable.name}
                        onChange={(e) => {
                            onChange({
                                label: e.target.value,
                                value: currentAudienceVariable.value,
                            });
                        }}
                        className="mt-0"
                    >
                        {variables.map((variable, idx) => (
                            <option
                                key={`variable-${variable.label}`}
                                value={variable.label}
                            >
                                {variable.label}
                            </option>
                        ))}
                    </NativeSelect>
                )
            ) : (
                <ReactSelect
                    cacheOptions
                    defaultOptions
                    className="ReactSelect Variables-select"
                    classNamePrefix="Variables-select"
                    placeholder="Выберите переменную"
                    options={variables}
                    onChange={onChange}
                    noOptionsMessage={() => {
                        return "Ничего не найдено";
                    }}
                    components={{
                        MenuList: (selectProps) =>
                            renderSelectScrollbar(selectProps),
                    }}
                />
            )}
        </>
    );
};

export default VariablesSelect;
