import React, { useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import {
    ButtonGroup,
    Button,
    Div,
    Spinner,
    DateRangeInput,
    Subhead,
    usePlatform,
    Platform,
} from "@vkontakte/vkui";
import { PageStatisticSummary } from "../../includes/Structures";
import { addDays } from "@vkontakte/vkjs";
import PageStatisticTable from "./PageStatisticTable";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

type PageStatisticProps = {
    tableData: PageStatisticSummary;
    isPendingStatisticsByDateRange: boolean;
    lineChartData: { labels: []; datasets: [] };
    lineChartOptions: object;
    fetchLineChartPageStatistics: (
        dateInterval: string,
        dateRangeFrom?: any,
        dateRangeTo?: any
    ) => Promise<boolean>;
    fetchSummaryStatisticByDateRange: (
        dateInterval: string,
        dateRangeFrom?: any,
        dateRangeTo?: any
    ) => Promise<boolean>;
    isPendingTableData: boolean;
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const PageStatistic = (props: PageStatisticProps) => {
    const {
        lineChartData,
        isPendingStatisticsByDateRange,
        lineChartOptions,
        fetchLineChartPageStatistics,
        tableData,
        isPendingTableData,
        fetchSummaryStatisticByDateRange,
    } = props;

    const [activeButton, setActiveButton] = useState<string>("today");

    const [dateRangevalue, setDateRangevalue] = useState([
        new Date(),
        addDays(new Date(), 10),
    ]);

    const lineChartRef = useRef();

    const platform = usePlatform();

    const getStatisticsByDateRange = async (
        statisticsType: string,
        dateInterval: string,
        dateRangeFrom = null,
        dateRangeTo = null
    ) => {
        let isSuccess;

        if (statisticsType === "intervals") {
            isSuccess = await fetchLineChartPageStatistics(
                dateInterval,
                dateRangeFrom,
                dateRangeTo
            );
        } else {
            isSuccess = await fetchSummaryStatisticByDateRange(
                dateInterval,
                dateRangeFrom,
                dateRangeTo
            );
        }

        if (isSuccess) {
            setActiveButton(dateInterval);
        }

        return isSuccess;
    };

    const onChangeDateRange = async (statisticsType: string, val) => {
        if (val === undefined || val[1] === null) {
            setDateRangevalue(val);
            return
        }

        const dateFrom = val[0];
        const dateTo = val[1];

        let isSuccess;

        if (statisticsType === "intervals") {
            isSuccess = await getStatisticsByDateRange(
                "intervals",
                "user-date-range",
                dateFrom,
                dateTo
            );
        } else {
            isSuccess = await getStatisticsByDateRange(
                "summary",
                "user-date-range",
                dateFrom,
                dateTo
            );
        }

        if (isSuccess) {
            setDateRangevalue(val);
            setActiveButton(
                `
                    ${String(dateFrom.getDate()).padStart(2, "0")}.${String(
                    dateFrom.getMonth() + 1
                ).padStart(2, "0")}.${dateFrom.getFullYear()}
                    -
                    ${String(dateTo.getDate()).padStart(2, "0")}.${String(
                    dateTo.getMonth() + 1
                ).padStart(2, "0")}.${dateTo.getFullYear()}

            `
            );
        }
    };

    const getDateIntervalRu = (dateInterval: string): string => {
        switch (dateInterval) {
            case "today":
                return "сегодня";
            case "yesterday":
                return "вчера";
            case "week":
                return "неделю";
            case "month":
                return "месяц";
            case "quarter":
                return "квартал";
            case "year":
                return "год";
            default:
                return dateInterval;
        }
    };

    const isDesktop = platform === Platform.VKCOM;

    return (
        <div
            className={`PageStatistic ${
                isDesktop ? "PageStatistic-desktop" : "PageStatistic-mobile"
            }`}
        >
            <Div className="PageStatistic__btns-wrapper">
                <ButtonGroup
                    gap="none"
                    mode={isDesktop ? "horizontal" : "vertical"}
                >
                    <Button
                        className={`PageStatistic__btn ${
                            activeButton === "today"
                                ? "PageStatistic__active-chart-filter-btn"
                                : ""
                        }`}
                        style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            boxShadow: "none",
                            border: "1px solid var(--vkui--color_separator_primary)",
                        }}
                        onClick={() => {
                            getStatisticsByDateRange("intervals", "today");
                            getStatisticsByDateRange("summary", "today");
                        }}
                        size="s"
                        appearance="neutral"
                        mode="outline"
                        hasActive={false}
                        disabled={isPendingStatisticsByDateRange}
                    >
                        Сегодня
                    </Button>
                    <Button
                        className={`PageStatistic__btn ${
                            activeButton === "yesterday"
                                ? "PageStatistic__active-chart-filter-btn"
                                : ""
                        }`}
                        style={{
                            borderRadius: 0,
                            boxShadow: "none",
                            border: "1px solid var(--vkui--color_separator_primary)",
                            borderLeft: 0,
                            borderRight: 0,
                        }}
                        onClick={() => {
                            getStatisticsByDateRange("intervals", "yesterday");
                            getStatisticsByDateRange("summary", "yesterday");
                        }}
                        size="s"
                        appearance="neutral"
                        mode="outline"
                        hasActive={false}
                        disabled={isPendingStatisticsByDateRange}
                    >
                        Вчера
                    </Button>
                    <Button
                        className={`PageStatistic__btn ${
                            activeButton === "week"
                                ? "PageStatistic__active-chart-filter-btn"
                                : ""
                        }`}
                        style={{
                            borderRadius: 0,
                            boxShadow: "none",
                            border: "1px solid var(--vkui--color_separator_primary)",
                        }}
                        onClick={() => {
                            getStatisticsByDateRange("intervals", "week");
                            getStatisticsByDateRange("summary", "week");
                        }}
                        size="s"
                        appearance="neutral"
                        mode="outline"
                        hasActive={false}
                        disabled={isPendingStatisticsByDateRange}
                    >
                        Неделя
                    </Button>
                    <Button
                        className={`PageStatistic__btn ${
                            activeButton === "month"
                                ? "PageStatistic__active-chart-filter-btn"
                                : ""
                        }`}
                        style={{
                            borderRadius: 0,
                            boxShadow: "none",
                            border: "1px solid var(--vkui--color_separator_primary)",
                            borderLeft: 0,
                            borderRight: 0,
                        }}
                        onClick={() => {
                            getStatisticsByDateRange("intervals", "month");
                            getStatisticsByDateRange("summary", "month");
                        }}
                        size="s"
                        appearance="neutral"
                        mode="outline"
                        hasActive={false}
                        disabled={isPendingStatisticsByDateRange}
                    >
                        Месяц
                    </Button>
                    <Button
                        className={`PageStatistic__btn ${
                            activeButton === "quarter"
                                ? "PageStatistic__active-chart-filter-btn"
                                : ""
                        }`}
                        style={{
                            borderRadius: 0,
                            boxShadow: "none",
                            border: "1px solid var(--vkui--color_separator_primary)",
                        }}
                        onClick={() => {
                            getStatisticsByDateRange("intervals", "quarter");
                            getStatisticsByDateRange("summary", "quarter");
                        }}
                        size="s"
                        appearance="neutral"
                        mode="outline"
                        hasActive={false}
                        disabled={isPendingStatisticsByDateRange}
                    >
                        Квартал
                    </Button>
                    <Button
                        className={`PageStatistic__btn ${
                            activeButton === "year"
                                ? "PageStatistic__active-chart-filter-btn"
                                : ""
                        }`}
                        style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            boxShadow: "none",
                            border: "1px solid var(--vkui--color_separator_primary)",
                            borderLeft: 0,
                        }}
                        onClick={() => {
                            getStatisticsByDateRange("intervals", "year");
                            getStatisticsByDateRange("summary", "year");
                        }}
                        size="s"
                        appearance="neutral"
                        mode="outline"
                        hasActive={false}
                        disabled={isPendingStatisticsByDateRange}
                    >
                        Год
                    </Button>
                </ButtonGroup>

                <DateRangeInput
                    className="PageStatistic__dateRangeInput"
                    disabled={isPendingStatisticsByDateRange}
                    style={{
                        border:
                            activeButton !== "today" &&
                            activeButton !== "yesterday" &&
                            activeButton !== "week" &&
                            activeButton !== "month" &&
                            activeButton !== "quarter" &&
                            activeButton !== "year"
                                ? "1px solid var(--vkui--color_text_link_themed)"
                                : "",
                    }}
                    value={dateRangevalue}
                    onChange={(val) => {
                        onChangeDateRange("intervals", val);
                        onChangeDateRange("summary", val);
                    }}
                    disablePast={false}
                    disableFuture={false}
                    disablePickers={false}
                    closeOnChange
                />
            </Div>

            <Div style={{ height: 324 }}>
                {isPendingStatisticsByDateRange ? (
                    <div style={{ height: 324 }}>
                        <Spinner />
                    </div>
                ) : (
                    <Line
                        ref={lineChartRef}
                        data={lineChartData}
                        options={lineChartOptions}
                    />
                )}
            </Div>

            <Div>
                <Subhead weight="3" style={{ marginBottom: 12 }}>
                    Общая статистика за {getDateIntervalRu(activeButton)}
                </Subhead>

                {isPendingTableData ? (
                    <div style={{ height: 281 }}>
                        <Spinner />
                    </div>
                ) : (
                    <PageStatisticTable
                        data={tableData}
                        lineChartRef={lineChartRef}
                    />
                )}
            </Div>
        </div>
    );
};

export default PageStatistic;
