export default class DateTimeHelper {
    timeUnits = {
        day: ["день", "дня", "дней"],
        hour: ["час", "часа", "часов"],
        minute: ["минута", "минуты", "минут"],
        second: ["секунда", "секунды", "секунд"],
    };

    /**
     * Получить текстовое представление для количества дней
     */
    getDeclarationOfDays(daysValue: number): string {
        return this.getDeclarationOfTimeUnit(daysValue, "day");
    }

    /**
     * Получить текстовое представление для количества часов
     */
    getDeclarationOfHours(hoursValue: number): string {
        return this.getDeclarationOfTimeUnit(hoursValue, "hour");
    }

    /**
     * Получить текстовое представление для количества минут
     */
    getDeclarationOfMinutes(minutesValue: number): string {
        return this.getDeclarationOfTimeUnit(minutesValue, "minute");
    }

    /**
     * Получить текстовое представление для количества секунд
     */
    getDeclarationOfSeconds(secondsValue: number): string {
        return this.getDeclarationOfTimeUnit(secondsValue, "second");
    }

    getDeclarationOfTimeUnit(value: number, timeUnitType: string) {
        let types = this.timeUnits[timeUnitType];

        value = Math.abs(value) % 100;
        let n1 = value % 10;

        if (value > 10 && value < 20) {
            return types[2];
        }
        if (n1 > 1 && n1 < 5) {
            return types[1];
        }
        if (n1 == 1) {
            return types[0];
        }

        return types[2];
    }

    getTimeRange(min: number, max: number): string[] {
        let result = []

        for (let i = min; i <= max; i++) {
            if (i < 10) {
                result[i] = `${0}${i}`
                continue
            }

            result[i] = `${i}`
        }

        return result
    }
}
